import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'

import { formatDate } from 'helpers/Formatters'
import Checkbox from 'components/Common/Form/Checkbox'
import downloadjs from 'downloadjs'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import RadioGroup from 'components/Common/Form/RadioGroup'

class ExportModal extends Component {
  constructor(props) {
    super(props)
    const { productTypes } = this.props
    const fields = {
      allProductTypes: {
        label: 'All',
        type: 'checkbox',
        value: true,
      },
      autoRenewalInds: {
        label: 'Auto-Renewal',
        type: 'text',
        value: '10',
      },
      allVisibleOnTable: {
        type: 'switch',
        value: true,
      },
      virtualProductTypes: {
        type: 'array',
      },
    }
    this.form = new Form({ fields }, { name: 'ExportModalForm', handleSubmit: this.handleSubmit })
    this.form.update({ virtualProductTypes: toJS(productTypes) })
    this.form.$('virtualProductTypes').map(item => {
      return item.add({
        key: 'productType',
        type: 'checkbox',
        label: item.value,
        value: true,
      })
    })
  }
  handleSubmit = () => {
    const { deviceManagementRepository, onClose } = this.props
    let payload = {}
    if (this.form.$('allVisibleOnTable').value) {
      payload = {
        autoRenewalInds: this.getAutoRenewalIndsData(this.form.$('autoRenewalInds').value),
        virtualProductTypes: this.form
          .$('virtualProductTypes')
          .map(item => (item.value.productType ? item.$value : null))
          .filter(Boolean),
      }
    } else {
      const {
        Table: { data },
      } = deviceManagementRepository
      payload = {
        deviceManagementIds: data.map(item => item.id),
      }
    }
    deviceManagementRepository.export(payload, result => {
      const dateStamp = formatDate(new Date(), 'DD-MMM-YYYY-hhmmss')
      downloadjs(
        result,
        `Auto-Renewal-${dateStamp}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
      onClose()
    })
  }
  getAutoRenewalIndsData = value => {
    let data = []
    if (value.includes('1')) {
      data.push(true)
    }
    if (value.includes('0')) {
      data.push(false)
    }

    return data
  }
  selectAll = toggle => {
    this.form.$('virtualProductTypes').map(item => item.$('productType').set(toggle))
  }
  handleAllVisibleOnTableChange = event => {
    if (event.target.checked) {
      this.form.$('autoRenewalInds').set('10')
      this.form.$('allProductTypes').set(true)
      this.selectAll(true)
    }
  }
  handleVirtualProductTypesChange = event => {
    if (!event.target.checked) {
      this.form.$('allProductTypes').set(false)
    }
  }
  render() {
    const {
      open,
      onClose,
      productTypes,
      deviceManagementRepository: {
        Export: { loading, errors },
      },
    } = this.props
    const checkboxClassName = productTypes.length > 15 ? 'w-50' : 'w-100'
    const autoRenewalRadios = [
      {
        label: 'All',
        value: '10',
      },
      {
        label: 'On',
        value: '1',
      },
      {
        label: 'Off',
        value: '0',
      },
    ]
    return (
      <Modal
        title="Export Options"
        size={productTypes.length > 15 ? 'md' : 'sm'}
        open={open}
        onConfirm={() => this.buttonSubmit.click()}
        onClose={() => onClose()}
        loading={loading}
        centered
      >
        <FormContainer
          onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })}
          errors={errors}
          plain
        >
          <Checkbox
            field={this.form.$('allVisibleOnTable')}
            label={this.form.$('allVisibleOnTable').value ? 'All Auto Renewals' : 'Only visible Auto Renewals'}
            onChange={this.handleAllVisibleOnTableChange}
          />
          {!!this.form.$('allVisibleOnTable').value && (
            <>
              <RadioGroup field={this.form.$('autoRenewalInds')} radios={autoRenewalRadios} inline />
              <hr className="my-2" />
              <Checkbox
                field={this.form.$('allProductTypes')}
                className={checkboxClassName}
                onChange={e => this.selectAll(e.target.checked)}
              />
              <div className="d-flex flex-wrap">
                {this.form.$('virtualProductTypes').map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      field={item.$('productType')}
                      className={checkboxClassName}
                      onChange={this.handleVirtualProductTypesChange}
                    />
                  )
                })}
              </div>
            </>
          )}
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('deviceManagementRepository')(observer(ExportModal)), {})
