import React, { Component } from 'react'
import styled from 'styled-components'

import { formatDate, formatDecimal } from 'helpers/Formatters'
import { kbToSizes } from 'helpers/Common'
import { REGION } from 'definitions'
import Modal from 'components/Common/Modal'

class EsimDataPlanModal extends Component {
  getCommonData = () => {
    const { esimDetails } = this.props
    if (esimDetails && esimDetails.length) {
      if (esimDetails[0]?.details?.length) {
        return esimDetails[0]?.details[0] || {}
      }
    }
    return {}
  }

  render() {
    const { open, mode, esimDetails, onClose, onSubmit } = this.props

    const commonData = this.getCommonData()
    const isIccid = mode === 'iccid'

    return (
      <Modal
        title={`Purchase Peplink ${
          commonData.targetDataRegion?.toLowerCase() !== REGION.GLOBAL.toLowerCase() ? commonData.targetDataRegion : ''
        } eSim Data Plan`}
        open={open}
        size="xl"
        centered
        confirmLabel="Agree and Continue"
        onClose={onClose}
        onConfirm={onSubmit}
      >
        <div style={{ fontSize: '14px' }}>
          <div>
            {/* eslint-disable-next-line max-len */}
            All remaining {commonData.sourceDataRegion} eSIM data associated with the following{' '}
            {isIccid ? 'ICCID' : 'serial'} numbers will be{' '}
            <span className="text-primary font-weight-600">converted</span> to {commonData.targetDataRegion} eSIM Data:
          </div>
          <TableStyled className="table table-striped table-sm my-2">
            <thead>
              <tr>
                <th className="border-bottom border-primary align-middle">{isIccid ? 'ICCID' : 'Serial'} Number</th>
                <th className="border-bottom border-primary align-middle text-center">
                  Remaining Quota
                  <br />({commonData.sourceDataRegion} eSIM Data)
                </th>
                <th className="border-bottom border-primary align-middle text-center">Current Expiry Date</th>
                <th className="border-bottom border-primary align-middle text-center">
                  Converted Quota
                  <br />({commonData.targetDataRegion} eSIM Data)
                </th>
                <th className="border-bottom border-primary align-middle text-center">
                  New Quota
                  <br />({commonData.targetDataRegion} eSIM Data)
                </th>
                <th className="border-bottom border-primary align-middle text-center">New Expiry Date</th>
              </tr>
            </thead>
            <tbody>
              {esimDetails.map(product => {
                if (product.details) {
                  return product.details.map((item, index) => (
                    <tr key={index}>
                      <td className="border-bottom">{isIccid ? item.iccidNumber : item.serialNumber}</td>
                      <td className="border-bottom text-center">{kbToSizes(item.remainingQuotaInKB, 'MB')}</td>
                      <td className="border-bottom text-center">{formatDate(item.expirationDate, 'MMM DD, YYYY')}</td>
                      <td className="border-bottom text-center">{kbToSizes(item.convertedRemainingQuotaInKB, 'MB')}</td>
                      <td className="border-bottom text-center">{kbToSizes(item.predictedRemainingQuotaInKB, 'MB')}</td>
                      <td className="border-bottom text-center">
                        {formatDate(item.predictedExpirationDate, 'MMM DD, YYYY')}
                      </td>
                    </tr>
                  ))
                }
                return null
              })}
            </tbody>
          </TableStyled>
          <div className="mt-2 d-flex align-items-center">
            {/* eslint-disable-next-line max-len */}
            <i className="icon ion-md-alert text-primary mr-2" />
            Conversion Ratio: {formatDecimal(commonData.sourceExchangeRate, 1)} GB {commonData.sourceDataRegion} eSIM
            Data <i className="icon ion-md-arrow-forward text-primary mx-2" />
            {formatDecimal(commonData.targetExchangeRate, 1)} GB {commonData.targetDataRegion} eSIM Data
          </div>
        </div>
      </Modal>
    )
  }
}

const TableStyled = styled.table`
  &.table {
    th {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 20px;
    }
  }
`

export default EsimDataPlanModal
