import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import React from 'react'

import { ENV_PRODUCTION, SSO_CALLBACK } from 'definitions'
import Loader from 'components/Common/Loader'

class ReactKeycloak extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
    this.init()
  }

  init = () => {
    const { keycloakRepository } = this.props
    keycloakRepository.initialize({
      onAuthenticated: this.authenticate,
      onAuthLogout: this.handleAuthLogout,
      onAuthError: error => {
        if (error) {
          // eslint-disable-next-line no-console
          console.log('Peplink ID error:')
          // eslint-disable-next-line no-console
          console.log(error)
        }
        this.setState({
          hasError: true,
        })
      },
    })
  }

  handleAuthLogout = async ({ onAuthLogout = null } = {}) => {
    const { authRepository } = this.props
    await authRepository.ssoLogout({
      onAuthLogout,
    })
  }

  authenticate = async data => {
    const isCallback = window.location.pathname === SSO_CALLBACK

    const { authRepository, history } = this.props
    if (!authRepository.isAuthenticated()) {
      const { state, login } = queryString.parse(location.search)
      authRepository.authenticatePeplinkId({ history, data, state, login, isCallback })
    }
  }
  render() {
    const { hasError } = this.state
    const { authRepository, keycloakRepository, children } = this.props
    return (
      <React.Fragment>
        {authRepository.submitting && (
          <div
            style={{
              marginTop: process.env.REACT_APP_ENV !== ENV_PRODUCTION ? '26px' : '0',
            }}
          >
            <Loader text="Please wait..." />
          </div>
        )}
        {((!keycloakRepository.initializing && !authRepository.submitting) || hasError) && (
          <React.Fragment>{children}</React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default withRouter(inject('authRepository', 'keycloakRepository')(observer(ReactKeycloak)))
