import React, { Component } from 'react'

import { Button, Col, FormText, Input, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

import { formatCustomerCurrency } from 'helpers/Formatters'
import { PRODUCTS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import QuantityField from 'components/Common/Form/QuantityField'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import TextDecoration from 'components/Common/TextDecoration'

import { ProductPanelFunctions } from 'components/Orders/OrderForm/ProductPanelFunctions'
import CustomOptionsModal from 'components/Orders/OrderForm/CustomOptionsModal'
import DiscountContainer from 'components/Orders/OrderForm/DiscountContainer'
import OrderItemsModal from 'components/Orders/OrderForm/OrderItemsModal'
import SerialNumbersModal from 'components/Orders/OrderForm/SerialNumbersModal'
import SerialsModal from './SerialsModal'
class PricingRuleField extends Component {
  constructor(props) {
    super(props)
    this.state = { options: [] }
    this.getPricingRuleOptions(props.storeRepository.CRUD.data)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const orderDetails = nextProps.storeRepository.CRUD.data
    if (orderDetails.orderItemPricingRules) nextProps.form.$('pricingRules').set(orderDetails.orderItemPricingRules)
  }
  handlePricingRuleChange = vals => {
    const order = { ...this.props.storeRepository.CRUD.data }
    order.orderItemPricingRules = vals
    this.props.orderItemRepository.applyPricingRule(order.id, { order, pricingRules: vals }, res => {
      this.props.updateOrderData(res.order)
      this.getPricingRuleOptions(res.order)
    })
  }
  getPricingRuleOptions = order => {
    this.props.storeRepository.getPricingRulesOptions(order, options => {
      this.setState({
        options,
      })
    })
  }
  render() {
    const { options } = this.state
    const { form, readOnly } = this.props
    return (
      <ColPRStyled sm={12} className="price-rules">
        <ReactSelect
          disabled={readOnly}
          field={form.$('pricingRules')}
          customLabelKey={['label', 'id']}
          options={{
            isMulti: true,
            valueKey: 'id',
            labelKey: 'label',
            options: options,
          }}
          onChange={this.handlePricingRuleChange}
        />
      </ColPRStyled>
    )
  }
}

class ProductPanel extends Component {
  table = React.createRef()
  constructor(props) {
    super(props)
    const order = props.orderRepository.CRUD.data
    this.state = {
      isProductsModalOpen: false,
      isCustomOptionModalOpen: false,
      isSerialNumbersModalOpen: false,
      discountInd: (order.discount && order.discount.discountInd) || 'NA',
      selected: {},
      selectedIndex: null,
    }
    this.handleInputChange = debounce(this.handleInputChange, 500)

    this.handleOrderItemChangeCustomDiscountedPrice = debounce(this.handleOrderItemChangeCustomDiscountedPrice, 350, {
      leading: false,
      trailing: true,
    }).bind(this)
    this.handleCustomOptionChangeCustomDiscountedPrice = debounce(
      this.handleCustomOptionChangeCustomDiscountedPrice,
      350,
      {
        leading: false,
        trailing: true,
      }
    ).bind(this)
  }
  handleInputChange = (field, value, item) => {
    const {
      orderRepository: {
        CRUD: { data },
      },
      orderItemRepository,
      orderDetails,
    } = this.props
    item[field] = value
    orderItemRepository.patchOrderItem({ order: data, item }, item.id, res => {
      orderDetails.orderItems = [res.item]
      this.updateOrderData(res.order)
    })
  }
  handleDeleteItem = item => ProductPanelFunctions.handleDeleteItem(item, this)
  handleDeleteCustomOption = (item, customOptionIndex) =>
    ProductPanelFunctions.handleDeleteCustomOption(item, customOptionIndex, this)
  handleQuantityChange = (value, item) => ProductPanelFunctions.handleQuantityChange(value, item, this)
  handleOrderItemSerialNumbers = (serialNumbers, validateSFCPlan) =>
    ProductPanelFunctions.handleOrderItemSerialNumbers(serialNumbers, this, true, validateSFCPlan)
  handleSubmit = (item, updateShippingTotal) => ProductPanelFunctions.handleSubmit(item, updateShippingTotal, this)
  handleOrderItemChangeCustomDiscountedPrice = (val, index) =>
    ProductPanelFunctions.handleOrderItemChangeCustomDiscountedPrice(val, index, this)
  handleCustomOptionChangeCustomDiscountedPrice = (val, orderItemIndex, customOptionIndex) =>
    ProductPanelFunctions.handleCustomOptionChangeCustomDiscountedPrice(val, orderItemIndex, customOptionIndex, this)
  submitOrderItem = (product, callback = null, isCustomOptions = false) =>
    ProductPanelFunctions.submitOrderItem(product, callback, isCustomOptions, this)

  updateOrderData = (order, updateShippingTotal, callSummary = false) =>
    ProductPanelFunctions.updateOrderData(order, updateShippingTotal, this, callSummary)
  updateOrderItemsData = (orderItems, compute) => ProductPanelFunctions.updateOrderItemsData(orderItems, compute, this)

  toggleSerialModal = (selected, selectedIndex) => {
    this.setState({
      selected,
      isSerialModalOpen: !this.state.isSerialModalOpen,
      selectedIndex,
    })
  }
  toggleProductModal = () => {
    this.setState({
      isProductsModalOpen: !this.state.isProductsModalOpen,
    })
  }
  toggleCustomOptionsModal = () => {
    this.setState({
      isCustomOptionModalOpen: !this.state.isCustomOptionModalOpen,
    })
  }
  toggleSerialNumbersModal = selected => {
    this.setState({
      selected: selected ? { ...selected } : {},
      isSerialNumbersModalOpen: !this.state.isSerialNumbersModalOpen,
    })
  }
  setDiscountInd = discountInd => {
    this.setState({
      discountInd: discountInd,
    })
  }
  showSfcCloudUnlimitedMessage = sfcCloudSerialNumbers => {
    this.setState({
      sfcCloudSerialNumbers,
    })
  }
  renderStar = () => {
    return <span style={{ color: '#ffb81c' }}>★</span>
  }
  renderSerialNumbers = item => {
    if (!item.serialNumbers || item.serialNumbers.length !== item.activeQuantity) {
      return (
        <CellStyled>
          <Button
            color="light"
            size="xs"
            className="text-danger d-flex align-items-center"
            onClick={() => {
              this.toggleSerialNumbersModal(item)
            }}
          >
            <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />
            Please Configure
          </Button>
        </CellStyled>
      )
    } else {
      return (
        <CellStyled>
          <Button
            color="light"
            size="xs"
            className="d-flex align-items-center"
            onClick={() => {
              this.toggleSerialNumbersModal(item)
            }}
          >
            Configured
          </Button>
        </CellStyled>
      )
    }
  }
  render() {
    const {
      orderDetails = {},
      showDiscount = true,
      form,
      readOnly,
      isContract,
      isLoan,
      storeProductRepository,
      orderRepository: {
        CRUD: { data },
      },
    } = this.props
    const field = form && form.$('orderItems')
    const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
    const orderItems = data.orderItems || []
    const columns = {
      products: [
        {
          Header: 'Product Code',
          accessor: 'product.product.productCode',
          sortable: false,
          width: 200,
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <div>
                  <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>
                    {original.product.product.productCode}
                  </Link>
                </div>
              </CellStyled>
            )
          },
        },
        {
          Header: 'Product Name',
          accessor: 'product.product.name',
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <div>
                  <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>{original.product.product.name}</Link>
                </div>
              </CellStyled>
            )
          },
          width: '20%',
        },
        {
          Header: 'Subscription Items',
          className: !readOnly ? 'px-2 py-0 pt-1' : '',
          Cell: ({ row, original }) => {
            const subscriptionItem =
              (original.customOptionValues && !!original.customOptionValues.length && original.customOptionValues[0]) ||
              null
            return (
              <React.Fragment>
                {!readOnly && original.product.customOptions && !!original.product.customOptions.length ? (
                  <ReactSelectStyled
                    onChange={selected => this.handleInputChange('customOptionValues', selected, original)}
                    plain
                    customLabelKey={['name', 'id']}
                    serverSide
                    search={() =>
                      storeProductRepository.fetchSubscriptionItems(orderDetails.store.id, original.product.id)
                    }
                    options={{
                      isMulti: false,
                      valueKey: 'id',
                      labelKey: 'name',
                      defaultOptions: true,
                      value: original.customOptionValues,
                    }}
                  />
                ) : (
                  <span>{subscriptionItem && `${subscriptionItem.productCode} ${subscriptionItem.title}`}</span>
                )}
              </React.Fragment>
            )
          },
          width: 300,
        },
        {
          Header: 'Quantity',
          accessor: 'quantity',
          headerClassName: 'd-flex justify-content-center',
          className: 'text-center cell-qty',
          sortable: false,
          Cell: ({ row, original }) => {
            return (
              <CellStyled>
                <QuantityField
                  readOnly={readOnly}
                  value={row.quantity}
                  onChange={newVal => this.handleQuantityChange(newVal, original)}
                />
              </CellStyled>
            )
          },
          width: 150,
        },
        {
          Header: 'Listed Price',
          accessor: 'listedPrice',
          headerClassName: 'd-flex justify-content-end',
          className: 'd-flex justify-content-end',
          sortable: false,
          Cell: ({ original }) => {
            return (
              <CellStyled>
                {original.listedPrice ? (
                  <div>
                    <TextDecoration
                      decoration={
                        original.hasOwnProperty('salePrice') || original.hasOwnProperty('discountedPrice')
                          ? 'line-through'
                          : null
                      }
                    >
                      <div>{formatCustomerCurrency(isLoan ? 0 : original.listedPrice, data.customer)}</div>
                    </TextDecoration>
                  </div>
                ) : (
                  <div>-</div>
                )}
              </CellStyled>
            )
          },
          width: 150,
        },
        {
          Header: 'Installment Fee',
          accessor: 'installmentFee',
          headerClassName: 'd-flex  justify-content-end',
          className: 'd-flex  justify-content-end',
          sortable: false,
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <InputStyled
                  bsSize="sm"
                  className="text-right"
                  style={{ padding: '2px 0' }}
                  defaultValue={original.installmentFee}
                  name="installmentFee"
                  type="number"
                  onBlur={e => {
                    this.handleInputChange(e.target.name, e.target.value, original)
                  }}
                  readOnly={readOnly}
                />
              </CellStyled>
            )
          },
          width: 150,
        },
        {
          Header: 'Subtotal',
          accessor: 'subTotal',
          width: 150,
          headerClassName: 'd-flex justify-content-end',
          className: 'd-flex justify-content-end',
          sortable: false,
          Cell: ({ row }) => {
            return (
              <CellStyled>
                <div>{formatCustomerCurrency(isLoan ? 0 : row.subTotal || 0, data.customer)}</div>
              </CellStyled>
            )
          },
        },
        {
          Header: 'Action',
          accessor: null,
          sortable: false,
          width: 70,
          headerClassName: 'd-flex justify-content-center',
          className: 'd-flex justify-content-center',
          Cell: ({ original }) => (
            <CellStyled>
              <IconButton
                disabled={readOnly}
                icon="ios-trash"
                className="p-1"
                style={{ marginTop: '-4px' }}
                title="Remove product"
                onClick={() => this.handleDeleteItem(original)}
                iconSize="small"
              />
            </CellStyled>
          ),
        },
      ],
      associatedProducts: [
        {
          Header: 'Product Code',
          accessor: 'product.product.productCode',
          sortable: false,
          width: 250,
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <div>
                  <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>
                    {original.product.product.productCode}
                  </Link>
                </div>
              </CellStyled>
            )
          },
        },
        {
          Header: 'Product Name',
          accessor: 'product.product.name',
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <div>
                  <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>{original.product.product.name}</Link>
                </div>
              </CellStyled>
            )
          },
          width: '20%',
        },
        {
          Header: 'Serial Number',
          accessor: 'serialNumbers',
          headerClassName: 'text-left pl-2',
          sortable: false,
          width: 300,
          Cell: ({ original }) => this.renderSerialNumbers(original),
        },
        {
          Header: 'Active Quantity',
          accessor: 'activeQuantity',
          headerClassName: 'd-flex justify-content-center',
          className: 'd-flex justify-content-center',
          sortable: false,
          width: 200,
          Cell: ({ row, original }) => {
            return (
              <CellStyled className=" active-qty">
                <QuantityField
                  readOnly={readOnly}
                  value={row.activeQuantity}
                  onChange={newVal => this.handleQuantityChange(newVal, original)}
                />
              </CellStyled>
            )
          },
        },

        {
          Header: 'Installment Fee',
          accessor: 'installmentFee',
          headerClassName: 'd-flex justify-content-end',
          className: 'd-flex justify-content-end',
          sortable: false,
          width: 200,
          Cell: ({ original }) => {
            return (
              <CellStyled>
                <div>{formatCustomerCurrency(original.installmentFee, data.customer)}</div>
              </CellStyled>
            )
          },
        },
        {
          Header: 'Action',
          accessor: null,
          sortable: false,
          width: 75,
          headerClassName: 'd-flex justify-content-center',
          className: 'd-flex justify-content-center',
          Cell: ({ original }) => (
            <CellStyled>
              <IconButton icon="md-search" className="p-1" onClick={() => this.toggleSerialModal(original)} />
            </CellStyled>
          ),
        },
      ],
    }
    return (
      <Card
        title="Products"
        titleActions={
          <Visible toAppAdminRole>
            <IconButton
              disabled={readOnly}
              color="dark"
              className="mb-1"
              outline
              onClick={() => this.toggleProductModal()}
            />
          </Visible>
        }
      >
        <ReactTable
          ref={ref => (this.table = ref)}
          className="no-hover no-overflow"
          columns={isContract ? columns.products : columns.associatedProducts}
          data={orderItems}
          defaultPageSize={5}
          defaultSorted={[
            {
              id: 'product.product.name',
              desc: false,
            },
          ]}
          showRowPerPage={false}
          showPager={false}
        />
        {hasError && (
          <FormText color="danger" style={{ marginTop: '-15px' }}>
            {field.error}
          </FormText>
        )}
        <Row>
          <Col sm={10}>
            <p>Total {orderItems.length} product(s)</p>
          </Col>
          {orderDetails.hasOwnProperty('appliedCoupon') && (
            <Col sm={2} style={{ fontSize: 12 }}>
              {this.renderStar()} Coupon Code has been applied
            </Col>
          )}
        </Row>
        {showDiscount && (
          <React.Fragment>
            <PricingRuleField {...this.props} updateOrderData={this.updateOrderData} readOnly={readOnly} />
            <DiscountContainer
              readOnly={readOnly}
              readOnlyCoupon={readOnly}
              form={form}
              updateOrderData={this.updateOrderData}
              store={form.$('store').value}
              orderItems={orderItems}
              order={orderDetails}
              setDiscountInd={this.setDiscountInd}
              computeOrderSummary={this.props.computeOrderSummary}
            />
          </React.Fragment>
        )}
        <OrderItemsModal
          open={this.state.isProductsModalOpen}
          onClose={this.toggleProductModal}
          form={form}
          order={orderDetails}
          store={form.$('store').value}
          handleSelected={this.submitOrderItem}
          customer={orderDetails.customer}
          isCustomOptions={false}
        />
        <CustomOptionsModal
          open={this.state.isCustomOptionModalOpen}
          onClose={this.toggleCustomOptionsModal}
          form={form}
          order={orderDetails}
          store={form.$('store').value}
          handleSelected={this.submitOrderItem}
          selected={this.state.selected}
        />
        {this.state.isSerialNumbersModalOpen && (
          <SerialNumbersModal
            open={this.state.isSerialNumbersModalOpen}
            onClose={this.toggleSerialNumbersModal}
            handleSubmit={this.handleOrderItemSerialNumbers}
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
            sfcCloudSerialNumbers={this.state.sfcCloudSerialNumbers}
          />
        )}
        {this.state.isSerialModalOpen && (
          <SerialsModal
            open={this.state.isSerialModalOpen}
            onClose={this.toggleSerialModal}
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
            updateOrderData={this.updateOrderData}
          />
        )}
      </Card>
    )
  }
}

const CellStyled = styled.div`
  position: relative;
  > div {
    padding: 0.4rem 5px;
  }
  &.active-qty {
    .static-text {
      padding: 0 !important;
    }
  }
`
const ColPRStyled = styled(Col)`
  background: rgba(242, 242, 242, 0.8);
  margin-bottom: 15px;
  padding: 15px;
`

const InputStyled = styled(Input)`
  width: 100px;
  height: 15px !important;
  font-size: 11px !important;
  margin: 3px 0 !important;
  background: rgb(239, 239, 239) !important;
`
const ReactSelectStyled = styled(ReactSelect)`
  position: inherit !important;
  .react-select__menu {
    width: 22% !important;
  }
`

export default inject(
  'orderRepository',
  'orderItemRepository',
  'orderItemSerialNumberRepository',
  'shippingRateRepository',
  'storeProductRepository'
)(observer(ProductPanel))
