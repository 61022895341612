import React from 'react'

import { Badge } from 'reactstrap'
import { titleize } from 'helpers/String'

export default ({ status }) => {
  const isPaid = status === 'PAID'
  const isVoid = status === 'VOID'
  return (
    <Badge
      className={`text-uppercase d-inline-flex justify-content-center font-size-11 w-100 ${
        isPaid || isVoid ? '' : 'text-danger'
      }`}
      color={isPaid || isVoid ? 'light' : ''}
    >
      {!isPaid && !isVoid && (
        <i
          className="icon ion-md-warning"
          style={{
            width: '19px',
            height: '19px',
            fontSize: '14pt',
            marginTop: '-5px',
          }}
        />
      )}
      <span className="ml-1">{titleize(status, '_')}</span>
    </Badge>
  )
}
