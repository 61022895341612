import React from 'react'
import styled from 'styled-components'

export default ({ env }) => (
  <EnvNoticeStyled className="alert badge-primary d-flex align-items-center justify-content-center rounded-0 mb-0">
    This is just a Demo Environment (<span className="text-capitalize">{env}</span>)
  </EnvNoticeStyled>
)

const EnvNoticeStyled = styled.div`
  &.alert {
    z-index: 1030;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 20px;
    font-size: 12px;
    font-weight: 700;
  }
`
