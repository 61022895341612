import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Visible } from 'helpers/Auth'
import CollectionCreateModal from './CollectionCreateModal'
import IconButton from 'components/Common/Buttons/IconButton'
import React, { Component } from 'react'
import ReactTable from 'components/Common/ReactTable'

class CollectionTable extends Component {
  state = { isModalOpen: false }
  handleCreate = () => {}

  toggleCreateModal = () => this.setState({ isModalOpen: !this.state.isModalOpen })

  render() {
    const {
      columns = [
        { Header: 'Collection Name', accessor: 'collection' },
        { Header: 'Description', accessor: 'description' },
      ],
      innerRef,
      collectionRepository,
      searchRepository,
    } = this.props

    const {
      Table: { data, loading, originalData, totalRecordCount },
      fetchTable,
    } = collectionRepository
    return (
      <React.Fragment>
        <ReactTable
          searchRepository={searchRepository}
          pageKey="collections"
          enableRowLink
          getRowUrl={({ original }) => `/collections/${original.collection}`}
          className="striped cell-vertical-center"
          columns={columns}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          innerRef={innerRef}
          sort={{ id: 'collection' }}
          search
          actions={
            <Visible>
              <div className="text-right">
                <IconButton color="dark" outline onClick={this.toggleCreateModal} title="Add New Collection" />
              </div>
            </Visible>
          }
        />
        <CollectionCreateModal open={this.state.isModalOpen} toggle={this.toggleCreateModal} {...this.props} />
      </React.Fragment>
    )
  }
}

export default decorate(inject('collectionRepository', 'searchRepository')(observer(CollectionTable)), {})
