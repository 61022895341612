import React, { Component } from 'react'

import { Alert, Button, Col, Collapse, Row } from 'reactstrap'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { getFormattedDateTimeString } from 'helpers/Formatters'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import Loader from 'components/Common/Loader'
import MobxForm from 'components/Common/Form'

class AutoRenewalComments extends Component {
  constructor(props) {
    super(props)

    const { autoRenewalId } = this.props
    const fields = {
      type: {
        value: 'INTERNAL',
      },
      deviceManagementId: {
        value: autoRenewalId,
      },
      comment: {
        value: null,
        rules: 'required',
      },
    }
    this.form = new MobxForm({ fields }, { name: 'DeviceManagementCommentsForm', handleSubmit: this.handleSubmit })
    this.state = {
      showAddComment: false,
    }

    this.handleSubmit = debounce(this.handleSubmit, 350)
    this.fetchComments()
  }
  showAddComment = showAddComment => {
    this.setState({ showAddComment })
  }
  fetchComments = async () => {
    const { deviceManagementCommentRepository, autoRenewalId } = this.props
    await deviceManagementCommentRepository.fetchTable({
      autoRenewalId,
      size: 30,
      sort: 'createdDate,desc',
    })
  }
  handleSubmit = event => {
    this.form.onSubmit(event, {
      onSuccess: form => {
        const { deviceManagementCommentRepository } = this.props
        const values = form.values()

        deviceManagementCommentRepository.create(values, async () => {
          this.form.$('comment').clear()
          await this.fetchComments()
        })
      },
    })
  }
  handleKeyUp = event => {
    if (event.ctrlKey && event.keyCode === 13) {
      this.handleSubmit(event)
    }
  }
  render() {
    const { deviceManagementCommentRepository, onDrawerClose } = this.props
    const { showAddComment } = this.state
    const {
      CRUD: { submitting },
    } = deviceManagementCommentRepository
    const {
      Table: { data = [], loading },
    } = deviceManagementCommentRepository

    return (
      <AutoRenewalCommentsStyled>
        <Row>
          <Col className="pr-0">
            <h5 className="title">Comments</h5>
          </Col>
          <Col className="ml-auto px-0">
            <Button color="secondary" size="xs" className="mr-1" onClick={() => this.showAddComment(!showAddComment)}>
              Add Comment
            </Button>
            <IconButton icon="md-close" onClick={onDrawerClose} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={showAddComment}>
              <div className="d-flex flex-column mt-3">
                <Input
                  field={this.form.$('comment')}
                  type="textarea"
                  inputClassName="border"
                  style={{ whiteSpace: 'pre-line' }}
                  disabled={submitting}
                  onKeyUp={this.handleKeyUp}
                />
                <Button
                  color="dark"
                  size="xs"
                  className="mt-2 ml-auto align-self-end"
                  disabled={submitting}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row className="row-comments position-relative">
          {(loading || submitting) && <Loader className="loading p-3" text="Loading..." />}
          <Col>
            {data.map(row => {
              const bgColor = row.type === 'INTERNAL' ? 'bg-secondary' : 'bg-success'
              return (
                <div key={row.id} className="line">
                  <div className="d-inline-flex align-items-center">
                    <span className={`rounded-circle ${bgColor}`}></span>
                    <span className="ml-1 mr-2 font-weight-bold">{row.createdBy}</span>
                    <span>{getFormattedDateTimeString(row.createdDate)}</span>
                  </div>
                  <div>
                    <Alert color="dark" className="comment" style={{ whiteSpace: 'pre-line' }}>
                      {row.comment}
                    </Alert>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </AutoRenewalCommentsStyled>
    )
  }
}

const AutoRenewalCommentsStyled = styled.div`
  .title {
    min-height: 26px;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5rem 0;
  }
  .line {
    padding: 0.5rem 0;
    font-size: 85%;
    .alert {
      padding: 0.5rem 1rem;
      margin: 0;
    }
    .rounded-circle {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }
  .row-comments {
    min-height: 200px;
    .loading {
      display: flex;
      align-items: start;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
    }
  }
`

export default inject('deviceManagementCommentRepository')(observer(AutoRenewalComments))
