import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { Input } from 'reactstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { formatCustomerCurrency } from 'helpers/Formatters'
import { PRODUCTS_PATH } from 'definitions'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import TextDecoration from 'components/Common/TextDecoration'

class ProductsTable extends Component {
  render() {
    const {
      form,
      orderRepository,
      readOnly,
      discountInd,
      hideDelete,
      handleOrderItemChangeCustomDiscountedPrice,
      handleDeleteDevice,
      renderStar,
    } = this.props
    const orderDetails = orderRepository.CRUD.data
    const orderItems = orderDetails.orderItems || []
    const customer = form.$('customer').value

    return (
      <ReactTable
        className="no-hover"
        columns={[
          {
            Header: 'Product Code',
            accessor: 'product.product.productCode',
            sortable: false,
            width: 150,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  <div>
                    <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>
                      {original.product.product.productCode}
                    </Link>
                  </div>
                </CellStyled>
              )
            },
          },
          {
            Header: 'Product Name',
            accessor: 'product.product.name',
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  <div>
                    {/* eslint-disable-next-line max-len */}
                    <Link to={`${PRODUCTS_PATH}/${original.product.product.id}`}>
                      {original.product.product.name}
                    </Link>{' '}
                  </div>
                  {original.devices &&
                    original.devices.map(co => (
                      <div className="sub-cell" key={co.serialNumber}>
                        - {co.serialNumber}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },

          {
            Header: 'Terms in Month',
            accessor: 'orderItems',
            headerClassName: 'd-flex justify-content-center',
            className: 'text-center cell-qty',
            sortable: false,
            width: 100,
            Cell: ({ row, original }) => {
              return (
                <CellStyled className="device">
                  {original.devices &&
                    original.devices.map(co => (
                      <div className="sub-cell" key={co.serialNumber}>
                        {co.termsInMonth}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Listed Price',
            accessor: 'listedPrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  {original.listedPrice ? (
                    <div>
                      <TextDecoration
                        decoration={
                          original.hasOwnProperty('salePrice') || original.hasOwnProperty('discountedPrice')
                            ? 'line-through'
                            : null
                        }
                      >
                        <div>{formatCustomerCurrency(original.listedPrice, customer)}</div>
                      </TextDecoration>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Sale Price',
            accessor: 'salePrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original }) => {
              return (
                <CellStyled>
                  {original.salePrice ? (
                    <div>
                      <TextDecoration decoration={original.hasOwnProperty('discountedPrice') ? 'line-through' : null}>
                        <div>{formatCustomerCurrency(original.salePrice, customer)}</div>
                      </TextDecoration>
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </CellStyled>
              )
            },
          },
          {
            Header: 'Discounted Price',
            accessor: 'discountedPrice',
            headerClassName: 'text-right',
            className: 'text-right',
            sortable: false,
            width: 120,
            Cell: ({ original, index }) => {
              return discountInd === 'C' ? (
                <CellStyled>
                  <InputStyled
                    bsSize="sm"
                    className="text-right"
                    type="number"
                    defaultValue={original.discountedPrice}
                    onBlur={e => handleOrderItemChangeCustomDiscountedPrice(e.target.value, index)}
                    style={{ padding: '2px 0' }}
                  />
                </CellStyled>
              ) : (
                <CellStyled>
                  <>
                    {original.hasOwnProperty('appliedCoupon') && (
                      <div style={{ position: 'absolute', left: 15 }}>
                        {original.hasOwnProperty('appliedCoupon') && renderStar()}
                      </div>
                    )}
                    {original.discountedPrice ? (
                      <div
                        className={
                          (original.hasOwnProperty('appliedCoupon') ||
                            (original.discount && original.discount.discountInd === 'P')) &&
                          'text-danger'
                        }
                      >
                        {formatCustomerCurrency(original.discountedPrice, customer)}
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </>
                </CellStyled>
              )
            },
          },
          {
            Header: 'Subtotal',
            accessor: 'subTotal',
            width: 120,
            headerClassName: 'd-flex justify-content-end',
            className: 'text-right',
            sortable: false,
            Cell: ({ original }) => {
              return (
                <CellStyled className="device">
                  {original.devices &&
                    original.devices.map((device, index) => (
                      <div key={index} className="sub-cell">
                        {formatCustomerCurrency(device.subtotal || 0, customer)}
                      </div>
                    ))}
                </CellStyled>
              )
            },
          },
          ...(!hideDelete
            ? [
                {
                  Header: 'Action',
                  accessor: null,
                  sortable: false,
                  width: 70,
                  headerClassName: 'd-flex justify-content-center',
                  className: 'text-center',
                  Cell: ({ original }) => (
                    <CellStyled className="device">
                      {original.devices &&
                        original.devices.map((_, deviceIndex) => (
                          <div key={deviceIndex} className="sub-cell action">
                            <IconButton
                              disabled={readOnly}
                              icon="ios-trash"
                              className="button-delete"
                              title="Remove device"
                              onClick={() => handleDeleteDevice(original, deviceIndex)}
                              iconSize="small"
                            />
                          </div>
                        ))}
                    </CellStyled>
                  ),
                },
              ]
            : []),
        ]}
        data={orderItems}
        defaultPageSize={5}
        defaultSorted={[
          {
            id: 'product.product.name',
            desc: false,
          },
        ]}
        showRowPerPage={false}
        showPager={false}
      />
    )
  }
}

const CellStyled = styled.div`
  position: relative;
  > div {
    padding: 0.4rem 5px;
  }
  &.device {
    padding-top: 22px;
  }
  .sub-cell {
    padding: 0.25rem;
    position: relative;
    line-height: 16px;
    height: 24px;
    .button-delete {
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      padding: 0.25rem !important;
    }
  }
`

const InputStyled = styled(Input)`
  width: 100px;
  height: 15px !important;
  font-size: 11px !important;
  margin: 3px 0 !important;
  background: rgb(239, 239, 239) !important;
  color: #cc0092 !important;
`

export default inject('orderRepository', 'orderItemRepository', 'shippingRateRepository')(observer(ProductsTable, {}))
