/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import AttributeForm from './AttributeForm'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

class AttributeAdd extends Component {
  componentDidMount() {
    this.props.productAttributeRepository.clearCrudData()
  }

  render() {
    return (
      <ContentContainer
        title={`Add new Attribute`}
        promptOnExit={this.props.productAttributeRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <AttributeForm {...this.props} />
      </ContentContainer>
    )
  }
}

export default decorate(inject('productAttributeRepository')(observer(AttributeAdd)), {})
