import { mobxEmailList } from 'helpers/Validation'

import { formatDecimalValByCustomer } from 'helpers/Formatters'
import { PAYMENT_METHODS } from 'definitions'

const rules = 'required'

export const getCreateFields = () => {
  return {
    id: {
      type: 'hidden',
    },
    customer: {
      type: 'select',
      label: 'Customer',
      rules,
    },
    orderType: {
      type: 'select',
      label: 'Order Type',
      rules,
    },
    poNumber: {
      label: 'PO Number',
    },
    orderNumberSource: {
      label: 'Order Id',
    },
    skipSNWarrantyInd: {
      type: 'checkbox',
      label: 'Skip SN Warranty Check',
    },
  }
}

export const getFields = ({ data, requireQuotationDaysValidFor, validatePoNumber, virtualProductOnlyInd }) => {
  const {
    id,
    customer = null,
    billingAddress = {},
    shippingAddress = {},
    store = null,
    orderType = null,
    poNumber,
    skipSNWarrantyInd,
    noShipmentInd,
    noShipmentReason,
    noWarrantyInd,
    orderDate,
    groups = null,
    orderItems = [],
    shippingMethod,
    payment = {},
    others = {},
    discount = {},
    discountApproval = {},
    orderSubscription = {},
    orderMailing = {},
    orderItemPricingRules = [],
    dealId,
    internalRemarks = [],
    customerComments = [],
    referralCode,
    quotationDaysValidFor = 30,
    loanSubscriptionDays,
    rebateMSRPInd,
    coTermCustomerRemarks,
    postpaidHardwareInd,
  } = data

  const selectedShippingMethod = (shippingMethod ? shippingMethod : customer && customer.shippingMethod) || {}

  const fields = {
    id: {
      type: 'hidden',
      value: id || null,
    },
    attachmentUniqueId: {
      type: 'hidden',
    },
    paymentAttachmentUniqueId: {
      type: 'hidden',
    },
    discount: {
      type: 'hidden',
      value: discount || {},
      fields: {
        discountInd: {
          value: discount.discountInd || 'ND',
        },
        percentage: {
          label: 'Discount Rate (% off from the applicable price)',
          placeholder: 'Enter % Discount',
          type: 'number',
          rules: 'max:100|min:0',
          value: discount.percentage ? discount.percentage : '',
        },
      },
    },
    customer: {
      type: 'select',
      label: 'Customer',
      value: customer,
    },
    addressBillingSelection: {
      type: 'select',
      label: 'Customer Address',
      value: Object.keys(billingAddress).length ? billingAddress : null,
    },
    contactBillingSelection: {
      type: 'select',
      label: 'Customer Contact',
      value: Object.keys(billingAddress).some(key => key === 'firstName') ? billingAddress : null,
    },
    addressShippingSelection: {
      type: 'select',
      label: 'Customer Address',
      value: Object.keys(shippingAddress).length ? shippingAddress : null,
    },
    contactShippingSelection: {
      type: 'select',
      label: 'Customer Contact',
      value: Object.keys(shippingAddress).some(key => key === 'firstName') ? shippingAddress : null,
    },
    store: {
      type: 'select',
      label: 'Store',
      value: store,
    },
    orderType: {
      type: 'select',
      label: 'Order Type',
      value: orderType,
    },
    poNumber: {
      label: 'PO Number',
      value: poNumber,
      ...(validatePoNumber ? { validators: [validatePoNumber] } : {}),
    },
    skipSNWarrantyInd: {
      type: 'checkbox',
      label: 'Skip SN Warranty Check',
      value: skipSNWarrantyInd,
    },
    noShipmentInd: {
      type: 'checkbox',
      label: 'No shipment',
      value: noShipmentInd,
    },
    noShipmentReason: {
      type: 'select',
      placeholder: 'No Shipment Reason',
      value: noShipmentReason,
      validators: [requireNoShipmentReason],
    },
    noWarrantyInd: {
      type: 'checkbox',
      label: 'Prime - No warranty, IC2 only',
      value: noWarrantyInd,
    },
    orderDate: {
      type: 'datepicker',
      label: 'Order Date',
      value: orderDate || new Date(),
    },
    groups: {
      type: 'select',
      label: 'Customer Groups',
      value: groups,
    },
    billingAddress: {
      fields: {
        company: {
          type: 'text',
          label: 'Company',
          value: billingAddress.company || customer.name,
        },
        firstName: {
          type: 'text',
          label: 'First Name',
          value: billingAddress.firstName || null,
        },
        middleName: {
          type: 'text',
          label: 'Middle Name',
          value: billingAddress.middleName || null,
        },
        lastName: {
          type: 'text',
          label: 'Last Name',
          value: billingAddress.lastName || null,
        },
        email: {
          type: 'email',
          label: 'Email',
          value: billingAddress.email || null,
        },
        telNo: {
          type: 'text',
          label: 'Telephone',
          value: billingAddress.telNo || null,
        },
        streetAddress1: {
          type: 'text',
          label: 'Address Line 1',
          value: billingAddress.streetAddress1 || null,
          rules: 'max:35',
        },
        streetAddress2: {
          type: 'text',
          label: 'Address Line 2',
          value: billingAddress.streetAddress2 || null,
          rules: 'max:35',
        },
        city: {
          type: 'text',
          label: 'City',
          value: billingAddress.city || null,
        },
        zipCode: {
          type: 'text',
          label: 'ZIP/Postal Code',
          value: billingAddress.zipCode || null,
        },
        country: {
          type: 'text',
          label: 'Country',
          value: billingAddress.country || null,
        },
        state: {
          type: 'text',
          label: 'State',
          value: billingAddress.state || null,
        },
        sanctioned: {
          type: 'hidden',
          value: billingAddress.customerContact ? billingAddress.customerContact.sanctioned : false,
        },
      },
    },
    shippingAddress: {
      fields: {
        company: {
          type: 'text',
          label: 'Company',
          value: shippingAddress.company || customer.name,
        },
        firstName: {
          type: 'text',
          label: 'First Name',
          value: shippingAddress.firstName || null,
        },
        middleName: {
          type: 'text',
          label: 'Middle Name',
          value: shippingAddress.middleName || null,
        },
        lastName: {
          type: 'text',
          label: 'Last Name',
          value: shippingAddress.lastName || null,
        },
        email: {
          type: 'email',
          label: 'Email',
          value: shippingAddress.email || null,
        },
        telNo: {
          type: 'text',
          label: 'Telephone',
          value: shippingAddress.telNo || null,
          rules: '',
        },
        streetAddress1: {
          type: 'text',
          label: 'Address Line 1',
          value: shippingAddress.streetAddress1 || null,
          rules: !virtualProductOnlyInd ? 'max:35' : '',
        },
        streetAddress2: {
          type: 'text',
          label: 'Address Line 2',
          value: shippingAddress.streetAddress2 || null,
          rules: !virtualProductOnlyInd ? 'max:35' : '',
        },
        city: {
          type: 'text',
          label: 'City',
          value: shippingAddress.city || null,
        },
        zipCode: {
          type: 'text',
          label: 'ZIP/Postal Code',
          value: shippingAddress.zipCode || null,
        },
        country: {
          type: 'text',
          label: 'Country',
          value: shippingAddress.country || null,
        },
        state: {
          type: 'text',
          label: 'State',
          value: shippingAddress.state || null,
        },
        sanctioned: {
          type: 'hidden',
          value: shippingAddress.customerContact ? shippingAddress.customerContact.sanctioned : false,
        },
      },
    },
    orderItems: {
      value: orderItems,
    },
    shippingMethod: {
      type: 'radio',
      label: 'Shipping Method',
      fields: {
        method: {
          name: 'shippingMethod.method',
          value: selectedShippingMethod.method || null,
        },
        shippingInsurance: {
          type: 'number',
          label: 'Shipping Insurance Amount',
          placeholder: '0',
          value: selectedShippingMethod.shippingInsurance
            ? formatDecimalValByCustomer(selectedShippingMethod.shippingInsurance, customer)
            : '',
        },
        shippingInsuranceInd: {
          type: 'checkbox',
          label: 'Shipping Insurance',
          placeholder: '0',
          value: selectedShippingMethod.shippingInsuranceInd || null,
        },
        totalShippingFee: {
          label: 'Total Shipping fee',
          placeholder: '0',
          value: selectedShippingMethod.totalShippingFee
            ? formatDecimalValByCustomer(selectedShippingMethod.totalShippingFee, customer)
            : '',
          type: 'number',
        },
        providedMethod: {
          type: 'select',
          label: 'Provided Method',
          value: selectedShippingMethod.providedMethod || null,
        },
        shippingCarrier: {
          label: 'Shipping Method',
          type: 'select',
          value: selectedShippingMethod.shippingCarrier || null,
        },
        shippingService: {
          label: 'Shipping Service',
          type: 'select',
          value: selectedShippingMethod.shippingService || null,
        },
        accountNo: {
          label: 'Account Number',
          value: selectedShippingMethod.accountNo || 'N/A',
        },
        contactInfo: {
          type: 'textarea',
          label: 'Additional Information',
          value: selectedShippingMethod.contactInfo || 'N/A',
        },
      },
    },
    payment: {
      fields: {
        amount: {
          label: 'Payment Amount',
          value: payment.amount || null,
          readOnly: true,
        },
        paymentMethod: {
          label: 'Payment Method',
          value: payment.paymentMethod,
        },
        surcharge: {
          label: 'Payment Surcharge Amount',
          placeholder: '0',
          value: payment.surcharge ? formatDecimalValByCustomer(payment.surcharge, customer) : null,
          type: 'number',
        },
        surchargeInd: {
          type: 'checkbox',
          label: 'Include Payment Surcharge',
          value: payment.surchargeInd,
        },
        bankAccount: {
          type: 'select',
          label: 'Bank Account Name',
          value: payment.bankAccount || null,
          default: null,
          readOnly: true,
        },
        paymentTerms: {
          label: 'Payment Terms',
          value: payment.paymentTerms || null,
        },
        attachments: {
          value: payment.attachments ? payment.attachments : [],
        },
      },
    },
    others: {
      fields: {
        comment: {
          type: 'textarea',
          label: 'Comment',
          value: others.comment || null,
        },
      },
    },
    coupon: {
      type: 'text',
      label: 'Enter Discount Code',
    },
    orderSubscription: {
      fields: {
        duration: {
          label: 'Duration',
          value: orderSubscription.duration,
        },
        recurringPeriod: {
          label: 'Recurring Period',
          value: orderSubscription.recurringPeriod,
        },
        noOfInstallment: {
          label: 'No. of Installments',
          value: orderSubscription.noOfInstallment,
        },
        subscriptionStartDate: {
          type: 'datepicker',
          label: 'Subscription Start Date',
          value: orderSubscription.subscriptionStartDate,
        },
        upfrontPayment: {
          type: 'number',
          label: 'Upfront Payment',
          value: orderSubscription.upfrontPayment,
        },
        requireLoanOrder: {
          type: 'hidden',
          value: orderSubscription.requireLoanOrder || false,
        },
      },
    },
    orderMailing: {
      fields: {
        billings: {
          value: orderMailing.billings ? orderMailing.billings.join('\n') : null,
          validators: [mobxEmailList],
        },
        shippings: {
          value: orderMailing.shippings ? orderMailing.shippings.join('\n') : null,
          validators: [mobxEmailList],
        },
      },
    },
    internalRemarks: {
      value: internalRemarks && internalRemarks.length ? internalRemarks[0] : null,
    },
    customerComments: {
      value: customerComments && customerComments.length ? customerComments[0] : null,
    },
    pricingRules: {
      label:
        // eslint-disable-next-line max-len
        'Please note that these price rules have been applied on the above items, you can cancel them here by removing the tag(s) respectively.',
      placeholder: 'Select Pricing Rule',
      value: orderItemPricingRules,
    },
    quotationDaysValidFor: {
      type: 'number',
      label: 'Please select the days for validation',
      placeholder: 'Select days',
      validators: [requireQuotationDaysValidFor],
      value: quotationDaysValidFor,
    },
    discountApproval: {
      fields: {
        approver: {
          label: 'Please select the respective approvers',
          type: 'select',
          value: discountApproval.approver || null,
        },
        deleteAttachments: {
          value: [],
        },
        attachments: {
          value: discountApproval.attachments || [],
        },
        comment: {
          label: 'Reason',
          type: 'textarea',
          value: discountApproval.comment || '',
        },
        noApprovalRequired: {
          label: 'No Approval Required',
          type: 'checkbox',
          value: discountApproval.noApprovalRequired || false,
        },
      },
    },
    paymentDeleteAttachments: {
      value: [],
    },
    dealId: {
      value: dealId,
      label: 'Deal ID',
    },
    manualShippingFeeInd: {
      value: false,
    },
    referralCode: {
      value: referralCode,
      label: 'Referral Code',
    },
    loanSubscriptionDays: {
      type: 'number',
      value: loanSubscriptionDays,
      label: 'LOAN Subscription days',
      placeholder: 'eg: 30, 60 or 365',
    },
    rebateMSRPInd: {
      type: 'checkbox',
      value: rebateMSRPInd,
      label: 'Input Rebate MSRP',
    },
    coTermCustomerRemarks: {
      type: 'textarea',
      value: coTermCustomerRemarks,
    },
    postpaidHardwareInd: {
      type: 'checkbox',
      value: postpaidHardwareInd,
      label: 'Postpaid Hardware',
    },
  }

  if (payment.paymentMethod && fields.payment.fields.paymentMethod.value) {
    switch (payment.paymentMethod.value) {
      case PAYMENT_METHODS.CREDIT_CARD:
        if (payment.transactionId) {
          fields.payment.fields.paymentMethod.value.label = `${payment.paymentMethod.label} (${payment.transactionId})`
        } else if (payment.invoiceId) {
          fields.payment.fields.paymentMethod.value.label = `${payment.paymentMethod.label} (${payment.invoiceId})`
        }
        break
      case PAYMENT_METHODS.PAYPAL_PAYMENT:
        if (payment.paypalCaptureAuthorizationId) {
          // eslint-disable-next-line max-len
          fields.payment.fields.paymentMethod.value.label = `${payment.paymentMethod.label} (${payment.paypalCaptureAuthorizationId})`
        }
        break
    }
  }

  return fields
}

const requireNoShipmentReason = ({ form, field }) => {
  if (!field.value && !!form.$('noShipmentInd').value) {
    return [false, 'No Shipment Rason is required']
  }

  return [true]
}
