import React from 'react'

import cx from 'classnames'

import {
  PRODUCT_NATURE_PHYSICAL,
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_NO_SN,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
} from 'definitions'
import { PRODUCTS_PATH } from 'definitions'

export const getProductOptions = () => {
  const sort = { id: 'name' }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${PRODUCTS_PATH}/${original.id}`

  return {
    title: 'Products',
    moreUrl: PRODUCTS_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        {
          headerClassName: 'px-0 product-nature-header',
          accessor: 'productNature',
          resizable: false,
          sortable: false,
          width: 20,
          fixed: 'leftmost',
          getProps: (state, { original = {} } = {}) => ({
            className: cx({
              'border-info': original.productNatureValue === PRODUCT_NATURE_PHYSICAL || !original.productNatureValue,
              'border-primary': original.productNatureValue === PRODUCT_NATURE_VIRTUAL,
              'border-danger': original.productNatureValue === PRODUCT_NATURE_VIRTUAL_NO_SN,
              'border-success': original.productNatureValue === PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
            }),
            style: {
              padding: 0,
              borderLeft: '5px solid',
            },
          }),
          Cell: () => '',
        },
        {
          Header: 'Product Code',
          accessor: 'productCode',
          fixed: 'left',
          Cell: ({ original }) => {
            const productCode = original.tempOrderNumber || original.productCode || 'N/A'
            return (
              <span title={productCode} className="pr-2 ellipsis row-clickable">
                {productCode}
              </span>
            )
          },
        },
        {
          Header: 'Product Name',
          accessor: 'name',
          Cell: ({ original }) => (
            <span title={original.name} className="pr-2 ellipsis row-clickable">
              {original.name}
            </span>
          ),
        },
        {
          accessor: 'series',
          Header: 'Series',
          headerClassName: 'justify-content-center',
          className: 'justify-content-center',
        },
        {
          accessor: 'parentCategory',
          Header: 'Parent Category',
          Cell: ({ original }) => (
            <span title={original.parentCategory} className="pr-2 ellipsis row-clickable">
              {original.parentCategory}
            </span>
          ),
        },
        {
          accessor: 'childCategory',
          Header: 'Child Category',
          Cell: ({ original }) => (
            <span title={original.childCategory} className="pr-2 ellipsis row-clickable">
              {original.childCategory}
            </span>
          ),
        },
      ],
      sort,
    },
  }
}
