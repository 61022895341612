import { Button } from 'reactstrap'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import ToasterNotify from 'helpers/ToasterNotify'

import { Visible } from 'helpers/Auth'

import CustomFieldModal from './CustomFieldModal'
import Field from './AttributeField'
class CustomFields extends Component {
  state = {
    isModalOpen: false,
  }
  handleSubmit = () => {
    const { form, initializeAttributeField, productAttributeDictionary } = this.props
    const newCustomFields = form.$('newCustomField').values()

    newCustomFields &&
      newCustomFields.forEach(item => {
        const customFields = form.$('customFields')

        if (!customFields.has(item.key)) {
          const metadata = initializeAttributeField(
            { name: item.attributeName },
            productAttributeDictionary[item.attributeName]
          )
          customFields.add(metadata)
        }
      })

    this.toggleModal()
  }
  removeCustomField = key => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove custom field?`,
      title: 'Warning',
      onConfirm: () => {
        const { form } = this.props

        //remove from form
        const customFields = form.$('customFields')
        customFields.has(key) && customFields.del(key)
      },
    })
  }
  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen })
  render() {
    const { productNature, form, group, readOnly } = this.props

    return (
      <>
        {form.$('customFields').map(field => (
          <Field
            productNature={productNature}
            key={`attributeField${field.key}`}
            field={field}
            group={group}
            readOnly={readOnly}
            className="flex-grow-1"
            isCustomField
            deleteField={() => this.removeCustomField(field.key)}
          />
        ))}
        <Visible toAppAdminRole>
          {!readOnly && (
            <div className="d-flex justify-content-center mt-3 button-container">
              <Button color="light" className="d-flex-center" onClick={this.toggleModal}>
                <i className="icon ion-md-add large mr-2 add" />
                Add Custom Fields
              </Button>
              <CustomFieldModal
                form={form}
                group={group}
                open={this.state.isModalOpen}
                onClose={this.toggleModal}
                onConfirm={this.handleSubmit}
              />
            </div>
          )}
        </Visible>
      </>
    )
  }
}

export default observer(CustomFields)
