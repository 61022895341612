import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { observer } from 'mobx-react'

import { allowedTo } from 'helpers/Auth'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class SetStoreProductPriceModal extends Component {
  constructor(props) {
    super(props)

    const fields = [
      'storeProducts',
      'storeProducts[].product',
      'storeProducts[].product.name',
      'storeProducts[].product.id',
      'storeProducts[].product.productCode',
      'storeProducts[].price',
      'storeProducts[].price.amount',
      'storeProducts[].price.currency',
    ]
    const labels = { 'storeProducts[].product.name': 'Product Name', 'storeProducts[].price.amount': 'Price' }
    const rules = { 'storeProducts[].price.amount': 'required|numeric|min:1' }
    const values = { storeProducts: [] }
    const types = { 'storeProducts[].price.amount': 'number' }

    this.form = new Form(
      { fields, rules, labels, values, types },
      {
        name: 'SetStoreProductPriceModal',
        hooks: {
          onSuccess: form => this.handleSubmit(form),
        },
      }
    )
  }

  handleSubmit = form => {
    const { storeProducts } = form.values()
    const {
      storeProductRepository: { create },
      match: {
        params: { id },
      },
      toggleModal,
      reloadTable,
    } = this.props

    Promise.all(storeProducts.map(sp => create({ storeId: id, ...sp }))).then(() => {
      toggleModal()
      reloadTable()
    })
  }

  renderStoreProduct = storeProduct => (
    <Row key={storeProduct.id}>
      <Col sm="8">
        <ReadOnlyField value={storeProduct.$('product.name').value} label={storeProduct.$('product.name').label} />
      </Col>
      <Col sm="4">
        <Input field={storeProduct.$('price.amount')} />
      </Col>
      {/* <hr /> */}
    </Row>
  )

  componentWillUnmount() {
    this.form.$('storeProducts').reset()
  }

  componentDidMount() {
    // MobX React Form array fields are weird.
    const storeProducts = this.form.$('storeProducts')
    const products = []
    this.props.selectedProducts.forEach(product => {
      // You would think the method `.add` would add an item to the array field
      // It does not. It adds a new entry to the array w/o setting values
      storeProducts.add()
      // Which is why we collect all the values here
      products.push({ product, price: { amount: 0, currency: this.props.store.currency } })
    })
    // and subsequently set the exact same amount of values
    // to the same amount of fields. I really don't know anymore
    storeProducts.set(products)
  }

  render() {
    const { isModalOpen, toggleModal } = this.props

    return (
      <Modal
        size="lg"
        title="Set Product Price"
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={() => this.form.submit()}
        hideConfirm={!allowedTo()}
      >
        <FormContainer onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })} errors={[]} plain>
          {this.form.$('storeProducts').map(this.renderStoreProduct)}
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(observer(SetStoreProductPriceModal), {})
