import React from 'react'

import { Button, FormGroup, Label } from 'reactstrap'
import { debounce } from 'lodash'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import styled from 'styled-components'

class QuantityField extends React.Component {
  constructor(props) {
    super(props)

    this.debouncedOnChange = debounce(props.onChange, 350)
    this.state = {
      val: props.value,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ val: nextProps.value })
    }
  }

  handleChange = val => {
    this.setState({
      val,
    })
    this.debouncedOnChange(val)
  }

  render() {
    const { val } = this.state
    const { readOnly, label, className, name, minimum = 1 } = this.props
    return (
      <FormGroupStyled key={name} className={classNames('m-0', className)}>
        {label && <Label>{label}</Label>}
        {!readOnly && (
          <ButtonStyled
            size="xs"
            color="inverse"
            type="button"
            onClick={() => {
              if (val > minimum) {
                this.handleChange(val - 1)
              }
            }}
          >
            <i className="icon ion-md-remove tiny" />
          </ButtonStyled>
        )}
        {readOnly ? (
          <div className="static-text d-flex justify-content-center">{val}</div>
        ) : (
          <input
            type="text"
            value={val}
            onChange={event => {
              let newValue = event.target.value
              if (newValue < minimum) {
                newValue = minimum
              }
              this.handleChange(newValue)
            }}
          />
        )}
        {!readOnly && (
          <ButtonStyled
            size="xs"
            color="inverse"
            type="button"
            onClick={() => {
              this.handleChange(val + 1)
            }}
          >
            <i className="icon ion-md-add tiny" />
          </ButtonStyled>
        )}
      </FormGroupStyled>
    )
  }
}
const FormGroupStyled = styled(FormGroup)`
  input,
  .static-text {
    border: none;
    margin: 0 2px;
    padding: 4px;
    text-align: center;
  }
  input {
    width: 60px;
  }
`

const ButtonStyled = styled(Button)`
  width: 15px !important;
  padding: 1px !important;
  height: 15px !important;
  display: inline-block !important;
  vertical-align: middle !important;
  background-color: #f2f2f2 !important;
  border-radius: 50% !important;
`

export default observer(QuantityField)
