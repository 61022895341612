import { Badge, Button, Col, Row } from 'reactstrap'
import cx from 'classnames'
import React from 'react'

import { titleize } from 'helpers/String'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'

const FilterButton = ({ status, className, selected, handleFilter, ...rest }) => {
  return (
    <Button
      size="xs"
      style={{ width: '80px' }}
      className={cx(className, selected === status && 'selected')}
      onClick={() => handleFilter('access', status)}
      {...rest}
    >
      {titleize(status)}
    </Button>
  )
}

export default ({ selectedView, selected, handleChangeView, handleFilter }) => (
  <Row className="mb-2">
    {selectedView.key !== 'country' && (
      <Col>
        <FilterButton color="dark" status="all" selected={selected} handleFilter={handleFilter} />
        <FilterButton
          status="blacklist"
          color="black"
          selected={selected}
          className="mx-3"
          handleFilter={handleFilter}
        />
        <FilterButton status="whitelist" color="white" outline selected={selected} handleFilter={handleFilter} />
      </Col>
    )}
    <Col className="d-inline-flex align-items-center justify-content-end">
      <h6 className="mr-2 mb-0">
        <Badge color="light-dark" className="text-uppercase px-3 badge-display">
          {selectedView.label}
        </Badge>
      </h6>
      <ContextMenu
        plain
        dropDownToggle={<IconButton icon="md-apps" title="Toggle View" />}
        menus={[
          {
            text: 'Country',
            onClick: () => handleChangeView('country'),
          },
          {
            text: 'Customer',
            onClick: () => handleChangeView('customer'),
          },
        ]}
      />
    </Col>
  </Row>
)
