/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import CollectionServices, { CollectionItemServices } from 'services/collection'

class CollectionRepository extends BaseRepository {
  constructor(service: CollectionServices) {
    super(service)
    this.service = service
  }

  Description = { ...defaults.CRUD }

  resetDescription = async () => {
    this.Description = { ...defaults.CRUD }
  }

  getDescription = async (collectionName, callback) => {
    this.clearErrorMessages()
    this.Description.loading = true
    const { data, errors } = await this.service.getDescription(collectionName)
    if (data && !errors.length) {
      this.Description.data = data
      if (callback) callback(data)
    } else {
      this.Description.errors = errors
    }
    this.Description.loading = false
  }

  patchDescription = async (collectionName, description, callback) => {
    this.clearErrorMessages()
    this.Description.loading = true
    const { data, errors } = await this.service.patchDescription(collectionName, description)
    if (data && !errors.length) {
      this.Description.data = data
      if (callback) callback(data)
    } else {
      this.Description.errors = errors
    }
    this.Description.loading = false
  }
}

export class CollectionItemRepository extends CollectionRepository {
  constructor(service: CollectionItemServices) {
    super(service)
    this.service = service
  }
}

export default decorate(CollectionRepository, {
  Description: observable,
})
