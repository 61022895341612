import { Button, Col, CustomInput } from 'reactstrap'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import { formatNumber } from 'helpers/Formatters'
import { ListBodyStyled, ListHeaderStyled, ListItemStyled, ListStyled } from 'styled/List'
import { PRODUCTS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import AddProductsModal from './AddProductsModal'
import IconButton from 'components/Common/Buttons/IconButton'
import ToasterNotify from 'helpers/ToasterNotify'

class CustomOptionsPanel extends Component {
  state = {
    isModalOpen: false,
  }
  constructor(props) {
    super(props)
    this.field = this.props.form.$('customOptions')
  }
  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen })
  handleAdd = selection => {
    const customOptions = this.field.value

    //Excluded self and selected products
    const productsId = customOptions.reduce(
      (list, current) => list.concat((current.products || []).map(item => item.id)),
      []
    )
    const newProducts = selection.filter(item => productsId.findIndex(itemId => itemId === item.id) === -1)

    if (newProducts.length > 0) {
      newProducts.forEach(newProduct => {
        newProduct.status = 'Active'
        const newProductCategory = newProduct.customOptionTitle

        const customOption = customOptions.find(option => option.category && option.category === newProductCategory)
        if (customOption) {
          if (customOption.products) {
            customOption.products.push(newProduct)
          } else {
            customOption.products = [newProduct]
          }
        } else {
          customOptions.push({
            category: newProductCategory,
            products: [newProduct],
          })
        }
      })
      this.field.set(customOptions)
    }
    this.toggleModal()
  }
  handleDelete = (category, product) => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove '${product.name}'?`,
      title: 'Warning',
      onConfirm: () => {
        const customOptions = this.field.value
        const customOptionIndex = customOptions.findIndex(option => option.category === category)
        if (customOptionIndex >= 0) {
          const customOption = customOptions[customOptionIndex]
          customOption.products = customOption.products.filter(item => item.id !== product.id)

          //remove group if there's no more products
          if (!customOption.products.length) {
            customOptions.splice(customOptionIndex, 1)
          }

          this.field.set(customOptions)
        }
      },
    })
  }
  handleChangeStatus = (event, category, product) => {
    const customOptions = this.field.values()
    const customOption = customOptions.find(option => option.category === category)
    if (customOption) {
      const { products = [] } = customOption
      const productFound = products.find(item => item.id === product.id)
      if (productFound) {
        productFound.status = event.target.checked ? 'Active' : 'Inactive'
        this.field.set(customOptions)
      }
    }
  }
  render() {
    const { readOnly } = this.props
    const customOptions = this.field.value || []

    return (
      <RelatedProductsPanelStyled>
        {customOptions.length > 0 && (
          <ListStyled className="list">
            <ListHeaderStyled>
              <ListItemStyled>
                <Col sm="2">Product Code</Col>
                <Col>Product Name</Col>
                <Col sm="2">Series</Col>
                <Col sm="1">Type</Col>
                <Col sm="1" className="justify-content-center">
                  MOQ
                </Col>
                <Col sm="2" className="justify-content-center">
                  Linkage
                </Col>
                <Col sm="1" className="justify-content-center">
                  Action
                </Col>
              </ListItemStyled>
            </ListHeaderStyled>
            {customOptions.map((option, optionKey) => {
              return (
                option.products &&
                !!option.products.length && (
                  <React.Fragment key={`customOption${optionKey}`}>
                    <ListHeaderStyled>
                      <ListItemStyled className="sub-heading">
                        <Col>{option.category || 'Unknown Category'}</Col>
                      </ListItemStyled>
                    </ListHeaderStyled>
                    <ListBodyStyled>
                      {option.products.map((product, index) => {
                        return (
                          <ListItemStyled key={`customOptionProduct${index}`}>
                            <Col sm="2">
                              <Link to={`${PRODUCTS_PATH}/${product.productId || product.id}`}>
                                {product.productCode}
                              </Link>
                            </Col>
                            <Col>
                              <Link to={`${PRODUCTS_PATH}/${product.productId || product.id}`}>{product.name}</Link>
                            </Col>
                            <Col sm="2">{product.productSeries}</Col>
                            <Col sm="1">{product.productType}</Col>
                            <Col sm="1" className="justify-content-center">
                              {formatNumber(product.moq, 0)}
                            </Col>
                            <Col sm="2" className="justify-content-center">
                              <CustomInput
                                type="switch"
                                id={`switchLinkage${product.id}`}
                                label={product.status === 'Active' ? 'ON' : 'OFF'}
                                className="mr-2"
                                defaultChecked={product.status === 'Active'}
                                onChange={event => this.handleChangeStatus(event, option.category, product)}
                                disabled={readOnly}
                                readOnly={readOnly}
                              />
                            </Col>
                            <Visible toAppAdminRole>
                              <Col sm="1" className="p-1 text-center">
                                {!readOnly && (
                                  <IconButton
                                    icon="ios-trash"
                                    title="Remove custom option"
                                    onClick={() => this.handleDelete(option.category, product)}
                                  />
                                )}
                              </Col>
                            </Visible>
                          </ListItemStyled>
                        )
                      })}
                    </ListBodyStyled>
                  </React.Fragment>
                )
              )
            })}
          </ListStyled>
        )}
        <Visible toAppAdminRole>
          {readOnly && (
            <div className="d-flex-center mx-auto" style={{ height: '120px' }}>
              <p className="text-dark">To add custom options, click Edit Product</p>
            </div>
          )}
          {!readOnly && (
            <Button color="light" className="d-flex-center mt-3 mx-auto" onClick={this.toggleModal}>
              <i className="icon ion-md-add large mr-2 add" />
              Add Custom Options
            </Button>
          )}
          <AddProductsModal
            isModalOpen={this.state.isModalOpen}
            toggleModal={this.toggleModal}
            handleSubmit={this.handleAdd}
            isCustomOptions
          />
        </Visible>
      </RelatedProductsPanelStyled>
    )
  }
}

const RelatedProductsPanelStyled = styled.div`
  .icon.add {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    color: ${props => props.theme.black};
    background-color: ${props => props.theme.lightDark};
  }
  a {
    color: inherit;
  }
  .list {
    margin: 0 -1.25rem;
  }
`

export default observer(CustomOptionsPanel)
