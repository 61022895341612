import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { ORDER_SOURCE } from 'definitions'
import ConfigurableOptionForm from './ConfigurableOptionForm'
import CustomOptionsTable from './CustomOptionsTable'
import Modal from 'components/Common/Modal'
import OrderItemsTable from './OrderItemsTable'

class OrderItemModal extends Component {
  form = null
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      step: 1,
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.loadPostpaidHardwareInvalidProductCodes()
    }
  }
  loadPostpaidHardwareInvalidProductCodes = () => {
    const { commonRepository, order, form } = this.props
    if (order.orderSource === ORDER_SOURCE.MANUAL && form.$('postpaidHardwareInd').value) {
      commonRepository.getTableItemCollection(
        'postpaidHardwareInvalidProductCodes',
        'postpaidHardwareInvalidProductCodes/items'
      )
    } else {
      if (commonRepository.TableItems.collections?.postpaidHardwareInvalidProductCodes) {
        commonRepository.TableItems.collections.postpaidHardwareInvalidProductCodes = []
      }
    }
  }
  handleNextStep = step => {
    this.setState({ step })
  }
  handleSubmit = () => {
    const selected = this.state.selected

    this.props.handleSelected(selected, () => {
      this.setState({
        selected: null,
        step: 1,
      })
    })
  }
  toggleCustomOptionSelected = (e, item) => {
    const customOptionValues = [...this.state.selected.customOptionValues]
    const isInSelected = customOptionValues.map(sel => sel.productCode).includes(e.target.value)
    if (e.target.checked && !isInSelected) {
      customOptionValues.push(item)
    } else {
      const selectedIndex = customOptionValues.map(sel => sel.productCode).indexOf(item.productCode)
      customOptionValues.splice(selectedIndex, 1)
    }

    this.setState({
      selected: {
        ...this.state.selected,
        customOptionValues,
      },
    })
  }
  handleSelected = rowData => {
    let selected
    if (rowData && rowData.id) {
      selected = {
        ...rowData,
        quantity: 1,
        product: {
          ...rowData.product,
          id: rowData.id,
          productId: rowData.product.id,
        },
        customOptions: rowData.customOptions,
        customOptionsInd: rowData.customOptions != null && rowData.customOptions.length > 0,
        customOptionValues: [],
      }
    }
    this.setState({ selected })
  }
  handleConfigureOptions = (vals, type) => {
    let selected = { ...this.state.selected }
    selected = {
      ...this.state.selected,
      ...(!selected.hasOwnProperty('attributes') && { attributes: [] }),
    }

    if (!selected.attributes.map(attr => attr.attributeName).includes(type)) {
      selected.attributes.push({
        attributeName: type,
        ...vals,
      })
    } else {
      const attrIndex = selected.attributes.findIndex(attr => attr.attributeName === type)
      selected.attributes[attrIndex] = {
        ...selected.attributes[attrIndex],
        ...vals,
      }
    }
    this.setState({ selected })
  }
  render() {
    const { selected, step } = this.state
    const { open, onClose, store = null, isCustomOptions = true, customer } = this.props
    const isStep1 = step === 1
    const selectedHasCustomOptions =
      selected &&
      ((selected.customOptions && selected.customOptions.length) ||
        (selected.product &&
          selected.product.productType &&
          selected.product.productType.value.toLowerCase() === 'configurable product'))

    const isConfigurableProduct =
      selected &&
      selected.product &&
      selected.product.productType &&
      selected.product.productType.value.toLowerCase() === 'configurable product'
    return (
      <Modal
        open={open}
        size={!isStep1 && isConfigurableProduct ? 'md' : 'lg'}
        title={isStep1 ? 'Select a Product' : `Configure ${!isConfigurableProduct ? 'Custom' : ''}  Options`}
        confirmLabel={
          isStep1 && selectedHasCustomOptions && isCustomOptions
            ? `Configure ${!isConfigurableProduct ? 'Custom' : ''} Options`
            : 'Add Product(s)'
        }
        closeLabel={isStep1 && selectedHasCustomOptions && isCustomOptions ? 'Cancel' : 'Back'}
        disabled={!selected}
        confirmIcon=""
        onConfirm={
          isStep1 && selectedHasCustomOptions && isCustomOptions
            ? () => this.handleNextStep(2)
            : () => {
                this.handleSubmit()
              }
        }
        onClose={!isStep1 && selectedHasCustomOptions ? () => this.handleNextStep(1) : onClose}
        hideConfirm={!allowedToOrderRole()}
      >
        {isStep1 ? (
          <OrderItemsTable
            store={store}
            handleRowSelect={this.handleSelected}
            selected={selected}
            customer={customer}
          />
        ) : isConfigurableProduct ? (
          <ConfigurableOptionForm data={selected} handleConfigureOptions={this.handleConfigureOptions} />
        ) : (
          <CustomOptionsTable
            data={selected}
            toggleSelected={this.toggleCustomOptionSelected}
            selected={selected.customOptionValues}
          />
        )}
      </Modal>
    )
  }
}

export default decorate(inject('commonRepository', 'storeRepository')(observer(OrderItemModal)), {})
