import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import CardField from './PermissionsPanel/CardField'
import Checkbox from 'components/Common/Form/Checkbox'

class MiniStoreSettingsPanel extends Component {
  handleAddStore = () => {}
  handleDeleteStore = () => {}
  render() {
    const { storeRepository, commonRepository, customerGroupRepository, customerRepository, form, readOnly } =
      this.props

    const isReferralProductInd = form.$('permission.referralProductInd').value

    return (
      <React.Fragment>
        <Checkbox
          field={form.$('permission.referralProductInd')}
          className="pl-4 mb-2"
          readOnly={readOnly}
          disabled={readOnly}
        />
        {isReferralProductInd && (
          <React.Fragment>
            <CardField
              elementKey="productAvailableStores"
              search={storeRepository.reactSelectSearch}
              field={form.$('ministorePermission.availableStores')}
              readOnly={readOnly}
              valueKey="id"
              labelKey="name"
            />
            <CardField
              elementKey="productExcludedCountries"
              search={params => commonRepository.reactSelectSearch(params, 'country')}
              field={form.$('ministorePermission.excludedCountries')}
              readOnly={readOnly}
            />
            <CardField
              elementKey="productDisableCustomerGroups"
              search={params => customerGroupRepository.reactSelectSearch({ ...params, sort: 'name,asc' })}
              field={form.$('ministorePermission.disableCustomerGroups')}
              readOnly={readOnly}
              valueKey="id"
              labelKey="name"
            />
            <CardField
              elementKey="productHidePriceFromCustomerGroups"
              search={params => customerGroupRepository.reactSelectSearch({ ...params, sort: 'name,asc' })}
              field={form.$('ministorePermission.hidePriceFromCustomerGroups')}
              readOnly={readOnly}
              valueKey="id"
              labelKey="name"
            />
            <CardField
              search={customerRepository.customerReactSelectSearch}
              field={form.$('ministorePermission.availableCustomers')}
              readOnly={readOnly}
              valueKey="id"
              labelKey="customerLabel"
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default inject(
  'storeRepository',
  'commonRepository',
  'customerGroupRepository',
  'customerRepository'
)(observer(MiniStoreSettingsPanel))
