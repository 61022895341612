import React from 'react'

import { Badge, Col, Row } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import Input from 'components/Common/Form/Input'

export default observer(({ form, readOnly }) => {
  return (
    <MailingListStyled title="Email CC List" className="mb-2">
      <Row>
        <Col className="d-flex flex-column">
          <Badge color="light" className="px-3 mb-2 align-self-start">
            Billing
          </Badge>
          <InputStyled
            readOnly={readOnly}
            field={form.$('orderMailing.billings')}
            type="textarea"
            className={cx(readOnly && 'h-100')}
            inputClassName="border"
            rows="4"
            readOnlyClassName={cx('readonly-field', readOnly && 'slim-vertical-scroll-bar')}
            style={
              readOnly
                ? { minHeight: '98px', height: '100%', whiteSpace: 'pre-line' }
                : { minHeight: '98px', whiteSpace: 'pre-line' }
            }
          />
        </Col>
        <Col className="d-flex flex-column">
          <Badge color="light" className="px-3 mb-2 align-self-start">
            Shipping
          </Badge>
          <InputStyled
            readOnly={readOnly}
            field={form.$('orderMailing.shippings')}
            type="textarea"
            className={cx(readOnly && 'h-100')}
            inputClassName="border"
            rows="4"
            readOnlyClassName={cx('readonly-field', readOnly && 'slim-vertical-scroll-bar')}
            style={
              readOnly
                ? { minHeight: '98px', height: '100%', whiteSpace: 'pre-line' }
                : { minHeight: '98px', whiteSpace: 'pre-line' }
            }
          />
        </Col>
      </Row>
    </MailingListStyled>
  )
})

const MailingListStyled = styled(Card)`
  .label-mail {
    width: 85px;
  }
`

const InputStyled = styled(Input)`
  &&&& {
    .readonly-field {
      min-height: 100px;
      max-height: 250px;
      text-overflow: clip;
      white-space: pre-wrap;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .text-area {
      height: 100px;
    }
  }
`
