import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import Modal from '../Common/Modal'

import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'

class CollectionCreateModal extends Component {
  constructor(props) {
    super(props)

    const fields = {
      description: {
        type: 'textarea',
        label: 'Description',
        value: props.description,
      },
    }

    this.form = new Form({ fields }, { name: 'CollectionDescriptionForm', handleSubmit: this.handleSubmit })
  }

  handleSubmit = form => {
    const {
      collectionRepository: { patchDescription },
      collectionName,
      toggle,
    } = this.props
    const { description } = form.values()

    patchDescription(collectionName, description, toggle)
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  render() {
    const { collectionRepository, open, toggle } = this.props
    const {
      CRUD: { loading, errors },
    } = collectionRepository

    return (
      <Modal
        size="md"
        open={open}
        title="Edit Collection Description"
        onClose={toggle}
        onConfirm={this.triggerSubmit}
        disabled={loading}
      >
        <FormContainer onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })} errors={errors} plain>
          <InputText field={this.form.$('description')} />
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default inject('collectionRepository')(observer(CollectionCreateModal))
