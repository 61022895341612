/* eslint-disable max-len */
import React from 'react'

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.3375 6.76914C5.5875 6.52539 4.78125 6.58164 4.06875 6.93789C3.35625 7.29414 2.83125 7.91289 2.56875 8.66289C2.04375 10.2191 2.90625 11.9066 4.4625 12.4316C4.7625 12.5254 5.08125 12.5816 5.4 12.5816C5.86875 12.5816 6.31875 12.4691 6.73125 12.2629C7.44375 11.9066 7.96875 11.2879 8.23125 10.5379C8.7375 9.00039 7.89375 7.29414 6.3375 6.76914Z"
      fill="white"
    />
    <path
      d="M13.0875 4.12525C12.4312 3.919 11.7375 3.9565 11.1187 4.27525C10.5 4.594 10.0312 5.119 9.825 5.77525C9.375 7.144 10.1062 8.6065 11.475 9.0565C11.7375 9.15025 12.0187 9.18775 12.2812 9.18775C12.675 9.18775 13.0687 9.094 13.4437 8.9065C14.0625 8.58775 14.5312 8.06275 14.7375 7.4065C15.1875 6.03775 14.4562 4.57525 13.0875 4.12525Z"
      fill="white"
    />
    <path
      d="M19.5937 4.06899C19.05 3.88149 18.45 3.91899 17.9437 4.20024C17.4187 4.46274 17.0437 4.91274 16.8562 5.45649C16.6687 6.00024 16.725 6.60024 16.9875 7.10649C17.25 7.63149 17.7 8.00649 18.2437 8.19399C18.4687 8.26899 18.6937 8.30649 18.9187 8.30649C19.2562 8.30649 19.575 8.23149 19.875 8.08149C20.4 7.81899 20.775 7.36899 20.9625 6.82524C21.15 6.28149 21.0937 5.70024 20.8312 5.17524C20.5875 4.63149 20.1375 4.25649 19.5937 4.06899Z"
      fill="white"
    />
    <path
      d="M24.2625 7.96887C23.3625 7.66887 22.3875 8.15637 22.0875 9.05637C21.9375 9.48762 21.975 9.95637 22.1812 10.3689C22.3875 10.7814 22.7437 11.0814 23.175 11.2314C23.3625 11.2876 23.5312 11.3251 23.7187 11.3251C24.4312 11.3251 25.1062 10.8751 25.35 10.1439C25.65 9.24387 25.1625 8.26887 24.2625 7.96887Z"
      fill="white"
    />
    <path
      d="M26.8312 12.8252C26.5687 12.7314 26.2687 12.7502 26.0062 12.8814C25.7437 13.0127 25.5562 13.2377 25.4625 13.5189C25.275 14.0814 25.5937 14.7002 26.1562 14.8877C26.2687 14.9252 26.3812 14.9439 26.4937 14.9439C26.9437 14.9439 27.375 14.6627 27.525 14.1939C27.7125 13.6314 27.4125 13.0127 26.8312 12.8252Z"
      fill="white"
    />
    <path
      d="M11.6813 12.9751H16.5C17.2688 12.9751 17.9438 13.0876 18.5438 13.2938C19.125 13.5001 19.6125 13.8188 19.9688 14.2126C20.325 14.6063 20.6063 15.0563 20.775 15.5813C20.9625 16.1063 21.0375 16.6876 21.0375 17.3251C21.0375 18.0376 20.925 18.6563 20.7 19.2001C20.475 19.7438 20.1563 20.1751 19.725 20.5313C19.2938 20.8876 18.8063 21.1313 18.225 21.3188C17.6438 21.4876 16.9875 21.5813 16.2563 21.5813H14.2313V26.0438H11.6813V12.9751ZM15.9375 19.2751C16.725 19.2751 17.325 19.0876 17.7375 18.7313C18.15 18.3751 18.3563 17.8876 18.3563 17.2688C18.3563 16.6501 18.15 16.1626 17.7563 15.8063C17.3438 15.4501 16.7813 15.2626 16.0313 15.2626H14.2125V19.2751H15.9375V19.2751Z"
      fill="white"
    />
  </svg>
)
