import { decorate, observable } from 'mobx'
import Keycloak from 'keycloak-js'

import { CHECK_LOGIN } from 'definitions'
import PeplinkIdServices from 'services/peplinkId'

class KeycloakStore {
  keycloakConfig = {
    realm: process.env.REACT_APP_PEPLINK_SSO_REALM,
    clientId: process.env.REACT_APP_PEPLINK_SSO_CLIENT_ID,
    url: `${process.env.REACT_APP_PEPLINK_SSO_SERVER_URL}auth`,
    resource: process.env.REACT_APP_PEPLINK_SSO_CLIENT_ID,
    'ssl-required': process.env.REACT_APP_PEPLINK_SSO_SSL_REQUIRED,
    'public-client': process.env.REACT_APP_PEPLINK_SSO_PUBLIC_CLIENT,
    'confidential-port': process.env.REACT_APP_PEPLINK_SSO_CONFIDENTIAL_PORT,
    'enable-cors': process.env.REACT_APP_PEPLINK_SSO_ENABLE_CORS,
  }

  keycloak = new Keycloak(this.keycloakConfig)

  constructor() {
    this.peplinkIdService = new PeplinkIdServices(process.env.REACT_APP_PEPLINK_SSO_SERVER_URL, this.keycloak)
  }

  initialize = async ({ onAuthenticated, onAuthError, onAuthLogout }) => {
    let authenticated
    let error

    this.keycloak.onAuthError = onAuthError
    this.keycloak.onAuthLogout = onAuthLogout

    try {
      this.initializing = true
      authenticated = await this.keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}${CHECK_LOGIN}`,
      })
    } catch (err) {
      error = err
    } finally {
      this.initializing = false
    }

    if (authenticated) {
      onAuthenticated(this.keycloak)
    } else if (error) {
      onAuthError(error)
    }

    return { authenticated, error }
  }

  getClientApps = () => {
    return this.peplinkIdService.get(process.env.REACT_APP_PEPLINK_SSO_CLIENTS_PATH)
  }

  getClientAppUrl = appId => {
    return this.peplinkIdService.get(
      `${process.env.REACT_APP_PEPLINK_SSO_CLIENTS_REDIRECT_PATH.replace('{appId}', appId)}`
    )
  }
}

export default decorate(KeycloakStore, {
  initializing: observable,
})
