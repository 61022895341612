import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { FormGroup } from 'reactstrap'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'

class FscIdModal extends Component {
  constructor(props) {
    super(props)

    const {
      selectedOrderItem: { fscId },
      readOnly,
    } = props
    const fields = {
      fscId: {
        label: !readOnly ? 'FSC ID' : '',
        value: fscId,
        rules: 'required',
        placeholder: 'Please enter your FSD ID here',
      },
    }

    this.form = new Form({ fields }, { name: 'FscId', handleSubmit: this.handleSubmit })
  }

  handleSubmit = () => {
    const { fscId } = this.form.values()
    this.props.handleSubmit(fscId)
    this.props.onClose()
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  render() {
    const { open, onClose } = this.props
    return (
      <Modal title="Enter FSC ID" open={open} closeLabel="Cancel" onClose={onClose} onConfirm={this.triggerSubmit}>
        <FormContainer
          onSubmit={e => {
            e.stopPropagation()
            this.form.onSubmit(e, { onSuccess: this.handleSubmit })
          }}
          plain
        >
          <FormGroup>
            <Input field={this.form.$('fscId')} />
          </FormGroup>
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('orderItemRepository')(observer(FscIdModal)), {})
