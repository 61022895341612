import React from 'react'

import styled from 'styled-components'

export default ({ children, ...rest }) => {
  return <ButtonLinkStyled {...rest}>{children}</ButtonLinkStyled>
}

const ButtonLinkStyled = styled.button`
  outline: none !important;
  border: none !important;
  background: transparent !important;
  padding: 0;
`
