import { Badge, Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap'
import { components } from 'react-select'
import { observer } from 'mobx-react'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import {
  PRODUCT_NATURE_PHYSICAL,
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_NO_SN,
  PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
} from 'definitions'
import { ReactSelectStyled } from './ProductTable.styled'
import { sort } from 'helpers/Array'
import IconButton from 'components/Common/Buttons/IconButton'

class Filters extends React.Component {
  state = {
    action: null,
    selectedValue: null,
    productNature: { value: 'all', label: 'All' },
  }
  productNatures = []

  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
    this.fetchProductNature()
  }

  initializeDefaultFilters = () => {
    const { searchRepository, pageKey } = this.props
    const filters = searchRepository.Pages[pageKey]?.filters
    if (filters?.productNature) {
      this.state = { ...this.state, productNature: filters?.productNature }
    }
  }

  fetchProductNature = async () => {
    const { quotation, commonRepository } = this.props
    if (!quotation) {
      await commonRepository.getCommon('ProductNature', 'productNature')
      if (commonRepository.Common.ProductNature?.data?.length) {
        this.productNatures = sort(commonRepository.Common.ProductNature.data, 'label')
      }
    }
  }

  handleChangeDisplay = action => {
    this.setState({ action })
  }

  handleChangeValue = value => {
    const { action } = this.state
    switch (action) {
      case 'orderProcess':
        this.setState({ selectedValue: value ? { id: value } : null })
        break
      default:
        this.setState({ selectedValue: value })
        break
    }
  }

  handleFilterProductNature = (selection, action) => {
    let newProductNature

    if (selection?.length) {
      const hasAll = selection.some(item => item.value === 'all')
      if (action.action === 'select-option' && action.option.value !== 'all' && hasAll) {
        const newSelection = selection.filter(item => item.value !== 'all')
        newProductNature = newSelection
      } else if (action.action === 'select-option' && action.option.value === 'all') {
        newProductNature = { value: 'all', label: 'All' }
      } else {
        newProductNature = selection
      }
    } else {
      newProductNature = { value: 'all', label: 'All' }
    }

    this.setState({ productNature: newProductNature })
    this.props.handleFilter('productNature', newProductNature)
  }

  handleSubmit = () => {
    const { showEditMoqModal, handleBulkUpdate } = this.props
    const { action, selectedValue } = this.state
    switch (action) {
      case 'moq':
        showEditMoqModal()
        break
      case 'orderProcess':
      case 'storeDisplay':
        selectedValue != null && handleBulkUpdate(action, selectedValue)
        break
      default:
        break
    }
  }

  render() {
    const { action, selectedValue } = this.state
    const { stores, commonRepository, selectedView, handleChangeView, toggleModal } = this.props

    return (
      <>
        <Row className="mt-3 mb-4">
          <Col>
            <h4 className="mb-1">Products</h4>
          </Col>
          <div className="d-flex justify-content-end align-items-center px-3" style={{ fontSize: '11px' }}>
            <div className="d-flex justify-content-end align-items-center mr-2">
              <ReactSelectStyled
                className="mr-2"
                plain={true}
                options={{
                  placeholder: 'Select Bulk Action',
                  options: [
                    { value: 'moq', label: 'MOQ' },
                    { value: 'orderProcess', label: 'Process Order' },
                    { value: 'storeDisplay', label: 'Store Display' },
                  ],
                }}
                onChange={selected => {
                  if (selected) {
                    this.handleChangeDisplay(selected.value)
                  } else {
                    this.handleChangeDisplay(null)
                  }
                }}
              />
              {this.state.action !== 'storeDisplay' && (
                <ReactSelectStyled
                  plain={true}
                  className="mr-2"
                  serverSide
                  search={params => commonRepository.reactSelectSearch(params, 'productOrderProcess')}
                  disabled={this.state.action !== 'orderProcess'}
                  options={{
                    placeholder: 'Select Option',
                    valueKey: 'id',
                    labelKey: 'label',
                    defaultOptions: true,
                  }}
                  onChange={selected => this.handleChangeValue(selected && selected.id)}
                />
              )}
              {this.state.action === 'storeDisplay' && (
                <ReactSelectStyled
                  plain={true}
                  className="mr-2"
                  options={{
                    placeholder: 'Select Option',
                    options: [
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ],
                  }}
                  onChange={selected => this.handleChangeValue(selected && selected.value)}
                />
              )}
              <Button
                color="dark"
                size="sm"
                onClick={this.handleSubmit}
                disabled={!(action === 'moq' || selectedValue !== null)}
              >
                Submit
              </Button>
            </div>
            <UncontrolledDropdown>
              <DropdownToggle tag="div">
                <IconButton icon="md-apps" title="Toggle View" />
              </DropdownToggle>
              <DropdownMenuStyled>
                <DropdownItem onClick={() => handleChangeView('general')}>General View</DropdownItem>
                <DropdownItem onClick={() => handleChangeView('price')}>List Price</DropdownItem>
                {stores &&
                  stores.map(store => (
                    <DropdownItem key={`menuStore${store.id}`} onClick={() => handleChangeView('store', store)}>
                      {store.name}
                    </DropdownItem>
                  ))}
              </DropdownMenuStyled>
            </UncontrolledDropdown>
            <IconButton icon="md-options" title="Show/Hide Columns" onClick={() => toggleModal('ChooseColumns')} />
            <IconButton icon="md-download" title="Export products" onClick={() => toggleModal('Export')} />
          </div>
        </Row>
        <Row className="mb-3">
          <Col xs="7">
            <ReactSelectStyled
              plain
              className="mr-2 product-nature"
              options={{
                value: this.state.productNature,
                placeholder: 'Filter By Series',
                valueKey: 'value',
                labelKey: 'label',
                isMulti: true,
                options: this.productNatures,
                components: {
                  MultiValue: ({ data, ...innerProps }) => (
                    <components.MultiValue
                      {...data}
                      {...innerProps}
                      className={cx(innerProps.className, {
                        'text-white': data.value !== 'all',
                        'bg-info': data.value === PRODUCT_NATURE_PHYSICAL,
                        'bg-primary': data.value === PRODUCT_NATURE_VIRTUAL,
                        'bg-danger': data.value === PRODUCT_NATURE_VIRTUAL_NO_SN,
                        'bg-success': data.value === PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
                        'bg-secondary': data.value === PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
                      })}
                    />
                  ),
                },
              }}
              onChange={this.handleFilterProductNature}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <h6>
              <Badge color="light-dark" className="text-uppercase px-3 badge-display">
                {selectedView}
              </Badge>
            </h6>
          </Col>
        </Row>
      </>
    )
  }
}

const DropdownMenuStyled = styled(DropdownMenu)`
  &.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
    margin-right: 1.25rem;
  }
`

export default observer(Filters)
