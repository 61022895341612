/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class PaymentServices extends BaseServices {
  api: APIRepository
  publicAPI: APIRepository
  constructor(api: APIRepository, publicApi: APIRepository) {
    const url = '/payments'
    super(api, url)
    this.api = api
    this.url = url
    this.publicAPI = publicApi
    this.publicUrl = `public${url}`
  }
  getPaymentDetails(token) {
    return this.publicAPI.get(`${this.publicUrl}/${token}`)
  }
  submitPayment(payload, token) {
    return this.publicAPI.post(`${this.publicUrl}/pay${token ? `?paymentToken=${token}` : ''}`, payload)
  }
  resendEmail(id) {
    return this.api.post(`${this.url}/resend/${id}`)
  }
  voidPayment(id) {
    return this.api.patch(`${this.url}/void/${id}`)
  }
  patchStripe(token) {
    return this.publicAPI.patch(`${this.publicUrl}/pay/otp${token ? `?paymentToken=${token}` : ''}`)
  }
}
