import React from 'react'

import { formatNumber } from 'helpers/Formatters'
import { PAYMENTS_PATH } from 'definitions'
import PaymentStatus from 'components/Payments/PaymentStatus'

export const getPaymentOptions = () => {
  const sort = { id: 'createdDate', desc: true }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${PAYMENTS_PATH}/${original.id}`

  return {
    title: 'Online Payments',
    moreUrl: PAYMENTS_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        { accessor: 'orderId', Header: 'Order ID' },
        { accessor: 'invoiceNumber', Header: 'Invoice Number' },
        {
          accessor: 'description',
          Header: 'Description',
          Cell: ({ original }) => (
            <span title={original.description} className="pr-2 ellipsis row-clickable">
              {original.description}
            </span>
          ),
        },
        { accessor: 'paymentTo', Header: 'Payment to' },
        {
          accessor: 'amount',
          Header: 'Amount',
          headerClassName: 'justify-content-end pr-3',
          className: 'justify-content-end pr-3',
          Cell: ({ original }) => <div>{`${original.amountCurrency} ${formatNumber(original.amount)}`}</div>,
        },
        {
          accessor: 'status',
          Header: 'Status',
          width: 140,
          Cell: ({ original }) => <PaymentStatus status={original.status} />,
        },
      ],
      sort,
    },
  }
}
