import { Pagination, PaginationItem } from 'reactstrap'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import ButtonLink from 'components/Common/Buttons/ButtonLink'

const PageItem = ({ className, disabled, onClick, children, title }) => {
  return (
    <PaginationItemStyled>
      <ButtonLink className={className} disabled={disabled} onClick={onClick} title={title}>
        {children}
      </ButtonLink>
    </PaginationItemStyled>
  )
}

class Pager extends React.Component {
  handleClickItem = (e, item) => {
    e.preventDefault()
    const { onClickItem } = this.props
    onClickItem(item)
  }
  render() {
    const {
      loading,
      onNextClick,
      onPrevClick,
      onFirstPageClick,
      onLastPageClick,
      totalPages,
      currentPage,
      pagerCount = 10,
    } = this.props
    var start
    var end
    if (currentPage < pagerCount) {
      if (totalPages > pagerCount) {
        start = 1
        end = pagerCount + 1
      } else {
        start = 1
        end = totalPages + 1
      }
    } else if (currentPage + pagerCount - 1 > totalPages) {
      start = totalPages - (pagerCount - 1)
      end = totalPages + 1
    } else {
      start = currentPage - Math.floor(pagerCount / 2)
      end = currentPage + Math.ceil(pagerCount / 2)
    }

    const mappedPages = _.range(start, end).map((item, index) => {
      const active = item === currentPage
      return (
        <PageItem
          key={index}
          className={`page-number ${active ? 'active' : ''}`}
          disabled={active}
          onClick={e => this.handleClickItem(e, item)}
          title={`Go to page ${item}`}
        >
          {item}
        </PageItem>
      )
    })

    return (
      <PagerContainerStyled>
        <Pagination>
          <PageItem onClick={onFirstPageClick} disabled={currentPage === 1 || loading} title="Go to first page">
            ‹‹
          </PageItem>
          <PageItem onClick={onPrevClick} disabled={currentPage === 1 || loading} title="Previous page">
            ‹
          </PageItem>
          {mappedPages}
          <PageItem onClick={onNextClick} disabled={totalPages === currentPage || loading} title="Next page">
            ›
          </PageItem>
          <PageItem onClick={onLastPageClick} disabled={totalPages === currentPage || loading} title="Go to last page">
            ››
          </PageItem>
        </Pagination>
      </PagerContainerStyled>
    )
  }
}

const PagerContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  .pagination {
    display: flex;
    align-items: center;
    margin: 0;
    li {
      display: inline-block;
    }
  }
`
const PaginationItemStyled = styled(PaginationItem)`
  button {
    padding: 5px;
    &:not(.page-number) {
      font-size: 17px;
      margin-top: -2px;
    }
    &:hover,
    &.active {
      color: ${props => props.theme.primary};
    }
    &:disabled:not(.active) {
      color: ${props => props.theme.disabled};
    }
  }
`

export default Pager
