import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { formatNumber } from 'helpers/Formatters'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

class CustomOptionsTable extends Component {
  state = {
    expanded: {},
  }

  isSelectionDisabled = customOption => {
    let {
      commonRepository: {
        TableItems: {
          collections: { postpaidHardwareInvalidProductCodes: { data: postpaidHardwareInvalidProductCodes = [] } = {} },
        },
      },
    } = this.props

    if (postpaidHardwareInvalidProductCodes?.length) {
      return postpaidHardwareInvalidProductCodes.some(item => item.value === customOption.productCode)
    }

    return false
  }

  render() {
    const { data = {}, toggleSelected, selected = [] } = this.props
    return (
      <ReactTable
        className="cell-vertical-center"
        columns={[
          {
            Header: '',
            accessor: 'expander',
            expander: true,
            className: 'justify-content-center p-1',
            sortable: false,
            Expander: ({ isExpanded, ...rest }) => {
              if (!rest.original.values.length) {
                return null
              } else {
                return isExpanded ? (
                  <IconButton icon="md-arrow-dropdown" style={{ height: '28px', width: '28px' }} />
                ) : (
                  <IconButton icon="md-arrow-dropright" style={{ height: '28px', width: '28px' }} />
                )
              }
            },
          },
          {
            Header: 'Custom Option',
            accessor: 'title',
            sortable: false,
            expander: true,
            Expander: ({ original }) => original.title,
            width: 'auto',
            headerClassName: 'py-3',
          },
        ]}
        data={data.customOptions}
        defaultPageSize={10}
        showRowPerPage={false}
        onExpandedChange={(expanded, index, event) => {
          this.setState({ expanded })
        }}
        expanded={this.state.expanded}
        SubComponent={row => {
          return (
            <ReactTable
              showRowPerPage={false}
              className="striped cell-vertical-center mb-0"
              data={row.original.values}
              columns={[
                {
                  Header: '',
                  accessor: 'null',
                  sortable: false,
                  Cell: d => {
                    const { original } = d
                    const isChecked = selected.map(sel => sel.id).includes(original.id)
                    const isDisabled = this.isSelectionDisabled(original)
                    return (
                      <CustomInput
                        type="checkbox"
                        id={`CheckboxCustomOption${data.id}${original.id}`}
                        value={original.id}
                        defaultChecked={isChecked}
                        onChange={e => toggleSelected(e, original)}
                        disabled={isDisabled}
                      />
                    )
                  },
                  width: 50,
                },
                {
                  Header: 'Product Code',
                  accessor: 'productCode',
                  sortable: false,
                  Cell: ({ original }) => {
                    const isDisabled = this.isSelectionDisabled(original)
                    return (
                      <div title={original.productCode}>
                        <div>{original.productCode}</div>
                        {isDisabled && (
                          <div className="text-danger mt-2">
                            <i>{row.original.title} products are disabled</i>
                          </div>
                        )}
                      </div>
                    )
                  },
                },
                {
                  Header: 'Product Name',
                  accessor: 'title',
                  Cell: ({ original }) => <span title={original.title}>{original.title}</span>,
                },

                {
                  Header: 'Listed Price',
                  accessor: 'listedPrice',
                  headerClassName: 'text-right',
                  className: 'justify-content-end',
                  sortable: false,
                  Cell: ({ original }) => {
                    return original.listedPrice ? formatNumber(original.listedPrice) : '-'
                  },
                  width: 120,
                },
              ]}
            />
          )
        }}
      />
    )
  }
}

export default decorate(inject('commonRepository', 'storeRepository')(observer(CustomOptionsTable)), {})
