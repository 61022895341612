import { observer } from 'mobx-react'
import cx from 'classnames'
import React from 'react'

import {
  ATTRIBUTE_NAMES,
  PRODUCT_ATTRIBUTE_GROUP_SPECIFICATION,
  PRODUCT_NATURE_VIRTUAL,
  PRODUCT_NATURE_VIRTUAL_NO_SN,
  PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
  PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
} from 'definitions'
import Checkbox from 'components/Common/Form/Checkbox'
import DatePicker from 'components/Common/Form/DatePicker'
import FieldWrapper from '../FieldWrapper'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

const CustomFieldWrapper = ({ attributeKey, children, readOnly, type, isCustomField, deleteField, hideDelete }) => {
  return (
    <div className="d-flex custom-field">
      {children}
      {!readOnly && !hideDelete && isCustomField && (
        <span className={cx('delete-icon', type === 'boolean' && 'mt-1')}>
          <IconButton icon="ios-trash" title="Delete custom field" onClick={() => deleteField(attributeKey)} />
        </span>
      )}
    </div>
  )
}

export default observer(({ productNature, group, field, className, readOnly, isCustomField, deleteField }) => {
  const { key } = field
  const attribute = field.extra.attributeMetadata || {}
  const { productAttributeGroup: { value: attributeGroup = PRODUCT_ATTRIBUTE_GROUP_SPECIFICATION } = {} } = attribute

  if (!attribute || attributeGroup !== group) {
    return null
  }

  let type = field.type || attribute.attributeType

  let element
  switch (type) {
    case 'boolean':
      element = <Checkbox field={field} id={`switchAttribute-${field.key}`} type="switch" className={className} />
      break
    case 'datetime':
      element = <DatePicker field={field} label={attribute.attributeLabel} className={className} />
      break
    case 'array':
    case 'select':
      element = (
        <ReactSelect
          field={field}
          options={{
            options: attribute.attributeValue
              ? attribute.attributeValue.map(attr => ({ label: attr, value: attr }))
              : [],
            isMulti: attribute.multipleValuesInd,
          }}
          label={attribute.attributeLabel}
          className={className}
          showTooltip={!attribute.multipleValuesInd}
        />
      )
      break
    case 'broken':
      return (
        <CustomFieldWrapper key={key} readOnly={readOnly} isCustomField={isCustomField} deleteField={deleteField}>
          <ReadOnlyField label={key} value="This field is broken" className="w-100" />
        </CustomFieldWrapper>
      )
    case 'textarea':
      element = <Input field={field} label={attribute.attributeLabel} className={className} type="textarea" rows={3} />
      break
    default:
      element = <Input field={field} label={attribute.attributeLabel} className={className} />
      break
  }

  let hideDelete = false
  if (
    [
      PRODUCT_NATURE_VIRTUAL,
      PRODUCT_NATURE_VIRTUAL_NO_SN,
      PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID,
      PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID,
    ].includes(productNature)
  ) {
    if (attribute.attributeName === ATTRIBUTE_NAMES.VIRTUALPRODUCTTYPE) {
      hideDelete = true
    }
  }

  if (isCustomField) {
    return (
      <CustomFieldWrapper
        key={key}
        readOnly={readOnly}
        type={type}
        isCustomField={isCustomField}
        deleteField={deleteField}
        hideDelete={hideDelete}
      >
        <FieldWrapper label={attribute.attributeLabel} className={className} readOnly={readOnly}>
          {element}
        </FieldWrapper>
      </CustomFieldWrapper>
    )
  } else {
    return (
      <FieldWrapper label={attribute.attributeLabel} className={className} readOnly={readOnly}>
        {element}
      </FieldWrapper>
    )
  }
})
