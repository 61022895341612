/* @flow */
import React, { Component } from 'react'

import { Button, Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import CouponFormModal from './Form/CouponFormModal'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class AllCodesTable extends Component {
  table = null
  state = {
    isCouponFormModalOpen: false,
    selected: [],
    bulkAction: null,
    itemToDelete: null,
  }
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    const { couponRepository } = this.props
    if (bulkAction === 'Remove') {
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected code(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await couponRepository.deleteBulk({ coupons: selected.map(id => ({ id })) }, () => {
            this.table.reload()
          })
        },
      })
    }
  }
  handleEdit = selected => {
    this.setState({
      selected,
      isCouponFormModalOpen: !this.state.isCouponFormModalOpen,
    })
  }
  handleCouponFormSuccess = () => {
    this.table.reload()
    this.toggleCouponFormModal()
  }
  toggleCouponFormModal = () =>
    this.setState({
      isCouponFormModalOpen: !this.state.isCouponFormModalOpen,
      selected: !this.state.isCouponFormModalOpen && {},
    })
  render() {
    const { searchRepository } = this.props
    const { bulkAction, selected, isCouponFormModalOpen } = this.state
    const { AllCodesTable, fetchAllCodesTable } = this.props.couponRepository
    return (
      <>
        <ReactTable
          searchRepository={searchRepository}
          pageKey="all-coupon-codes"
          className="striped cell-vertical-center horizontal-scroll"
          multiSelect={allowedToOrderRole()}
          columns={[
            {
              accessor: 'pricingRule.name',
              Header: 'Rule Name',
              Cell: ({ value }) => (
                <span title={value} className="pr-2 ellipsis">
                  {value || '-'}
                </span>
              ),
            },
            { accessor: 'code', Header: 'Code', width: 100 },
            { accessor: 'dealId', Header: 'Deal ID', width: 100 },
            {
              accessor: 'customer.name',
              Header: 'Customer',
            },
            {
              accessor: 'claimableFrom',
              Header: 'Date Starts',
              width: 120,
              Cell: ({ value }) => getFormattedDateTimeString(value),
            },
            {
              accessor: 'claimableTo',
              Header: 'Date Expire',
              width: 120,
              Cell: ({ value }) => getFormattedDateTimeString(value),
            },
          ]}
          data={AllCodesTable.data}
          loadData={fetchAllCodesTable}
          loading={AllCodesTable.loading}
          totalRecordCount={AllCodesTable.totalRecordCount}
          totalPages={AllCodesTable.originalData.totalPages}
          ref={ref => (this.table = ref)}
          actions={
            <Visible toOrderProcessRole>
              <div className="d-flex justify-content-end align-items-center mb-1 px-3">
                <Col lg={6}>
                  <ReactSelect
                    plain={true}
                    className="mr-2"
                    options={{
                      placeholder: 'Bulk Action',
                      options: ['Remove'].map(opt => ({ value: opt, label: opt })),
                    }}
                    disabled={!selected.length}
                    onChange={vals => {
                      this.setState({ bulkAction: vals?.value })
                    }}
                  />
                </Col>
                <Button
                  size="sm"
                  color="dark"
                  type="button"
                  disabled={!bulkAction}
                  onClick={this.handleSubmitBulkAction}
                >
                  Submit
                </Button>
              </div>
            </Visible>
          }
          search
          selectionChanged={this.handleSelected}
          handleRowClick={({ original }) => this.handleEdit(original)}
        />
        {isCouponFormModalOpen && (
          <CouponFormModal
            data={selected}
            open={isCouponFormModalOpen}
            toggleModal={this.toggleCouponFormModal}
            selected={selected}
            onCreateSuccess={this.handleCouponFormSuccess}
            allCodes
          />
        )}
      </>
    )
  }
}

export default decorate(inject('couponRepository', 'searchRepository')(observer(AllCodesTable)), {})
