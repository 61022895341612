/* @flow */

import { action, decorate, observable } from 'mobx'
import { debounce } from 'lodash'
import { FormGroup, FormText, Label, UncontrolledTooltip } from 'reactstrap'
import { observer } from 'mobx-react'
import { withTheme } from 'styled-components'
import Async from 'react-select/lib/Async'
import AsyncCreatable from 'react-select/lib/AsyncCreatable'
import Creatable from 'react-select/lib/Creatable'
import React, { Component } from 'react'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import Select, { components } from 'react-select'
import styled from 'styled-components'

class ReactSelect extends Component<Props> {
  data: Array = []

  constructor(props) {
    super(props)
    const { theme } = props
    this.styles = {
      menu: styles => ({
        ...styles,
        zIndex: 100,
      }),
      control: (styles, { isFocused }) => ({
        ...styles,
        border: 'none',
        borderBottom: props.plain ? null : `1px solid ${theme.inputBorder}`,
        borderRadius: '0',
        boxShadow: isFocused ? '0 0 0 0.2rem rgba(255, 184, 28, 0.25)' : null,
        borderColor: null,
        '&:hover': null,
        minHeight: '35px',
      }),
      valueContainer: styles => ({
        ...styles,
        padding: '0 8px',
      }),
      dropdownIndicator: styles => ({
        ...styles,
        padding: '6px 8px',
      }),
      clearIndicator: styles => ({
        ...styles,
        padding: '6px 8px',
      }),
      indicatorsContainer: styles => ({
        ...styles,
        cursor: 'pointer',
      }),
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled ? null : isSelected ? theme.primary : isFocused ? theme.light : null,
          color: isDisabled ? theme.disabled : isSelected ? theme.white : 'inherit',
          cursor: isDisabled ? 'not-allowed' : 'default',

          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled && (isSelected ? theme.primary : theme.primary),
            color: isDisabled ? theme.disabled : theme.white,
          },
        }
      },
    }

    this.handleSearchSelectDebounced = debounce((input, callback) => {
      const doSearch = async () => {
        const options = await this.handleSearchSelect(input)
        callback && callback(options)
      }
      doSearch()

      // this return statement is required for debounce to work properly with react-select
      return
    }, 350)
  }
  componentDidCatch(e) {
    // workaround for options.reduce issue in react-select
    // eslint-disable-next-line no-console
    console.log(e)
  }

  handleSearchSelect = async (input = '') => {
    const { search, serverSide = false, filterField, options, creatable = false } = this.props
    try {
      if (serverSide) {
        const { data, errors } = await search({
          filterField: filterField,
          search: input,
        })
        if (!errors.length && data && data.content && data.content.length) {
          if (creatable) {
            return data.content.map(d => ({
              ...d,
              label: d[options.labelKey],
              value: d[options.valueKey],
            }))
          } else {
            return data.content || []
          }
        } else {
          if (creatable)
            return [
              {
                [options.labelKey]: `${input}`,
                [options.valueKey]: input,
                label: input,
                value: input,
              },
            ]
          else return []
        }
      } else {
        return options.options || []
      }
    } catch (e) {
      return []
    }
  }

  handleCreatable = input => {
    return input
  }

  render() {
    const {
      classes,
      className = '',
      serverSide = false,
      creatable = false,
      field = {},
      options = {},
      onBeforeChange,
      onChange = null,
      onBlur = null,
      innerClassName = '',
      disabled,
      label: propsLabel,
      plain,
      readOnly,
      readOnlyClassName,
      title,
      innerKey = '', //Hack to force react select to update the options
      showTooltip = false,
    } = this.props
    const { name, value = options.value, placeholder = `Type to search ${field.label || ''}` } = field
    const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
    const label = propsLabel || field.label
    const reactSelectInputId = showTooltip ? `reactSelect${Math.random().toString().split('.')[1]}` : null

    let Component = Select
    let defaultProps = {
      isMulti: false,
      className: innerClassName,
      classNamePrefix: 'react-select',
      hasError: { hasError },
      classes: { classes },
      textFieldProps: {
        label: label,
        InputLabelProps: {
          shrink: true,
        },
      },
      onChange: (vals, action) => {
        onBeforeChange && onBeforeChange(vals, action)
        if (Object.keys(field).length) {
          field.onChange(vals)
          field.validate()
        }
        if (onChange) onChange(vals, action)
      },
      placeholder: placeholder,
      isClearable: true,
      value: value,
      onBlur: onBlur,
      isDisabled: disabled || this.props.options.isDisabled || field.disabled,
      styles: this.styles,
    }

    if (innerKey) {
      defaultProps.key = innerKey
    }

    let valueLabel
    if (field.value) {
      valueLabel = options.labelKey ? field.value[options.labelKey] : field.value.label
    }
    const valueTitle = title || valueLabel

    if (readOnly) {
      Component = () => (
        <ReadOnlyField
          plain
          value={valueLabel}
          title={valueTitle}
          showTooltip={showTooltip}
          className={readOnlyClassName}
        />
      )
    } else if (serverSide && !creatable) {
      Component = Async
      defaultProps = {
        ...defaultProps,
        serverSide: false,
        cacheOptions: false,
        loadOptions: this.handleSearchSelectDebounced,
        getOptionLabel: opt => opt[options.labelKey],
        getOptionValue: opt => opt[options.valueKey],
        noOptionsMessage: ({ inputValue }) =>
          !inputValue && inputValue.length < 3 ? (
            'Type to search'
          ) : (
            <React.Fragment>
              No matching results for the keyword:
              <strong>{inputValue}</strong>
            </React.Fragment>
          ),
      }
    } else if (serverSide && creatable) {
      Component = AsyncCreatable
      defaultProps = {
        ...defaultProps,
        serverSide: true,
        creatable: true,
        cacheOptions: false,
        loadOptions: this.handleSearchSelectDebounced,
        getOptionLabel: opt => opt[options.labelKey],
        getOptionValue: opt => opt[options.valueKey],
      }
    } else if (creatable) {
      Component = Creatable
      defaultProps = {
        ...defaultProps,
        creatable: true,
      }
    } else {
      defaultProps = {
        ...defaultProps,
        ...(options.labelKey ? { getOptionLabel: opt => opt[options.labelKey] } : {}),
        ...(options.valueKey ? { getOptionValue: opt => opt[options.valueKey] } : {}),
      }
    }

    return (
      <React.Fragment>
        {plain ? (
          <Component {...defaultProps} {...this.props.options} className={className} />
        ) : (
          <FormGroup key={name} className={className}>
            {label && <LabelStyled>{label}</LabelStyled>}
            <Component
              {...defaultProps}
              {...this.props.options}
              {...(showTooltip && {
                components: {
                  SingleValue: inputProps => (
                    <components.SingleValue {...inputProps} innerProps={{ id: reactSelectInputId }} />
                  ),
                },
              })}
            />
            {hasError ? <FormText color="danger">{field.error}</FormText> : null}
          </FormGroup>
        )}
        {showTooltip && !options.isMulti && !readOnly && reactSelectInputId && !!valueTitle && (
          <UncontrolledTooltip key={reactSelectInputId} target={reactSelectInputId} autohide={false} placement="bottom">
            {valueTitle}
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    )
  }
}

const LabelStyled = styled(Label)`
  color: #7f7f7f;
  font-size: 10px;
  font-weight: 700;
`

export default withTheme(
  decorate(observer(ReactSelect), {
    data: observable,
    defaults: observable,
    handleCreatable: action,
    handleSearchSelectDebounced: action,
  })
)
