import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PRODUCT_NATURE_PHYSICAL, PRODUCTS_PATH } from 'definitions'
import CloneProductModal from './CloneProductModal'
import CustomOptionsModal from 'components/Products/ProductAdd/CustomOptionsModal'
import Form from 'components/Common/Form'
import Modal from 'components/Common/Modal'

class CloneProduct extends Component {
  state = {
    isCloneProductModalOpen: this.props.open,
    isCustomOptionsModalOpen: false,
    isShowSuccessMessage: false,
  }

  constructor(props) {
    super(props)

    const { data } = props

    const fields = {
      name: {
        label: 'Product Name',
        rules: 'required',
      },
      productCode: {
        label: 'Product Code',
        rules: 'required',
      },
      prices: {
        value: [...data.prices],
      },
      addAsSingleProductInd: {
        type: 'checkbox',
        label: 'Add as Single Product',
        value: false,
      },
      customOptions: {
        value: [],
      },
    }

    this.form = new Form({ fields }, { name: 'CloneProduct', handleSubmit: this.handleSubmit })
  }

  componentWillUnmount() {
    this.props.productRepository.ProductClone.errors = []
  }

  handleSubmit = async () => {
    const { data, productRepository } = this.props
    const { customOptions, productCode, ...rest } = this.form.values()

    const payload = {
      ...rest,
      productCode: productCode.toUpperCase(),
      customOptions: this.isAddCustomOptions() ? customOptions : [],
    }

    await productRepository.cloneProduct(data.id, payload)
    if (!productRepository.ProductClone.errors?.length) {
      this.toggleCloneProductModal()
      this.toggleSuccessMessageModal()
    }
  }

  handNext = () => {
    const { data } = this.props
    const productNature = data.productNature.value

    const addAsSingleProductInd = this.form.$('addAsSingleProductInd').value
    if (!addAsSingleProductInd && productNature === PRODUCT_NATURE_PHYSICAL) {
      this.handleSmartAdd()
    } else {
      this.handleSubmit()
    }
  }

  handleSmartAdd = async () => {
    const { productRepository } = this.props
    const name = this.form.$('name').value
    const productCode = this.form.$('productCode').value

    await productRepository.getSmartAddCustomOptions({ name, productCode })

    if (productRepository.CustomOptions.data && !productRepository.CustomOptions.errors.length) {
      this.toggleCustomOptionsModal()
    }
  }

  handleCloseCloneProductModal = () => {
    this.toggleCloneProductModal()
    this.props.onClose()
  }

  handleCloseSuccessMessageModal = () => {
    this.toggleSuccessMessageModal()
    this.props.onClose()
  }

  isAddCustomOptions = () => {
    const { data } = this.props
    const productNature = data.productNature.value

    const isAddCustomOptions = productNature === PRODUCT_NATURE_PHYSICAL && !this.form.$('addAsSingleProductInd').value
    return isAddCustomOptions
  }

  toggleCloneProductModal = () => this.setState({ isCloneProductModalOpen: !this.state.isCloneProductModalOpen })

  toggleCustomOptionsModal = () => this.setState({ isCustomOptionsModalOpen: !this.state.isCustomOptionsModalOpen })

  toggleSuccessMessageModal = () => this.setState({ isShowSuccessMessage: !this.state.isShowSuccessMessage })

  render() {
    const { productRepository, data } = this.props
    const isAddCustomOptions = this.isAddCustomOptions()

    return (
      <React.Fragment>
        <CloneProductModal
          productRepository={this.props.productRepository}
          data={data}
          form={this.form}
          isAddCustomOptions={isAddCustomOptions}
          open={this.state.isCloneProductModalOpen}
          onSubmit={isAddCustomOptions ? this.handNext : this.handleSubmit}
          onClose={this.handleCloseCloneProductModal}
        />
        {this.state.isCustomOptionsModalOpen && (
          <CustomOptionsModal
            productRepository={this.props.productRepository}
            productForm={this.form}
            onClose={this.toggleCustomOptionsModal}
            onConfirm={() => {
              this.toggleCustomOptionsModal()
              this.handleSubmit()
            }}
          />
        )}
        {this.state.isShowSuccessMessage && (
          <Modal
            title="Product is successfully cloned"
            open
            confirmLabel={
              <ButtonLinkStyled to={`${PRODUCTS_PATH}/${productRepository.ProductClone.data.id}`} target="_blank">
                View Cloned Product
              </ButtonLinkStyled>
            }
            closeLabel="Close"
            onClose={this.handleCloseSuccessMessageModal}
          >
            <div style={{ fontSize: '14px' }}>
              <div>Product is successfully cloned.</div>
              <div>Please note that the PERMISSION details are not part of the cloning.</div>
              <div>Please update it accordingly.</div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    )
  }
}

const ButtonLinkStyled = styled(Link)`
  &&& {
    color: #ffffff !important;
  }
`

export default decorate(inject('productRepository')(observer(CloneProduct)), {})
