import React, { Component } from 'react'

import { observer } from 'mobx-react'

import { FormGroup } from 'reactstrap'
import { PRODUCT_NATURE_PHYSICAL, PRODUCT_TYPE_ACCESSORY } from 'definitions'
import Checkbox from 'components/Common/Form/Checkbox'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'

class CloneProductModal extends Component {
  handleProductCodeOnBlur = event => {
    const value = event.target.value
    if (value) {
      event.target.value = value.toUpperCase()
      this.props.form.$('productCode').set(event.target.value)
    }
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  render() {
    const {
      productRepository: {
        ProductClone: { errors, loading },
      },
      data,
      form,
      isAddCustomOptions,
      open,
      onSubmit,
      onClose,
    } = this.props

    const isShowAddAsSingleProduct =
      data.productNature.value === PRODUCT_NATURE_PHYSICAL && data.productType.value !== PRODUCT_TYPE_ACCESSORY

    return (
      <Modal
        title="Clone Product"
        loading={loading}
        open={open}
        closeLabel="Cancel"
        confirmLabel={isAddCustomOptions ? 'Next' : 'Save'}
        onClose={onClose}
        onConfirm={this.triggerSubmit}
      >
        <FormContainer
          onSubmit={e => {
            e.stopPropagation()
            form.onSubmit(e, { onSuccess: onSubmit })
          }}
          errors={errors}
          plain
        >
          <FormGroup>
            <Input field={form.$('name')} readOnly={loading} />
            <Input field={form.$('productCode')} readOnly={loading} onBlur={this.handleProductCodeOnBlur} />
            {isShowAddAsSingleProduct && (
              <Checkbox
                field={form.$('addAsSingleProductInd')}
                disabled={loading}
                readOnly={loading}
                className="mt-3"
              />
            )}
          </FormGroup>
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default observer(CloneProductModal)
