import React, { Component } from 'react'

import { Nav, NavItem, TabContent, TabPane } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import { ENV_PRODUCTION } from 'definitions'
import ButtonLink from './Buttons/ButtonLink'

class Drawer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: props.activeTab,
      openDrawer: false,
    }
  }

  openDrawer = activeTab => {
    this.setState({
      activeTab,
      openDrawer: true,
    })
  }

  closeDrawer = () => {
    this.setState({
      activeTab: null,
      openDrawer: false,
    })
  }

  render() {
    const { tabs = [], className } = this.props
    const { activeTab, openDrawer } = this.state

    if (tabs.length === 0) {
      return false
    }

    return (
      <DrawerStyled
        className={cx(className, openDrawer && 'active')}
        isProduction={process.env.REACT_APP_ENV === ENV_PRODUCTION}
      >
        <div className="backdrop" onClick={this.closeDrawer} role="button" tabIndex="0" />
        <div ref={ref => (this.sidebarRef = ref)} className="sidebar d-flex">
          <div className="tab-header py-2">
            <Nav vertical>
              {tabs.map(tab => (
                <NavItem key={`tabHeader${tab.key}`} className={`text-center ${activeTab === tab.key ? 'active' : ''}`}>
                  <ButtonLink
                    className="py-2"
                    onClick={() => {
                      this.openDrawer(tab.key)
                    }}
                  >
                    {tab.icon}
                  </ButtonLink>
                </NavItem>
              ))}
            </Nav>
          </div>
          <div className="tab-content flex-grow-1 pt-2 border-left">
            <TabContent activeTab={activeTab} className="p-3">
              {tabs.map(
                tab =>
                  tab.key === activeTab && (
                    <TabPane key={`tabContent${tab.key}`} tabId={tab.key}>
                      {typeof tab.content === 'function' ? tab.content(this) : tab.content}
                    </TabPane>
                  )
              )}
            </TabContent>
          </div>
        </div>
      </DrawerStyled>
    )
  }
}

const DrawerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  z-index: 10;
  .sidebar {
    position: fixed;
    top: ${props => (!props.isProduction ? '76px' : '50px')};
    right: 0;
    width: 350px;
    height: calc(100% - 50px);
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
    transform: translate3d(100%, 0, 0);
    transition: all 0.5s;
    z-index: 10;
    .tab-header {
      position: absolute;
      top: 0;
      left: -50px;
      width: 50px;
      background-color: #fff;
      box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.15);
      border-radius: 4px 0 0 4px;
    }
    .tab-content {
      background-color: #fff;
      overflow-y: auto;
    }
  }
  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 0;
    height: 100%;
    transition:
      opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
      height 0s ease 0.3s;
    opacity: 0;
  }
  &.active {
    width: 100%;
    .sidebar {
      transform: translate3d(0, 0, 0);
      .nav-item.active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    .backdrop {
      opacity: 1;
      width: 100%;
    }
  }
`

export default observer(Drawer)
