/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class ImportServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/import'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export class ImportTypeServices extends ImportServices {
  getById(payload) {
    return this.api.get(`${this.url}/types/${payload}`)
  }
}

export default ImportServices
