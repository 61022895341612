import BaseServices from './BaseServices'

export default class ProhibitedItemServices extends BaseServices {
  constructor(api) {
    const url = '/prohibitedItems'
    super(api, url)
    this.api = api
    this.url = url
  }
  fetchTable = (payload, id) => {
    return this.api.get(`${this.url}/${id}`, payload)
  }
  create = (payload, id) => {
    return this.api.post(`${this.url}/${id}`, payload)
  }
  bulkPost(key, payload) {
    return this.api.post(`${this.url}/${key}`, payload)
  }
}
