/* @flow */

import { action, decorate, observable, toJS } from 'mobx'
import BaseRepository from './BaseRepository'
import CommonServices from 'services/common'

class CommonRepository extends BaseRepository {
  service: CommonServices
  constructor(service: CommonServices) {
    super(service)
    this.service = service
  }

  Currencies = {
    isLoading: false,
    data: [],
  }

  get currencies() {
    return toJS(this.Currencies.data)
  }

  getCurrencies = async callback => {
    this.Currencies.isLoading = true
    const { data, errors } = await this.service.getCurrencies()
    if (data && !errors.length) {
      this.Currencies.data = data.content
      callback && callback(data.content)
    }
    this.Currencies.isLoading = false
  }

  SelectItems = {
    loading: false,
    collections: {},
  }

  TableItems = {
    loading: false,
    collections: {},
  }

  VirtualProductTypes = {
    data: [],
    loading: false,
    errors: [],
  }

  getSelectItemCollection = async (collectionName, isPublic = false) => {
    let data = []
    this.SelectItems.collections[collectionName] = { data, loading: true }

    let response = undefined
    switch (collectionName) {
      case 'currency':
        response = await this.service.getCurrencies()
        break
      case 'shippingMethods':
        response = await this.service.getShippingMethod()
        break
      case 'shippingServices':
        response = await this.service.getShippingServices()
        break
      case 'customOptionPriceType':
        response = await this.service.getCustomOptionPriceType()
        break
      case 'noShipmentReason':
        response = await this.service.getNoShipmentReason()
        break
      default:
        response = await this.service.getByCollectionName(collectionName, isPublic)
        break
    }

    this.SelectItems.collections[collectionName] = { data: response.data, loading: false }
  }

  getSelectItemCollectionByKey = async (key, collectionName, payload) => {
    let data = []
    this.SelectItems.collections[key] = { data, loading: true }

    const response = await this.service.getByCollectionName(collectionName, false, payload)
    this.SelectItems.collections[key] = {
      data: response.data,
      loading: false,
    }
  }

  getTableItemCollection = async (key, collectionName, payload) => {
    this.TableItems.collections[key] = {
      loading: false,
      loadingFailed: false,
      data: [],
      originalData: {},
      errors: [],
    }

    let response = await this.service.getTableItemCollection(collectionName, payload)
    this.TableItems.collections[key] = {
      data: response.data.content,
      loading: false,
      originalData: response.data,
      totalRecordCount: response.data.totalElements,
    }
  }

  reactSelectSearch = async (params, collectionName) => {
    const { data, errors } = await this.service.reactSelectSearch(collectionName, params)
    return { data, errors }
  }

  getVirtualProductTypes = async () => {
    this.VirtualProductTypes.loading = true
    let response = await this.service.getVirtualProductTypes()
    this.VirtualProductTypes = {
      data: response.data,
      loading: false,
      errors: response.errors,
    }
  }
}

export default decorate(CommonRepository, {
  // fetchTable: action,
  getCurrencies: action,
  Currencies: observable,
  SelectItems: observable,
  TableItems: observable,
  getSelectItemCollection: action,
  getVirtualProductTypes: action,
  VirtualProductTypes: observable,
  // setCurrency: action,
})
