import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import { allowedTo } from 'helpers/Auth'
import { COLLECTIONS_PATH } from 'definitions'
import Modal from '../Common/Modal'

import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'

class CollectionCreateModal extends Component {
  constructor(props) {
    super(props)

    const fields = {
      collectionName: {
        type: 'text',
        label: 'Collection Name',
        rules: 'required',
        validators: [this.validateIllegalCharacters],
      },
      description: {
        type: 'textarea',
        label: 'Description',
      },
    }

    this.form = new Form({ fields }, { name: 'CollectionForm', handleSubmit: this.handleSubmit })
  }

  validateIllegalCharacters = ({ field }) => {
    if (field.value) {
      const illegalCharacters = field.value.match(/[^A-Za-z0-9_-\s]*/g).filter(item => item)
      if (illegalCharacters.length) {
        return [false, `'${illegalCharacters.join(', ')}' are not valid characters`]
      } else {
        const hasInvalidSpaces = /(^\s+)|(\s+$)/g.test(field.value)
        if (hasInvalidSpaces) {
          return [false, 'Collection name must not start or end with space']
        }
      }
    }

    return [true, 'Please enter valid collection name']
  }

  handleSubmit = form => {
    const {
      collectionItemRepository: { create },
      history,
    } = this.props
    const values = form.values()
    const { collectionName } = values
    const payload = { ...values, collectionName, label: 'New Label', value: 'New Value' }

    create(payload, () => {
      history.push(`${COLLECTIONS_PATH}/${collectionName}`)
    })
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  render() {
    const { collectionItemRepository, open, toggle } = this.props
    const {
      CRUD: { loading, errors },
    } = collectionItemRepository

    return (
      <Modal
        size="md"
        open={open}
        title="Create new Collection"
        onClose={toggle}
        onConfirm={this.triggerSubmit}
        hideConfirm={!allowedTo()}
        disabled={loading}
      >
        <FormContainer onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })} errors={errors} plain>
          <InputText field={this.form.$('collectionName')} />
          <InputText field={this.form.$('description')} />
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default inject('collectionItemRepository')(observer(CollectionCreateModal))
