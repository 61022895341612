import React, { Component } from 'react'

import { debounce } from 'lodash'
import { formatCustomerCurrency, getFormattedDateString, getFullName } from 'helpers/Formatters'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import Status from 'components/Common/Status'

class ContractPanel extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = debounce(this.handleInputChange, 500)
  }
  handleInputChange = e => {
    const { data } = this.props
    data.orderSubscription[e.name] = e.value
  }

  getLink = ({ orderType, id }) => {
    return {
      pathname: `${SUBSCRIPTIONS_PATH}/${id}`,
      search: `?${orderType.name === 'Subscription Loan Out' ? 'loan' : 'contract'}=true`,
    }
  }

  render() {
    const {
      data: { contracts, customer },
    } = this.props

    return (
      <Card title="Contract">
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'orderNumber',
              Header: 'Order No.',
              sortable: false,
              Cell: ({ original }) => {
                return <Link to={this.getLink(original)}>{original.orderNumber}</Link>
              },
            },
            {
              accessor: 'customer.name',
              Header: 'Customer',
              sortable: false,
            },
            {
              accessor: 'createdDate',
              Header: 'Created On',
              sortable: false,
              Cell: ({ original }) => <span>{getFormattedDateString(original.createdDate)}</span>,
            },

            {
              accessor: 'billingAddress.firstName',
              Header: 'Bill To',
              sortable: false,
              Cell: ({ original }) => getFullName(original.billingAddress),
            },
            {
              accessor: 'store.currency',
              Header: 'Currency',
              sortable: false,
            },
            {
              accessor: 'orderSummaryDto.grandTotal',
              Header: 'Balance Due',
              headerClassName: 'justify-content-end',
              className: 'd-flex justify-content-end',
              sortable: false,
              Cell: ({ original }) => formatCustomerCurrency(original.orderSummaryDto.grandTotal, customer),
            },
            {
              accessor: 'status',
              Header: 'Status',
              headerClassName: 'd-flex justify-content-center',
              className: 'd-flex justify-content-center',
              sortable: false,
              Cell: ({ original }) => <Status status={original.status} />,
            },
            {
              Header: 'Action',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              sortable: false,
              Cell: ({ original }) => (
                <Link to={this.getLink(original)}>
                  <IconButton type="button" icon="md-search" />
                </Link>
              ),
            },
          ]}
          dateColumns={['createdDate']}
          data={contracts}
          showRowPerPage={false}
          defaultSorted={[{ id: 'createdDate', desc: true }]}
        />
      </Card>
    )
  }
}

export default observer(ContractPanel)
