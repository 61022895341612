import { CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'

import { AUTO_RENEWAL_PATH } from 'definitions'
import { formatDate } from 'helpers/Formatters'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

const DATE_FORMAT = 'DD-MMM-YYYY'

class AutoRenewalTable extends React.PureComponent {
  table = React.createRef()
  handlePatchAutoRenewDevice = (e, id) => {
    this.props.deviceManagementRepository.patch(
      {
        autoRenewInd: e.target.checked,
        id,
      },
      () => this.table.current.reload()
    )
  }
  handleAutoRenewChange = async (id, serialNumber, subscriptionProducts, productId, checked) => {
    const { deviceManagementRepository } = this.props
    const payload = {
      id,
      serialNumber,
      subscriptionProducts: subscriptionProducts.map(item => {
        if (item.id === productId) {
          return {
            id: productId,
            autoRenewInd: checked,
          }
        } else {
          return {
            id: item.id,
            autoRenewInd: item.autoRenewInd,
          }
        }
      }),
    }
    const res = await deviceManagementRepository.patchSubscriptionProduct(id, payload)

    if (res) this.table.current.reload()
    else {
      ToasterNotify.alert({
        message: deviceManagementRepository.Table.errors[0].message,
        title: 'Error',
        color: 'error',
        duration: 5000,
      })
    }
  }
  handleChangeView = view => {
    this.setState({ view }, () => {
      if (this.table) this.table.reload()
    })
  }
  renderCell = content => (
    <span title={content} className="pr-2 ellipsis row-clickable">
      {content}
    </span>
  )
  render() {
    const { deviceManagementRepository, searchRepository } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
      fetchTable,
    } = deviceManagementRepository
    const columns = [
      {
        accessor: 'orderNumbers',
        Header: 'Order Number',
        width: '15%',
        style: {
          alignItems: 'start',
        },
        Cell: ({ original }) => this.renderCell(original.orderNumbers),
      },
      {
        accessor: 'customer.name',
        Header: 'Customer Name',
        width: '20%',
        style: {
          alignItems: 'start',
        },
        Cell: ({ original }) =>
          this.renderCell(
            original.customer && (
              <div>
                <p title={original.customer.name} className="ellipsis mb-2">
                  {original.customer.name}
                </p>
                <p title={original.email} className="ellipsis mb-0">
                  {original.email}
                </p>
              </div>
            )
          ),
      },
      {
        accessor: 'product.name',
        Header: 'Device',
        width: '15%',
        style: {
          alignItems: 'start',
        },
        Cell: ({ original }) =>
          this.renderCell(
            original.product && (
              <div>
                <p title={original.product.productCode} className="ellipsis mb-2">
                  {original.product.productCode}
                </p>
                <p title={original.product.name} className="ellipsis mb-0">
                  - {original.product.name}
                </p>
              </div>
            )
          ),
      },
      {
        accessor: 'serialNumber',
        Header: 'Serial Number',
        width: '20%',
        style: {
          alignItems: 'start',
        },
        Cell: ({ original }) => this.renderCell(original.serialNumber),
      },
      {
        Header: 'Product Code',
        sortable: false,
        width: '10%',
        style: {
          alignItems: 'start',
        },
        headerClassName: 'no-sort',
        Cell: ({ original }) => (
          <div className="ellipsis">
            {original.subscriptionProducts
              ? original.subscriptionProducts.map((product, index) => (
                  <div
                    key={index}
                    className={`ellipsis ${
                      original.subscriptionProducts.length - 1 === index ? 'pb-0 mb-0' : `mb-3 pb-3`
                    }`}
                  >
                    <p title={product.productCode} className="mb-1 ellipsis">
                      {product.productCode}
                    </p>
                    <p title={product.name} className="mb-0 ellipsis">
                      -{product.name}
                    </p>
                  </div>
                ))
              : ''}
          </div>
        ),
      },
      {
        accessor: 'subscriptionProducts.renewalDate',
        Header: 'Renewal Date',
        width: '5%',
        style: {
          alignItems: 'start',
        },
        Cell: ({ original }) => (
          <div className="ellipsis">
            {original.subscriptionProducts
              ? original.subscriptionProducts.map((product, index) => (
                  <div
                    key={index}
                    className={`ellipsis ${
                      original.subscriptionProducts.length - 1 === index ? 'pb-0 mb-0' : `mb-3 pb-3`
                    }`}
                    title={formatDate(product.renewalDate, DATE_FORMAT)}
                  >
                    <div className="mb-1 ellipsis" title={formatDate(product.renewalDate, DATE_FORMAT)}>
                      {formatDate(product.renewalDate, DATE_FORMAT)}
                    </div>
                    <p></p>
                  </div>
                ))
              : ''}
          </div>
        ),
      },
      {
        accessor: 'subscriptionProducts.autoRenewInd',
        Header: 'Auto-Renewal',
        width: '5%',
        headerClassName: 'justify-content-center',
        style: {
          alignItems: 'start',
          justifyContent: 'center',
        },
        Cell: ({ original }) => (
          <div>
            {original.subscriptionProducts
              ? original.subscriptionProducts.map((product, index) => (
                  <div
                    key={index}
                    className={original.subscriptionProducts.length - 1 === index ? 'pb-0 mb-0' : `mb-3 pb-3`}
                  >
                    <CustomInput
                      type="switch"
                      id={`autoRenewInd-${original.id}-${product.id}`}
                      name={`autoRenewInd-${original.id}-${product.id}`}
                      checked={product.autoRenewInd}
                      onChange={event =>
                        this.handleAutoRenewChange(
                          original.id,
                          original.serialNumber,
                          original.subscriptionProducts,
                          product.id,
                          event.target.checked
                        )
                      }
                      onClick={event => event.preventDefault()}
                      className="mb-1 d-flex align-items-center"
                      label={product.autoRenewInd ? 'Yes' : 'No'}
                    />
                    <div></div>
                  </div>
                ))
              : ''}
          </div>
        ),
      },
    ]
    return (
      <ReactTable
        searchRepository={searchRepository}
        pageKey="auto-renewal"
        enableRowLink
        getRowUrl={({ original }) => `${AUTO_RENEWAL_PATH}/${original.id}`}
        ref={this.table}
        columns={columns}
        data={data}
        loadData={params => fetchTable({ ...params })}
        loading={loading}
        totalRecordCount={totalRecordCount}
        totalPages={originalData.totalPages}
        search
        className="striped cell-vertical-center"
      />
    )
  }
}

export default decorate(inject('deviceManagementRepository', 'searchRepository')(observer(AutoRenewalTable)), {})
