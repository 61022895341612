import { Badge, Button, Col, CustomInput, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { head } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { PRODUCTS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import cx from 'classnames'
import React from 'react'
import ReactTable from 'components/Common/ReactTable'
import styled from 'styled-components'

const pageKey = 'product-options'

const StatusButton = ({ status, className, selected, handleFilterStatus, ...rest }) => {
  return (
    <Button
      size="xs"
      style={{ width: '80px' }}
      className={cx(className, selected && 'selected')}
      onClick={() => handleFilterStatus(status?.value)}
      {...rest}
    >
      {status?.label || 'All'}
    </Button>
  )
}

class ProductOptions extends React.Component {
  table = React.createRef()
  filters = {}
  state = {
    expanded: {},
  }

  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const defaultFilters = searchRepository.Pages[pageKey]?.filters
    if (defaultFilters) {
      this.filters = defaultFilters
    }
  }

  handleFilterStatus = status => {
    if (status) {
      this.filters.productNature = status
    } else {
      this.filters = {}
    }
    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload()
  }
  handleCheckedChange = e => {
    this.filters.showUnlinked = e.target.checked
    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload()
  }

  getData = params => {
    const {
      productOptionRepository: { fetchTable },
    } = this.props
    fetchTable({ ...params, ...this.filters }).then(() => {
      const data = this.props.productOptionRepository.Table.data
      if (data.length) {
        const defaultExpandedRows = {}
        data.forEach((_, index) => {
          defaultExpandedRows[index] = true
        })
        this.setState({ expanded: defaultExpandedRows })
      }
    })
  }

  render() {
    const {
      productOptionRepository: {
        Table: { data, loading, originalData, totalRecordCount },
      },
      searchRepository,
    } = this.props

    let newData = []
    data.forEach(row => {
      const items = [...row.associatedProducts]
      if (row.associatedProducts.length > 0) {
        row.products = head(items)
      }
    })
    return (
      <ContentContainer title="Product Options">
        <Row className="mb-3">
          <Col>
            <StatusButton
              color="dark"
              selected={!this.filters?.productNature}
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Physical',
                value: 'physical',
              }}
              selected={this.filters?.productNature == 'physical'}
              color="cyan"
              className="mx-2"
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Virtual',
                value: 'virtual',
              }}
              selected={this.filters?.productNature == 'virtual'}
              color="primary"
              handleFilterStatus={this.handleFilterStatus}
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <CustomInput
              id="showUnlinked"
              type="switch"
              label="Show Unlinked"
              defaultChecked={this.filters?.showUnlinked}
              onChange={this.handleCheckedChange}
            />
          </Col>
        </Row>
        <TableStyled
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) => `${PRODUCTS_PATH}/${original.productId}`}
          columns={[
            {
              width: 20,
              sortable: false,
              getProps: (state, { original = {} } = {}) => {
                let classes = ''
                if (original.productNature === 'Virtual') {
                  classes = 'border-primary'
                } else if (original.productNature === 'Physical') {
                  classes = 'border-cyan'
                }
                return {
                  className: classes,
                  style: {
                    padding: 0,
                    ...(original.productNature && { borderLeft: '5px solid' }),
                  },
                }
              },
            },
            {
              accessor: 'productCode',
              Header: 'Product Code',
              width: 200,
            },
            {
              accessor: 'name',
              Header: 'Parent Products',
            },
            {
              Header: 'Associated Products',
              Cell: ({ original }) => {
                return (
                  <React.Fragment>
                    {original.products && (
                      <Link to={`${PRODUCTS_PATH}/${original.products.productId}`}>
                        <Badge>{original.products.productCode}</Badge> <span>{original.products.name}</span>
                      </Link>
                    )}
                  </React.Fragment>
                )
              },
            },
            {
              expander: true,
              width: 20,
              className: 'justify-content-center',
              show: false,
              Expander: ({ isExpanded, ...rest }) => {
                return (
                  <div>
                    {rest.original.associatedProducts.length > 1 && (
                      <React.Fragment>
                        {isExpanded ? (
                          <i className="icon ion-md-arrow-dropdown" />
                        ) : (
                          <i className="icon ion-md-arrow-dropright" />
                        )}
                      </React.Fragment>
                    )}
                  </div>
                )
              },
            },
          ]}
          className="striped cell-vertical-center mb-3"
          sort={{ id: 'productCode' }}
          pageSize={newData.length}
          perPageOptions={[10, 20, 30, 40, 50]}
          defaultPageSize={10}
          data={data}
          loadData={this.getData}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          search
          onExpandedChange={(expanded, index, event) => {
            this.setState({ expanded })
          }}
          expanded={this.state.expanded}
          SubComponent={row => {
            const newItems = [...row.original.associatedProducts]
            newItems.splice(0, 1)
            const items = newItems
            return (
              <React.Fragment>
                {!!items.length && (
                  <ReactTable
                    className="m-0"
                    TheadComponent={() => null}
                    data={items.reverse()}
                    pageSize={items.length}
                    showRowPerPage={false}
                    showPager={false}
                    columns={[
                      {
                        width: 20,
                      },
                      {
                        width: 200,
                      },
                      {},
                      {
                        Header: 'Product Name',
                        accessor: 'name',
                        className: 'pl-3',
                        Cell: ({ original }) => (
                          <React.Fragment>
                            <Link to={`${PRODUCTS_PATH}/${original.productId}`}>
                              <Badge>{original.productCode}</Badge> <span>{original.name}</span>
                            </Link>
                          </React.Fragment>
                        ),
                      },
                      {
                        width: 20,
                      },
                    ]}
                  />
                )}
              </React.Fragment>
            )
          }}
        />
      </ContentContainer>
    )
  }
}
export default decorate(inject('productOptionRepository', 'searchRepository')(observer(ProductOptions)), {})

const TableStyled = styled(ReactTable)`
  &.ReactTable {
    margin-bottom: 0;
  }
  .badge {
    font-size: 11px;
    font-weight: normal;
    color: #555;
    background-color: ${props => props.theme.gray300};
    margin-right: 1rem;
  }
`
