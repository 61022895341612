import React, { Component } from 'react'

import { formatDate } from 'helpers/Formatters'
import Modal from 'components/Common/Modal'

class SfcCloudUnlimitedModal extends Component {
  render() {
    const { open, sfcPlanDetails, onClose, onSubmit } = this.props
    const activeSerialNumbers = sfcPlanDetails.filter(item => item.activeAutoRenewal)

    return (
      <Modal
        title="Please Confirm"
        open={open}
        size="lg"
        centered
        confirmLabel="Proceed"
        onClose={onClose}
        onConfirm={onSubmit}
      >
        <div style={{ fontSize: '14px' }}>
          <div>
            Upon purchasing the Unlimited Plan, any remaining SpeedFusion Connect Usage data from the original plan will
            be overwritten by the unlimited data.
          </div>
          <table className="table table-striped table-sm my-2">
            <thead>
              <tr>
                <th className="border-bottom border-primary">Serial Number</th>
                <th className="border-bottom border-primary"> Expiration Date</th>
              </tr>
            </thead>
            <tbody>
              {sfcPlanDetails.map((item, index) => (
                <tr key={index}>
                  <td className="border-bottom">{item.serialNumber}</td>
                  <td className="border-bottom">{formatDate(item.expirationDate, 'DD-MM-YYYY')}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!!activeSerialNumbers?.length && (
            <div class="mt-2">
              <span className="mr-1">Please note that the auto-renewal setting for your SN:</span>
              {activeSerialNumbers.map((item, index) => (
                <React.Fragment key={item.serialNumber}>
                  <span class="text-primary">{item.serialNumber}</span>
                  {index < activeSerialNumbers.length - 1 && ', '}
                </React.Fragment>
              ))}
              <span className="ml-1">will also deactivate.</span>
            </div>
          )}
        </div>
      </Modal>
    )
  }
}

export default SfcCloudUnlimitedModal
