import { inject, observer } from 'mobx-react'
import React from 'react'

import { USER_ROLE_ACCESS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import Loader from 'components/Common/Loader'
import UserRoleAccessForm from './UserRoleAccessForm'

class UserRoleAccessEdit extends React.Component {
  componentDidMount() {
    this.loadUserRoleAccess()
  }

  loadUserRoleAccess() {
    const {
      match: {
        params: { id },
      },
      userRoleAccessRepository,
    } = this.props

    userRoleAccessRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.userRoleAccessRepository.clearCrudData()
    this.props.userRoleAccessRepository.clearErrorMessages()
  }

  render() {
    const {
      userRoleAccessRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <ContentContainer
        title="Edit User Role Access"
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'User Role Access',
            href: USER_ROLE_ACCESS_PATH,
          },
          {
            label: data ? data.role : '',
          },
        ]}
      >
        <UserRoleAccessForm {...this.props} data={data} isAdd={false} />
      </ContentContainer>
    )
  }
}

export default inject('userRoleAccessRepository')(observer(UserRoleAccessEdit))
