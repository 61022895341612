/* @flow */
import { inject, observer } from 'mobx-react'
import ContentContainer from 'components/Common/ContentContainer'
import PaymentForm from './PaymentForm'
import React, { Component } from 'react'

class PaymentAdd extends Component {
  render() {
    return (
      <ContentContainer
        title="Create New Payment"
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Online Payments',
            href: '/payments',
          },
          {
            label: 'New',
          },
        ]}
        promptOnExit={this.props.paymentRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <PaymentForm {...this.props} />
      </ContentContainer>
    )
  }
}

export default inject('paymentRepository')(observer(PaymentAdd))
