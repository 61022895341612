const rules = 'required'

export const fields = (data = {}) => {
  const {
    prefix = '',
    firstName = '',
    middleName = '',
    lastName = '',
    suffix = '',
    email = '',
    tel = '',
    loginFlag = false,
    status = 'ACTIVE',
    referralCode,
  } = data
  return {
    prefix: {
      type: 'text',
      label: 'Prefix',
      value: prefix ? { label: prefix, value: prefix } : null,
    },
    firstName: {
      type: 'text',
      label: 'First Name',
      rules,
      value: firstName,
    },
    middleName: {
      type: 'text',
      label: 'Middle Name',
      value: middleName,
    },
    lastName: {
      type: 'text',
      label: 'Last Name',
      rules,
      value: lastName,
    },
    suffix: {
      type: 'text',
      label: 'Suffix',
      value: suffix,
    },
    email: {
      type: 'email',
      label: 'Email',
      rules: 'required|email',
      value: email,
    },
    tel: {
      type: 'text',
      label: 'Telephone',
      value: tel,
    },
    loginFlag: {
      type: 'switch',
      label: 'Allow Storefront Login',
      value: loginFlag,
    },
    status: {
      type: 'switch',
      label: 'Active',
      value: status === 'ACTIVE',
      rules,
    },
    referralCode: {
      type: 'text',
      label: 'Referral Code',
      value: referralCode || 'None',
      rules,
    },
  }
}
