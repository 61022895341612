import React, { Component } from 'react'

import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import * as definitions from 'definitions'
import { Visible } from 'helpers/Auth'
import Checkbox from 'components/Common/Form/Checkbox'
import CommonSelect from 'components/Common/CommonSelect'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'

class Actions extends Component {
  getOrderMenu = () => {
    const {
      blacklisted = false,
      data: { status, orderSummary, payment } = {},
      readOnly,
      handleSaveOrder,
      handleEditMode,
      handleCloneOrder,
      handleCancelOrder,
      handleDiscardChanges,
      handleSaveDraft,
      handleSendEmail,
      form,
    } = this.props
    const isNew = !status || status === definitions.ORDER_STATUS_NEW
    const pendingPayment = status === definitions.ORDER_STATUS_PENDING_PAYMENT
    const orderType = form.$('orderType').value || {}
    const isOrderProcessing = status === definitions.ORDER_STATUS_ORDER_PROCESSING
    const isOrderProcessingWithoutTotal = isOrderProcessing && orderSummary.grandTotal !== 0
    const isCreditCard = payment.paymentMethod?.value === definitions.PAYMENT_METHODS.CREDIT_CARD
    const isPaypal = payment.paymentMethod?.value === definitions.PAYMENT_METHODS.PAYPAL_PAYMENT

    return [
      {
        text: 'Edit Order',
        onClick: handleEditMode,
        hidden: !readOnly || blacklisted || isOrderProcessingWithoutTotal,
      },
      {
        text: 'Discard Changes',
        onClick: handleDiscardChanges,
        hidden: readOnly || blacklisted || isOrderProcessingWithoutTotal,
      },
      {
        text: 'Save Draft',
        onClick: handleSaveDraft,
        hidden: readOnly || pendingPayment || blacklisted || isOrderProcessing,
      },
      {
        text: 'Submit Order',
        onClick: handleSaveOrder,
        hidden: blacklisted || isOrderProcessingWithoutTotal,
      },
      {
        text: 'Save As Quotation',
        onClick: () => handleSaveOrder({ isToggleSaveQuotationModal: true }),
        hidden: !(isNew && orderType.name === 'Store') || blacklisted || isOrderProcessingWithoutTotal,
      },
      {
        text: 'Clone Order',
        onClick: handleCloneOrder,
        hidden: !readOnly || blacklisted || isOrderProcessingWithoutTotal,
      },
      {
        text: 'Cancel Order',
        onClick: handleCancelOrder,
        hidden: blacklisted || (isOrderProcessingWithoutTotal && !(isCreditCard || isPaypal)),
      },
      {
        text: 'Send Email',
        onClick: handleSendEmail,
        hidden: !readOnly || isNew || blacklisted,
      },
    ]
  }

  getQuotationMenu = () => {
    const {
      blacklisted = false,
      data: { status } = {},
      readOnly,
      handleSaveOrder,
      handleEditMode,
      handleCloneOrder,
      handleCancelOrder,
      handleDiscardChanges,
      handleSaveDraft,
      handleSubmitAsOrder,
      handleSendProformaInvoice,
      handleSendEmail,
    } = this.props
    const isNew = !status || status === definitions.ORDER_STATUS_NEW
    const approved = status === definitions.ORDER_STATUS_APPROVED_QUOTATION
    const isConverted = status === definitions.ORDER_STATUS_CONVERTED
    return [
      {
        text: 'Edit Quotation',
        onClick: handleEditMode,
        hidden: !readOnly || approved || blacklisted || isConverted,
      },
      {
        text: 'Discard Changes',
        onClick: handleDiscardChanges,
        hidden: readOnly || blacklisted,
      },
      {
        text: 'Save Draft',
        onClick: handleSaveDraft,
        hidden: readOnly || approved || blacklisted,
      },
      {
        text: 'Submit As Quotation',
        onClick: () => handleSaveOrder({ isToggleSaveQuotationModal: true }),
        hidden: (readOnly && (approved || isConverted)) || blacklisted,
      },
      {
        text: 'Convert to Order',
        onClick: handleSubmitAsOrder,
        hidden: !approved || blacklisted || isConverted,
      },
      {
        text: 'Clone Quotation',
        onClick: handleCloneOrder,
        hidden: !(readOnly && (approved || isConverted)) || blacklisted,
      },
      {
        text: 'Cancel Quotation',
        onClick: handleCancelOrder,
        hidden: blacklisted || isConverted,
      },
      {
        text: 'Send Proforma Invoice',
        onClick: handleSendProformaInvoice,
        hidden: !(readOnly && approved) || blacklisted || isConverted,
      },
      {
        text: 'Send Email',
        onClick: handleSendEmail,
        hidden: !readOnly || isNew || blacklisted || isConverted,
      },
    ]
  }

  setDefaultNoShipmentReaason = () => {
    const { commonRepository, form } = this.props

    if (form.$('noShipmentInd').value && !form.$('noShipmentReason').value) {
      const { noShipmentReason: { data: noShipmentReasons = [] } = {} } = commonRepository.SelectItems.collections
      if (noShipmentReasons) {
        const defaultValue = noShipmentReasons.find(item => item.value === definitions.NO_SHIPMENT_REASON_DEFAULT)
        if (defaultValue) {
          form.$('noShipmentReason').set(defaultValue)
        }
      }
    }
  }

  renderPendingStatus = label => {
    return (
      <Badge
        color=""
        className="font-size-11 w-100 text-danger d-inline-flex justify-content-center align-items-center"
        style={{ minWidth: '140px' }}
      >
        <i className="icon ion-md-alert" />
        <span className="ml-2 text-uppercase">{label}</span>
      </Badge>
    )
  }

  renderDefaultStatus = (label, className) => {
    return (
      <Badge
        color={className || 'outline-primary'}
        className="font-size-11 text-uppercase"
        style={{ minWidth: '140px' }}
      >
        {label}
      </Badge>
    )
  }

  renderStatus = row => {
    if (row.status) {
      switch (row.status) {
        case definitions.ORDER_STATUS_PENDING_APPROVAL:
          return this.renderPendingStatus(definitions.ORDER_STATUS_DESC_PENDING_APPROVAL)
        case definitions.ORDER_STATUS_PENDING_PAYMENT:
          return this.renderPendingStatus(definitions.ORDER_STATUS_DESC_PENDING_PAYMENT)
        case definitions.ORDER_STATUS_ORDER_PROCESSING:
          return this.renderDefaultStatus(definitions.ORDER_STATUS_DESC_ORDER_PROCESSING)
        case definitions.ORDER_STATUS_SUBSCRIPTION_MASTER_COPY:
          return this.renderDefaultStatus(definitions.ORDER_STATUS_DESC_SUBSCRIPTION_MASTER_COPY)
        case definitions.ORDER_STATUS_DESC_SUBSCRIPTION_INACTIVE:
          return this.renderDefaultStatus(definitions.ORDER_STATUS_DESC_SUBSCRIPTION_INACTIVE)
        case definitions.ORDER_STATUS_DRAFT_ORDER:
          return this.renderDefaultStatus(definitions.ORDER_STATUS_DESC_DRAFT_ORDER)
        case definitions.ORDER_STATUS_APPROVED_QUOTATION:
          return this.renderDefaultStatus(definitions.ORDER_STATUS_DESC_APPROVED_QUOTATION, 'light')
        case definitions.ORDER_STATUS_CANCELLED:
        case definitions.ORDER_STATUS_DISAPPROVED:
        case definitions.ORDER_STATUS_DELETED:
        case definitions.ORDER_STATUS_COMPLETED:
          return this.renderDefaultStatus(row.status, 'light')
        default:
          return this.renderDefaultStatus(row.status)
      }
    }
    return false
  }

  render() {
    const { blacklisted = false, data = {}, form, quotation, readOnly, loading } = this.props

    const { status, orderSource } = data
    const isDisabled = status === definitions.ORDER_STATUS_CANCELLED
    const noShipmentInd = form.$('noShipmentInd').value

    return (
      <ActionsStyled>
        {/* noWarrantyInd is temporarily unused */}
        {/* <Checkbox
          disabled={readOnly}
          readOnly={readOnly}
          field={form.$('noWarrantyInd')}
          className="mr-3 mt-0 col-auto p-0"
        /> */}
        {orderSource === definitions.ORDER_SOURCE.MANUAL && (
          <Checkbox
            disabled={readOnly}
            readOnly={readOnly}
            field={form.$('postpaidHardwareInd')}
            className="mr-3 mt-0 col-auto p-0"
          />
        )}
        {!!noShipmentInd && (
          <CommonSelect
            field={form.$('noShipmentReason')}
            collectionName="noShipmentReason"
            readOnly={readOnly}
            showTooltip
            options={{ isClearable: false }}
            className="mr-3 my-0"
            readOnlyClassName="no-shipment-reason-read-only"
            onCollectionLoaded={this.setDefaultNoShipmentReaason}
          />
        )}
        <Checkbox
          disabled={readOnly}
          readOnly={readOnly}
          field={form.$('noShipmentInd')}
          className="mr-3 mt-0 col-auto p-0"
          onChange={this.setDefaultNoShipmentReaason}
        />
        <Checkbox
          disabled={readOnly}
          readOnly={readOnly}
          field={form.$('skipSNWarrantyInd')}
          className="mr-3 mt-0 col-auto p-0"
        />
        {status && <h6 className="mb-0">{this.renderStatus(data)}</h6>}
        {loading && <Loader text="" className="ml-2 p-0" />}
        {!blacklisted && !isDisabled && !loading && (
          <Visible toOrderProcessRole>
            <ContextMenu
              className="ml-2"
              plain
              dropDownToggle={<IconButton icon="md-more" className="icon-more" iconSize="large" />}
              menus={quotation ? this.getQuotationMenu() : this.getOrderMenu()}
            />
          </Visible>
        )}
      </ActionsStyled>
    )
  }
}

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width label {
    cursor: pointer;
  }
  .btn-outline-primary {
    width: 150px;
    small {
      font-size: 90%;
      font-weight: 600;
    }
  }
  .dropdown-menu {
    width: 300px;
    button {
      padding-left: 3rem;
    }
  }
  .icon-more {
    color: #bdbdbd;
  }
  .react-select {
    &__control {
      width: 220px;
      border: none;

      .react-select__indicator {
        padding-top: 2px;
        padding-bottom: 2px;
      }
      .react-select__single-value,
      .react-select__placeholder {
        font-size: 11px;
      }
    }
    &__menu {
      width: 300px;
      .react-select__option {
        font-size: 14px;
      }
    }
  }
  .no-shipment-reason-read-only {
    font-size: 11px !important;
  }
`

export default observer(Actions)
