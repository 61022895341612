import React, { Component } from 'react'

import { observer } from 'mobx-react'

import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'

class EditMoqModal extends Component {
  constructor(props) {
    super(props)
    const fields = {
      moq: {
        label: 'Please enter the minimum order quantity',
        type: 'number',
        rules: 'required',
        value: 1,
      },
    }
    this.form = new Form({ fields }, { name: 'MOQ', handleSubmit: this.handleSubmit })
  }
  handleSubmit = () => {
    this.props.onClose()
    this.props.handleSubmit(this.form.$('moq').values())
  }
  render() {
    const { open, onClose } = this.props
    return (
      <Modal open={open} size="sm" title="Edit MOQ" onConfirm={() => this.buttonSubmit.click()} onClose={onClose}>
        <FormContainer onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })} plain>
          <Input field={this.form.$('moq')} />
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default observer(EditMoqModal)
