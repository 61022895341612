import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { components } from 'react-select'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import queryString from 'query-string'
import styled from 'styled-components'

import { COTERM_PATH, ORDER_SOURCE } from 'definitions'
import { formatCustomerCurrency, getFormattedDateString, getFullName } from 'helpers/Formatters'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import Status from 'components/Common/Status'

class CoTerm extends Component {
  filters = {
    sort: 'orderDate,desc',
  }

  constructor(props) {
    super(props)

    this.state = {
      orderSources: { value: 'ALL', label: 'All' },
    }

    this.initializeDefaultFilters()
  }

  initializeDefaultFilters = () => {
    const { searchRepository, pageKey } = this.props

    const filters = searchRepository.Pages[pageKey]?.filters || {}
    this.filters = filters
    this.state = {
      ...this.state,
      orderSources: filters?.orderSources || this.state.orderSources,
    }
  }

  componentDidMount() {
    const { history } = this.props
    const { search } = queryString.parse(this.props.location.search)
    search && history.replace('/co-term', {})
  }

  fetchTable = async params => {
    const { coTermRepository } = this.props
    const { orderSources, ...rest } = this.filters
    const filters = { ...rest }

    if (orderSources?.length) {
      const [item] = orderSources
      if (item.value !== 'ALL') {
        filters.orderSources = orderSources.map(item => item.value).join(',')
      }
    }

    return await coTermRepository.fetchTable({ ...params, ...filters })
  }

  handleCreate = () => {
    this.props.history.push(`${COTERM_PATH}/new`)
  }

  handleChangeOrderSource = (selection, action) => {
    let newOrderSource

    if (selection?.length) {
      const hasAll = selection.some(item => item.value === 'ALL')
      if (action.action === 'select-option' && action.option.value !== 'ALL' && hasAll) {
        const newSelection = selection.filter(item => item.value !== 'ALL')
        newOrderSource = newSelection
      } else if (action.action === 'select-option' && action.option.value === 'ALL') {
        newOrderSource = [{ value: 'ALL', label: 'All' }]
      } else {
        newOrderSource = selection
      }
    } else {
      newOrderSource = [{ value: 'ALL', label: 'All' }]
    }

    delete this.filters.orderSources
    this.filters = { ...this.filters, orderSources: newOrderSource }
    this.setState({ orderSources: newOrderSource })

    // update memoized filters
    this.props.searchRepository.updateSearchPage(this.props.pageKey, {
      filters: { ...this.filters },
    })

    // reload table
    this.table && this.table.reload(false, true)
  }

  getColor = orderSource => {
    switch (orderSource) {
      case 'ALL':
      case 'All':
        return 'black'
      case ORDER_SOURCE.COTERM_V1:
        return 'primary'
      case ORDER_SOURCE.COTERM_V2:
        return 'success'
      case ORDER_SOURCE.COTERM_PARTNER_PAVILION:
        return 'danger'
      case ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION:
        return 'secondary'
      default:
        return 'dark'
    }
  }

  render() {
    const {
      commonRepository,
      coTermRepository: {
        Table: { data, loading, totalRecordCount, originalData },
      },
      searchRepository,
    } = this.props
    const { search } = queryString.parse(this.props.location.search)

    return (
      <ContentContainer title="Co-Term">
        <Row className="mb-3">
          <Col lg="6" md="8" sm="12">
            <ReactSelect
              plain
              serverSide
              search={params => commonRepository.reactSelectSearch(params, 'cotermOrderSources')}
              options={{
                value: this.state.orderSources,
                placeholder: 'Filter By Series',
                isMulti: true,
                valueKey: 'value',
                labelKey: 'label',
                defaultOptions: true,
                components: {
                  MultiValueLabel: innerProps => (
                    <components.MultiValueLabel {...innerProps}>
                      <OptionStyled>
                        <div className={cx('badge-icon', `bg-${this.getColor(innerProps.data.value)}`)} />
                        <span>{innerProps.data.label}</span>
                      </OptionStyled>
                    </components.MultiValueLabel>
                  ),
                  Option: innerProps => (
                    <components.Option {...innerProps}>
                      <OptionStyled>
                        <div className={cx('badge-icon', `bg-${this.getColor(innerProps.value)}`)} />
                        <span>{innerProps.label}</span>
                      </OptionStyled>
                    </components.Option>
                  ),
                },
              }}
              className="mr-2 select-series"
              onChange={this.handleChangeOrderSource}
            />
          </Col>
        </Row>
        <ReactTable
          ref={ref => (this.table = ref)}
          searchRepository={searchRepository}
          pageKey="coterm"
          enableRowLink
          getRowUrl={({ original }) => `${COTERM_PATH}/${original.id}`}
          columns={[
            {
              headerClassName: 'px-0',
              className: 'p-0',
              accessor: 'orderSource',
              resizable: false,
              width: 20,
              Cell: ({ value }) => <RowColorCellStyled color={this.getColor(value)} />,
            },
            {
              accessor: 'tempOrderNumber',
              Header: 'Order No.',
              Cell: ({ original }) => original.orderNumber || original.tempOrderNumber || '',
            },
            {
              accessor: 'customer.name',
              Header: 'Customer',
              Cell: ({ original }) => (
                <span title={original.customer.name} className="pr-1 ellipsis row-clickable">
                  {original.customer.name}
                </span>
              ),
            },
            { accessor: 'store.name', Header: 'Store' },
            {
              accessor: 'orderDate',
              Header: 'Purchased On',
              width: 120,
              Cell: ({ original }) => <span>{getFormattedDateString(original.orderDate)}</span>,
            },
            {
              accessor: 'billingAddress.firstName',
              Header: 'Bill To',
              Cell: ({ original }) => {
                const fullName = getFullName(original.billingAddress)
                return (
                  <span title={fullName} className="pr-1 ellipsis row-clickable">
                    {fullName}
                  </span>
                )
              },
            },
            {
              accessor: 'orderSummary.currency',
              Header: 'Currency',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 80,
            },
            {
              accessor: 'orderSummary.grandTotal',
              Header: 'Amount',
              headerClassName: 'justify-content-end',
              className: 'justify-content-end',
              width: 120,
              Cell: ({ original: { customer = {}, orderSummary = {} } = {} }) =>
                orderSummary && formatCustomerCurrency(orderSummary.grandTotal, customer),
            },
            {
              Header: 'Order Status',
              accessor: 'status',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 200,
              Cell: ({ original }) => <Status status={original.status} />,
            },
          ]}
          actions={
            <Visible toOrderProcessRole>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Create a New Co-Term Quotation" />
              </div>
            </Visible>
          }
          className="striped cell-vertical-center"
          search
          searchCriteria={search}
          data={data}
          sort={{ id: 'orderDate', desc: true }}
          loadData={params => this.fetchTable({ ...params })}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
        />
      </ContentContainer>
    )
  }
}

const OptionStyled = styled.div`
  &&& {
    display: flex;
    align-items: center;
    .badge-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
`

const RowColorCellStyled = styled.div`
  &&& {
    height: 100%;
    &:before {
      content: '';
      display: block;
      background-color: ${props => props.theme[props.color]};
      width: 5px;
      height: 100%;
    }
  }
`

export default decorate(inject('commonRepository', 'coTermRepository', 'searchRepository')(observer(CoTerm)), {})
