/* @flow */
import { Badge, Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { BATCH_JOB_STATUS } from 'definitions'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import Card from 'components/Common/Card'
import ContentContainer from 'components/Common/ContentContainer'
import ErrorDetail from 'components/AutoRenewal/BatchJob/ErrorDetail'
import Loader from 'components/Common/Loader'
import ReactTable from 'components/Common/ReactTable'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class BatchJobDetailForm extends Component {
  componentDidMount() {
    this.loadBatchJob()
  }
  componentWillUnmount() {
    this.props.deviceManagementJobRepository.clearCrudData()
    this.props.deviceManagementJobRepository.clearErrorMessages()
  }
  loadBatchJob = () => {
    const {
      match: {
        params: { id },
      },
      deviceManagementJobRepository,
    } = this.props

    deviceManagementJobRepository.getById(id)
  }
  getStatusColor = status => {
    switch (status) {
      case BATCH_JOB_STATUS.SUCCESS:
        return 'success'
      case BATCH_JOB_STATUS.FAILED:
        return 'danger'
      default:
        return ''
    }
  }
  render() {
    const {
      deviceManagementJobRepository: { CRUD: { loading, data } = {} },
    } = this.props

    if (loading && !data.id) {
      return <Loader />
    }

    const orders = data.orders || []
    const failed = data.status === BATCH_JOB_STATUS.FAILED

    return (
      <ContentContainer title="Batch Job Detail">
        <Row>
          <Col xl={6}>
            <Card>
              <ReadOnlyField label="Batch Job Id" value={data.batchJobId} />
              <ReadOnlyField label="Batch Job Type" value={data.batchJobType} />
              <ReadOnlyField
                label="Status"
                value={
                  <Badge color={this.getStatusColor(data.status)} className="font-size-12" style={{ width: '100px' }}>
                    {failed && <i className="icon ion-md-alert tiny" />}{' '}
                    <span className={'text-uppercase'}>{data.status}</span>
                  </Badge>
                }
              />
              <ReadOnlyField label="Created By" value={data.createdBy} />
              <ReadOnlyField label="Date Created" value={getFormattedDateTimeString(data.createdDate)} />
            </Card>
          </Col>
          {failed && <ErrorDetail data={data} />}
        </Row>
        <Row>
          <Col>
            <Card title="Order Details">
              <ReactTable
                columns={[
                  {
                    Header: '',
                    expander: true,
                    Expander: () => <span />,
                    width: 0,
                    className: 'p-0',
                    headerClassName: 'p-0',
                  },
                  {
                    accessor: 'orderNumber',
                    Header: 'Order Number',
                    Cell: ({ original }) => `Order# ${original.orderNumber}`,
                  },
                ]}
                data={orders}
                totalRecordCount={orders.length}
                sort={{ id: 'orderNumber', asc: true }}
                expanded={orders.reduce(
                  (result, item, index) => ({
                    ...result,
                    [index]: true,
                  }),
                  {}
                )}
                SubComponent={({ original }) => (
                  <ReactTable
                    columns={[
                      {
                        accessor: 'productCode',
                        Header: 'Product Code',
                      },
                      {
                        accessor: 'productName',
                        Header: 'Product Name',
                      },
                      {
                        accessor: 'productType',
                        Header: 'Product Type',
                      },
                      {
                        Header: 'Serial Numbers',
                        sortable: false,
                        className: 'flex-column align-items-start',
                        Cell: ({ original }) =>
                          original.serialNumbers
                            ? original.serialNumbers.map((serialNumber, index) => (
                                <div key={index} className="mb-2">
                                  {serialNumber}
                                </div>
                              ))
                            : '',
                      },
                    ]}
                    data={original.orderItems || []}
                    className="striped"
                    sort={{ id: 'productCode', asc: true }}
                    showPager={false}
                    showRowPerPage={false}
                  />
                )}
              />
            </Card>
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

export default inject('deviceManagementJobRepository')(observer(BatchJobDetailForm))
