import { defaultAttributeValues, defaultCriteraValues, STRING_MAP } from './defaults'

export const toggleCreate = item => {
  item.isCreate = !item.isCreate
}

export const toggleCriteria = item => {
  item.isCriteriaOpen = !item.isCriteriaOpen
}

export const toggleCondition = item => {
  item.isConditionOpen = !item.isConditionOpen
}

export const handleCriteriaChange = (value, item) => {
  item.condition.condition = value
  toggleCriteria(item)
}
export const handleConditionChange = (value, item) => {
  item.condition.value = value
  toggleCondition(item)
}

export const toggleOperator = item => {
  item.isOperatorOpen = !item.isOperatorOpen
}

export const toggleAttribute = item => {
  item.isAttributeOpen = !item.isAttributeOpen
}

export const handleDeleteCondition = (parentCriteria, itemIndex) => {
  parentCriteria.length > itemIndex && parentCriteria.splice(itemIndex, 1)
}

export const handleDeleteRule = (parentCriteria, index) => {
  parentCriteria.splice(index, 1)
}

export const handleProductAttribute = (value, item, type) => {
  if (type === 'operator') {
    item.operator = value
    toggleOperator(item)
  } else if (type === 'attribute') {
    item.fieldValue = value
    toggleAttribute(item)
  }
}

export const handleNewRule = (selected, item) => {
  if (selected.value === 'condition_combination') {
    item.criterias = [...item.criterias, defaultCriteraValues]
  } else {
    item.criterias = [
      ...item.criterias,
      {
        ...defaultAttributeValues,
        ...{
          fieldName: selected.value,
          fieldValue:
            selected.attributeType === 'number'
              ? {
                  label: 0,
                  value: 0,
                }
              : null,
          operator: STRING_MAP.is,
          type: 'attribute',
        },
      },
    ]
  }
  toggleCreate(item)
}
