import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import { ORDERS_PATH, PUBLIC_PATH } from 'definitions'
import Template from '../Template'

class ApproveContainer extends Component {
  form = null
  constructor(props) {
    super(props)

    const {
      match: { params },
      history,
    } = this.props

    const token = params.token
    if (!token) {
      history.push('/')
      return
    }
    this.handleSubmit()
  }
  handleSubmit = form => {
    this.props.orderRepository.approveRequestApproval(this.props.match.params.token, res => {
      if (res.id) {
        this.props.history.push(`${PUBLIC_PATH}${ORDERS_PATH}/s/approve`)
      }
    })
  }
  render() {
    const {
      orderRepository: {
        CRUD: { loading, errors },
      },
    } = this.props
    const hasErrors = errors.length
    return (
      <Template
        title={
          loading ? (
            <h3 className="h5">Please wait...</h3>
          ) : hasErrors ? (
            <h3 className="text-danger h5">There's an error while processing your request</h3>
          ) : null
        }
      >
        {loading ? (
          <h6 className="h6" style={{ color: '#666', padding: 25 }}>
            <i>We're processing your request...</i>
          </h6>
        ) : null}
        {errors.length ? (
          <>
            <h6 className="h6" style={{ padding: '30px 25px 0' }}>
              Please see the error(s) below:
            </h6>
            <ul className="text-danger">
              {errors.map((e, i) => (
                <li key={i}>{e.message}</li>
              ))}
            </ul>
          </>
        ) : null}
      </Template>
    )
  }
}

export default inject('orderRepository')(observer(ApproveContainer))
