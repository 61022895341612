import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'

import { formatOrderPayload } from 'helpers/Formatters'
import Checkbox from 'components/Common/Form/Checkbox'
import ErrorsList from 'components/Common/ErrorsList'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

class ApprovalRequestModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      loading: false,
      errors: [],
    }
  }

  handleSubmit = () => {
    const { form, order, orderRepository, onClose, onConfirm, formAction = 'submit' } = this.props
    const values = form.values()
    const isQuotation = formAction === 'quotation'

    this.setState(
      {
        loading: true,
      },
      async () => {
        orderRepository.sendApprovalRequest(
          order.id,
          formatOrderPayload({
            ...order,
            ...values,
            payment: {
              ...values.payment,
              additionalCharge: orderRepository.OrderRates.data.additionalCharge || 0,
            },
            ...(values.groups.length ? { groups: values.groups } : { groups: [] }),
          }),
          isQuotation,
          data => {
            this.setState({
              loading: false,
            })
            onClose()
            if (onConfirm) {
              onConfirm({ useQuotation: isQuotation, skipPromptOnExit: true })
            }
          }
        )
      }
    )
  }
  handleNoApprovalRequire = e => {
    if (e.target.checked) {
      this.props.form.$('discountApproval.approver').set(null)
    }
  }
  handleClose = () => {
    const {
      onClose,
      orderRepository: { ApprovalRequest },
    } = this.props
    ApprovalRequest.errors = []
    onClose()
  }
  render() {
    const {
      form,
      open,
      userRepository: { reactSelectSearch },
      orderRepository: { ApprovalRequest },
      formAction,
    } = this.props
    const noApprovalRequired = form.$('discountApproval.noApprovalRequired').value
    return (
      <Modal
        open={open}
        size="md"
        title="Request Approval"
        confirmLabel="Save"
        onConfirm={() => this.handleSubmit()}
        onClose={this.handleClose}
        loading={ApprovalRequest.loading}
      >
        <FormContainer onSubmit={() => {}} plain>
          {ApprovalRequest.errors.length ? <ErrorsList errors={ApprovalRequest.errors} /> : null}
          {formAction === 'quotation' && (
            <Input field={form.$('quotationDaysValidFor')} allowExponent={false} allowNegative={false} />
          )}
          <ReactSelect
            field={form.$('discountApproval.approver')}
            customLabelKey={['fullName', 'id']}
            serverSide
            search={payload => reactSelectSearch({ ...payload, status: 'ACTIVE' })}
            options={{
              valueKey: 'id',
              labelKey: 'fullName',
              defaultOptions: true,
              isDisabled: noApprovalRequired,
            }}
            style={{ zIndex: 999 }}
          />
          <Checkbox
            field={form.$('discountApproval.noApprovalRequired')}
            className="mr-3 mt-0"
            onChange={this.handleNoApprovalRequire}
          />
        </FormContainer>
      </Modal>
    )
  }
}

export default inject('orderRepository', 'userRepository')(observer(ApprovalRequestModal))
