import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import Currency from './Currency'

class ZonePrices extends Component {
  render() {
    const {
      form,
      zonePrices,
      commonRepository: {
        Currencies: { data: currencies = [] },
      },
      zoneRepository: {
        CRUD: { data: zoneData = [] },
      },
      promptOnExit,
    } = this.props

    return (
      <ZonesStyled>
        {currencies &&
          zoneData.length &&
          currencies.map((currency, currencyIndex) => (
            <Currency
              key={`zonePrice${currency.value}`}
              form={form}
              zonePrice={zonePrices && zonePrices.find(item => item.currency === currency.value)}
              currency={toJS(currency)}
              currencyIndex={currencyIndex}
              zoneData={toJS(zoneData)}
              promptOnExit={promptOnExit}
            />
          ))}
      </ZonesStyled>
    )
  }
}

const ZonesStyled = styled.div`
  margin-top: 8px;

  label {
    font-weight: bold !important;
  }
`

export default observer(ZonePrices)
