import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'
import React from 'react'

import ErrorsList from 'components/Common/ErrorsList'

const ErrorDetailsPopup = ({ position = 'bottom', original = {} }) => {
  return (
    <UncontrolledPopover trigger="legacy" placement={position} target={`popover-${original.id}`}>
      <PopoverHeader>Error Details</PopoverHeader>
      <PopoverBody>
        <ErrorsList
          className="stop-redirect"
          errors={original.errorDetails.map(err => ({
            message: `Batch job failed for ${err.serialNumber} ${
              err.deviceManagementJobErrorItems.length &&
              `on Products: ${err.deviceManagementJobErrorItems.map(product => product.productCode).join(', ')}`
            }`,
          }))}
        />
      </PopoverBody>
    </UncontrolledPopover>
  )
}

export default ErrorDetailsPopup
