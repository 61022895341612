import { Col } from 'reactstrap'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import Input from 'components/Common/Form/Input'
import Loader from 'components/Common/Loader'

class StorePrices extends Component {
  constructor(props) {
    super(props)
    this.initializeStorePrices()
  }

  initializeStorePrices = () => {
    const { storeRepository, form } = this.props
    if (form.has('prices')) {
      form.del('prices')
    }

    form.add({
      key: 'prices',
      value: [],
    })
    storeRepository.fetchTable({ size: '99' })
  }

  render() {
    const {
      form,
      storeRepository: {
        Table: { data, loading },
      },
    } = this.props

    const stores = toJS(data)

    return (
      <StorePricesStyled title="Price">
        {loading && <Loader text="Loading Stores..." />}
        {!loading && stores && (
          <div className="prices">
            {stores.map(store => (
              <StorePrice key={`store${store.id}`} form={form} store={store} />
            ))}
          </div>
        )}
      </StorePricesStyled>
    )
  }
}

class StorePrice extends Component {
  constructor(props) {
    super(props)

    const { form, store } = props
    this.field = form.$('prices').add()
    this.field.add({
      key: 'price',
      type: 'number',
      label: store.name,
      value: 0,
      currency: store.currency,
    })
    this.field.add({
      key: 'storeId',
      value: store.id,
    })
    this.field.add({
      key: 'storeName',
      value: store.name,
    })
    this.field.add({
      key: 'currency',
      value: store.currency,
    })
  }
  render() {
    return (
      <Col sm="3">
        <Input
          field={this.field.$('price')}
          startAdornment={<span className="mt-2">{this.field.$('currency').value}</span>}
        />
      </Col>
    )
  }
}

const StorePricesStyled = styled(Card)`
  &.card {
    height: 440px;
    flex-grow: 1;
    .card-body {
      overflow: auto;
      .prices {
        max-height: 100%;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
`

export default observer(StorePrices)
