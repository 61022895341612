/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

import OrderTable from './OrderTable'
import ToasterNotify from 'helpers/ToasterNotify'

class Orders extends Component {
  componentDidMount() {
    const { history } = this.props
    const { status, search } = queryString.parse(this.props.location.search)
    switch (status) {
      case 'NEW':
        ToasterNotify.alert({ message: 'Order created successfully!' })
        history.replace('/orders', {})
        break
      case 'PENDING_APPROVAL':
        ToasterNotify.alert({ message: 'Order detail saved, but will not be submitted until approved', color: 'info' })
        history.replace('/orders', {})
        break
      default:
        search && history.replace('/orders', {})
        break
    }
  }
  render() {
    const { orderRepository, searchRepository } = this.props
    const { search } = queryString.parse(this.props.location.search)

    return (
      <OrderTable
        repository={orderRepository}
        searchRepository={searchRepository}
        pageKey="orders"
        searchCriteria={search}
      />
    )
  }
}

export default decorate(inject('orderRepository', 'searchRepository')(observer(Orders)), {})
