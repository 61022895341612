import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedTo } from 'helpers/Auth'
import ErrorsList from 'components/Common/ErrorsList'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable'

class StoreProductsModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedItems: [],
    }
    props.storeCountryRepository.CRUD.errors = []
  }

  handleSubmit = () => {
    const data = this.state.selectedItems
    if (data.length) {
      const { storeId, toggleModal, table } = this.props
      this.props.storeCountryRepository.create({ storeId, countries: data.map(id => ({ id })) }, res => {
        toggleModal()
        if (table) table.reload()
      })
    }
  }
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selectedItems: Object.keys(selection),
    })
  }
  render() {
    const {
      isModalOpen,
      toggleModal,
      storeCountryRepository: {
        CRUD: { errors = [] },
        CRUD,
      },
      commonRepository: {
        TableItems: {
          collections: { country: { data = [], loading = false, totalRecordCount = 0, originalData = {} } = {} },
        },
        getTableItemCollection,
      },
    } = this.props
    return (
      <Modal
        size="lg"
        title="Add Country for Store"
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={this.handleSubmit}
        hideConfirm={!allowedTo()}
        disabled={loading || CRUD.loading}
      >
        {errors.length > 0 && (
          <div style={{ margin: '20px' }}>
            <ErrorsList errors={errors} />{' '}
          </div>
        )}
        <ReactTable
          multiSelect
          selectElementId="storeCountryModal"
          className="striped cell-vertical-center"
          columns={[{ accessor: 'label', Header: 'Name' }]}
          sort={{ id: 'label' }}
          data={data}
          loadData={params => getTableItemCollection('country', 'country', params)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          selectionChanged={this.handleSelected}
        />
      </Modal>
    )
  }
}

export default decorate(inject('storeCountryRepository', 'commonRepository')(observer(StoreProductsModal)), {})
