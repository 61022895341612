/* @flow */

import { action, decorate, observable, toJS } from 'mobx'
import BaseRepository from './BaseRepository'
import ImportServices from 'services/import'

class ImportRepository extends BaseRepository {
  service: ImportServices
  constructor(service: ImportServices) {
    super(service)
    this.service = service
  }

  ImportList = {}

  importFile = async payload => {
    const { data, errors } = await this.service.create(payload)
    return { data, errors }
  }

  getByKey = async (key, payload) => {
    this.initalizeImportFile(key)

    this.ImportList[key].loading = true
    const { data, errors } = await this.service.getById(payload)
    if (!errors.length) {
      this.ImportList[key].loadingFailed = false
    } else {
      this.ImportList[key].loadingFailed = true
      this.ImportList[key].errors = errors
    }
    this.ImportList[key].data = data
    this.ImportList[key].loading = false

    return { data, errors }
  }

  initalizeImportFile = key => {
    if (!toJS(this.ImportList).hasOwnProperty(key)) {
      this.ImportList[key] = {
        loading: false,
        loadingFailed: false,
        data: {},
        errors: [],
      }
    }
  }

  clearErrorMessages = key => {
    this.ImportList[key].errors = []
  }
}

export class ImportTypeRepository extends BaseRepository {
  service: ImportTypeServices
  constructor(service: ImportTypeServices) {
    super(service)
    this.service = service
  }

  get() {
    return this.getCommon('importType', '')
  }
}

export default decorate(ImportRepository, {
  ImportList: observable,
  getByKey: action,
})
