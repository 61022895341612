/* @flow */
import { inject, observer } from 'mobx-react'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'
import UserForm from './UserForm'

class UserAdd extends Component {
  render() {
    return (
      <ContentContainer
        title="Add New User"
        promptOnExit={this.props.userRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <UserForm {...this.props} />
      </ContentContainer>
    )
  }
}

export default inject('userRepository')(observer(UserAdd))
