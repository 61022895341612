/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class ShippingServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/shippingDetails'
    super(api, url)
    this.api = api
    this.url = url
  }
  bulkDelete = async payload => {
    return await this.api.post(`${this.url}/remove`, payload)
  }
  bulkUpdateStatus = async payload => {
    return await this.api.post(`${this.url}/update/status`, payload)
  }
  reactSelectSearchShippingCarriers(shippingMethodId, payload) {
    return this.api.get(`${this.url}/${shippingMethodId}/carriers`, payload)
  }
  reactSelectSearchShippingServices(shippingMethodId, shippingCarrierId, payload) {
    return this.api.get(`${this.url}/${shippingMethodId}/carriers/${shippingCarrierId}/services`, payload)
  }
}

export default ShippingServices
