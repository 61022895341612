/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class OrderChargeServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/additionalCharge'
    super(api, url)
    this.api = api
    this.url = url
  }

  bulkCopy = payload => {
    return this.api.post(`${this.url}/copy`, { additionalCharges: payload })
  }
  bulkDelete = payload => {
    return this.api.post(`${this.url}/remove`, { additionalCharges: payload })
  }
}

export default OrderChargeServices
