import React, { Component } from 'react'

import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import Zone from './Zone'

class Currency extends Component {
  constructor(props) {
    super(props)

    //Create the dynamic fields for
    const { form, currency } = props
    const itemArrayField = form.$('zonePrices').add()
    this.currencyField = itemArrayField.add({
      key: 'currency',
      label: 'Shipping Zone (Per Unit)',
      value: currency.value,
    })
    this.zonesField = itemArrayField.add({
      key: 'zones',
      value: [],
    })
  }
  render() {
    const { currencyIndex, currency, zoneData = [], zonePrice = {}, promptOnExit } = this.props
    const label = currencyIndex === 0 ? this.currencyField.label : ''

    return (
      <div className="grouped">
        <ReadOnlyField value={this.currencyField.value} label={label} />
        {zoneData != null &&
          zoneData.map(zData => {
            const { id, zone: { label } = {} } = zData
            return (
              <Zone
                key={`zonePrice${currency.value}${id}`}
                currencyIndex={currencyIndex}
                zonesField={this.zonesField}
                zonePriceAmount={zonePrice.zones && zonePrice.zones.find(item => item.zoneId === id)}
                zone={{ id, label }}
                promptOnExit={promptOnExit}
              />
            )
          })}
      </div>
    )
  }
}

export default Currency
