/* @flow */
import React from 'react'
import Validator from 'validatorjs'

export const required = (value): ?string => {
  return value ? null : 'This field is required'
}

export const minLength =
  (min: number) =>
  (value: string): ?string => {
    return value && value.length < min ? `Must be ${min} characters or more` : null
  }

export const maxLength =
  (max: number) =>
  (value: string): ?string => {
    return value && value.length > max ? `Upto ${max} characters only` : null
  }

export const equalLength =
  (equalTo: number) =>
  (value: string): ?string => {
    return value && value.length !== equalTo ? `Must be ${equalTo} characters` : null
  }

export const number = (value: *): ?string => {
  return value && isNaN(Number(value)) ? 'Must be a number' : null
}

export const isInteger = (value: *): ?string => {
  return value && !/^\d+$/i.test(value) ? 'Invalid number' : null
}

export const email = (value: *): ?string => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : null
}

export const isPassword = (value: *): ?string => {
  return value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).[^\s]{7,20}$/.test(value) ? (
    <React.Fragment>
      - Password should be 8 characters minimum <br />- Must contain at least one from each of the following: <br />
      <ul>
        <li>An upper case letter</li>
        <li>A lower case letter</li>
        <li>A numeric character</li>
      </ul>
    </React.Fragment>
  ) : null
}
export const isAlphaNumeric = value => {
  return value && !/^[a-zA-Z0-9]*$/i.test(value) ? 'Invalid characters' : null
}
export const isTagFormat = value => {
  return value && !/^[a-zA-Z0-9\s_-]*$/i.test(value) ? 'Invalid characters' : null
}

export const isEqual = (value, equal) => {
  return value !== equal ? 'Value does not match' : null
}

export const mobxEmail = ({ field }): ?string => {
  const isValidEmail = field.value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(field.value)

  return [!isValidEmail, 'Invalid email address']
}

export const mobxEmailList = ({ field }) => {
  let success = true
  if (field.value) {
    let emails

    if (typeof field.value === 'string' || field.value instanceof String) {
      emails = field.value.split('\n')
    } else {
      emails = field.value
    }

    emails.forEach(email => {
      const validation = new Validator({ email }, { email: 'email' })
      if (validation.fails()) {
        success = false
      }
    })
    //check for duplicates
    if (success) {
      const trimmedEmails = emails.filter(item => !!item)
      if (trimmedEmails.some((item, index, list) => list.indexOf(item) !== index)) {
        return [false, 'Email addresses must be unique']
      }
    }
  }
  return [success, 'Enter valid email addresses']
}

export const mobxRequired = ({ field }): ?string => {
  return [!!field.value, 'This field is required']
}

export const isValidPoNumberFormat = value => /^SO[0-9]{6}$/.test(value)
