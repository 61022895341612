/* @flow */
import { CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedTo, Visible } from 'helpers/Auth'
import { PAYMENT_ACCOUNTS_PATH } from 'definitions'
import { titleize } from 'helpers/String'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class PaymentAccounts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemToDelete: null,
    }
  }
  handleCreate = () => {
    this.props.history.push(`${PAYMENT_ACCOUNTS_PATH}/new`)
  }
  handleDelete = item => {
    ToasterNotify.confirm({
      message: `Are you sure you want to delete this stripe account?`,
      title: 'Warning',
      onConfirm: () =>
        this.props.paymentAccountRepository.delete(item.id, () => {
          this.table && this.table.reload()
        }),
    })
  }
  handToggleStatus = item => {
    if (item) {
      const payload = {
        ...item,
        status: item.status && item.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      }
      this.props.paymentAccountRepository.patch(payload, () => {
        this.table && this.table.reload()
      })
    }
  }
  render() {
    const {
      paymentAccountRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="Payment Accounts" breadcrumbLabel="Payment Accounts">
        <ReactTable
          searchRepository={searchRepository}
          pageKey="payment-accounts"
          enableRowLink
          getRowUrl={({ original }) => `${PAYMENT_ACCOUNTS_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
              Cell: ({ original }) => (
                <span title={original.name} className="pr-2 ellipsis row-clickable">
                  {original.name}
                </span>
              ),
            },
            {
              accessor: 'type.label',
              Header: 'Type',
            },
            {
              accessor: 'status',
              Header: 'Status',
              width: 100,
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  label={titleize(original.status)}
                  id={`switchStatus${original.id}`}
                  className="mr-2"
                  checked={original.status === 'ACTIVE'}
                  onClick={event => event.preventDefault()}
                  {...(allowedTo() ? { onChange: () => this.handToggleStatus(original) } : {})}
                  disabled={!allowedTo()}
                />
              ),
            },
            ...(allowedTo()
              ? [
                  {
                    Header: 'Actions',
                    headerClassName: 'justify-content-center',
                    className: 'text-center p-1 justify-content-center',
                    width: 80,
                    Cell: ({ original }) => (
                      <IconButton
                        icon="ios-trash"
                        onClick={event => {
                          event.preventDefault()
                          this.handleDelete(original)
                        }}
                      />
                    ),
                  },
                ]
              : []),
          ]}
          actions={
            <Visible>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Add New Bank Account" />
              </div>
            </Visible>
          }
          sort={{ id: 'name' }}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          innerRef={ref => (this.table = ref)}
          search
          ref={ref => (this.table = ref)}
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('paymentAccountRepository', 'searchRepository')(observer(PaymentAccounts)), {})
