/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import { Badge } from 'reactstrap'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContextMenu from 'components/Common/ContextMenu'
import CustomerForm from './CustomerForm'
import Drawer from 'components/Common/Drawer'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

const STR_DEF = {
  contacts: 'Contacts',
  addresses: 'Addresses',
  products: 'Special Products',
}

const customerLogData = {
  activityLogData: {
    entity: 'CustomerOrganization',
    entityLabel: 'Customer',
  },
  commentData: {
    commentSource: 'customer',
  },
}

class CustomerEdit extends Component {
  constructor(props) {
    super(props)
    const {
      match: { params },
      customerRepository: { getById },
    } = props
    this.state = {
      view: 'contacts',
      ...customerLogData,
    }
    getById(params.customerId)
  }

  componentWillUnmount() {
    this.props.customerRepository.clearCrudData()
  }

  handleChangeView = view => {
    this.setState({
      view,
    })
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  handleChangeDisplayMode = (mode, id) => {
    if (id) {
      switch (mode) {
        case 'contact':
          this.setState({
            activityLogData: {
              entity: 'CustomerContact',
              entityId: id,
              entityLabel: 'Contact',
            },
            commentData: {
              referenceId: id,
              commentSource: 'customerContact',
            },
          })
          break
        case 'address':
          this.setState({
            activityLogData: {
              entity: 'Address',
              entityId: id,
            },
            commentData: {
              referenceId: id,
              commentSource: 'address',
            },
          })
          break
        case 'special-products':
          this.setState({
            activityLogData: {
              entity: 'Product',
              entityId: id,
              entityLabel: 'Special Product',
            },
            commentData: {
              referenceId: id,
              commentSource: 'specialProducts',
            },
          })
          break
        default:
          this.setState({ ...customerLogData })
          break
      }
    } else {
      this.setState({ ...customerLogData })
    }
  }

  handleOnActiviityLogModalOpen = () => {
    const modalWrapperEl = window.document.querySelector('.log-book-modal-wrapper')
    if (modalWrapperEl) {
      modalWrapperEl.parentElement.style.zIndex = 1071
    }
  }

  render() {
    const { view, activityLogData, commentData } = this.state
    const {
      customerRepository: {
        CRUD: { loading, data, errors },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <CustomerForm
          {...this.props}
          data={data}
          view={view}
          isEdit
          title="Edit Customer"
          errors={!data.id && errors}
          actions={
            <>
              <Badge color="dark" className="text-uppercase p-2 mr-2">
                {STR_DEF[view]}
              </Badge>
              <ContextMenu
                disabled={loading}
                dropDownToggle={<IconButton icon="md-apps" />}
                menus={[
                  {
                    text: STR_DEF.contacts,
                    onClick: () => {
                      this.handleChangeView('contacts')
                    },
                  },
                  {
                    text: STR_DEF.addresses,
                    onClick: () => {
                      this.handleChangeView('addresses')
                    },
                  },
                  {
                    text: STR_DEF.products,
                    onClick: () => {
                      this.handleChangeView('products')
                    },
                  },
                ]}
                plain
              />
            </>
          }
          onDisplayModeChanged={this.handleChangeDisplayMode}
        />

        <DrawerStyled
          ref={ref => (this.drawer = ref)}
          className={cx(commentData.commentSource !== 'customer' && 'topmost')}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity={activityLogData.entity}
                  entityId={activityLogData.entityId || data.id}
                  entityLabel={activityLogData.entityLabel}
                  closeDrawer={this.handleCloseDrawer}
                  onLogModalOpen={this.handleOnActiviityLogModalOpen}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={commentData.referenceId || data.id}
                  commentSource={commentData.commentSource}
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const DrawerStyled = styled(Drawer)`
  &&& {
    &.topmost {
      z-index: 1060;
      .sidebar {
        top: 0;
        height: 100%;
      }
    }
  }
`

export default decorate(
  inject(
    'customerRepository',
    'customerContactRepository',
    'customerAddressRepository',
    'customerSpecialProductRepository'
  )(observer(CustomerEdit)),
  {}
)
