/* @flow */
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import ContentContainer from 'components/Common/ContentContainer'
import ImportForm from './ImportForm'
import ImportHistoryTable from './ImportHistoryTable'
import ImportProcessList from './ImportProcess'

class Import extends Component {
  constructor(props) {
    super(props)

    this.state = {
      importList: [],
    }
  }
  addImportFile = importData => {
    const { importList } = this.state
    importList.push(importData)
    this.setState({ importList })
  }
  removeImportFile = key => {
    const { importList } = this.state
    const index = importList.findIndex(item => item.key === key)
    if (index >= 0) {
      importList.splice(index, 1)
      this.setState({ importList })
    }
  }
  reloadHistory = () => {
    this.history.reloadTable()
  }
  render() {
    const { importList } = this.state

    return (
      <ContentContainer
        title="Upload Function"
        promptOnExit={this.props.fileUploadRepository.submitting}
        promptOnExitIsConfirm={false}
      >
        <Row>
          <Col md={5}>
            <ImportForm addImportFile={this.addImportFile} />
            <ImportProcessList
              importList={importList}
              removeImportFile={this.removeImportFile}
              reloadHistory={this.reloadHistory}
            />
          </Col>
          <Col md={7}>
            <ImportHistoryTable ref={ref => (this.history = ref)} />
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

export default inject('fileUploadRepository')(observer(Import))
