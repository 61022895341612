import React, { useState } from 'react'

import { Button } from 'reactstrap'
import styled from 'styled-components'

import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'

export default ({ handleCreate, handleDelete, handToggleStatus }) => {
  const [bulkAction, setBulkAction] = useState(null)

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ReactSelectStyled
        plain={true}
        className="mr-2"
        options={{
          placeholder: 'Select Bulk Action',
          options: [
            { value: 'ACTIVE', label: 'Activate' },
            { value: 'INACTIVE', label: 'Inactivate' },
            { value: 'DELETE', label: 'Delete' },
          ],
        }}
        onChange={selected => {
          setBulkAction(selected && selected.value)
        }}
      />
      <Button
        color="dark"
        size="sm"
        className="mr-2"
        onClick={() => {
          if (bulkAction === 'DELETE') {
            handleDelete()
          } else {
            handToggleStatus(bulkAction)
          }
        }}
        disabled={bulkAction === null}
      >
        Submit
      </Button>
      <IconButton onClick={handleCreate} color="light-dark" title="Create New Shipping" />
    </div>
  )
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`
