import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Row } from 'reactstrap'
import React from 'react'

import { AUTO_RENEWAL_PATH } from 'definitions'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import AutoRenewalComments from './AutoRenewalComments'
import AutoRenewalForm from './AutoRenewalForm'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class AutoRenewalEdit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.loadData()
  }

  loadData() {
    const {
      match: {
        params: { id },
      },
      deviceManagementRepository,
    } = this.props

    deviceManagementRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.deviceManagementRepository.clearCrudData()
    this.props.deviceManagementRepository.clearErrorMessages()
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  render() {
    const {
      deviceManagementRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer
          title="Edit Details"
          breadcrumbs={[
            {
              label: 'Home',
              href: '/',
            },
            {
              label: 'Auto Renewal',
              href: AUTO_RENEWAL_PATH,
            },
            {
              label: data ? data.serialNumber : '',
            },
          ]}
        >
          <Row>
            <AutoRenewalForm {...this.props} data={data} isAdd={false} />
          </Row>
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="DeviceManagement"
                  entityId={data.id}
                  entityLabel="Auto Renewal"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: <AutoRenewalComments autoRenewalId={data.id} onDrawerClose={this.handleCloseDrawer} />,
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('deviceManagementRepository')(observer(AutoRenewalEdit)), {})
