/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { SANCTION_COUNTRY_TYPE, SANCTION_ENTITY_TYPE } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import ReactTable from 'components/Common/ReactTable'

class Sanctions extends Component {
  render() {
    const {
      sanctionRepository: {
        Table: { data, loading, totalRecordCount, originalData },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="Sanctions">
        <ReactTable
          searchRepository={searchRepository}
          pageKey="sanctions"
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
              Cell: ({ original }) => {
                switch (original.type) {
                  case SANCTION_ENTITY_TYPE:
                    return original.companyName
                  case SANCTION_COUNTRY_TYPE:
                    return original.country
                  default:
                    return `${original.firstName} ${original.lastName}`
                }
              },
            },
            {
              accessor: 'emailAddress',
              Header: 'Email Address',
              Cell: ({ original }) =>
                original.emailAddress
                  ? original.emailAddress.map((item, index) => (
                      <div key={index} style={{ marginBottom: '2px' }}>
                        {item}
                      </div>
                    ))
                  : '',
            },
            {
              accessor: 'type',
              Header: 'Type',
            },
          ]}
          sort={{ id: 'name' }}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('sanctionRepository', 'searchRepository')(observer(Sanctions)), {})
