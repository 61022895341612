/* @flow */
import moment from 'moment'

import { countDecimals, isNull, stripEmptyValues } from 'helpers/Common'
import { removeEmptyItems } from 'helpers/Array'
import { toDecimal } from 'helpers/String'

export const getFormattedDateString = (dateString, defaultValue = 'N/A') => {
  const isValidDate = dateString && moment(dateString).isValid()

  if (!isValidDate) {
    return defaultValue
  }

  return moment(dateString).format('DD MMM YYYY')
}

export const getFormattedDateTimeString = (dateTimeString, defaultValue = 'N/A') => {
  const isValidDate = dateTimeString && moment(dateTimeString).isValid()
  if (!isValidDate) {
    return defaultValue
  }

  return moment(dateTimeString).format('DD MMM YYYY HH:mm')
}

export const getFormattedUtcDateTimeString = (dateTimeString, offset, defaultValue = 'N/A') => {
  if (offset) {
    const momentDate = moment.utc(dateTimeString)
    const isValidDate = dateTimeString && momentDate.isValid()
    if (!isValidDate) {
      return defaultValue
    }

    return momentDate.utcOffset(offset).format('DD MMM YYYY HH:mm')
  }
  return 'Timezone not set'
}

export const formatDate = (dateTime, format = 'DD-MM-YYYY HH:mm:ss') => {
  const isValidDate = dateTime && moment(dateTime).isValid()

  if (!isValidDate) {
    return 'N/A'
  }

  return moment(dateTime).format(format)
}

export const formatDecimalValByCustomer = (num, customer) =>
  parseFloat(num).toFixed(
    customer && customer.decimalPlace && customer.decimalPlace.decimalPlaceInd
      ? customer.decimalPlace.noOfDecimalPlace
      : 2
  )

export const formatNumber = (num, decimal = 2) => groupNumber(parseFloat(num).toFixed(decimal))

export const formatCurrency = (num, decimal = 2) => {
  if (countDecimals(num) > 2) {
    return groupNumber(num)
  }
  return formatNumber(num, decimal)
}

export const formatCustomerCurrency = (num, customer = {}, currency) => {
  const { decimalPlace = {} } = customer || {}
  const decimal = decimalPlace.decimalPlaceInd && decimalPlace.noOfDecimalPlace > 2 ? decimalPlace.noOfDecimalPlace : 2

  if (currency) {
    return `${currency} ${formatCurrency(num, decimal)}`
  }
  return formatCurrency(num, decimal)
}

export const formatDecimal = (num = 0, decimal = 2) => {
  if (num !== undefined && num !== null) {
    const decimals = num.toString().split('.')
    if (decimals.length > 1) {
      if (parseInt(`0.${decimals[1]}`, 10) > 0) {
        return num.toFixed(decimal)
      } else {
        return decimals[0]
      }
    }
  }
  return num
}

export const formatSerialNumber = value => {
  return value && `${value.replace(/[-]/g, '').replace(/([0-9a-zA-Z]{4})(?!$)/g, '$1-')}`.toUpperCase()
}

export const formatIccidNumber = value => value && `${value.replace(/[^0-9]/gi, '')}`.slice(0, 20).toUpperCase()

export const formatOrganizationNumber = value => {
  return value && `${value.replace(/[^0-9a-z]/gi, '')}`.slice(0, 6).toUpperCase()
}

export const groupNumber = num => {
  var tokens = num.toString().split('.')
  return `${tokens[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}${tokens[1] ? `.${tokens[1]}` : ''}`
}

export const getDays = (startDate, endDate = new Date()) => {
  const start = moment(startDate)
  const end = moment(endDate)
  return start.diff(end, 'days') + 1
}

export const formatOrderPayload = payload => {
  let surchargeInd
  if (payload.payment) {
    surchargeInd =
      payload.payment &&
      (isNull(payload.payment.paymentMethod, {}).value === 'Peplink Credit Terms Payment'
        ? false
        : payload.payment.surchargeInd)
  }

  return {
    ...payload,
    attachmentUniqueId: payload.attachmentUniqueId,
    billingAddress: stripEmptyValues(payload.billingAddress),
    ...(!payload.virtualProductOnlyInd
      ? { shippingAddress: stripEmptyValues(payload.shippingAddress) }
      : { shippingAddress: null }),
    customer: {
      id: payload.customer.id,
      decimalPlace: payload.customer.decimalPlace,
    },
    orderType: isNull(payload.orderType),
    poNumber: payload.poNumber,
    skipSNWarrantyInd: payload.skipSNWarrantyInd,
    noShipmentReason: payload.noShipmentInd ? isNull(payload.noShipmentReason) : null,
    postpaidHardwareInd: payload.postpaidHardwareInd,
    others: payload.others,
    store: {
      ...payload.store,
    },
    ...(payload.shippingMethod && !payload.virtualProductOnlyInd
      ? {
          shippingMethod: {
            ...(payload.shippingMethod.method && { method: payload.shippingMethod.method }),
            ...(payload.shippingMethod.method === 'Priority Shipping'
              ? {
                  shippingInsurance: payload.shippingMethod.shippingInsurance,
                  shippingInsuranceInd: payload.shippingMethod.shippingInsuranceInd,
                  totalShippingFee: toDecimal(payload.shippingMethod.totalShippingFee),
                  shippingCarrier: isNull(payload.shippingMethod.shippingCarrier),
                  shippingService: isNull(payload.shippingMethod.shippingService),
                }
              : {
                  shippingCarrier: isNull(payload.shippingMethod.shippingCarrier),
                  shippingService: isNull(payload.shippingMethod.shippingService),
                  accountNo: payload.shippingMethod.accountNo,
                  contactInfo: payload.shippingMethod.contactInfo,
                }),
          },
        }
      : { shippingMethod: null }),
    ...(payload.payment && {
      payment: {
        ...payload.payment,
        bankAccount: isNull(payload.payment.bankAccount),
        paymentMethod: isNull(payload.payment.paymentMethod) ? payload.payment.paymentMethod : undefined,
        surcharge: surchargeInd ? toDecimal(payload.payment.surcharge) : 0,
        surchargeInd: surchargeInd,
      },
    }),
    // orderSubscription: undefined,
    ...(payload.orderSubscription && {
      orderSubscription: payload.orderSubscription,
    }),
    orderMailing: payload.orderMailing
      ? {
          billings: payload.orderMailing.billings
            ? removeEmptyItems(payload.orderMailing.billings.trim().split('\n'))
            : undefined,
          shippings: payload.orderMailing.shippings
            ? removeEmptyItems(payload.orderMailing.shippings.trim().split('\n'))
            : undefined,
        }
      : undefined,
    quotationDaysValidFor: payload.quotationDaysValidFor,
    internalRemarks: payload.internalRemarks,
    customerComments: payload.customerComments,
    ...(payload.discountApproval && {
      discountApproval: {
        approver: payload.discountApproval.approver ? payload.discountApproval.approver : undefined,
        noApprovalRequired: payload.discountApproval.noApprovalRequired === true,
        comment: payload.discountApproval.comment ? payload.discountApproval.comment : undefined,
        attachments: isNull(payload.discountApproval.attachments),
      },
    }),
    ...(payload.discountApproval && payload.discountApproval.deleteAttachments
      ? { deleteAttachments: payload.discountApproval.deleteAttachments }
      : {}),
    ...(payload.hasOwnProperty('updateSurcharge') && { updateSurcharge: payload.updateSurcharge }),
    orderItems: payload.orderItems?.length
      ? payload.orderItems.map(orderItem => ({
          ...orderItem,
          iccidNumbers: orderItem.iccidBasedInd ? orderItem.iccidNumbers : [],
        }))
      : [],
  }
}

export const getFullName = (obj = {}) => {
  return `${obj.firstName || ''} ${obj.lastName || ''}`
}

export const jsonToQueryString = (payload = {}) =>
  Object.keys(payload)
    .filter(key => payload[key] !== null && payload[key] !== undefined)
    .map((key, index) => `${!index ? '?' : ''}${key}=${encodeURIComponent(payload[key])}`)
    .join('&')
