/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import DeviceManagementServices from 'services/deviceManagement'

class DeviceManagementRepository extends BaseRepository {
  service: DeviceManagementServices
  constructor(service: DeviceManagementServices) {
    super(service)
    this.service = service
  }

  Select = {
    data: [],
    loading: false,
  }
  Export = defaults.CRUD

  reactSelectSearch = async (id, params) => {
    this.Select.loading = true
    const { data, errors } = await this.service.reactSelectSearch(id, params)
    this.Select.loading = false
    if (data && !errors.length) {
      this.Select.data = data.deviceManagementSubscriptions || []
    }
  }

  patchSubscriptionProduct = async (id, payload) => {
    this.Table.loading = true
    const { data, errors } = await this.service.patchSubscriptionProduct(id, payload)
    this.Table.loading = false

    if (data && !errors.length) {
      return true
    } else {
      this.Table.errors = errors
    }

    return false
  }

  export = async (payload, callback) => {
    this.Export.errors = []
    this.Export.loading = true
    const { data, errors } = await this.service.bulkPost(payload, { responseType: 'blob' })
    if (data && !errors.length) {
      this.Export.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.Export.loadingFailed = true
      this.Export.errors = errors
    }
    this.Export.loading = false
  }
}

export default decorate(DeviceManagementRepository, {
  Select: observable,
  Export: observable,
})
