import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedToOrderRole } from 'helpers/Auth'
import DatePicker from 'components/Common/Form/DatePicker'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

import ZonePrices from './ZonePrices'

class ShippingRateModal extends Component {
  constructor(props) {
    super(props)

    const { isAdd, data = {} } = props
    const { shippingDetailId, shippingMethod = 'frpp', shippingType, effectiveDate } = data
    const rules = 'required'
    const hooks = { onChange: () => props.promptOnExit(true) }

    const fields = {
      shippingDetailId: {
        value: shippingDetailId,
        rules,
        hooks,
      },
      shippingMethod: {
        value: shippingMethod,
        rules,
        hooks,
      },
      shippingType: {
        label: 'Shipping Type',
        value: shippingType,
        rules,
        hooks,
      },
      effectiveDate: {
        label: 'Effective Date',
        type: 'date',
        value: effectiveDate,
        rules,
        hooks,
      },
      quantity: {
        label: 'Shipping QTY',
        rules,
        hooks,
      },
      zonePrices: {
        value: [],
        hooks,
      },
    }

    this.form = new Form({ fields }, { name: 'ShippingRateForm', handleSubmit: this.handleSubmit })
    this.saving = false

    this.state = {
      data,
      showZoneSection: isAdd,
      shippingQtyRange: [],
    }
  }

  componentDidMount() {
    this.loadShippingRate()
  }

  loadShippingRate = () => {
    const { shippingRateRepository, data, isAdd } = this.props
    if (isAdd) {
      this.props.commonRepository.getCurrencies()
      this.props.zoneRepository.getById('')
      this.initializeShippingRateQuantity()
    } else {
      shippingRateRepository.clearErrorMessages()
      shippingRateRepository.clearCrudData()
      shippingRateRepository.getById(data.id, result => {
        const { id, shippingDetailId, shippingMethod, shippingType, effectiveDate } = result
        this.form.update({
          id,
          shippingDetailId,
          shippingMethod,
          shippingType,
          effectiveDate,
        })

        this.setState(
          {
            showZoneSection: true,
            data: result,
          },
          () => {
            this.props.commonRepository.getCurrencies()
            this.props.zoneRepository.getById('')
            this.initializeShippingRateQuantity()
          }
        )
      })
    }
  }

  initializeShippingRateQuantity = async setNullIfNotFound => {
    const qtyRange = await this.getShippingRateQuantityRange()
    this.setShippingRateQuantityValue(qtyRange, setNullIfNotFound)
  }

  getShippingRateQuantityRange = async () => {
    const { commonRepository } = this.props
    const defaultCollectionName = 'shippingRateQuantityRange'

    const shippingType = this.form.$('shippingType').value
    const collectionName = shippingType ? `${defaultCollectionName}${shippingType}` : defaultCollectionName

    await commonRepository.getSelectItemCollectionByKey('shippingQtyRange', `collections/${collectionName}`, {
      default: defaultCollectionName,
    })

    const qtyRangeData = commonRepository.SelectItems.collections['shippingQtyRange']?.data || []
    if (qtyRangeData?.length) {
      const shippingQtyRange = qtyRangeData
        .map(item => {
          let quantityFrom = 0
          let quantityTo = 0
          const tokens = item.value?.split('-')?.map(item => item.trim())

          if (tokens.length) {
            quantityFrom = parseInt(tokens[0], 10)
            if (tokens.length > 1) {
              quantityTo = parseInt(tokens[1], 10)
            }
          }

          return {
            value: {
              quantityFrom: quantityFrom,
              quantityTo: quantityTo,
            },
            label: item.label,
          }
        })
        .sort((item1, item2) => item1.value.quantityFrom - item2.value.quantityFrom)

      this.setState({ shippingQtyRange })

      return shippingQtyRange
    }

    return []
  }

  setShippingRateQuantityValue = (qtyRange, setNullIfNotFound) => {
    let quantityValue = null
    if (qtyRange?.length) {
      const { data: { quantityFrom = 0, quantityTo = 0 } = {} } = this.state

      quantityValue = qtyRange.find(
        item => item.value.quantityFrom === quantityFrom && (item.value.quantityTo === quantityTo || quantityTo > 100)
      )
    }

    if (quantityValue) {
      this.form.$('quantity').set(quantityValue)
    } else if (setNullIfNotFound) {
      this.form.$('quantity').set(null)
    }
  }

  triggerSubmit = () => {
    this.buttonSubmit.click()
  }

  handleSubmit = () => {
    const { shippingRateRepository, isAdd } = this.props
    const { data } = this.state

    const { quantity, shippingMethod, ...rest } = this.form.values()
    const payload = {
      ...rest,
      shippingMethod: shippingMethod.toUpperCase(),
      quantityFrom: quantity.value.quantityFrom,
      quantityTo: quantity.value.quantityTo,
    }

    this.saving = true

    if (isAdd) {
      shippingRateRepository.create(payload, this.submitCallback, payload.shippingDetailId)
    } else {
      shippingRateRepository.patch({ ...payload, id: data.id }, this.submitCallback)
    }
  }

  submitCallback = () => {
    this.saving = false
    this.props.reloadTable()
    this.props.promptOnExit(false)
  }

  handleQuantityChange = selected => {
    const { data } = this.state
    this.setState({
      data: {
        ...data,
        quantityFrom: selected.value.quantityFrom,
        quantityTo: selected.value.quantityTo,
      },
    })
  }

  handleShippingTypeOnChange = () => {
    if (this.state.shippingQtyRange?.length) {
      this.setState({ shippingQtyRange: [] })
    }
  }

  handleShippingTypeOnBlur = () => {
    if (!this.state.shippingQtyRange?.length) {
      this.initializeShippingRateQuantity(true)
    }
  }

  render() {
    const {
      shippingQtyRange,
      data: { id, shippingMethod, zonePrices = [] },
      showZoneSection,
    } = this.state
    const {
      isShow,
      closeModal,
      promptOnExit,
      shippingRateRepository: {
        CRUD: { errors, loading: crudLoading },
      },
      commonRepository,
      zoneRepository,
    } = this.props
    const {
      Common: { currencies = {} },
      SelectItems,
    } = commonRepository
    const collectionloading = SelectItems.collections['shippingQtyRange']?.loading
    const loading = crudLoading || collectionloading || currencies.loading || zoneRepository.Table.loading

    return (
      <Modal
        size="lg"
        open={isShow}
        title={`FLAT RATE PER ${shippingMethod === 'frpq' ? 'QUANTITY' : 'PRODUCT'}`}
        loading={loading}
        loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
        confirmLabel="Save"
        confirmIcon="save"
        onConfirm={this.triggerSubmit}
        onClose={() => {
          closeModal()
          promptOnExit(false)
        }}
        closeLabel={id ? 'Close' : 'Cancel'}
        disabled={loading}
        hideConfirm={!allowedToOrderRole()}
      >
        <FormContainer
          title="Shipping Rates"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={errors || currencies.errors || zoneRepository.Table.errors}
          plain
        >
          <div className="grouped">
            <Input
              field={this.form.$('shippingType')}
              onChange={this.handleShippingTypeOnChange}
              onBlur={this.handleShippingTypeOnBlur}
            />
            <DatePicker field={this.form.$('effectiveDate')} />
          </div>
          <ReactSelect
            field={this.form.$('quantity')}
            options={{
              options: shippingQtyRange || [],
              isLoading: collectionloading,
              isClearable: false,
            }}
            onChange={this.handleQuantityChange}
          />
          {showZoneSection && (
            <ZonePrices
              form={this.form}
              zonePrices={zonePrices}
              commonRepository={commonRepository}
              zoneRepository={zoneRepository}
              promptOnExit={promptOnExit}
            />
          )}
          <button
            ref={ref => (this.buttonSubmit = ref)}
            type="submit"
            className="collapse"
            disabled={!allowedToOrderRole()}
          />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(
  inject('shippingRateRepository', 'commonRepository', 'zoneRepository')(observer(ShippingRateModal)),
  {}
)
