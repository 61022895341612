import React from 'react'

import 'toasted-notes/src/styles.css'
import styled from 'styled-components'
import toaster from 'toasted-notes'

import { getColor } from 'components/Common/Alert'
import alertManager from 'components/Common/AlertManager'
import IconButton from 'components/Common/Buttons/IconButton'

export default {
  confirm: ({ title, message, onConfirm, onCancel, color, confirmText }) => {
    alertManager.open({ title, message, onConfirm, onCancel, color, confirmText })
  },
  alert: ({ title, message, color = 'success', position = 'top-right', duration = 15000 }) => {
    toaster.notify(
      ({ onClose }) => {
        const { titleIcon, bgColor } = getColor(color)
        return (
          <ToasterStyled className={`mb-1 text-white bg-${bgColor}`}>
            <div className="toaster-title">
              <h5 className="d-flex align-items-center">
                <i className={`icon ion-${titleIcon}`} /> &nbsp;{title}
                <IconButton
                  id="toaster-close"
                  title="Close"
                  icon="md-close"
                  className={`text-white bg-${bgColor} ml-auto pull-right toaster-close`}
                  onClick={onClose}
                />
              </h5>
            </div>
            <div className="toaster-body d-flex">{message}</div>
          </ToasterStyled>
        )
      },
      { position, duration }
    )
  },
  close: () => {
    const el = document.getElementById('toaster-close')
    if (el) {
      setTimeout(() => el && el.click(), 50)
    }
  },
}

const ToasterStyled = styled.div`
  display: block;
  width: 450px;
  border-radius: 5px;
  font-size: 13px;
  // margin-top: 50px;
  z-index: 1090;
  button.bg-danger,
  &.bg-danger {
    background-color: rgba(215, 31, 31, 1) !important;
    color: #fff !important;
  }
  .Toaster__message {
    // margin-top: 50px;
  }
  .toaster-title {
    padding: 0.75rem 1.25rem 0;
    h5 {
      margin-bottom: 0;
      line-height: 1.5;
      text-transform: none;
      button {
        right: 25px;
        position: absolute;
      }
    }
  }
  .toaster-title h5,
  .toaster-body {
    font-size: 14px;
  }
  .toaster-body {
    padding: 0rem 1.3rem 1rem;
  }
`
