/* @flow */

import BaseRepository from './BaseRepository'
import CoTermServices, { CoTermProductServices } from 'services/coTerm'

class CoTermRepository extends BaseRepository {
  service: CoTermServices
  constructor(service: CoTermServices) {
    super(service)
    this.service = service
  }

  Products = {
    data: [],
    errors: [],
    loading: false,
    originalData: {},
    totalRecordCount: 0,
    delete: {
      loading: false,
      errors: [],
    },
  }
  create = payload => {
    return this.service.create(payload)
  }
}

export class CoTermProductRepository extends BaseRepository {
  service: CoTermProductServices
  constructor(service: CoTermProductServices) {
    super(service)
    this.service = service
  }
}

export default CoTermRepository
