import React, { Component } from 'react'

import { Badge, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'

import IconButton from 'components/Common/Buttons/IconButton'

class TitleActions extends Component {
  state = {
    type: 'frpp',
  }
  handleChangeTypeOfRate = type => {
    this.setState({ type }, () => this.props.handleChangeTypeOfRate(type))
  }
  render() {
    return (
      <TitleActionsStyled className="d-flex align-items-center mb-1">
        <h6 className="mr-2 mb-0">
          <Badge color="light-dark" className="text-uppercase px-3">
            {this.state.type === 'frpp' ? 'Flat Rate Per Product' : 'Flat Rate Per Quantity'}
          </Badge>
        </h6>
        <UncontrolledDropdown>
          <DropdownToggle tag="div">
            <IconButton icon="md-apps" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => this.handleChangeTypeOfRate('frpp')}>Flat Rate Per Product</DropdownItem>
            <DropdownItem onClick={() => this.handleChangeTypeOfRate('frpq')}>Flat Rate Per Quantity</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </TitleActionsStyled>
    )
  }
}

const TitleActionsStyled = styled.div`
  position: absolute;
  right: 20px;
  top: -50px;
`

export default TitleActions
