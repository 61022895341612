/* @flow */
import React, { Component } from 'react'

import { Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS, INACTIVE_STATUS, PAYMENT_ACCOUNTS_PATH } from 'definitions'
import { allowedTo } from 'helpers/Auth'
import { isNull } from 'helpers/Common'
import { titleize } from 'helpers/String'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

class BankAccountForm extends Component {
  constructor(props) {
    super(props)

    const {
      data: {
        type,
        name,
        status = ACTIVE_STATUS,
        vatInd = false,
        defaultAccountInd = false,
        countries,
        description,
      } = {},
    } = props
    const rules = 'required'
    const fields = {
      type: {
        label: 'Account Type',
        rules,
        value: type,
      },
      name: {
        label: 'Name',
        rules,
        value: name,
      },
      status: {
        type: 'switch',
        label: titleize(status),
        value: status === ACTIVE_STATUS,
      },
      defaultAccountInd: {
        type: 'switch',
        value: defaultAccountInd,
        label: 'Default',
      },
      vatInd: {
        type: 'switch',
        value: vatInd,
        label: 'VAT',
      },
      countries: {
        value: countries,
        label: 'Countries',
      },
      description: {
        type: 'textarea',
        label: 'Description',
        value: description,
        rules: 'max:500',
      },
    }

    this.form = new Form({ fields }, { name: 'PaymentAccountForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }

  componentDidMount() {
    this.props.commonRepository.getCommon('countries', 'countries')
    this.props.commonRepository.getCommon('paymentAccountTypes', 'collections/paymentAccountTypes')
  }

  handleSubmit = () => {
    const actualValues = this.form.values()
    const {
      paymentAccountRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props

    const requestAction = isAdd ? create : patch
    this.saving = true
    requestAction(
      {
        ...actualValues,
        ...(id && { id }),
        status: actualValues.status ? ACTIVE_STATUS : INACTIVE_STATUS,
        countries: isNull(actualValues.countries),
      },
      result => result && this.props.history.push(PAYMENT_ACCOUNTS_PATH)
    )
  }

  backToIndex = () => {
    this.props.history.push(PAYMENT_ACCOUNTS_PATH)
  }

  render() {
    const {
      commonRepository: {
        Common: {
          countries: { data: countries = [] } = {},
          paymentAccountTypes: { data: paymentAccountTypes = [] } = {},
        },
      },
      paymentAccountRepository: { CRUD: { data: { id }, errors, loading } = {} },
    } = this.props

    return (
      <Col xl={6}>
        <FormContainer
          title="Account Detail"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={errors}
          actions={
            <FormActions
              variant="contained"
              loading={loading}
              loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
              confirmLabel="Save"
              cancelLabel={id ? 'Close' : 'Cancel'}
              onCancel={this.backToIndex}
              hideConfirm={!allowedTo()}
            />
          }
        >
          <ReactSelect
            field={this.form.$('type')}
            options={{
              options: paymentAccountTypes,
            }}
            showTooltip
          />
          <InputText
            field={this.form.$('name')}
            endAdornment={<Checkbox field={this.form.$('status')} plain className="ml-1 mt-4" id="checkboxStatus" />}
          />
          <Checkbox field={this.form.$('vatInd')} id="checkboxVatInd" />
          <Checkbox field={this.form.$('defaultAccountInd')} id="checkboxDefaultInd" disabled={true} />
          <ReactSelect
            field={this.form.$('countries')}
            options={{
              options: countries,
              isMulti: true,
            }}
            showTooltip
          />
          <InputText field={this.form.$('description')} rows="4" />
        </FormContainer>
      </Col>
    )
  }
}

export default decorate(inject('paymentAccountRepository', 'commonRepository')(observer(BankAccountForm)), {})
