import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { ACTIVE_STATUS, INACTIVE_STATUS } from 'definitions'
import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { CustomInput } from 'reactstrap'
import { titleize } from 'helpers/String'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

class CollectionItemTable extends Component {
  table = React.createRef()

  handleCreate = () => {
    const { collectionName, history } = this.props
    history.push(`/collections/${collectionName}/new`)
  }

  handToggleStatus = async item => {
    const { collectionName } = this.props
    const payload = {
      ...item,
      collectionName,
      status: item.status && item.status === INACTIVE_STATUS ? ACTIVE_STATUS : INACTIVE_STATUS,
    }
    await this.props.collectionItemRepository.patch({ ...payload, collectionName })
    this.table && this.table.reload()
  }

  fetchItems = payload => {
    const {
      collectionName,
      collectionItemRepository: { fetchTable },
    } = this.props
    fetchTable(collectionName, { ...payload, showDeleted: true })
  }

  render() {
    const { collectionItemRepository, searchRepository, collectionName } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
    } = collectionItemRepository

    const columns = [
      { accessor: 'label', Header: 'Label' },
      { accessor: 'value', Header: 'Value' },
      {
        accessor: 'status',
        Header: 'Status',
        headerClassName: 'justify-content-center',
        className: 'text-center p-1 justify-content-center',
        width: 150,
        Cell: ({ original }) => (
          <CustomInput
            type="switch"
            label={titleize(original.status)}
            id={`switchStatus${original.id}`}
            className="mr-2"
            checked={original.status === ACTIVE_STATUS}
            onChange={() => this.handToggleStatus(original)}
            onClick={event => event.preventDefault()}
            disabled={!allowedToOrderRole()}
          />
        ),
      },
    ]

    return (
      <ReactTable
        searchRepository={searchRepository}
        pageKey={`collection-items-${collectionName}`}
        enableRowLink
        getRowUrl={({ original }) => `/collections/${collectionName}/${original.id}`}
        className="striped cell-vertical-center"
        columns={columns}
        data={data}
        loadData={this.fetchItems}
        loading={loading}
        totalRecordCount={totalRecordCount}
        totalPages={originalData.totalPages}
        ref={ref => (this.table = ref)}
        actions={
          <Visible>
            <div className="text-right">
              <IconButton color="dark" outline onClick={this.handleCreate} />
            </div>
          </Visible>
        }
        search
      />
    )
  }
}

export default inject('collectionItemRepository', 'searchRepository')(observer(CollectionItemTable))
