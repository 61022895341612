/* @flow */

import { decorate } from 'mobx'
import BankAccountServices from 'services/bankAccount'
import BaseRepository from './BaseRepository'

class BankAccountRepository extends BaseRepository {
  service: BankAccountServices
  constructor(service: BankAccountServices) {
    super(service)
    this.service = service
  }
  getBankInfoBySelectedBank = async (country, payload) => {
    return await this.service.getBankInfoBySelectedBank(country, payload)
  }
  getBankAccountsByOrder = async payload => {
    const { data, errors } = await this.service.getBankAccountsByOrder(payload)
    return { data, errors }
  }
}

export default decorate(BankAccountRepository, {})
