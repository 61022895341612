import React, { Component } from 'react'

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'

// import { getFormattedDateString } from 'helpers/Formatters'
import { PRODUCT_NATURE_VIRTUAL, PRODUCT_NATURE_VIRTUAL_NO_SN } from 'definitions'
import Checkbox from 'components/Common/Form/Checkbox'
import IconButton from 'components/Common/Buttons/IconButton'
import ToasterNotify from 'helpers/ToasterNotify'

class Actions extends Component {
  toggleReadOnly = () => {
    const { readOnly, toggleReadOnly } = this.props
    if (!readOnly) {
      ToasterNotify.confirm({
        message: 'Are you sure you want to cancel edit? This will undo all changes',
        title: 'Warning',
        onConfirm: toggleReadOnly,
      })
    } else {
      toggleReadOnly()
    }
  }
  render() {
    const { form, data, readOnly, handleSubmit, handleConvertToVirtualNoSN, handleCloneProduct } = this.props
    const { productNature = {} } = data

    return (
      <ActionsStyled>
        {/* <div className="launch-date d-inline-flex align-items-center mr-3">
          <span className="mr-2">Launch Date</span>
          <h6 className="mb-0">
            <Badge color="title" className="px-2 py-1 d-inline-flex align-items-center">
              <span className="mr-2">{data.launchDate && getFormattedDateString(data.launchDate)}</span>
              <i className="icon ion-md-calendar" />
            </Badge>
          </h6>
        </div> */}
        <Checkbox field={form.$('storeDisplayInd')} className="mr-2 mt-0" disabled={readOnly} readOnly={readOnly} />
        <UncontrolledDropdown>
          <DropdownToggle nav className="p-0">
            <IconButton icon="md-more" className="icon-more" iconSize="large" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={this.toggleReadOnly}>{readOnly ? 'Edit Product' : 'Cancel Edit'}</DropdownItem>
            <DropdownItem onClick={handleSubmit} disabled={readOnly}>
              Save Product
            </DropdownItem>
            {productNature.value === PRODUCT_NATURE_VIRTUAL && (
              <DropdownItem onClick={() => handleConvertToVirtualNoSN(PRODUCT_NATURE_VIRTUAL_NO_SN)}>
                Convert to Virtual No Serial Number
              </DropdownItem>
            )}
            {productNature.value === PRODUCT_NATURE_VIRTUAL_NO_SN && (
              <DropdownItem onClick={() => handleConvertToVirtualNoSN(PRODUCT_NATURE_VIRTUAL)}>
                Convert to Virtual
              </DropdownItem>
            )}
            <DropdownItem onClick={handleCloneProduct}>Clone Product</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </ActionsStyled>
    )
  }
}

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .launch-date {
    font-size: 11px;
  }
  .dropdown-menu {
    button {
      padding-left: 3rem;
    }
  }
  .icon-more {
    color: #bdbdbd;
  }
`

export default Actions
