/* @flow */
import { ACTIVE_STATUS, INACTIVE_STATUS } from 'definitions'
import { allowedTo } from 'helpers/Auth'
import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Visible } from 'helpers/Auth'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import React, { Component } from 'react'

type Props = {}

const getStatusLabel = active => (active ? 'Active' : 'Inactive')

class CollectionItemForm extends Component<Props> {
  constructor(props) {
    super(props)
    const { data: { label = '', value = '', status = ACTIVE_STATUS } = {} } = props

    const isActive = status === ACTIVE_STATUS

    const fields = {
      label: {
        type: 'text',
        label: 'Label',
        rules: 'required',
        value: label,
      },
      value: {
        type: 'text',
        label: 'Value',
        rules: 'required',
        value: value,
      },
      status: {
        type: 'switch',
        label: getStatusLabel(isActive),
        value: isActive,
      },
    }

    this.form = new Form({ fields }, { name: 'CollectionItemForm', handleSubmit: this.handleSubmit })
    this.state = {}
  }

  componentWillUnmount() {
    this.props.collectionItemRepository.clearErrorMessages()
  }

  handleCancel = () => {
    const {
      match: {
        params: { collectionName },
      },
      history,
    } = this.props
    history.push(`/collections/${collectionName}`)
  }

  handleSubmit = form => {
    const {
      collectionItemRepository: {
        CRUD: { data },
      },
      match: {
        params: { collectionName },
      },
      isEdit,
    } = this.props
    const { status, ...values } = form.values()
    const payload = { ...values, status: status ? ACTIVE_STATUS : INACTIVE_STATUS }

    if (isEdit) {
      this.props.collectionItemRepository.patch({ ...payload, id: data.id, collectionName }, this.handleCancel)
    } else {
      this.props.collectionItemRepository.create({ ...payload, id: data.id, collectionName }, this.handleCancel)
    }
  }

  render() {
    const {
      collectionItemRepository: { CRUD: { errors = [], loading, loadingFailed } = {} },
    } = this.props

    return (
      <Row>
        <Col lg={8}>
          <FormContainer
            title="Item Details"
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={loadingFailed && errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`Saving please wait...`}
                confirmLabel={`Save`}
                confirmIcon={`save`}
                onCancel={this.handleCancel}
                hideConfirm={!allowedTo()}
              />
            }
          >
            <InputText
              field={this.form.$('label')}
              endAdornment={
                <Visible>
                  <Checkbox field={this.form.$('status')} label={getStatusLabel(this.form.$('status').value)} />
                </Visible>
              }
            />
            <InputText field={this.form.$('value')} />
          </FormContainer>
        </Col>
      </Row>
    )
  }
}

export default decorate(inject('collectionItemRepository')(observer(CollectionItemForm)), {})
