/* @flow */

import bindings from './bindings'
import dvr from 'mobx-react-form/lib/validators/DVR'
import hooks from './hooks'
import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'
import vjf from 'mobx-react-form/lib/validators/VJF'

class Form extends MobxReactForm {
  bindings() {
    return bindings
  }

  hooks() {
    return hooks
  }

  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(validatorjs),
    }
  }

  options() {
    return {
      defaultGenericError: 'Invalid Data',
      autoParseNumbers: true,
      validateOnChange: true,
    }
  }
}

export default Form
