/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import CollectionItemForm from './CollectionItemForm'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

class CollectionItemAdd extends Component {
  componentDidMount() {
    this.props.collectionItemRepository.clearCrudData()
  }

  render() {
    const {
      match: {
        params: { collectionName },
      },
    } = this.props

    return (
      <ContentContainer title={`Add new ${collectionName}`}>
        <CollectionItemForm {...this.props} />
      </ContentContainer>
    )
  }
}

export default decorate(inject('collectionItemRepository')(observer(CollectionItemAdd)), {})
