/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'

class PaymentAccountRepository extends BaseRepository {
  constructor(service) {
    super(service)
    this.service = service
  }
  getBankInfoBySelectedBank = async (country, payload) => {
    return await this.service.getBankInfoBySelectedBank(country, payload)
  }
  getByCountryAndVat = async (country, vatInd, paymentAccount) => {
    const { data, errors } = await this.service.getByCountryAndVat(country, vatInd, paymentAccount)
    return { data, errors }
  }
  getByCustomer = async (customerId, paymentAccount) => {
    const { data, errors } = await this.service.getByCustomer(customerId, paymentAccount)
    return { data, errors }
  }
}

export default decorate(PaymentAccountRepository, {})
