/* @flow */
import React, { Component } from 'react'

import { Col, CustomInput, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedTo } from 'helpers/Auth'
import { STORES_PATH } from 'definitions'

import CommonSelect from 'components/Common/CommonSelect'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'
import StoreCountryMappingPanel from './StoreCountryMappingPanel'
import StoreFrontSlidesPanel from './StoreFrontSlidesPanel'
import StoreProductPanel from './StoreProductPanel'

class StoreForm extends Component {
  constructor(props) {
    super(props)

    const rules = 'required'
    const { data: { name = '', currency = '', website = '', timezone, status, orderNumberSettings = {} } = {} } = props
    const fields = {
      category: {
        type: 'text',
        label: 'Root Category',
        value: '',
      },
      currency: {
        type: 'text',
        label: 'Currency',
        value: currency ? { value: currency, label: currency } : null,
        rules,
      },
      name: {
        type: 'text',
        label: 'Name',
        value: name,
        rules,
      },
      status: {
        label: 'Active',
        value: status === 'Active',
      },
      website: {
        type: 'text',
        label: 'Website',
        value: website,
        rules: 'required|url',
      },
      timezone: {
        type: 'text',
        label: 'Time Zone',
        value: timezone,
        rules,
      },
      orderNumberSettings: {
        fields: {
          prefix: {
            type: 'text',
            label: 'Order Number Prefix',
            value: orderNumberSettings.prefix || '',
          },
          counterPadding: {
            type: 'number',
            label: 'Counter Padding',
            value: orderNumberSettings.counterPadding || 0,
          },
          startCounterFrom: {
            type: 'number',
            label: 'Start Counter From',
            value: orderNumberSettings.startCounterFrom || 1,
          },
        },
      },
    }

    this.form = new Form({ fields }, { name: 'StoreForm', handleSubmit: this.handleSubmit })
    this.state = { isProductModalOpen: false }
    this.props.commonRepository.getCurrencies()
  }

  handleSubmit = form => {
    const {
      storeRepository: {
        CRUD: {
          data: { id = null },
        },
        patch,
        create,
      },
    } = this.props

    const values = form.values()
    const requestAction = id ? patch : create
    requestAction(
      {
        id,
        ...values,
        currency: values.currency ? values.currency.value : null,
        status: values.status ? 'Active' : 'Inactive',
      },
      this.handleCancel
    )
  }

  handleCancel = () => this.props.history.push(STORES_PATH)

  render() {
    const {
      storeRepository: { CRUD: { errors = [], loading, loadingFailed } = {} },
      commonRepository,
      match: {
        params: { id },
      },
      storeView,
    } = this.props

    return (
      <Row>
        <Col lg={5}>
          <FormContainer
            title="Store Details"
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={loadingFailed && errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`Saving please wait...`}
                confirmLabel={`Save`}
                onCancel={this.handleCancel}
                hideConfirm={!allowedTo()}
              />
            }
          >
            <Row>
              <Col sm={12}>
                <Input field={this.form.$('name')} />
                <CustomInputStyled
                  type="switch"
                  label="Active"
                  id="activeStore"
                  defaultChecked={this.form.$('status').value}
                  onChange={e => this.form.$('status').set(e.target.checked)}
                />
              </Col>
            </Row>
            <Input field={this.form.$('website')} />
            <CommonSelect field={this.form.$('timezone')} collectionName="timezones" />
            <ReactSelect
              field={this.form.$('currency')}
              options={{
                options: commonRepository.Currencies.data,
                defaultOptions: true,
              }}
            />
            <Input field={this.form.$('orderNumberSettings.prefix')} />
            <Input field={this.form.$('orderNumberSettings.counterPadding')} />
            <Input field={this.form.$('orderNumberSettings.startCounterFrom')} />
          </FormContainer>
        </Col>
        {!!id && (
          <>
            {(!storeView || storeView === 'product') && <StoreProductPanel storeId={id} {...this.props} />}
            {storeView === 'country' && <StoreCountryMappingPanel storeId={id} {...this.props} />}
            {storeView === 'storefront' && <StoreFrontSlidesPanel storeId={id} {...this.props} />}
          </>
        )}
      </Row>
    )
  }
}

const CustomInputStyled = styled(CustomInput)`
  position: absolute !important;
  top: 35px !important;
  right: 20px !important;
`

export default decorate(inject('storeRepository', 'bankAccountRepository', 'commonRepository')(observer(StoreForm)), {})
