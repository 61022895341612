import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { Visible } from 'helpers/Auth'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CollectionDescriptionModal from 'components/CollectionItems/CollectionDescriptionModal'
import CollectionItemTable from 'components/CollectionItems/CollectionItemTable'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import IconButton from 'components/Common/Buttons/IconButton'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class CollectionItems extends Component {
  state = {
    isModalOpen: false,
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }

  componentDidMount() {
    const {
      match: {
        params: { collectionName },
      },
      collectionRepository: { getDescription },
    } = this.props

    getDescription(collectionName)
  }

  componentWillUnmount() {
    this.props.collectionRepository.resetDescription()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      match: {
        params: { collectionName },
      },
      collectionRepository,
    } = this.props
    const {
      Description: {
        data: { id, description },
        errors,
      },
    } = collectionRepository

    return (
      <React.Fragment>
        <ContentContainer
          title={
            <TitleStyled>
              <div className="title mb-1">
                {`Collection: ${collectionName}`}
                <Visible>
                  <IconButton
                    color="dark"
                    icon="md-create"
                    outline
                    title="Edit description"
                    iconSize="small"
                    onClick={this.toggleModal}
                  />
                </Visible>
              </div>
              {description && <div className="description">Description: {description}</div>}
            </TitleStyled>
          }
          breadcrumbLabel={collectionName}
          titleClassName="col-12"
          errors={errors}
        >
          <CollectionItemTable history={this.props.history} collectionName={collectionName} />
          {this.state.isModalOpen && (
            <CollectionDescriptionModal
              open
              toggle={this.toggleModal}
              collectionName={collectionName}
              description={description}
            />
          )}
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="CollectionItem"
                  entityId={id}
                  entityLabel="Collection"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={collectionName}
                  commentSource="collection"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

const TitleStyled = styled.div`
  .title {
    button {
      margin-left: 6px;
      border: none;
    }
  }
  .description {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
  }
`

export default inject('collectionRepository')(observer(CollectionItems))
