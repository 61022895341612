import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import ErrorsList from 'components/Common/ErrorsList'

class AppsDropdown extends React.Component {
  state = {
    isOpen: false,
    apps: [],
    loading: false,
    errors: [],
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    this.setState({ loading: true, errors: [] })
    const { data, errors } = await this.props.keycloakRepository.getClientApps()
    this.setState({ loading: false })

    if (errors?.length) {
      this.setState({ errors })
    } else {
      this.setState({ apps: data })
    }
  }

  handleAppClick = async appId => {
    this.setState({ errors: [] })
    const { data, errors } = await this.props.keycloakRepository.getClientAppUrl(appId)

    if (errors?.length) {
      this.setState({ errors })
    } else {
      if (data?.auth) {
        this.toggle()
        window.open(data.auth, '_blank')
      }
    }
    return false
  }

  render() {
    const { apps, loading, isOpen } = this.state

    return (
      <Dropdown isOpen={isOpen} active={isOpen} toggle={this.toggle} setActiveFromChild>
        <DropdownToggle tag="div" className="p-0">
          {this.props.render(this.toggle)}
        </DropdownToggle>
        <DropdownMenuStyled
          right
          onKeyUp={event => {
            if (event.key === 'Escape') {
              event.stopPropagation()
              this.toggle()
            }
          }}
        >
          {!!this.state.errors.length && (
            <ErrorsList errors={this.state.errors} className="px-2" alertClassName="pt-2 pb-1 px-2" />
          )}
          <div ref={this.appListRef} className="d-flex flex-wrap">
            {apps.length ? (
              apps.map(app => {
                return (
                  <DropdownItemStyled key={app.id} onClick={() => this.handleAppClick(app.id)}>
                    <ImageContainerStyled>
                      <img
                        src={`${process.env.REACT_APP_PEPLINK_SSO_SERVER_URL}${app.iconImage}`}
                        height="20px"
                        width="20px"
                      />
                    </ImageContainerStyled>
                    {app.name}
                  </DropdownItemStyled>
                )
              })
            ) : (
              <small className="px-3">{loading ? 'Loading Apps...' : 'No Application(s) Found.'}</small>
            )}
          </div>
        </DropdownMenuStyled>
      </Dropdown>
    )
  }
}

const DropdownMenuStyled = styled(DropdownMenu)`
  &.dropdown-menu {
    width: 394px;
    padding: 16px 8px;
  }
`

const DropdownItemStyled = styled(DropdownItem)`
  &.dropdown-item {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    color: inherit;
    cursor: pointer;
    width: 122px;
    padding: 8px;
    margin-bottom: 16px;
    background: #fff !important;
    outline: none !important;
    border: none !important;
    text-align: center;
    white-space: break-spaces;
    &:hover {
      background-color: rgb(231, 231, 231, 0.4) !important;
      border-radius: 8px;
      color: inherit;
    }
  }
`

const ImageContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
  border-radius: 50%;
  box-shadow: 2px 2px 8px rgb(119, 105, 72, 0.15);
  background: #ffffff;
  img {
    width: 20px;
    height: 20px;
  }
`

export default inject('keycloakRepository')(observer(AppsDropdown))
