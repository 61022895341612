import React, { Component } from 'react'

import { Badge, Button } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { CUSTOMERS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ImportCustomerModal from './ImportCustomerModal'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class CustomerPanel extends Component {
  table = null
  constructor(props) {
    super(props)
    this.state = {
      isOpenImportCustomerModal: false,
      selectedCustomers: [],
      groupId: props.match.params.groupId,
      bulkAction: null,
    }
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.groupId !== nextProps.match.params.groupId) {
      this.setState(
        {
          groupId: nextProps.match.params.groupId,
        },
        this.table.reload
      )
    }
  }
  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this customer?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }

  handleDelete = async () => {
    const { customerGroupDetailsRepository } = this.props
    await customerGroupDetailsRepository.delete(
      { id: this.state.groupId, customers: [{ id: this.state.itemToDelete }] },
      () => {
        this.table.reload()
      }
    )
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selectedCustomers } = this.state
    if (bulkAction === 'Remove') {
      const { customerGroupDetailsRepository } = this.props
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected customer(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await customerGroupDetailsRepository.delete(
            { id: this.state.groupId, customers: selectedCustomers.map(id => ({ id })) },
            () => {
              setTimeout(() => this.table.reload(), 300)
            }
          )
        },
      })
    }
  }
  handleSelectCustomer = ({ selection = {} }) => {
    this.setState({
      selectedCustomers: Object.keys(selection),
    })
  }
  handleSubmitImportCustomer = async selectedCustomers => {
    const {
      customerGroupDetailsRepository,
      selectedGroupData: { id },
    } = this.props

    customerGroupDetailsRepository.CRUD.submitting = true
    await customerGroupDetailsRepository.put({ id, customers: selectedCustomers }, () => {
      this.toggleImportUserModal()
      this.table.reload()
    })
    customerGroupDetailsRepository.CRUD.submitting = false
  }

  toggleImportUserModal = () => {
    this.setState({
      isOpenImportCustomerModal: !this.state.isOpenImportCustomerModal,
      selectedCustomers: [],
    })
  }
  render() {
    const { isOpenImportCustomerModal, selectedCustomers, groupId, bulkAction } = this.state
    const {
      selectedGroupData,
      selectedGroupData: { name = '' } = {},
      customerGroupDetailsRepository,
      searchRepository,
    } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
      CRUD: { errors },
      fetchTable,
    } = customerGroupDetailsRepository
    return (
      <>
        <Card
          title={name}
          titleActions={
            <Visible toAppAdminRole>
              <IconButton color="dark" outline onClick={this.toggleImportUserModal} />
            </Visible>
          }
        >
          <>
            {!isOpenImportCustomerModal && errors.length > 0 && (
              <div style={{ margin: '20px' }}>
                <ErrorsList errors={errors} />{' '}
              </div>
            )}
            <ReactTable
              searchRepository={searchRepository}
              pageKey="customer-groups-customers"
              enableRowLink
              getRowUrl={({ original }) => `${CUSTOMERS_PATH}/${original.id}`}
              multiSelect={allowedToAppAdminRole()}
              className="striped cell-vertical-center"
              ref={ref => (this.table = ref)}
              columns={[
                {
                  accessor: 'name',
                  Header: 'Name',
                  Cell: ({ original }) => (
                    <span title={original.name} className="pr-2 ellipsis">
                      {original.name}
                    </span>
                  ),
                },
                {
                  accessor: 'sanctioned',
                  Header: 'Restriction',
                  width: 160,
                  Cell: ({ original }) =>
                    original.sanctioned ? (
                      <Badge color="dark">Blacklist</Badge>
                    ) : (
                      <Badge color="light">Whitelist</Badge>
                    ),
                },
                {
                  Header: 'Actions',
                  headerClassName: 'justify-content-center',
                  className: 'justify-content-center p-1',
                  show: allowedToAppAdminRole(),
                  width: 80,
                  Cell: ({ original }) => (
                    <IconButton
                      icon="ios-trash"
                      onClick={event => {
                        event.preventDefault()
                        this.confirmDeletion(original.id)
                      }}
                    />
                  ),
                },
              ]}
              data={data}
              loadData={params => fetchTable(params, groupId)}
              loading={loading}
              totalRecordCount={totalRecordCount}
              totalPages={originalData.totalPages}
              search
              selectionChanged={this.handleSelectCustomer}
              actions={
                <Visible toAppAdminRole>
                  <div className="d-flex justify-content-end align-items-center mb-1 px-3">
                    <ReactSelectStyled
                      className="mr-2"
                      plain={true}
                      options={{
                        placeholder: 'Bulk Action',
                        options: [{ value: 'Remove', label: 'Remove' }],
                      }}
                      disabled={!selectedCustomers.length}
                      onChange={vals => {
                        this.setState({ bulkAction: vals && vals.value })
                      }}
                    />
                    <Button
                      size="sm"
                      color="dark"
                      type="button"
                      disabled={!bulkAction}
                      onClick={this.handleSubmitBulkAction}
                    >
                      Submit
                    </Button>
                  </div>
                </Visible>
              }
              selectElementId="customerPanelTable"
              sort={{ id: 'name' }}
            />
          </>
        </Card>
        {isOpenImportCustomerModal && (
          <ImportCustomerModal
            open={isOpenImportCustomerModal}
            onConfirm={this.handleSubmitImportCustomer}
            onClose={this.toggleImportUserModal}
            selectedGroupData={selectedGroupData}
          />
        )}
      </>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`

export default decorate(
  inject('customerGroupRepository', 'customerGroupDetailsRepository', 'searchRepository')(observer(CustomerPanel)),
  {}
)
