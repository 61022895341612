import { decorate, observable } from 'mobx'

class SearchRepository {
  Pages = {}

  updateSearchPage = (pageKey, newValues) => {
    if (newValues) {
      this.Pages[pageKey] = {
        search: null,
        page: 1,
        pageSize: 10,
        sort: {},
        filters: {},
        ...this.Pages[pageKey],
        ...newValues,
      }
    }
  }
}

export default decorate(SearchRepository, {
  Pages: observable,
})
