import React from 'react'

import cx from 'classnames'

import { CustomInput, FormGroup, FormText, Label } from 'reactstrap'
import { observer } from 'mobx-react'

const RadioGroup = ({
  field,
  subLabel = '',
  radios = [],
  row = true,
  onChange = null,
  radioProps = null,
  inline = false,
  defaultValue,
  className = '',
  radioListClassName = '',
  label: propsLabel,
  id = Math.random(),
  disabled = false,
}) => {
  const { value, name = '' } = field
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  const label = propsLabel || field.label

  const handleOnChange = newValue => {
    field.onChange(newValue)
    field.validate()

    if (onChange) {
      onChange(newValue)
    }
  }
  return (
    <FormGroup className={className}>
      {label && <Label className="col-form-label">{label}</Label>}
      <div className={cx('form-group-radio-list', radioListClassName)}>
        {radios.map((radio, index) => (
          <React.Fragment key={index}>
            <CustomInput
              type="radio"
              id={`radio-${id}-${index}`}
              name={`${name}-${id}`}
              value={radio.value}
              label={radio.label}
              checked={(defaultValue || value) === radio.value}
              onChange={() => handleOnChange(radio.value)}
              {...radioProps}
              inline={inline}
              disabled={disabled}
              className={radio.className}
            />
            {radio.children}
          </React.Fragment>
        ))}
      </div>
      {subLabel ? <small>{subLabel}</small> : null}
      {hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </FormGroup>
  )
}

export default observer(RadioGroup)
