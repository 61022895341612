/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import CategoryServices from 'services/category'

class CategoryRepository extends BaseRepository {
  service: CategoryServices
  constructor(service: CategoryServices) {
    super(service)
    this.service = service
  }

  Products = {
    data: [],
    errors: [],
    loading: false,
    originalData: {},
    totalRecordCount: 0,
    delete: {
      loading: false,
      errors: [],
    },
  }

  remove = payload => {
    return this.service.remove(payload)
  }

  move = payload => {
    return this.service.move(payload)
  }

  importProducts = async (payload, id, callback) => {
    this.Products.loading = true
    this.Products.submitting = true
    const { data, errors } = await this.service.importProducts(payload, id)
    this.Products.submitting = false
    if (data && !errors.length) {
      this.Products.originalData = data
      this.Products.data = data.content
      this.Products.totalRecordCount = data.totalElements
      if (callback) callback(data)
    } else this.Products.errors = errors
    this.Products.loading = false
  }

  deleteProduct = async (categoryId, productId, callback) => {
    const { data, errors } = await this.service.deleteProduct(categoryId, productId)
    this.Products.delete.loading = true
    if (data && !errors.length) {
      if (callback) callback(data)
    } else this.Products.delete.errors = errors
    this.Products.delete.loading = false
  }

  uploadImage = async (categoryId, payload) => {
    const { data, errors } = await this.service.uploadImage(categoryId, payload)
    this.CRUD.loading = true
    this.CRUD.errors = []
    if (errors.length) {
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
    return { data, errors }
  }
}

export default decorate(CategoryRepository, {
  Products: observable,
})
