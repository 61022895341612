import { Col } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedTo, Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
// import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import StoreFrontSlideModal from 'components/Stores/StoreForm/StoreFrontSlideModal'
import ToasterNotify from 'helpers/ToasterNotify'

class StoreFrontSlidesPanel extends Component {
  state = {
    isModalOpen: false,
    isEditMode: false,
    selected: null,
  }

  handleView = selected => {
    this.toggleModal(true, selected)
  }

  handleDelete = (storeId, id) => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this storefront marketing slides?',
      title: 'Warning',
      onConfirm: () => {
        this.props.storefrontSlideRepository.delete({ storeId, id }, () => {
          this.table.reload()
        })
      },
    })
  }

  toggleModal = (isEditMode, selected) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      isEditMode,
      selected,
    })
  }

  setSelected = selected => {
    this.setState({
      selected: selected,
    })
  }

  reloadTable = () => {
    this.table && this.table.reload()
  }

  render() {
    const { isModalOpen, isEditMode, selected } = this.state
    const { storefrontSlideRepository, storeId } = this.props
    const {
      List: { data, loading },
      fetchList,
    } = storefrontSlideRepository

    return (
      <Col lg={7}>
        <Card
          title="Storefront Marketing Slides"
          titleActions={
            <Visible>
              <IconButton
                title="Add Marketing Slide"
                color="dark"
                className="mb-1"
                outline
                onClick={() => this.toggleModal(false)}
              />
            </Visible>
          }
        >
          <ReactTable
            className="striped cell-vertical-center"
            columns={[
              {
                accessor: 'name',
                Header: 'Name',
                sortable: false,
                width: 180,
              },
              {
                accessor: 'description',
                Header: 'Description',
                sortable: false,
              },
              {
                accessor: 'learnMoreLink',
                Header: 'Learn More Url',
                sortable: false,
                width: 100,
                Cell: ({ original }) =>
                  original.learnMoreLink ? (
                    <a
                      href={original.learnMoreLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={original.learnMoreLink}
                      className="text-primary"
                    >
                      Learn More
                    </a>
                  ) : (
                    ''
                  ),
              },
              {
                Header: 'Actions',
                headerClassName: 'justify-content-center',
                className: 'p-1 justify-content-center',
                show: allowedTo(),
                width: 100,
                Cell: ({ original }) => (
                  <IconButton icon="ios-trash" onClick={() => this.handleDelete(storeId, original.id)} />
                ),
              },
            ]}
            data={data}
            loadData={() => fetchList(storeId)}
            loading={loading}
            showPager={false}
            showRowPerPage={false}
            ref={ref => (this.table = ref)}
            handleRowClick={({ original }) => this.handleView(original)}
          />
          {isModalOpen && (
            <StoreFrontSlideModal
              storefrontSlideRepository={storefrontSlideRepository}
              storeId={storeId}
              isModalOpen={isModalOpen}
              data={selected}
              editMode={isEditMode}
              toggleModal={() => this.toggleModal(true)}
              onReloadTable={this.reloadTable}
            />
          )}
        </Card>
      </Col>
    )
  }
}

export default inject('storefrontSlideRepository')(observer(StoreFrontSlidesPanel))
