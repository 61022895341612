import { Col } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import { formatNumber, getFormattedDateTimeString } from 'helpers/Formatters'
import Input from 'components/Common/Form/Input'
import Loader from 'components/Common/Loader'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class StorePricePanel extends Component {
  storePrices = []
  componentDidMount = () => {
    this.loadStorePrices()
  }
  initializeFields = () => {
    this.props.form.update({ prices: this.storePrices })
  }
  applyStockStatusToStores = stockStatus => {
    this.props.form.$('prices').map(field => {
      field.$('stockStatus').set(stockStatus)
      return false
    })
  }
  loadStorePrices = async () => {
    const {
      storeRepository,
      commonRepository,
      data: { prices = [] },
    } = this.props

    await storeRepository.fetchTable({ size: '999' })
    await commonRepository.getCommon('stockStatus', 'stockStatus/items')

    this.storePrices = toJS(
      storeRepository.Table.data
        .map(store => {
          let { price = {}, stockStatus } = prices.find(item => item.store && item.store.id === store.id) || {}

          if (!price || !price.amount) {
            price = { amount: 0, currency: store.currency }
          }
          return {
            store: {
              id: store.id,
              name: store.name,
              currency: store.currency,
            },
            price,
            stockStatus,
          }
        })
        .filter(item => item.price)
    )

    this.initializeFields()
  }
  renderStorePriceField = storeField => {
    const { readOnly } = this.props
    const field = storeField.$('price.amount')
    const store = storeField.$('store').value

    field.set('label', store.name)
    field.set('type', 'number')

    return (
      <Col sm="3" key={`priceField${store.id}`}>
        {readOnly && (
          <ReadOnlyField
            value={
              <div className="d-flex justify-content-between">
                <span>{store.currency}</span>
                <span>{formatNumber(field.value)}</span>
              </div>
            }
            label={store.name}
          />
        )}
        {!readOnly && <Input startAdornment={<span className="mt-2">{store.currency}</span>} field={field} />}
      </Col>
    )
  }
  renderStockStatusField = (stockStatusOptions, storeField) => {
    const { readOnly } = this.props
    const store = storeField.$('store').value
    const stockStatusField = storeField.$('stockStatus')

    stockStatusField.set('label', store.name)

    return (
      <Col sm="3" key={`stockStatusField${store.id}`}>
        <ReactSelect
          field={stockStatusField}
          readOnly={readOnly}
          placeholder="Stock Status"
          showTooltip
          options={{
            valueKey: 'value',
            labelKey: 'label',
            options: stockStatusOptions,
            defaultOptions: true,
          }}
          onChange={value => {
            stockStatusField.set(value)
          }}
        />
      </Col>
    )
  }
  render() {
    const {
      storeRepository: {
        Table: { loading },
      },
      productRepository: {
        Table: { loading: productLoading },
      },
      commonRepository: {
        Common: { stockStatus: { data: { content: stockStatusOptions = [] } = {} } = {} },
      },
      data,
      form,
      readOnly,
    } = this.props

    return (
      <div className="w-100 h-100 d-flex flex-column">
        {loading && <Loader loading={loading || productLoading} />}
        <div className="mb-5">
          <SectionTitleStyled>Prices</SectionTitleStyled>
          <div className="d-flex flex-wrap">{form.$('prices').map(this.renderStorePriceField)}</div>
        </div>
        <div className="mb-5">
          <SectionTitleStyled>Stock Status</SectionTitleStyled>
          {!readOnly && (
            <Col sm="5" className="pr-0 mb-2">
              <ReactSelect
                field={form.$('allStoresStockAvailability')}
                readOnly={readOnly}
                label="Apply to all stores"
                showTooltip
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  options: stockStatusOptions,
                  defaultOptions: true,
                  isClearable: true,
                }}
                onChange={value => this.applyStockStatusToStores(value)}
              />
            </Col>
          )}
          <div className="d-flex flex-wrap">
            {form.$('prices').map(item => this.renderStockStatusField(stockStatusOptions, item))}
          </div>
        </div>
        {data.priceModifiedDate && (
          <small className="mt-auto">Price Modified on {getFormattedDateTimeString(data.priceModifiedDate)}</small>
        )}
      </div>
    )
  }
}

const SectionTitleStyled = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
`

export default inject('productRepository', 'storeRepository', 'commonRepository')(observer(StorePricePanel))
