/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import OrderTypeServices from 'services/orderType'

class OrderTypeRepository extends BaseRepository {
  service: OrderTypeServices
  constructor(service: OrderTypeServices) {
    super(service)
    this.service = service
  }
}

export default decorate(OrderTypeRepository, {})
