import React, { Component } from 'react'

import { Button, Col, FormGroup, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import styled from 'styled-components'

import { formatCustomerCurrency } from 'helpers/Formatters'
import { ORDER_TYPE_LOAN_ORDER } from 'definitions'
import CouponContainer from './CouponContainer'
import Input from 'components/Common/Form/Input'
import MobxForm from 'components/Common/Form/index'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import SupportingDocumentModal from './SupportingDocumentModal'

class DiscountContainer extends Component {
  constructor(props) {
    super(props)
    const fields = {
      orderItems: {
        value: this.convertOrderItems(props.orderItems),
      },
    }
    this.state = {
      isSupportingDocumentModalOpen: false,
    }
    this.form = new MobxForm(
      {
        fields,
      },
      {
        name: 'DiscountContainerForm',
        hooks: {
          onSuccess: form => {
            this.handleSubmitDiscount(form)
          },
        },
      }
    )

    // this.handleDiscountPercentage = debounce(this.handleDiscountPercentage, 350).bind(this)
  }
  convertOrderItems = orderItems => {
    return toJS(orderItems).map(item => {
      item.hasDiscount = true
      item.discount = item.discount || {}
      return item
    })
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orderItems.length) {
      nextProps.form.$('orderItems').set(this.convertOrderItems(nextProps.orderItems))
    }
  }
  handleSubmitDiscount = form => {
    const values = this.props.form.values()
    const orderItems = values.orderItems.map(oi => ({
      ...oi,
      quantity: oi.quantity,
    }))

    const payload = {
      order: {
        ...this.props.order,
        orderItems,
      },
      discount: values.discount,
    }

    this.props.orderItemRepository.patchDiscount(payload, this.props.order.id, data => {
      this.props.updateOrderData(data.order)
    })
  }
  handleDiscountPercentage = value => {
    const inputVal = value ? value : ''
    const orderItems = this.form.$('orderItems')
    this.props.form.$('discount.percentage').set(inputVal)

    orderItems.set(
      orderItems.value.map(item => {
        const itemPrice = item.listedPrice
        if (inputVal) {
          const dp = itemPrice - itemPrice * (inputVal / 100).toFixed(2)
          item.discountedPrice = Number(dp) < 0 ? 0 : dp
        } else {
          item.discountedPrice = item.listedPrice
        }

        return item
      })
    )
    this.form.submit()
  }
  handleChangeDiscountInd = val => {
    this.props.setDiscountInd(val)
    if (val === 'ND' || val === 'C') {
      const order = { ...this.props.orderRepository.CRUD.data }
      this.props.orderRepository.removeDiscount(
        order.id,
        { order, ...(val === 'C' && { removeCoupon: true }) },
        data => {
          this.props.updateOrderData({ ...data })
        }
      )
    } else if (val === 'P') {
      this.handleDiscountPercentage(this.props.form.$('discount.percentage').value)
    }
  }
  handleFileChange = e => this.form.$('file').set(this.uploadFile.current.files[0])
  toggleSupportingDocumentModal = () => {
    this.setState({
      isSupportingDocumentModalOpen: !this.state.isSupportingDocumentModalOpen,
    })
  }

  render() {
    const { isSupportingDocumentModalOpen } = this.state
    const { store, updateOrderData, form, readOnly, readOnlyCoupon, order } = this.props

    const discountType = form.$('discount.discountInd').value
    const customer = form.$('customer').value
    const files = form.$('discountApproval.attachments').value || []

    return (
      <Row>
        <Col sm={6}>
          <Row>
            <Col sm={3}>
              <RadioGroup
                field={form.$('discount.discountInd')}
                radios={[
                  {
                    label: 'No Discount Offer',
                    value: 'ND',
                  },
                  {
                    label: 'Add % Discount',
                    value: 'P',
                  },
                  {
                    label: 'Add Custom Discount',
                    value: 'C',
                  },
                ]}
                radioProps={{
                  disabled: readOnly,
                }}
                onChange={this.handleChangeDiscountInd}
              />
            </Col>
            <Col sm={9} className="p-0">
              <Row className="m-0">
                {discountType === 'P' && (
                  <Col sm={6}>
                    <Input
                      readOnly={readOnly}
                      field={form.$('discount.percentage')}
                      disabled={discountType !== 'P'}
                      max={100}
                      min={1}
                      onBlur={e => {
                        // debounce(this.handleDiscountPercentage, 350)
                        this.handleDiscountPercentage(e.target.value)
                      }}
                    />
                  </Col>
                )}
                {(discountType === 'P' || discountType === 'C') && (
                  <Col sm={6}>
                    {readOnly ? (
                      <AttachmentContainer>
                        <FormGroup>
                          <label>Custom Discount Approval</label>
                        </FormGroup>
                        <div className="attachments">
                          {/* eslint-disable-next-line no-extra-boolean-cast */}
                          {!!files.length ? (
                            files.map((file, i) => (
                              <div key={i} className="ellipsis">
                                <a
                                  className="text-primary"
                                  href={`${process.env.REACT_APP_ATTACHMENT_SERVER}${file.resourcePath}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={file.name || file.fileName}
                                >
                                  <i className="icon ion-md-attach" /> {file.name || file.fileName}
                                </a>
                              </div>
                            ))
                          ) : (
                            <small>No Attachment</small>
                          )}
                        </div>
                      </AttachmentContainer>
                    ) : (
                      <>
                        <Button
                          disabled={readOnly}
                          size="sm"
                          color="primary"
                          type="button"
                          onClick={this.toggleSupportingDocumentModal}
                        >
                          SUPPORTING
                        </Button>
                        {isSupportingDocumentModalOpen && (
                          <SupportingDocumentModal
                            open={isSupportingDocumentModalOpen}
                            onClose={this.toggleSupportingDocumentModal}
                            form={form}
                          />
                        )}
                      </>
                    )}
                  </Col>
                )}
                {order.orderType.name === ORDER_TYPE_LOAN_ORDER && (
                  <Col sm={6}>
                    <Input readOnly={readOnly} field={form.$('loanSubscriptionDays')} />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Row>
            <Col sm={6}>
              {discountType !== 'C' && (
                <>
                  <div className="grouped">
                    <Input field={form.$('dealId')} readOnly={readOnly} className="mb-3 pr-2" showTooltip />
                    <Input
                      field={form.$('referralCode')}
                      readOnly={readOnly}
                      className="mb-3 pl-2"
                      showTooltip
                      onBlur={event => {
                        form.$('referralCode').set(event.target.value?.toUpperCase())
                      }}
                    />
                  </div>
                  <CouponContainer
                    readOnly={readOnlyCoupon}
                    updateOrderData={updateOrderData}
                    discountType={discountType}
                    customer={customer}
                    orderForm={form}
                  />
                  {order.referralProgramCode && (
                    <ReadOnlyField label="Referral Code" value={order.referralProgramCode} showTooltip />
                  )}
                </>
              )}
            </Col>
            <ColSubtotalStyled sm={6} className="d-flex">
              <Row className="align-items-end flex-fill mx-0">
                <Col sm={6} className="align-items-end text-right">
                  Subtotal{' '}
                </Col>
                <Col sm={6} className="subtotal align-items-end pl-0">
                  {store && store.currency} {formatCustomerCurrency(order.orderSummary.subTotal, customer)}
                </Col>
              </Row>
            </ColSubtotalStyled>
          </Row>
        </Col>
      </Row>
    )
  }
}

const AttachmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  .form-group {
    label {
      margin-bottom: 0;
    }
  }
  .attachments {
    max-height: 50px;
    overflow: hidden;
    overflow-y: auto;
    width: 250px;
    border-bottom: 1px solid #ccc;
    padding: 0.375rem 0.75rem;
  }
  a {
    font-size: 14px;
    i {
      font-size: 14px;
    }
  }
`

const ColSubtotalStyled = styled(Col)`
  position: relative;

  .row {
    border-bottom: 1px solid #ccc;
    margin-bottom: 4px;
    .subtotal {
      font-size: 18px;
      font-weight: 400;
    }
  }
`

export default inject('orderRepository', 'orderItemRepository')(observer(DiscountContainer))
