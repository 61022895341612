import React from 'react'

import { Collapse } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import CommentSidePanel from 'components/Settings/CommentSidePanel'

class ShippingZoneCommentLogSidePanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeZoneId: null,
    }
  }

  togglePanel = activeZoneId => {
    let isOpen = false
    if (activeZoneId !== this.state.activeZoneId) {
      isOpen = true
    } else {
      isOpen = !this.state.activeZoneId
    }
    this.setState({ activeZoneId: isOpen ? activeZoneId : null })
  }

  render() {
    const { zones = {}, closeDrawer } = this.props
    const { activeZoneId } = this.state

    return (
      <div>
        <TitleStyled>Shipping Zone Comments</TitleStyled>
        {zones.map(item => {
          const isOpen = activeZoneId === item.id
          return (
            <CardStyled
              key={item.id}
              title={
                <button className={cx('btn', isOpen && 'btn-primary')} onClick={() => this.togglePanel(item.id)}>
                  {item.zone.label}
                </button>
              }
              className="mb-2 border-1"
            >
              <Collapse isOpen={isOpen}>
                <CommentSidePanel
                  referenceId={item.id}
                  commentSource="zoneCountry"
                  closeDrawer={closeDrawer}
                  hideClose
                />
              </Collapse>
            </CardStyled>
          )
        })}
      </div>
    )
  }
}

const TitleStyled = styled.h6`
  margin-top: 0.5rem;
  font-size: 0.875rem;
`

const CardStyled = styled(Card)`
  &.card {
    .card-header {
      padding: 0;
      .card-title {
        min-height: unset;
        width: 100%;
        height: 100%;
        padding: 0;
        margin-right: 4px;
        .btn {
          padding: 12px;
          width: 100%;
          height: 100%;
          text-align: left;
          border: none !important;
          box-shadow: none !important;
          outline: none !important;
        }
      }
    }
    .card-body {
      padding: 0;
      .comment-side-panel {
        padding: 4px 12px;
        min-height: 150px;
      }
    }
  }
`

export default inject('settingsCommentRepository')(observer(ShippingZoneCommentLogSidePanel))
