import { FormGroup, Label } from 'reactstrap'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'

export default class ImagePanel extends React.Component {
  state = {
    loading: false,
  }
  constructor(props) {
    super(props)

    this.fileInput = React.createRef()
  }
  handleFileChange = async files => {
    if (files.length) {
      this.setState({ loading: true })
      const { storefrontSlideRepository, id, name, onReloadTable } = this.props
      const formFiles = new FormData()
      const file = files[0]
      formFiles.append(name, file)
      const { data } = await storefrontSlideRepository.uploadImage(id, formFiles)
      if (data) {
        this.props.onChange(name, data)
        onReloadTable()
      }
      this.setState({ loading: false })
    }
  }
  handleImageError = event => {
    event.target.setAttribute('src', require(`images/no-image.svg`).default)
  }
  render() {
    const { storefrontSlideRepository, image = {}, label, size, onToggleModal } = this.props
    const {
      CRUD: { loading },
    } = storefrontSlideRepository
    const hasImage = !!image.resourcePath

    return (
      <FormGroup>
        <Label>{label}</Label>
        <ImageStyled className="d-flex-center">
          {hasImage && (
            <img
              src={`${process.env.REACT_APP_ATTACHMENT_SERVER}${image.resourcePath}`}
              alt={image.fileName || label}
              title={image.fileName}
              onError={this.handleImageError}
            />
          )}
          {this.state.loading && <Loader text="Please wait..." className="loader centered" />}
          <div className="actions centered">
            {hasImage && (
              <IconButton
                title="View image"
                icon="md-eye"
                className="large mr-3"
                disabled={loading}
                onClick={() => onToggleModal({ ...image, size })}
              />
            )}
            <IconButton
              title={`${image.resourcePath ? 'Replace image' : 'Upload image'} (auto-save)`}
              icon="md-cloud-upload"
              className={cx('large mr-3', !hasImage && 'no-image')}
              disabled={loading}
              onClick={() => {
                this.fileInput.current.click()
              }}
            />
          </div>
          <input
            type="file"
            ref={this.fileInput}
            accept="image/*"
            onChange={event => this.handleFileChange(event.target.files)}
            className="collapse"
          />
        </ImageStyled>
      </FormGroup>
    )
  }
}

const ImageStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  border: 1px solid ${props => props.theme.light};
  img {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
  }
  .centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .actions {
    button {
      display: none;
      &.no-image {
        display: block;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      button {
        display: block;
      }
    }
  }
`
