/* eslint-disable radix */
/* @flow */
import 'react-table/react-table.css'
import { Col, FormGroup, Input, Row } from 'reactstrap'
import { decorate, observable } from 'mobx'
import { observer } from 'mobx-react'
import _ from 'lodash'
import cx from 'classnames'
import React, { Component } from 'react'
import RT from 'react-table'

import { isArrayOrObject } from 'helpers/Common'
import { ReactTableContainerStyled } from './ReactTableContainerStyled'
import ErrorCatcher from './ErrorCatcher'
import Loader from 'components/Common/Loader'
import Pager from '../Pager'
import TableRowLink from './TableRowLink'
import withFixedColumn from './FixedColumn'
import withMultiSelect from './MultiSelect'

class ReactTable extends Component {
  loading = false
  originalData = {}
  currPage = 1
  totalRecords = 0
  sort = this.props.sort || {}
  filters = this.props.filters || {}
  defaultPageSize = 10
  search = this.props.searchCriteria
  state = {
    page: 1,
    pageSize: this.defaultPageSize,
  }

  constructor(props) {
    super(props)
    this.defaultPageSize = props.defaultPageSize || 10
    this.onSearch = _.debounce(this.onSearch, 350, { leading: false, trailing: true }).bind(this)

    const { fixedColumns, multiSelect } = this.props

    const table = fixedColumns ? withFixedColumn(RT) : RT
    this.tableComponent = multiSelect ? withMultiSelect(table) : table

    this.initializeCachedSearch()
  }
  componentDidMount() {
    this.handleLoadData()
  }
  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.filters, nextProps.filters)) {
      this.filters = nextProps.filters
      this.currPage = 1
      this.handleLoadData()
    }
  }
  initializeCachedSearch = () => {
    const { searchRepository, pageKey } = this.props
    if (searchRepository && pageKey) {
      if (searchRepository.Pages[pageKey]) {
        const criteria = searchRepository.Pages[pageKey]
        this.search = criteria.search
        this.currPage = criteria.page
        this.defaultPageSize = criteria.pageSize
        this.sort = criteria.sort || {}
      } else {
        searchRepository.updateSearchPage(pageKey, {
          search: this.props.searchCriteria,
          sort: this.props.sort,
          filters: this.props.filters,
        })
      }
    }
  }
  handleLoadData = () => {
    this.loadData({
      ...(this.search && { search: this.search }),
      page: this.currPage - 1,
      sort: this.sort.id ? `${this.sort.id},${this.sort.desc ? 'desc' : 'asc'}` : null,
      size: this.defaultPageSize,
      ...(this.props.filters || this.filters),
    })
  }
  loadData = params => {
    if (this.props.loadData) {
      this.props.loadData(params)
    }
  }
  onChangePage = page => {
    if (this.currPage === page || this.loading || page < 1 || page > this.props.totalPages) {
      return
    }
    this.currPage = page

    this.updateSearchPage({ page: this.currPage })

    if (this.props.manual) {
      this.handleLoadData()

      //reset selection when switching pages for serverside only
      this.setSelection({})
    } else {
      this.setState({ page })
    }
  }
  onDefaultPageSizeChange = event => {
    this.defaultPageSize = parseInt(event.target.value)
    //reset page to 1 to safely re-structure pagination
    this.currPage = 1
    if (!this.props.manual) {
      this.setState({
        page: this.currPage,
        pageSize: this.defaultPageSize,
      })
    }

    this.updateSearchPage({
      page: this.currPage,
      pageSize: this.defaultPageSize,
    })

    if (this.props.manual) {
      this.handleLoadData()
    }
  }
  onSortChange = params => {
    const { id, desc } = params[0]
    this.sort = { id, desc }

    this.updateSearchPage({ sort: this.sort })
    if (this.props.manual) {
      this.handleLoadData()
    }
  }
  onSearch = value => {
    this.search = value
    this.currPage = 1

    if (!this.props.manual) {
      this.setState({ page: this.currPage })
    }
    this.updateSearchPage({
      search: this.search,
      page: this.currPage,
    })
    this.handleLoadData()
  }
  reload = (isReset = false, isResetPageOnly = false) => {
    if (isReset) {
      this.currPage = 1
      this.search = null
      if (this.textSearch) this.textSearch.value = null
    }
    if (isResetPageOnly) {
      this.currPage = 1
    }

    this.handleLoadData()
    this.setSelection({})
    if (!this.props.manual) {
      this.setState({ page: this.currPage })
    }
  }
  resetPage = () => {
    this.currPage = 1
    if (!this.props.manual) {
      this.setState({ page: this.currPage })
    }
  }
  setSelection = selection => {
    this.table && this.table.setSelection && this.table.setSelection(selection)
  }
  updateSearchPage = newValues => {
    const { searchRepository, pageKey } = this.props
    searchRepository && searchRepository.updateSearchPage(pageKey, newValues)
  }
  renderPagination = (totalPages, currPage) => {
    if (!this.props.manual) {
      this.currPage = currPage + 1
    }

    return (
      <Pager
        loading={this.props.loading}
        totalPages={totalPages}
        currentPage={this.currPage}
        pagerCount={10}
        onClickItem={this.onChangePage}
        onPrevClick={() => this.onChangePage(this.currPage - 1)}
        onNextClick={() => this.onChangePage(this.currPage + 1)}
        onFirstPageClick={() => this.onChangePage(1)}
        onLastPageClick={() => this.onChangePage(totalPages)}
      />
    )
  }
  render() {
    let {
      loading,
      className,
      columns,
      data = [],
      defaultSorted = [this.sort],
      getTdProps = () => ({}),
      getTrProps = () => ({}),
      minRows,
      search = false,
      searchClassName,
      searchPlaceholder = 'Search...',
      perPageOptions = [10, 20, 30, 40, 50],
      SubComponent = null,
      TheadComponent,
      totalRecordCount,
      totalPages: totalPagesProp,
      onExpandedChange = () => {},
      expanded = {},
      actions = null,
      actionsClassName,
      fixedColumns,
      multiSelect,
      selectKeyField = 'id',
      selectDisabledKeyField,
      selectionChanged,
      selectElementId,
      pageSize,
      showRowPerPage = true,
      manual = true,
      showPager = true,
      handleRowClick,
      enableRowLink,
      getRowUrl,
    } = this.props
    const currentTotalRecords = this.currPage > 0 ? this.defaultPageSize * this.currPage : this.defaultPageSize
    const TableComponent = this.tableComponent

    return (
      <ReactTableContainerStyled>
        <Row>
          <Col className={cx('rt-search', searchClassName)}>
            {search && (
              <FormGroup>
                <div className="search-box">
                  <i className="icon ion-md-search" />
                  <Input
                    ref={ref => (this.textSearch = ref)}
                    type="text"
                    name="search"
                    id="search"
                    placeholder={searchPlaceholder}
                    onChange={e => this.onSearch(e.target.value)}
                    bsSize="sm"
                    defaultValue={this.search}
                  />
                </div>
              </FormGroup>
            )}
          </Col>
          {!!actions && <Col className={cx('rt-toolbar mb-1', actionsClassName)}>{actions}</Col>}
        </Row>
        <TableComponent
          ref={ref => (this.table = ref)}
          data={data}
          columns={columns}
          {...(manual
            ? {
                minRows: data.length < 3 ? (minRows > 3 ? minRows : 1) : data.length,
                manual: true,
                defaultPageSize: this.defaultPageSize,
                pageSize: pageSize || this.defaultPageSize,
              }
            : {
                minRows: data.length > 1 ? 0 : 1,
                defaultPageSize: this.defaultPageSize,
                pageSize: this.state.pageSize,
                page: this.state.page - 1,
              })}
          defaultSorted={defaultSorted} // this is for non-serverside call
          onSortedChange={this.onSortChange}
          getTdProps={(state, rowInfo, column, instance) => {
            const cellValue = rowInfo?.row[column.id]
            return {
              ...{
                title: cellValue && !isArrayOrObject(cellValue) ? cellValue : '',
                // className: 'ellipsis',
                selector: (column.id === '_selector').toString(),
              },
              ...getTdProps(state, rowInfo, column, instance),
            }
          }}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              ...{
                onClick: (event, handleOriginal) => {
                  if (handleOriginal) {
                    handleOriginal()
                  } else {
                    if (
                      handleRowClick &&
                      event.target.tagName !== 'A' &&
                      (event.target.classList.contains('rt-td') ||
                        event.target.classList.contains('row-clickable') ||
                        event.target.classList.contains('ellipsis'))
                    ) {
                      handleRowClick(rowInfo)
                    }
                  }
                },
                className: cx(handleRowClick && 'cursor-pointer'),
                ...(enableRowLink ? { isRow: true, rowInfo } : {}),
              },
              ...getTrProps(state, rowInfo, column, instance),
            }
          }}
          loading={loading}
          LoadingComponent={loadProps => loadProps.loading && <Loader className="table-loader" />}
          className={cx(
            className,
            !data.length && 'no-data',
            multiSelect && 'multi-select',
            fixedColumns && 'fixed-columns'
          )}
          SubComponent={SubComponent}
          onExpandedChange={onExpandedChange}
          expanded={expanded}
          selectionChanged={selectionChanged}
          TheadComponent={TheadComponent}
          multiSelect
          {...(multiSelect ? { selectKeyField, selectElementId, selectDisabledKeyField } : null)}
          TdComponent={({ children, ...rest }) => {
            return (
              <ErrorCatcher {...rest} className={`rt-td ${rest.className || ''}`}>
                {!rest.selector && typeof children === 'string' ? <div className="ellipsis">{children}</div> : children}
              </ErrorCatcher>
            )
          }}
          showPagination={showRowPerPage || showPager}
          enableRowLink
          {...(enableRowLink
            ? {
                TrComponent: innerProps => (
                  <TableRowLink getRowUrl={getRowUrl} handleRowClick={handleRowClick} {...innerProps} />
                ),
              }
            : {})}
          PaginationComponent={innerProps => {
            const totalPages = manual ? totalPagesProp : innerProps.pages
            return (
              <React.Fragment>
                <Row>
                  {showRowPerPage && (
                    <Col sm={6} className="rt-info">
                      <strong>Rows Per Page</strong>{' '}
                      <Input
                        type="select"
                        name="defaultPageSize"
                        id="defaultPageSize"
                        defaultValue={this.defaultPageSize}
                        onChange={this.onDefaultPageSizeChange}
                        bsSize="sm"
                      >
                        {perPageOptions.map((i, key) => (
                          <option value={i} key={key}>
                            {i}
                          </option>
                        ))}
                      </Input>
                      {totalRecordCount < this.defaultPageSize
                        ? totalRecordCount
                        : currentTotalRecords > totalRecordCount
                          ? totalRecordCount
                          : currentTotalRecords}{' '}
                      of {totalRecordCount || this.defaultPageSize}
                    </Col>
                  )}
                  {showPager && !!data.length && totalPages > 1 && (
                    <Col sm={6} className="rt-pagination">
                      {this.renderPagination(totalPages, innerProps.page)}
                    </Col>
                  )}
                </Row>
              </React.Fragment>
            )
          }}
        />
      </ReactTableContainerStyled>
    )
  }
}

ReactTable.defaultProps = {
  manual: true,
}

export default decorate(observer(ReactTable), {
  loading: observable,
  currentPage: observable,
  totalRecords: observable,
  sort: observable,
  filters: observable,
  defaultPageSize: observable,
})
