import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { formatNumber } from 'helpers/Formatters'
import DatePicker from 'components/Common/Form/DatePicker'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

const rules = 'required'
class CouponFormModal extends Component {
  constructor(props) {
    super(props)

    const {
      code = '',
      customer = null,
      dealId = '',
      supportingInd = '',
      reason = '',
      claimableFrom = null,
      claimableTo = null,
    } = props.selected
    if (customer) {
      customer.customerLabel = `${customer.name}${customer.partnerId && ` (${customer.partnerId})`}`
    }
    const fields = {
      customer: {
        type: 'select',
        label: 'Customers',
        value: customer,
      },
      code: {
        type: 'text',
        value: code,
        label: 'Code',
        placeholder: 'Leave this field blank to auto generate the code',
      },
      supportingInd: {
        type: 'radio',
        label: 'Supporting',
        value: supportingInd || 'DEAL_ID',
      },
      reason: {
        type: 'textarea',
        label: 'Reason (Limit to 100 words)',
        value: reason,
      },
      dealId: {
        type: 'text',
        label: 'Deal ID',
        value: dealId,
      },
      claimableFrom: {
        type: 'text',
        value: claimableFrom,
        label: 'Effective Date',
      },
      claimableTo: {
        type: 'text',
        value: claimableTo,
        label: 'Expiry Date',
      },
    }

    this.form = new Form({ fields }, { name: 'CouponForm', handleSubmit: this.handleSubmit })
  }
  componentWillUnmount() {
    this.props.couponRepository.clearCrudData()
    this.props.couponRepository.clearErrorMessages()
  }
  handleSubmit = form => {
    const {
      couponRepository: { patch, create },
      selected: { id = null },
      data,
      allCodes = false,
    } = this.props

    const values = form.values()
    const requestAction = id ? patch : create
    requestAction(
      { pricingRuleId: allCodes ? data.pricingRule.id : data.id, id, ...values },
      this.props.onCreateSuccess
    )
  }

  setValidation = () => {
    const { couponType } = this.props
    if (['time', 'combo'].includes(couponType)) {
      this.form.$('claimableFrom').set('rules', rules)
      this.form.$('claimableTo').set('rules', rules)
    } else {
      this.form.$('claimableFrom').set('rules', null)
      this.form.$('claimableTo').set('rules', null)
    }
  }
  validateForm = () => {
    this.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        this.form.onSubmit(window.event, { onSuccess: this.handleSubmit })
      }
    })
  }
  render() {
    const {
      open,
      toggleModal,
      couponRepository: {
        reactSelectSearch,
        CRUD: { errors },
      },
      data,
      selected: { id, balanceOfUsage },
    } = this.props

    const supportingInd = this.form.$(`supportingInd`).value
    return (
      <Modal
        open={open}
        title="Coupon Code"
        onClose={toggleModal}
        onConfirm={e => {
          this.setValidation()
          this.validateForm()
        }}
        hideConfirm={!allowedToAppAdminRole()}
        confirmLabel="Submit"
      >
        <FormContainer
          onSubmit={e => {
            this.setValidation()
            this.validateForm()
          }}
          errors={errors}
          plain
        >
          <ReactSelect
            field={this.form.$('customer')}
            customLabelKey={['name', 'id']}
            serverSide
            search={payload => reactSelectSearch(payload, data.pricingRule ? data.pricingRule.id : data.id)}
            options={{
              valueKey: 'id',
              labelKey: 'customerLabel',
              defaultOptions: true,
            }}
          />
          <Input field={this.form.$('code')} />
          <RadioGroup
            field={this.form.$(`supportingInd`)}
            radios={[
              {
                label: 'Deal ID',
                value: 'DEAL_ID',
              },
              {
                label: 'Reason',
                value: 'REASON',
              },
            ]}
            inline
          />
          {supportingInd === 'DEAL_ID' ? (
            <Input field={this.form.$('dealId')} />
          ) : supportingInd === 'REASON' ? (
            <Input field={this.form.$('reason')} />
          ) : null}
          <Row>
            <Col lg={6}>
              <DatePicker field={this.form.$('claimableFrom')} />
            </Col>
            <Col lg={6}>
              <DatePicker field={this.form.$('claimableTo')} />
            </Col>
          </Row>
          {!!id && <ReadOnlyField value={formatNumber(balanceOfUsage, 0)} label="Balance" disabled />}
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(
  inject('couponRepository', 'customerRepository', 'catalogPricingRuleRepository')(observer(CouponFormModal)),
  {}
)
