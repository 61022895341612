import React, { Component } from 'react'

import { Button, Col } from 'reactstrap'

class Actions extends Component {
  renderActionButtons = () => {
    const { data, loading, switchMode, onSearch, onReset } = this.props

    return (
      <div className="d-flex">
        <Button type="button" color="primary" title="Search" disabled={loading} onClick={() => onSearch()}>
          {loading ? 'Please wait...' : 'Search'}
        </Button>
        <Button
          type="button"
          color="dark"
          title="Reset all search fields"
          className="mx-2"
          disabled={loading}
          onClick={() => onReset()}
        >
          Reset
        </Button>
        {!!data.length && (
          <Button
            type="button"
            color="primary"
            title="View last search result"
            disabled={loading}
            onClick={() => switchMode('result')}
          >
            View Last Result
          </Button>
        )}
      </div>
    )
  }

  render() {
    return (
      <Col xs="auto" className="d-flex flex-column justify-content-between">
        {this.renderActionButtons()}
        {this.renderActionButtons()}
      </Col>
    )
  }
}

export default Actions
