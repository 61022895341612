/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import ZoneServices from 'services/zone'

class ZoneRepository extends BaseRepository {
  service: ZoneServices
  constructor(service: ZoneServices) {
    super(service)
    this.service = service
  }
  deleteCountry = async (zoneId, countryId) => {
    const { data, errors } = await this.service.deleteCountry(zoneId, countryId)
    return { data, errors }
  }

  addCountry = async (zoneId, payload) => {
    this.CRUD.submitting = true
    const { data, errors } = await this.service.addCountry(zoneId, { countries: [payload] })
    this.CRUD.submitting = false
    return { data, errors }
  }
}

export default decorate(ZoneRepository, {})
