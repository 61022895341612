import { CustomInput } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { inject, observer } from 'mobx-react'
import { titleize } from 'helpers/String'
import React, { Component } from 'react'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { PAYMENT_SURCHARGE_PATH } from 'definitions'
import Actions from './Actions'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

const getInitialState = () => {
  return { table: { selection: {} } }
}
class OrderChargesTable extends Component {
  table = React.createRef()
  state = getInitialState()
  handleCreate = () => this.props.history.push(`${PAYMENT_SURCHARGE_PATH}/new`)

  /* eslint-disable-next-line */
  handleBulkAction = type => {
    const { table } = this.state
    const {
      orderChargeRepository: { bulkCopy, bulkDelete },
    } = this.props
    const ids = Object.keys(table.selection).map(i => ({
      id: i,
    }))
    if (!ids.length) return false

    switch (type) {
      case 'delete':
        ToasterNotify.confirm({
          message: `Are you sure you want to delete?`,
          title: 'Warning',
          onConfirm: async () => {
            await bulkDelete(ids)
            this.table.reload()
            this.setState(getInitialState())
          },
        })

        break
      default:
        ToasterNotify.confirm({
          message: `Are you sure you want to copy?`,
          title: 'Warning',
          onConfirm: async () => {
            await bulkCopy(ids)
            this.table.reload()
            this.setState(getInitialState())
          },
        })
        break
    }
  }

  handToggleStatus = item => {
    const { orderChargeRepository } = this.props

    if (item) {
      const payload = {
        ...item,
        status: item.status && item.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      }
      this.props.orderChargeRepository.patch(payload, data => {
        orderChargeRepository.Table.data.map(i => {
          if (i.id === data.id) {
            i.status = data.status
          }
          return i
        })
      })
    }
  }
  render() {
    const {
      orderChargeRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props
    return (
      <React.Fragment>
        <ReactTable
          searchRepository={searchRepository}
          pageKey="payment-surcharge"
          enableRowLink
          getRowUrl={({ original }) => `${PAYMENT_SURCHARGE_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          multiSelect={allowedToOrderRole()}
          columns={[
            { accessor: 'label', Header: 'Rule Name' },
            {
              accessor: 'startDate',
              Header: 'Date Start',
              Cell: ({ original }) => getFormattedDateTimeString(original.startDate),
            },
            {
              accessor: 'endDate',
              Header: 'Date Expire',
              Cell: ({ original }) => getFormattedDateTimeString(original.endDate),
            },
            {
              accessor: 'status',
              Header: 'Status',
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  label={titleize(original.status)}
                  id={`switchStatus${original.id}`}
                  className="mr-2"
                  defaultChecked={original.status === 'ACTIVE'}
                  onChange={() => this.handToggleStatus(original)}
                  onClick={event => event.preventDefault()}
                  disabled={!allowedToOrderRole()}
                />
              ),
            },
          ]}
          actions={
            <Visible toOrderProcessRole>
              <Actions onCreate={this.handleCreate} onBulkAction={this.handleBulkAction} />
            </Visible>
          }
          data={toJS(data)}
          dateColumns={['startDate', 'endDate']}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          search
          sort={{ id: 'label' }}
          selectionChanged={i => this.setState({ table: i })}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('orderChargeRepository', 'searchRepository')(observer(OrderChargesTable)), {})
