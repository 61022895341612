import React from 'react'

import { formatCustomerCurrency, getFormattedDateString } from 'helpers/Formatters'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import Status from 'components/Common/Status'

export const getSubscriptionOptions = () => {
  const sort = { id: 'createdDate', desc: true }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${SUBSCRIPTIONS_PATH}/${original.id}`

  return {
    title: 'Subscriptions',
    moreUrl: SUBSCRIPTIONS_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        {
          accessor: 'subscriptionNumber',
          Header: 'Subscription No.',
        },
        {
          accessor: 'customer.name',
          Header: 'Customer',
        },
        {
          accessor: 'subscriptionStartDate',
          Header: 'Subscription Period',
          Cell: ({ original }) =>
            `${getFormattedDateString(original.orderSubscription.subscriptionStartDate)} - ${getFormattedDateString(
              original.orderSubscription.subscriptionEndDate
            )}`,
        },
        {
          accessor: 'subscriptionFee',
          Header: 'Fee',
          headerClassName: 'justify-content-end pr-3',
          className: 'justify-content-end pr-3',
          width: 65,
          Cell: ({ original }) => formatCustomerCurrency(original.subscriptionFee, original.customer),
        },
        {
          Header: 'Status',
          accessor: 'status',
          headerClassName: 'justify-content-center pl-2',
          className: 'justify-content-center pl-2',
          Cell: ({ original }) => <Status status={original.status} />,
        },
      ],
      sort,
    },
  }
}
