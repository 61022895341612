/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CommonServices extends BaseServices {
  api: APIRepository
  publicAPI: APIRepository
  constructor(api: APIRepository, publicAPI: APIRepository) {
    const url = '/common'
    super(api, url)
    this.api = api
    this.publicAPI = publicAPI
    this.url = url
    this.publicUrl = `public${url}`
  }

  getCoTermType() {
    return this.api.get('/coTermType')
  }

  getCurrencies() {
    return this.api.get('/common/currency/items')
  }

  getShippingMethod() {
    return this.api.get('/shippingMethods')
  }

  getShippingServices() {
    return this.api.get('/shippingServices')
  }

  getCustomOptionPriceType() {
    return this.api.get(`${this.url}/customOptionPriceType`)
  }

  getNoShipmentReason() {
    return this.api.get(`${this.url}/collections/noShipmentReason`)
  }

  getByCollectionName(collectionName, isPublic = false, payload) {
    if (isPublic) return this.publicAPI.get(`${this.publicUrl}/${collectionName}`, payload)
    else return this.api.get(`${this.url}/${collectionName}`, payload)
  }

  getTableItemCollection(collectionName, payload) {
    return this.api.get(`${this.url}/${collectionName}`, payload)
  }

  reactSelectSearch(collectionName, payload) {
    return this.api.get(`${this.url}/${collectionName}/items`, payload)
  }

  getVirtualProductTypes() {
    return this.api.get(`${this.url}/virtualProductTypes`)
  }
}
