import React from 'react'

import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

class Logout extends React.Component {
  constructor(props) {
    super(props)
    this.logout()
  }
  logout = async () => {
    await this.props.authRepository.ssoLogout()
  }
  render() {
    return null
  }
}

export default withRouter(inject('authRepository')(observer(Logout)))
