/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class StorefrontSlideServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/stores'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchList(storeId) {
    return this.api.get(`/public/products${this.url}/promotions/${storeId}`)
  }

  create(payload, storeId) {
    return this.api.post(`${this.url}/${storeId}/slides`, payload)
  }

  patch(payload, storeId) {
    const { id, ...rest } = payload
    return this.api.patch(`${this.url}/${storeId}/slides/${id}`, rest)
  }

  delete(payload) {
    const { storeId, id } = payload
    return this.api.del(`${this.url}/${storeId}/slides/${id}`)
  }

  uploadImage(id, payload) {
    return this.api.post(`/products/promotions/${id}/upload`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}

export default StorefrontSlideServices
