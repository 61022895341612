/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class StoreServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/stores'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchStoreByCustomer(payload, id) {
    return this.api.get(`${this.url}/customer/${id}`, payload)
  }

  fetchProductsByStoreId(payload, storeId) {
    return this.api.get(`${this.url}/${storeId}/products`, payload)
  }

  addProductToStore(storeId, product) {
    return this.api.post(`${this.url}/${storeId}/products`, product)
  }
}

export class StoreProductServices extends BaseServices {
  constructor(api: APIRepository) {
    const url = '/stores'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable(payload, storeId) {
    return this.api.get(`${this.url}/${storeId}/products`, payload)
  }

  fetchAvailableProductsTable(params, storeId) {
    return this.api.get(`${this.url}/${storeId}/products/available`, params)
  }

  fetchRelatedProductsTable(params, storeId, id) {
    return this.api.get(`${this.url}/${storeId}/products/${id}/additionalOptions`, params)
  }

  delete({ storeId, ...payload }) {
    return this.api.post(`${this.url}/${storeId}/products/remove`, payload)
  }

  create({ storeId, ...payload }) {
    return this.api.post(`${this.url}/${storeId}/products`, payload)
  }

  patch({ storeId, id, ...payload }) {
    return this.api.patch(`${this.url}/${storeId}/products/${id}`, payload)
  }

  fetchSubscriptionItems(storeId, productId) {
    return new Promise((resolve, reject) => {
      this.api.get(`${this.url}/${storeId}/products/${productId}/warranty`).then(res => {
        resolve({ data: { content: res.data }, errors: res.errors })
      })
    })
  }

  getCustomOptions(storeId, storeProductId) {
    return this.api.get(`${this.url}/${storeId}/products/${storeProductId}/customOptions`)
  }
}

export class StoreCountryServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/stores'
    super(api, url)
    this.api = api
    this.url = url
  }
  fetchTable(payload, storeId) {
    return this.api.get(`${this.url}/${storeId}/country`, payload)
  }
  delete({ storeId, ...payload }) {
    return this.api.post(`${this.url}/${storeId}/country/remove`, payload)
  }
  create({ storeId, ...payload }) {
    return this.api.post(`${this.url}/${storeId}/country`, payload)
  }
  patch({ storeId, id, ...payload }) {
    return this.api.patch(`${this.url}/${storeId}/country/${id}`, payload)
  }
}
