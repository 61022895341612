import React, { useState } from 'react'

import { Button } from 'reactstrap'
import styled from 'styled-components'

import ReactSelect from 'components/Common/Form/ReactSelect'

export default ({ handleBulkDelete, handleBulkClone }) => {
  const [bulkAction, setBulkAction] = useState(null)

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ReactSelectStyled
        plain={true}
        className="mr-2"
        options={{
          placeholder: 'Select Bulk Action',
          options: [
            { value: 0, label: 'Clone' },
            { value: 1, label: 'Delete' },
          ],
        }}
        onChange={selected => {
          setBulkAction(selected && selected.value)
        }}
      />
      <Button
        color="dark"
        size="xs"
        onClick={() => {
          if (bulkAction === 0) {
            handleBulkClone()
          } else {
            handleBulkDelete()
          }
        }}
        disabled={bulkAction === null}
      >
        Submit
      </Button>
    </div>
  )
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`
