import React, { Component } from 'react'

import { FormGroup } from 'reactstrap'
import { observer } from 'mobx-react'
import styled from 'styled-components'

import { formatCustomerCurrency } from 'helpers/Formatters'
import Card from 'components/Common/Card'

const ValueField = ({ label, value, currency, className, customer }) => {
  return (
    <ValueFieldStyled className={className}>
      <span className="label">{label}</span>
      <div className="value text-right d-flex justify-content-between">
        <span>{currency || 'USD'}</span>
        <span>{formatCustomerCurrency(value, customer)}</span>
      </div>
    </ValueFieldStyled>
  )
}

class OrderSummary extends Component {
  render() {
    const {
      storeRepository: {
        CRUD: {
          data: { virtualProductOnlyInd = false },
        },
        OrderRates: {
          data: {
            shippingTotal = 0,
            grandTotal,
            currency = '',
            paymentCharge,
            subTotal,
            shippingInsurance = 0,
            subscriptionBalance,
          },
        },
      },
      form,
      subscription = false,
    } = this.props
    // const totalShippingFee = form.$('shippingMethod.totalShippingFee').value
    const shippingInsuranceInd = form.$('shippingMethod.shippingInsuranceInd').value
    const shippingInsuranceVal = shippingInsurance
    const customer = form.$('customer').value

    return (
      <Card title="Order Summary" className="flex-grow-1 mb-0">
        <ValueField
          label="Subtotal"
          currency={currency}
          value={subscription ? subscriptionBalance : subTotal}
          customer={customer}
        />
        {!virtualProductOnlyInd && (
          <ValueField label="Shipping" currency={currency} value={shippingTotal} customer={customer} />
        )}
        {shippingInsuranceInd && shippingInsuranceVal > 0 && (
          <ValueField label="Insurance" currency={currency} value={shippingInsuranceVal} customer={customer} />
        )}
        {paymentCharge > 0 && (
          <ValueField label="Payment Surcharge" currency={currency} value={paymentCharge} customer={customer} />
        )}
        <ValueField
          label="Grand Total"
          currency={currency}
          value={grandTotal}
          className="grand-total"
          customer={customer}
        />
      </Card>
    )
  }
}

const ValueFieldStyled = styled(FormGroup)`
  &.form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.75rem;
    padding: 1rem 0;
    border-bottom: 1px solid #dee2e6;
    span.label {
      color: inherit;
      font-weight: bold;
      text-transform: uppercase;
    }
    .value {
      width: 30%;
    }
    &.grand-total {
      font-size: 170%;
      font-weight: bold;
      padding: 1rem 0;
      margin: 0;
      border: none;
      span.label {
        font-size: 0.75rem;
      }
      .value {
        width: 50%;
      }
    }
  }
`

export default observer(OrderSummary)
