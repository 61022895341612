import axios from 'axios'
import BaseServices from './BaseServices'

export default class userServices extends BaseServices {
  constructor(api) {
    super(api)
    this.api = api
  }

  ssoLogin = payload => {
    return this.api.post(`/sa/public/auth-sso-sa`, payload)
  }

  ssoAuth = async token => {
    return await axios.get(
      // eslint-disable-next-line max-len
      `${process.env.REACT_APP_PEPLINK_SSO_SERVER_URL}${process.env.REACT_APP_PEPLINK_SSO_TOKEN_PATH}?access_token=${token}`
    )
  }

  logout() {
    return this.api.post('/sa/logout')
  }
}
