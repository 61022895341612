/* @flow */
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import cx from 'classnames'
import styled from 'styled-components'

import Breadcrumbs from 'components/Common/Breadcrumbs'
import ErrorsList from 'components/Common/ErrorsList'
import JobStatusNotification from 'components/Common/JobStatusNotification'
import Prompt from 'components/Common/Prompt'

class ContentContainer extends Component {
  render() {
    const {
      title = '',
      actions,
      actionsClassName,
      children,
      errors = [],
      breadcrumbs,
      breadcrumbLabel,
      promptOnExit = false,
      promptOnExitIsConfirm = true,
      titleClassName,
    } = this.props
    return (
      <React.Fragment>
        <Prompt promptOnExit={promptOnExit} isConfirm={promptOnExitIsConfirm} />
        <JobStatusNotification />
        <Breadcrumbs breadcrumbs={breadcrumbs} breadcrumbLabel={breadcrumbLabel} />
        <ContentStyled>
          <Row className="m-0">
            {title && (
              <Col className={cx('d-flex justify-content-start align-items-center', titleClassName)}>
                <h4 className="mt-3 mb-4">{title}</h4>
              </Col>
            )}
            <Col className={cx('d-flex justify-content-end align-items-center', actionsClassName)}>{actions}</Col>
            <Col xs="12">{errors.length ? <ErrorsList errors={errors} /> : <div>{children}</div>}</Col>
          </Row>
        </ContentStyled>
      </React.Fragment>
    )
  }
}

const ContentStyled = styled.div`
  display: flex;
  padding: 0 20px 20px;
  > .row {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
`

export default ContentContainer
