import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import CategoryList from './CategoryList'
import Loader from 'components/Common/Loader'

class CategoriesPanel extends Component {
  componentDidMount() {
    this.fetchCategories()
  }
  fetchCategories = () => {
    this.props.categoryRepository.fetchTable()
  }
  render() {
    const {
      form,
      categoryRepository: {
        Table: { loading, data },
      },
      readOnly,
    } = this.props

    return (
      <>
        {loading && <Loader loading={loading} />}
        {!loading && <CategoryList form={form} list={data} readOnly={readOnly} />}
      </>
    )
  }
}

export default decorate(inject('categoryRepository', 'productRepository')(observer(CategoriesPanel)), {})
