/* eslint-disable no-unused-vars */
/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { Badge, Button, Col, CustomInput } from 'reactstrap'
import cx from 'classnames'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import AllCodesTable from './AllCodesTable'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'

import ToasterNotify from 'helpers/ToasterNotify'

class CatalogPriceRules extends Component {
  table = null
  filters = {}
  pageKey = null
  constructor(props) {
    super(props)
    const {
      match: {
        params: { type = '' },
      },
    } = this.props
    this.state = {
      selected: [],
      bulkAction: null,
      type: type,
      view: 'all-rules',
    }

    this.initializeDefaultFilters(type)
  }

  initializeDefaultFilters = type => {
    this.pageKey = type === 'rules' ? 'price-rules' : 'coupon-codes'
    if (this.pageKey === 'coupon-codes') {
      const filters = this.props.searchRepository.Pages[this.pageKey]?.filters || {}
      this.filters = filters
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { type = '' },
      },
    } = nextProps
    if (type) {
      this.setState(
        {
          type,
        },
        () => {
          if (this.table) this.table.reload()
        }
      )
    }
  }
  handleCreate = () => {
    this.props.history.push(`${this.state.type}/new`)
  }
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  handleChangeView = view => {
    this.setState(
      {
        view,
      },
      () => {
        if (this.table) this.table.reload()
      }
    )
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    const { catalogPricingRuleRepository } = this.props
    if (bulkAction === 'Remove') {
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected rule(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await catalogPricingRuleRepository.delete({ pricingRules: selected.map(id => ({ id })) }, () => {
            this.table.reload()
          })
        },
      })
    } else if (bulkAction === 'Copy') {
      catalogPricingRuleRepository.copyRules({ pricingRules: selected.map(id => ({ id })) }, res => {
        this.table.reload()
      })
    }
  }
  handleUpdateStatus = (e, row) => {
    this.props.catalogPricingRuleRepository.patch({ ...row, status: e.target.checked ? 'Active' : 'Inactive' }, res => {
      if (this.table) this.table.reload()
    })
  }
  handleFetchCouponTable = params => {
    const { store, productType, partnerType } = this.filters
    const filters = {
      ...params,
      ...(store ? { store: store.id } : {}),
      ...(productType ? { productType: productType.value } : {}),
      ...(partnerType ? { partnerType: partnerType.value } : {}),
    }

    return this.props.catalogPricingRuleRepository.fetchCouponTable(filters)
  }
  handleCouponFilterChange = (key, value) => {
    this.filters[key] = value

    // update memoized filters
    this.props.searchRepository.updateSearchPage(this.pageKey, { filters: { ...this.filters } })

    // reload table
    this.table.reload(false, true)
  }
  render() {
    const { bulkAction, selected, type, view } = this.state
    const { catalogPricingRuleRepository, commonRepository, storeRepository, searchRepository } = this.props
    const {
      Table: { data, loading, originalData, totalRecordCount },
      CRUD: { submitting },
      fetchTable,
    } = catalogPricingRuleRepository

    const isViewAllRules = view === 'all-rules'
    const isTypeCouponCode = type === 'codes'

    return (
      <ContentContainer
        title={type === 'rules' ? 'Price Rules' : 'Coupon Codes'}
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: type === 'rules' ? 'Price Rules' : 'Coupon Codes',
          },
        ]}
        actions={
          isTypeCouponCode ? (
            <>
              <Badge color="dark" className="text-uppercase p-2">
                {view === 'all-rules' ? 'All Rules' : 'All Coupon Codes'}
              </Badge>
              <ContextMenu
                className="mx-1"
                disabled={loading}
                dropDownToggle={<IconButton icon="md-apps" />}
                menus={[
                  {
                    text: 'All Rules',
                    onClick: () => {
                      this.handleChangeView('all-rules')
                    },
                  },
                  {
                    text: 'All Coupon Codes',
                    onClick: () => {
                      this.handleChangeView('all-codes')
                    },
                  },
                ]}
                plain
              />
              <Visible toOrderProcessRole>
                <div className="text-right">
                  <IconButton onClick={this.handleCreate} color="dark" outline />
                </div>
              </Visible>
            </>
          ) : (
            <Visible toOrderProcessRole>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline />
              </div>
            </Visible>
          )
        }
        promptOnExit={submitting}
        promptOnExitIsConfirm={false}
        catalogPricingRuleRepository
      >
        {type === 'rules' || isViewAllRules ? (
          <ReactTable
            key={type === 'rules' ? 'price-rules' : 'coupon-codes'}
            searchRepository={searchRepository}
            pageKey={this.pageKey}
            enableRowLink
            getRowUrl={({ original }) => `${this.state.type}/${original.id}`}
            className="striped cell-vertical-center horizontal-scroll"
            multiSelect={allowedToOrderRole()}
            columns={[
              {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ original }) => (
                  <span title={original.name} className="pr-2 ellipsis row-clickable">
                    {original.name}
                  </span>
                ),
              },
              {
                accessor: 'label',
                Header: 'Label',
                Cell: ({ original }) => (
                  <span title={original.label} className="pr-2 ellipsis row-clickable">
                    {original.label}
                  </span>
                ),
              },
              {
                accessor: 'store',
                Header: 'Stores',
                Cell: ({ original }) => {
                  const stores = original.stores.map(store => store.name).join(', ')
                  return (
                    <span title={stores} className="pr-2 ellipsis row-clickable">
                      {stores}
                    </span>
                  )
                },
              },
              {
                accessor: 'status',
                Header: 'Status',
                width: 100,
                Cell: ({ original }) => (
                  <>
                    <CustomInput
                      type="switch"
                      id={`status-${original.id}`}
                      name="status"
                      label={original.status}
                      checked={original.status === 'Active'}
                      onChange={e => {
                        this.handleUpdateStatus(e, original)
                      }}
                      onClick={event => event.preventDefault()}
                      disabled={!allowedToOrderRole()}
                    />
                  </>
                ),
              },
            ]}
            sort={{ id: 'name' }}
            searchClassName={cx(isViewAllRules && isTypeCouponCode && 'col-4')}
            actionsClassName={cx(isViewAllRules && isTypeCouponCode && 'col-8')}
            actions={
              <div className="d-flex justify-content-end align-items-center mb-1 px-0">
                {isViewAllRules && isTypeCouponCode && (
                  <React.Fragment>
                    <Col className="pl-0">
                      <ReactSelect
                        plain
                        customLabelKey={['name', 'id']}
                        serverSide
                        search={payload => storeRepository.reactSelectSearch({ ...payload, showDisabled: false })}
                        options={{
                          defaultValue: this.filters?.store,
                          valueKey: 'id',
                          labelKey: 'name',
                          defaultOptions: true,
                          placeholder: 'Store',
                        }}
                        showTooltip
                        onChange={selected => this.handleCouponFilterChange('store', selected)}
                      />
                    </Col>
                    <Col className="pl-0">
                      <ReactSelect
                        plain
                        serverSide
                        search={params =>
                          catalogPricingRuleRepository.getAttributeValues({
                            ...params,
                            attributeName: 'field.productType.value.keyword',
                            searchType: 'unique',
                            attributeType: 'text',
                          })
                        }
                        options={{
                          defaultValue: this.filters?.productType,
                          valueKey: 'value',
                          labelKey: 'label',
                          defaultOptions: true,
                          placeholder: 'Product Type',
                        }}
                        showTooltip
                        onChange={selected => this.handleCouponFilterChange('productType', selected)}
                      />
                    </Col>
                    <Col className="pl-0">
                      <ReactSelect
                        plain
                        serverSide
                        search={params => commonRepository.reactSelectSearch(params, 'partnerTypes')}
                        options={{
                          defaultValue: this.filters?.partnerType,
                          valueKey: 'value',
                          labelKey: 'label',
                          defaultOptions: true,
                          placeholder: 'Partner Type',
                        }}
                        showTooltip
                        onChange={selected => this.handleCouponFilterChange('partnerType', selected)}
                      />
                    </Col>
                  </React.Fragment>
                )}
                <Visible toOrderProcessRole>
                  <Col lg={isViewAllRules && isTypeCouponCode ? '' : '6'}>
                    <ReactSelect
                      plain={true}
                      className="mr-2"
                      options={{
                        placeholder: 'Bulk Action',
                        options: ['Remove', ...(type === 'rules' ? ['Copy'] : [])].map(opt => ({
                          value: opt,
                          label: opt,
                        })),
                      }}
                      disabled={!selected.length}
                      onChange={vals => {
                        this.setState({ bulkAction: vals && vals.value })
                      }}
                    />
                  </Col>
                  <Button
                    size="sm"
                    color="dark"
                    type="button"
                    disabled={!bulkAction}
                    onClick={this.handleSubmitBulkAction}
                  >
                    Submit
                  </Button>
                </Visible>
              </div>
            }
            data={data}
            loadData={type === 'rules' ? fetchTable : this.handleFetchCouponTable}
            loading={loading}
            totalRecordCount={totalRecordCount}
            totalPages={originalData.totalPages}
            ref={ref => (this.table = ref)}
            search
            selectionChanged={this.handleSelected}
          />
        ) : (
          <AllCodesTable />
        )}
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'catalogPricingRuleRepository',
    'couponRepository',
    'commonRepository',
    'storeRepository',
    'searchRepository'
  )(observer(CatalogPriceRules)),
  {}
)
