import { CancelToken } from 'axios'
import { debounce } from 'lodash'
import { Dropdown, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'
import { inject } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import { CANCELLED_REQUEST } from 'stores/APIRepository'
import { getCoTermOptions } from 'components/GlobalSearch/Data/co-term'
import { getCustomerOptions } from 'components/GlobalSearch/Data/customer'
import { getOrderOptions } from 'components/GlobalSearch/Data/order'
import { getPaymentOptions } from 'components/GlobalSearch/Data/payment'
import { getProductOptions } from 'components/GlobalSearch/Data/product'
import { getQuotationOptions } from 'components/GlobalSearch/Data/quotation'
import { getSubscriptionOptions } from 'components/GlobalSearch/Data/subscription'

import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import SearchResult from 'components/GlobalSearch/SearchResult'

const defaultSearchParams = { page: 0, size: 5 }
const maxSearchCount = 7

class GlobalSearch extends Component {
  state = {
    openDropDown: false,
    searchResults: [],
    searchCounter: 1,
    loading: false,
  }
  requestId = 0
  cancelTokenSource = null

  constructor(props) {
    super(props)
    this.handleSearch = debounce(this.handleSearch, 350).bind(this)
  }

  toggleDropDown = () => this.setState({ openDropDown: !this.state.openDropDown })

  openDropDown = () => this.setState({ openDropDown: true })

  handleOnChange = () => this.handleSearch()

  handleSearch = () => {
    const criteria = this.inputSearch.value

    if (this.requestId > 0 && this.cancelTokenSource) {
      this.cancelTokenSource.cancel(CANCELLED_REQUEST)
    }

    this.setState({
      openDropDown: false,
      searchResults: [],
      searchCounter: 1,
      loading: !!criteria,
    })

    if (criteria) {
      const { globalSearchRepository } = this.props
      const requestId = new Date().valueOf()

      this.requestId = requestId
      this.cancelTokenSource = CancelToken.source()

      //Orders
      this.handleSearchData(requestId, criteria, getOrderOptions(), globalSearchRepository.searchOrders)
      //Quotations
      this.handleSearchData(requestId, criteria, getQuotationOptions(), globalSearchRepository.searchQuotations)
      //Subscriptions
      this.handleSearchData(requestId, criteria, getSubscriptionOptions(), globalSearchRepository.searchSubscriptions)
      //Co-term
      this.handleSearchData(requestId, criteria, getCoTermOptions(), globalSearchRepository.searchCoTerm)
      //Payments
      this.handleSearchData(requestId, criteria, getPaymentOptions(), globalSearchRepository.searchPayments)
      //Payments
      this.handleSearchData(requestId, criteria, getCustomerOptions(), globalSearchRepository.searchCustomers)
      //Products
      this.handleSearchData(requestId, criteria, getProductOptions(), globalSearchRepository.searchProducts)
    }
  }

  handleSearchData = async (requestId, criteria, options, searchFunction) => {
    const params = { ...defaultSearchParams, sort: options.sort, search: criteria }
    const { data = [], errors = [] } = await searchFunction(params, {
      cancelToken: this.cancelTokenSource.token,
    })

    if (this.requestId === requestId) {
      const searchResults = this.state.searchResults
      if (!errors.length) {
        if (data.length) {
          searchResults.push({ data, options })
        }
      } else {
        searchResults.push({ errors, options: { title: options.title } })
      }

      const searchCounter = this.state.searchCounter + 1
      const loading = searchCounter <= maxSearchCount
      this.setState(
        {
          openDropDown: true,
          searchResults,
          searchCounter,
          loading,
        },
        () => {
          if (!loading) {
            this.requestId = 0
          }
        }
      )
    }
  }

  render() {
    const { loading } = this.state
    const criteria = this.inputSearch && this.inputSearch.value

    return (
      <GlobalSearchStyled className="d-none d-sm-block">
        <InputGroup className="border-bottom">
          <InputGroupAddon addonType="prepend">
            <InputGroupText className="icon-container border-0 px-1 pb-1">
              {!loading && <i className="icon ion-md-search" />}
              {loading && <Loader size="sm" text="" className="" />}
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Search"
            className="input-search border-0 outline-0"
            innerRef={ref => (this.inputSearch = ref)}
            onClick={this.openDropDown}
            onChange={this.handleOnChange}
          />
          <InputGroupAddon addonType="append">
            <IconButton
              icon="md-arrow-forward"
              size="tiny"
              outline
              onClick={this.handleSearch}
              className="button-search"
              title="Global Search"
            />
          </InputGroupAddon>
        </InputGroup>
        <DropdownStyled
          isOpen={this.state.openDropDown && this.state.searchResults.length > 0}
          toggle={this.toggleDropDown}
        >
          <DropdownToggle color="light" className="dropdown-toggle" />
          <DropdownMenu className="py-3 px-2">
            {this.state.searchResults.map((item, index) => (
              <SearchResult key={index} criteria={criteria} {...item} />
            ))}
          </DropdownMenu>
        </DropdownStyled>
      </GlobalSearchStyled>
    )
  }
}

const GlobalSearchStyled = styled.div`
  &&& {
    position: relative;
    align-self: center;
    padding-left: 16px;
    font-size: 14px;
    .input-search {
      width: 400px;
      height: 30px;
      font-size: 14px;
      box-shadow: none;
    }
    .button-search {
      border-radius: 50%;
      &:hover {
        background-color: ${props => props.theme.lightDark};
      }
    }
    .icon-container {
      padding: 0 0.75rem;
      line-height: 0;
      background-color: ${props => props.theme.white};
      .icon {
        height: 18px;
        width: 18px;
      }
    }

    .spinner-border,
    .spinner-grow {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`

const DropdownStyled = styled(Dropdown)`
  &&& {
    &.dropdown {
      position: absolute;
      left: 0;
      top: -2px;
      .dropdown-toggle {
        visibility: hidden;
      }
      .dropdown-menu {
        width: 800px;
        max-height: 800px;
        overflow-y: auto;

        @media (max-height: 768px) {
          max-height: 650px;
        }
      }
    }
  }
`

export default inject('globalSearchRepository')(GlobalSearch)
