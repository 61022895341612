/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import Form from './Form'
import React, { Component } from 'react'
class CoTermAdd extends Component {
  componentDidMount() {
    this.props.productAttributeRepository.clearCrudData()
  }

  render() {
    return <Form />
  }
}

export default decorate(inject('productAttributeRepository')(observer(CoTermAdd)), {})
