import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CustomerModal extends Component {
  constructor(props) {
    super(props)
    const fields = {
      customers: {
        label: 'Customers',
        type: 'select',
        rules: 'required',
      },
      access: {
        type: 'radio',
        rules: 'required',
      },
      customerOnlineOrder: {
        type: 'switch',
        value: false,
      },
      customerStoreDisplay: {
        type: 'switch',
        value: false,
      },
    }
    this.form = new Form({ fields }, { name: 'CustomerModalForm', handleSubmit: this.handleSubmit })
  }
  handleSubmit = () => {
    const { prohibitedItemRepository, onClose, reloadTable } = this.props
    const values = this.form.values()
    const payload = {
      ...values,
      customers: values.customers.map(item => ({
        id: item.id,
        name: item.name,
      })),
    }
    prohibitedItemRepository.create(
      payload,
      () => {
        onClose()
        reloadTable()
      },
      'customer'
    )
  }
  render() {
    const {
      prohibitedItemRepository: {
        CRUD: { loading, errors },
      },
      customerRepository,
      open,
      onClose,
    } = this.props
    return (
      <Modal
        open={open}
        title="Add Prohibited Customers"
        onConfirm={() => this.buttonSubmit.click()}
        onClose={onClose}
        loading={loading}
        confirmLabel="Add"
      >
        <FormContainer
          onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })}
          plain
          errors={errors}
        >
          <ReactSelect
            field={this.form.$('customers')}
            serverSide
            search={customerRepository.reactSelectSearch}
            options={{
              isMulti: true,
              valueKey: 'id',
              labelKey: 'name',
              defaultOptions: true,
            }}
          />
          <div className="d-flex justify-content-between align-items-center pr-2">
            <LabelStyled className="mt-2">Online Order</LabelStyled>
            <Checkbox
              field={this.form.$('customerOnlineOrder')}
              label={this.form.$('customerOnlineOrder').value ? 'Yes' : 'No'}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center pr-2">
            <LabelStyled>Store Display</LabelStyled>
            <Checkbox
              field={this.form.$('customerStoreDisplay')}
              label={this.form.$('customerStoreDisplay').value ? 'Yes' : 'No'}
            />
          </div>
          <RadioGroup
            field={this.form.$('access')}
            radios={[
              { value: 'blacklist', label: 'Add to Blacklist' },
              { value: 'whitelist', label: 'Add to Whitelist' },
            ]}
            className="mt-3"
            onChange={this.handleRadioChange}
          />
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

const LabelStyled = styled.label`
  color: #797979;
  font-size: 11px;
  font-weight: 700;
  margin: 0.25rem 0 0;
  & + .form-group {
    width: 60px;
  }
`

export default inject('customerRepository')(observer(CustomerModal))
