import { Badge, Button } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import React from 'react'

import { BATCH_JOB_STATUS, BATCH_JOBS_PATH, ENV_PRODUCTION } from 'definitions'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { Visible } from 'helpers/Auth'
import ErrorDetailsPopup from './ErrorDetailsPopup'
import ErrorsList from 'components/Common/ErrorsList'
import ReactTable from 'components/Common/ReactTable'
import RetryButton from 'components/AutoRenewal/BatchJob/RetryButton'
import ToasterNotify from 'helpers/ToasterNotify'

class AutoRenewalTable extends React.PureComponent {
  state = {
    errors: [],
  }
  table = React.createRef()
  handleRetryByType = type => {
    ToasterNotify.confirm({
      message: `Are you sure you want to re-run "${type}" jobs?`,
      title: 'Warning',
      onConfirm: async () => {
        this.setErrors([])
        const { errors = [] } = await this.props.deviceManagementJobRepository.retryByType(type)
        this.setErrors(errors)
      },
    })
  }
  setErrors = errors => this.setState({ errors })
  renderCell = content => (
    <span title={content} className="pr-2 ellipsis row-clickable">
      {content}
    </span>
  )
  render() {
    const { deviceManagementJobRepository, searchRepository } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData, errors = [] },
      fetchTable,
    } = deviceManagementJobRepository
    const allErrors = [...errors, ...this.state.errors]

    return (
      <React.Fragment>
        {allErrors.length ? <ErrorsList errors={allErrors} /> : null}
        <ReactTable
          ref={this.table}
          searchRepository={searchRepository}
          pageKey="bacth-jobs"
          enableRowLink
          getRowUrl={({ original }) => `${BATCH_JOBS_PATH}/${original.id}`}
          columns={[
            {
              accessor: 'batchJobId',
              Header: 'Batch Job Id',
            },
            {
              accessor: 'batchJobType',
              Header: 'Job Type',
              width: 100,
            },
            {
              Header: 'Order Details',
              sortable: false,
              className: 'flex-column',
              Cell: ({ original }) =>
                original.orders
                  ? original.orders.map((order, index) => (
                      <div
                        key={index}
                        className={cx(
                          'row-clickable w-100',
                          original.orders.length - 1 === index ? 'pb-0 mb-0' : 'mb-3 pb-3 border-bottom border-dark'
                        )}
                      >
                        <div className="mb-3">Order# {order.orderNumber}</div>
                        <div className="mb-2">Serial Numbers:</div>
                        {order.orderItems &&
                          order.orderItems
                            .reduce((list, orderItem) => {
                              if (orderItem.serialNumbers) {
                                list.push(orderItem.serialNumbers)
                              }
                              return list
                            }, [])
                            .map((serialNumber, index) => (
                              <div key={index} className="mb-2">
                                {serialNumber}
                              </div>
                            ))}
                      </div>
                    ))
                  : '',
            },
            {
              accessor: 'createdDate',
              Header: 'Date Created',
              Cell: ({ original }) => getFormattedDateTimeString(original.createdDate),
            },
            {
              accessor: 'status',
              Header: 'Status',
              Cell: ({ original, ...rest }) => {
                const isSuccess = original.status === BATCH_JOB_STATUS.SUCCESS
                return (
                  <div>
                    <Badge
                      color={isSuccess ? 'success' : 'danger'}
                      className={`font-size-11 ${!isSuccess && 'cursor-pointer'}`}
                      style={{ width: '100px' }}
                      id={`popover-${original.id}`}
                      onClick={event => event.preventDefault()}
                    >
                      {!isSuccess && (
                        <>
                          <i className="icon ion-md-alert tiny"></i> <ErrorDetailsPopup original={original} />
                        </>
                      )}
                      <span className={'text-uppercase'}>{original.status}</span>{' '}
                    </Badge>
                  </div>
                )
              },
            },
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'text-center p-1 justify-content-center align-items-center',
              sortable: false,
              width: 80,
              Cell: ({ original }) => (
                <RetryButton
                  deviceManagementJobRepository={deviceManagementJobRepository}
                  id={original.id}
                  status={original.status}
                  handleRetry={this.handleRetry}
                  setErrors={this.setErrors}
                />
              ),
            },
          ]}
          actions={
            process.env.REACT_APP_ENV !== ENV_PRODUCTION && (
              <Visible toOrderProcessRole>
                <div className="text-right">
                  <Button size="sm" className="mr-2" onClick={() => this.handleRetryByType('Data Plan')}>
                    Re-run all data plan jobs
                  </Button>
                  <Button size="sm" color="info" onClick={() => this.handleRetryByType('Warranty')}>
                    Re-run all warranty jobs
                  </Button>
                </div>
              </Visible>
            )
          }
          data={data}
          loadData={params => fetchTable(params)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          className="striped cell-vertical-center"
          sort={{ id: 'createdDate', desc: true }}
          getTdProps={(state, rowInfo) => ({
            className:
              rowInfo && rowInfo.original && (!!rowInfo.original.errorDetails || !!rowInfo.original.orders)
                ? 'align-items-start'
                : '',
          })}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('deviceManagementJobRepository', 'searchRepository')(observer(AutoRenewalTable)), {})
