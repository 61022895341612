import { Button, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import { ListBodyStyled, ListHeaderStyled, ListItemStyled, ListStyled } from 'styled/List'
import { PRODUCTS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import AddProductsModal from './AddProductsModal'
import IconButton from 'components/Common/Buttons/IconButton'
import ToasterNotify from 'helpers/ToasterNotify'

class RelatedProductsPanel extends Component {
  state = {
    isModalOpen: false,
  }
  constructor(props) {
    super(props)
    this.field = this.props.form.$('additionalOptions')
  }
  toggleModal = () => this.setState({ isModalOpen: !this.state.isModalOpen })
  handleAdd = selection => {
    const { data } = this.props

    const additionalOptions = this.field.values().map(item => item.id)
    additionalOptions.push(data.id)

    //Excluded self and selected products
    const newRelatedProducts = selection.filter(
      item => additionalOptions.findIndex(itemId => itemId === item.id) === -1
    )

    newRelatedProducts.length > 0 && this.field.set([...this.field.value, ...newRelatedProducts])
    this.toggleModal()
  }
  handleDelete = product => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove '${product.name}'?`,
      title: 'Warning',
      onConfirm: () => {
        this.field.set(this.field.value.filter(val => val.id !== product.id))
      },
    })
  }
  render() {
    const { readOnly } = this.props
    const products = this.field.value || []

    return (
      <RelatedProductsPanelStyled>
        {products.length > 0 && (
          <ListStyled>
            <ListHeaderStyled>
              <ListItemStyled>
                <Col sm="3">Product Code</Col>
                <Col>Product Name</Col>
                <Col sm="1" className="justify-content-center">
                  Action
                </Col>
              </ListItemStyled>
            </ListHeaderStyled>
            <ListBodyStyled>
              {products.map(product => (
                <ListItemStyled key={`relatedProduct${product.id}`}>
                  <Col sm="3">
                    <Link to={`${PRODUCTS_PATH}/${product.productId || product.id}`}>{product.productCode}</Link>
                  </Col>
                  <Col>
                    <Link to={`${PRODUCTS_PATH}/${product.productId || product.id}`}>{product.name}</Link>
                  </Col>
                  <Visible toAppAdminRole>
                    <Col sm="1" className="p-1 justify-content-center">
                      {!readOnly && (
                        <IconButton
                          icon="ios-trash"
                          title="Remove related product"
                          onClick={() => this.handleDelete(product)}
                        />
                      )}
                    </Col>
                  </Visible>
                </ListItemStyled>
              ))}
            </ListBodyStyled>
          </ListStyled>
        )}
        <Visible toAppAdminRole>
          {readOnly && (
            <div className="d-flex-center mx-auto" style={{ height: '120px' }}>
              <p className="text-dark">To add products, click Edit Product</p>
            </div>
          )}
          {!readOnly && (
            <Button color="light" className="d-flex-center mt-3 mx-auto" onClick={this.toggleModal}>
              <i className="icon ion-md-add large mr-2 add" />
              Add Related Products
            </Button>
          )}
          <AddProductsModal
            isModalOpen={this.state.isModalOpen}
            toggleModal={this.toggleModal}
            handleSubmit={this.handleAdd}
          />
        </Visible>
      </RelatedProductsPanelStyled>
    )
  }
}

const RelatedProductsPanelStyled = styled.div`
  margin: 0 -1.25rem;
  .icon.add {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    color: ${props => props.theme.black};
    background-color: ${props => props.theme.lightDark};
  }
  a {
    color: inherit;
  }
`

export default observer(RelatedProductsPanel)
