import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import React, { Component } from 'react'

import CategoryList from './index'

class CategoryListItem extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: props.primary }
  }
  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (prevProps.item.isOpen !== item.isOpen) {
      this.setState({ isOpen: true })
    }
  }

  toggleChild = item => {
    item.isOpen = !item.isOpen
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  render() {
    const { item, onSelectItem, selected } = this.props
    const isSelected = selected && selected.id === item.id
    const hasSubCategories = !!item['subCategories']
    const { isOpen } = this.state
    return (
      <li>
        <div className="d-flex align-items-center">
          {hasSubCategories ? (
            <React.Fragment>
              {/* eslint-disable-next-line */}
              <div className="cursor-pointer mr-1" onClick={this.toggle}>
                {isOpen ? <i className="icon ion-md-arrow-dropdown" /> : <i className="icon ion-md-arrow-dropright" />}
              </div>
              <div className={cx('flex-grow-1', isSelected && 'active')}>
                <button type="button" onClick={() => onSelectItem(item)}>
                  <i className={`icon ion-md-${isOpen ? 'folder-open' : 'folder'}`} />
                  {item.name}
                </button>
              </div>
            </React.Fragment>
          ) : (
            <div className={cx('ml-3', isSelected && 'active')}>
              <button type="button" onClick={() => onSelectItem(item)}>
                <i className="icon ion-md-list-box" />
                {item.name}
              </button>
            </div>
          )}
        </div>
        {hasSubCategories && isOpen && (
          <CategoryList
            list={item.subCategories}
            className="ml-2"
            primary={false}
            onSelectItem={onSelectItem}
            selected={selected}
          />
        )}
      </li>
    )
  }
}

export default inject('commonRepository')(observer(CategoryListItem))
