/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import CustomerServices from 'services/customer'

class CustomerRepository extends BaseRepository {
  service: CustomerServices
  constructor(service: CustomerServices) {
    super(service)
    this.service = service
  }

  DefaultStore = {
    loading: false,
    data: null,
    errors: [],
  }

  getDefaultStore = async (customerId, callback = null) => {
    this.DefaultStore.data = null
    this.DefaultStore.loading = true
    const { data, errors } = await this.service.getDefaultStore(customerId)
    if (data && !errors.length) {
      this.DefaultStore.data = data
      if (callback) callback(data)
    }
    this.DefaultStore.loading = false
  }

  bulkUpdateStatus = async (payload, callback) => {
    this.Table.loading = true
    const { data, errors } = await this.service.bulkUpdateStatus(payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    }
    this.Table.loading = false
  }

  customerReactSelectSearch = async params => {
    const { data, errors } = await this.service.fetchTable(params)
    if (!errors.length && data && data.content && data.content.length) {
      return {
        data: {
          ...data,
          content: data.content.map(item => ({
            ...item,
            customerLabel: item.partnerId && item.partnerId !== '0' ? `${item.name} (${item.partnerId})` : item.name,
          })),
        },
        errors,
      }
    }
    return { data, errors }
  }

  genericReactSelectSearch = async params => {
    const { data, errors } = await this.service.fetchTable(params)
    if (!errors.length && data && data.content && data.content.length) {
      return {
        data: {
          content: data.content.map(item => ({
            value: item.id,
            label: item.name,
          })),
        },
        errors: [],
      }
    }
    return { data, errors }
  }
}

export class CustomerAddressRepository extends CustomerRepository {
  service: CustomerServices
  constructor(service: CustomerServices) {
    super(service)
    this.service = service
  }
}

export class CustomerContactRepository extends CustomerRepository {
  service: CustomerServices
  constructor(service: CustomerServices) {
    super(service)
    this.service = service
  }
}

export class CustomerUserLoginRepository extends CustomerRepository {
  service: CustomerServices
  constructor(service: CustomerServices) {
    super(service)
    this.service = service
  }
}

class CustomerSpecialProduct extends BaseRepository {
  service: CustomerServices
  constructor(service: CustomerServices) {
    super(service)
    this.service = service
  }
  ProductTable = {
    ...defaults.Table,
  }
  fetchProductTable = async (params, id = null, callback) => {
    this.ProductTable.loading = true
    const { data, errors } = await this.service.fetchProductTable(params, id)
    if (data && !errors.length) {
      this.ProductTable = {
        ...this.ProductTable,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
      }
      this.ProductTable.loading = false
      callback && callback(data)
    } else {
      this.ProductTable.loading = false
    }
  }
}

export const CustomerSpecialProductRepository = decorate(CustomerSpecialProduct, {
  ProductTable: observable,
})

export default decorate(CustomerRepository, {
  DefaultStore: observable,
})
