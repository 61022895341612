/* @flow */

import { action, decorate, observable, toJS } from 'mobx'
export const defaults = {
  Table: {
    originalData: {},
    data: [],
    loading: false,
    totalRecordCount: 0,
  },
  CRUD: {
    loading: false,
    loadingFailed: false,
    submitting: false,
    data: {},
    errors: [],
  },
}
const dataReference = {
  loading: false,
  loadingFailed: false,
  data: {},
  errors: [],
}
class BaseRepository {
  constructor(service: any) {
    this.service = service
  }

  Table = defaults.Table

  CRUD = defaults.CRUD

  Common = {}

  fetchTable = async (params, id = null, callback) => {
    this.Table.loading = true
    const { data, errors } = await this.service.fetchTable(params, id)
    if (data && !errors.length) {
      this.Table = {
        ...this.Table,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
        errors: [],
      }
      this.Table.loading = false
      callback && callback(data)
    } else {
      this.Table.errors = errors
      this.Table.loading = false
    }
  }

  reactSelectSearch = async (params, id = null) => {
    const { data, errors } = await this.service.fetchTable(params, id)
    return { data, errors }
  }

  getById = async (payload, callback) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.getById(payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  create = async (payload, callback, id = null) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.create(payload, id)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  patch = async (payload, callback, id = null) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.patch(payload, id)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  put = async (payload, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.put(payload)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  delete = async (payload, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.delete(payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  clearCrudData = () => {
    this.CRUD = defaults.CRUD
  }

  clearTableData = () => {
    this.Table = defaults.Table
  }

  clearErrorMessages = () => {
    this.CRUD.errors = []
  }

  getCommon = async (key, payload) => {
    if (!toJS(this.Common).hasOwnProperty(key))
      this.Common[key] = {
        ...dataReference,
        data: [],
      }
    this.Common[key].loading = true
    const { data, errors } = await this.service.getById(payload)
    if (data && !errors.length) {
      this.Common[key].loadingFailed = false
      this.Common[key].data = data
    } else {
      this.Common[key].loadingFailed = true
      this.Common[key].errors = errors
    }
    this.Common[key].loading = false
  }
  reset() {
    this.Table = defaults.Table
    this.CRUD = defaults.CRUD
  }
}

export default decorate(BaseRepository, {
  Table: observable,
  CRUD: observable,
  Common: observable,
  reset: action,
})
