/* @flow */
import React, { Component } from 'react'

import { Col, CustomInput, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { PRODUCTS_PATH } from 'definitions'
import Actions from './Actions'
import Card from 'components/Common/Card'
import CategoryImportProductModal from './CategoryImportProductModal'
import CategorySelectorModal from './CategorySelectorModal'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class CategoryDetails extends Component {
  table = null
  state = {
    selectedCustomers: [],
    selectedProduct: {},
    selectedProducts: [],
    isOpen: {
      importProductModal: false,
      categorySelectorModal: false,
    },
    isLoading: {
      moveCategory: false,
    },
  }

  componentDidMount() {
    const { selected } = this.props
    this.props.categoryRepository.getById(selected.id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected.id !== prevProps.selected.id) {
      this.props.categoryRepository.getById(prevProps.selected.id)
      this.table.reload(true)
    }
  }

  handleDelete = () => {
    let { onDelete } = this.props
    onDelete().then(res => {
      this.toggleDeleteAlert()
    })
  }

  handleSubmitImport = productIds => {
    const { categoryRepository, selected } = this.props
    categoryRepository.importProducts({ productIds }, selected.id, res => {
      this.toggleModal('importProductModal')
      this.table.reload()
    })
  }

  handleDeleteProduct = () => {
    const { selectedProduct } = this.state
    const { categoryRepository, selected } = this.props
    categoryRepository.deleteProduct(selected.id, selectedProduct.id, () => {
      this.table.reload()
      this.setState({
        selectedProducts: [],
      })
    })
  }
  handleBulkDelete = () => {
    const { selected, categoryRepository } = this.props
    const { selectedProducts } = this.state
    categoryRepository
      .remove({
        categoryId: selected.id,
        products: selectedProducts,
      })
      .then(() => {
        this.table.reload()
        this.setState({
          selectedProducts: [],
        })
      })
  }
  handleMoveCategory = newCategory => {
    const { selected, categoryRepository } = this.props
    const { selectedProducts } = this.state
    this.toggleLoading('moveCategory')
    categoryRepository
      .move({
        products: selectedProducts,
        targetCategoryId: newCategory.id,
        categoryId: selected.id,
      })
      .then(() => {
        this.toggleModal('categorySelectorModal')
        this.table.reload()
        this.setState({
          selectedProducts: [],
        })
        this.toggleLoading('moveCategory')
      })
  }
  handleSelected = ({ selection }) => {
    const selectedProducts = Object.keys(selection).map(i => {
      return { id: i }
    })
    this.setState({ selectedProducts })
  }
  toggleLoading = type => {
    const { isLoading } = this.state
    this.setState({
      isLoading: {
        ...isLoading,
        [type]: !isLoading[type],
      },
    })
  }
  toggleModal = type => {
    const { isOpen } = this.state
    this.setState({
      isOpen: {
        ...isOpen,
        [type]: !isOpen[type],
      },
    })
  }

  toggleDeleteProductAlert = selectedProduct => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove this product from the category?`,
      title: 'Warning',
      onConfirm: this.handleDeleteProduct,
    })

    this.setState({
      selectedProduct,
    })
  }

  toggleBulkDeleteAlert = selectedProduct => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove this product from the category?`,
      title: 'Warning',
      onConfirm: this.handleBulkDelete,
    })

    this.setState({
      selectedProduct,
    })
  }
  render() {
    const { isOpen, selectedProducts, isLoading } = this.state
    const {
      productRepository: { ProductsByCategory, getProductsByCategory },
      searchRepository,
      pageKey,
      selected,
      list,
    } = this.props
    return (
      <Card>
        <div className="d-flex align-items-center mb-3">
          <h5 className="m-0">{selected.name}</h5>
        </div>
        <Row>
          <Col lg={12}>
            <ReactTable
              searchRepository={searchRepository}
              pageKey={pageKey}
              enableRowLink
              getRowUrl={({ original }) => `${PRODUCTS_PATH}/${original.id}`}
              className="striped cell-vertical-center"
              multiSelect={allowedToAppAdminRole()}
              selectElementId="categoryProducts"
              selectionChanged={this.handleSelected}
              ref={ref => (this.table = ref)}
              columns={[
                {
                  accessor: 'name',
                  Header: 'Name',
                },
                {
                  accessor: 'productCode',
                  Header: 'Product Code',
                },
                {
                  accessor: 'storeDisplayInd',
                  Header: 'Store Display',
                  width: 120,
                  headerClassName: 'justify-content-center',
                  className: 'justify-content-center',
                  Cell: ({ original }) => (
                    <CustomInput
                      type="switch"
                      label={original.storeDisplayInd ? 'Yes' : 'No'}
                      id={`switchStoreDisplay${original.id}`}
                      checked={original.storeDisplayInd}
                      readOnly
                      disabled
                    />
                  ),
                },
                {
                  accessor: 'permission.allowAutoRenewInd',
                  Header: 'Allow Auto-Renew',
                  width: 120,
                  headerClassName: 'justify-content-center',
                  className: 'justify-content-center',
                  Cell: ({ original }) => (
                    <CustomInput
                      type="switch"
                      label={original.permission?.autoRenewInd ? 'Yes' : 'No'}
                      id={`switchAllowAutoRenew${original.id}`}
                      checked={original.permission?.autoRenewInd}
                      readOnly
                      disabled
                    />
                  ),
                },
                {
                  Header: 'Actions',
                  headerClassName: 'justify-content-center',
                  className: 'text-center p-1 justify-content-center',
                  width: 150,
                  show: allowedToAppAdminRole(),
                  Cell: ({ original }) => (
                    <IconButton
                      icon="ios-trash"
                      onClick={event => {
                        event.preventDefault()
                        this.toggleDeleteProductAlert(original)
                      }}
                    />
                  ),
                },
              ]}
              actions={
                <Visible toAppAdminRole>
                  <Actions
                    disabled={!selectedProducts.length}
                    handleCreate={() => this.toggleModal('importProductModal')}
                    handleDelete={this.toggleBulkDeleteAlert}
                    handleMove={() => this.toggleModal('categorySelectorModal')}
                    disabledAddProducts={selected.rootInd}
                  />
                </Visible>
              }
              sort={{ id: 'name' }}
              tableColumnExtensions={[]}
              data={ProductsByCategory.data}
              loadData={params => getProductsByCategory(params, selected.id)}
              loading={ProductsByCategory.loading}
              totalRecordCount={ProductsByCategory.totalRecordCount}
              totalPages={ProductsByCategory.totalPages}
              search
            />
          </Col>
        </Row>
        <CategoryImportProductModal
          isOpen={isOpen.importProductModal}
          onToggle={() => this.toggleModal('importProductModal')}
          data={selected}
          handleSubmitImport={this.handleSubmitImport}
        />
        {isOpen.categorySelectorModal && (
          <CategorySelectorModal
            isLoading={isLoading.moveCategory}
            isOpen={isOpen.categorySelectorModal}
            onToggle={() => this.toggleModal('categorySelectorModal')}
            list={list}
            onConfirm={this.handleMoveCategory}
          />
        )}
      </Card>
    )
  }
}

export default decorate(
  inject('categoryRepository', 'productRepository', 'searchRepository')(observer(CategoryDetails)),
  {}
)
