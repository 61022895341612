/*
 * You might be wondering why this file is named 'definitions'
 * as opposed to 'constants', well node has a built-in called
 * 'constants' and resolves that first, combining that with 'NODE_PATH=src'
 * will trigger the bug. More information here:
 *
 * https://github.com/facebook/create-react-app/issues/2338
 */

// CONSTANTS
// ==========================================================================
export const ATTRIBUTES = 'attributes'
export const CATALOGS = 'catalogs'
export const CATEGORIES = 'categories'
export const COLLECTIONS = 'collections'
export const COTERM = 'co-term'
export const CUSTOMER_GROUPS = 'customer-groups'
export const CUSTOMERS = 'customers'
export const ITEMS = 'items'
export const LOGIN = 'login'
export const LOG_BOOK = 'log-book'
export const PAYMENT_SURCHARGE = 'payment-surcharge'
export const ORDERS = 'orders'
export const PAYMENTS = 'payments'
export const PRODUCTS = 'products'
export const PRODUCT_OPTIONS = 'product-options'
export const PROFILE = 'profile'
export const SETTINGS = 'settings'
export const SHIPPING = 'shipping'
export const STORES = 'stores'
export const USERS = 'users'
export const USER_ROLE_ACCESS = 'user-role-access'
export const PRICING_RULES = 'rules'
export const PROHIBITED_ITEMS = 'prohibited-items'
export const COUPON_CODES = 'coupons'
export const IMPORT = 'upload-function'
export const SANCTIONS = 'sanctions'
export const QUOTATIONS = 'quotations'
export const BANK_ACCOUNTS = 'bankaccounts'
export const SHIPPING_ZONES = 'shipping-zones'
export const PAYMENT_ACCOUNTS = 'payment-accounts'
export const SUBSCRIPTIONS = 'subscriptions'
export const PUBLIC = 'public'
export const ORDER_ADVANCED_SEARCH = 'orders-advanced-search'
export const ORDER_TYPES = 'order-types'
export const AUTO_RENEWAL = 'auto-renewal'
export const BATCH_JOBS = 'batch-jobs'

export const LOGOUT = 'logout'

// PATHS
// ==========================================================================
export const ATTRIBUTES_PATH = `/${ATTRIBUTES}`
export const CATALOGS_PATH = `/${CATALOGS}`
export const CATEGORIES_PATH = `/${CATEGORIES}`
export const COLLECTIONS_PATH = `/${COLLECTIONS}`
export const COLLECTION_ITEMS_PATH = `${COLLECTIONS_PATH}/:collectionName`
export const COTERM_PATH = `/${COTERM}`
export const CUSTOMER_GROUPS_PATH = `/${CUSTOMER_GROUPS}`
export const CUSTOMERS_PATH = `/${CUSTOMERS}`
export const GOOGLE_CALLBACK_PATH = `/google/callback`
export const LOGIN_PATH = `/${LOGIN}`
export const LOG_BOOK_PATH = `/${LOG_BOOK}`
export const ORDERS_PATH = `/${ORDERS}`
export const PAYMENT_SURCHARGE_PATH = `/${PAYMENT_SURCHARGE}`
export const PAYMENTS_PATH = `/${PAYMENTS}`
export const PRODUCTS_PATH = `/${PRODUCTS}`
export const PRODUCT_OPTIONS_PATH = `/${PRODUCT_OPTIONS}`
export const PROFILE_PATH = `/${PROFILE}`
export const SHIPPING_PATH = `/${SHIPPING}`
export const STORES_PATH = `/${STORES}`
export const USERS_PATH = `/${USERS}`
export const USER_ROLE_ACCESS_PATH = `/${USER_ROLE_ACCESS}`
export const PRICING_RULES_PATH = `/${PRICING_RULES}`
export const PROHIBITED_ITEMS_PATH = `/${PROHIBITED_ITEMS}`
export const COUPON_CODES_PATH = `/${COUPON_CODES}`
export const IMPORT_PATH = `/${IMPORT}`
export const SANCTIONS_PATH = `/${SANCTIONS}`
export const QUOTATIONS_PATH = `/${QUOTATIONS}`
export const BANK_ACCOUNTS_PATH = `/${BANK_ACCOUNTS}`
export const SHIPPING_ZONES_PATH = `/${SHIPPING_ZONES}`
export const PAYMENT_ACCOUNTS_PATH = `/${PAYMENT_ACCOUNTS}`
export const SUBSCRIPTIONS_PATH = `/${SUBSCRIPTIONS}`
export const PUBLIC_PATH = `/${PUBLIC}`
export const ORDER_TYPES_PATH = `/${ORDER_TYPES}`
export const AUTO_RENEWAL_PATH = `/${AUTO_RENEWAL}`
export const BATCH_JOBS_PATH = `/${BATCH_JOBS}`
export const LOGOUT_PATH = `/${LOGOUT}`
export const SSO_CALLBACK = '/sso/callback'
export const CHECK_LOGIN = '/check-login.html'
export const ORDER_ADVANCED_SEARCH_PATH = `/${ORDER_ADVANCED_SEARCH}`

// COLLECTIONS
// ==========================================================================
export const ATTRIBUTE_TYPES = {
  number: 'Number',
  text: 'Text Input',
  textarea: 'Multi-line Text Input',
  array: 'Dropdown',
  boolean: 'Yes / No',
}

export const ORDER_TYPE = {
  CO_TERM: 'Co-Term',
  CO_TERM_SIMULATION: 'COTERM_SIMULATION',
  CONTRACT: 'Contract',
}

// SANCTIONS
// ==========================================================================
export const SANCTION_ENTITY_TYPE = 'ENTITY'
export const SANCTION_COUNTRY_TYPE = 'COUNTRY'
export const SANCTION_INDIVIDUAL_TYPE = 'INDIVIDUAL'

// USER ROLES
// ==========================================================================
export const ROLE_ADMIN = 'ROLE_ADMIN'
export const ROLE_APP_ADMIN = 'ROLE_APP_ADMIN'
export const ROLE_USER = 'ROLE_USER'
export const ROLE_ORDER_PROCESS = 'ROLE_ORDER_PROCESS'

// PRODUCTS
// ==========================================================================
export const PRODUCT_NATURE_PHYSICAL = 'physical'
export const PRODUCT_NATURE_VIRTUAL = 'virtual'
export const PRODUCT_NATURE_VIRTUAL_NO_SN = 'virtualNoSN'
export const PRODUCT_NATURE_VIRTUAL_WITH_ORG_ID = 'virtualWithOrgId'
export const PRODUCT_NATURE_VIRTUAL_WITH_FSC_ID = 'virtualWithFscId'

// Attribute Group
// ==========================================================================
export const PRODUCT_ATTRIBUTE_GROUP_SPECIFICATION = 'SPECIFICATION'
export const PRODUCT_ATTRIBUTE_GROUP_PACKAGE_CONTENT = 'PACKAGECONTENT'
export const PRODUCT_ATTRIBUTE_GROUP_CERTIFICATION = 'CERTIFICATION'

// ORDER RECORD TYPE
// ==========================================================================
export const ORDER_RECORD_TYPE_SUBSCRIPTION = 'SUBSCRIPTION'

// ORDER TYPE
// ==========================================================================
export const ORDER_TYPE_LOAN_ORDER = 'Loan Order'

// ORDER STATUS
// ==========================================================================
export const ORDER_STATUS_NEW = 'NEW'
export const ORDER_STATUS_ORDER_PROCESSING = 'ORDER_PROCESSING'
export const ORDER_STATUS_PENDING_APPROVAL = 'PENDING_APPROVAL'
export const ORDER_STATUS_PENDING_PAYMENT = 'PENDING_PAYMENT'
export const ORDER_STATUS_SUBSCRIPTION_MASTER_COPY = 'SUBSCRIPTION_MASTER_COPY'
export const ORDER_STATUS_SUBSCRIPTION_INACTIVE = 'SUBSCRIPTION_INACTIVE'
export const ORDER_STATUS_DRAFT_ORDER = 'DRAFT_ORDER'
export const ORDER_STATUS_CANCELLED = 'CANCELLED'
export const ORDER_STATUS_DISAPPROVED = 'DISAPPROVED'
export const ORDER_STATUS_COMPLETED = 'COMPLETED'
export const ORDER_STATUS_DELETED = 'DELETED'
export const ORDER_STATUS_APPROVED_QUOTATION = 'APPROVED_QUOTATION'
export const ORDER_STATUS_PENDING_SUBSCRIPTION = 'PENDING_SUBSCRIPTION'
export const ORDER_STATUS_OUTSTANDING = 'OUTSTANDING'
export const ORDER_STATUS_CONVERTED = 'CONVERTED'

// ORDER STATUS DESCRIPTION
// ==========================================================================
export const ORDER_STATUS_DESC_ORDER_PROCESSING = 'Order Processing'
export const ORDER_STATUS_DESC_PENDING_APPROVAL = 'Pending Approval'
export const ORDER_STATUS_DESC_PENDING_PAYMENT = 'Pending Payment'
export const ORDER_STATUS_DESC_SUBSCRIPTION_MASTER_COPY = 'Master Copy'
export const ORDER_STATUS_DESC_SUBSCRIPTION_INACTIVE = 'Inactive'
export const ORDER_STATUS_DESC_DRAFT_ORDER = 'Draft Order'
export const ORDER_STATUS_DESC_APPROVED_QUOTATION = 'Approved'

export const ORDER_STATUS = {
  NEW: { label: 'New', color: 'outline-primary' },
  ORDER_PROCESSING: { label: 'Order Processing', color: 'outline-primary' },
  PENDING_APPROVAL: { label: 'Pending Approval', color: 'danger' },
  PENDING_PAYMENT: { label: 'Pending Payment', color: 'danger' },
  SUBSCRIPTION_MASTER_COPY: { label: 'Subscription Master Copy', color: 'outline-primary' },
  SUBSCRIPTION_INACTIVE: { label: 'Subscription Inactive', color: 'outline-primary' },
  DRAFT_ORDER: { label: 'Draft Order', color: 'outline-primary' },
  CANCELLED: { label: 'Cancelled', color: 'dark', color2: 'light' },
  DISAPPROVED: { label: 'Disapproved', color: 'dark' },
  COMPLETED: { label: 'Completed', color: 'outline-primary' },
  DELETED: { label: 'Deleted', color: 'outline-primary' },
  APPROVED_QUOTATION: { label: 'Approved Quotation', color: 'light' },
  RUNNING: { label: 'Running', color: 'outline-turquoise' },
  REJECTED: { label: 'Rejected', color: 'light' },
  PENDING_SUBSCRIPTION: { label: 'Pending Subscription', color: 'danger' },
  OUTSTANDING: { label: 'Outstanding', color: 'danger' },
  CONVERTED: { label: 'Converted', color: 'outline-primary' },
  REJECTED_QUOTATION: { label: 'Rejected', color: 'dark', color2: 'light' },
  SIMULATED: { label: 'Simulated', color: 'outline-secondary' },
}

// Order source
// ==========================================================================
export const ORDER_SOURCE = {
  STORE: 'STORE',
  MANUAL: 'MANUAL',
  MOBILE: 'MOBILE',
  MOBILE_V2: 'MOBILE_V2',
  APP_PROMO: 'APP_PROMO',
  APP_PROMO2: 'APP_PROMO2',
  AUTO_RENEWAL: 'AUTO_RENEWAL',
  PARTNER_QUOTATION: 'PARTNER_QUOTATION',
  API: 'API',
  COTERM_V1: 'COTERM_V1',
  COTERM_V2: 'COTERM_V2',
  COTERM_SIMULATOR: 'COTERM_SIMULATOR',
  COTERM_BACKEND: 'COTERM_BACKEND',
  COTERM_PARTNER_PAVILION: 'COTERM_PARTNER_PAVILION',
  COTERM_SIMULATOR_PARTNER_PAVILION: 'COTERM_SIMULATOR_PARTNER_PAVILION',
}

// ACTIVE STATUS
// ==========================================================================
export const ACTIVE_STATUS = 'ACTIVE'
export const INACTIVE_STATUS = 'INACTIVE'

// PAYMENT METHOD
// ==========================================================================
export const PAYMENT_METHOD_NOT_APPLICABLE = 'Not Applicable'
export const PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT = 'Peplink Credit Terms Payment'
export const PAYMENT_METHOD_BANK_TRANSFER = 'Bank Transfer'
export const PAYMENT_METHOD_CREDIT_PAYMENT = 'Credit Payment'

// SHIPPING METHOD
// ==========================================================================
export const SHIPPING_METHOD_CUSTOMER_PROVIDED = 'Customer Provided'
export const SHIPPING_METHOD_PRIORITY_SHIPPING = 'Priority Shipping'

export const SHIPPING_CARRIER = {
  US_VFT_ONLY_SELF_PICK_UP: 'US VFT Only - self pick-up',
}

export const ENV_PRODUCTION = 'production'

// CATEGORY
// ==========================================================================
export const CATEGORY_ACCESSORIES = 'Accessories'
export const CATEGORY_FEATURED_ADD_ON = 'Featured Add On'

export const BATCH_JOB_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
}

export const CHECK_JOB_STATUS = 'CHECK_JOB_STATUS'

// ATTRIBUTES
// ==========================================================================
export const ATTRIBUTE_NAMES = {
  VIRTUALPRODUCTTYPE: 'virtualProductType',
  SFCRENEWALQUOTA: 'sfcRenewalQuota',
  SFCVOLUME: 'sfcVolume',
}

export const VIRTUAL_PRODUCT_TYPE = {
  DATAPLANLICENSE: 'Data Plan License',
  CLOUD: 'Cloud',
  CLOUD_UNLIMITED: 'Cloud Unlimited',
  LICENSE: 'License',
}

export const PRODUCT_TYPE_ACCESSORY = 'accessory'

export const SUBMITTING = 'SUBMITTING'

export const SSO_STATE = 'abcdweradfasd'

export const WARRANTY_TYPE = {
  ESSENTIAL_CARE: 'EssentialCare',
  ESSENTIAL_CARE_PLUS: 'EssentialCare+',
  INCONTROL: 'InControl',
  INCONTROL2: 'InControl2',
  PRIME_CARE: 'PrimeCare',
  PRIME_CARE_PLUS: 'PrimeCare+',
}

export const PAYMENT_METHODS = {
  BANK: 'Bank Transfer',
  CREDIT_TERM: 'Peplink Credit Terms Payment',
  CREDIT_CARD: 'Credit Card',
  PAYPAL_PAYMENT: 'Paypal Payment',
  NA: 'Not Available',
}

export const NO_SHIPMENT_REASON_DEFAULT = 'noShipmentReason'

export const REGION = {
  GLOBAL: 'Global',
}

export const PRODUCT_PERMISSION_FIELDS = {
  iccidBasedInd: 'ICCID based/New eSIM',
  iccidOptionESIMInd: 'eStore ICCID option - eSIM',
  iccidOptionPhySIMInd: 'eStore ICCID option - PhySIM',
}
