import React from 'react'

import { Badge } from 'reactstrap'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

import { formatDate, getFormattedDateString } from 'helpers/Formatters'
import Card from 'components/Common/Card'
import ReactTable from 'components/Common/ReactTable'

class Products extends React.Component {
  constructor(props) {
    super(props)
    this.fetchTable = debounce(this.fetchTable, 500).bind(this)
  }

  fetchTable = async (params = {}) => {
    const {
      form,
      formValues,
      coTermProductRepository: { fetchTable },
    } = this.props

    if (!form.isValid) return false

    const {
      type,
      customer,
      targetDate,
      serialNumbers: serialNumbersValue,
      skipSNWarrantyCheck,
      allowLessThan1YearInd,
    } = formValues

    if (type) {
      const serialNumbers = serialNumbersValue
        .trim()
        .split('\n')
        .filter(item => !!item)
      await fetchTable({
        type,
        customerId: customer.id,
        targetDate: formatDate(targetDate, 'YYYY-MM-DD'),
        allowLessThan1YearInd,
        serialNumbers: serialNumbers.map(i => i.trim()),
        skipSNWarrantyCheck,
        search: params.search,
        pageable: {
          page: 0,
          size: serialNumbers.length || 1,
        },
      })

      const selection = this.getMappedData()
        .filter(item => !item.disabledInd)
        .reduce((selection, item) => {
          selection[item.id] = item
          return selection
        }, {})

      this.table.setSelection(selection)
      this.handleSelection({ selection })
    }
    return true
  }

  getMappedData = () => {
    const {
      coTermProductRepository: {
        Table: { data = [] },
      },
    } = this.props
    return toJS(data).map((item, index) => ({
      ...item,
      id: index,
      disallowedInd: item.disabledInd,
    }))
  }

  handleSelection = ({ selection }) => {
    const selectedCoTermProducts = Object.keys(selection).map(i => {
      delete selection[i].isSelected
      return selection[i]
    })
    this.props.onSelect(selectedCoTermProducts)
  }

  render() {
    const {
      coTermProductRepository: {
        Table: { loading, totalRecordCount },
      },
    } = this.props

    return (
      <Card title="Co Term Product Matching Result">
        <ReactTable
          multiSelect
          selectElementId="storeProductPanel"
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'serialNumber',
              Header: 'Serial Number',
              width: 130,
            },
            {
              accessor: 'productCode',
              Header: 'Product Code',
              width: 150,
            },
            {
              accessor: 'expiryDate',
              Header: 'Expiry Date',
              width: 120,
              Cell: ({ original }) => getFormattedDateString(original.expiryDate),
            },
            {
              accessor: 'newStartDate',
              Header: 'New Start Date',
              width: 120,
              Cell: ({ original }) => getFormattedDateString(original.newStartDate),
            },
            { accessor: 'termsInMonths', Header: 'Terms in Months', width: 120 },
            {
              accessor: 'termsInMonths',
              Header: 'Co-Term Product',
              width: 120,
              Cell: ({ original }) => (
                <React.Fragment>
                  {original.coTermProduct && (
                    <Badge color={'light'} className="font-size-11 w-75 text-uppercase">
                      {original.coTermProduct.productCode}
                    </Badge>
                  )}
                </React.Fragment>
              ),
            },
            {
              accessor: 'remarks',
              Header: 'Remarks',
              width: 150,
              Cell: ({ original }) => (
                <React.Fragment>
                  {original.warningInd ? (
                    <span className="text-danger d-flex align-items-center text-break" style={{ whiteSpace: 'normal' }}>
                      <i className="font-size-14 ion-md-information-circle-outline" />
                      <span className="ml-2">{original.remarks}</span>
                    </span>
                  ) : (
                    original.remarks
                  )}
                </React.Fragment>
              ),
            },
          ]}
          sort={{ id: 'serialNumber' }}
          data={this.getMappedData()}
          loadData={this.fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          ref={ref => (this.table = ref)}
          search
          selectionChanged={this.handleSelection}
          selectDisabledKeyField="disallowedInd"
          manual={false}
        />
      </Card>
    )
  }
}

export default inject('coTermProductRepository')(observer(Products))
