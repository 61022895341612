import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CommonSelect extends Component {
  componentDidMount() {
    this.fetchCollection()
  }

  fetchCollection = async () => {
    const { commonRepository, collectionName, isPublic = false, onCollectionLoaded } = this.props
    await commonRepository.getSelectItemCollection(collectionName, isPublic)
    if (onCollectionLoaded) {
      const collection = commonRepository.SelectItems.collections[collectionName]?.data
      onCollectionLoaded(collection)
    }
    this.fetchCollection()
  }

  fetchCollection = async () => {
    const { commonRepository, collectionName, isPublic = false, onCollectionLoaded } = this.props
    await commonRepository.getSelectItemCollection(collectionName, isPublic)
    if (onCollectionLoaded) {
      const collection = commonRepository.SelectItems.collections[collectionName]?.data
      onCollectionLoaded(collection)
    }
  }

  render() {
    const {
      commonRepository: {
        SelectItems: { collections },
      },
      field = undefined,
      collectionName = '',
      disabled = false,
      options,
      showTooltip,
      readOnly,
      className,
      readOnlyClassName,
      onBeforeChange,
      onChange,
    } = this.props

    const { [collectionName]: { data = [], loading = true } = {} } = collections

    return (
      <ReactSelect
        field={field}
        options={{
          ...options,
          options: data,
          isLoading: loading,
        }}
        disabled={disabled}
        showTooltip={showTooltip}
        readOnly={readOnly}
        className={className}
        readOnlyClassName={readOnlyClassName}
        onBeforeChange={onBeforeChange}
        onChange={onChange}
      />
    )
  }
}

export default decorate(inject('commonRepository')(observer(CommonSelect)), {})
