import { Prompt, withRouter } from 'react-router-dom'
import React, { Component } from 'react'

import ToasterNotify from 'helpers/ToasterNotify'

export const DEFAULT_CONFIRM_MESSAGE = 'Do you want to leave this page?\nChanges you made may not be saved.'
const DEFAULT_ALERT_MESSAGE = 'Page is still submitting.\nPlease wait for the operation to complete...'

class PromptNavigate extends Component {
  state = {
    promptOnExit: this.props.promptOnExit,
    nextLocation: null,
  }
  componentDidMount() {
    if (this.props.promptOnExit) {
      this.bindBeforeUnload(true)
    }
  }
  componentWillUnmount() {
    if (this.props.promptOnExit) {
      this.bindBeforeUnload(false)
    }
  }
  componentDidUpdate(prevProps) {
    const { promptOnExit } = this.props
    if (promptOnExit !== prevProps.promptOnExit) {
      this.bindBeforeUnload(promptOnExit)
      this.setState({ promptOnExit })
      !promptOnExit && ToasterNotify.close()
    }
  }
  beforeUnload = event => {
    event.preventDefault()
    event.returnValue = false
  }
  bindBeforeUnload = bind => {
    if (bind) {
      window.addEventListener('beforeunload', this.beforeUnload)
      window.addEventListener('keydown', this.disableRefresh)
    } else {
      window.removeEventListener('beforeunload', this.beforeUnload)
      window.removeEventListener('keydown', this.disableRefresh)
    }
  }
  // eslint-disable-next-line consistent-return
  disableRefresh = () => {
    const stopRefresh = () => {
      event.returnValue = false
      this.showModal()
    }
    const event = window.event
    switch (event.keyCode) {
      case 116: //F5 button
        stopRefresh()
        return false
      case 82: //ctrl + R button for windows, command + r for mac
        if (event.ctrlKey || event.key === 'Meta') {
          stopRefresh()
          return false
        }
        break
      default:
        break
    }
  }
  handlePromptNavigation = nextLocation => {
    if (this.props.promptOnExit) {
      this.setState({ nextLocation }, () => {
        this.showModal()
      })
      return false
    }
    return true
  }
  handleSubmit = () => {
    this.setState({ promptOnExit: false }, () => {
      this.bindBeforeUnload(false)
      const { nextLocation } = this.state
      if (nextLocation) {
        const url = `${nextLocation.pathname}${nextLocation.search}`
        this.props.history.push(url)
      } else {
        window.location.reload()
      }
    })
  }
  showModal = () => {
    const { isConfirm = true, promptOnExitMessage } = this.props
    if (isConfirm) {
      ToasterNotify.confirm({
        message: <div style={{ whiteSpace: 'break-spaces' }}>{promptOnExitMessage || DEFAULT_CONFIRM_MESSAGE}</div>,
        title: 'Caution',
        onConfirm: () => this.handleSubmit(),
      })
    } else {
      ToasterNotify.alert({
        message: <div style={{ whiteSpace: 'break-spaces' }}>{promptOnExitMessage || DEFAULT_ALERT_MESSAGE}</div>,
        title: 'Caution',
        color: 'error',
        duration: 7000,
      })
    }
  }
  render() {
    return <Prompt when={this.state.promptOnExit} message={this.handlePromptNavigation} />
  }
}

export default withRouter(PromptNavigate)
