/* @flow */

import BaseRepository from './BaseRepository'
import DeviceManagementJobServices from 'services/deviceManagementJob'

class DeviceManagementJobRepository extends BaseRepository {
  service: DeviceManagementJobServices
  constructor(service: DeviceManagementJobServices) {
    super(service)
    this.service = service
  }

  retry = async id => {
    const { errors } = await this.service.retry(id)
    return { errors }
  }

  retryByType = async type => {
    const { errors } = await this.service.retryByType(type)
    return { errors }
  }

  checkJobStatus = async () => {
    return await this.service.checkJobStatus()
  }
}

export default DeviceManagementJobRepository
