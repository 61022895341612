import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import { ATTRIBUTE_NAMES, PRODUCT_NATURE_PHYSICAL } from 'definitions'
import { isNull } from 'helpers/Common'
import { requireDataPlanAttribute, requireVirtualProductType } from '../fieldset'
import { sort, sortByNumber } from 'helpers/Array'
import CustomFields from './CustomFields'
import Loader from 'components/Common/Loader'

class AttributesPanel extends Component {
  state = {
    loading: true,
  }
  productAttributeDictionary = {}
  constructor(props) {
    super(props)
    this.loadAttributes()
  }
  loadAttributes = async () => {
    await this.props.commonRepository.getCommon('attributeGroup', 'attributeGroup/items')
    await this.props.productAttributeRepository.fetchTable({ size: '9999' })
    this.initializeAttributes()
    this.setState({ loading: false })
  }
  initializeAttributes = () => {
    const { form, productAttributeRepository } = this.props

    this.productAttributeDictionary = toJS(productAttributeRepository.Table.data).reduce((dict, item) => {
      dict[item.attributeName] = item
      return dict
    }, {})

    const formattedFields = form.$('customFields').map(field => {
      const attribute = this.productAttributeDictionary[field.key]
      const metaData = this.initializeAttributeField(
        {
          name: field.key,
          value: field.value,
        },
        attribute
      )

      return {
        key: field.key,
        value: metaData.value,
        type: metaData.type,
        label: metaData.label,
        rules: metaData.rules,
        validators: metaData.validators,
        extra: metaData.extra,
        sortOrder: metaData.sortOrder || 0,
      }
    })

    formattedFields.forEach(item => {
      form.$('customFields').del(item.key)
    })
    sortByNumber(formattedFields, 'sortOrder').forEach(item => {
      form.$('customFields').add(item)
    })

    form.$('customFieldsValidator').set(false)
  }
  initializeAttributeField = (attribute, attributeMetadata) => {
    const field = {
      key: attribute.name,
      extra: {
        attributeMetadata,
      },
    }
    if (attributeMetadata) {
      field.label = attributeMetadata.attributeLabel
      field.type = attributeMetadata.attributeType
      field.sortOrder = attributeMetadata.sortOrder
      if (attributeMetadata.requiredInd) {
        field.rules = 'required'
      }

      switch (field.key) {
        case ATTRIBUTE_NAMES.VIRTUALPRODUCTTYPE:
          field.validators = [formData => [!requireVirtualProductType(formData.form), `${field.label} is required`]]
          break
        case ATTRIBUTE_NAMES.SFCRENEWALQUOTA:
        case ATTRIBUTE_NAMES.SFCVOLUME:
          field.validators = [
            formData => {
              return [!requireDataPlanAttribute(formData.form, field.key), `${field.label} is required`]
            },
          ]
          break
        default:
          break
      }

      switch (field.type) {
        case 'boolean':
          field.value = attribute.value === 'true'
          break
        case 'select':
        case 'array':
          if (attributeMetadata.multipleValuesInd) {
            field.extra.originalValue = attribute.value
          }
          field.value = this.transformArrayValue(attribute.value, attributeMetadata.multipleValuesInd)
          break
        default:
          field.value = attribute.value
          break
      }
    } else {
      field.type = 'broken'
    }
    return field
  }
  transformArrayValue = (value, isMulti) => {
    if (value && typeof value === 'string') {
      if (isMulti) {
        return value.split(',').map(item => ({ label: item, value: item }))
      } else {
        return { label: value, value }
      }
    }

    return value
  }
  renderAttributes = (key, group, label) => {
    const { form, readOnly } = this.props
    const productNature = isNull(form.$('productNature').value, { value: PRODUCT_NATURE_PHYSICAL }).value

    return (
      <div key={key} className="mb-3">
        <label className="label">{label}</label>
        <div className="d-flex flex-wrap attribute-fields">
          <CustomFields
            productNature={productNature}
            group={group}
            form={form}
            readOnly={readOnly}
            initializeAttributeField={this.initializeAttributeField}
            productAttributeDictionary={this.productAttributeDictionary}
          />
        </div>
      </div>
    )
  }
  render() {
    if (this.state.loading) {
      return <Loader />
    }

    const {
      commonRepository: {
        Common: { attributeGroup: { data: { content: attributeGroups = [] } } = {} },
      },
    } = this.props

    return (
      <AttributePanelStyled>
        {sort(attributeGroups, 'label').map((item, index) => this.renderAttributes(index, item.value, item.label))}
      </AttributePanelStyled>
    )
  }
}

const AttributePanelStyled = styled.div`
  .label {
    color: ${props => props.theme.primary};
    margin-bottom: 0px;
    font-size: 12px;
    padding: 0 15px;
  }
  .attribute-fields {
    .custom-field,
    > .form-group {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      padding: 0 15px;
    }
    .custom-field {
      .form-group {
        max-width: 100%;
        > label {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      span.delete-icon {
        margin: 25px 0 5px -5px;
        &:hover {
          z-index: 2;
        }
      }
    }
  }
  .button-container {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 100%;
    width: 100%;
    max-width: 100%;
    .icon.add {
      border-radius: 50%;
      height: 42px;
      width: 42px;
      color: ${props => props.theme.black};
      background-color: ${props => props.theme.lightDark};
    }
  }
`

export default inject('productAttributeRepository', 'commonRepository')(observer(AttributesPanel))
