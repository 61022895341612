/* @flow */
import React, { Component } from 'react'

import { Button } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import queryString from 'query-string'

import { formatCustomerCurrency, getFormattedDateString } from 'helpers/Formatters'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import Status from 'components/Common/Status'

const pageKey = 'subsriptions'

const StatusButton = ({ status, className, selected, onClick, ...rest }) => {
  return (
    <Button
      size="xs"
      style={{ width: '80px' }}
      className={cx(className, {
        selected: selected == status,
      })}
      onClick={() => onClick(status)}
      {...rest}
    >
      {status || 'All'}
    </Button>
  )
}
class Subscription extends Component {
  filters = {}
  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props

    const filters = searchRepository.Pages[pageKey]?.filters || {}
    this.filters = filters
  }
  componentDidMount() {
    const { history } = this.props
    const { search } = queryString.parse(this.props.location.search)
    search && history.replace('/subscriptions', {})
  }
  handleFilterStatus = status => {
    if (status) {
      this.filters = { status }
    } else {
      this.filters = null
    }

    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload(false, true)
  }
  handleCreate = () => {
    this.props.history.push('/subscriptions/new')
  }
  render() {
    const {
      orderSubscriptionRepository: {
        Table: { data, loading, totalRecordCount, originalData },
        fetchTable,
      },
      searchRepository,
    } = this.props
    const { search } = queryString.parse(this.props.location.search)

    return (
      <ContentContainer title="Subscriptions">
        <div className="mb-3">
          <StatusButton
            className="mr-2"
            color="dark"
            selected={this.filters?.status}
            onClick={this.handleFilterStatus}
          />
          <StatusButton
            status="Running"
            color="turquoise"
            className="mr-2"
            selected={this.filters?.status}
            onClick={this.handleFilterStatus}
          />
          <StatusButton
            status="Outstanding"
            color="danger"
            style={{ backgroundColor: '#dd001b' }}
            className="mr-2"
            selected={this.filters?.status}
            onClick={this.handleFilterStatus}
          />
          <StatusButton
            className="mr-2"
            status="Completed"
            color="dark"
            selected={this.filters?.status}
            onClick={this.handleFilterStatus}
          />
          <StatusButton
            className="mr-2"
            status="Cancelled"
            color="dark"
            selected={this.filters?.status}
            onClick={this.handleFilterStatus}
          />
        </div>
        <ReactTable
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) =>
            `${SUBSCRIPTIONS_PATH}/${original.id}${original.status === 'NEW' ? `?contract=true` : ''}`
          }
          className="striped cell-vertical-center horizontal-scroll"
          columns={[
            {
              accessor: 'subscriptionNumber',
              Header: 'Subscription No.',
              width: 100,
            },
            {
              accessor: 'customer.name',
              Header: 'Customer',
            },
            {
              accessor: 'subscriptionStartDate',
              Header: 'Subscription Period',
              Cell: ({ original }) =>
                `${getFormattedDateString(original.orderSubscription.subscriptionStartDate)} - ${getFormattedDateString(
                  original.orderSubscription.subscriptionEndDate
                )}`,
            },
            {
              accessor: 'orderSubscription.recurringPeriod',
              Header: 'Recurring Period',
              Cell: ({ original }) => `${original.orderSubscription.recurringPeriod} Months`,
            },

            {
              accessor: 'orderSubscription.noOfInstallment',
              Header: 'No. of Instalments',
            },
            {
              accessor: 'orderSubscription.duration',
              Header: 'Duration',
            },
            {
              Header: 'Next Billing Date',
              Cell: ({ original }) => getFormattedDateString(original.nextBillingDate),
            },
            {
              accessor: 'subscriptionFee',
              Header: 'Subscription Fee',
              headerClassName: 'justify-content-end pr-3',
              className: 'justify-content-end pr-3',
              Cell: ({ original }) => formatCustomerCurrency(original.subscriptionFee, original.customer),
            },
            {
              Header: 'Status',
              accessor: 'status',
              headerClassName: 'justify-content-center pl-2',
              className: 'justify-content-center pl-2',
              Cell: ({ original }) => <Status status={original.status} />,
            },
          ]}
          actions={
            <Visible toOrderProcessRole>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Create New Contract" />
              </div>
            </Visible>
          }
          dateColumns={['createdDate']}
          search
          searchCriteria={search}
          data={data}
          sort={{ id: 'createdDate', desc: true }}
          loadData={params => fetchTable({ ...params, ...this.filters })}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('orderSubscriptionRepository', 'searchRepository')(observer(Subscription)), {})
