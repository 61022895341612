import React, { Component } from 'react'

import { autorun, decorate } from 'mobx'
import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS, INACTIVE_STATUS, PAYMENT_SURCHARGE_PATH } from 'definitions'

import { allowedToOrderRole } from 'helpers/Auth'

import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import CommonSelect from 'components/Common/CommonSelect'
import Conditions from './Conditions'
import ContentContainer from 'components/Common/ContentContainer'
import DatePicker from 'components/Common/Form/DatePicker'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

class OrderChargesForm extends Component {
  constructor(props) {
    super(props)

    const {
      data: {
        applyTo = '',
        label = '',
        description = '',
        type,
        status = ACTIVE_STATUS,
        stores,
        groups,
        startDate,
        endDate,
        expiresInd,
        criteria,
        action = {},
      } = {},
    } = props
    const rules = 'required'
    const hooks = { onChange: () => this.setState({ promptOnExit: true }) }
    const fields = {
      label: {
        type: 'text',
        label: 'Rule Name',
        rules,
        value: label || '',
        hooks,
      },
      description: {
        type: 'textarea',
        label: 'Description',
        value: description || '',
        hooks,
      },
      type: {
        type: 'select',
        label: 'Type',
        value: type,
        hooks,
      },
      status: {
        type: 'switch',
        rules,
        value: status === ACTIVE_STATUS,
        hooks,
      },
      stores: {
        type: 'select',
        label: 'Store',
        rules,
        value: stores || '',
        hooks,
      },
      groups: {
        type: 'select',
        label: 'Customer Groups',
        rules,
        value: groups || '',
        hooks,
      },
      startDate: {
        type: 'Input',
        label: 'From Date',
        value: startDate || new Date(),
        hooks,
      },
      endDate: {
        type: 'Input',
        label: 'To Date',
        value: endDate || new Date(),
        hooks,
      },
      expiresInd: {
        type: 'checkbox',
        label: 'Ends at a given date',
        value: expiresInd,
        hooks,
      },
      applyTo: {
        type: 'text',
        label: 'Apply Charges To',
        value: applyTo,
        hooks,
      },
      action: {
        fields: {
          applyBy: {
            type: 'select',
            label: 'Apply',
            value: action.applyBy,
            rules,
            hooks,
          },
          amount: {
            type: 'text',
            label: 'Charge Amount',
            value: action.amount,
            rules,
            hooks,
          },
        },
      },
      criteria: {
        value: criteria
          ? [criteria]
          : [
              {
                isCreate: false,
                isCriteriaOpen: false,
                isConditionOpen: false,
                condition: {
                  condition: 'all',
                  value: 'true',
                },
                type: 'combinations',
                criterias: [],
              },
            ],
        hooks,
      },
    }
    this.form = new Form({ fields }, { name: 'UserForm', handleSubmit: this.handleSubmit })

    let checkCriteriaChanged = false
    autorun(
      reaction => {
        JSON.stringify(this.form.$('criteria').value)
        if (checkCriteriaChanged) {
          this.setState({ promptOnExit: true })

          //dispose autorun, only need to run 1 time, once a change is detected
          reaction.dispose()
        }
        checkCriteriaChanged = true
      },
      { name: 'checkCriteria', delay: 150 }
    )

    this.state = {
      currentTab: 0,
      promptOnExit: false,
    }
  }

  componentWillMount() {
    this.props.commonRepository.getCommon('pricingRuleStatus', 'pricingRuleStatus', {})
    this.props.commonRepository.getCommon('pricingRuleApply', 'pricingRuleApply', {})
    this.props.commonRepository.getCommon('additionalChargeApplyTo', 'additionalChargeApplyTo', {})
  }

  handleCancel = () => this.props.history.push(PAYMENT_SURCHARGE_PATH)

  handleTabChange = (event, value) => this.setState({ currentTab: value })

  handleSubmit = form => {
    const values = form.values()

    const {
      orderChargeRepository: {
        CRUD: { data },
        create,
        patch,
      },
    } = this.props

    const requestAction = data.id ? patch : create
    requestAction(
      {
        ...values,
        status: values.status ? ACTIVE_STATUS : INACTIVE_STATUS,
        applyTo: values.applyTo,
        criteria: { ...values.criteria[0] },
        id: data.id,
      },
      () => {
        this.setState({ promptOnExit: false })
        this.handleCancel()
      }
    )
  }

  render() {
    const {
      commonRepository,
      customerGroupRepository,
      storeRepository,
      orderChargeRepository: { CRUD: { errors = [], loading, submitting, loadingFailed } = {} },
      data,
      title,
    } = this.props
    const { pricingRuleApply, additionalChargeApplyTo } = commonRepository.Common
    return (
      <ContentContainer
        title={title}
        breadcrumbLabel={data ? data.label : ''}
        promptOnExit={submitting || this.state.promptOnExit}
        promptOnExitIsConfirm={!submitting}
      >
        <FormContainer
          title="User Details"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={loadingFailed && errors}
          plain
        >
          <Row>
            <Col lg={4}>
              <Card title="Rule Information">
                <Input
                  field={this.form.$('label')}
                  style={{ paddingRight: this.form.$('status').value ? '75px' : '85px' }}
                  endAdornment={
                    <Checkbox
                      field={this.form.$('status')}
                      label={this.form.$('status').value ? 'Active' : 'Inactive'}
                      id={`switchStatus${this.form.$('status').id}`}
                      plain
                      inputClassName="mt-1"
                    />
                  }
                />
                <Input field={this.form.$('description')} rows="10" />
                <CommonSelect field={this.form.$('type')} collectionName="paymentSurchargeType" />
                <ReactSelect
                  field={this.form.$('stores')}
                  customLabelKey={['name', 'id']}
                  serverSide
                  search={storeRepository.reactSelectSearch}
                  options={{
                    isMulti: true,
                    valueKey: 'id',
                    labelKey: 'name',
                    defaultOptions: true,
                  }}
                />
                <ReactSelect
                  field={this.form.$('groups')}
                  customLabelKey={['name', 'id']}
                  serverSide
                  search={customerGroupRepository.reactSelectSearch}
                  options={{
                    isMulti: true,
                    valueKey: 'id',
                    labelKey: 'name',
                    defaultOptions: true,
                  }}
                />
                <RadioGroup field={this.form.$('applyTo')} radios={additionalChargeApplyTo.data} inline />
                <RadioGroup
                  defaultValue={this.form.$('action.applyBy').value.value}
                  field={this.form.$('action.applyBy')}
                  radios={pricingRuleApply.data}
                  inline
                  onChange={selected => {
                    this.form.$('action.applyBy').set(pricingRuleApply.data.find(i => i.value === selected))
                  }}
                />
                <Input field={this.form.$('action.amount')} />
                <Checkbox field={this.form.$('expiresInd')} />
                <DatePicker field={this.form.$('startDate')} />
                {this.form.$('expiresInd').value && <DatePicker field={this.form.$('endDate')} />}
              </Card>
            </Col>
            <Col lg={8}>
              <Card title="Conditions">
                <Conditions form={this.form} criteria={this.form.$('criteria').value} />
              </Card>
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <FormActions
              variant="contained"
              loading={loading}
              loadingMessage={`Saving please wait...`}
              confirmLabel={`Save`}
              onCancel={this.handleCancel}
              hideConfirm={!allowedToOrderRole()}
            />
          </div>
        </FormContainer>
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'commonRepository',
    'orderChargeRepository',
    'customerGroupRepository',
    'storeRepository'
  )(observer(OrderChargesForm)),
  {}
)
