import React, { Component } from 'react'

import { Col, FormGroup, Label, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'

import { sort } from 'helpers/Array'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import DatePicker from 'components/Common/Form/DatePicker'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

import Actions from './Actions'

class SearchForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderSources: [],
      orderStatuses: [],
      isOptionsLoading: false,
    }
  }

  componentDidMount() {
    this.initialize()
  }

  initialize = async () => {
    const { commonRepository, orderRepository } = this.props

    this.setState({ isOptionsLoading: true })

    await orderRepository.getCommon('OrderSource', 'sources')
    if (orderRepository.Common.OrderSource?.data?.length) {
      this.setState({
        orderSources: sort(orderRepository.Common.OrderSource.data, 'label'),
      })
    }

    const statusResult = await commonRepository.reactSelectSearch({ sort: 'label,asc', size: 1000 }, 'orderStatus')
    if (statusResult?.data?.content?.length) {
      this.setState({
        orderStatuses: statusResult.data.content.map(item => ({
          value: item.value,
          label: item.label,
        })),
      })
    }

    commonRepository.getCommon('paymentMethods', 'paymentMethods')

    this.setState({ isOptionsLoading: false })
  }

  render() {
    const {
      form,
      commonRepository: {
        Common: { paymentMethods: { data: paymentMethods = [] } = {} },
      },
      customerGroupRepository,
      orderAdvanceSearchRepository: {
        Table: { data, loading },
      },
      orderTypeRepository,
      storeRepository,
      switchMode,
      onSearch,
      onReset,
    } = this.props
    const { orderSources, orderStatuses, isOptionsLoading } = this.state

    return (
      <Row className="mb-4">
        <Col xs="6" xl="5">
          <Card className="mb-0">
            <FormContainer
              title="Search Fields"
              onSubmit={e => form.onSubmit(e, { onSuccess: this.handleSubmit })}
              plain
            >
              <Input field={form.$('orderNumber')} disabled={loading} />
              <Input field={form.$('customerName')} disabled={loading} />
              <Input field={form.$('email')} disabled={loading} />
              <ReactSelect
                field={form.$('customerGroupId')}
                customLabelKey={['name', 'id']}
                serverSide
                search={customerGroupRepository.reactSelectSearch}
                options={{
                  isMulti: false,
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                  isDisabled: loading,
                }}
              />
              <ReactSelect
                field={form.$('store')}
                customLabelKey={['name', 'id']}
                serverSide
                search={payload => storeRepository.reactSelectSearch({ ...payload, showDisabled: false })}
                options={{
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                  isDisabled: loading,
                }}
              />
              <ReactSelect
                field={form.$('orderTypeId')}
                customLabelKey={['name', 'id']}
                serverSide
                search={payload =>
                  orderTypeRepository.reactSelectSearch({ ...payload, showDisabled: false, sort: 'name' })
                }
                options={{
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                  isDisabled: loading,
                }}
              />
              <FormGroup>
                <Label>Order Date</Label>
                <Row>
                  <Col xs="auto" className="pr-0">
                    <Label>from</Label>
                  </Col>
                  <Col className="px-2">
                    <DatePicker
                      field={form.$('orderDateFrom')}
                      options={{
                        showMonthDropdown: true,
                        showYearDropdown: true,
                      }}
                      disabled={loading}
                    />
                  </Col>
                  <Col xs="auto" className="px-0">
                    <Label>to</Label>
                  </Col>
                  <Col className="px-2">
                    <DatePicker
                      field={form.$('orderDateTo')}
                      options={{
                        showMonthDropdown: true,
                        showYearDropdown: true,
                      }}
                      disabled={loading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <ReactSelect
                field={form.$('orderSource')}
                options={{
                  options: orderSources,
                  isLoading: isOptionsLoading,
                  isDisabled: loading,
                }}
              />
              <ReactSelect
                field={form.$('status')}
                options={{
                  options: orderStatuses,
                  isLoading: isOptionsLoading,
                  isDisabled: loading,
                }}
              />
              <Input field={form.$('productCode')} disabled={loading} />
              <Input field={form.$('productName')} disabled={loading} />
              <Input field={form.$('orgId')} disabled={loading} />
              <Input field={form.$('fscId')} disabled={loading} />
              <Input field={form.$('serialNumber')} disabled={loading} />
              <Input field={form.$('dealId')} disabled={loading} />
              <Input field={form.$('referralCode')} disabled={loading} />
              <Input field={form.$('couponCode')} disabled={loading} />
              <ReactSelect
                field={form.$('paymentMethodId')}
                options={{
                  options: paymentMethods,
                  isDisabled: loading,
                }}
              />
              <Checkbox field={form.$('noShipmentInd')} disabled={loading} />
            </FormContainer>
          </Card>
        </Col>
        <Actions data={data} loading={loading} switchMode={switchMode} onSearch={onSearch} onReset={onReset} />
      </Row>
    )
  }
}

export default inject(
  'commonRepository',
  'customerGroupRepository',
  'orderRepository',
  'orderAdvanceSearchRepository',
  'orderTypeRepository',
  'storeRepository'
)(observer(SearchForm))
