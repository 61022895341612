import { components } from 'react-select'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import React, { Component } from 'react'
import styled from 'styled-components'

import { PRODUCTS_PATH } from 'definitions'
import { sort } from 'helpers/Array'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class FlexibleCustomOptionPanel extends Component {
  state = {
    categories: [],
    products: [],
  }
  constructor(props) {
    super(props)
    this.fetchProducts = debounce(this.fetchProducts, 350)
  }
  componentDidMount() {
    this.loadCategories()
  }
  loadCategories = async () => {
    const { categoryRepository } = this.props

    await categoryRepository.fetchTable()
    const {
      Table: { data = [] },
    } = categoryRepository
    this.setState({
      categories: this.flattenedCategoryTreeList(data),
    })
  }
  flattenedCategoryTreeList = (categories = [], flatCategories = [], level = 0) => {
    if (categories) {
      return categories.reduce((flatCategories, item) => {
        const isParent = item.subCategories != null
        flatCategories.push({
          id: item.id,
          name: item.name,
          // value: item.id,
          // label: item.name,
          level,
          isParent,
          isDisabled: isParent,
        })
        return this.flattenedCategoryTreeList(item.subCategories, flatCategories, level + 1)
      }, flatCategories)
    }
    return flatCategories
  }
  fetchProducts = params => {
    const { productRepository, form } = this.props
    const categories = form.$('flexibleCustomOptionCategory').value || []

    if (categories.length) {
      productRepository.fetchFlexibleCustomOptions({
        ...(params && params),
        showDisabled: false,
        productCategories: categories.map(item => item.id),
      })
    } else {
      productRepository.clearFlexibleCustomOptionsData()
    }
  }
  categoriesChangeHandler = (values, action) => {
    this.props.setNewCustomOptionCategory(action)
    this.table && this.table.reload(true)
  }
  render() {
    const { form, readOnly, productRepository } = this.props
    const {
      FlexibleCustomOptionsTable: { loading, data = [], totalRecordCount, originalData = {} },
    } = productRepository
    const categories = form.$('flexibleCustomOptionCategory').value || []
    const hideTable = !categories.length

    return (
      <RelatedProductsPanelStyled>
        <div className="d-flex">
          {readOnly && (
            <ReadOnlyField
              className="my-2 col-xl-6 px-0"
              value={categories && categories.length ? categories.map(item => item.name).join(', ') : ''}
              label={form.$('flexibleCustomOptionCategory').label}
            />
          )}
          {!readOnly && (
            <ReactSelect
              className="my-2 col-xl-6 px-0"
              options={{
                isMulti: true,
                placeholder: 'Select categories',
                options: this.state.categories,
                closeMenuOnSelect: false,
                components: {
                  Option: props => (
                    <OptionStyled categoryLevel={props.data.level}>
                      {props.data.isParent && <i className="icon ion-md-folder text-primary" />}
                      {!props.data.isParent && <i className="icon ion-ios-list text-primary" />}
                      <components.Option {...props} />
                    </OptionStyled>
                  ),
                },
                labelKey: 'name',
                valueKey: 'id',
              }}
              onChange={this.categoriesChangeHandler}
              field={form.$('flexibleCustomOptionCategory')}
            />
          )}
        </div>
        <ProductTableStyled className={cx(hideTable && 'd-none')}>
          <h6>Selected category products</h6>
          <ReactTable
            className="striped cell-vertical-center "
            columns={[
              {
                Header: 'Product Code',
                accessor: 'productCode',
                fixed: 'left',
                width: 160,
                Cell: ({ original }) => {
                  return (
                    <Link to={`${PRODUCTS_PATH}/${original.id}`} title={original.productCode} className="pr-2 ellipsis">
                      {original.productCode}
                    </Link>
                  )
                },
              },
              {
                Header: 'Product Name',
                accessor: 'name',
                Cell: ({ original }) => {
                  return (
                    <Link to={`${PRODUCTS_PATH}/${original.id}`} title={original.name} className="pr-2 ellipsis">
                      {original.name}
                    </Link>
                  )
                },
              },
              {
                Header: 'Categories',
                accessor: 'childCategory',
                Cell: ({ value }) => (value ? sort(value.split(',').map(item => item && item.trim())).join(', ') : ''),
              },
            ]}
            sort={{ id: 'name' }}
            data={data}
            loadData={this.fetchProducts}
            loading={loading}
            totalRecordCount={totalRecordCount}
            totalPages={originalData.totalPages}
            search
            ref={ref => (this.table = ref)}
          />
        </ProductTableStyled>
      </RelatedProductsPanelStyled>
    )
  }
}

const RelatedProductsPanelStyled = styled.div`
  padding-bottom: 25px;
  .icon.add {
    border-radius: 50%;
    height: 42px;
    width: 42px;
    color: ${props => props.theme.black};
    background-color: ${props => props.theme.lightDark};
  }
  a {
    color: inherit;
  }
`

const ProductTableStyled = styled.div`
  margin-top: 12px;
  h6 {
    text-transform: uppercase;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 0.75rem;
    font-weight: bold;
  }
  .ReactTable {
    width: auto !important;
  }
`

const OptionStyled = styled.div`
  &&& {
    display: flex;
    padding-left: 12px;
    .icon {
      margin-left: ${props => props.categoryLevel * 20}px;
    }
    .react-select__option {
      cursor: pointer;
      padding-left: 8px;
      font-size: 14px;
      color: inherit;
    }
  }
`

export default inject('categoryRepository', 'productRepository')(observer(FlexibleCustomOptionPanel))
