/* eslint-disable max-len */
import React from 'react'

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 23V8H6.11834V23H3Z" fill="white" />
    <path
      d="M10.8521 15.8944C10.8521 19.2563 11.8166 20.3831 14.3255 20.3831H19.2249V22.9937H14.1894C12.4734 22.9937 11.148 22.8498 9.76926 21.679C8.30773 20.4457 7.59767 18.48 7.59767 15.7692C7.59175 10.4666 9.70418 8 14.2663 8H19.2249V10.5668H14.3255C11.8166 10.5668 10.8521 12.1757 10.8521 15.8944Z"
      fill="white"
    />
    <path
      d="M23.2072 11.6563H24.1657C24.935 11.6563 25.3314 11.4872 25.3314 10.7923C25.3314 10.0974 24.9764 9.90333 24.1657 9.90333H20.7693V8.60742H24.8166C25.3077 8.60742 25.7338 8.6262 26.1125 8.85784C26.7042 9.22094 27.0001 9.85951 27.0001 10.8111C27.0001 12.2322 26.2722 12.996 24.8699 12.996H23.5267C22.7101 12.996 22.3728 13.2777 22.3728 14.0728V14.824H26.9705V16.1074H20.7693V13.8537C20.7693 12.2635 21.4793 11.6625 23.2072 11.6625V11.6563Z"
      fill="white"
    />
  </svg>
)
