import axios from 'axios'

import { jsonToQueryString } from 'helpers/Formatters'

export default class PelinkIdService {
  constructor(baseUrl, keycloak) {
    this.baseUrl = baseUrl
    this.keycloak = keycloak

    this.Instance = axios.create({
      baseURL: baseUrl,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
  }

  responseParser(response) {
    return { data: response.data, errors: [] }
  }

  errorParser(e) {
    return { data: null, errors: [{ message: e?.message || 'Unknown error' }] }
  }

  setHeaders() {
    this.Instance.defaults.headers['Authorization'] = `Bearer ${this.keycloak.token}`

    this.Instance.interceptors.response.use(null, error => {
      if (error.config && error.response?.status === 401) {
        return this.keycloak
          .updateToken()
          .then(() => {
            error.config.headers['Authorization'] = `Bearer ${this.keycloak.token}`
            return axios.request(error.config)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }

      return Promise.reject(error)
    })
  }

  async get(url, payload = {}, headers = {}) {
    this.setHeaders()
    return this.Instance.get(`${url}${jsonToQueryString(payload)}`, {
      ...headers,
    })
      .then(this.responseParser)
      .catch(this.errorParser)
  }
}
