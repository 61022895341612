import React from 'react'

import { Badge, Button } from 'reactstrap'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import cx from 'classnames'
import styled from 'styled-components'

import { formatDate, getFormattedDateString, groupNumber } from 'helpers/Formatters'
import { WARRANTY_TYPE } from 'definitions'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

import EssentialCarePlusSvg from './Icons/EssentialCare+'
import EssentialCareSvg from './Icons/EssentialCare'
import InControl2Svg from './Icons/InControl2'
import PrimeCarePlusSvg from './Icons/PrimeCare+'
import PrimeCareSvg from './Icons/PrimeCare'

class ProductsV2 extends React.Component {
  constructor(props) {
    super(props)
    this.fetchTable = debounce(this.fetchTable, 350).bind(this)

    this.state = {
      coTermProducts: [],
      grandTotal: 0,
      selection: {},
      validSelectionCount: 0,
      subscriptionColumnWidth: 100,
    }
  }

  // eslint-disable-next-line consistent-return
  fetchTable = async (params = {}) => {
    const {
      form,
      coTermProductRepository: { fetchTable },
    } = this.props
    const { coTermProducts } = this.state

    if (!form.isValid) return false

    const {
      customer,
      targetDate,
      serialNumbers: serialNumbersValue,
      allowLessThan1YearInd,
      skipSNWarrantyCheck,
    } = form.values()
    let serialNumbers = []

    if (serialNumbersValue) {
      serialNumbers = serialNumbersValue
        .trim()
        .split('\n')
        .filter(item => !!item)
      form.$('serialNumbers').clear()
    } else {
      serialNumbers = coTermProducts.map(item => item.serialNumber)
    }

    if (serialNumbers.length) {
      await fetchTable({
        version: 'v2',
        customerId: customer.id,
        targetDate: formatDate(targetDate, 'YYYY-MM-DD'),
        allowLessThan1YearInd,
        serialNumbers: serialNumbers.map(i => i.trim()),
        skipSNWarrantyCheck,
        search: params.search,
        pageable: {
          page: 0,
          size: serialNumbers.length,
        },
      })

      form.$('serialNumbers').set('rules', 'required')
      this.setMappedData()
    }
  }

  setMappedData = () => {
    const {
      coTermProductRepository: {
        Table: { data = [] },
      },
    } = this.props
    let { coTermProducts: existingCoTermProducts, selection, subscriptionColumnWidth } = this.state

    const newCoTermProducts = toJS(data).map((item, index) => {
      // Workaround to set the proper width size for subscription column
      if (item.coTermProducts?.length) {
        const width = 28 * item.coTermProducts.length + 16
        if (width > subscriptionColumnWidth) {
          subscriptionColumnWidth = width
        }
      }

      return {
        ...item,
        index,
        coTermProducts: (item.coTermProducts || [])
          .sort((item1, item2) => {
            return item1.coTermProduct.childCategory.localeCompare(item2.coTermProduct.childCategory)
          })
          .map((product, index) => {
            const totalPrice = (product.price * (product.termsInMonths || 1)) / 12
            const formmatedTotalPrice = this.formatPrice(totalPrice, product.decimalPlace)

            let active = false
            if (selection[item.serialNumber]) {
              if (selection[item.serialNumber].coTermProduct?.childCategory === product.coTermProduct.childCategory) {
                active = true
              }
            } else if (index === 0) {
              active = true
            }

            return {
              ...product,
              totalPrice: formmatedTotalPrice,
              active,
            }
          }),
      }
    })

    // Combine the new result to the existing and eliminating any duplicates
    const serialNumbersSet = new Set(newCoTermProducts.map(item => item.serialNumber))
    const coTermProducts = [
      ...newCoTermProducts,
      ...existingCoTermProducts.filter(item => !serialNumbersSet.has(item.serialNumber)),
    ]

    this.setState({ coTermProducts, subscriptionColumnWidth })
    this.setInitialSelectedCoTermProducts(coTermProducts)
  }

  setInitialSelectedCoTermProducts = coTermProducts => {
    const selection = coTermProducts.reduce((dictionary, item) => {
      if (item.coTermProducts?.length) {
        const product = item.coTermProducts.find(item => item.active)
        if (product) {
          dictionary[item.serialNumber] = product
        }
      }
      return dictionary
    }, {})
    this.calculateSelectedCoTermProducts(coTermProducts, selection)
  }

  calculateSelectedCoTermProducts = (coTermProducts, selection) => {
    let grandTotal = 0
    let validSelectionCount = 0

    const selectedCoTermProducts = Object.values(selection)
    if (selectedCoTermProducts.length) {
      const product = selectedCoTermProducts[0]

      const sum = selectedCoTermProducts.reduce((total, item) => {
        if (!item.remarks) {
          validSelectionCount += 1
          return total + parseFloat(item.totalPrice.replace(/,/g, ''))
        } else {
          return total
        }
      }, 0)

      grandTotal = this.formatPrice(sum, product.decimalPlace)
    }

    this.setState({ coTermProducts, selection, grandTotal, validSelectionCount })
    this.props.onSelect(selectedCoTermProducts, validSelectionCount != coTermProducts.length)
  }

  formatPrice = (price, decimalPlace) => {
    const noOfDecimalPlace = (decimalPlace?.decimalPlaceInd && decimalPlace?.noOfDecimalPlace) || 0
    let roundedNumber
    if (noOfDecimalPlace > 0) {
      var multiplier = Math.pow(10, noOfDecimalPlace)
      roundedNumber = Math.ceil(price * multiplier) / multiplier
    } else {
      roundedNumber = Math.ceil(price)
    }

    return groupNumber(roundedNumber)
  }

  getCategoryIcon = category => {
    switch (category) {
      case WARRANTY_TYPE.ESSENTIAL_CARE:
        return <EssentialCareSvg />
      case WARRANTY_TYPE.ESSENTIAL_CARE_PLUS:
        return <EssentialCarePlusSvg />
      case WARRANTY_TYPE.INCONTROL:
      case WARRANTY_TYPE.INCONTROL2:
        return <InControl2Svg />
      case WARRANTY_TYPE.PRIME_CARE:
        return <PrimeCareSvg />
      case WARRANTY_TYPE.PRIME_CARE_PLUS:
        return <PrimeCarePlusSvg />
      default:
        return category
    }
  }

  getCategoryColor = category => {
    switch (category) {
      case WARRANTY_TYPE.ESSENTIAL_CARE:
      case WARRANTY_TYPE.ESSENTIAL_CARE_PLUS:
      case WARRANTY_TYPE.INCONTROL:
      case WARRANTY_TYPE.INCONTROL2:
        return 'info'
      default:
        return 'primary'
    }
  }

  handleActiveCoTermProductChange = (products, index) => {
    const { selection } = this.state

    products.forEach((item, itemIndex) => {
      item.active = itemIndex === index
      if (item.active) {
        selection[item.serialNumber] = item
      }
    })
    this.calculateSelectedCoTermProducts(this.state.coTermProducts, selection)
  }

  handleRemoveSerialNumber = index => {
    const { selection } = this.state
    const coTermProducts = this.state.coTermProducts

    const removedItems = coTermProducts.splice(index, 1)
    if (removedItems?.length) {
      const [item] = removedItems
      delete selection[item.serialNumber]
    }

    this.calculateSelectedCoTermProducts(coTermProducts, selection)
  }

  render() {
    const {
      coTermProductRepository: {
        Table: { loading, totalRecordCount },
      },
    } = this.props
    const { coTermProducts, grandTotal, subscriptionColumnWidth, selection, validSelectionCount } = this.state

    return (
      <CardStyled title="Co Term V2 Product Matching Result" className="mb-3">
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'serialNumber',
              Header: 'Serial Number',
              Footer: () => <strong>Valid Input:</strong>,
              width: 105,
            },
            {
              accessor: 'childCategory',
              Header: 'Subscription',
              width: subscriptionColumnWidth,
              style: { overflow: 'visible' },
              Cell: ({ original }) => {
                if (original.errorMessage) {
                  return <span className="text-danger d-flex align-items-center">{original.errorMessage}</span>
                } else if (original.coTermProducts?.length) {
                  return (
                    <WarrantyTypesStyled>
                      {original.coTermProducts.map((product, index) => {
                        const childCategory = product.coTermProduct.childCategory
                        const active = product.active

                        return (
                          <WarrantyTypeButtonStyled
                            key={`${index}-${childCategory}`}
                            type="button"
                            color={this.getCategoryColor(childCategory)}
                            size="sm"
                            title={childCategory}
                            className={cx(!active && 'inactive')}
                            onClick={() => this.handleActiveCoTermProductChange(original.coTermProducts, index)}
                          >
                            {this.getCategoryIcon(childCategory)}
                          </WarrantyTypeButtonStyled>
                        )
                      })}
                    </WarrantyTypesStyled>
                  )
                } else {
                  return <span className="text-danger d-flex align-items-center">There are no matching products</span>
                }
              },
              Footer: () => (
                <strong>{coTermProducts?.length ? `${validSelectionCount}/${coTermProducts.length}` : 0}</strong>
              ),
            },
            {
              accessor: 'productCode',
              Header: 'Product Code',
              className: 'ellipsis',
              width: 160,
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  return original.coTermProducts.find(product => product.active).productCode
                }
                return false
              },
            },
            {
              accessor: 'expiryDate',
              Header: 'Expiry Date',
              width: 100,
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  const product = original.coTermProducts.find(product => product.active)
                  if (product) {
                    const childCategory = product.coTermProduct.childCategory
                    let invalidDateValue
                    let isExpired = false

                    switch (childCategory) {
                      case WARRANTY_TYPE.INCONTROL:
                      case WARRANTY_TYPE.INCONTROL2:
                        invalidDateValue = '(New Subscription)'
                        break
                      default:
                        invalidDateValue = 'N/A'
                        break
                    }

                    const formattedDate = getFormattedDateString(product.expiryDate, invalidDateValue)
                    if (formattedDate !== invalidDateValue) {
                      if (new Date(product.expiryDate) < new Date()) {
                        isExpired = true
                      }
                    }

                    return <span className={cx(isExpired && 'text-danger')}>{formattedDate}</span>
                  }
                  return ''
                }
                return false
              },
            },
            {
              accessor: 'newStartDate',
              Header: 'New Start Date',
              width: 105,
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  return getFormattedDateString(original.coTermProducts.find(product => product.active).newStartDate)
                }
                return false
              },
            },
            {
              accessor: 'unitPrice',
              Header: 'Unit Price',
              width: 100,
              headerClassName: 'text-right',
              className: 'justify-content-end',
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  const activeProduct = original.coTermProducts.find(product => product.active)
                  const hasDiscount = activeProduct.price > 0 && activeProduct.price !== activeProduct.unitPrice
                  return (
                    <div>
                      <UnitPriceStyled className={cx('mb-1', hasDiscount && 'text-decoration-strikethrough')}>
                        {groupNumber(activeProduct.unitPrice)}
                      </UnitPriceStyled>
                      {hasDiscount && (
                        <div className="discounted-price text-right">{groupNumber(activeProduct.price)}</div>
                      )}
                    </div>
                  )
                }
                return false
              },
              Footer: () => <strong>Grand Total:</strong>,
            },
            {
              accessor: 'termsInMonths',
              Header: 'Terms in Months',
              headerClassName: 'text-right',
              className: 'justify-content-end',
              width: 115,
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  return original.coTermProducts.find(product => product.active)?.termsInMonths || ''
                }
                return false
              },
            },
            {
              accessor: 'price',
              Header: 'Total Price',
              width: 120,
              headerClassName: 'text-right',
              className: 'justify-content-end',
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  return original.coTermProducts.find(product => product.active).totalPrice
                }
                return false
              },
              Footer: () => {
                const selectedCoTermProducts = Object.values(selection)
                if (selectedCoTermProducts.length) {
                  const product = selectedCoTermProducts[0]

                  return (
                    <strong>
                      <span className="mr-1">{product?.currency}</span> {grandTotal}
                    </strong>
                  )
                }
                return <strong>0</strong>
              },
            },
            {
              accessor: 'coTermProduct.productCode',
              Header: 'Co-Term Product',
              width: 180,
              Cell: ({ original }) => {
                if (original.coTermProducts?.length) {
                  const productCode = original.coTermProducts.find(product => product.active).coTermProduct.productCode
                  return (
                    <Badge color="light" title={productCode} className="font-size-11 text-uppercase ellipsis">
                      {productCode}
                    </Badge>
                  )
                }
                return false
              },
            },
            {
              accessor: 'remarks',
              Header: 'Remarks',
              width: 200,
              sortable: false,
              Cell: ({ original }) => {
                if (original.errorMessage) {
                  return (
                    <span className="text-danger">
                      <i className="font-size-14 ion-md-information-circle-outline" />
                      <span className="ml-2">Error</span>
                    </span>
                  )
                } else if (original.coTermProducts?.length) {
                  const coTermProduct = original.coTermProducts.find(product => product.active)
                  if (coTermProduct.remarks) {
                    return (
                      <span
                        className="text-danger d-flex align-items-center text-break"
                        style={{ whiteSpace: 'normal' }}
                      >
                        <i className="font-size-14 ion-md-information-circle-outline" />
                        <span className="ml-2">{coTermProduct.remarks}</span>
                      </span>
                    )
                  }
                }
                return false
              },
            },
            {
              accessor: 'index',
              Header: 'Action',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              sortable: false,
              Cell: ({ row }) => (
                <IconButton
                  icon="ios-trash"
                  title="Remove serial number"
                  onClick={() => this.handleRemoveSerialNumber(row._index)}
                />
              ),
            },
          ]}
          sort={{ id: 'serialNumber' }}
          data={coTermProducts}
          loadData={this.fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          ref={ref => (this.table = ref)}
          search
          manual={false}
        />
      </CardStyled>
    )
  }
}

const CardStyled = styled(Card)`
  &&& {
    .rt-table {
      .rt-tbody {
        .rt-td {
          min-height: 46px;
          padding: 6px;
        }
        .rt-tr {
          overflow: hidden;
        }
      }
    }
  }
`

const WarrantyTypesStyled = styled.div`
  button {
    padding: 2px 4px;
    border-radius: 0;
    margin-right: 1px;
    width: 28px;
    height: 28px;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child {
      margin-right: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
`

const WarrantyTypeButtonStyled = styled(Button)`
  &.btn {
    &.inactive:not(:hover) {
      background-color: ${props => (props.color === 'info' ? 'rgba(23, 162, 184, 0.4)' : 'rgba(255, 184, 28, 0.4)')};
      border-color: ${props => (props.color === 'info' ? 'rgba(23, 162, 184, 0.4)' : 'rgba(255, 184, 28, 0.4)')};
    }
  }
`

const UnitPriceStyled = styled.div`
  &.text-decoration-strikethrough {
    margin-right: -6px;
  }
`

export default inject('coTermProductRepository')(observer(ProductsV2))
