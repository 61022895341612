import React, { Component } from 'react'

import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { isNull } from 'helpers/Common'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

import ImagePanel from 'components/Categories/CategoryFormModal/ImagePanel'

class CustomerGroupFormModal extends Component {
  submitRef = React.createRef()
  form = null
  state = {
    isModalOpen: false,
    selectedImage: {},
  }

  constructor(props) {
    super(props)
    const {
      selected: {
        name,
        excludedCustomerGroups,
        warrantyInd = false,
        iconClassName,
        featuredHardwareInd = false,
        featuredSoftwareInd = false,
        featuredOrder,
        storeFrontOrder,
      },
      type,
    } = this.props
    const fields = {
      name: {
        type: 'text',
        label: 'Name',
        rules: 'required',
        value: type === 'edit_category' ? name : '',
      },
      excludedCustomerGroups: {
        type: 'select',
        label: 'Exclude Customer Groups',
        value: excludedCustomerGroups || null,
      },
      warrantyInd: {
        type: 'switch',
        label: 'Warranty?',
        value: warrantyInd,
      },
      iconClassName: {
        label: 'Icon Classname (internally use in icon styling)',
        value: iconClassName,
      },
      featuredHardwareInd: {
        type: 'switch',
        label: 'Display in Featured Hardware Section',
        value: featuredHardwareInd,
      },
      featuredSoftwareInd: {
        type: 'switch',
        label: 'Display in Featured Software Section',
        value: featuredSoftwareInd,
      },
      featuredOrder: {
        type: 'number',
        label: 'Sort Order in Featured Section',
        value: featuredOrder,
      },
      storeFrontOrder: {
        type: 'number',
        label: 'Sort Order in Menu and Filter',
        value: storeFrontOrder,
      },
    }
    this.form = new Form({ fields }, { name: 'CustomerGroupFormModal', handleSubmit: this.handleSubmit })
  }

  componentWillUnmount() {
    this.props.categoryRepository.clearErrorMessages()
  }

  handleImageError = event => {
    event.target.setAttribute('src', require(`images/no-image.svg`).default)
  }

  handleSubmit = form => {
    const values = form.values()
    let {
      selected,
      type,
      setSelected,
      categoryRepository: { Table },
    } = this.props

    switch (type) {
      case 'sub_category':
        values.parent = selected.id
        this.props.categoryRepository.create(
          { ...values, excludedCustomerGroups: isNull(values.excludedCustomerGroups) },
          res => {
            function find(id, list) {
              return list.map(item => {
                if (item.subCategories) {
                  item.subCategories = find(id, item.subCategories)
                }
                if (item.id === id) {
                  return {
                    ...item,
                    isOpen: true,
                    subCategories: item.subCategories ? [...item.subCategories, res] : [res],
                  }
                } else {
                  return { ...item }
                }
              })
            }

            Table.data = find(res.parent, Table.data)
            setSelected(res)
            this.props.toggleModal()
          }
        )

        break
      case 'edit_category':
        delete values.parent
        this.props.categoryRepository.patch(
          {
            ...values,
            id: selected.id,
            excludedCustomerGroups: isNull(values.excludedCustomerGroups),
          },
          res => {
            function find(id, list) {
              return list.map(item => {
                if (item.subCategories) {
                  item.subCategories = find(id, item.subCategories)
                }
                if (item.id === id) {
                  return { ...res, ...(item.subCategories && { subCategories: item.subCategories }) }
                } else {
                  return { ...item }
                }
              })
            }

            Table.data = find(res.id, Table.data)
            setSelected(res)
            this.props.toggleModal()
          }
        )

        break
      default:
        delete values.parent
        this.props.categoryRepository.create(
          {
            ...values,
            excludedCustomerGroups: isNull(values.excludedCustomerGroups),
            rootInd: type === 'root_category',
          },
          res => {
            Table.data = [...toJS(Table.data), res]
            setSelected(res)
            this.props.toggleModal()
          }
        )

        break
    }
  }

  handleImageOnChange = (name, category) => {
    const imgName = name !== 'image' ? `${name}Image` : name
    this.props.selected[imgName] = category[imgName]

    this.forceUpdate()
  }

  triggerSubmit = e => {
    this.submitRef.current.click()
  }
  toggleModal = selectedImage => this.setState({ isModalOpen: !this.state.isModalOpen, selectedImage })

  render() {
    const { categoryRepository, customerGroupRepository, isModalOpen, toggleModal, selected, type } = this.props
    const {
      CRUD: { errors, loading, loadingFailed },
    } = categoryRepository
    const { bannerImage, image, iconImage, overlayImage } = selected

    let title
    let editMode = false
    switch (type) {
      case 'sub_category':
        title = 'Add New Sub'
        break
      case 'edit_category':
        title = 'Edit'
        editMode = true
        break
      default:
        title = 'Add New Root'
        break
    }

    return (
      <Modal
        open={isModalOpen}
        title={`${title} Category`}
        confirmLabel={`Save`}
        loading={loading}
        loadingMessage={`Saving please wait...`}
        onConfirm={this.triggerSubmit}
        onClose={toggleModal}
        hideConfirm={!allowedToAppAdminRole()}
      >
        <FormContainer
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={loadingFailed && errors}
          plain
        >
          <Input field={this.form.$('name')} />
          <ReactSelect
            field={this.form.$('excludedCustomerGroups')}
            customLabelKey={['name', 'id']}
            serverSide
            search={customerGroupRepository.reactSelectSearch}
            options={{
              isMulti: true,
              valueKey: 'id',
              labelKey: 'name',
              defaultOptions: true,
            }}
          />
          {editMode && (
            <React.Fragment>
              <SfSectionStyled>Storefront settings:</SfSectionStyled>
              <Checkbox id="switchCategoryWarrantyInd" field={this.form.$('warrantyInd')} />
              <Checkbox id="switchCategoryFeaturedHardwareInd" field={this.form.$('featuredHardwareInd')} />
              <Checkbox id="switchCategoryFeaturedSoftwareInd" field={this.form.$('featuredSoftwareInd')} />

              <div className="grouped">
                <Input field={this.form.$('storeFrontOrder')} className="pr-2" />
                <Input field={this.form.$('featuredOrder')} className="pl-2" />
              </div>

              <ImagePanel
                categoryRepository={categoryRepository}
                categoryId={selected.id}
                image={bannerImage}
                name="banner"
                label="Banner Image"
                size="xl"
                onToggleModal={this.toggleModal}
                onChange={this.handleImageOnChange}
              />
              <ImagePanel
                categoryRepository={categoryRepository}
                categoryId={selected.id}
                image={image}
                name="image"
                label="Category Image (Used in featured section)"
                size="lg"
                onToggleModal={this.toggleModal}
                onChange={this.handleImageOnChange}
              />
              <ImagePanel
                categoryRepository={categoryRepository}
                categoryId={selected.id}
                image={iconImage}
                name="icon"
                label="Menu and Category Filter Icon"
                size="sm"
                onToggleModal={this.toggleModal}
                onChange={this.handleImageOnChange}
              />
              <Input field={this.form.$('iconClassName')} />
              <ImagePanel
                categoryRepository={categoryRepository}
                categoryId={selected.id}
                image={overlayImage}
                name="overlay"
                label="Overlay Image for Warranty Products"
                size="sm"
                onToggleModal={this.toggleModal}
                onChange={this.handleImageOnChange}
              />
            </React.Fragment>
          )}
          <button ref={this.submitRef} type="submit" className="d-none" />
        </FormContainer>
        <Modal
          title=""
          size={this.state.selectedImage.size}
          backdrop
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          hideConfirm
          hideCancel
          centered
        >
          <ModalImageStyled
            src={`${process.env.REACT_APP_ATTACHMENT_SERVER}${this.state.selectedImage.resourcePath}`}
            alt={this.state.selectedImage.fileName}
            title={this.state.selectedImage.fileName}
          />
        </Modal>
      </Modal>
    )
  }
}

const ModalImageStyled = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`

const SfSectionStyled = styled.h5`
  margin-top: 1rem;
  text-transform: uppercase;
  font-size: 14px;
`

export default decorate(inject('categoryRepository', 'customerGroupRepository')(observer(CustomerGroupFormModal)), {})
