import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ORDERS_PATH } from 'definitions'
import Loader from 'components/Common/Loader'
import OrderForm from './OrderForm/'

import { getFormattedDateString } from 'helpers/Formatters'

class OrderEdit extends Component {
  constructor(props) {
    super(props)
    this.loadOrder(this.props.match.params.orderId)
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.orderId !== prevProps.match.params.orderId) {
      this.loadOrder(this.props.match.params.orderId)
    }
  }
  componentWillUnmount() {
    this.props.orderRepository.reset()
    const toasterClose = document.getElementsByClassName('toaster-close')
    for (let i = 0; i < toasterClose.length; i++) {
      setTimeout(() => toasterClose[i].click(), 50)
    }
  }
  loadOrder = orderId => {
    const {
      orderRepository: { getById },
    } = this.props
    getById(orderId)
  }
  updateStatus = payload => {
    const {
      orderRepository: {
        CRUD: { data },
      },
    } = this.props

    this.props.orderRepository.updateStatus(data.id, payload, res => {
      this.props.history.push(`${ORDERS_PATH}/${res.id}`)
    })
  }
  render() {
    const {
      orderRepository: {
        CRUD: { data, loading },
      },
      quotation,
    } = this.props
    const orderNumber = data.orderNumber || data.tempOrderNumber || 'N/A'
    return loading && !data.id ? (
      <Loader />
    ) : (
      <OrderForm
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: quotation ? 'Quotations' : 'Orders',
            href: quotation ? '/quotations' : '/orders',
          },
          {
            label: orderNumber,
          },
        ]}
        title={
          <>
            {quotation ? 'Quotation' : 'Order'} Details{' '}
            {quotation && (data.status === 'PENDING_APPROVAL' || data.status === 'NEW') && data.validDate ? (
              <small className="font-italic" style={{ fontSize: 12, color: '#666' }}>
                (Valid until {getFormattedDateString(data.validDate)})
              </small>
            ) : (
              ''
            )}
          </>
        }
        data={data}
        {...this.props}
        step={2}
      />
    )
  }
}

export default decorate(inject('orderRepository')(observer(OrderEdit)), {})
