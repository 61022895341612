import { Col, Row } from 'reactstrap'
import { debounce } from 'lodash'
import { decorate } from 'mobx'
import { inject } from 'mobx-react'
import React from 'react'

import { InputStyled, ReactSelectStyled } from './OrderTable.styled'

class Filters extends React.Component {
  filters = {}
  state = {
    stores: [],
    orderStatuses: [],
  }

  constructor(props) {
    super(props)

    this.initializeDefaultFilters()
    this.debouncedHandleFilterChange = debounce(this.handleFilterChange, 350)
  }

  componentDidMount() {
    this.fetchOptions()
  }

  initializeDefaultFilters = () => {
    const { searchRepository, pageKey } = this.props

    const filters = searchRepository.Pages[pageKey]?.filters
    this.filters = filters || {}

    if (filters) {
      this.setState({
        stores: filters.stores,
        orderStatuses: filters.orderStatuses,
      })
    }
  }

  fetchOptions = async () => {
    const { commonRepository, storeRepository } = this.props
    let stores = []
    let orderStatuses = []

    const result = await storeRepository.reactSelectSearch({ showDisabled: false })
    if (result) {
      stores = result.data?.content?.map(item => ({
        value: item.name,
        label: item.name,
      }))
    }

    const statusResult = await commonRepository.reactSelectSearch({ sort: 'label,asc', size: 1000 }, 'orderStatus')
    if (statusResult?.data?.content?.length) {
      orderStatuses = statusResult.data.content.map(item => ({
        value: item.value,
        label: item.label,
      }))
    }

    this.setState({
      stores,
      orderStatuses,
    })
  }

  handleFilterChange = (type, value) => {
    this.filters[type] = value
    this.props.onChange && this.props.onChange(this.filters)
  }

  render() {
    return (
      <Row>
        <Col xs="4" className="pr-0">
          <ReactSelectStyled
            plain
            options={{
              defaultValue: this.filters.stores,
              options: this.state.stores,
              placeholder: 'Filter By Stores',
              isMulti: true,
              closeMenuOnSelect: false,
            }}
            onChange={selection => {
              this.debouncedHandleFilterChange('stores', selection)
            }}
          />
        </Col>
        <Col xs="4" className="pr-0">
          <ReactSelectStyled
            plain
            options={{
              defaultValue: this.filters.status,
              options: this.state.orderStatuses,
              placeholder: 'Filter By Status',
            }}
            onChange={selection => this.debouncedHandleFilterChange('status', selection)}
          />
        </Col>
        <Col xs="4">
          <InputStyled
            defaultValue={this.filters.referralCode}
            placeholder="Filter By Referral Code"
            onChange={event => this.debouncedHandleFilterChange('referralCode', event.target.value?.toUpperCase())}
            onBlur={event => (event.target.value = event.target.value?.toUpperCase())}
          />
        </Col>
      </Row>
    )
  }
}

export default decorate(inject('commonRepository', 'storeRepository')(Filters), {})
