import React from 'react'
import styled from 'styled-components'

import ErrorsList from 'components/Common/ErrorsList'
import ReactTable from 'components/Common/ReactTable'

export default ({ data = [], errors = [], options, criteria }) => {
  const { title, moreUrl, tableOptions } = options
  const hasError = errors.length > 0
  const hasData = data.length > 0
  return (
    <SearchResultStyled className="pb-2">
      <h6>{title}</h6>
      {hasError > 0 && <ErrorsList errors={errors} />}
      {hasData && (
        <React.Fragment>
          <ReactTable
            className="striped cell-vertical-center"
            sort={{ id: 'createdDate', desc: true }}
            data={data}
            loadData={() => () => {}}
            showRowPerPage={false}
            showPager={false}
            handleRowClick={({ original }) => (window.location = tableOptions.getRowUrl({ original }))}
            {...tableOptions}
          />
          <div className="text-right" style={{ fontSize: '14px' }}>
            <a href={`${moreUrl}?search=${encodeURI(criteria)}`}>More...</a>
          </div>
        </React.Fragment>
      )}
    </SearchResultStyled>
  )
}

const SearchResultStyled = styled.div`
  &&& {
    .ReactTable {
      margin-bottom: 5px;
      .rt-td {
        min-height: 0;
        padding-top: 11px;
        padding-bottom: 11px;
      }
    }
  }
`
