import React from 'react'

import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap'
import styled from 'styled-components'

const CardComponent = ({ actions, titleActions, children, title, className = '', cardBodyClassName = '', style }) => (
  <CardStyled className={`${className}`} style={style}>
    {title && (
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        {titleActions}
      </CardHeader>
    )}
    <CardBody className={`${cardBodyClassName} ${!title ? 'pt-3' : ''}`}>{children}</CardBody>
    {actions && <CardFooter className="card-action bg-transparent">{actions}</CardFooter>}
  </CardStyled>
)

const CardStyled = styled(Card)`
  &.card {
    margin-bottom: 30px;
    background: ${props => props.theme.white};
    border: 0px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    .card-header {
      display: flex;
      justify-content: space-between;
      border: none;
      padding: 15px 20px 0 20px;
      background: transparent;
      .card-title {
        font-family: Helvetica, Arial, sans-serif;
        min-height: 25px;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
      }
    }
    .card-action {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1.25rem;
      border: none;
    }
    .card-body {
      padding-top: 0px;
    }
  }
`

export default CardComponent
