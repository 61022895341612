/* @flow */
import React, { Component } from 'react'

import { Button, Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import queryString from 'query-string'

import { formatNumber } from 'helpers/Formatters'
import { getFormattedDateString, getFormattedDateTimeString } from 'helpers/Formatters'
import { PAYMENTS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import PaymentStatus from 'components/Payments/PaymentStatus'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

const pageKey = 'payments'

const StatusButton = ({ status, className, selected, handleFilterStatus, ...rest }) => {
  return (
    <Button
      size="xs"
      style={{ minWidth: '80px' }}
      className={cx(className, selected && 'selected')}
      onClick={() => handleFilterStatus(status?.value)}
      {...rest}
    >
      {status ? status.label : 'All'}
    </Button>
  )
}
class Payments extends Component {
  table = null
  filters = null
  componentDidMount() {
    const { history } = this.props
    const { search } = queryString.parse(this.props.location.search)
    search && history.replace('/payments', {})
  }
  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const filters = searchRepository.Pages[pageKey]?.filters
    if (filters) {
      this.filters = filters
    }
  }
  handleCreate = () => {
    this.props.history.push('/payments/new')
  }
  handleFilterStatus = status => {
    if (status) {
      this.filters = { status }
    } else {
      this.filters = null
    }
    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload(false, true)
  }
  handleResendEmail = payment => {
    ToasterNotify.confirm({
      message: 'Do you confirm to resend the payment notification to the relevant email addresses?',
      title: 'Warning',
      onConfirm: async () => {
        this.props.paymentRepository.resendEmail(payment.id, ({ data, errors }) => {
          if (data && !errors.length) {
            ToasterNotify.alert({ message: 'Email was successfully sent.' })
          } else {
            ToasterNotify.alert({ message: `Failed to Resend Email: ${errors[0].message}`, color: 'error' })
          }
        })
      },
    })
  }
  render() {
    const { paymentRepository, searchRepository } = this.props
    const {
      Table: { data, loading, originalData, totalRecordCount },
      CRUD,
      fetchTable,
    } = paymentRepository
    const { search } = queryString.parse(this.props.location.search)

    return (
      <ContentContainer
        title="Payments"
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Online Payments',
          },
        ]}
      >
        <Row className="mb-3">
          <Col>
            <StatusButton color="dark" selected={!this.filters?.status} handleFilterStatus={this.handleFilterStatus} />
            <StatusButton
              status={{
                label: 'Pending Payment',
                value: 'PENDING_PAYMENT',
              }}
              color="danger"
              className="mx-2"
              selected={this.filters?.status == 'PENDING_PAYMENT'}
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Paid',
                value: 'PAID',
              }}
              color="dark"
              selected={this.filters?.status == 'PAID'}
              handleFilterStatus={this.handleFilterStatus}
            />
            <StatusButton
              status={{
                label: 'Void',
                value: 'VOID',
              }}
              color="dark"
              selected={this.filters?.status == 'VOID'}
              handleFilterStatus={this.handleFilterStatus}
              className="mx-2"
            />
          </Col>
        </Row>
        <ReactTable
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) => `${PAYMENTS_PATH}/${original.id}`}
          className="striped cell-vertical-center horizontal-scroll"
          columns={[
            { accessor: 'orderId', Header: 'Order ID', width: 120 },
            { accessor: 'invoiceNumber', Header: 'Invoice Number', width: 150 },
            {
              accessor: 'description',
              Header: 'Description',
              minWidth: 250,
              Cell: ({ original }) => (
                <span title={original.description} className="pr-2 ellipsis row-clickable">
                  {original.description}
                </span>
              ),
            },
            { accessor: 'paymentTo', Header: 'Payment to', width: 150 },
            {
              accessor: 'ccs',
              Header: '',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 80,
              sortable: false,
              Cell: ({ original }) =>
                original.ccs && !!original.ccs.length ? (
                  <div className="text-left">
                    <Button
                      id={`PopoverFocus-${original.id}`}
                      color=""
                      size="xs"
                      style={{ fontSize: 10 }}
                      className="text-primary"
                      onClick={event => event.preventDefault()}
                    >
                      [ View List ]
                    </Button>
                    <UncontrolledPopover trigger="focus" placement="bottom" target={`PopoverFocus-${original.id}`}>
                      <PopoverBody>{original.ccs && original.ccs.join(', ')}</PopoverBody>
                    </UncontrolledPopover>
                  </div>
                ) : (
                  'N/A'
                ),
            },
            {
              accessor: 'amount',
              Header: 'Amount',
              headerClassName: 'justify-content-end',
              className: 'justify-content-end',
              width: 120,
              Cell: ({ original }) => <div>{formatNumber(original.amount)}</div>,
            },
            {
              accessor: 'amountCurrency',
              Header: 'Currency',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 100,
            },
            {
              accessor: 'status',
              Header: 'Status',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 150,
              Cell: ({ original }) => <PaymentStatus status={original.status} />,
            },
            {
              accessor: 'dueDate',
              Header: 'Due Date',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 120,
              Cell: ({ original }) => getFormattedDateString(original.dueDate),
            },
            {
              accessor: 'paymentDate',
              Header: 'Paid On',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 120,
              Cell: ({ original }) => getFormattedDateTimeString(original.paymentDate),
            },
            {
              accessor: 'createdDate',
              Header: 'Created Date',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 120,
              Cell: ({ original }) => getFormattedDateTimeString(original.createdDate),
            },
            { accessor: 'createdBy', Header: 'Created By', width: 120 },
          ]}
          data={data}
          sort={{ id: 'createdDate', desc: true }}
          loadData={params => fetchTable({ ...params, ...this.filters })}
          loading={loading || CRUD.loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          searchCriteria={search}
          ref={ref => (this.table = ref)}
          actions={
            <Visible toOrderProcessRole>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Create New Payment" />
              </div>
            </Visible>
          }
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('paymentRepository', 'searchRepository')(observer(Payments)), {})
