import React, { Component } from 'react'

/** Plugins */
import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

/** Common Components */
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { SANCTION_COUNTRY_TYPE, SANCTION_ENTITY_TYPE } from 'definitions'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import ToasterNotify from 'helpers/ToasterNotify'

export const handleCheckSelectedCustomer = customer => {
  ToasterNotify.close()
  const blacklisted = customer && customer.blacklisted
  const sanctioned = customer && customer.sanctioned
  let toasterError = []
  if (sanctioned) {
    toasterError.push(`Customer: ${customer.name} is sanctioned.`)
  } else if (blacklisted) {
    toasterError.push(`Customer: ${customer.name} is blacklisted.`)
  }

  if (blacklisted || sanctioned) {
    ToasterNotify.alert({
      title: 'Warning',
      message: toasterError.map((err, i) => <div key={i}>{err}</div>),
      color: 'error',
      backdrop: false,
    })
  } else {
    ToasterNotify.close()
  }
}
class StoreFieldClass extends Component {
  handleStoreChange = selected => {
    if (!this.props.isCreate) {
      this.props.orderRepository.CRUD.data.orderItems = []
      this.props.form.$('orderItems').set([])
      this.props.computeOrderSummary()
    }
  }
  render() {
    const { form, storeRepository, selectedCustomer, readOnly = false } = this.props
    return (
      <ReactSelect
        field={form.$('store')}
        customLabelKey={['name', 'id']}
        serverSide
        search={params => selectedCustomer && storeRepository.fetchStoreByCustomer(params, selectedCustomer.id)}
        options={{
          valueKey: 'id',
          labelKey: 'name',
          defaultOptions: true,
        }}
        onChange={this.handleStoreChange}
        readOnly={readOnly}
        showTooltip
      />
    )
  }
}

export const StoreField = inject('orderRepository', 'storeRepository')(observer(StoreFieldClass))

class CustomerPanel extends Component {
  state = {
    isSanctioned: false,
    isWhitelisted: false,
    showMatches: false,
    defaultStore: null,
  }

  componentDidMount() {
    const { customerId } = queryString.parse(window.location.search)
    if (customerId) {
      this.props.customerRepository.getById(customerId, data => {
        this.props.form.$('customer').set(data)
      })
    }

    this.props.orderTypeRepository.getById('all')

    const {
      orderRepository: {
        CRUD: { data: orderData = {} },
      },
    } = this.props
    this.handleSelectedCustomer(orderData.customer, true)
  }

  componentWillUnmount() {
    ToasterNotify.close()
  }
  getSanctionList = () => {
    const {
      sanctionCustomerRepository: {
        CRUD: { data: { content = [] } = {} },
      },
    } = this.props

    if (content.length) {
      return content.map(item => {
        switch (item.type) {
          case SANCTION_ENTITY_TYPE:
            return { message: item.companyName }
          case SANCTION_COUNTRY_TYPE:
            return { message: item.country }
          default:
            return { message: `${item.firstName} ${item.lastName}` }
        }
      })
    }

    return [{ message: 'No match found' }]
  }
  handleSelectedCustomer = selected => {
    // const sanctioned = selected && selected.sanctioned
    // const whitelisted = selected && selected.whitelisted
    handleCheckSelectedCustomer(selected)
    // this.setState(
    //   { isSanctioned: sanctioned, isWhitelisted: whitelisted, showMatches: false },
    //   () => {
    //     if (this.state.isSanctioned) {
    //       const { sanctionCustomerRepository } = this.props
    //       sanctionCustomerRepository.getById(selected.id)
    //     }
    //   },
    //   () => {}
    // )
  }

  toggleCustomerMatches = () => {
    this.setState({ showMatches: !this.state.showMatches })
  }

  render() {
    const {
      form,
      customerRepository: { customerReactSelectSearch },
      orderTypeRepository: {
        CRUD: { data: orderTypes = [] },
      },
      step,
      readOnly,
      recordTypeStatic,
      isCoTerm = false,
    } = this.props

    const selectedCustomer = form.$('customer').value
    const groupName = step === 2 && form.$('groups').value ? form.$('groups').value.map(group => group.name)[0] : ''
    const store = step === 2 && form.$('store').value
    const orderType = form.$('orderType').value

    const blacklisted = selectedCustomer && selectedCustomer.blacklisted
    const sanctioned = selectedCustomer && selectedCustomer.sanctioned
    const customerNameAndPartnerId =
      selectedCustomer && selectedCustomer.partnerId && selectedCustomer.partnerId !== '0'
        ? `${selectedCustomer.name} (${selectedCustomer.partnerId})`
        : selectedCustomer && selectedCustomer.name

    return step === 2 ? (
      <Card className="customer-panel">
        <Row>
          <ColStyled>
            {/* <ReactSelect
              field={form.$('customer')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload => reactSelectSearch({ ...payload, status: 'ACTIVE' })}
              options={{
                valueKey: 'id',
                labelKey: 'name',
                options: selectedCustomer ? [selectedCustomer] : [],
                defaultOptions: true,
                isClearable: false,
              }}
              onChange={this.handleSelectedCustomer}
              readOnly
              className={blacklisted || sanctioned ? 'has-error' : ''}
              showTooltip
            /> */}
            <ReadOnlyField
              value={customerNameAndPartnerId}
              label="Customer"
              showTooltip
              className={blacklisted || sanctioned ? 'has-error' : ''}
            />
          </ColStyled>
          <ColStyled>
            <ReadOnlyField value={groupName} label="Customer Group" showTooltip />
          </ColStyled>
          <ColStyled>
            {selectedCustomer && !isCoTerm ? (
              <StoreField {...this.props} selectedCustomer={selectedCustomer} readOnly={true} />
            ) : (
              <ReadOnlyField value={store && store.name} label="Store" showTooltip />
            )}
          </ColStyled>
          <ColStyled>
            <ReadOnlyField value={store && store.currency} label="Currency" showTooltip />
          </ColStyled>
          <ColStyled>
            {recordTypeStatic ? (
              <ReadOnlyField value={orderType.name} label="Order Type" showTooltip />
            ) : (
              <ReactSelect
                field={form.$('orderType')}
                options={{
                  valueKey: 'id',
                  labelKey: 'name',
                  options: Array.isArray(orderTypes) ? orderTypes : [],
                }}
                readOnly={true}
                showTooltip
              />
            )}
          </ColStyled>
          <ColStyled>
            <Input field={form.$('poNumber')} readOnly={readOnly || blacklisted} showTooltip />
          </ColStyled>
          <ColStyled>
            <ReadOnlyField
              value={getFormattedDateTimeString(form.$('orderDate').value)}
              label="Order Date"
              showTooltip
            />
          </ColStyled>
        </Row>
      </Card>
    ) : (
      <CardStyled title="Account Information">
        <ReactSelect
          field={form.$('customer')}
          serverSide
          search={async payload => await customerReactSelectSearch({ ...payload, status: 'ACTIVE' })}
          options={{
            valueKey: 'id',
            labelKey: 'customerLabel',
            options: selectedCustomer ? [selectedCustomer] : [],
            value: selectedCustomer ? selectedCustomer : undefined,
            defaultOptions: true,
          }}
          onChange={this.handleSelectedCustomer}
          className={blacklisted || sanctioned ? 'has-error' : ''}
          showTooltip
        />
        <Visible toAppAdminRole>
          <div className="text-right text-link">
            <LinkStyled to="/customers/new?redirectTo=/orders/new">Create New Customer</LinkStyled>
          </div>
        </Visible>
        <ReactSelect
          field={form.$('orderType')}
          options={{
            valueKey: 'id',
            labelKey: 'name',
            options: Array.isArray(orderTypes) ? orderTypes : [],
            isDisabled: blacklisted,
          }}
        />
        <Input field={form.$('poNumber')} disabled={blacklisted} />
        {orderType && !orderType.counterAdjustmentInd && (
          <Input field={form.$('orderNumberSource')} disabled={blacklisted} />
        )}
        <Checkbox field={form.$('skipSNWarrantyInd')} disabled={blacklisted} />
      </CardStyled>
    )
  }
}

const LinkStyled = styled(Link)`
  font-size: 11px;
  font-weight: bold;
`
export const ColStyled = styled(Col)`
  &&& {
    flex: 0 0 14.28571%;
    max-width: ${props => (props.width ? `${props.width}%` : '14.28571%')};
    padding: 6px;
    &:first-child {
      padding-left: 15px;
    }
    &:last-child {
      padding-right: 15px;
    }
  }
  .has-error {
    .form-control,
    label,
    .react-select__single-value {
      color: #e57373 !important;
    }
    .form-control,
    .react-select__control {
      border-color: #e57373 !important;
    }
  }
`

const CardStyled = styled(Card)`
  .has-error {
    .form-control,
    label,
    .react-select__single-value {
      color: #e57373 !important;
    }
    .form-control,
    .react-select__control {
      border-color: #e57373 !important;
    }
  }
`

export default inject('customerRepository', 'orderTypeRepository')(observer(CustomerPanel))
