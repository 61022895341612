/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import React, { Component } from 'react'
import ShippingDetail from './ShippingDetail'

class ShippingAdd extends Component {
  componentWillUnmount() {
    this.props.shippingRepository.clearCrudData()
    this.props.shippingRepository.clearErrorMessages()
  }

  render() {
    return <ShippingDetail {...this.props} title="Add New Shipping" isAdd />
  }
}

export default decorate(inject('shippingRepository')(observer(ShippingAdd)), {})
