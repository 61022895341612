/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class SanctionServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/sanctions'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export class SanctionCustomerServices extends SanctionServices {
  getById(id) {
    return this.api.get(`${this.url}/customer/${id}`)
  }
}

export default SanctionServices
