import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ORDERS_PATH } from 'definitions'
import Loader from 'components/Common/Loader'
import OrderForm from './OrderForm'

class OrderEdit extends Component {
  constructor(props) {
    super(props)
    props.orderRepository.getById(props.match.params.orderId)
  }
  componentWillUnmount() {
    this.props.orderRepository.reset()
  }
  updateStatus = payload => {
    const {
      orderRepository: {
        CRUD: { data },
      },
    } = this.props

    this.props.orderRepository.updateStatus(data.id, payload, res => {
      this.props.history.push(`${ORDERS_PATH}/${res.id}`)
    })
  }
  render() {
    const {
      orderRepository: {
        CRUD: { data, loading },
      },
    } = this.props
    const orderNumber = data.orderNumber || data.tempOrderNumber || 'N/A'
    return loading && !data.id ? (
      <Loader />
    ) : (
      <OrderForm
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Co-Term',
            href: '/co-term',
          },
          {
            label: orderNumber,
          },
        ]}
        title="Co-Term Details"
        data={data}
        {...this.props}
        step={2}
      />
    )
  }
}

export default decorate(inject('orderRepository')(observer(OrderEdit)), {})
