import { Col, Row } from 'reactstrap'
import { decorate, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { handleCheckSelectedCustomer, StoreField } from 'components/Orders/OrderForm/CustomerPanel'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import Checkbox from 'components/Common/Form/Checkbox'
import ContentContainer from 'components/Common/ContentContainer'
import DatePicker from 'components/Common/Form/DatePicker'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import React from 'react'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import styled from 'styled-components'

class SubscriptionAdd extends React.Component {
  constructor(props) {
    super(props)
    const fields = {
      customer: {
        type: 'text',
        label: 'Customer Name',
        rules: 'required',
        value: '',
      },
      store: {
        type: 'text',
        label: 'Store',
        rules: 'required',
        value: '',
      },
      recurringPeriod: {
        type: 'text',
        label: 'Recurring Period',
        rules: 'required',
        value: '',
      },
      noOfInstallment: {
        type: 'select',
        label: 'No. of Instalments',
        rules: 'required',
        value: '5',
      },
      requireLoanOrder: {
        type: 'checkbox',
        label: 'Require Loan Order',
        value: false,
      },
      subscriptionStartDate: {
        type: 'date',
        label: 'Subscription Date',
        rules: 'required',
        value: '',
      },
    }
    this.form = new Form({ fields }, { name: 'UserForm', handleSubmit: this.handleSubmit })
  }
  handleCancel = () => {
    this.props.history.push(SUBSCRIPTIONS_PATH)
  }
  handleSubmit = () => {
    const { orderSubscriptionRepository } = this.props
    const { store, customer, ...rest } = this.form.values()
    orderSubscriptionRepository.create(
      {
        store,
        customer,
        orderSubscription: rest,
      },
      res => {
        this.props.history.push({
          pathname: `${SUBSCRIPTIONS_PATH}/${res.id}`,
          search: '?contract=true&edit=true',
        })
      }
    )
  }

  handleSelectedCustomer = async selected => {
    const {
      customerRepository,
      customerRepository: { DefaultStore },
    } = this.props

    handleCheckSelectedCustomer(selected)
    this.selectedCustomer = selected
    await customerRepository.getDefaultStore(selected.id)
    if (DefaultStore.data) {
      this.form.$('store').set(DefaultStore.data)
    } else {
      this.form.$('store').set(null)
    }
  }
  render() {
    const {
      orderSubscriptionRepository,
      customerRepository: { customerReactSelectSearch },
    } = this.props

    const noOfInstallments = Array.apply(null, Array(12)).map((i, key) => {
      return (
        <option key={key} value={key + 1}>
          {key + 1}
        </option>
      )
    })

    return (
      <ContentContainer
        title="Create New Contract"
        promptOnExit={orderSubscriptionRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <FormContainer
              title="Account Information"
              onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
              actions={
                <FormActions
                  variant="contained"
                  loadingMessage={`Saving please wait...`}
                  confirmLabel={`Save`}
                  onCancel={this.handleCancel}
                />
              }
            >
              <ReactSelect
                field={this.form.$('customer')}
                serverSide
                search={payload => customerReactSelectSearch(payload)}
                options={{
                  valueKey: 'id',
                  labelKey: 'customerLabel',
                  defaultOptions: true,
                }}
                onChange={this.handleSelectedCustomer}
              />
              <Visible toAppAdminRole>
                <div className="text-right text-link">
                  <LinkStyled to="/customers/new?redirectTo=/orders/new">Create New Customer</LinkStyled>
                </div>
              </Visible>
              {this.selectedCustomer ? (
                <StoreField {...this.props} selectedCustomer={this.selectedCustomer} form={this.form} isCreate={true} />
              ) : (
                <ReadOnlyField value={this.form.$('store').value && this.form.$('store').value.name} label="Store" />
              )}

              <Row>
                <Col>
                  <InputText
                    type="number"
                    field={this.form.$('recurringPeriod')}
                    endAdornment={
                      <span className="d-flex align-items-center">
                        <small>Months</small>
                      </span>
                    }
                  />
                </Col>
                <Col>
                  <InputText field={this.form.$('noOfInstallment')}>{noOfInstallments}</InputText>
                </Col>
              </Row>

              <DatePicker field={this.form.$('subscriptionStartDate')} />
              <Checkbox field={this.form.$('requireLoanOrder')} />
            </FormContainer>
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

const LinkStyled = styled(Link)`
  font-size: 11px;
  font-weight: bold;
`

export default decorate(
  inject(
    'customerRepository',
    'orderRepository',
    'storeRepository',
    'orderSubscriptionRepository'
  )(observer(SubscriptionAdd)),
  {
    selectedCustomer: observable,
  }
)
