import React from 'react'

import { formatCustomerCurrency } from 'helpers/Formatters'
import { ORDERS_PATH } from 'definitions'
import Status from 'components/Common/Status'

export const getOrderOptions = () => {
  const sort = { id: 'createdDate', desc: true }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${ORDERS_PATH}/${original.id}`

  return {
    title: 'Orders',
    moreUrl: ORDERS_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        {
          headerClassName: 'px-0',
          accessor: 'orderSource',
          resizable: false,
          width: 20,
          getProps: (state, { original = {} } = {}) => {
            return {
              className: `${original.orderSource === 'STORE' ? 'border-primary' : 'border-success'}`,
              style: {
                padding: 0,
                borderLeft: '5px solid',
              },
            }
          },
          Cell: () => '',
        },
        {
          Header: 'Order No.',
          accessor: 'orderNumber',
          Cell: ({ original }) => original.orderNumber || original.tempOrderNumber || 'N/A',
        },
        {
          Header: 'Customer',
          accessor: 'customer.name',
          Cell: ({ original }) => (
            <span title={original.customer.name} className="pr-1 ellipsis row-clickable">
              {original.customer.name}
            </span>
          ),
        },
        {
          Header: 'Store',
          accessor: 'store.name',
          className: 'd-flex align-items-center',
        },
        {
          Header: 'Amount',
          accessor: 'orderSummary.grandTotal',
          headerClassName: 'justify-content-end pr-3',
          className: 'justify-content-end pr-3',
          Cell: ({ original }) =>
            formatCustomerCurrency(original.orderSummary.grandTotal, original.customer, original.store.currency),
        },
        {
          Header: 'Status',
          accessor: 'status',
          className: 'd-flex align-items-center',
          width: 200,
          Cell: ({ original }) => <Status status={original.status} />,
        },
      ],
      sort,
    },
  }
}
