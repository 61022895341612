import React from 'react'

import { COTERM_PATH } from 'definitions'
import { formatCustomerCurrency } from 'helpers/Formatters'
import Status from 'components/Common/Status'

export const getCoTermOptions = () => {
  const sort = { id: 'orderDate', desc: true }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${COTERM_PATH}/${original.id}`

  return {
    title: 'Co-Term',
    moreUrl: COTERM_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        {
          accessor: 'tempOrderNumber',
          Header: 'Order No.',
          Cell: ({ original }) => original.orderNumber || original.tempOrderNumber,
        },
        {
          accessor: 'customer.name',
          Header: 'Customer',
          Cell: ({ original }) => (
            <span title={original.customer.name} className="pr-1 ellipsis row-clickable">
              {original.customer.name}
            </span>
          ),
        },
        { accessor: 'store.name', Header: 'Store' },
        {
          accessor: 'orderSummary.grandTotal',
          Header: 'Amount',
          headerClassName: 'justify-content-end pr-3',
          className: 'justify-content-end pr-3',
          Cell: ({ original: { customer = {}, orderSummary = {} } = {} }) =>
            orderSummary && formatCustomerCurrency(orderSummary.grandTotal, customer, orderSummary.currency),
        },
        {
          Header: 'Order Status',
          accessor: 'status',
          headerClassName: 'justify-content-center',
          className: 'justify-content-center p-1',
          Cell: ({ original }) => <Status status={original.status} className="row-clickable" />,
        },
      ],
      sort,
    },
  }
}
