import { inject, observer } from 'mobx-react'
import React from 'react'

import { USER_ROLE_ACCESS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import UserRoleAccessForm from './UserRoleAccessForm'

class UserRoleAccessAdd extends React.Component {
  componentWillUnmount() {
    this.props.userRoleAccessRepository.clearCrudData()
    this.props.userRoleAccessRepository.clearErrorMessages()
  }

  render() {
    return (
      <ContentContainer
        title="Add New User Role Access"
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'User Role Access',
            href: USER_ROLE_ACCESS_PATH,
          },
          {
            label: 'New',
          },
        ]}
        promptOnExit={this.props.userRoleAccessRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <UserRoleAccessForm {...this.props} isAdd={true} />
      </ContentContainer>
    )
  }
}

export default inject('userRoleAccessRepository')(observer(UserRoleAccessAdd))
