import React, { Component } from 'react'

import { Badge, CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { CUSTOMERS_PATH } from 'definitions'
import { titleize } from 'helpers/String'
import ReactTable from 'components/Common/ReactTable'

class CustomerTable extends Component {
  handleCreate = () => {
    this.props.history.push('/customers/new')
  }

  render() {
    const { searchCriteria, searchRepository, pageKey } = this.props
    const {
      columns = [
        {
          accessor: 'group',
          Header: '',
          width: 20,
          getProps: (state, { original = {} } = {}) => {
            const group = original.groups && original.groups.length > 0 ? original.groups[0] : {}
            return {
              style: {
                padding: 0,
                borderLeft: `5px solid ${group.name === 'General' ? '#70D776' : '#AB6694'}`,
              },
            }
          },
          fixed: 'leftmost',
          Cell: () => '',
          sortable: false,
        },
        { accessor: 'bpCode', Header: 'BP No.', width: 120 },
        {
          accessor: 'name',
          Header: 'Customers',
          width: '25%',
          Cell: ({ original }) => (
            <span title={original.name} className="pr-2 ellipsis row-clickable">
              {original.name}
            </span>
          ),
        },
        // { accessor: 'userLogin', Header: 'User Login', width: '10%' },
        {
          accessor: 'defaultStore',
          Header: 'Default Store',
          Cell: ({ original }) => (original.defaultStore ? original.defaultStore.name : 'N/A'),
          width: '10%',
        },
        {
          accessor: 'groups',
          Header: 'Group',
          Cell: ({ original }) =>
            original.groups
              ? original.groups.slice(0, 1).map((item, index) => (
                  <div key={index} style={{ marginBottom: '2px' }}>
                    {item.name}
                  </div>
                ))
              : 'N/A',
          width: '10%',
        },
        {
          accessor: 'restriction',
          Header: 'Restriction',
          Cell: ({ original }) =>
            original.sanctioned || original.blacklisted ? (
              <BlackListedBadgeStyled className="badge-black">
                {original.sanctioned ? 'SANCTIONED' : 'Blacklisted'}
              </BlackListedBadgeStyled>
            ) : (
              'N/A'
            ),
          width: '10%',
        },
        {
          accessor: 'status',
          Header: 'Status',
          Cell: ({ original }) => (
            <CustomInput
              type="switch"
              id={`status-${original.id}`}
              name="status"
              label={titleize(original.status)}
              checked={original.status === 'ACTIVE'}
              onChange={event => handlePatchStatus(event, original.id)}
              onClick={event => event.preventDefault()}
              disabled={!allowedToAppAdminRole()}
            />
          ),
          width: 150,
        },
      ],
      actions = null,
      selectionChanged = null,
      innerRef,
      customerRepository,
      multiSelect = false,
      fixedColumns = false,
      selectElementId = '',
      filters = {},
      handlePatchStatus,
      isCG = false,
      CGData = {},
    } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
      fetchTable,
    } = customerRepository
    return (
      <ReactTable
        searchRepository={searchRepository}
        pageKey={pageKey}
        enableRowLink
        getRowUrl={({ original }) => `${CUSTOMERS_PATH}/${original.id}`}
        multiSelect={multiSelect}
        fixedColumns={fixedColumns}
        columns={columns}
        data={isCG ? CGData.data : data}
        loadData={isCG ? CGData.loadData : params => fetchTable({ ...params, ...filters })}
        loading={isCG ? CGData.loading : loading}
        totalRecordCount={isCG ? CGData.totalRecordCount : totalRecordCount}
        ref={innerRef}
        totalPages={isCG ? CGData.totalPages : originalData.totalPages}
        selectionChanged={selectionChanged}
        search
        searchCriteria={searchCriteria}
        selectElementId={selectElementId}
        sort={{ id: 'name' }}
        actions={actions}
        className="striped cell-vertical-center"
      />
    )
  }
}

const BlackListedBadgeStyled = styled(Badge)`
  &&& {
    padding: 6px 12px;
    font-size: 12px;
  }
`

export default withRouter(decorate(inject('customerRepository', 'searchRepository')(observer(CustomerTable)), {}))
