import React from 'react'

import { Link } from 'react-router-dom'
import cx from 'classnames'
import styled from 'styled-components'

export const TrComponent = ({ isRow, rowInfo, children, className, handleRowClick, ...props }) => {
  return (
    <div className={cx('rt-tr', className)} role="row" {...props}>
      {children}
    </div>
  )
}

const LinkStyled = styled(Link)`
  &.rt-tr-link {
    color: inherit;
  }
`

export default ({ isRow, rowInfo, children, className, getRowUrl, handleRowClick, ...props }) =>
  isRow ? (
    <LinkStyled
      to={getRowUrl(rowInfo)}
      className={cx('rt-tr rt-tr-link', className)}
      {...props}
      onClick={event => {
        if (handleRowClick) {
          if (!event.ctrlKey) {
            event.preventDefault()
            handleRowClick(rowInfo)
          }
        } else if (event.target?.tagName === 'LABEL' && event.target?.htmlFor) {
          // re-direct click to the elment htmlFor is targetting
          event.preventDefault()
          document.getElementById(event.target.htmlFor)?.click()
        } else if (event.target?.classList.contains('stop-redirect')) {
          event.preventDefault()
        }
      }}
    >
      {children}
    </LinkStyled>
  ) : (
    <TrComponent {...props}>{children}</TrComponent>
  )
