export const createFields = () => {
  const validateDateFrom = ({ field, form }) => {
    if (field.value) {
      const dateTo = form.$('orderDateTo').value
      if (dateTo) {
        form.$('orderDateTo').resetValidation()
        if (field.value > dateTo) {
          return [false, 'Date should be less than Order Date To']
        }
      }
    }
    return [true]
  }

  const validateDateTo = ({ field, form }) => {
    if (field.value) {
      const dateFrom = form.$('orderDateFrom').value
      if (dateFrom) {
        form.$('orderDateFrom').resetValidation()
        if (field.value < dateFrom) {
          return [false, 'Date should be greater than Order Date From']
        }
      }
    }
    return [true]
  }

  return {
    orderNumber: {
      label: 'Order Number',
    },
    customerName: {
      label: 'Customer Name',
    },
    email: {
      label: 'Billing\\Shipping Email',
    },
    customerGroupId: {
      type: 'select',
      label: 'Customer Group',
    },
    store: {
      type: 'select',
      label: 'Store',
    },
    orderTypeId: {
      type: 'select',
      label: 'Order Type',
    },
    orderDateFrom: {
      type: 'date',
      validators: [validateDateFrom],
    },
    orderDateTo: {
      type: 'date',
      validators: [validateDateTo],
    },
    orderSource: {
      type: 'select',
      label: 'Order Source',
    },
    status: {
      type: 'select',
      label: 'Order Status',
    },
    productCode: {
      label: 'Product Code (SKU)',
    },
    productName: {
      label: 'Product Name',
    },
    orgId: {
      label: 'Organization ID',
    },
    fscId: {
      label: 'FSC ID',
    },
    serialNumber: {
      label: 'Serial Number',
    },
    dealId: {
      label: 'Deal ID',
    },
    referralCode: {
      label: 'Referral Code',
    },
    couponCode: {
      label: 'Coupon Code',
    },
    paymentMethodId: {
      type: 'select',
      label: 'Payment Method',
    },
    noShipmentInd: {
      type: 'checkbox',
      label: 'No Shipment',
    },
  }
}
