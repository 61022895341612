import React from 'react'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

import { titleize } from 'helpers/String'

const BreadcrumbsStyled = styled(Breadcrumb)`
  padding: 20px 20px 0;
  font-size: 13px;
  .breadcrumb {
    background: inherit;
    margin: 0;
    .breadcrumb-item {
      display: flex;
      align-items: center;
      &::before {
        content: '';
        padding: 0;
      }
    }
  }
`

export default withRouter(({ history, match: { path, url }, breadcrumbs, breadcrumbLabel }) => {
  let paths = breadcrumbs

  if (!paths) {
    const urlTokens = url === '/' ? [''] : url.split('/')

    paths = urlTokens.map((token, index) => ({
      label: token === '' ? 'Home' : titleize(token),
      href: token === '' ? '/' : urlTokens.slice(0, index + 1).join('/'),
    }))
  }

  return (
    <BreadcrumbsStyled tag="nav" listTag="div" className="bg-">
      {paths.map((path, index) => {
        const isLast = index + 1 === paths.length

        if (isLast) {
          return (
            <BreadcrumbItem key={`link-${index}`} active>
              {index > 0 && <i className="icon ion-ios-arrow-forward small ml-1 mr-2 font-size-14" />}
              {breadcrumbLabel || path.label}
            </BreadcrumbItem>
          )
        } else {
          return (
            <BreadcrumbItem key={`link-${index}`} tag="a" onClick={path.onClick || (() => history.push(path.href))}>
              {index > 0 && <i className="icon ion-ios-arrow-forward small ml-1 mr-2 font-size-14" />}
              {path.label}
            </BreadcrumbItem>
          )
        }
      })}
    </BreadcrumbsStyled>
  )
})
