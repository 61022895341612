/* @flow */
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import Loader from 'components/Common/Loader'
import OrderTypeForm from './OrderTypeForm'

class OrderTypeEdit extends Component {
  constructor(props) {
    super(props)

    this.loadOrderType()
  }

  loadOrderType() {
    const {
      match: {
        params: { id },
      },
      orderTypeRepository,
    } = this.props

    orderTypeRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.orderTypeRepository.clearCrudData()
    this.props.orderTypeRepository.clearErrorMessages()
  }

  render() {
    const {
      orderTypeRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? <Loader /> : <OrderTypeForm {...this.props} data={data} isAdd={false} />
  }
}

export default inject('orderTypeRepository')(observer(OrderTypeEdit))
