/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import ShippingRateServices from 'services/shippingRate'

class ShippingRateRepository extends BaseRepository {
  service: ShippingRateServices
  constructor(service: ShippingRateServices) {
    super(service)
    this.service = service
  }
  OrderRate = {
    loading: false,
    data: 0,
  }
  bulkDelete = async payload => {
    return await this.service.bulkDelete(payload)
  }
  bulkClone = async payload => {
    this.CRUD.submitting = true
    const result = await this.service.bulkClone(payload)
    this.CRUD.submitting = false
    return result
  }
  getOrderRate = async orderId => {
    this.OrderRate.loading = true
    const { data, errors } = await this.service.getOrderRate(orderId)
    this.OrderRate.loading = false
    if (data && !errors.length) {
      this.OrderRate.data = data
    }
  }
  reactSelectSearchShippingTypes = async params => {
    const { data, errors } = await this.service.reactSelectSearchShippingTypes(params)
    return { data, errors }
  }
}

export default decorate(ShippingRateRepository, {
  OrderRate: observable,
})
