import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import React from 'react'

import { FormGroup, FormText, Label } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'

import { formatDate } from 'helpers/Formatters'

const DatePickerField = ({
  field,
  disabled,
  className,
  labelClassName,
  label: propsLabel,
  dateFormat = 'DD MMM YYYY',
  options = {},
  onChange,
}) => {
  const selectedDate = React.useMemo(() => {
    const dateValue = field.value
    if (dateValue && typeof dateValue === 'string') {
      var momentDate = moment(dateValue)
      if (!momentDate.isValid()) {
        // eslint-disable-next-line no-console
        console.log(`Invalid date. field: ${field.name} value: ${dateValue}`)
        return null
      }
      return momentDate.toDate()
    }
    return dateValue
  }, [field.value])

  const { name, value, placeholder = `Enter ${field.label}` } = field
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  const label = propsLabel || field.label

  return (
    <FormGroupStyled
      key={name}
      className={className}
      nolabel={label ? undefined : 'nolabel'}
      haserror={hasError ? 'hasError' : undefined}
    >
      {label && <Label className={cx(labelClassName)}>{label}</Label>}
      <DatePicker
        selected={selectedDate}
        {...field.bind({
          name,
          value: value ? formatDate(value, dateFormat) : null,
          disabled: disabled ? disabled : field.disabled,
        })}
        className={`form-control ${hasError ? 'is-invalid' : ''}`}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        todayButton="Today"
        {...(onChange && {
          onChange: val => {
            field.onChange(val)
            field.validate()
            onChange && onChange(val)
          },
        })}
        {...options}
      />
      {hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </FormGroupStyled>
  )
}

const FormGroupStyled = styled(FormGroup)`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected {
    &:hover {
      background-color: #f5a800;
      border-radius: 50%;
    }
    background-color: ${props => props.theme.primary};
    border-radius: 50%;
  }
  .react-datepicker__day,
  .react-datepicker__month,
  .react-datepicker__quarter {
    &:hover {
      border-radius: 50%;
    }
  }
`

export default observer(DatePickerField)
