import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import Modal from '../Common/Modal'
import ReactTable from 'components/Common/ReactTable'

class AddDisableProductsModal extends Component {
  state = { selectedItems: [] }
  handleSubmit = () => {
    // this.props.handleSelected(data)
    const { groupId, customerGroupDisabledProductRepository, toggleModal, table } = this.props
    customerGroupDisabledProductRepository.create(
      {
        products: this.state.selectedItems.map(id => ({
          id: id,
        })),
      },
      res => {
        toggleModal()
        table.reload()
      },
      groupId
    )
  }
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selectedItems: Object.keys(selection),
    })
  }
  render() {
    const {
      isModalOpen,
      toggleModal,
      customerGroupDisabledProductRepository: {
        ProductTable: { data, loading, totalRecordCount, originalData },
        fetchProductTable,
      },
    } = this.props
    return (
      <Modal
        size="lg"
        title="Disable Products"
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={this.handleSubmit}
        hideConfirm={!allowedToAppAdminRole()}
      >
        <ReactTable
          multiSelect
          className="striped  cell-vertical-center"
          columns={[
            { accessor: 'productCode', Header: 'Product Code', width: 160 },
            { accessor: 'name', Header: 'Name' },
          ]}
          sort={{ id: 'name' }}
          data={data}
          loadData={fetchProductTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          selectionChanged={this.handleSelected}
          selectElementId="addDisabledProductsTable"
        />
      </Modal>
    )
  }
}

export default decorate(inject('customerGroupDisabledProductRepository')(observer(AddDisableProductsModal)), {})
