/* @flow */

import BaseRepository, { defaults } from './BaseRepository'
import UserRoleAccessServices from 'services/userRoleAccess'

class UserRoleAccessRepository extends BaseRepository {
  service: UserRoleAccessServices

  Export = defaults.CRUD

  constructor(service: UserRoleAccessServices) {
    super(service)
    this.service = service
  }
}

export default UserRoleAccessRepository
