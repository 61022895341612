/* @flow */
import React, { Component } from 'react'

import { Nav } from 'reactstrap'
import styled from 'styled-components'

import { routes } from './routes'
import { Visible } from 'helpers/Auth'
import MenuItem from './MenuItem'

class SidebarMenu extends Component {
  render() {
    return (
      <SidebarMenuStyled vertical className="border-right">
        {routes.map((route, index) => (
          <Visible to={route.roles} key={index}>
            <MenuItem {...route} />
          </Visible>
        ))}
      </SidebarMenuStyled>
    )
  }
}

const SidebarMenuStyled = styled(Nav)`
  &.nav {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    padding: 10px 0;
    background-color: #fff;
  }
`

export default SidebarMenu
