import { inject, observer } from 'mobx-react'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

import OrderChargesTable from './OrderChargesTable'

class OrderCharges extends Component {
  render() {
    return (
      <ContentContainer
        title="Payment Surcharge"
        promptOnExit={this.props.orderChargeRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <OrderChargesTable {...this.props} />
      </ContentContainer>
    )
  }
}

export default inject('orderChargeRepository')(observer(OrderCharges))
