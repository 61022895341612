import { action, decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'

class LogsRepository extends BaseRepository {
  constructor(service) {
    super(service)
    this.service = service
  }
  LogsTable = defaults.Table
  EntityLogsTable = defaults.Table
  fetchLogsById = async (id, params) => {
    this.LogsTable.loading = true
    const { data, errors } = await this.service.fetchLogsById(id, params)
    if (data && !errors.length) {
      this.LogsTable.originalData = data
      this.LogsTable.data = data.content
      this.LogsTable.totalRecordCount = data.totalElements
    }
    this.LogsTable.loading = false
  }
  fetchLogsByEntity = async params => {
    this.EntityLogsTable.loading = true
    const { data, errors } = await this.service.fetchLogsByEntity(params)
    if (data && !errors.length) {
      this.EntityLogsTable.originalData = data
      this.EntityLogsTable.data = data.content
      this.EntityLogsTable.totalRecordCount = data.totalElements
    }
    this.EntityLogsTable.loading = false
  }
  clearEntityLogsTableData = () => {
    this.Table = defaults.Table
  }
}

export default decorate(LogsRepository, {
  LogsTable: observable,
  EntityLogsTable: observable,
  clearEntityLogsTableData: action,
})
