import React, { Component } from 'react'

import { FormGroup } from 'reactstrap'
import { inject, observer } from 'mobx-react'

import { ORDERS_PATH, PUBLIC_PATH } from 'definitions'
import CommonSelect from 'components/Common/CommonSelect'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import MobxForm from 'components/Common/Form/index'
import Template from '../Template'

class RejectForm extends Component {
  form = null
  constructor(props) {
    super(props)
    const rules = 'required'
    const fields = {
      reason: {
        label: 'Reason',
        type: 'select',
        rules,
      },
      remarks: {
        type: 'textarea',
        placeholder: 'Message',
        rules,
      },
    }
    this.form = new MobxForm(
      {
        fields,
      },
      {
        name: 'RejectOrderForm',
        hooks: {
          onSuccess: form => {
            this.handleSubmit(form)
          },
        },
      }
    )
    const {
      match: { params },
      history,
    } = this.props

    const token = params.token
    if (!token) {
      history.push('/')
      return
    }
  }
  handleSubmit = form => {
    this.props.orderRepository.rejectRequestApproval(form.values(), this.props.match.params.token, res => {
      if (res.id) {
        this.props.history.push(`${PUBLIC_PATH}${ORDERS_PATH}/s/reject`)
      }
    })
  }
  render() {
    const {
      orderRepository: { CRUD },
    } = this.props
    return (
      <Template title="Reject Reason">
        <FormContainer onSubmit={() => this.handleSubmit(this.form)} errors={CRUD.errors} plain>
          <CommonSelect field={this.form.$('reason')} collectionName="rejectReason" isPublic />
          <Input field={this.form.$('remarks')} rows={5} />
          <FormGroup className="d-flex justify-content-end">
            <FormActions
              loading={CRUD.loading}
              loadingMessage={`Saving please wait...`}
              confirmLabel={`Submit`}
              confirmIcon={`save`}
              onConfirm={() => this.form.submit()}
              hideCancel={true}
            />
          </FormGroup>
        </FormContainer>
      </Template>
    )
  }
}

export default inject('orderRepository')(observer(RejectForm))
