/* @flow */
import { action, decorate, observable } from 'mobx'
import _ from 'lodash'
import axios, { CancelToken } from 'axios'
import jwt_decode from 'jwt-decode'

const axiosClient = axios.create({
  /*eslint-disable no-undef*/
  baseURL: process.env.REACT_APP_API_SERVER,
  headers: { 'Content-Type': 'application/json;charset=utf-8' },
  cancelToken: new CancelToken(cancel => {
    cancelRequestInstance = cancel
  }),
})

Object.toParams = function ObjectToParams(obj) {
  var p = []
  for (var key in obj) {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      p.push(key + '=' + encodeURIComponent(obj[key]))
    }
  }
  return p.join('&')
}

export type ApiError = {
  errorCode: number,
  message: string,
  fieldName?: string,
}

export type ApiResponse = {
  data: Object,
  errors?: Array<ApiError>,
}

export const forcedLogout = () => {
  localStorage.removeItem('STORE_SESSION')
  window.location.replace(`${window.location.origin}/login`)
}

let cancelRequestInstance = (errorMessage: string) => null

export const CANCELLED_REQUEST = `message request cancelled`

export const cancelRequest = () => {
  cancelRequestInstance(CANCELLED_REQUEST)
}

export const apiHasErrors = ({ data, errors }: { data: any, errors: Array<ApiError> }) => !_.isEmpty(errors)

export const jsonToUrlParams = obj => {
  return Object.keys(obj)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join('&')
}

class APIRepository {
  Instance = axiosClient
  API = null
  interceptorId = null
  isRenewingJWT: boolean = false
  isPublic = false
  constructor(isPublic: false) {
    this.isPublic = isPublic
  }

  responseParser(response) {
    const { data } = response
    return {
      data,
      errors: data.errorMessages || [],
    }
  }

  errorParser(e) {
    if (e.response) {
      const {
        data: { errorCode, errorMessages },
      } = e.response
      if (e.response.status === 403 /*|| e.response.status === 404*/) {
        if (e.response.data) {
          localStorage.setItem('PAGE_ERROR', JSON.stringify(e.response.data))
        }
        window.location.replace(`/404?error`)
      }

      return {
        data: {},
        errorCode,
        errors: errorMessages || [{ message: 'Your request cannot be completed as of now' }],
      }
    }

    if (e.message && e.message === CANCELLED_REQUEST) {
      return { data: {} }
    }

    return {
      data: {},
      errors: [{ message: 'Your request cannot be completed as of now' }],
    }
  }

  setHeaders(url = '') {
    if (!this.isPublic && _.has(localStorage, 'STORE_SESSION')) {
      const { token } = JSON.parse(localStorage.getItem('STORE_SESSION'))
      this.Instance.defaults.headers['Authorization'] = `Bearer ${token}`
    } else {
      const apiKey = process.env.REACT_APP_API_KEY
      this.Instance.defaults.headers['Authorization'] = `Bearer ${apiKey}`
    }

    if (!this.isPublic) {
      //refetch failed API logic
      this.Instance.interceptors.response.use(null, error => {
        if (error.config && error.response && error.response.status === 401) {
          const { clientKey, refreshToken, clientId } = JSON.parse(localStorage.getItem('STORE_SESSION'))
          this.isRenewingJWT = true
          return this.Instance.post(`/refresh-token`, {
            clientId: clientId,
            clientKey: clientKey,
            token: refreshToken,
          })
            .then(res => {
              const Bearer = `Bearer ${res.data.id_token}`
              const loginInfo = jwt_decode(res.data.id_token)
              localStorage.setItem(
                'STORE_SESSION',
                JSON.stringify({ token: res.data.id_token, ...JSON.parse(loginInfo.sub), role: loginInfo.auth })
              )
              error.config.headers['Authorization'] = Bearer
              return axios.request(error.config)
            })
            .catch(err => {
              return Promise.reject(err)
            })
        } else if (error.config && error.response && error.response.status === 406) {
          localStorage.setItem('redirectUrl', `${window.location.pathname}${window.location.search}`)
          localStorage.removeItem('STORE_SESSION')
          window.location.replace(`${window.location.origin}/login`)
        }

        return Promise.reject(error)
      })
    }
  }

  async get(url: string, payload: any = {}, options = {}) {
    this.setHeaders()
    return this.Instance.get(`${url}${Object.keys(payload).length ? `?${Object.toParams(payload)}` : ''}`, options)
      .then(this.responseParser)
      .catch(this.errorParser)
  }

  async post(url: string, payload: any = {}, options = {}) {
    this.setHeaders(url)
    return this.Instance.post(`${url}`, payload, options).then(this.responseParser).catch(this.errorParser)
  }

  async update(url: string, payload: any = {}) {
    this.setHeaders()
    return this.Instance.put(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }

  async patch(url: string, payload: any = {}) {
    this.setHeaders()
    return this.Instance.patch(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }

  async del(url: string, payload: any = {}) {
    this.setHeaders()
    return this.Instance.delete(`${url}`, payload).then(this.responseParser).catch(this.errorParser)
  }
}

export default decorate(APIRepository, {
  Instance: observable,
  API: observable,
  interceptorId: observable,
  isRenewingJWT: observable,
  responseParser: action.bound,
  errorParser: action.bound,
  setHeaders: action.bound,
  get: action,
  post: action,
  update: action,
  patch: action,
  del: action,
})
