import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ATTRIBUTE_TYPES, ATTRIBUTES_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class AttributeTable extends Component {
  table = React.createRef()

  handleCreate = () => {
    this.props.history.push(`${ATTRIBUTES_PATH}/new`)
  }

  handleDelete = selected => {
    ToasterNotify.confirm({
      message: `Are you sure you want to delete '${selected.attributeLabel}'?`,
      title: 'Warning',
      onConfirm: () => {
        this.props.productAttributeRepository.delete(selected.id, () => {
          this.table.current.reload()
        })
      },
    })
  }

  getTypeLabel = ({ original }) =>
    original.attributeType ? ATTRIBUTE_TYPES[original.attributeType] || original.attributeType : ''

  yesOrNo = ({ original }) => (original.criteriaInd ? 'Yes' : 'No')

  render() {
    const {
      productAttributeRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ReactTable
        searchRepository={searchRepository}
        pageKey="attributes"
        enableRowLink
        getRowUrl={({ original }) => `${ATTRIBUTES_PATH}/${original.id}`}
        columns={[
          {
            accessor: 'attributeLabel',
            Header: 'Label',
          },
          {
            accessor: 'attributeName',
            Header: 'Name',
            className: 'ellipsis',
          },
          { accessor: 'criteriaInd', Header: 'Pricing Rule', Cell: this.yesOrNo },
          { accessor: 'attributeType', Header: 'Type', Cell: this.getTypeLabel },
          {
            Header: 'Actions',
            headerClassName: 'justify-content-center',
            className: 'text-center p-1 justify-content-center',
            width: 150,
            Cell: ({ original }) => (
              <Visible>
                <IconButton
                  icon="ios-trash"
                  onClick={event => {
                    event.preventDefault()
                    this.handleDelete(original)
                  }}
                />
              </Visible>
            ),
          },
        ]}
        actions={
          <Visible>
            <div className="text-right">
              <IconButton onClick={this.handleCreate} color="dark" outline title="Create New Attribute" />
            </div>
          </Visible>
        }
        className="striped cell-vertical-center"
        sort={{ id: 'attributeLabel' }}
        data={data}
        loadData={fetchTable}
        loading={loading}
        totalRecordCount={totalRecordCount}
        totalPages={originalData.totalPages}
        ref={this.table}
        search
      />
    )
  }
}

export default decorate(inject('productAttributeRepository', 'searchRepository')(observer(AttributeTable)), {})
