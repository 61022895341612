import { Button } from 'reactstrap'
import React, { Component } from 'react'
import styled from 'styled-components'

import ReactSelect from 'components/Common/Form/ReactSelect'

class Actions extends Component {
  state = {
    display: null,
    selectedValue: null,
  }
  handleChangeDisplay = display => {
    this.setState({ display })
  }
  handleChangeValue = selectedValue => {
    this.setState({ selectedValue })
  }
  handleSubmit = () => {
    const { handleBulkDelete, handleBulkUpdate } = this.props
    const { display, selectedValue } = this.state
    if (display) {
      if (display === 'remove') {
        handleBulkDelete()
      } else {
        handleBulkUpdate(display, selectedValue)
      }
    }
  }
  render() {
    const { display, selectedValue } = this.state
    const { view } = this.props

    return (
      <div className="d-flex justify-content-end align-items-center">
        <ReactSelectStyled
          className="mr-2"
          plain
          options={{
            placeholder: 'Select Bulk Action',
            options: [
              { value: 'remove', label: 'Remove' },
              ...(view.key === 'country'
                ? [
                    { value: 'onlineOrder', label: 'Online Order' },
                    { value: 'storeDisplay', label: 'Store Display' },
                    { value: 'directShipment', label: 'Direct Shipment' },
                  ]
                : []),
            ],
          }}
          onChange={selected => this.handleChangeDisplay(selected && selected.value)}
        />
        <ReactSelectStyled
          className="mr-2"
          plain
          disabled={!display || display === 'remove'}
          options={{
            placeholder: 'Select Option',
            options: [
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ],
          }}
          onChange={selected => this.handleChangeValue(selected && selected.value)}
        />
        <Button
          color="dark"
          size="sm"
          onClick={this.handleSubmit}
          disabled={!display || (display !== 'remove' && selectedValue === null)}
        >
          Submit
        </Button>
      </div>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;

      &.category-select {
        &:active {
          box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.25);
        }
      }
    }
  }
`

export default Actions
