const rules = 'required'
export const fields = (data = {}, customer) => {
  const {
    company = customer ? customer.name : '',
    city = '',
    country = null,
    state = '',
    streetAddress1 = '',
    streetAddress2 = '',
    zipCode = '',
    billing = false,
    shipping = false,
    billingOnly = false,
    shippingOnly = false,
  } = data
  return {
    company: {
      type: 'text',
      label: 'Company',
      rules: `${rules}`,
      value: company,
    },
    city: {
      type: 'text',
      label: 'City',
      rules,
      value: city,
    },
    country: {
      type: 'text',
      label: 'Country',
      rules,
      value: country,
    },
    state: {
      type: 'text',
      label: 'State',
      value: state,
    },
    streetAddress1: {
      type: 'text',
      label: 'Address Line 1',
      rules: `${rules}|max:35`,
      value: streetAddress1,
    },
    streetAddress2: {
      type: 'text',
      label: 'Address Line 2',
      rules: `max:35`,
      value: streetAddress2,
    },
    zipCode: {
      type: 'text',
      label: 'ZIP Code',
      rules: 'required',
      value: zipCode,
    },
    billing: {
      type: 'checkbox',
      label: 'Default Billing Address',
      ...(billing && {
        checked: billing.toString(),
        value: billing,
      }),
    },
    presetBilling: {
      type: 'checkbox',
      label: 'Preset Billing Address',
      ...(billing && {
        checked: billing.toString(),
        value: billing,
      }),
    },
    shipping: {
      type: 'checkbox',
      label: 'Default Shipping Address',
      ...(shipping && {
        checked: shipping.toString(),
        value: shipping,
      }),
    },
    billingOnly: {
      type: 'checkbox',
      label: 'Billing',
      ...(billingOnly && {
        checked: billingOnly.toString(),
        value: billingOnly,
      }),
    },
    shippingOnly: {
      type: 'checkbox',
      label: 'Shipping',
      ...(shippingOnly && {
        checked: shippingOnly.toString(),
        value: shippingOnly,
      }),
    },
  }
}
