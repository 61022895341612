import cx from 'classnames'
import React, { Component } from 'react'

import { Button } from 'reactstrap'
import styled from 'styled-components'

class IconButton extends Component {
  render() {
    const {
      title,
      onClick,
      color = 'transparent',
      size,
      className = '',
      icon = 'md-add',
      iconSize = '',
      disabled = false,
      outline = false,
      ...rest
    } = this.props
    return (
      <IconButtonStyled
        type="button"
        color={color}
        outline={outline}
        size={size}
        aria-label={title}
        className={cx(className, iconSize)}
        // className={`${className} ${iconSize}`}
        onClick={onClick}
        disabled={disabled}
        data-toggle="tooltip"
        data-placement="top"
        title={title}
        {...rest}
      >
        <i className={`icon ion-${icon} ${iconSize}`}></i>
      </IconButtonStyled>
    )
  }
}

const IconButtonStyled = styled(Button)`
  &.btn {
    line-height: 0;
    border-radius: 50%;
    padding: 0;
    height: 32px;
    width: 32px;
    &.tiny {
      height: 22px;
      width: 22px;
    }
    &.small {
      height: 28px;
      width: 28px;
    }
    &.medium {
      height: 36px;
      width: 36px;
    }
    &.large {
      height: 42px;
      width: 42px;
    }
    &.xtra-large {
      height: 50px;
      width: 50px;
    }

    &.btn-transparent:hover {
      background-color: #e7e7e7;
    }
  }
`

export default IconButton
