/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class DeviceManagementJobServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/deviceManagements/jobs'
    super(api, url)
    this.api = api
    this.url = url
  }

  retry = id => {
    return this.api.post(`${this.url}/${id}/retry/`)
  }

  retryByType = type => {
    return this.api.post(`${this.url}/type/${type}/retry/`)
  }

  checkJobStatus = () => {
    return this.api.get(`${this.url}/failed`)
  }
}

export default DeviceManagementJobServices
