/* @flow */

import { action, decorate, observable } from 'mobx'
import _ from 'lodash'
import jwt_decode from 'jwt-decode'

import { CHECK_JOB_STATUS } from 'definitions'

class LoginRepository {
  constructor(user: UserRepository, service: LoginServices) {
    this.user = user
    this.service = service
  }

  apiErrors: Array = []
  isNormalLoginLoading: boolean = false
  isGoogleLoginLoading: boolean = false
  isGettingGoogleDetails: boolean = false
  googleDetails: object = {}

  isAuthenticated = () => {
    if (_.has(localStorage, 'STORE_SESSION')) {
      const { token = null, id = null, role = null } = JSON.parse(localStorage.getItem('STORE_SESSION'))
      return !!id && !!token && !!role
    } else return false
  }

  getCurrentRole = () => {
    if (_.has(localStorage, 'STORE_SESSION')) {
      const { role = null } = JSON.parse(localStorage.getItem('STORE_SESSION'))
      return role
    } else return null
  }

  redirect = () => {
    const redirectUrl = localStorage.getItem('redirectUrl')
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl')
      window.location.replace(`${window.location.origin}${redirectUrl}`)
    } else {
      window.location.replace(`${window.location.origin}/`)
    }
  }

  async googleLogin(params) {
    this.isGoogleLoginLoading = true
    localStorage.removeItem('GOOGLE_ERROR')
    const { data, errors } = await this.service.googleLogin(params)
    if (!errors.length) {
      const loginInfo = jwt_decode(data.id_token)
      localStorage.setItem(
        'STORE_SESSION',
        JSON.stringify({ token: data.id_token, ...JSON.parse(loginInfo.sub), role: loginInfo.auth })
      )
      localStorage.removeItem('GOOGLE_AUTH')
      sessionStorage.setItem(CHECK_JOB_STATUS, true)
      this.redirect()
    } else {
      this.isGoogleLoginLoading = false
      this.apiErrors = errors

      localStorage.setItem(
        'GOOGLE_ERROR',
        JSON.stringify(
          errors.map(error => {
            const tokens = error.message.split(':')
            if (tokens.length > 1) {
              return { message: tokens[1].trim() }
            } else {
              return { message: error.message }
            }
          })
        )
      )

      window.location.replace(`${window.location.origin}/login?err=google`)
    }
  }

  async getGoogleDetails(token) {
    this.isGettingGoogleDetails = true
    let res = await this.service.getGoogleDetailsByToken(token)
    localStorage.setItem('GOOGLE_AUTH', JSON.stringify(res))
    this.isGettingGoogleDetails = false
  }

  logout = async callback => {
    const { errors } = await this.service.logout()
    if (!errors.length) {
      callback()
      localStorage.removeItem('STORE_SESSION')
      window.location.replace(`${window.location.origin}/login`)
    }
  }
}

export default decorate(LoginRepository, {
  apiErrors: observable,
  isNormalLoginLoading: observable,
  isGoogleLoginLoading: observable,
  isGettingGoogleDetails: observable,
  googleDetails: observable,
  isAuthenticated: observable,
  getCurrentRole: observable,
  login: action.bound,
  googleLogin: action.bound,
  getGoogleDetails: action.bound,
})
