import { inject, observer } from 'mobx-react'
import { last } from 'lodash'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import React from 'react'
import styled from 'styled-components'

import ActivityLogSidePanel from './ActivityLogSidePanel'
import CommentSidePanel from './CommentSidePanel'
import Zones from './Zones'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class ShippingZones extends React.Component {
  constructor(props) {
    super(props)
    const {
      zoneRepository: { getCommon, fetchTable },
    } = this.props
    getCommon('availableCountries', 'countries/available', {})
    fetchTable()
  }
  updateData = res => {
    const {
      zoneRepository: {
        Table: { originalData },
        getCommon,
      },
    } = this.props
    const zone = originalData.find(i => i.id === res.data.id)
    zone.countries = res.data.countries
    getCommon('availableCountries', 'countries/available', {})
  }
  handleAdd = (zoneId, payload, callback) => {
    const {
      zoneRepository: { addCountry },
    } = this.props

    addCountry(zoneId, payload).then(res => {
      this.updateData(res)
      callback && callback()
    })
  }
  handleDelete = (zoneId, countryId) => {
    const {
      zoneRepository: { deleteCountry },
    } = this.props
    deleteCountry(zoneId, countryId).then(res => {
      this.updateData(res)
    })
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  render() {
    const {
      zoneRepository: {
        Table: { originalData },
        Common: {
          availableCountries: { data: availableCountries },
        },
      },
    } = this.props
    return (
      <React.Fragment>
        <ContentContainer
          title="Shipping Zones"
          promptOnExit={this.props.zoneRepository.CRUD.submitting}
          promptOnExitIsConfirm={false}
        >
          <GridStyled>
            {!!originalData.length &&
              originalData.map((item, key) => (
                <Zones
                  onAdd={this.handleAdd}
                  onDelete={this.handleDelete}
                  key={key}
                  title={last(item.zone.label.split(' '))}
                  availableCountries={availableCountries}
                  data={item}
                />
              ))}
          </GridStyled>
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: <ActivityLogSidePanel zones={originalData} closeDrawer={this.handleCloseDrawer} />,
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: <CommentSidePanel zones={originalData} closeDrawer={this.handleCloseDrawer} />,
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default inject('zoneRepository')(observer(ShippingZones))

const GridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  .card {
    margin: 0;
    .card-title {
      color: ${props => props.theme.primary};
    }
  }
`
