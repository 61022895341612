import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Badge */
    .badge {
      &-light-dark {
        background-color: ${props => props.theme.lightDark};
      }
      &-black, &-white {
        padding:3px 15px;
        font-size:11px;
      }
      &-black {
        background-color: #000;
        color: #FFF;
      }
      &-white {
        background-color: #FFF;
        color: ${props => props.theme.dark};
        border: 1px solid ${props => props.theme.dark};
      }
    }
    .table-badge {
      .badge {
      font-size: 11px;
      padding: 0.35em 0.4em;
      }
    }
    .badge-display {
      padding: 0.45em 0.4em 0.35em 0.4em;
    }
    .badge-title {
      padding: 0.375rem 1.5rem;
      color: inherit;
      background-color: ${props => props.theme.lightDark};
    }


    /* Button */
    .btn{
      transition: color .3s ease-in;
    }
    .btn-primary {
      color: #ffffff;
      &:hover {
        color: #ffffff;
      }
      &:focus {
        color: #ffffff;
      }
      &.selected {
        background-color: #f5a800;
        border-color: #e89f00;
        box-shadow: 0 0 0 0.2rem rgb(222 162 30 / 50%);
      }
    }
    .btn-success {
      &.selected {
        background-color: #218838;
        border-color: #1e7e34;
        box-shadow: 0 0 0 0.2rem rgb(72 180 97 / 50%);
      }
    }
    .btn-info {
      &.selected {
        background-color: #138496;
        border-color: #117a8b;
        box-shadow: 0 0 0 0.2rem rgb(58 176 195 / 50%);
      }
    }
    .btn-outline-white,
    .btn-black {
      &.selected {
        box-shadow: 0 0 0 0.2rem rgb(149 149 149 / 50%);
      }
    }
    .btn-dark {
      &.selected {
        background-color: #6f6f6f;
        border-color: dimgray;
        box-shadow: 0 0 0 0.2rem rgb(149 149 149 / 50%);
      }
    }
    .btn-danger {
      &.selected {
        background-color: #dd001b;
        border-color: #dd4848;
        box-shadow: 0 0 0 0.2rem rgb(233 136 136 / 50%);
      }
    }
    .btn-turquoise {
      &.selected {
        background-color: #009e84;
        border-color: #009179;
        box-shadow: 0 0 0 0.2rem rgb(38 205 178 / 50%);
      }
    }
    .btn-cyan {
      &.selected {
        background-color: #00abb3;
        border-color: #009ea6;
        box-shadow: 0 0 0 0.2rem rgb(38 214 223 / 50%);
      }
    }
    .btn-light-dark {
      background-color: ${props => props.theme.lightDark};
      &:hover {
        background-color: ${props => props.theme.dark};
      }
    }
    .btn-xs {
      font-size: 0.75rem;
      line-height: normal;
      padding: 2px 10px;
    }
    .btn-rounded {
      border-radius: 20px;
    }
    .modal-header .close {
      padding: 0.5rem 0.75rem;
    }
    .btn-outline-white,
    .btn-outline-white:hover {
      color: ${props => props.theme.dark};
      border-color: ${props => props.theme.dark};
    }

    /* Links */
    a {
      cursor: pointer;
      text-decoration: none !important;
    }
    .text-link a {
      font-size: 11px;
      font-weight: bold;
      margin-top: 5px;
      display: inline-block;
      position: relative;
    }
    .text-link a:hover {
      color: #ffb81c;
    }
    .text-link a:hover:after {
      cursor: pointer;
      width: 100%;
      left: 0;
      background: #ffb81c;
    }
    .text-link a:after {
      content: '';
      position: absolute;
      width: 0;
      bottom: -1px;
      height: 1px;
      margin-top: 0px;
      right: 0;
      background: #ffb81c;
      transition: width 0.3s ease;
      -webkit-transition: width 0.3s ease;
    }

    /* Order Panel */
    .customer-panel {
      .card-body {
        padding-top: 0.6rem !important;
      }
      .form-group {
        label {
        color: #ffb81c;
        margin-bottom: 0px;
        font-size: 12px;
        }
        .form-control {
          font-size: 20px;
          font-weight: bold;
          height: calc(1.2em + 0.75rem + 3px);
          padding: 0.3rem 0rem;
        }
      }
      .react-select__control {
        font-size: 20px;
        font-weight: bold;
      }
      .d-grid{
        display: grid;
        grid-column-gap: 1rem;
        grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
        .form-group {
          label,
          .form-control{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .price-rules {
      padding: 6px 15px 18px 15px;
      border-radius: 0px;
      .form-group {
        label {
        font-size: 10px;
        }
      }
      .react-select__control {
        background: transparent;
      }
      .react-select__placeholder {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .cell-sn {
      padding-left: 8px;
    }
    .cell-qty {
      .form-group {
        padding: 0;
        margin-top: 4px;
      }
    }

    /* Sidebar */
    .sidebar {
      .tab-content.p-3 {
      padding-top: 0 !important;
      }
      .mx-2 {
        margin-left: 0 !important;
      }
    }
    .alert-activity {
      background: #ececec;
      margin-top: 0.5em !important;
    }

    /* Form */
    .form-group {
      margin: 3px 0;
      label {
        color: #797979;
        font-size: 11px;
        font-weight: 700;
      }
      .form-control {
        border: none;
        border-bottom: 1px solid ${props => props.theme.inputBorder};
        border-radius: 0px;
        font-size: 14px;
        font-weight: 400;
        color: #333;
      }
      .text-danger {
        font-size:10px;
      }
    }
    .grouped {
      display: flex;
      .form-group {
        flex-grow: 1;
        flex-basis: 0;
        &:focus-within {
          z-index: 2;
        }
      }
    }
    input[type='number'] {
      padding-right: 0.25rem;
      text-align: right;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin-left: 5px;
      }
    }

    /* Custom switch, checkbox and radio */
    .custom-control {
      cursor: pointer;
      input:disabled[readonly] {
        & + .custom-control-label {
          &::before {
            color: inherit;
            background-color: #fff;
          }
          &::after {
            color: inherit;
          }
        }
        &:checked + .custom-control-label {
          &::before {
            background-color: ${props => props.theme.primary};
          }
        }
      }
      .custom-control-label {
        cursor: pointer;
        font-size: 0.75rem;
        font-weight: normal;
        padding-top: 4px;
      }
      &.no-label {
        padding-left: 0;
        .custom-control-label {
          &::before,
          &::after {
            left: 0;
          }
        }
      }
    }
    .react-select__control {
      cursor: pointer;
    }
    .react-select__multi-value {
        border-radius: 50px;
        padding: 2px 5px;
      }
      .react-select__multi-value__label {
        color: #666666;
        font-weight: bold;
        font-size: 12px;
      }
      .react-select__multi-value__remove:hover {
        border-radius: 50px;
        background-color: #efefef;
      }

    /* Typography */
    p {
      font-size: 11px;
    }
    .badge {
      &.badge-light {
        color: rgb(51, 51, 51);
        background: #ececec;
        font-size: 11px;
      }
    }
    .modal-title {
      text-transform: uppercase;
      font-size: 14px;
    }
    .font-size-11 {
      font-size: 11px;
    }
    .font-size-12 {
      font-size: 12px;
    }
    .font-size-14 {
      font-size: 14px !important;
    }
    .font-size-16 {
      font-size: 16px;
    }

    /* Utils */
    .w-60 {
      width: 60%;
    }
    .w-40 {
      width: 40%;
    }
    .height-100 {
      height: 100%;
    }
    .cursor {
      &-pointer {
        cursor: pointer;
      }
    }
    .bg-black {
      background-color: ${props => props.theme.black};
    }
    .border {
      border: 1px solid ${props => props.theme.inputBorder} !important;
    }
    .border-bottom {
      border-bottom: 1px solid ${props => props.theme.inputBorder} !important;
    }
    .d-flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ellipsis {
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
      overflow: hidden !important;
    }

    /* Icons */
    .icon {
      font-size: 22px;
      &.tiny {
        font-size: 12px;
      }
      &.small {
        font-size: 18px;
      }
      &.large {
        font-size: 32px;
      }
      &.xtra-large {
        font-size: 40px;
      }
    }
    .void-payment-modal {
      .modal-footer  {
        button{
          display:none !important;
        }
      }
    }
  }
  .text-center {
    text-align:center !important;
  }
  .alert {
    overflow-wrap: break-word !important;
  }
  .modal-body {
    padding: 0rem 1rem !important;
  }

.table-select {
  .react-select {
    &__control {
      min-width:180px;
      border: none;
    }
  }
}

.modal-wrapper {
  .modal-class {
    top:50px;
  }
}

input {
  &[type='number'],
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
}

.Toaster__manager-top-right {
  top: 50px !important;
}
`
