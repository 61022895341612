import cx from 'classnames'
import React, { Component } from 'react'

export default (TableComponent, options) => {
  return class FixedColumnTable extends Component {
    getOffset = (allLeftColumns, columnIndex) => {
      let offset = 0
      for (let index = 0; index < columnIndex; index += 1) {
        const column = allLeftColumns[index]
        if (column.show !== false) {
          const width = column.width || column.minWidth
          offset += width
        }
      }

      return offset
    }
    getWrappedInstance = () => {
      if (!this.wrappedInstance) console.warn('RTSelectTable - No wrapped instance')
      if (this.wrappedInstance.getWrappedInstance) return this.wrappedInstance.getWrappedInstance()
      else return this.wrappedInstance
    }
    render() {
      const { columns = [], multiSelect } = this.props

      let leftMostColumns = columns.filter(item => item.fixed === 'leftmost')
      const leftColumns = columns.filter(item => item.fixed === 'left')
      const regularColumns = columns.filter(item => !item.fixed)
      const rightColumns = columns.filter(item => item.fixed === 'right')

      if (multiSelect) {
        const selectColumn = regularColumns[0]
        leftMostColumns = [...leftMostColumns, selectColumn]
        regularColumns.shift()
      }

      const allLeftColumns = [...leftMostColumns, ...leftColumns]
      allLeftColumns.forEach((column, index) => {
        const leftOffset = this.getOffset(allLeftColumns, index)

        column.headerClassName = cx(column.headerClassName, 'fixed-column')
        column.className = cx(column.className, 'fixed-column')
        column.style = { ...column.style, left: leftOffset }
        column.headerStyle = { ...column.style, left: leftOffset }
      })

      const allRightColumns = rightColumns.slice().reverse()
      allRightColumns.forEach((column, index) => {
        const offset = this.getOffset(allRightColumns, index)
        column.headerClassName = cx(column.headerClassName, 'fixed-column')
        column.className = cx(column.className, 'fixed-column')
        column.style = { ...column.style, right: offset }
        column.headerStyle = { ...column.style, right: offset }
      })

      return (
        <TableComponent
          {...this.props}
          columns={[...leftMostColumns, ...leftColumns, ...regularColumns, ...rightColumns]}
          ref={ref => (this.wrappedInstance = ref)}
        />
      )
    }
  }
}
