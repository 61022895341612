/* @flow */
import { SettingsCommentService } from 'services/settings'
import BaseRepository from './BaseRepository'

export class SettingsCommentRepository extends BaseRepository {
  service: SettingsCommentService
  constructor(service: SettingsCommentService) {
    super(service)
    this.service = service
  }
}
