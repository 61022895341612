/* @flow */
import React, { Component } from 'react'

import { Col } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { formatNumber } from 'helpers/Formatters'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

import Actions from './Actions'
import ShippingRateModal from '../ShippingRateModal'
import TitleActions from './TitleActions'

class ShippingRate extends Component {
  state = {
    modal: {
      data: {},
      show: false,
      isAdd: false,
      shippingRateLabel: 'null',
    },
  }
  selection = []
  shippingRateType
  componentWillUnmount() {
    this.props.shippingRateRepository.clearTableData()
  }
  closeModal = () => {
    this.setState({ modal: { show: false } })
  }
  handleCreate = () => {
    const { shippingRateRepository, shippingDetailId } = this.props

    shippingRateRepository.clearErrorMessages()
    shippingRateRepository.clearCrudData()
    this.setState({
      modal: {
        data: {
          shippingDetailId,
          shippingMethod: this.shippingRateType,
        },
        show: true,
        isAdd: true,
      },
    })
  }
  handleView = selected => {
    this.setState({
      modal: {
        data: { id: selected.id },
        show: true,
        isAdd: false,
      },
    })
  }
  handleDelete = item => {
    const { shippingRateRepository, shippingDetailId } = this.props
    item &&
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete this shipping rate?',
        title: 'Warning',
        onConfirm: () => {
          shippingRateRepository.delete({ id: item.id, shippingDetailId: shippingDetailId }, () => this.table.reload())
        },
      })
  }
  handleBulkDelete = () => {
    if (this.selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to delete '${this.selection.length}' shipping rates?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = { shippingRates: this.selection.map(item => ({ id: item })) }
          await this.props.shippingRateRepository.bulkDelete(payload)
          this.table.reload()
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select shipping rates to delete', color: 'info' })
    }
  }
  handleBulkClone = () => {
    if (this.selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to clone '${this.selection.length}' shipping rates?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = { shippingRates: this.selection.map(item => ({ id: item })) }
          await this.props.shippingRateRepository.bulkClone(payload)
          this.table.reload()
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select shipping rates to clone', color: 'info' })
    }
  }
  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => key) : []
  }
  reloadTable = () => {
    this.table.reload()
    this.closeModal()
  }
  handleChangeTypeOfRate = type => {
    this.shippingRateType = type
    this.table && this.table.reload()
  }
  render() {
    const {
      shippingRateRepository: {
        Table: { data, loading, totalRecordCount, originalData },
        fetchTable,
      },
      shippingDetailId,
      promptOnExit,
    } = this.props
    const { modal } = this.state
    const shippingRates = toJS(data)

    return (
      <Col lg={8} className="position-relative">
        <TitleActions handleChangeTypeOfRate={this.handleChangeTypeOfRate} />
        <Card
          title="Shipping Rates"
          titleActions={
            <Visible toOrderProcessRole>
              <IconButton onClick={this.handleCreate} color="light-dark" title="Add New Shipping Rate" />
            </Visible>
          }
        >
          <ReactTable
            multiSelect={allowedToOrderRole()}
            className="striped cell-vertical-center"
            columns={[
              {
                accessor: 'shippingType',
                Header: 'Shipping Type',
              },
              {
                accessor: 'quantityLabel',
                Header: 'QTY',
              },
              {
                accessor: 'currencies',
                Header: 'Currency',
                headerClassName: 'justify-content-end',
                className: 'flex-column align-items-end',
                Cell: ({ original }) =>
                  original.currencies &&
                  original.currencies.map((item, index) => (
                    <div key={index} className="mb-1">
                      {item}
                    </div>
                  )),
                sortable: false,
              },
              //Zone columns
              ...(shippingRates && shippingRates.length > 0
                ? Object.keys(shippingRates[0])
                    .filter(
                      key =>
                        ![
                          'id',
                          'currencies',
                          'quantityFrom',
                          'quantityTo',
                          'quantityLabel',
                          'shippingType',
                          'shippingMethod',
                        ].includes(key)
                    )
                    .map(key => ({
                      accessor: key,
                      Header: key,
                      headerClassName: 'justify-content-end',
                      className: 'flex-column align-items-end',
                      Cell: ({ original }) =>
                        original[key] &&
                        original[key].map((item, index) => (
                          <div key={index} className="mb-1">
                            {formatNumber(item || 0)}
                          </div>
                        )),
                    }))
                : []),
              {
                Header: 'Actions',
                headerClassName: 'justify-content-center',
                className: 'justify-content-center p-1',
                width: 100,
                Cell: ({ original }) => (
                  <Visible toOrderProcessRole>
                    <IconButton icon="ios-trash" onClick={() => this.handleDelete(original)} />
                  </Visible>
                ),
                sortable: false,
              },
            ]}
            actions={
              <Visible toOrderProcessRole>
                <Actions handleBulkDelete={this.handleBulkDelete} handleBulkClone={this.handleBulkClone} />
              </Visible>
            }
            sort={{ id: 'shippingType' }}
            data={shippingRates}
            loadData={params => {
              shippingDetailId && fetchTable({ ...params, shippingRateType: this.shippingRateType }, shippingDetailId)
            }}
            loading={loading}
            totalRecordCount={totalRecordCount}
            totalPages={originalData.totalPages}
            search
            ref={ref => (this.table = ref)}
            selectionChanged={this.handleSelectionChanged}
            handleRowClick={({ original }) => this.handleView(original)}
          />
        </Card>
        {modal.show && (
          <ShippingRateModal
            data={modal.data}
            isShow={modal.show}
            isAdd={modal.isAdd}
            closeModal={this.closeModal}
            reloadTable={this.reloadTable}
            promptOnExit={promptOnExit}
          />
        )}
      </Col>
    )
  }
}

export default decorate(inject('shippingRateRepository')(observer(ShippingRate)), {})
