/* @flow */
import React, { Component } from 'react'

import { Badge } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import Drawer from 'components/Common/Drawer'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import StoreForm from './StoreForm'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class StoreEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      storeView: 'product',
    }
  }
  componentDidMount() {
    const {
      storeRepository: { getById },
      match: {
        params: { id },
      },
    } = this.props

    getById(id)
  }

  componentWillUnmount() {
    this.props.storeRepository.clearCrudData()
  }

  handleChangeStoreView = storeView => {
    this.setState({
      storeView,
    })
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const { storeView } = this.state
    const {
      storeRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer
          actions={
            <>
              <Badge color="dark" className="text-uppercase p-2 mr-2">
                {(!storeView || storeView === 'product') && 'Store Products'}
                {storeView === 'country' && 'Country Mapping'}
                {storeView === 'storefront' && 'Storefront Slides'}
              </Badge>
              <ContextMenu
                disabled={loading}
                dropDownToggle={<IconButton icon="md-apps" />}
                menus={[
                  {
                    text: 'Store Products',
                    onClick: () => {
                      this.handleChangeStoreView('product')
                    },
                  },
                  {
                    text: 'Country Mapping',
                    onClick: () => {
                      this.handleChangeStoreView('country')
                    },
                  },
                  {
                    text: 'Storefront Slides',
                    onClick: () => {
                      this.handleChangeStoreView('storefront')
                    },
                  },
                ]}
                plain
              />
            </>
          }
          title={`Edit Store`}
          breadcrumbLabel={data ? data.name : ''}
          promptOnExit={
            this.props.storeProductRepository.CRUD.submitting || this.props.storeCountryRepository.CRUD.submitting
          }
          promptOnExitIsConfirm={false}
        >
          <StoreForm {...this.props} data={data} storeView={storeView} />
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: <ActivityLogSidePanel entity="Store" entityId={data.id} closeDrawer={this.handleCloseDrawer} />,
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel referenceId={data.id} commentSource="store" closeDrawer={this.handleCloseDrawer} />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(
  inject('storeRepository', 'storeProductRepository', 'storeCountryRepository')(observer(StoreEdit)),
  {}
)
