import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { Elements, StripeProvider } from 'react-stripe-elements'
import { inject, observer } from 'mobx-react'
import Jasypt from 'jasypt'

import { formatNumber, getFormattedDateString } from 'helpers/Formatters'
import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import ReactTable from 'components/Common/ReactTable/'
import StripeForm from './StripeForm'
import stripeLogo from '../../../images/stripe.png'
import Template from '../Template'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.initStripeScript()
    this.state = {
      stripe: null,
    }
    const {
      match: { params },
      history,
    } = this.props

    const { token } = params
    if (!token) {
      history.push('/')
      return
    } else {
      this.getPaymentDetails(token)
    }
  }
  componentDidUpdate() {
    const {
      paymentRepository: {
        CRUD: { data },
      },
    } = this.props
    if (data.id && !this.state.stripe) {
      const jasypt = new Jasypt()
      jasypt.setPassword(process.env.REACT_APP_JASYPT_PASSWORD)
      const stripePublishableKey = jasypt.decrypt(data.paymentAccount.clientId)
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe(stripePublishableKey) })
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          // Create Stripe instance once Stripe.js loads
          this.setState({ stripe: window.Stripe(stripePublishableKey) })
        })
      }
    }
  }
  initStripeScript = () => {
    ;(function () {
      var s = document.createElement('script')
      s.type = 'text/javascript'
      s.id = 'stripe-js'
      s.src = 'https://js.stripe.com/v3/'
      s.async = true

      let x = document.getElementsByTagName('script')[0]
      x.parentNode.insertBefore(s, x)
    })()
  }
  getPaymentDetails = token => {
    this.props.paymentRepository.getPaymentDetails(token)
  }
  renderLoading = () => {
    return 'Loading...'
  }
  render() {
    const {
      paymentRepository: {
        CRUD: { data, loading, errors },
      },
    } = this.props
    return (
      <Template title={loading ? '' : !errors.length && 'Pay For Your Order'}>
        {loading ? (
          this.renderLoading()
        ) : errors.length ? (
          <ErrorsList errors={errors} />
        ) : (
          <>
            {loading ? (
              this.renderLoading()
            ) : (
              <>
                <p>Billed to customer for {data.paymentTo}</p>
                <h6>
                  {!!data &&
                    `${data.amountCurrency} ${formatNumber(data.amount)} due on ${getFormattedDateString(
                      data.dueDate
                    )}`}
                </h6>
              </>
            )}

            <StripeProvider stripe={this.state.stripe}>
              <Elements>
                <StripeForm {...this.props} />
              </Elements>
            </StripeProvider>
            <Card className="invoice-panel" style={{ margin: '20px 0' }}>
              <h6 className="text-uppercase" style={{ fontSize: 12 }}>
                Invoice #{data.invoiceNumber}
              </h6>
              <ReactTable
                className="striped cell-vertical-center"
                columns={[
                  {
                    accessor: 'orderNumber',
                    Header: 'Order ID',
                    sortable: false,
                    width: 150,
                  },
                  {
                    accessor: 'description',
                    Header: 'Description',
                    sortable: false,
                    minWidth: 250,
                    className: 'white-space-normal',
                  },
                  {
                    accessor: 'quantity',
                    Header: 'QTY',
                    sortable: false,
                    width: 100,
                    className: 'justify-content-center',
                    headerClassName: 'justify-content-center',
                    Cell: ({ original }) => {
                      return original.quantity
                    },
                  },
                  {
                    accessor: 'amount',
                    Header: 'Amount',
                    sortable: false,
                    width: 150,
                    className: 'justify-content-end',
                    headerClassName: 'text-right',
                    Cell: ({ original }) => {
                      return formatNumber(original.amount)
                    },
                  },
                ]}
                data={[
                  {
                    orderNumber: data.orderId,
                    description: data.description,
                    quantity: data.quantity,
                    amount: data.amount,
                  },
                ]}
                loading={loading}
                totalRecordCount={0}
                showRowPerPage={false}
              />
              <Row style={{ fontSize: 11 }}>
                <Col lg={8}>&nbsp;</Col>
                <Col lg={4} sm={12}>
                  <Row style={{ marginBottom: 10 }}>
                    <Col lg={8} md={8} sm={8} xs={7} className="text-right">
                      Subtotal
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={5}
                      className="text-right"
                      style={{
                        padding: '0 21px',
                      }}
                    >
                      {loading ? this.renderLoading() : formatNumber(data.amount)}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8} md={8} sm={8} xs={7} className="text-right font-weight-bold">
                      Amount due
                    </Col>
                    <Col
                      lg={4}
                      md={4}
                      sm={4}
                      xs={5}
                      className="text-right font-weight-bold"
                      style={{
                        padding: '0 21px',
                      }}
                    >
                      {loading ? (
                        this.renderLoading()
                      ) : (
                        <>
                          {data.amountCurrency} {formatNumber(data.amount)}{' '}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
            <Row>
              <Col lg={12} className="d-flex align-items-center">
                <p style={{ margin: 0 }}>
                  {/*eslint-disable jsx-a11y/anchor-is-valid */}
                  If you have any questions, please contact Peplink at{' '}
                  <a href="mailto:orders@peplink.com">orders@peplink.com</a>
                </p>
              </Col>
              <Col lg={12} className="d-flex align-items-center">
                <img src={stripeLogo} style={{ height: 23, width: 106, marginTop: 50 }} alt="Powered by Stripe" />
              </Col>
            </Row>
          </>
        )}
      </Template>
    )
  }
}

export default inject('paymentRepository')(observer(Payment))
