/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class GlobalSearchServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    super(api)
    this.api = api
  }

  searchOrders(params) {
    return this.api.get('orders', params)
  }
  searchQuotations(params) {
    return this.api.get('orders/quotation', params)
  }
  searchSubscriptions(params) {
    return this.api.get('orderSubscriptions', params)
  }
  searchCoTerm(params) {
    return this.api.get('co-term', params)
  }
  searchPayments(params) {
    return this.api.get('payments', params)
  }
  searchCustomers(params) {
    return this.api.get('customers', params)
  }
  searchProducts(params) {
    return this.api.get('products', params)
  }
}

export default GlobalSearchServices
