/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class DeviceManagementServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/deviceManagements'
    super(api, url)
    this.api = api
    this.url = url
  }

  bulkPost(payload, headers = {}) {
    return this.api.post(`${this.url}/export`, payload, headers)
  }

  reactSelectSearch = (id, payload) => {
    return this.api.get(`${this.url}/${id}/subscriptions`, payload)
  }

  patchSubscriptionProduct = (id, payload) => {
    return this.api.patch(`${this.url}/${id}`, payload)
  }
}

export default DeviceManagementServices
