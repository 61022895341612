/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CoTermServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/co-term'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export class CoTermProductServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/co-term/products'
    super(api, url)
    this.api = api
    this.url = url
  }

  fetchTable({ version, ...payload }) {
    if (version === 'v2') {
      return this.api.post('/co-term/v2/products', payload)
    } else {
      return this.api.post(this.url, payload)
    }
  }
}
