/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'
import StoreForm from './StoreForm'

class StoreAdd extends Component {
  componentDidMount() {
    this.props.storeRepository.clearCrudData()
  }

  render() {
    return (
      <ContentContainer
        title={`Create New Store`}
        promptOnExit={this.props.storeRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <StoreForm {...this.props} />
      </ContentContainer>
    )
  }
}

export default decorate(inject('storeRepository')(observer(StoreAdd)), {})
