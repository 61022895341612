/* @flow */

import { action, decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import OrderRepository from './order'
import OrderSubscriptionServices, {
  OrderSubscriptionListServices,
  OrderSubscriptionProductsServices,
} from 'services/orderSubscription'

const defaults = {
  CRUD: {
    loading: false,
    data: {},
    errors: [],
  },
  OrderRates: {
    loading: false,
    data: {
      shippingTotal: 0,
      shippingInsurance: 0,
      grandTotal: 0,
      currency: '',
      paymentCharge: 0,
      subTotal: 0,
      subscriptionBalance: 0,
      subscriptionBalanceDue: 0,
    },
  },
  OrderAttributes: { loading: true, data: null },
  ApprovalRequest: {
    loading: false,
    data: {},
    errors: [],
  },
  OrderStatus: {
    loading: false,
    data: [],
    errors: [],
  },
  Coupon: {
    loading: false,
    errors: [],
  },
  Discounts: {
    loading: false,
    errors: [],
    data: [],
  },
}
class OrderSubscriptionRepository extends OrderRepository {
  service: OrderSubscriptionServices
  constructor(service: OrderSubscriptionServices) {
    super(service)
    this.service = service
  }
  OrderRates = defaults.OrderRates

  OrderAttributes = defaults.OrderAttributes

  ApprovalRequest = defaults.ApprovalRequest

  OrderStatus = defaults.OrderStatus

  Coupon = defaults.Coupon

  Discounts = defaults.Discounts
  cancelSubscription = async payload => {
    const { data, errors } = await this.service.cancelSubscription(payload)
    return { data, errors }
  }
  cancelOrder = async orderId => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.cancelOrder(orderId)
    this.CRUD.loading = false
    return { data, errors }
  }

  getNewContractById = async (payload, callback) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.getNewContractById(payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  saveAsDraft = async (payload, callback, id = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.saveAsDraft(id, payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }
  reset() {
    this.CRUD = defaults.CRUD
    this.OrderRates = defaults.OrderRates
    this.OrderAttributes = defaults.OrderAttributes
    this.ApprovalRequest = defaults.ApprovalRequest
    this.OrderStatus = defaults.OrderStatus
    this.Coupon = defaults.Coupon
    this.Discounts = defaults.Discounts
  }
}

export default decorate(OrderSubscriptionRepository, {
  ApprovalRequest: observable,
  isRemovingDiscount: observable,
  OrderRates: observable,
  OrderAttributes: observable,
  OrderStatus: observable,
  Coupon: observable,
  Discounts: observable,
  reset: action,
})
export class OrderSubscriptionProductsRepository extends BaseRepository {
  service: OrderSubscriptionProductsServices
  constructor(service: OrderSubscriptionProductsServices) {
    super(service)
    this.service = service
  }
}

export class OrderSubscriptionListRepository extends BaseRepository {
  service: OrderSubscriptionListServices
  constructor(service: OrderSubscriptionListServices) {
    super(service)
    this.service = service
  }
}
