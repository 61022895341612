import * as definitions from 'definitions'

const appAdminRole = [definitions.ROLE_ADMIN, definitions.ROLE_APP_ADMIN]
const orderRoles = [...appAdminRole, definitions.ROLE_ORDER_PROCESS]
const allRoles = [...orderRoles, definitions.ROLE_USER]

export const routes = [
  {
    text: 'Sales',
    icon: 'sales.svg',
    roles: allRoles,
    routes: [
      {
        route: definitions.ORDERS_PATH,
        text: 'Orders',
        roles: allRoles,
      },
      {
        route: '/quotations',
        text: 'Quotations',
        roles: allRoles,
      },
      // for Phase 2 only
      {
        route: definitions.SUBSCRIPTIONS_PATH,
        text: 'Subscriptions',
        roles: allRoles,
      },
      {
        route: definitions.COTERM_PATH,
        text: 'Co-Term',
        roles: allRoles,
      },
      {
        route: definitions.PAYMENTS_PATH,
        text: 'Online Payments',
        roles: allRoles,
      },
      {
        route: definitions.AUTO_RENEWAL_PATH,
        text: 'Auto Renewal',
        roles: appAdminRole,
      },
      {
        route: definitions.ORDER_ADVANCED_SEARCH_PATH,
        text: 'Advanced Search',
        roles: orderRoles,
      },
    ],
  },
  {
    text: 'Pricing',
    icon: 'price rules.svg',
    roles: appAdminRole,
    routes: [
      {
        route: definitions.SHIPPING_PATH,
        text: 'Shipping',
        roles: appAdminRole,
      },
      {
        route: definitions.PAYMENT_SURCHARGE_PATH,
        text: 'Payment Surcharge',
        roles: appAdminRole,
      },
      {
        route: `/pricing/rules`,
        text: 'Price Rules',
        roles: appAdminRole,
      },
      {
        route: `/pricing/codes`,
        text: 'Coupon Codes',
        roles: appAdminRole,
      },
    ],
  },
  {
    text: 'Products',
    icon: 'products.svg',
    roles: allRoles,
    routes: [
      {
        route: definitions.PRODUCTS_PATH,
        text: 'All Products',
        roles: allRoles,
      },
      {
        route: definitions.CATEGORIES_PATH,
        text: 'Categories',
        roles: allRoles,
      },
      {
        route: definitions.PRODUCT_OPTIONS_PATH,
        text: 'Product Options',
        roles: allRoles,
      },
    ],
  },
  {
    text: 'Customers',
    icon: 'customers.svg',
    roles: allRoles,
    routes: [
      {
        route: definitions.CUSTOMERS_PATH,
        text: 'All Customers',
        roles: allRoles,
      },
      {
        route: definitions.CUSTOMER_GROUPS_PATH,
        text: 'Customer Groups',
        roles: allRoles,
      },
    ],
  },
  {
    text: 'Settings',
    icon: 'settings.svg',
    roles: appAdminRole,
    routes: [
      {
        route: definitions.ORDER_TYPES_PATH,
        text: 'Order Types',
        roles: appAdminRole,
      },
      {
        route: definitions.USERS_PATH,
        text: 'Users',
        roles: appAdminRole,
      },
      {
        route: definitions.USER_ROLE_ACCESS_PATH,
        text: 'User Role Access',
      },
      {
        route: definitions.STORES_PATH,
        text: 'Stores',
        roles: appAdminRole,
      },
      {
        route: definitions.BANK_ACCOUNTS_PATH,
        text: 'Bank Accounts',
        roles: appAdminRole,
      },
      {
        route: definitions.PAYMENT_ACCOUNTS_PATH,
        text: 'Payment Accounts',
        roles: appAdminRole,
      },
      {
        route: definitions.SHIPPING_ZONES_PATH,
        text: 'Shipping Zones',
        roles: appAdminRole,
      },
      {
        route: definitions.COLLECTIONS_PATH,
        text: 'Collections',
      },
      {
        route: definitions.IMPORT_PATH,
        text: 'Upload Function',
      },
      {
        route: definitions.ATTRIBUTES_PATH,
        text: 'Attributes',
      },
    ],
  },
  {
    text: 'Restrictions',
    icon: 'sanctions.svg',
    roles: appAdminRole,
    routes: [
      {
        route: definitions.PROHIBITED_ITEMS_PATH,
        text: 'Prohibited Items',
        roles: appAdminRole,
      },
      {
        route: definitions.SANCTIONS_PATH,
        text: 'Sanction List',
        roles: appAdminRole,
      },
    ],
  },
  {
    route: definitions.LOG_BOOK_PATH,
    text: 'Log Book',
    icon: 'log book.svg',
    roles: appAdminRole,
  },
]
