/* @flow */

import React, { Component } from 'react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import Form from 'components/Common/Form'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

type Props = {}

class PaymentForm extends Component<Props> {
  constructor(props) {
    super(props)
    const {
      data: {
        amountCurrency = '',
        amount = '',
        orderId = null,
        customer = null,
        description = '',
        paymentAccount = {},
        paymentTo,
        ccs = null,
      } = {},
    } = props
    const rules = 'required'
    const fields = {
      amountCurrency: {
        type: 'select',
        label: 'Currency',
        rules,
        value: amountCurrency,
      },
      amount: {
        type: 'text',
        label: 'Amount',
        rules,
        value: amount,
      },
      orderId: {
        type: 'text',
        label: 'Order ID',
        rules,
        value: orderId,
      },
      customer: {
        type: 'text',
        label: 'Customer',
        value: customer,
      },
      description: {
        type: 'textarea',
        label: 'Description',
        rules,
        value: description,
      },
      paymentTo: {
        type: 'text',
        label: 'Send Payment to',
        placeholder: 'Enter Email',
        rules: `${rules}|email`,
        value: paymentTo,
      },
      paymentAccount: {
        type: 'text',
        label: 'Stripe Public Business Name',
        value: paymentAccount,
      },
      ccs: {
        type: 'select',
        label: 'CC Mailing List',
        value: ccs,
      },
    }

    this.form = new Form({ fields }, { name: 'UserForm', handleSubmit: this.handleSubmit })
    this.state = {}
  }
  componentDidMount() {
    this.props.commonRepository.getCurrencies()
  }
  componentWillUnmount() {
    this.props.paymentRepository.reset()
  }
  handleCancel = () => {
    this.props.history.push('/payments')
  }
  handleSubmit = form => {
    const { orderId, customer, ...rest } = form.values()
    rest.amountCurrency = rest.amountCurrency.value
    rest.invoiceUrl = 'string'
    rest.ccs = rest.ccs ? rest.ccs.map(cc => cc.value) : []
    const {
      paymentRepository: { create },
    } = this.props
    create({ ...rest, orderId: orderId && orderId.orderNumber, ...(customer && { customer }) }, this.handleCancel)
  }
  handleOderIdAndCustomerOnChange = async (val = {}) => {
    const { paymentAccount = null } = val?.customer || {}
    if (val && val.hasOwnProperty('country') && val.hasOwnProperty('vatInd')) {
      const { data, errors } = await this.props.paymentAccountRepository.getByCountryAndVat(
        val.country,
        val.vatInd,
        paymentAccount
      )
      if (!errors.length && data && data.name) {
        this.form.$('paymentAccount').set(data)
      }
      if (val && val.hasOwnProperty('customer')) {
        this.form.$('customer').set(val.customer)
      } else {
        this.form.$('customer').set(null)
      }
    } else if (val && val.id) {
      const { data, errors } = await this.props.paymentAccountRepository.getByCustomer(val.id, paymentAccount)
      if (!errors.length && data && data.name) {
        this.form.$('paymentAccount').set(data)
      }
    } else {
      this.form.$('paymentAccount').set({})
    }
  }
  render() {
    const {
      commonRepository: { currencies },
      paymentRepository: {
        CRUD: { errors, loading, loadingFailed },
      },
      customerRepository: { customerReactSelectSearch },
      orderRepository: { getOrderByOrderNumber },
    } = this.props
    const hasCustomer = this.form.$('orderId').value && this.form.$('orderId').value.hasOwnProperty('customer')
    return (
      <Row>
        <Col lg={6}>
          <FormContainer
            title="Payment Details"
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={loadingFailed && errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`Saving please wait...`}
                confirmLabel={`Save`}
                onCancel={this.handleCancel}
                hideConfirm={!allowedToAppAdminRole()}
              />
            }
          >
            <Row>
              <Col xs={4}>
                <ReactSelect
                  field={this.form.$('amountCurrency')}
                  options={{
                    value: this.form.$('amountCurrency').value,
                    options: currencies,
                  }}
                />
              </Col>
              <Col xs={8}>
                <Input field={this.form.$('amount')} />
              </Col>
            </Row>
            {/* <Input field={this.form.$('orderId')} /> */}
            <ReactSelect
              field={this.form.$('orderId')}
              serverSide
              search={async payload => {
                return payload.search ? await getOrderByOrderNumber(payload.search) : await { data: [], errors: [] }
              }}
              options={{
                valueKey: 'orderNumber',
                labelKey: 'orderNumber',
              }}
              onChange={this.handleOderIdAndCustomerOnChange}
              creatable
            />
            <ReactSelect
              field={this.form.$('customer')}
              serverSide
              search={async payload => await customerReactSelectSearch({ ...payload, status: 'ACTIVE' })}
              options={{
                valueKey: 'id',
                labelKey: 'name',
                defaultOptions: true,
                isDisabled: hasCustomer,
              }}
              onChange={this.handleOderIdAndCustomerOnChange}
              showTooltip
            />
            <Input field={this.form.$('description')} />
            <Input field={this.form.$('paymentTo')} />
            <ReadOnlyField
              label={this.form.$('paymentAccount').label}
              value={this.form.$('paymentAccount').value.name || 'N/A'}
              disabled
            />
            <ReactSelect field={this.form.$('ccs')} creatable options={{ options: [], isMulti: true }} />
          </FormContainer>
        </Col>
      </Row>
    )
  }
}

export default decorate(
  inject(
    'commonRepository',
    'paymentRepository',
    'customerRepository',
    'orderRepository',
    'paymentAccountRepository'
  )(observer(PaymentForm)),
  {}
)
