import { ListGroup } from 'reactstrap'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import CategoryListItem from './CategoryListItem'

const CategoryList = ({ form, list = [], readOnly }) => {
  return (
    <CategoryListStyled tag="div">
      {list.map(listItem => (
        <CategoryListItem key={listItem.id} form={form} item={listItem} siblings={list} readOnly={readOnly} />
      ))}
    </CategoryListStyled>
  )
}

const CategoryListStyled = styled(ListGroup)`
  .list-group-item {
    display: flex;
    align-items: center;
    border: none;
    padding-top: 4px;
    padding-bottom: 4px;
    &:hover {
      background-color: #f3f3f3;
    }
    &:active {
      background-color: ${props => props.theme.inputBorder};
    }
  }
  .indent-left {
    margin-left: 28px;
  }
`

export default observer(CategoryList)
