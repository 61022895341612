import React from 'react'

import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedTo } from 'helpers/Auth'
import { getDuplicates } from 'helpers/Array'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { prettyPrintJson } from 'helpers/String'
import { USER_ROLE_ACCESS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class UserRoleAccessForm extends React.Component {
  constructor(props) {
    super(props)

    const { data: { role, createdDate, lastModifiedDate, lastModifiedBy, operations } = {} } = props
    const fields = {
      role: {
        label: 'Role',
        rules: 'required',
        value: role,
      },
      createdDate: {
        label: 'Created Date',
        value: createdDate,
      },
      lastModifiedDate: {
        label: 'Last Modified Date',
        value: lastModifiedDate,
      },
      lastModifiedBy: {
        label: 'Last Modified By',
        value: lastModifiedBy,
      },
      operations: {
        type: 'textarea',
        label: 'Operations',
        value: prettyPrintJson(operations, 4),
        validators: [this.validateOperations],
      },
    }

    this.form = new Form({ fields }, { name: 'UserRoleAccessForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }

  validateOperations = ({ field }) => {
    const value = field.value

    if (!value) {
      return [false, 'The Operations field is required.']
    }

    try {
      const operations = JSON.parse(value)
      if (!Array.isArray(operations)) {
        return [false, `"${operations}" is not a valid array of objects`]
      }

      if (!operations.length) {
        return [false, 'Operations array should not be empty']
      }

      const tooManyProperties = operations.filter(item => Object.keys(item).length > 2)
      if (tooManyProperties?.length) {
        return [
          false,
          // eslint-disable-next-line max-len
          `Operation rule(s) should only contain "method" and "regex" properties:\n\n${prettyPrintJson(tooManyProperties)}`,
        ]
      }

      const httpActions = ['*', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE']
      const invalidObject = operations.filter(
        item => !item['method'] || !item['regex'] || !httpActions.includes(item['method'])
      )
      if (invalidObject?.length) {
        return [
          false,
          // eslint-disable-next-line max-len
          `Operation rule(s) have missing property or invalid valid value for either "method" or "regex" properties:\n\n${prettyPrintJson(
            invalidObject
          )}`,
        ]
      }

      const duplicates = getDuplicates(operations, 'method', 'regex')
      if (duplicates.length) {
        return [false, `Operations field contain duplicates:\n\n${prettyPrintJson(duplicates)}`]
      }

      return [true]
    } catch (error) {
      return [false, error.message]
    }
  }

  handleSubmit = () => {
    const {
      userRoleAccessRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props
    const { role, operations } = this.form.values()
    const payload = {
      id,
      role,
      operations: JSON.parse(operations),
    }

    const requestAction = isAdd ? create : patch
    requestAction(payload, this.gotoUserRoleAccessList)
  }

  gotoUserRoleAccessList = () => {
    this.props.history.push(USER_ROLE_ACCESS_PATH)
  }

  handleOperationsOnBlur = event => {
    if (this.form.$('operations').isValid) {
      const value = event.target.value
      if (value) {
        let validJson
        try {
          validJson = JSON.parse(value)
        } catch {}
        if (validJson) {
          event.target.value = JSON.stringify(validJson, null, 4)
        }
      }
    }
  }

  render() {
    const {
      userRoleAccessRepository: { CRUD: { data: { id }, errors, loading } = {} },
      isAdd,
    } = this.props

    return (
      <FormContainer
        plain
        title="User Role Access"
        onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
        errors={errors}
      >
        <Row>
          <Col xs={4}>
            <Card>
              {isAdd && <InputStyled field={this.form.$('role')} />}
              {!isAdd && <ReadOnlyField label={this.form.$('role').label} value={this.form.$('role').value} />}
              <ReadOnlyField
                label={this.form.$('createdDate').label}
                value={getFormattedDateTimeString(this.form.$('createdDate').value)}
              />
              <ReadOnlyField
                label={this.form.$('lastModifiedDate').label}
                value={getFormattedDateTimeString(this.form.$('lastModifiedDate').value)}
              />
              <ReadOnlyField label={this.form.$('lastModifiedBy').label} value={this.form.$('lastModifiedBy').value} />
            </Card>
          </Col>
          <Col xs={8}>
            <Card
              className="mb-0"
              actions={
                <FormActions
                  variant="contained"
                  loading={loading}
                  loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
                  confirmLabel="Save"
                  cancelLabel={id ? 'Close' : 'Cancel'}
                  onCancel={this.gotoUserRoleAccessList}
                  hideConfirm={!allowedTo()}
                />
              }
            >
              <InputStyled field={this.form.$('operations')} rows="25" onBlur={this.handleOperationsOnBlur} />
            </Card>
          </Col>
        </Row>
      </FormContainer>
    )
  }
}

const InputStyled = styled(Input)`
  .form-text {
    font-size: 12px !important;
    white-space: break-spaces;
  }
`

export default inject('userRoleAccessRepository')(observer(UserRoleAccessForm))
