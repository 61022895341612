export const defaultCriteraValues = {
  isCreate: false,
  isCriteriaOpen: false,
  isConditionOpen: false,
  condition: {
    condition: 'all',
    value: 'true',
  },
  type: 'combinations',
  criterias: [],
}

export const defaultAttributeValues = {
  fieldName: '',
  operator: '',
  fieldValue: '',
  type: 'attribute',
  isOperatorOpen: false,
  isAttributeOpen: false,
}

export const STRING_MAP = {
  all: 'ALL',
  any: 'ANY',
  true: 'TRUE',
  false: 'FALSE',
  is: 'is',
  isNot: 'is not',
  eogt: 'equals or greater than',
  eolt: 'equals or less than',
  gt: 'greater than',
  lt: 'less than',
  contains: 'contains',
  dnc: 'does not contain',
  ioo: 'is one of',
  inoo: 'is not one of',
  condition_combination: 'Conditions Combination',
  attribute_set: 'Attribute Set',
  category: 'Category',
  physical_product_type: 'Physical Product Type',
  product_series: 'Product Series',
  sku: 'SKU',
  shipping_type: 'Shipping Type',
  virtual_product_type: 'Virtual Product Type',
  warranty_period: 'Warranty Period',
  warranty_type: 'Warranty Type',
}
