import { inject } from 'mobx-react'
import React from 'react'

import { PRODUCT_ATTRIBUTE_GROUP_SPECIFICATION } from 'definitions'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

export default inject('productAttributeRepository')(({
  productAttributeRepository,
  form,
  group,
  open,
  onConfirm,
  onClose,
}) => {
  const {
    Table: { data: productAttributeData },
  } = productAttributeRepository
  const field = form.$('newCustomField')
  // const values = form.values()

  const excludedAttributes = form.$('customFields').map(item => item.key)
  //values ? Object.keys(values.customFields || {}) : []
  const filteredData = productAttributeData.filter(item => {
    const { productAttributeGroup: { value: attributeGroup = PRODUCT_ATTRIBUTE_GROUP_SPECIFICATION } = {} } = item
    return attributeGroup === group && !excludedAttributes.includes(item.attributeName)
  })
  field.set(null)
  return (
    <Modal title="Add Custom Fields" open={open} onConfirm={onConfirm} onClose={onClose}>
      <ReactSelect
        field={field}
        options={{
          isMulti: true,
          labelKey: 'attributeLabel',
          valueKey: 'attributeName',
          options: filteredData,
        }}
      />
    </Modal>
  )
})
