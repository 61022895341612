/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import QuotationServices from 'services/quotation'

class QuotationRepository extends BaseRepository {
  service: QuotationServices
  constructor(service: QuotationServices) {
    super(service)
    this.service = service
  }
}

export default decorate(QuotationRepository, {})
