/* @flow */
import * as serviceWorker from './serviceWorker'
import { Provider } from 'mobx-react'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'

import './scss/main.scss'
import 'ionicons/dist/css/ionicons.css'
import theme from './scss/vars.module.scss'

import { ThemeProvider } from 'styled-components'
import GlobalStyles from './styled/global'

import { ENV_PRODUCTION } from 'definitions'
import EdgeMarker from 'components/Common/EdgeMarker'
import EnvNotice from './EnvNotice'

import store from 'stores'

const env = process.env.REACT_APP_ENV

ReactDOM.render(
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {env !== ENV_PRODUCTION && <EnvNotice env={env} />}
        <EdgeMarker />
        <App />
        <GlobalStyles />
      </React.Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
