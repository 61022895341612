/* @flow */
import APIRepository from './APIRepository'
import Common from './common'
import CommonServices from 'services/common'

import CollectionRepository, { CollectionItemRepository } from './collection'
import CollectionServices, { CollectionItemServices } from 'services/collection'

import Customer, {
  CustomerAddressRepository,
  CustomerContactRepository,
  CustomerSpecialProductRepository,
  CustomerUserLoginRepository,
} from './customer'
import CustomerGroup, { CustomerGroupDetails, CustomerGroupDisabledProductRepository } from './customerGroup'
import CustomerGroupServices, {
  CustomerGroupDetailsServices,
  CustomerGroupDisabledProductServices,
} from 'services/customerGroup'
import CustomerServices, {
  CustomerAddressServices,
  CustomerContactServices,
  CustomerSpecialProductServices,
  CustomerUserLoginServices,
} from 'services/customer'

import LoginRepository from 'stores/login'
import LoginServices from 'services/login'

import OrderRepositories, {
  OrderAdvanceSearchRepository,
  OrderAttributeRepository,
  OrderItemCustomOptionRepository,
  OrderItemIccidNumberRepository,
  OrderItemRepository,
  OrderItemSerialNumberRepository,
} from './order'
import OrderServices, {
  OrderAdvanceSearchServices,
  OrderAttributeServices,
  OrderItemCustomOptionServices,
  OrderItemIccidNumberServices,
  OrderItemSerialNumberServices,
  OrderItemServices,
} from 'services/order'

import OrderChargeRepository from './orderCharge'
import OrderChargeServices from 'services/orderCharge'

import OrderCommentRepository from './orderComment'
import OrderCommentServices from 'services/orderComment'

import ProductRepositories, {
  ProductAttributeRepository,
  ProductAttributeValuesRepository,
  ProductCustomOptionRepository,
  ProductImageRepository,
  ProductOptionRepository,
} from './product'
import ProductServices, {
  ProductAttributeServices,
  ProductAttributeValuesServices,
  ProductCustomOptionServices,
  ProductImageServices,
  ProductOptionServices,
} from 'services/product'

import ProductCommentRepository from './productComment'
import ProductCommentServices from 'services/productComment'

import FileUploadRepository from './fileUpload'
import FileUploadServices from 'services/fileUpload'

import Payment from './payment'
import PaymentServices from 'services/payment'

import UserRepository from './user'
import UserServices from 'services/user'

import UserRoleAccessRepository from './userRoleAccess'
import UserRoleAccessServices from 'services/userRoleAccess'

import StoreRepository, { StoreCountryRepository, StoreProductRepository } from './store'
import StoreServices, { StoreCountryServices, StoreProductServices } from 'services/store'

import CatalogPricingRuleRepository, { CouponRepository } from './catalogPricingRule'
import CatalogPricingRuleServices, { CouponServices } from 'services/catalogPricingRule'

import CategoryRepository from './category'
import CategoryServices from 'services/category'

import CoTermRepository, { CoTermProductRepository } from './coTerm'
import CoTermServices, { CoTermProductServices } from 'services/coTerm'

import ShippingRepository from './shipping'
import ShippingServices from 'services/shipping'

import ShippingRateRepository from './shippingRate'
import ShippingRateServices from 'services/shippingRate'

import ImportRepository, { ImportTypeRepository } from './import'
import ImportServices, { ImportTypeServices } from 'services/import'

import SanctionRepository, { SanctionCustomerRepository } from './sanction'
import SanctionServices, { SanctionCustomerServices } from 'services/sanction'

import QuotationRepository from './quotation'
import QuotationServices from 'services/quotation'

import BankAccountRepository from './bankAccount'
import BankAccountServices from 'services/bankAccount'

import OrderSubscriptionRepository, {
  OrderSubscriptionListRepository,
  OrderSubscriptionProductsRepository,
} from './orderSubscription'
import OrderSubscriptionServices, {
  OrderSubscriptionListServices,
  OrderSubscriptionProductsServices,
} from 'services/orderSubscription'

import LogsRepository from './logs'
import LogsServices from 'services/logs'

import ZoneRepository from './zone'
import ZoneServices from 'services/zone'

import ProhibitedItemRepository from './prohibitedItem'
import ProhibitedItemServices from 'services/prohibitedItem'

import OrderTypeRepository from './orderType'
import OrderTypeServices from 'services/orderType'

import StorefrontSlideRepository from './storefrontSlide'
import StorefrontSlideServices from 'services/storefrontSlide'

import PaymentAccountRepository from './paymentAccount'
import PaymentAccountServices from 'services/paymentAccount'

import GlobalSearchRepository from './globalSearch'
import GlobalSearchServices from 'services/globalSearch'

import DeviceManagementServices from 'services/deviceManagement'
import DevinceManagementRepository from './deviceManagement'

import DeviceManagementJobServices from 'services/deviceManagementJob'
import DevinceManagementJobRepository from './deviceManagementJob'

import DeviceManagementCommentServices from 'services/deviceManagementComment'
import DevinceManagementCommentRepository from './deviceManagementComment'
import KeycloakRepository from './keycloak'

import AuthService from 'services/auth'
import AuthStore from './auth'

import SearchRepository from './search'

import { SettingsCommentRepository } from './settings'
import { SettingsCommentService } from 'services/settings'

const apiRepository = new APIRepository()
const apiPublicRepository = new APIRepository(true)

const userServices = new UserServices(apiRepository)
const userRepository = new UserRepository(userServices)

const userRoleAccessServices = new UserRoleAccessServices(apiRepository)
const userRoleAccessRepository = new UserRoleAccessRepository(userRoleAccessServices)

const collectionServices = new CollectionServices(apiRepository)
const collectionRepository = new CollectionRepository(collectionServices)
const collectionItemServices = new CollectionItemServices(apiRepository)
const collectionItemRepository = new CollectionItemRepository(collectionItemServices)

const commonServices = new CommonServices(apiRepository, apiPublicRepository)
const commonRepository = new Common(commonServices)

const paymentServices = new PaymentServices(apiRepository, apiPublicRepository)
const paymentRepository = new Payment(paymentServices)

const orderServices = new OrderServices(apiRepository, apiPublicRepository)
const orderRepository = new OrderRepositories(orderServices)
const orderAdvanceSearchServices = new OrderAdvanceSearchServices(apiRepository)
const orderAdvanceSearchRepository = new OrderAdvanceSearchRepository(orderAdvanceSearchServices)
const orderAttributeServices = new OrderAttributeServices(apiRepository)
const orderAttributeRepository = new OrderAttributeRepository(orderAttributeServices)
const orderItemServices = new OrderItemServices(apiRepository)
const orderItemRepository = new OrderItemRepository(orderItemServices)
const orderItemSerialNumberServices = new OrderItemSerialNumberServices(apiRepository)
const orderItemSerialNumberRepository = new OrderItemSerialNumberRepository(orderItemSerialNumberServices)
const orderItemIccidNumberServices = new OrderItemIccidNumberServices(apiRepository)
const orderItemIccidNumberRepository = new OrderItemIccidNumberRepository(orderItemIccidNumberServices)

const orderItemCustomOptionServices = new OrderItemCustomOptionServices(apiRepository)
const orderItemCustomOptionRepository = new OrderItemCustomOptionRepository(orderItemCustomOptionServices)

const orderChargeServices = new OrderChargeServices(apiRepository)
const orderChargeRepository = new OrderChargeRepository(orderChargeServices)

const orderCommentServices = new OrderCommentServices(apiRepository)
const orderCommentRepository = new OrderCommentRepository(orderCommentServices)

const productServices = new ProductServices(apiRepository)
const productRepository = new ProductRepositories(productServices)
const productAttributeServices = new ProductAttributeServices(apiRepository)
const productAttributeRepository = new ProductAttributeRepository(productAttributeServices)
const productAttributeValuesServices = new ProductAttributeValuesServices(apiRepository)
const productAttributeValuesRepository = new ProductAttributeValuesRepository(productAttributeValuesServices)
const productImageServices = new ProductImageServices(apiRepository)
const productImageRepository = new ProductImageRepository(productImageServices)
const productOptionServices = new ProductOptionServices(apiRepository)
const productOptionRepository = new ProductOptionRepository(productOptionServices)
const productCustomOptionServices = new ProductCustomOptionServices(apiRepository)
const productCustomOptionRepository = new ProductCustomOptionRepository(productCustomOptionServices)

const productCommentServices = new ProductCommentServices(apiRepository)
const productCommentRepository = new ProductCommentRepository(productCommentServices)

const fileUploadServices = new FileUploadServices(apiRepository)
const fileUploadRepository = new FileUploadRepository(fileUploadServices)

const loginServices = new LoginServices(apiRepository)
const loginRepository = new LoginRepository(userRepository, loginServices)

const customerServices = new CustomerServices(apiRepository)
const customerRepository = new Customer(customerServices)
const customerAddressServices = new CustomerAddressServices(apiRepository)
const customerAddressRepository = new CustomerAddressRepository(customerAddressServices)
const customerContactServices = new CustomerContactServices(apiRepository)
const customerContactRepository = new CustomerContactRepository(customerContactServices)
const customerUserLoginServices = new CustomerUserLoginServices(apiRepository)
const customerUserLoginRepository = new CustomerUserLoginRepository(customerUserLoginServices)
const customerSpecialProductServices = new CustomerSpecialProductServices(apiRepository)
const customerSpecialProductRepository = new CustomerSpecialProductRepository(customerSpecialProductServices)

const customerGroupServices = new CustomerGroupServices(apiRepository)
const customerGroupRepository = new CustomerGroup(customerGroupServices)
const customerGroupDetailsServices = new CustomerGroupDetailsServices(apiRepository)
const customerGroupDetailsRepository = new CustomerGroupDetails(customerGroupDetailsServices)
const customerGroupDisabledProductServices = new CustomerGroupDisabledProductServices(apiRepository)
const customerGroupDisabledProductRepository = new CustomerGroupDisabledProductRepository(
  customerGroupDisabledProductServices
)

const storeServices = new StoreServices(apiRepository)
const storeRepository = new StoreRepository(storeServices)
const storeProductServices = new StoreProductServices(apiRepository)
const storeProductRepository = new StoreProductRepository(storeProductServices)
const storeCountryServices = new StoreCountryServices(apiRepository)
const storeCountryRepository = new StoreCountryRepository(storeCountryServices)

const catalogPricingRuleServices = new CatalogPricingRuleServices(apiRepository)
const catalogPricingRuleRepository = new CatalogPricingRuleRepository(catalogPricingRuleServices)
const couponServices = new CouponServices(apiRepository)
const couponRepository = new CouponRepository(couponServices)

const categoryServices = new CategoryServices(apiRepository)
const categoryRepository = new CategoryRepository(categoryServices)

const coTermServices = new CoTermServices(apiRepository)
const coTermRepository = new CoTermRepository(coTermServices)
const coTermProductServices = new CoTermProductServices(apiRepository)
const coTermProductRepository = new CoTermProductRepository(coTermProductServices)

const shippingServices = new ShippingServices(apiRepository)
const shippingRepository = new ShippingRepository(shippingServices)

const shippingRateServices = new ShippingRateServices(apiRepository)
const shippingRateRepository = new ShippingRateRepository(shippingRateServices)

const importServices = new ImportServices(apiRepository)
const importRepository = new ImportRepository(importServices)
const importTypeServices = new ImportTypeServices(apiRepository)
const importTypeRepository = new ImportTypeRepository(importTypeServices)

const sanctionServices = new SanctionServices(apiRepository)
const sanctionRepository = new SanctionRepository(sanctionServices)
const sanctionCustomerServices = new SanctionCustomerServices(apiRepository)
const sanctionCustomerRepository = new SanctionCustomerRepository(sanctionCustomerServices)

const quotationServices = new QuotationServices(apiRepository)
const quotationRepository = new QuotationRepository(quotationServices)

const bankAccountServices = new BankAccountServices(apiRepository)
const bankAccountRepository = new BankAccountRepository(bankAccountServices)

const orderSubscriptionServices = new OrderSubscriptionServices(apiRepository)
const orderSubscriptionRepository = new OrderSubscriptionRepository(orderSubscriptionServices)

const orderSubscriptionProductsServices = new OrderSubscriptionProductsServices(apiRepository)
const orderSubscriptionProductsRepository = new OrderSubscriptionProductsRepository(orderSubscriptionProductsServices)
const orderSubscriptionListServices = new OrderSubscriptionListServices(apiRepository)
const orderSubscriptionListRepository = new OrderSubscriptionListRepository(orderSubscriptionListServices)

const logsServices = new LogsServices(apiRepository)
const logsRepository = new LogsRepository(logsServices)

const zoneServices = new ZoneServices(apiRepository)
const zoneRepository = new ZoneRepository(zoneServices)

const prohibitedItemServices = new ProhibitedItemServices(apiRepository)
const prohibitedItemRepository = new ProhibitedItemRepository(prohibitedItemServices)

const orderTypeServices = new OrderTypeServices(apiRepository)
const orderTypeRepository = new OrderTypeRepository(orderTypeServices)

const storefrontSlideServices = new StorefrontSlideServices(apiRepository)
const storefrontSlideRepository = new StorefrontSlideRepository(storefrontSlideServices)
const paymentAccountServices = new PaymentAccountServices(apiRepository)

const paymentAccountRepository = new PaymentAccountRepository(paymentAccountServices)

const globalSearchServices = new GlobalSearchServices(apiRepository)
const globalSearchRepository = new GlobalSearchRepository(globalSearchServices)

const deviceManagementServices = new DeviceManagementServices(apiRepository)
const deviceManagementRepository = new DevinceManagementRepository(deviceManagementServices)

const deviceManagementJobServices = new DeviceManagementJobServices(apiRepository)
const deviceManagementJobRepository = new DevinceManagementJobRepository(deviceManagementJobServices)

const deviceManagementCommentServices = new DeviceManagementCommentServices(apiRepository)
const deviceManagementCommentRepository = new DevinceManagementCommentRepository(deviceManagementCommentServices)
const keycloakRepository = new KeycloakRepository()

const authService = new AuthService(apiRepository)
const authRepository = new AuthStore(authService)

const searchRepository = new SearchRepository()

const settingsCommentService = new SettingsCommentService(apiRepository)
const settingsCommentRepository = new SettingsCommentRepository(settingsCommentService)

export default {
  userRepository,
  userRoleAccessRepository,
  collectionRepository,
  collectionItemRepository,
  couponRepository,
  commonRepository,
  coTermRepository,
  coTermProductRepository,
  apiRepository,
  paymentRepository,
  loginRepository,
  customerRepository,
  customerGroupRepository,
  customerGroupDetailsRepository,
  customerAddressRepository,
  customerContactRepository,
  customerGroupDisabledProductRepository,
  storeRepository,
  storeProductRepository,
  storeCountryRepository,
  productAttributeRepository,
  productAttributeValuesRepository,
  productImageRepository,
  productOptionRepository,
  productCustomOptionRepository,
  productRepository,
  orderRepository,
  orderAttributeRepository,
  orderChargeRepository,
  catalogPricingRuleRepository,
  fileUploadRepository,
  categoryRepository,
  shippingRepository,
  shippingRateRepository,
  importRepository,
  importTypeRepository,
  orderItemRepository,
  sanctionRepository,
  sanctionCustomerRepository,
  orderItemSerialNumberRepository,
  orderItemIccidNumberRepository,
  quotationRepository,
  bankAccountRepository,
  orderAdvanceSearchRepository,
  orderSubscriptionRepository,
  orderSubscriptionProductsRepository,
  orderSubscriptionListRepository,
  orderItemCustomOptionRepository,
  orderCommentRepository,
  logsRepository,
  zoneRepository,
  productCommentRepository,
  customerUserLoginRepository,
  customerSpecialProductRepository,
  prohibitedItemRepository,
  orderTypeRepository,
  storefrontSlideRepository,
  paymentAccountRepository,
  globalSearchRepository,
  deviceManagementRepository,
  deviceManagementJobRepository,
  deviceManagementCommentRepository,
  keycloakRepository,
  authRepository,
  searchRepository,
  settingsCommentRepository,
}
