import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { stripEmptyValues } from 'helpers/Common'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CustomerGroupFormModal extends Component {
  form = null
  constructor(props) {
    super(props)
    const { selectedGroupData, editMode = false } = this.props
    const { name, partnerType, downloadProductListInd } = selectedGroupData || {}

    const fields = {
      name: {
        type: 'text',
        label: 'Name',
        rules: 'required',
        value: editMode ? name : '',
      },
      partnerType: {
        label: 'Partner Type',
        value: editMode ? partnerType : undefined,
      },
      downloadProductListInd: {
        label: 'Enable Product List Download',
        type: 'checkbox',
        value: editMode ? downloadProductListInd : false,
      },
    }
    this.form = new Form(
      { fields },
      {
        name: 'CustomerGroupFormModal',
        hooks: {
          onSuccess: form => this.handleSubmit(form),
        },
      }
    )
    this.props.commonRepository.getCommon('partnerTypes', 'partnerTypes', {})
  }
  componentWillUnmount() {
    this.props.customerGroupRepository.clearErrorMessages()
  }
  handleSubmit = form => {
    const values = form.values()
    const { selectedGroupData, editMode } = this.props
    const { id } = selectedGroupData || {}

    if (id && editMode) {
      this.props.customerGroupRepository.patch({ ...stripEmptyValues(values), id }, res => {
        this.props.table.reload()
        this.props.toggleModal()
      })
    } else {
      this.props.customerGroupRepository.create(stripEmptyValues(values), res => {
        this.props.redirect(res.id)
        this.props.table.reload()
        this.props.toggleModal()
      })
    }
  }
  render() {
    const {
      isModalOpen,
      toggleModal,
      editMode,
      customerGroupRepository: {
        CRUD: { errors, loading, loadingFailed },
      },

      commonRepository: {
        Common: { partnerTypes },
      },
    } = this.props
    return (
      <Modal
        open={isModalOpen}
        title={`${editMode ? `Edit ` : `Add New `} Group`}
        confirmLabel={`Save`}
        loading={loading}
        loadingMessage={`Saving please wait...`}
        onConfirm={() => this.form.submit()}
        onClose={toggleModal}
        hideConfirm={!allowedToOrderRole()}
      >
        <FormContainer
          title="Payment Details"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={loadingFailed && errors}
          plain
        >
          <Input field={this.form.$('name')} />
          <ReactSelect
            field={this.form.$('partnerType')}
            options={{
              options: partnerTypes.data,
            }}
          />
          <Checkbox field={this.form.$('downloadProductListInd')} plain className="ml-1 mt-4" />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('customerGroupRepository', 'commonRepository')(observer(CustomerGroupFormModal)), {})
