import React, { Component } from 'react'

import { Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import CustomDiscountApproval from './CustomDiscountApproval'
import ErrorsList from 'components/Common/ErrorsList'
import Input from 'components/Common/Form/Input'
import MobxForm from 'components/Common/Form/index'
import Modal from 'components/Common/Modal'

class SupportingDocumentModal extends Component {
  form = null
  constructor(props) {
    super(props)
    const { orderRepository } = this.props
    const order = orderRepository.CRUD.data
    this.state = {
      selected: props.selected || {},
      errors: [],
    }
    const fields = {
      files: {
        type: 'file',
        label: 'Custom Discount Approval',
        rules: 'required',
        value:
          order.discountApproval &&
          order.discountApproval.hasOwnProperty('attachments') &&
          order.discountApproval.attachments.length
            ? order.discountApproval.attachments
            : [],
      },
    }
    this.form = new MobxForm(
      {
        fields,
      },
      {
        name: 'CustomDiscountApprovalForm',
        hooks: {
          onSuccess: form => {
            this.handleSubmit(form)
          },
          onError: form => {
            this.setState({
              errors: form.errors(),
            })
          },
        },
      }
    )
  }

  handleSubmit = f => {
    const {
      fileUploadRepository,
      form,
      orderRepository: { CRUD },
    } = this.props
    const attachmentUniqueId =
      (CRUD.data.discountApproval && CRUD.data.discountApproval.attachmentUniqueId) ||
      form.$('attachmentUniqueId').value
    const values = f.values()
    let formAttachment = new FormData()
    Object.keys(values.files).forEach(key => {
      formAttachment.append('files', values.files[key])
    })
    this.setState({ loading: true }, async () => {
      const { data, errors } = await fileUploadRepository.attachFiles(formAttachment, attachmentUniqueId)
      if (data && !errors.length) {
        form.$('attachmentUniqueId').set(data.attachmentUniqueId)
        form
          .$('discountApproval.attachments')
          .set([...(form.$('discountApproval.attachments').value || []), ...data.attachedFiles])
      } else
        this.setState({
          errors: errors,
        })

      this.setState({ loading: false })
    })
  }

  handleDeteleFiles = async files => {
    const {
      fileUploadRepository,
      orderRepository: { CRUD },
      form,
    } = this.props

    if (files?.length) {
      const deletedFileIds = []
      const forDeleteAttachments = []

      this.setState({ loading: true })

      for (var file of files) {
        const fileExists = CRUD.data.discountApproval?.attachments?.find(item => item.id === file.id)
        const attachmentUniqueId = form.$('attachmentUniqueId').value

        if (attachmentUniqueId && !fileExists) {
          const { data: success, errors } = await fileUploadRepository.removeFile(
            attachmentUniqueId,
            file.name || file.fileName
          )
          if (success) {
            deletedFileIds.push(file.id)
          } else {
            if (!errors.length) {
              // Remove file if it no longer exists in temp file in BE
              deletedFileIds.push(file.id)
              forDeleteAttachments.push(file)
            }
          }
        } else {
          deletedFileIds.push(file.id)
          forDeleteAttachments.push(file)
        }
      }

      const attachments = form.$('discountApproval.attachments').value
      const newAttachments = attachments.filter(item => !deletedFileIds.includes(item.id))
      form.$('discountApproval.attachments').set(newAttachments)

      if (forDeleteAttachments.length) {
        form
          .$('discountApproval.deleteAttachments')
          .set([...form.$('discountApproval.deleteAttachments').value, ...forDeleteAttachments])
      }

      this.setState({ loading: false })
    }
  }

  render() {
    const { selected, errors, loading } = this.state
    const { open, onClose, form } = this.props
    return (
      <Modal
        open={open}
        size="md"
        title={'SUBMIT DISCOUNT SUPPORTING'}
        closeLabel={'Close'}
        disabled={!selected}
        confirmIcon=""
        onClose={onClose}
        loading={loading}
        hideConfirm={true}
      >
        <Col sm={12}>
          {!!errors.length && <ErrorsList errors={errors} />}

          <Input field={form.$('discountApproval.comment')} />
          <CustomDiscountApproval
            files={form.$('discountApproval.attachments').value}
            cdForm={this.form}
            onDeleteFiles={this.handleDeteleFiles}
          />
        </Col>
      </Modal>
    )
  }
}

export default decorate(
  inject('storeRepository', 'orderRepository', 'fileUploadRepository')(observer(SupportingDocumentModal)),
  {}
)
