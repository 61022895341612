/* @flow */
import { Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedTo, Visible } from 'helpers/Auth'
import { ATTRIBUTE_TYPES, ATTRIBUTES_PATH } from 'definitions'
import { isNull } from 'helpers/Common'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ToasterNotify from 'helpers/ToasterNotify'

class AttributeForm extends Component {
  constructor(props) {
    super(props)
    const {
      data: {
        attributeLabel = '',
        attributeName = '',
        attributeType = '',
        attributeValue = [''],
        criteriaInd = false,
        multipleValuesInd = false,
        requiredInd = false,
        visibleInStoreInd = true,
        productAttributeGroup,
        sortOrder,
      } = {},
    } = props

    const fields = [
      'attributeLabel',
      'attributeName',
      'attributeType',
      'criteriaInd',
      'multipleValuesInd',
      'requiredInd',
      'visibleInStoreInd',
      'attributeValue',
      'attributeValue[].option',
      'productAttributeGroup',
      'sortOrder',
    ]

    const values = {
      attributeLabel,
      attributeName,
      attributeType,
      criteriaInd,
      multipleValuesInd,
      requiredInd,
      visibleInStoreInd,
      // convert ['a', 'b'] => [{ option: 'a' }, { option: 'b' }]
      attributeValue: attributeValue.map(attr => ({ option: attr })),
      productAttributeGroup: productAttributeGroup,
      sortOrder,
    }

    const labels = {
      attributeLabel: 'Label',
      attributeName: 'Name',
      attributeType: 'Type',
      criteriaInd: 'Pricing Rule Criteria',
      multipleValuesInd: 'Accepts Multiple Values',
      requiredInd: 'Required',
      visibleInStoreInd: 'Visible in Store',
      'attributeValue[].option': 'Option',
      productAttributeGroup: 'Product Attribute Group',
      sortOrder: 'Sort Order',
    }
    const types = {
      criteriaInd: 'checkbox',
      multipleValuesInd: 'checkbox',
      requiredInd: 'checkbox',
      visibleInStoreInd: 'checkbox',
      productAttributeGroup: 'select',
      sortOrder: 'number',
    }
    const rules = { attributeLabel: 'required', attributeName: 'required', attributeType: 'required' }

    this.form = new Form(
      { fields, labels, values, types, rules },
      { name: 'ProductAttributeForm', handleSubmit: this.handleSubmit }
    )

    // // haxx to set attributeType, because setting from the values object doesn't make it observable
    // if (attributeType) {
    //   this.form.$('attributeType').set({ label: ATTRIBUTE_TYPES[attributeType], value: attributeType })
    // }
  }
  componentWillUnmount() {
    this.props.productAttributeRepository.clearErrorMessages()
  }
  handleCancel = () => this.props.history.push(ATTRIBUTES_PATH)
  handleSubmit = form => {
    const values = form.values()

    const {
      productAttributeRepository: {
        CRUD: {
          data: { id = null },
        },
        patch,
        create,
      },
    } = this.props

    const requestAction = id ? patch : create
    requestAction(
      {
        id,
        ...values,
        attributeType: values.attributeType,
        attributeValue: values.attributeValue.map(value => value.option).filter(value => !!value),
        productAttributeGroup: isNull(values.productAttributeGroup),
      },
      this.handleCancel
    )
  }
  shouldRenderAttributeValues = () => {
    const type = this.form.$('attributeType').value
    return type === 'array'
  }
  toggleDeleteOption = (e, option) => {
    ToasterNotify.confirm({
      message: `Are you sure you want to remove this option?`,
      title: 'Warning',
      onConfirm: () => option.onDel(e),
    })
  }
  renderAttributeValues = () => {
    const options = this.form.$('attributeValue')

    return (
      <Card
        title="Options"
        titleActions={
          <Visible>
            <IconButton color="dark" outline onClick={options.onAdd}></IconButton>
          </Visible>
        }
      >
        <Checkbox field={this.form.$('multipleValuesInd')} />
        {options.map((option, index) => (
          <div key={`grid-${index}`}>
            <div className="d-flex align-items-end">
              <Input key={`option-${index}`} field={option.$('option')} className="flex-fill" />
              <Visible>
                <IconButton icon="ios-trash" onClick={e => this.toggleDeleteOption(e, option)}></IconButton>
              </Visible>
            </div>
          </div>
        ))}
      </Card>
    )
  }
  render() {
    const {
      productAttributeRepository: { CRUD: { errors = [], loading, loadingFailed } = {} },
      commonRepository,
    } = this.props

    return (
      <Row>
        <Col lg={6}>
          <FormContainer
            title="Product Attribute Details"
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={loadingFailed && errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`Saving please wait...`}
                confirmLabel={`Save`}
                onCancel={this.handleCancel}
                hideConfirm={!allowedTo()}
              />
            }
          >
            <Input field={this.form.$('attributeLabel')} />
            <Input field={this.form.$('attributeName')} />

            <RadioGroup
              field={this.form.$('attributeType')}
              radios={Object.keys(ATTRIBUTE_TYPES).map(key => ({ label: ATTRIBUTE_TYPES[key], value: key }))}
              inline
            />

            <Checkbox field={this.form.$('criteriaInd')} />
            <Checkbox field={this.form.$('visibleInStoreInd')} />
            <Checkbox field={this.form.$('requiredInd')} />
            <ReactSelect
              field={this.form.$('productAttributeGroup')}
              customLabelKey={['label', 'value']}
              serverSide
              search={params => commonRepository.reactSelectSearch(params, 'attributeGroup')}
              options={{
                valueKey: 'value',
                labelKey: 'label',
                defaultOptions: true,
              }}
            />
            <Input field={this.form.$('sortOrder')} />
          </FormContainer>
        </Col>
        <Col lg={6}>{this.shouldRenderAttributeValues() && this.renderAttributeValues()}</Col>
      </Row>
    )
  }
}

export default decorate(inject('productAttributeRepository', 'commonRepository')(observer(AttributeForm)), {})
