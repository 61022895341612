import React from 'react'

import Template from '../Template'

const Success = props => {
  const {
    match: { params },
    history,
  } = props

  const action = params.action
  if (!action) {
    history.push('/')
  }
  return (
    <Template title={<h6>Thank you</h6>}>
      <p style={{ fontSize: 12 }}>Your response was successfully submitted.</p>
    </Template>
  )
}

export default Success
