import React, { Component } from 'react'

import { Button } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { PRODUCTS_PATH } from 'definitions'
import AddDisableProductsModal from './AddDisableProductsModal'
import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class DisabledProductPanel extends Component {
  table = null
  constructor(props) {
    super(props)
    this.state = {
      isProductModalOpen: false,
      isPriceModalOpen: false,
      isAlertOpen: false,
      selectedProducts: [],
      itemToDelete: {},
      selected: [],
      bulkAction: null,
      groupId: props.match.params.groupId,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.groupId !== nextProps.match.params.groupId) {
      this.setState(
        {
          groupId: nextProps.match.params.groupId,
        },
        this.table.reload
      )
    }
  }

  toggleAddProductModal = () => this.setState({ isProductModalOpen: !this.state.isProductModalOpen })
  togglePriceModal = () => this.setState({ isPriceModalOpen: !this.state.isPriceModalOpen })
  toggleAlert = () => this.setState({ isAlertOpen: !this.state.isAlertOpen })

  toggleSelected = (e, item) => {
    const selected = [...this.state.selected]
    const isInSelected = selected.map(sel => sel.id).includes(e.target.value)
    if (e.target.checked && !isInSelected) {
      selected.push(item)
    } else {
      const selectedIndex = selected.map(sel => sel.id).indexOf(item.id)
      selected.splice(selectedIndex, 1)
    }

    this.setState(
      {
        selected,
      },
      () => {}
    )
  }

  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  handleProductSelect = products => {
    this.setState({
      isPriceModalOpen: true,
      selectedProducts: products,
    })
  }

  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this product?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }

  handleDelete = async () => {
    const { groupId, customerGroupDisabledProductRepository } = this.props
    await customerGroupDisabledProductRepository.delete(
      { groupId, products: [{ id: this.state.itemToDelete.id }] },
      () => {
        setTimeout(() => this.table.reload(), 300)
      }
    )
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    if (bulkAction === 'Remove') {
      const { groupId, customerGroupDisabledProductRepository } = this.props
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected product(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await customerGroupDisabledProductRepository.delete(
            { groupId, products: selected.map(id => ({ id })) },
            () => {
              setTimeout(() => this.table.reload(), 300)
            }
          )
        },
      })
    }
  }
  render() {
    const { bulkAction, selected } = this.state
    const {
      customerGroupDisabledProductRepository: {
        CRUD: { errors = [] } = {},
        Table: { data = [], loading, totalRecordCount, originalData },
        fetchTable,
      },
      customerGroupDisabledProductRepository,
      searchRepository,
      groupId,
      STR_DEF,
      selectedGroupData,
    } = this.props
    return (
      <>
        <Card
          title={STR_DEF.product}
          titleActions={
            <Visible toAppAdminRole>
              <IconButton color="dark" outline onClick={this.toggleAddProductModal} />
            </Visible>
          }
        >
          {errors.length > 0 && (
            <div style={{ margin: '20px' }}>
              <ErrorsList errors={errors} />{' '}
            </div>
          )}
          <ReactTable
            searchRepository={searchRepository}
            pageKey="customer-groups-disabled-products"
            enableRowLink
            getRowUrl={({ original }) => `${PRODUCTS_PATH}/${original.id}`}
            multiSelect
            className="striped cell-vertical-center"
            columns={[
              {
                accessor: 'name',
                Header: 'Name',
                Cell: ({ original }) => (
                  <span title={original.name} className="pr-2 ellipsis row-clickable">
                    {original.name}
                  </span>
                ),
              },
              { accessor: 'productCode', Header: 'Product Code', width: 160 },
              {
                Header: 'Actions',
                headerClassName: 'justify-content-center',
                className: 'justify-content-center p-1',
                show: allowedToAppAdminRole(),
                width: 80,
                Cell: ({ original }) => (
                  <IconButton
                    icon="ios-trash"
                    onClick={event => {
                      event.preventDefault()
                      this.confirmDeletion(original)
                    }}
                  />
                ),
              },
            ]}
            actions={
              <Visible toAppAdminRole>
                <div className="d-flex justify-content-end align-items-center mb-1 px-3">
                  <ReactSelectStyled
                    className="mr-2"
                    options={{
                      placeholder: 'Bulk Action',
                      options: [{ value: 'Remove', label: 'Remove' }],
                    }}
                    disabled={!selected.length}
                    onChange={vals => {
                      this.setState({ bulkAction: vals && vals.value })
                    }}
                  />
                  <Button
                    size="sm"
                    color="dark"
                    type="button"
                    disabled={!bulkAction}
                    onClick={this.handleSubmitBulkAction}
                  >
                    Submit
                  </Button>
                </div>
              </Visible>
            }
            sort={{ id: 'name' }}
            data={data}
            loadData={params => fetchTable(params, selectedGroupData.id)}
            loading={loading}
            totalRecordCount={totalRecordCount}
            ref={ref => (this.table = ref)}
            search
            totalPages={originalData.totalPages}
            selectElementId="disabledProductsTable"
            selectionChanged={this.handleSelected}
          />
        </Card>
        <AddDisableProductsModal
          groupId={groupId}
          customerGroupDisabledProductRepository={customerGroupDisabledProductRepository}
          isModalOpen={this.state.isProductModalOpen}
          toggleModal={this.toggleAddProductModal}
          handleSelected={this.handleProductSelect}
          table={this.table}
        />
      </>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`

export default decorate(
  inject(
    'customerGroupRepository',
    'customerGroupDisabledProductRepository',
    'searchRepository'
  )(observer(DisabledProductPanel)),
  {}
)
