/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class ShippingRateServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/shippingRates'
    super(api, url)
    this.api = api
    this.url = url
  }
  bulkDelete = async payload => {
    return await this.api.post(`${this.url}/remove`, payload)
  }
  bulkClone = async payload => {
    return await this.api.post(`${this.url}/clone`, payload)
  }
  create(payload, shippingDetailId) {
    return this.api.post(`${this.url}/shippingDetail/${shippingDetailId}`, payload)
  }
  delete({ id, shippingDetailId }) {
    return this.api.del(`${this.url}/shippingDetail/${shippingDetailId}/${id}`)
  }
  fetchTable(payload, id) {
    const { shippingRateType = 'frpp' } = payload
    return this.api.get(`${this.url}/shippingDetail/${id}/${shippingRateType}`, payload)
  }
  getOrderRate(orderId) {
    return this.api.get(`${this.url}/order/${orderId}`)
  }
  reactSelectSearchShippingTypes = async payload => {
    return this.api.get(`${this.url}/shippingTypes`, payload)
  }
}

export default ShippingRateServices
