import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import Modal from 'components/Common/Modal'
import React from 'react'
import styled from 'styled-components'

export default class CategorySelectorModal extends React.Component {
  state = { dropdownOpen: false, selected: {} }
  handleToggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }
  handleSelect = selected => {
    this.setState({
      selected,
    })
  }
  handleConfirm = () => {
    const { onConfirm } = this.props
    onConfirm(this.state.selected)
  }
  render() {
    const { dropdownOpen, selected } = this.state
    const { list, isOpen, onToggle, isLoading } = this.props

    const getSub = list => {
      const mappedList = list.map(i => {
        let sub
        if (i.subCategories) {
          sub = getSub(i.subCategories)
        }
        return (
          <li key={i.id}>
            <DropdownItem active={selected.name === i.name} onClick={() => this.handleSelect(i)}>
              {i.name}
            </DropdownItem>
            {i.subCategories && <ul>{sub}</ul>}
          </li>
        )
      })
      return mappedList
    }
    return (
      <Modal
        open={isOpen}
        title={`Move Products`}
        confirmIcon={`save`}
        loading={isLoading}
        loadingMessage={`Saving please wait...`}
        onConfirm={this.handleConfirm}
        onClose={onToggle}
      >
        <DropdownStyled isOpen={dropdownOpen} toggle={this.handleToggle} className="form-group">
          <DropdownToggle
            tag="div"
            onClick={this.handleToggle}
            data-toggle="dropdown"
            aria-expanded={dropdownOpen}
            className="btn-toggle d-flex align-items-center form-control"
          >
            <span className="mr-auto">{selected.name ? selected.name : 'Select Category'}</span>
            <i className="icon ion-arrow-dropdown" />
          </DropdownToggle>

          <DropdownMenu>
            <ul>{getSub(list)}</ul>
          </DropdownMenu>
        </DropdownStyled>
      </Modal>
    )
  }
}

const DropdownStyled = styled(Dropdown)`
  .dropdown-menu {
    width: 100%;
    max-height: 50vh;
    overflow: auto;
    > ul {
      padding: 0;
      ul {
        list-style-type: none;
        padding-left: 1rem;
      }
    }
  }
  button {
    font-size: 14px;
  }
  .btn-toggle {
    padding: 0.5rem;
  }
  &.show {
    color: #495057;
    background-color: #fff;
    border-color: #ffe09c;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.25);
  }
`
