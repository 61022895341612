import React, { Component } from 'react'

import Form from './Form'

class OrderChargesAdd extends Component {
  render() {
    return <Form {...this.props} title="New Payment Surcharge" />
  }
}

export default OrderChargesAdd
