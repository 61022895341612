import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

class UserLoginModal extends Component {
  constructor(props) {
    super(props)
    const fields = {
      customer: {
        label: 'Customer',
        type: 'select',
        rules: 'required',
      },
      user: {
        label: 'User Login',
        type: 'select',
        rules: 'required',
      },
      action: {
        type: 'select',
        rules: 'required',
      },
    }
    this.form = new Form({ fields }, { name: 'UserLoginModalForm', handleSubmit: this.handleSubmit })
  }
  handleSubmit = () => {
    const { onClose } = this.props
    onClose()
  }
  render() {
    const { customerRepository, userRepository, open, onClose } = this.props
    return (
      <Modal
        open={open}
        title="Add Prohibited User Logins"
        onConfirm={() => this.buttonSubmit.click()}
        onClose={onClose}
        confirmLabel="Add"
      >
        <FormContainer onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })} plain>
          <ReactSelect
            field={this.form.$('customer')}
            serverSide
            search={customerRepository.reactSelectSearch}
            options={{
              isMulti: true,
              valueKey: 'id',
              labelKey: 'name',
              defaultOptions: true,
            }}
          />
          <ReactSelect
            field={this.form.$('user')}
            serverSide
            search={userRepository.reactSelectSearch}
            options={{
              isMulti: true,
              valueKey: 'id',
              labelKey: 'username',
              defaultOptions: true,
            }}
          />
          <RadioGroup
            field={this.form.$('action')}
            radios={[
              { value: 'blacklist', label: 'Add to Blacklist' },
              { value: 'whitelist', label: 'Add to Whitelist' },
            ]}
            className="mt-3"
            onChange={this.handleRadioChange}
          />
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default inject('customerRepository', 'userRepository')(observer(UserLoginModal))
