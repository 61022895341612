import React, { Component } from 'react'

import { Button, Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedTo, Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import StoreCountryModal from './StoreCountryModal'
import ToasterNotify from 'helpers/ToasterNotify'

class StoreCountryMappingPanel extends Component {
  table = null

  state = {
    isCountryModalOpen: false,
    selectedProducts: [],
    itemToDelete: {},
    selected: [],
    bulkAction: null,
  }
  toggleAddCountryModal = () => this.setState({ isCountryModalOpen: !this.state.isCountryModalOpen })
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this country?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }
  handleDelete = async () => {
    const { storeId, storeCountryRepository } = this.props
    await storeCountryRepository.delete({ storeId, countries: [{ id: this.state.itemToDelete.id }] }, () => {
      this.table.reload()
    })
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    if (bulkAction === 'Remove') {
      const { storeId, storeCountryRepository } = this.props
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected country/countries?',
        title: 'Warning',
        onConfirm: async () => {
          await storeCountryRepository.delete({ storeId, countries: selected.map(id => ({ id })) }, () => {
            this.table.reload()
          })
        },
      })
    }
  }
  render() {
    const { bulkAction, selected, isCountryModalOpen } = this.state
    const {
      storeCountryRepository: {
        CRUD: { errors = [] } = {},
        Table: { data = [], loading, totalRecordCount, originalData },
        fetchTable,
      },
      storeCountryRepository,
      storeId,
    } = this.props
    return (
      <Col sm={7}>
        <Card
          title="Country Mapping"
          selectElementId="storeCountryPanel"
          titleActions={
            <Visible>
              <IconButton color="dark" className="mb-1" outline onClick={this.toggleAddCountryModal} />
            </Visible>
          }
        >
          {errors.length > 0 && !isCountryModalOpen && (
            <div style={{ margin: '20px' }}>
              <ErrorsList errors={errors} />{' '}
            </div>
          )}
          <ReactTable
            multiSelect={allowedTo()}
            className="striped cell-vertical-center"
            columns={[
              { accessor: 'label', Header: 'Name', Cell: ({ original }) => original.label },
              {
                Header: 'Actions',
                headerClassName: 'justify-content-center',
                className: 'justify-content-center p-1',
                width: 100,
                show: allowedTo(),
                Cell: ({ original }) => <IconButton icon="ios-trash" onClick={() => this.confirmDeletion(original)} />,
              },
            ]}
            actions={
              <Visible>
                <div className="d-flex justify-content-end align-items-center mb-1">
                  <ReactSelectStyled
                    plain={true}
                    className="mr-2"
                    options={{
                      placeholder: 'Bulk Action',
                      options: [{ value: 'Remove', label: 'Remove' }],
                    }}
                    disabled={!selected.length}
                    onChange={vals => {
                      this.setState({ bulkAction: vals.value })
                    }}
                  />
                  <Button
                    size="sm"
                    color="dark"
                    type="button"
                    disabled={!bulkAction}
                    onClick={this.handleSubmitBulkAction}
                  >
                    Submit
                  </Button>
                </div>
              </Visible>
            }
            sort={{ id: 'label' }}
            data={data}
            loadData={params => fetchTable(params, storeId)}
            loading={loading}
            totalRecordCount={totalRecordCount}
            ref={ref => (this.table = ref)}
            search
            totalPages={originalData.totalPages}
            selectionChanged={this.handleSelected}
          />
        </Card>

        <StoreCountryModal
          storeId={storeId}
          storeProductRepository={storeCountryRepository}
          isModalOpen={this.state.isCountryModalOpen}
          toggleModal={this.toggleAddCountryModal}
          table={this.table}
        />
      </Col>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`

export default decorate(inject('storeRepository', 'storeCountryRepository')(observer(StoreCountryMappingPanel)), {})
