import { inject, observer } from 'mobx-react'
import React, { PureComponent } from 'react'

import { FormGroup } from 'reactstrap'
import ReactSelect from 'components/Common/Form/ReactSelect'

class ConfigurableOptionForm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      size: [],
      // color: [],
    }
    this.loadOptions('size')
    // this.loadOptions('color')
  }
  loadOptions = async attributeName => {
    const { data, productRepository } = this.props
    const fromAddProducts = !data.product.hasOwnProperty('product')
    const { data: res, errors } = await productRepository.getSelectAttribute(
      { attributeName },
      fromAddProducts ? data.product.productId : data.product.product.id
    )
    this.setState({
      ...this.state,
      [attributeName]: res && !errors.length ? res : [],
    })
  }
  render() {
    const { handleConfigureOptions, data } = this.props
    const defaultSizeSelected =
      (data && data.hasOwnProperty('attributes') && data.attributes.filter(d => d.attributeName === 'size')[0]) || {}
    // const defaultColorSelected =
    //  (data && data.hasOwnProperty('attributes') && data.attributes.filter(d => d.attributeName === 'color')[0]) || {}
    return (
      <div>
        <FormGroup>
          <label>Size</label>
          <ReactSelect
            customLabelKey={['attributeLabel', 'attributeValue']}
            options={{
              defaultValue: defaultSizeSelected,
              defaultOptions: true,
              isClearable: false,
              options: this.state.size,
              valueKey: 'attributeValue',
              labelKey: 'attributeLabel',
            }}
            onChange={vals => handleConfigureOptions(vals, 'size')}
            showTooltip
          />
        </FormGroup>
        {/* <FormGroup>
          <label>Color</label>
          <ReactSelect
            customLabelKey={['attributeLabel', 'attributeValue']}
            options={{
              defaultValue: defaultColorSelected,
              defaultOptions: true,
              isClearable: false,
              options: this.state.color,
              valueKey: 'attributeValue',
              labelKey: 'attributeLabel',
            }}
            onChange={vals => handleConfigureOptions(vals, 'color')}
            showTooltip
          />
        </FormGroup> */}
      </div>
    )
  }
}

export default inject('customerRepository', 'productRepository')(observer(ConfigurableOptionForm))
