import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
// import cx from 'classnames'

import {
  COTERM_PATH,
  ORDER_ADVANCED_SEARCH_PATH,
  ORDER_TYPE,
  ORDERS_PATH,
  QUOTATIONS_PATH,
  SUBSCRIPTIONS_PATH,
} from 'definitions'
import { formatCustomerCurrency, getFormattedDateTimeString, getFullName } from 'helpers/Formatters'
import ReactTable from 'components/Common/ReactTable'
import Status from 'components/Common/Status'

class TableResult extends Component {
  state = {
    loaded: false,
  }

  componentDidMount() {
    this.setState({ loaded: true })
  }

  render() {
    const {
      orderAdvanceSearchRepository: {
        Table: { data, loading, totalRecordCount, originalData },
      },
      searchRepository,
      pageKey,
      loadData,
    } = this.props
    const { loaded } = this.state

    return (
      <Row className="mb-4">
        <Col>
          <ReactTable
            searchRepository={searchRepository}
            pageKey={pageKey}
            enableRowLink
            getRowUrl={({ original }) => {
              if (original) {
                if (
                  original.quotationInd &&
                  ![ORDER_TYPE.CO_TERM, ORDER_TYPE.CO_TERM_SIMULATION].includes(original.orderType?.name)
                ) {
                  return `${QUOTATIONS_PATH}/${original.id}`
                } else {
                  switch (original.orderType?.name) {
                    case ORDER_TYPE.CO_TERM:
                    case ORDER_TYPE.CO_TERM_SIMULATION:
                      return `${COTERM_PATH}/${original.id}`
                    case ORDER_TYPE.CONTRACT:
                      return `${SUBSCRIPTIONS_PATH}/${original.id}`
                    default:
                      return `${ORDERS_PATH}/${original.id}`
                  }
                }
              }
              return ORDER_ADVANCED_SEARCH_PATH
            }}
            columns={[
              {
                headerClassName: 'px-0',
                accessor: 'orderSource',
                resizable: false,
                width: 20,
                getProps: (_, { original = {} } = {}) => {
                  let borderColor
                  if (
                    original.quotationInd &&
                    ![ORDER_TYPE.CO_TERM, ORDER_TYPE.CO_TERM_SIMULATION].includes(original.orderType?.name)
                  ) {
                    borderColor = 'border-success'
                  } else {
                    switch (original.orderType?.name) {
                      case ORDER_TYPE.CO_TERM:
                      case ORDER_TYPE.CO_TERM_SIMULATION:
                        borderColor = 'border-secondary'
                        break
                      case ORDER_TYPE.CONTRACT:
                        borderColor = 'border-dark'
                        break
                      default:
                        borderColor = 'border-primary'
                        break
                    }
                  }

                  return {
                    className: borderColor,
                    style: {
                      padding: 0,
                      borderLeft: '5px solid',
                    },
                  }
                },
                Cell: () => '',
              },
              {
                Header: 'Order No.',
                accessor: 'orderNumber',
                Cell: ({ original }) => original.orderNumber || original.tempOrderNumber || 'N/A',
              },
              {
                Header: 'Customer',
                accessor: 'customer.name',
                Cell: ({ original }) => (
                  <span title={original.customer.name} className="pr-1 ellipsis row-clickable">
                    {original.customer.name}
                  </span>
                ),
              },
              {
                Header: 'Store',
                accessor: 'store.name',
                className: 'd-flex align-items-center',
              },
              {
                Header: 'Purchased On',
                accessor: 'orderDate',
                Cell: ({ original }) => getFormattedDateTimeString(original.orderDate),
              },
              {
                Header: 'Bill To',
                accessor: 'billingAddress.firstName',
                Cell: ({ original }) => {
                  const fullName = getFullName(original.billingAddress)
                  return (
                    <span title={fullName} className="pr-1 ellipsis row-clickable">
                      {fullName}
                    </span>
                  )
                },
              },
              {
                Header: 'Ship To',
                accessor: 'shippingAddress.firstName',
                Cell: ({ original }) => {
                  const fullName = getFullName(original.shippingAddress)
                  return (
                    <span title={fullName} className="pr-1 ellipsis row-clickable">
                      {fullName}
                    </span>
                  )
                },
              },
              {
                Header: 'Currency',
                accessor: 'store.currency',
                headerClassName: 'justify-content-center',
                className: 'justify-content-center',
                width: 80,
              },
              {
                Header: 'Amount',
                accessor: 'orderSummary.grandTotal',
                headerClassName: 'justify-content-end',
                className: 'justify-content-end',
                width: 120,
                Cell: ({ original }) => formatCustomerCurrency(original.orderSummary.grandTotal, original.customer),
              },
              {
                Header: 'Status',
                accessor: 'status',
                headerClassName: 'justify-content-center',
                className: 'justify-content-center p-1',
                width: 200,
                Cell: ({ original }) => <Status status={original.status} />,
              },
            ]}
            sort={{ id: 'createdDate', desc: true }}
            data={toJS(data)}
            {...(loaded ? { loadData } : {})}
            loading={loading}
            totalRecordCount={totalRecordCount}
            totalPages={originalData.totalPages}
            search={false}
            className="striped cell-vertical-center horizontal-scroll mb-2"
            searchClassName="col-4"
            actionsClassName="col-8"
          />
        </Col>
      </Row>
    )
  }
}

export default inject('orderAdvanceSearchRepository', 'searchRepository')(observer(TableResult))
