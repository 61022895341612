import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'

import { getFormattedDateTimeString } from 'helpers/Formatters'
import { USER_ROLE_ACCESS_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

class UserRoleAccess extends React.Component {
  handleCreate = () => {
    this.props.history.push(`${USER_ROLE_ACCESS_PATH}/new`)
  }
  render() {
    const {
      userRoleAccessRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="User Role Access" breadcrumbLabel="User Role Access">
        <ReactTable
          searchRepository={searchRepository}
          pageKey="user-role-access"
          enableRowLink
          getRowUrl={({ original }) => `${USER_ROLE_ACCESS_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'role',
              Header: 'Role',
            },
            {
              accessor: 'createdDate',
              Header: 'Created Date',
              Cell: ({ value }) => <span className="row-clickable">{getFormattedDateTimeString(value)}</span>,
            },
            {
              accessor: 'lastModifiedDate',
              Header: 'Last Modified Date',
              Cell: ({ value }) => <span className="row-clickable">{getFormattedDateTimeString(value)}</span>,
            },
            {
              accessor: 'lastModifiedBy',
              Header: 'Last Modified By',
            },
          ]}
          sort={{ id: 'role' }}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          actions={
            <Visible>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Add New User Role Access" />
              </div>
            </Visible>
          }
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('userRoleAccessRepository')(observer(UserRoleAccess)), {})
