/* @flow */
import { inject, observer } from 'mobx-react'
import OrderForm from './OrderForm/'
import React, { Component } from 'react'

class OrderAdd extends Component {
  constructor(props) {
    super(props)
    this.props.orderRepository.reset()
  }
  render() {
    return <OrderForm {...this.props} title="Create New Order" step={1} />
  }
}

export default inject('orderRepository')(observer(OrderAdd))
