/* @flow */
const getIconClassNameFromFile = (file: File): string => {
  const fileType = (file.name || file.fileName).split('.').slice(-1)
  switch (fileType) {
    case 'png':
    case 'jpg':
    case 'bmp':
    case 'gif':
      return 'photo'

    case 'mp3':
    case 'wav':
    case 'mpeg-4':
      return 'musical-notes'

    case 'avi':
    case 'flv':
    case 'mwv':
    case 'mp4':
      return 'videocam'

    case 'zip':
    case 'rar':
    case 'tar.gz':
    case 'gzip':
    case 'iso':
      return 'folder'

    case 'php':
    case 'js':
    case 'css':
    case 'html':
    case 'htm':
    case 'java':
    case 'py':
    case 'scss':
    case 'json':
    case 'sql':
      return 'code'

    case 'doc':
    case 'docx':
      return 'document'

    case 'xls':
    case 'xlsx':
      return 'document'

    case 'pdf':
      return 'document'

    case 'txt':
    case 'plain':
      return 'document'

    default:
      return 'document'
  }
}

export default getIconClassNameFromFile
