import { Button, Col, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { PRODUCT_NATURE_PHYSICAL, PRODUCT_TYPE_ACCESSORY, PRODUCTS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'

import CustomOptionsModal from './CustomOptionsModal'
import ProductInfo from './ProductInfo'
import StorePrices from './StorePrices'

class ProductAddForm extends Component {
  state = {
    step: 1,
    skipPromptOnExit: false,
    isCustomOptionsModalOpen: false,
  }
  constructor(props) {
    super(props)

    const rules = 'required'
    const fields = {
      name: {
        label: 'Product Name',
        rules,
      },
      productCode: {
        label: 'Product Code',
        rules,
      },
      productNature: {
        label: 'Product Nature',
        rules,
      },
      productType: {
        type: 'select',
        label: 'Product Type',
        rules,
      },
      productSeries: {
        type: 'select',
        label: 'Product Series',
        rules,
      },
      prices: {
        value: [],
      },
      addAsSingleProductInd: {
        type: 'checkbox',
        label: 'Add as Single Product',
        value: false,
      },
      customOptions: {
        value: [],
      },
    }
    this.form = new Form({ fields }, { name: 'ProductForm', handleSubmit: this.handleSubmit })
  }
  componentDidMount() {
    this.props.productRepository.clearCrudData()
  }
  displayStorePrice = () => {
    if (this.form.isValid) {
      this.state.step !== 2 && this.setState({ step: 2 })
    } else {
      this.form.showErrors(true)
    }
  }
  handleAddAsSingleProductChanged = event => {
    if (this.state.step === 2) {
      if (event.target.value) {
        this.setState({ step: 1 })
      }
    }
  }
  handleCancel = () => {
    this.props.history.push(PRODUCTS_PATH)
  }
  handleSubmit = () => {
    const { customOptions, productCode, prices, ...rest } = this.form.values()
    const payload = {
      ...rest,
      productCode: productCode?.replace(/\s/g, '').toUpperCase(),
      customOptions: this.isAddCustomOptions() ? customOptions : [],
      prices: prices.map(item => ({
        price: {
          amount: item.price,
          currency: item.currency,
        },
        store: {
          id: item.storeId,
          name: item.storeName,
          currency: item.currency,
        },
      })),
    }

    this.props.productRepository.create(payload, result => {
      this.setState({ skipPromptOnExit: true, isCustomOptionsModalOpen: false })
      this.loadProduct(result.id)
    })
  }
  handleNext = () => {
    const productNature = this.form.$('productNature').value && this.form.$('productNature').value.value
    const addAsSingleProductInd = this.form.$('addAsSingleProductInd').value
    if (!addAsSingleProductInd && productNature === PRODUCT_NATURE_PHYSICAL) {
      this.form.validate({ showErrors: true }).then(({ isValid }) => {
        isValid && this.handleSmartAdd()
      })
    } else {
      this.handleSubmit()
    }
  }
  handleSmartAdd = async () => {
    const { productRepository } = this.props
    const name = this.form.$('name').value
    const productCode = this.form.$('productCode').value

    await productRepository.getSmartAddCustomOptions({ name, productCode })

    if (productRepository.CustomOptions.data && !productRepository.CustomOptions.errors.length) {
      this.toggleCustomOptionsModal()
    }
  }
  handleOnChangeProductType = newProductType => {
    if (newProductType?.value === PRODUCT_TYPE_ACCESSORY) {
      this.form.$('addAsSingleProductInd').set(true)
    } else {
      const productNature = this.form.$('productNature').value && this.form.$('productNature').value.value
      if (productNature === PRODUCT_NATURE_PHYSICAL) {
        this.form.$('addAsSingleProductInd').set(false)
      }
    }
  }
  loadProduct = id => {
    this.props.history.push(`${PRODUCTS_PATH}/${id}?status=new`)
  }
  toggleCustomOptionsModal = () => this.setState({ isCustomOptionsModalOpen: !this.state.isCustomOptionsModalOpen })
  isAddCustomOptions = () => {
    const productNature = this.form.$('productNature').value
      ? this.form.$('productNature').value.value
      : PRODUCT_NATURE_PHYSICAL
    const isAddCustomOptions = productNature === PRODUCT_NATURE_PHYSICAL && !this.form.$('addAsSingleProductInd').value
    return isAddCustomOptions
  }
  render() {
    const {
      productRepository: {
        CRUD: { errors = [], loading, submitting } = {},
        CustomOptions: { errors: coErrors = [], loading: coLoading } = {},
      },
      commonRepository,
      storeRepository,
    } = this.props

    const showNextButton = this.isAddCustomOptions()

    return (
      <ContentContainer
        title="Create New Product"
        promptOnExit={submitting || (this.form.isDirty && !this.state.skipPromptOnExit)}
        promptOnExitIsConfirm={!submitting}
      >
        <FormContainer
          title="Product Information"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={this.state.isCustomOptionsModalOpen ? null : [...errors, ...coErrors]}
          plain
        >
          <Row>
            <Col xs="5">
              <ProductInfo
                form={this.form}
                commonRepository={commonRepository}
                onChangeProductType={this.handleOnChangeProductType}
              />
              <div className="d-flex justify-content-end">
                <FormActions
                  loading={!this.state.isCustomOptionsModalOpen && (loading || coLoading)}
                  loadingMessage="Please wait..."
                  confirmLabel="Next"
                  cancelLabel="Cancel"
                  onConfirm={this.displayStorePrice}
                  onCancel={this.handleCancel}
                  hideConfirm={!allowedToAppAdminRole()}
                  disabled={this.state.step === 2}
                />
              </div>
            </Col>
            {this.state.step === 2 && (
              <Col xs="7" className="d-flex flex-column">
                <StorePrices form={this.form} storeRepository={storeRepository} />
                <div className="d-flex justify-content-end">
                  {showNextButton && (
                    <Button color="primary" onClick={this.handleNext} disabled={loading}>
                      Next
                    </Button>
                  )}
                  {!showNextButton && (
                    <Button color="primary" onClick={this.handleSubmit} disabled={loading}>
                      Save
                    </Button>
                  )}
                </div>
              </Col>
            )}
          </Row>
        </FormContainer>
        {this.state.isCustomOptionsModalOpen && (
          <CustomOptionsModal
            productRepository={this.props.productRepository}
            productForm={this.form}
            onClose={this.toggleCustomOptionsModal}
            onConfirm={this.handleSubmit}
          />
        )}
      </ContentContainer>
    )
  }
}

export default decorate(
  inject('productRepository', 'commonRepository', 'storeRepository')(observer(ProductAddForm)),
  {}
)
