import { Input } from 'reactstrap'
import styled from 'styled-components'

import ReactSelect from 'components/Common/Form/ReactSelect'

export const InputStyled = styled(Input)`
  &&& {
    font-size: 12px;
    border: none !important;
    height: 35px;
  }
`

export const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: 100%;
      border: none !important;

      .react-select__indicator {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      .react-select__multi-value__label {
        padding: 0 4px;
      }

      .react-select__value-container:not(.react-select__value-container--is-focused) {
        max-height: 96px;
        overflow-y: auto;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          margin: 8px 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`

export const OptionStyled = styled.div`
  &&& {
    display: flex;
    align-items: center;
    .badge-icon {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      margin-right: 6px;
      border: 1px solid ${props => props.theme.dark};
      background: ${props => props.theme[props.color]};
      &.dashed {
        background: ${props =>
          `repeating-linear-gradient(-45deg, ${props.theme[props.color]}, ${
            props.theme[props.color]
          } 50%, #ffffff 50%, #ffffff)`};
      }
    }
  }
`

export const RowColorCellStyled = styled.div`
  &&& {
    height: 100%;
    &:before {
      content: '';
      display: block;
      background: ${props => props.theme[props.color]};
      width: 5px;
      height: 100%;
    }
    &.dashed {
      &:before {
        background: ${props =>
          `repeating-linear-gradient(-45deg, ${props.theme[props.color]}, ${
            props.theme[props.color]
          } 8px, #ffffff 8px, #ffffff 16px)`};
      }
    }
  }
`
