/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class CustomerServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/customers'
    super(api, url)
    this.api = api
    this.url = url
  }

  getDefaultStore(id) {
    return this.api.get(`${this.url}/${id}/store`)
  }

  bulkUpdateStatus(payload) {
    return this.api.post(`${this.url}/status`, payload)
  }
}

export class CustomerAddressServices extends CustomerServices {
  fetchTable(payload, id) {
    return this.api.get(`${this.url}/${id}/addresses`, payload)
  }

  create(payload, id) {
    return this.api.post(`${this.url}/${id}/addresses`, payload)
  }

  patch(payload, id) {
    return this.api.patch(`${this.url}/${id}/addresses/${payload.id}`, payload)
  }

  delete({ customerId, id }) {
    return this.api.del(`${this.url}/${customerId}/addresses/${id}`)
  }
}

export class CustomerContactServices extends CustomerServices {
  fetchTable(payload, id) {
    return this.api.get(`${this.url}/${id}/contacts`, payload)
  }

  create(payload, id) {
    return this.api.post(`${this.url}/${id}/contacts`, payload)
  }

  patch(payload, id) {
    return this.api.patch(`${this.url}/${id}/contacts/${payload.id}`, payload)
  }

  delete({ customerId, id }) {
    return this.api.del(`${this.url}/${customerId}/contacts/${id}`)
  }
}

export class CustomerUserLoginServices extends CustomerServices {}
export class CustomerSpecialProductServices extends CustomerServices {
  fetchTable(payload, id) {
    return this.api.get(`${this.url}/${id}/specialProducts`, payload)
  }

  fetchProductTable(payload, id) {
    return this.api.get(`${this.url}/${id}/specialProducts/available`, payload)
  }

  create(payload, id) {
    return this.api.post(`${this.url}/${id}/specialProducts`, payload)
  }

  delete({ customerId, ...payload }) {
    return this.api.post(`${this.url}/${customerId}/specialProducts/remove`, payload)
  }
}

export default CustomerServices
