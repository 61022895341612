import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

import { getView } from './columns'
import Actions from './Actions'
import CountryModal from './CountryModal'
import CustomerModal from './CustomerModal'
import Filters from './Filters'
import UserLoginModal from './UserLoginModal'

const pageKey = 'prohibited-items'

class ProhibitedItemsTable extends Component {
  filters = {
    access: 'all',
  }
  selection = []
  constructor(props) {
    super(props)

    const { searchRepository } = this.props
    const defaultView = searchRepository.Pages[pageKey]?.view

    this.state = {
      view: getView({
        key: defaultView || 'country',
        handleBulkUpdate: this.handleBulkUpdate,
        allowedToAppAdminRole: allowedToAppAdminRole,
      }),
      isCountryModalOpen: false,
      isCustomerModalOpen: false,
      isUserModalOpen: false,
    }

    const defaultFilters = searchRepository.Pages[`${pageKey}-${defaultView}`]?.filters
    if (defaultFilters) {
      this.filters = defaultFilters
    }
  }
  handleBulkUpdate = async (selection, key, value) => {
    if (selection.length) {
      const payload = {
        [key]: value,
        prohibitedItems: selection.map(id => ({ id })),
      }
      await this.props.prohibitedItemRepository.bulkPost(key, payload)
      this.reloadTable()
    } else {
      ToasterNotify.alert({ message: 'Please select products to update', color: 'info' })
    }
  }
  handleBulkDelete = async selection => {
    if (selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to delete?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = { prohibitedItems: selection.map(id => ({ id })) }
          await this.props.prohibitedItemRepository.bulkPost('remove', payload)
          this.reloadTable()
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select products first', color: 'info' })
    }
  }
  handleFilter = (key, value) => {
    if (value) {
      this.filters[key] = value
    } else {
      delete this.filters[key]
    }

    this.props.searchRepository.updateSearchPage(`${pageKey}-${this.state.view.key}`, { filters: this.filters })
    this.reloadTable()
  }
  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => key) : []
  }
  handleChangeView = key => {
    const { prohibitedItemRepository } = this.props
    const view = getView({
      key,
      handleBulkUpdate: this.handleBulkUpdate,
      allowedToAppAdminRole: allowedToAppAdminRole,
    })

    prohibitedItemRepository.Table.data = []
    if (view.key === 'country') {
      this.filters.access = 'all'
    }
    this.props.searchRepository.updateSearchPage(pageKey, {
      page: 1,
      view: view.key,
    })
    this.setState({ view })
  }
  reloadTable = reset => {
    this.table && this.table.reload(reset)
  }
  toggleModal = () => {
    const { view } = this.state
    let key

    switch (view.key) {
      case 'customer':
        key = 'isCustomerModalOpen'
        break
      case 'user':
        key = 'isUserModalOpen'
        break
      default:
        key = 'isCountryModalOpen'
        break
    }

    this.setState({ [key]: !this.state[key] })
  }
  render() {
    const { view } = this.state
    const { prohibitedItemRepository, searchRepository } = this.props
    const {
      BulkAction: { loading: bulkActionLoading, errors },
      Table: { data, loading, totalRecordCount, originalData },
      fetchTable,
    } = prohibitedItemRepository

    const tableKey = `${pageKey}-${view.key}`

    return (
      <ContentContainer
        title="Prohibited Items"
        actions={
          <Visible toAppAdminRole>
            <IconButton title="Add Items" color="dark" onClick={this.toggleModal} />
          </Visible>
        }
        promptOnExit={this.props.prohibitedItemRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        {!!errors.length && <ErrorsList errors={this.state.errors} />}
        <Filters
          selectedView={view}
          selected={this.filters.access}
          handleChangeView={this.handleChangeView}
          handleFilter={this.handleFilter}
        />
        <ReactTable
          key={tableKey}
          searchRepository={searchRepository}
          pageKey={tableKey}
          multiSelect={allowedToAppAdminRole()}
          className="striped cell-vertical-center"
          fixedColumns
          columns={[
            ...view.columns,
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 150,
              show: allowedToAppAdminRole(),
              Cell: ({ original }) => (
                <IconButton icon="ios-trash" onClick={() => this.handleBulkDelete([original.id])} />
              ),
              sortable: false,
            },
          ]}
          sort={view.sort}
          actions={
            <Visible toAppAdminRole>
              <Actions
                view={view}
                handleBulkDelete={() => this.handleBulkDelete(this.selection)}
                handleBulkUpdate={(key, value) => this.handleBulkUpdate(this.selection, key, value)}
              />
            </Visible>
          }
          data={toJS(data)}
          loadData={params => fetchTable({ ...params, ...this.filters }, view.key)}
          loading={bulkActionLoading || loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          ref={ref => (this.table = ref)}
          selectionChanged={this.handleSelectionChanged}
        />
        {this.state.isCountryModalOpen && (
          <CountryModal
            prohibitedItemRepository={prohibitedItemRepository}
            open={this.state.isCountryModalOpen}
            onClose={this.toggleModal}
            reloadTable={this.reloadTable}
          />
        )}
        {this.state.isCustomerModalOpen && (
          <CustomerModal
            prohibitedItemRepository={prohibitedItemRepository}
            open={this.state.isCustomerModalOpen}
            onClose={this.toggleModal}
            reloadTable={this.reloadTable}
          />
        )}
        {this.state.isUserModalOpen && (
          <UserLoginModal
            prohibitedItemRepository={prohibitedItemRepository}
            open={this.state.isUserModalOpen}
            onClose={this.toggleModal}
            reloadTable={this.reloadTable}
          />
        )}
      </ContentContainer>
    )
  }
}

export default inject('prohibitedItemRepository', 'searchRepository')(observer(ProhibitedItemsTable))
