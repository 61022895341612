/* eslint-disable max-len */
import React from 'react'

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.6063 22.8938H20.475V20.4376H23.6063V17.2876H26.0813V20.4563H29.2313V22.9126H26.0813V26.0626H23.6063V22.8938V22.8938Z"
      fill="white"
    />
    <path
      d="M4.68749 6.76914C3.93749 6.52539 3.13124 6.58164 2.41874 6.93789C1.70624 7.29414 1.18124 7.91289 0.918742 8.66289C0.412492 10.2379 1.25624 11.9254 2.81249 12.4504C3.11249 12.5441 3.43124 12.6004 3.74999 12.6004C4.21874 12.6004 4.66874 12.4879 5.08124 12.2816C5.79374 11.9254 6.31874 11.3066 6.58124 10.5566C7.08749 9.00039 6.24374 7.29414 4.68749 6.76914Z"
      fill="white"
    />
    <path
      d="M11.4375 4.12525C10.7812 3.919 10.0875 3.9565 9.46874 4.27525C8.84999 4.594 8.38124 5.119 8.17499 5.77525C7.72499 7.144 8.45624 8.6065 9.82499 9.0565C10.0875 9.15025 10.3687 9.18775 10.6312 9.18775C11.025 9.18775 11.4187 9.094 11.7937 8.9065C12.4125 8.58775 12.8812 8.06275 13.0875 7.4065C13.5375 6.03775 12.8062 4.57525 11.4375 4.12525Z"
      fill="white"
    />
    <path
      d="M17.9437 4.06899C17.4 3.88149 16.8 3.91899 16.2937 4.20024C15.7687 4.46274 15.3937 4.91274 15.2062 5.45649C15.0187 6.00024 15.075 6.60024 15.3375 7.10649C15.6 7.63149 16.05 8.00649 16.5937 8.19399C16.8187 8.26899 17.0437 8.30649 17.2687 8.30649C17.6062 8.30649 17.925 8.23149 18.225 8.08149C18.75 7.81899 19.125 7.36899 19.3125 6.82524C19.5 6.28149 19.4437 5.70024 19.1812 5.17524C18.9375 4.63149 18.4875 4.25649 17.9437 4.06899Z"
      fill="white"
    />
    <path
      d="M22.6125 7.96887C21.7125 7.66887 20.7375 8.15637 20.4375 9.05637C20.2875 9.48762 20.325 9.95637 20.5312 10.3689C20.7375 10.7814 21.0937 11.0814 21.525 11.2314C21.7125 11.2876 21.8812 11.3251 22.0687 11.3251C22.7812 11.3251 23.4562 10.8751 23.7 10.1439C24 9.24387 23.5125 8.26887 22.6125 7.96887Z"
      fill="white"
    />
    <path
      d="M25.2 12.8252C24.9375 12.7314 24.6375 12.7502 24.375 12.8814C24.1125 13.0127 23.925 13.2377 23.8312 13.5189C23.6437 14.0814 23.9625 14.7002 24.525 14.8877C24.6375 14.9252 24.75 14.9439 24.8625 14.9439C25.3125 14.9439 25.7437 14.6627 25.8937 14.1939C26.0812 13.6314 25.7625 13.0127 25.2 12.8252Z"
      fill="white"
    />
    <path
      d="M23.6062 22.8938H20.475V20.4376H23.6062V17.2876H26.0812V20.4563H29.2312V22.9126H26.0812V26.0626H23.6062V22.8938V22.8938Z"
      fill="white"
    />
    <path
      d="M9.69376 12.9751H18.2063V15.7126H12.7125V18.2251H17.0625V20.9251H12.7125V23.2876H18.6V26.0251H9.69376V12.9751Z"
      fill="white"
    />
  </svg>
)
