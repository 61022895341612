/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedTo, Visible } from 'helpers/Auth'
import { STORES_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

const pageKey = 'stores'

class Stores extends Component {
  filters = {
    showDisabled: true,
  }
  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const defaultFilters = searchRepository.Pages[pageKey]?.filters
    if (defaultFilters) {
      this.filters = defaultFilters
    }
  }
  handleChangeStatus = (id, active) => {
    const payload = { id, status: active ? 'Active' : 'Inactive' }
    this.props.storeRepository.patch(payload, () => this.table.reload())
  }
  handleCreate = () => this.props.history.push(`${STORES_PATH}/new`)
  handleShowDisabledStores = showDisabled => {
    this.filters.showDisabled = showDisabled

    this.props.searchRepository.updateSearchPage(pageKey, { filters: this.filters })
    this.table && this.table.reload()
  }
  render() {
    const {
      storeRepository: {
        CRUD: { loading: crudLoading, errors: crudErrors },
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="Stores">
        {!!crudErrors.length && <ErrorsList errors={crudErrors} />}
        <ReactTable
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) => `${STORES_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'name', Header: 'Name' },
            { accessor: 'website', Header: 'Website' },
            {
              accessor: 'currency',
              Header: 'Currency',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 120,
            },
            {
              accessor: 'status',
              Header: 'Status',
              width: 120,
              headerClassName: 'pl-4',
              className: 'pl-4',
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  label={original.status || 'Inactive'}
                  id={`switchStoreStatus${original.id}`}
                  checked={original.status === 'Active'}
                  {...(allowedTo()
                    ? { onChange: event => this.handleChangeStatus(original.id, event.target.checked) }
                    : {})}
                  onClick={event => event.preventDefault()}
                  disabled={!allowedTo()}
                />
              ),
            },
          ]}
          actions={
            <div className="d-flex justify-content-end align-items-center">
              <CustomInput
                type="switch"
                label="Show Disabled"
                id="showDisabledStores"
                className="mr-3"
                defaultChecked={this.filters.showDisabled}
                onChange={event => this.handleShowDisabledStores(event.target.checked)}
              />
              <Visible>
                <IconButton onClick={this.handleCreate} color="light-dark" title="Create new store" />
              </Visible>
            </div>
          }
          sort={{ id: 'name' }}
          data={data}
          loadData={params => fetchTable({ ...params, ...this.filters })}
          loading={crudLoading || loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          search
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('storeRepository', 'searchRepository')(observer(Stores)), {})
