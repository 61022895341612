/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { PROFILE_PATH, USERS_PATH } from 'definitions'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'
import UserForm from './UserForm'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class UserEdit extends Component {
  constructor(props) {
    super(props)
    props.userRepository.getById(props.match.params.userId)
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  render() {
    const {
      userRepository: {
        CRUD: { errors, data, loading },
      },
      location,
    } = this.props

    let component

    if (loading && !data.id) {
      component = <Loader />
    } else {
      const isMyProfile = location.pathname.startsWith(PROFILE_PATH)

      component = (
        <React.Fragment>
          <ContentContainer
            title={isMyProfile ? 'Edit My Profile' : 'Edit User'}
            errors={errors}
            breadcrumbs={
              isMyProfile
                ? [
                    {
                      label: 'Home',
                      href: '/',
                    },
                    {
                      label: data.fullName || '',
                    },
                  ]
                : [
                    {
                      label: 'Home',
                      href: '/',
                    },
                    {
                      label: 'Users',
                      href: USERS_PATH,
                    },
                    {
                      label: data.fullName || '',
                    },
                  ]
            }
          >
            <UserForm {...this.props} data={data} isMyProfile={isMyProfile} />
          </ContentContainer>
          <Drawer
            ref={ref => (this.drawer = ref)}
            tabs={[
              {
                key: 'logs',
                icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
                content: <ActivityLogSidePanel entity="User" entityId={data.id} closeDrawer={this.handleCloseDrawer} />,
              },
              {
                key: 'comments',
                icon: <img src={commentsIcon} alt="Comments" />,
                content: (
                  <CommentSidePanel referenceId={data.id} commentSource="user" closeDrawer={this.handleCloseDrawer} />
                ),
              },
            ]}
          />
        </React.Fragment>
      )
    }

    return component
  }
}

export default decorate(inject('userRepository')(observer(UserEdit)), {})
