import React from 'react'

import { CustomInput } from 'reactstrap'
import { observer } from 'mobx-react'

import Modal from 'components/Common/Modal'

export default observer(({ view, open, onConfirm, onClose }) => {
  return (
    <Modal
      title="Display Options"
      size={view.columns.length > 15 ? 'md' : 'sm'}
      open={open}
      onConfirm={() => onConfirm(view)}
      onClose={onClose}
    >
      <div className="d-flex flex-wrap">
        {view.columns.map((column, index) => {
          return (
            <CustomInput
              key={index}
              type="checkbox"
              id={`columnChooser${column.label || column.Header}`}
              label={column.label || column.Header}
              defaultChecked={column.show === undefined ? true : column.show}
              onChange={event => {
                column.show = event.target.checked
              }}
              className={view.columns.length > 15 ? 'w-50' : 'w-100'}
            />
          )
        })}
      </div>
    </Modal>
  )
})
