import React, { PureComponent } from 'react'

import { Alert, Col, Row } from 'reactstrap'
import cx from 'classnames'

class ErrorsList extends PureComponent {
  render() {
    const { errors = [], className, alertClassName } = this.props
    return (
      <Row className={cx(className)}>
        <Col sm="12">
          <Alert color="danger" className={cx('mb-2 white-space-break-spaces', alertClassName)}>
            {errors.map((error, index) => {
              return (
                <p
                  key={index}
                  dangerouslySetInnerHTML={{ __html: error.message }}
                  className="mb-1"
                  style={{ fontSize: '80%' }}
                />
              )
            })}
          </Alert>
        </Col>
      </Row>
    )
  }
}

export default ErrorsList
