import { Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import { AUTO_RENEWAL_PATH } from 'definitions'
import AutoRenewalProducts from './AutoRenewalProducts'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import ToasterNotify from 'helpers/ToasterNotify'

class AutoRenewalForm extends React.PureComponent {
  constructor(props) {
    super(props)
    const { data: { serialNumber, customer, email, orderNumbers, subscriptionProducts } = {} } = props

    const fields = {
      serialNumber: {
        label: 'Serial Number',
        value: serialNumber,
      },
      customer: {
        label: 'Customer',
        value: customer,
      },
      email: {
        label: 'Email',
        value: email,
      },
      orderNumber: {
        label: 'Order Numbers',
        value: orderNumbers,
      },
      subscriptionProducts: {
        label: 'Product',
        value: subscriptionProducts
          ? subscriptionProducts
              .filter(item => !item.overwrittenBySFCUnlimited)
              .map(({ autoRenewInd, id, productCode, productType, renewalDate, referralCode, remainingData }) => ({
                autoRenewInd,
                id,
                productCode,
                productType,
                renewalDate,
                referralCode,
                remainingData,
              }))
          : [],
      },
    }
    this.form = new Form({ fields }, { name: 'AutoRenewalForm', handleSubmit: this.handleSubmit })
    this.saving = false

    this.state = {
      validationErrors: [],
    }

    this.fetchDevice()
  }

  fetchDevice = () => {
    const {
      deviceManagementRepository: { reactSelectSearch },
      data,
    } = this.props

    reactSelectSearch(data.id)
  }

  handleSubmit = () => {
    if (this.validateAutoRenewal() && this.validateSelection()) {
      this.doSubmit()
    }
  }

  doSubmit = () => {
    const actualValues = this.form.values()
    const {
      deviceManagementRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props

    const requestAction = isAdd ? create : patch
    this.saving = true
    delete actualValues.orderNumber
    requestAction(
      {
        ...actualValues,
        ...(actualValues.warrantyProduct && {
          warrantyProduct: {
            id: actualValues.warrantyProduct.productCode.id,
          },
        }),
        id,
      },
      result => result && this.props.history.push(AUTO_RENEWAL_PATH)
    )
  }

  validateAutoRenewal = () => {
    this.setState({ validationErrors: [] })
    const selectedSubscriptionProducts = this.form.$('subscriptionProducts').values()

    if (!selectedSubscriptionProducts.length) {
      this.setState({
        validationErrors: [{ message: 'Please select at least 1 product' }],
      })
      return false
    }
    return true
  }

  validateSelection = () => {
    const data = this.props.deviceManagementRepository.CRUD.data
    const selectedSubscriptionProducts = this.form.$('subscriptionProducts').values()

    const originalProductTypes = data.subscriptionProducts.map(item => item.productType)
    const selectedProductTypes = selectedSubscriptionProducts
      .filter(item => originalProductTypes.includes(item.productType))
      .map(item => item.productType)

    if (originalProductTypes.length !== selectedProductTypes.length) {
      ToasterNotify.confirm({
        message:
          // eslint-disable-next-line max-len
          'Are you sure to remove product selection in the auto-renewal record? When all product is unselected, this renewal category will no longer exist in display?',
        confirmText: 'Proceed',
        onConfirm: this.doSubmit,
      })

      return false
    }
    return true
  }

  backToIndex = () => {
    this.props.history.push(AUTO_RENEWAL_PATH)
  }

  renderSerialNumber = () => {
    const { data } = this.props
    const label = this.form.$('serialNumber').label

    if (data.status === 'UNDER_RMA') {
      return (
        <ReadOnlyField
          value={`${data.serialNumber} (under RMA${data.newSerialNumber ? ` | new SN: ${data.newSerialNumber})` : ')'}`}
          label={label}
        />
      )
    } else {
      if (data.oldSerialNumber) {
        return <ReadOnlyField value={`${data.serialNumber} (old SN: ${data.oldSerialNumber})`} label={label} />
      } else {
        return <ReadOnlyField value={data.serialNumber} label={label} />
      }
    }
  }

  render() {
    const {
      deviceManagementRepository: {
        CRUD: { data: { id, customer, subscriptionProducts = [] }, errors, loading } = {},
        Select,
      },
    } = this.props
    const { validationErrors } = this.state

    return (
      <Col xl={6}>
        <FormContainer
          title="Product Details"
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
          errors={errors.length ? errors : validationErrors}
          actions={
            <FormActions
              variant="contained"
              loading={loading}
              loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
              confirmLabel="Save"
              cancelLabel={id ? 'Close' : 'Cancel'}
              onCancel={this.backToIndex}
              hideConfirm={!allowedToAppAdminRole()}
            />
          }
        >
          {this.renderSerialNumber()}
          <ReadOnlyField value={customer.name} label="Customer" />
          <InputText field={this.form.$('email')} readOnly />
          <InputText type="textarea" colSpan={5} field={this.form.$('orderNumber')} readOnly />
          <AutoRenewalProducts
            field={this.form.$('subscriptionProducts')}
            subscriptionProducts={subscriptionProducts}
            options={Select.data}
            loading={Select.loading}
            validateAutoRenewal={this.validateAutoRenewal}
          />
        </FormContainer>
      </Col>
    )
  }
}

export default decorate(inject('deviceManagementRepository', 'productRepository')(observer(AutoRenewalForm)), {})
