/* @flow */
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import ContentContainer from 'components/Common/ContentContainer'
import OrderTypeForm from './OrderTypeForm'

class OrderTypeAdd extends Component {
  componentWillUnmount() {
    this.props.orderTypeRepository.clearCrudData()
    this.props.orderTypeRepository.clearErrorMessages()
  }

  render() {
    return (
      <ContentContainer
        title="Add New Order Type"
        promptOnExit={this.props.orderTypeRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <OrderTypeForm {...this.props} isAdd={true} />
      </ContentContainer>
    )
  }
}

export default inject('orderTypeRepository')(observer(OrderTypeAdd))
