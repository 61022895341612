/* eslint-disable max-len */
import React from 'react'

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.6875 6.76914C3.9375 6.52539 3.13125 6.58164 2.41875 6.93789C1.70625 7.29414 1.18125 7.91289 0.91875 8.66289C0.4125 10.2379 1.25625 11.9254 2.8125 12.4504C3.1125 12.5441 3.43125 12.6004 3.75 12.6004C4.21875 12.6004 4.66875 12.4879 5.08125 12.2816C5.79375 11.9254 6.31875 11.3066 6.58125 10.5566C7.0875 9.00039 6.24375 7.29414 4.6875 6.76914Z"
      fill="white"
    />
    <path
      d="M11.4375 4.12525C10.7812 3.919 10.0875 3.9565 9.46875 4.27525C8.85 4.594 8.38125 5.119 8.175 5.77525C7.725 7.144 8.45625 8.6065 9.825 9.0565C10.0875 9.15025 10.3687 9.18775 10.6312 9.18775C11.025 9.18775 11.4187 9.094 11.7937 8.9065C12.4125 8.58775 12.8812 8.06275 13.0875 7.4065C13.5375 6.03775 12.8062 4.57525 11.4375 4.12525Z"
      fill="white"
    />
    <path
      d="M17.9437 4.06899C17.4 3.88149 16.8 3.91899 16.2937 4.20024C15.7687 4.46274 15.3937 4.91274 15.2062 5.45649C15.0187 6.00024 15.075 6.60024 15.3375 7.10649C15.6 7.63149 16.05 8.00649 16.5937 8.19399C16.8187 8.26899 17.0437 8.30649 17.2687 8.30649C17.6062 8.30649 17.925 8.23149 18.225 8.08149C18.75 7.81899 19.125 7.36899 19.3125 6.82524C19.5 6.28149 19.4437 5.70024 19.1812 5.17524C18.9375 4.63149 18.4875 4.25649 17.9437 4.06899Z"
      fill="white"
    />
    <path
      d="M22.6125 7.96887C21.7125 7.66887 20.7375 8.15637 20.4375 9.05637C20.2875 9.48762 20.325 9.95637 20.5312 10.3689C20.7375 10.7814 21.0937 11.0814 21.525 11.2314C21.7125 11.2876 21.8812 11.3251 22.0687 11.3251C22.7812 11.3251 23.4562 10.8751 23.7 10.1439C24 9.24387 23.5125 8.26887 22.6125 7.96887Z"
      fill="white"
    />
    <path
      d="M25.2 12.8252C24.9375 12.7314 24.6375 12.7502 24.375 12.8814C24.1125 13.0127 23.925 13.2377 23.8312 13.5189C23.6437 14.0814 23.9625 14.7002 24.525 14.8877C24.6375 14.9252 24.75 14.9439 24.8625 14.9439C25.3125 14.9439 25.7438 14.6627 25.8938 14.1939C26.0813 13.6314 25.7625 13.0127 25.2 12.8252Z"
      fill="white"
    />
    <path
      d="M10.0312 12.9751H14.8688C15.6375 12.9751 16.3125 13.0876 16.9125 13.2938C17.4937 13.5001 17.9813 13.8188 18.3375 14.2126C18.6938 14.6063 18.975 15.0563 19.1437 15.5813C19.3312 16.1063 19.4062 16.6876 19.4062 17.3251C19.4062 18.0376 19.2937 18.6563 19.0687 19.2001C18.8437 19.7438 18.525 20.1751 18.0938 20.5313C17.6625 20.8876 17.175 21.1313 16.5938 21.3188C16.0125 21.4876 15.3563 21.5813 14.625 21.5813H12.5812V26.0438H10.0312V12.9751ZM14.2875 19.2751C15.075 19.2751 15.675 19.0876 16.0875 18.7313C16.5 18.3751 16.7062 17.8876 16.7062 17.2688C16.7062 16.6501 16.5 16.1626 16.1063 15.8063C15.6938 15.4501 15.1312 15.2626 14.3812 15.2626H12.5625V19.2751H14.2875V19.2751Z"
      fill="white"
    />
    <path
      d="M23.6062 22.8938H20.475V20.4376H23.6062V17.2876H26.0813V20.4563H29.2312V22.9126H26.0813V26.0626H23.6062V22.8938V22.8938Z"
      fill="white"
    />
  </svg>
)
