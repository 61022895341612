/* @flow */
import React, { Component } from 'react'

import { createBrowserHistory } from 'history'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import styled from 'styled-components'

import {
  ATTRIBUTES_PATH,
  AUTO_RENEWAL_PATH,
  BANK_ACCOUNTS_PATH,
  BATCH_JOBS_PATH,
  CATEGORIES_PATH,
  COLLECTION_ITEMS_PATH,
  COLLECTIONS_PATH,
  COTERM_PATH,
  CUSTOMER_GROUPS_PATH,
  CUSTOMERS_PATH,
  ENV_PRODUCTION,
  IMPORT_PATH,
  LOG_BOOK_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  ORDER_ADVANCED_SEARCH_PATH,
  ORDER_TYPES_PATH,
  ORDERS_PATH,
  PAYMENT_ACCOUNTS_PATH,
  PAYMENT_SURCHARGE_PATH,
  PAYMENTS_PATH,
  PRODUCT_OPTIONS_PATH,
  PRODUCTS_PATH,
  PROFILE_PATH,
  PROHIBITED_ITEMS_PATH,
  PUBLIC_PATH,
  QUOTATIONS_PATH,
  SANCTIONS_PATH,
  SHIPPING_PATH,
  SHIPPING_ZONES_PATH,
  SSO_CALLBACK,
  STORES_PATH,
  SUBSCRIPTIONS_PATH,
  USER_ROLE_ACCESS_PATH,
  USERS_PATH,
} from 'definitions'
import { ROLE_ADMIN, ROLE_APP_ADMIN, ROLE_ORDER_PROCESS, ROLE_USER } from 'definitions'

import AccessDenied from 'components/AccessDenied'
import ApproveContainer from 'components/PublicPages/Order/ApproveContainer'
import AttributeAdd from './components/Attributes/AttributeAdd'
import AttributeEdit from './components/Attributes/AttributeEdit'
import Attributes from './components/Attributes/'
import AuthWrapper from './components/Auth/AuthWrapper'
import AutoRenewal from './components/AutoRenewal'
import AutoRenewalEdit from 'components/AutoRenewal/AutoRenewalEdit'
import BankAccountAdd from './components/BankAccounts/BankAccountAdd'
import BankAccountEdit from './components/BankAccounts/BankAccountEdit'
import BankAccounts from './components/BankAccounts/'
import BatchJobDetail from './components/AutoRenewal/BatchJob/DetailForm'
import Categories from './components/Categories/'
import CollectionItemAdd from './components/CollectionItems/CollectionItemAdd'
import CollectionItemEdit from './components/CollectionItems/CollectionItemEdit'
import CollectionItems from './components/CollectionItems'
import Collections from './components/Collections'
import CoTerm from './components/CoTerm'
import CoTermAdd from './components/CoTerm/Add'
import CoTermEdit from './components/CoTerm/Edit'
import CustomerAdd from './components/Customers/CustomerAdd'
import CustomerEdit from './components/Customers/CustomerEdit'
import CustomerGroups from './components/CustomerGroups/'
import Customers from './components/Customers/'
import GlobalErrorCatcher from 'components/GlobalErrorCatcher'
import Header from './components/Common/Header'
import Import from './components/Import'
import LogBook from './components/LogBook'
import Login from './components/Login'
import Logout from './components/Logout'
import OrderAdd from './components/Orders/OrderAdd'
import OrderAdvancedSearch from './components/OrderAdvancedSearch'
import OrderCharges from './components/OrderCharges'
import OrderChargesAdd from './components/OrderCharges/OrderChargesAdd'
import OrderChargesEdit from './components/OrderCharges/OrderChargesEdit'
import OrderEdit from './components/Orders/OrderEdit'
import Orders from './components/Orders'
import OrderTypeAdd from './components/OrderTypes/OrderTypeAdd'
import OrderTypeEdit from './components/OrderTypes/OrderTypeEdit'
import OrderTypes from './components/OrderTypes'
import Payment from './components/PublicPages/Payment/'
import PaymentAccount from './components/PaymentAccounts/'
import PaymentAccountAdd from './components/PaymentAccounts/Add'
import PaymentAccountEdit from './components/PaymentAccounts/Edit'
import PaymentAdd from './components/Payments/PaymentAdd'
import PaymentConfirmation from './components/PublicPages/Payment/Confirmation'
import PaymentEdit from './components/Payments/PaymentEdit'
import Payments from './components/Payments/'
import PricingRules from './components/Promotions/PriceRules'
import PricingRulesAdd from './components/Promotions/PriceRules/Add'
import PricingRulesEdit from './components/Promotions/PriceRules/Edit'
import ProductAdd from './components/Products/ProductAdd'
import ProductEdit from './components/Products/ProductEdit'
import ProductOptions from './components/ProductOptions'
import Products from './components/Products/'
import ProhibitedItems from './components/ProhibitedItems/'
import Quotations from './components/Quotations'
import RejectForm from './components/PublicPages/Order/RejectForm'
import Sanctions from './components/Sanctions'
import Shipping from './components/Shipping/'
import ShippingAdd from './components/Shipping/ShippingAdd'
import ShippingEdit from './components/Shipping/ShippingEdit'
import ShippingZones from './components/ShippingZones'
// import PaypalZones from './components/PaymentZone/Paypal'
import StoreAdd from './components/Stores/StoreAdd'
import StoreEdit from './components/Stores/StoreEdit'
import Stores from './components/Stores'
// for Phase 2 only
import Subscription from './components/Subscriptions'
import SubscriptionAdd from './components/Subscriptions/Add'
import SubscriptionEdit from './components/Subscriptions/Edit'
import SuccessPage from './components/PublicPages/Order/Success'
import UserAdd from './components/Users/UserAdd'
import UserEdit from './components/Users/UserEdit'
import UserRoleAccess from './components/UserRoleAccess'
import UserRoleAccessAdd from './components/UserRoleAccess/UserRoleAccessAdd'
import UserRoleAccessEdit from './components/UserRoleAccess/UserRoleAccessEdit'
import Users from './components/Users'

//Auth
import SSOCallback from './components/Auth/SSOCallback'

const history = createBrowserHistory()
const adminRole = [ROLE_ADMIN]
const appAdminRole = [...adminRole, ROLE_APP_ADMIN]
const orderRoles = [...appAdminRole, ROLE_ORDER_PROCESS]
const allRoles = [...orderRoles, ROLE_USER]

const MainComponent = inject(
  'userRepository',
  'couponRepository',
  'coTermRepository',
  'coTermProductRepository',
  'paymentRepository',
  'customerRepository',
  'customerGroupRepository',
  'customerGroupDetailsRepository',
  'customerAddressRepository',
  'customerContactRepository',
  'customerGroupDisabledProductRepository',
  'storeRepository',
  'storeProductRepository',
  'storeCountryRepository',
  'productAttributeRepository',
  'productAttributeValuesRepository',
  'productImageRepository',
  'productOptionRepository',
  'productCustomOptionRepository',
  'productRepository',
  'orderRepository',
  'orderAttributeRepository',
  'orderChargeRepository',
  'catalogPricingRuleRepository',
  'fileUploadRepository',
  'categoryRepository',
  'shippingRepository',
  'shippingRateRepository',
  'importRepository',
  'importTypeRepository',
  'orderItemRepository',
  'sanctionRepository',
  'sanctionCustomerRepository',
  'orderItemSerialNumberRepository',
  'quotationRepository',
  'bankAccountRepository',
  'orderSubscriptionRepository',
  'orderSubscriptionProductsRepository',
  'orderSubscriptionListRepository',
  'orderItemCustomOptionRepository',
  'zoneRepository',
  'productCommentRepository',
  'customerUserLoginRepository',
  'customerSpecialProductRepository',
  'prohibitedItemRepository',
  'orderTypeRepository',
  'paymentAccountRepository'
)(
  observer(
    ({
      children,
      customerRepository,
      orderRepository,
      couponRepository,
      coTermRepository,
      coTermProductRepository,
      paymentRepository,
      customerGroupRepository,
      customerGroupDetailsRepository,
      customerAddressRepository,
      customerContactRepository,
      customerGroupDisabledProductRepository,
      storeRepository,
      storeProductRepository,
      storeCountryRepository,
      productAttributeRepository,
      productAttributeValuesRepository,
      productImageRepository,
      productOptionRepository,
      productCustomOptionRepository,
      productRepository,
      orderAttributeRepository,
      orderChargeRepository,
      catalogPricingRuleRepository,
      fileUploadRepository,
      categoryRepository,
      shippingRepository,
      shippingRateRepository,
      importRepository,
      importTypeRepository,
      orderItemRepository,
      sanctionRepository,
      sanctionCustomerRepository,
      orderItemSerialNumberRepository,
      quotationRepository,
      bankAccountRepository,
      orderSubscriptionRepository,
      orderSubscriptionProductsRepository,
      orderSubscriptionListRepository,
      orderItemCustomOptionRepository,
      zoneRepository,
      productCommentRepository,
      customerUserLoginRepository,
      customerSpecialProductRepository,
      prohibitedItemRepository,
      orderTypeRepository,
      paymentAccountRepository,
    }) => {
      return (
        <MainStyled isProduction={process.env.REACT_APP_ENV === ENV_PRODUCTION}>
          <Header history={history} />
          <main className="bg-light">
            <GlobalErrorCatcher>{children}</GlobalErrorCatcher>
          </main>
          {(customerRepository.CRUD.loading ||
            orderRepository.CRUD.loading ||
            couponRepository.CRUD.loading ||
            coTermRepository.CRUD.loading ||
            coTermProductRepository.CRUD.loading ||
            paymentRepository.CRUD.loading ||
            customerGroupRepository.CRUD.loading ||
            customerGroupDetailsRepository.CRUD.loading ||
            customerAddressRepository.CRUD.loading ||
            customerContactRepository.CRUD.loading ||
            customerGroupDisabledProductRepository.CRUD.loading ||
            storeRepository.CRUD.loading ||
            storeProductRepository.CRUD.loading ||
            storeCountryRepository.CRUD.loading ||
            productAttributeRepository.CRUD.loading ||
            productAttributeValuesRepository.CRUD.loading ||
            productImageRepository.CRUD.loading ||
            productOptionRepository.CRUD.loading ||
            productCustomOptionRepository.CRUD.loading ||
            productRepository.CRUD.loading ||
            orderAttributeRepository.CRUD.loading ||
            orderChargeRepository.CRUD.loading ||
            catalogPricingRuleRepository.CRUD.loading ||
            fileUploadRepository.CRUD.loading ||
            categoryRepository.CRUD.loading ||
            shippingRepository.CRUD.loading ||
            shippingRateRepository.CRUD.loading ||
            importRepository.CRUD.loading ||
            importTypeRepository.CRUD.loading ||
            orderItemRepository.CRUD.loading ||
            sanctionRepository.CRUD.loading ||
            sanctionCustomerRepository.CRUD.loading ||
            orderItemSerialNumberRepository.CRUD.loading ||
            quotationRepository.CRUD.loading ||
            bankAccountRepository.CRUD.loading ||
            orderSubscriptionRepository.CRUD.loading ||
            orderSubscriptionProductsRepository.CRUD.loading ||
            orderSubscriptionListRepository.CRUD.loading ||
            orderItemCustomOptionRepository.CRUD.loading ||
            zoneRepository.CRUD.loading ||
            productCommentRepository.CRUD.loading ||
            customerUserLoginRepository.CRUD.loading ||
            customerSpecialProductRepository.CRUD.loading ||
            prohibitedItemRepository.CRUD.loading ||
            orderTypeRepository.CRUD.loading ||
            paymentAccountRepository.CRUD.loading) && <GlobalLoaderStyled />}
        </MainStyled>
      )
    }
  )
)

const GlobalLoaderStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  z-index: 99999;
`

class AuthenticatedRoute extends React.Component {
  render() {
    const { component: Component, isAuthenticated, role, allow = adminRole, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={props => {
          if (isAuthenticated()) {
            return <MainComponent>{allow.includes(role) ? <Component {...props} /> : <AccessDenied />}</MainComponent>
          } else {
            localStorage.setItem('redirectUrl', `${props.location.pathname}${props.location.search}`)
            return <Redirect to={LOGIN_PATH} />
          }
        }}
      />
    )
  }
}

class App extends Component {
  state = { isLoggedIn: false }

  render() {
    const {
      loginRepository: { isAuthenticated, getCurrentRole },
    } = this.props

    const ProtectedRoute = props => (
      <AuthWrapper>
        <AuthenticatedRoute {...props} isAuthenticated={isAuthenticated} role={getCurrentRole()} />
      </AuthWrapper>
    )
    return (
      <Router history={history}>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route path={`${PUBLIC_PATH}${ORDERS_PATH}/approve/:token`} component={ApproveContainer} />
          <Route path={`${PUBLIC_PATH}${ORDERS_PATH}/reject/:token`} component={RejectForm} />
          <Route path={`${PUBLIC_PATH}${ORDERS_PATH}/s/:action`} exact component={SuccessPage} />
          <Route path={`${PUBLIC_PATH}/payment/:invoiceNumber/success`} exact component={PaymentConfirmation} />
          <Route path={`${PUBLIC_PATH}/payment/:token`} component={Payment} />
          <Route path={SSO_CALLBACK} component={SSOCallback}>
            <Redirect to="/" />
          </Route>

          <ProtectedRoute path="/" exact component={Orders} allow={allRoles} />
          {/* AUTHENTICATION ROUTES */}
          {/* ========================================================== */}
          <Route
            path={LOGIN_PATH}
            render={props => {
              return isAuthenticated() ? <Redirect to="/" /> : <Login {...props} />
            }}
          />
          <ProtectedRoute exact path={LOGOUT_PATH} component={Logout} allow={allRoles} />
          {/* ATTRIBUTES ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute exact path={ATTRIBUTES_PATH} component={Attributes} />
          <ProtectedRoute exact path={`${ATTRIBUTES_PATH}/new`} component={AttributeAdd} />
          <ProtectedRoute exact path={`${ATTRIBUTES_PATH}/:id`} component={AttributeEdit} />
          {/* BANK_ACCOUNTS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={BANK_ACCOUNTS_PATH} exact component={BankAccounts} allow={appAdminRole} />
          <ProtectedRoute path={`${BANK_ACCOUNTS_PATH}/new`} component={BankAccountAdd} />
          <ProtectedRoute path={`${BANK_ACCOUNTS_PATH}/:id`} component={BankAccountEdit} allow={appAdminRole} />
          {/* BATCH JOB ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute
            exact
            path={`${BATCH_JOBS_PATH}`}
            component={props => <AutoRenewal {...props} view="job" />}
            allow={appAdminRole}
          />
          <ProtectedRoute path={`${BATCH_JOBS_PATH}/:id`} component={BatchJobDetail} allow={appAdminRole} />
          {/* CATEGORIES ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={CATEGORIES_PATH} component={Categories} allow={allRoles} />
          {/* COLLECTION ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={COLLECTIONS_PATH} exact component={Collections} />
          {/* COLLECTION ITEM ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={`${COLLECTION_ITEMS_PATH}`} exact component={CollectionItems} />
          <ProtectedRoute path={`${COLLECTION_ITEMS_PATH}/new`} component={CollectionItemAdd} />
          <ProtectedRoute path={`${COLLECTION_ITEMS_PATH}/:id`} component={CollectionItemEdit} />
          {/* CO-TERM ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={`${COTERM_PATH}`} exact component={CoTerm} allow={allRoles} />
          <ProtectedRoute path={`${COTERM_PATH}/new`} exact component={CoTermAdd} allow={orderRoles} />
          <ProtectedRoute path={`${COTERM_PATH}/:orderId`} exact component={CoTermEdit} allow={allRoles} />
          {/* CUSTOMER ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={CUSTOMERS_PATH} exact component={Customers} allow={allRoles} />
          <ProtectedRoute path={`${CUSTOMERS_PATH}/new`} exact component={CustomerAdd} allow={orderRoles} />
          <ProtectedRoute path={`${CUSTOMERS_PATH}/:customerId`} component={CustomerEdit} allow={allRoles} />
          {/* CUSTOMER GROUP ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={CUSTOMER_GROUPS_PATH} component={CustomerGroups} allow={allRoles} />
          {/* IMPORT ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={IMPORT_PATH} exact component={Import} />
          {/* LOG BOOK ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={LOG_BOOK_PATH} exact component={LogBook} allow={appAdminRole} />
          {/* ORDER CHARGES ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={PAYMENT_SURCHARGE_PATH} exact component={OrderCharges} allow={orderRoles} />
          <ProtectedRoute path={`${PAYMENT_SURCHARGE_PATH}/new`} component={OrderChargesAdd} allow={orderRoles} />
          <ProtectedRoute path={`${PAYMENT_SURCHARGE_PATH}/:id`} component={OrderChargesEdit} allow={orderRoles} />
          {/* ORDER ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={ORDERS_PATH} exact component={Orders} allow={allRoles} />
          <ProtectedRoute path={`${ORDERS_PATH}/new`} exact component={OrderAdd} allow={orderRoles} />
          <ProtectedRoute path={`${ORDERS_PATH}/:orderId`} component={OrderEdit} allow={allRoles} />
          {/* ORDER ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={ORDER_ADVANCED_SEARCH_PATH} exact component={OrderAdvancedSearch} allow={allRoles} />
          {/* ORDER TYPE ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={ORDER_TYPES_PATH} exact component={OrderTypes} allow={appAdminRole} />
          <ProtectedRoute path={`${ORDER_TYPES_PATH}/new`} exact component={OrderTypeAdd} />
          <ProtectedRoute path={`${ORDER_TYPES_PATH}/:id`} component={OrderTypeEdit} allow={appAdminRole} />
          {/* PAYMENTS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={PAYMENTS_PATH} exact component={Payments} allow={allRoles} />
          <ProtectedRoute path={`${PAYMENTS_PATH}/new`} exact component={PaymentAdd} allow={orderRoles} />
          <ProtectedRoute path={`${PAYMENTS_PATH}/:id`} component={PaymentEdit} allow={allRoles} />
          {/* PRODUCT ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute exact path={PRODUCTS_PATH} component={Products} allow={allRoles} />
          <ProtectedRoute exact path={`${PRODUCTS_PATH}/new`} component={ProductAdd} allow={appAdminRole} />
          <ProtectedRoute exact path={`${PRODUCTS_PATH}/:productId`} component={ProductEdit} allow={allRoles} />
          {/* PRODUCT OPTIONS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute exact path={`${PRODUCT_OPTIONS_PATH}`} component={ProductOptions} allow={allRoles} />
          {/* PROHIBITED ITEMS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute exact path={`${PROHIBITED_ITEMS_PATH}`} component={ProhibitedItems} allow={appAdminRole} />
          {/* PROMOTIONS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={`/pricing/:type/new`} exact component={PricingRulesAdd} allow={appAdminRole} />
          <ProtectedRoute path={`/pricing/:type/:id`} component={PricingRulesEdit} allow={appAdminRole} />
          <ProtectedRoute path={`/pricing/:type`} component={PricingRules} allow={appAdminRole} />
          {/* QUOTATION ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={QUOTATIONS_PATH} exact component={Quotations} allow={allRoles} />
          <Route path={`${QUOTATIONS_PATH}/new`} exact>
            <Redirect to={`${ORDERS_PATH}/new`} />
          </Route>
          <ProtectedRoute
            path={`${QUOTATIONS_PATH}/:orderId`}
            component={props => <OrderEdit {...props} quotation allow={allRoles} />}
            allow={allRoles}
          />
          {/* SANCTIONS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={SANCTIONS_PATH} exact component={Sanctions} allow={appAdminRole} />
          {/* SHIPPING MANAGEMENT ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={SHIPPING_PATH} exact component={Shipping} allow={orderRoles} />
          <ProtectedRoute path={`${SHIPPING_PATH}/new`} exact component={ShippingAdd} allow={orderRoles} />
          <ProtectedRoute path={`${SHIPPING_PATH}/:id`} exact component={ShippingEdit} allow={orderRoles} />
          {/* SHIPPING ZONES */}
          {/* ========================================================== */}
          <ProtectedRoute path={SHIPPING_ZONES_PATH} exact component={ShippingZones} allow={appAdminRole} />
          {/* PAYMENT ACCOUNTS */}
          {/* ========================================================== */}
          <ProtectedRoute path={PAYMENT_ACCOUNTS_PATH} exact component={PaymentAccount} allow={appAdminRole} />
          <ProtectedRoute path={`${PAYMENT_ACCOUNTS_PATH}/new`} component={PaymentAccountAdd} />
          <ProtectedRoute path={`${PAYMENT_ACCOUNTS_PATH}/:id`} component={PaymentAccountEdit} allow={appAdminRole} />
          {/* STORES ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={STORES_PATH} exact component={Stores} allow={appAdminRole} />
          <ProtectedRoute path={`${STORES_PATH}/new`} exact component={StoreAdd} />
          <ProtectedRoute path={`${STORES_PATH}/:id`} component={StoreEdit} allow={appAdminRole} />

          {/* SUBSCRIPTION ROUTES // for Phase 2 only */}
          {/* ========================================================== */}
          <ProtectedRoute path={SUBSCRIPTIONS_PATH} exact component={Subscription} allow={allRoles} />
          <ProtectedRoute path={`${SUBSCRIPTIONS_PATH}/new`} component={SubscriptionAdd} allow={orderRoles} />
          <ProtectedRoute path={`${SUBSCRIPTIONS_PATH}/:orderId`} component={SubscriptionEdit} allow={allRoles} />

          {/* USERS ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={USERS_PATH} exact component={Users} allow={appAdminRole} />
          <ProtectedRoute path={`${USERS_PATH}/new`} exact component={UserAdd} />
          <ProtectedRoute path={`${USERS_PATH}/:userId`} component={UserEdit} allow={appAdminRole} />
          <ProtectedRoute path={`${PROFILE_PATH}/:userId`} component={UserEdit} allow={allRoles} />

          {/* USER ROLE ACCESS */}
          {/* ========================================================== */}
          <ProtectedRoute path={USER_ROLE_ACCESS_PATH} exact component={UserRoleAccess} />
          <ProtectedRoute path={`${USER_ROLE_ACCESS_PATH}/new`} exact component={UserRoleAccessAdd} />
          <ProtectedRoute path={`${USER_ROLE_ACCESS_PATH}/:id`} component={UserRoleAccessEdit} />

          {/* AUTO RENEWAL ROUTES */}
          {/* ========================================================== */}
          <ProtectedRoute path={AUTO_RENEWAL_PATH} exact component={AutoRenewal} allow={appAdminRole} />
          <ProtectedRoute path={`${AUTO_RENEWAL_PATH}/:id`} component={AutoRenewalEdit} allow={appAdminRole} />
        </Switch>
      </Router>
    )
  }
}

const MainStyled = styled.div`
  display: flex;
  background: #fafafa;
  margin-top: ${props => (!props.isProduction ? '76px' : '50px')};

  > main {
    height: ${props => (!props.isProduction ? 'calc(100vh - 76px)' : 'calc(100vh - 55px)')};
    overflow: auto;
    flex-grow: 1;
  }

  > nav {
    top: ${props => (!props.isProduction ? '26px' : '0')};
  }
`

export default decorate(inject('loginRepository')(observer(App)), {})
