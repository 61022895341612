import React, { Component } from 'react'

import { observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import { formatNumber } from 'helpers/Formatters'
import { PRODUCT_NATURE_PHYSICAL } from 'definitions'
import Card from 'components/Common/Card'
import FieldWrapper from './FieldWrapper'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

import TimePeriodPicker from './TimePeriodPicker'

class ProductInfoPanel extends Component {
  componentDidMount() {
    this.props.commonRepository.getCommon('countries', 'countries')
  }
  render() {
    const { commonRepository, shippingRateRepository, form, data, readOnly } = this.props
    const {
      Common: { countries: { data: countries = [] } = {} },
    } = commonRepository
    const { productNature = {} } = data
    const isPhysical = (productNature.value || PRODUCT_NATURE_PHYSICAL) === PRODUCT_NATURE_PHYSICAL

    return (
      <ProductInfoPanelStyled>
        <div className={cx('row', { physical: isPhysical })}>
          <div className="product-code">
            <FieldWrapper readOnly={readOnly || !!form.$('productCode').value}>
              <Input field={form.$('productCode')} />
            </FieldWrapper>
          </div>
          <div>
            <FieldWrapper readOnly={readOnly}>
              <ReactSelect
                field={form.$('productSeries')}
                serverSide
                search={params => commonRepository.reactSelectSearch(params, 'productSeries')}
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  defaultOptions: true,
                  isClearable: false,
                }}
                showTooltip
              />
            </FieldWrapper>
          </div>
          <div>
            <FieldWrapper readOnly={readOnly}>
              <ReactSelect
                field={form.$('productType')}
                serverSide
                search={params => commonRepository.reactSelectSearch(params, 'productTypes')}
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  defaultOptions: true,
                  isClearable: false,
                }}
                showTooltip
              />
            </FieldWrapper>
          </div>
          <div className="manufacturer">
            <FieldWrapper readOnly={readOnly}>
              <ReactSelect
                field={form.$('countryOfManufacture')}
                options={{
                  options: countries,
                }}
                showTooltip
              />
            </FieldWrapper>
          </div>
          {isPhysical && (
            <React.Fragment>
              <div>
                <FieldWrapper readOnly={readOnly}>
                  <ReactSelect
                    field={form.$('shippingMethod')}
                    serverSide
                    search={params => commonRepository.reactSelectSearch(params, 'productShippingMethods')}
                    options={{
                      valueKey: 'value',
                      labelKey: 'label',
                      defaultOptions: true,
                      isClearable: false,
                    }}
                    showTooltip
                  />
                </FieldWrapper>
              </div>
              <div>
                <FieldWrapper readOnly={readOnly}>
                  <ReactSelect
                    field={form.$('shippingType')}
                    serverSide
                    search={params => shippingRateRepository.reactSelectSearchShippingTypes(params)}
                    options={{
                      valueKey: 'value',
                      labelKey: 'label',
                      defaultOptions: true,
                      isClearable: false,
                    }}
                    showTooltip
                  />
                </FieldWrapper>
              </div>
            </React.Fragment>
          )}
          {!isPhysical && (
            <div className="time-period">
              <TimePeriodPicker
                commonRepository={commonRepository}
                form={form}
                label="Time Period"
                readOnly={readOnly}
              />
            </div>
          )}
          <div className="moq">
            <FieldWrapper readOnly={readOnly} value={`${formatNumber(form.$('moq').value, 0)} units`}>
              <Input field={form.$('moq')} />
            </FieldWrapper>
          </div>
          <div>
            <FieldWrapper readOnly={readOnly}>
              <ReactSelect
                field={form.$('orderProcess')}
                serverSide
                search={params => commonRepository.reactSelectSearch(params, 'productOrderProcess')}
                options={{
                  valueKey: 'value',
                  labelKey: 'label',
                  defaultOptions: true,
                  isClearable: false,
                }}
                showTooltip
              />
            </FieldWrapper>
          </div>
        </div>
      </ProductInfoPanelStyled>
    )
  }
}

const ProductInfoPanelStyled = styled(Card)`
  .card-body {
    padding-top: 0.6rem !important;
  }
  .form-group {
    label {
      color: ${props => props.theme.primary};
      margin-bottom: 0px;
      font-size: 12px;
    }
    .form-control {
      font-size: 16px;
      font-weight: bold;
      height: calc(1.2em + 0.75rem + 5px);
      padding: 0.3rem 0rem;
    }
  }
  .react-select__control {
    font-size: 16px;
    font-weight: bold;
  }
  .d-grid {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    .form-group {
      label,
      .form-control {
        white-space: nowrap;
      }
    }
  }
  .row {
    margin-left: -12px;
    margin-right: 0;
    > div {
      padding-right: 12px;
      &:last-child {
        padding-right: 0;
      }
      &.product-code {
        padding-left: 12px;
        width: calc(30% - 100px);
      }
      &:not(.product-code) {
        width: 14%;
      }
      &.time-period {
        width: 120px !important;
      }
      &.moq {
        width: 100px !important;
      }
    }
    &.physical {
      > div {
        &.product-code {
          width: calc(28% - 100px);
        }
        &:not(.product-code) {
          width: 11.8%;
        }
        &.manufacturer {
          width: 13%;
        }
      }
    }
  }
`

export default observer(ProductInfoPanel)
