/* @flow */

import { action, decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import OrderServices, { OrderAttributeServices, OrderItemSerialNumberServices, OrderItemServices } from 'services/order'

const defaults = {
  CRUD: {
    loading: false,
    data: {},
    errors: [],
  },
  OrderRates: {
    loading: false,
    data: { shippingTotal: 0, shippingInsurance: 0, grandTotal: 0, currency: '', paymentCharge: 0, subTotal: 0 },
  },
  OrderAttributes: { loading: true, data: null },
  ApprovalRequest: {
    loading: false,
    data: {},
    errors: [],
  },
  OrderStatus: {
    loading: false,
    data: [],
    errors: [],
  },
  Coupon: {
    loading: false,
    errors: [],
  },
  Discounts: {
    loading: false,
    errors: [],
    data: [],
  },
}

class OrderRepository extends BaseRepository {
  service: OrderServices
  constructor(service: OrderServices) {
    super(service)
    this.service = service
  }

  OrderRates = defaults.OrderRates

  OrderAttributes = defaults.OrderAttributes

  ApprovalRequest = defaults.ApprovalRequest

  OrderStatus = defaults.OrderStatus

  Coupon = defaults.Coupon

  Discounts = defaults.Discounts

  cancelOrder = async orderId => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.cancelOrder(orderId)
    this.CRUD.loading = false
    return { data, errors }
  }

  cloneOrder = async orderId => {
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.cloneOrder(orderId)
    this.CRUD.submitting = false
    this.CRUD.loading = false
    return { data, errors }
  }

  createDraftOrder = async (payload, callback = null) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.createDraftOrder(payload)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  computeOrderRates = async orderId => {
    this.OrderRates.loading = true
    const { data, errors } = await this.service.computeOrderRates(orderId)
    if (data && !errors.length) {
      this.OrderRates.data = data
    }
    this.OrderRates.loading = false
  }

  submitAsOrder = async (payload, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.submitAsOrder(payload.id, payload)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  sendProformaInvoice = async id => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.sendProformaInvoice(id)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  sendEmail = async id => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.sendEmail(id)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
    return { errors }
  }

  removeDiscount = async (orderId, payload, callback) => {
    this.isRemovingDiscount = true
    const { data, errors } = await this.service.removeDiscount(orderId, payload)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    }
    this.isRemovingDiscount = false
  }

  sendApprovalRequest = async (orderId, payload, isQuotation, callback = null) => {
    this.ApprovalRequest.loading = true
    this.ApprovalRequest.submitting = true
    const { data, errors } = await this.service.sendApprovalRequest(orderId, payload, isQuotation)
    this.ApprovalRequest.submitting = false
    if (data && !errors.length) {
      this.ApprovalRequest.data = data
      if (callback) callback(data)
    } else {
      this.ApprovalRequest.errors = errors
    }
    this.ApprovalRequest.loading = false
  }

  applyCoupon = async (orderId, payload, callback) => {
    this.Coupon.loading = true
    this.Coupon.errors = []
    const { data, errors } = await this.service.applyCoupon(orderId, payload)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.Coupon.errors = errors
    }
    this.Coupon.loading = false
  }

  removeCoupon = async (orderId, payload, callback) => {
    this.Coupon.loading = true
    const { data, errors } = await this.service.removeCoupon(orderId, payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
    this.Coupon.loading = false
  }

  approveDiscount = async (orderId, callback) => {
    this.ApprovalRequest.loading = true
    const { data, errors } = await this.service.approveDiscount(orderId)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.ApprovalRequest.errors = errors
    }
    this.ApprovalRequest.loading = false
  }

  rejectRequest = async (payload, orderId, callback) => {
    this.ApprovalRequest.loading = true
    const { data, errors } = await this.service.rejectRequest(payload, orderId)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.ApprovalRequest.errors = errors
    }
    this.ApprovalRequest.loading = false
  }

  getStatus = async status => {
    this.OrderStatus.loading = true
    const { data, errors } = await this.service.getStatus(status)
    if (data && !errors.length) {
      this.OrderStatus.data = data
    }
    this.OrderStatus.loading = true
  }

  updateStatus = async (orderId, payload, callback) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.updateStatus(orderId, payload)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.OrderStatus.errors = errors
    }
    this.CRUD.loading = false
  }

  getDiscounts = async (orderId, callback) => {
    this.Discounts.loading = false
    const { data, errors } = await this.service.getDiscounts(orderId)
    if (data && !errors.length) {
      this.Discounts.data = data
      if (callback) callback(data)
    } else {
      this.Discounts.errors = errors
    }
    this.Discounts.loading = false
  }

  patchQuotation = async (payload, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    const { data, errors } = await this.service.patchQuotation(payload.id, payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  orderRevision = async (orderId, callback) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.cancelOrder(orderId)
    if (data && !errors.length) {
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  orderSummary = async (payload, callback) => {
    this.OrderRates.loading = true
    const { data, errors } = await this.service.orderSummary(payload)
    if (data && !errors.length) {
      this.OrderRates.data = data
      callback && callback(this.OrderRates.data)
    }
    this.OrderRates.loading = false
  }

  reset() {
    this.CRUD = defaults.CRUD
    this.OrderRates = defaults.OrderRates
    this.OrderAttributes = defaults.OrderAttributes
    this.ApprovalRequest = defaults.ApprovalRequest
    this.OrderStatus = defaults.OrderStatus
    this.Coupon = defaults.Coupon
    this.Discounts = defaults.Discounts
  }

  getPricingRulesOptions = async (order, callback = null) => {
    const { data, errors } = await this.service.getPricingRulesOptions(order)
    if (data && !errors.length) {
      if (callback) callback(data)
    }
  }

  saveAsDraft = async (payload, callback, id = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.saveAsDraft(id, payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  saveOrder = async (payload, callback, id = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.saveOrder(id, payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  rejectRequestApproval = async (payload, token, callback = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.rejectRequestApproval(token, payload)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }
  approveRequestApproval = async (token, callback = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.approveRequestApproval(token)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }
  getOrderByOrderNumber = async orderNumber => {
    const { data, errors } = await this.service.getOrderByOrderNumber(orderNumber)
    return { data, errors }
  }

  convertCoTermQuotation = async (id, callback) => {
    this.clearErrorMessages()
    this.CRUD.loading = true
    this.CRUD.submitting = true
    const { data, errors } = await this.service.convertCoTermQuotation(id)
    this.CRUD.submitting = false
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }
}

export default decorate(OrderRepository, {
  // StoreTable: observable,
  ApprovalRequest: observable,
  isRemovingDiscount: observable,
  OrderRates: observable,
  OrderAttributes: observable,
  OrderStatus: observable,
  Coupon: observable,
  Discounts: observable,
  reset: action,
})

export class OrderItemRepository extends BaseRepository {
  service: OrderItemServices
  constructor(service: OrderItemServices) {
    super(service)
    this.service = service
  }

  create = async (payload, id = null, callback) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.create(payload, id)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  patchOrderItem = async (payload, orderItemId, callback = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.patchOrderItem(payload, orderItemId)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  patchDiscount = async (payload, orderId, callback = null) => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.patchDiscount(payload, orderId)
    if (data && !errors.length) {
      this.CRUD.loadingFailed = false
      this.CRUD.data = data.order
      if (callback) callback(data)
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  applyCoupon = async (orderItem, payload, callback) => {
    const { data, errors } = await this.service.applyCoupon(orderItem, payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  removeCoupon = async (orderItem, callback) => {
    const { data, errors } = await this.service.removeCoupon(orderItem)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  applyPricingRule = async (orderId, payload, callback) => {
    const { data, errors } = await this.service.applyPricingRule(orderId, payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  removePricingRule = async (orderId, payload, callback) => {
    const { data, errors } = await this.service.removePricingRule(orderId, payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  removeOrderItem = async (orderId, payload, callback) => {
    const { data, errors } = await this.service.removeOrderItem(orderId, payload)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  validateOrganizationNumbers = async payload => {
    const { data, errors } = await this.service.validateOrganizationNumbers(payload)
    return { data, errors }
  }
}

export class OrderAttributeRepository extends OrderRepository {
  service: OrderAttributeServices
  constructor(service: OrderAttributeServices) {
    super(service)
    this.service = service
    this.attributes = {}
  }

  async fetchAll() {
    this.OrderAttributes.loading = true
    const { data, errors } = await this.service.fetchAll()
    if (data && !errors.length) {
      this.OrderAttributes = {
        loading: false,
        data: {
          Order: data.map(hash => ({
            attributeLabel: hash.label,
            attributeValue: hash.value,
            attributeType: hash.type,
            attributeName: hash.value,
          })),
        },
      }
    }
  }

  getAttributeValue = async payload => {
    const { data, errors } = await this.service.getAttributeValue(payload)
    this.attributes[payload.attributeName] = data
    return { data: { content: data }, errors }
  }
}

export class OrderItemSerialNumberRepository extends BaseRepository {
  service: OrderItemSerialNumberServices
  constructor(service: OrderItemSerialNumberServices) {
    super(service)
    this.service = service
  }
  fetchTable = async (payload, id = null, callback) => {
    this.Table.loading = true
    const { data, errors } = await this.service.getSerialNumbers(payload, id)

    if (data && !errors.length) {
      this.Table = {
        ...this.Table,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
      }
      this.Table.loading = false
      callback && callback(data)
    } else {
      this.Table.loading = false
    }
  }
  updateStatus = async (payload, callback, orderId) => {
    const { data, errors } = await this.service.updateStatus(payload, orderId)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }

  bulkUpdateStatus = async (payload, callback, orderId) => {
    const { data, errors } = await this.service.bulkUpdateStatus(payload, orderId)
    if (data && !errors.length) {
      if (callback) callback(data)
    } else {
      if (callback) callback({ error: true, messages: errors })
    }
  }
}

export class OrderItemIccidNumberRepository extends BaseRepository {
  service: OrderItemIccidNumberServices
  constructor(service: OrderItemIccidNumberServices) {
    super(service)
    this.service = service
  }
  fetchTable = async (payload, id = null, callback) => {
    this.Table.loading = true
    const { data, errors } = await this.service.getIccidNumbers(payload, id)

    if (data && !errors.length) {
      this.Table = {
        ...this.Table,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
      }
      this.Table.loading = false
      callback && callback(data)
    } else {
      this.Table.loading = false
    }
  }
}

export class OrderItemCustomOptionRepository extends BaseRepository {
  constructor(service) {
    super(service)
    this.service = service
  }
}

export class OrderAdvanceSearchRepository extends OrderRepository {
  service: OrderAdvanceSearchServices
  constructor(service: OrderAdvanceSearchServices) {
    super(service)
    this.service = service
    this.attributes = {}
  }
}
