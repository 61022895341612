import React, { Component } from 'react'

import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import RuleList from 'components/RuleList/'

class Conditions extends Component {
  constructor(props) {
    super(props)
    const { orderAttributeRepository } = this.props
    const { OrderAttributes } = orderAttributeRepository

    if (!OrderAttributes.data) {
      orderAttributeRepository.fetchAll()
    }
  }
  onAttributeSearch = payload => {
    if (payload.attributeType === 'collection') {
      if (payload.attributeName === 'country') {
        return this.props.commonRepository.reactSelectSearch(
          { ...payload, size: '99999', sort: 'label,asc' },
          payload.attributeName
        )
      }
      return this.props.commonRepository.reactSelectSearch(payload, payload.attributeName)
    } else {
      return this.props.orderAttributeRepository.getAttributeValue(payload)
    }
  }
  render() {
    const {
      criteria,
      orderAttributeRepository: { OrderAttributes },
    } = this.props

    return (
      <RuleList
        criteria={criteria}
        form={this.props.form}
        primaryIndex={0}
        AttributeCollection={toJS(OrderAttributes)}
        onAttributeSearch={this.onAttributeSearch}
      />
    )
  }
}

export default decorate(inject('orderAttributeRepository', 'commonRepository')(observer(Conditions)), {})
