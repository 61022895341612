import React, { Component } from 'react'

import { Button } from 'reactstrap'
import cx from 'classnames'
import styled from 'styled-components'

class TableFilterButtons extends Component {
  render() {
    const { filters = [], filterKey, onChange } = this.props
    return (
      <>
        {filters.map(({ label, value, color, selected }, key) => (
          <ButtonStyled
            size="xs"
            onClick={() => onChange({ [filterKey]: value })}
            color="default"
            hex={color}
            key={key}
            className={cx(selected && 'selected')}
          >
            {label}
          </ButtonStyled>
        ))}
      </>
    )
  }
}

const ButtonStyled = styled(Button)`
  width: 80px !important;
  color: #fff !important;
  margin-right: 5px !important;
  background: ${props => props.hex && props.hex} !important;
  &:hover {
    background: ${props => props.hex}CC !important;
    color: #fff !important;
  }
  &.selected,
  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem ${props => props.hex}80 !important;
  }
`

export default TableFilterButtons
