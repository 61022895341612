import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SHIPPING_PATH } from 'definitions'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { titleize } from 'helpers/String'
import Actions from './Actions'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class ShippingTable extends Component {
  selection = []
  handleCreate = () => {
    this.props.history.push(`${SHIPPING_PATH}/new`)
  }
  handleBulkDelete = () => {
    if (this.selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to delete '${this.selection.length}' shipping details?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = { shippingDetails: this.selection.map(item => ({ id: item })) }
          await this.props.shippingRepository.bulkDelete(payload)
          this.table.reload()
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select shipping details to delete', color: 'info' })
    }
  }
  handleDelete = item => {
    item &&
      ToasterNotify.confirm({
        message: `Are you sure you want to delete '${item.name}'?`,
        title: 'Warning',
        onConfirm: () => {
          this.props.shippingRepository.delete(item.id, () => this.table.reload())
        },
      })
  }
  handleBulkToggleStatus = newStatus => {
    if (this.selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to change the status of '${this.selection.length}' shipping details?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = {
            shippingDetails: this.selection.map(item => ({ id: item })),
            status: newStatus,
          }
          await this.props.shippingRepository.bulkUpdateStatus(payload)
          this.table.reload()
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select shipping details to update', color: 'info' })
    }
  }
  handToggleStatus = item => {
    if (item) {
      const payload = {
        ...item,
        status: item.status && item.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      }
      this.props.shippingRepository.patch(payload, () => {
        this.table && this.table.reload()
      })
    }
  }
  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => key) : []
  }
  render() {
    const {
      shippingRepository: {
        Table: { data, loading, totalRecordCount, originalData },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="Shipping">
        <ReactTable
          searchRepository={searchRepository}
          pageKey="shipping"
          enableRowLink
          getRowUrl={({ original }) => `${SHIPPING_PATH}/${original.id}`}
          multiSelect={allowedToOrderRole()}
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'shippingCarrier.label',
              Header: 'Shipping Method',
            },
            {
              accessor: 'shippingService.label',
              Header: 'Services',
            },
            {
              accessor: 'status',
              Header: 'Status',
              width: 150,
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  label={titleize(original.status)}
                  id={`switchStatus${original.id}`}
                  className="mr-2"
                  checked={original.status === 'ACTIVE'}
                  onChange={() => this.handToggleStatus(original)}
                  onClick={event => event.preventDefault()}
                  disabled={!allowedToOrderRole()}
                />
              ),
            },
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 150,
              Cell: ({ original }) => (
                <Visible toOrderProcessRole>
                  <IconButton
                    icon="ios-trash"
                    onClick={event => {
                      event.preventDefault()
                      this.handleDelete(original)
                    }}
                  />
                </Visible>
              ),
              sortable: false,
            },
          ]}
          actions={
            <Visible toOrderProcessRole>
              <Actions
                handleCreate={this.handleCreate}
                handleDelete={this.handleBulkDelete}
                handToggleStatus={this.handleBulkToggleStatus}
              />
            </Visible>
          }
          sort={{ id: 'shippingCarrier.label' }}
          data={toJS(data)}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          ref={ref => (this.table = ref)}
          selectionChanged={this.handleSelectionChanged}
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('shippingRepository', 'searchRepository')(observer(ShippingTable)), {})
