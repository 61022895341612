/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import StoreServices, { StoreCountryServices, StoreProductServices } from 'services/customer'

class StoreRepository extends BaseRepository {
  service: StoreServices
  constructor(service: StoreServices) {
    super(service)
    this.service = service
  }

  StoreProducts = {
    data: [],
    loading: false,
    totalRecordCount: 0,
    originalData: {},
  }

  fetchStoreByCustomer = async (params, id = null) => {
    const { data, errors } = await this.service.fetchStoreByCustomer(params, id)
    return { data, errors }
  }

  fetchProductsByStoreId = async (params, id = null) => {
    this.StoreProducts.loading = true
    const { data, errors } = await this.service.fetchProductsByStoreId(params, id)
    if (data && !errors.length) {
      this.StoreProducts.data = data.content
      this.StoreProducts.originalData = data
      this.StoreProducts.totalRecordCount = data.totalElements
    }
    this.StoreProducts.loading = false
  }

  addProductToStore = async (storeId, product) => this.service.addProductToStore(storeId, product)
}

class StoreProduct extends BaseRepository {
  constructor(service: StoreProductServices) {
    super(service)
    this.service = service
  }

  AvailableProducts = {
    data: [],
    loading: false,
    totalRecordCount: 0,
    originalData: {},
  }

  Products = {
    data: [],
    loading: false,
    totalRecordCount: 0,
    originalData: {},
  }

  fetchAvailableProductsTable = async (params, storeId) => {
    this.AvailableProducts.loading = true
    const { data, errors } = await this.service.fetchAvailableProductsTable(params, storeId)
    if (data && !errors.length) {
      this.AvailableProducts.originalData = data
      this.AvailableProducts.data = data.content
      this.AvailableProducts.totalRecordCount = data.totalElements
    }
    this.AvailableProducts.loading = false
  }

  fetchRelatedProductsTable = async (params, storeId, productId) => {
    this.Products.loading = true
    const { data, errors } = await this.service.fetchRelatedProductsTable(params, storeId, productId)
    if (data && !errors.length) {
      this.Products.originalData = data
      this.Products.data = data.content
      this.Products.totalRecordCount = data.totalElements
    }
    this.Products.loading = false
  }

  fetchSubscriptionItems = async (storeId, productId) => {
    const { data, errors } = await this.service.fetchSubscriptionItems(storeId, productId)
    return { data, errors }
  }

  getCustomOptions = async (storeId, productId) => {
    const { data, errors } = await this.service.getCustomOptions(storeId, productId)
    return { data, errors }
  }
}

class StoreCountry extends BaseRepository {
  constructor(service: StoreCountryServices) {
    super(service)
    this.service = service
  }
}
export const StoreCountryRepository = decorate(StoreCountry, {
  // StoreTable: observable,
  // Products: observable,
  // AvailableProducts: observable,
})

export const StoreProductRepository = decorate(StoreProduct, {
  // StoreTable: observable,
  Products: observable,
  AvailableProducts: observable,
})

export default decorate(StoreRepository, {
  // StoreTable: observable,
  StoreProducts: observable,
})
