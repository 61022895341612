import { CustomInput } from 'reactstrap'
import React from 'react'

import { formatNumber } from 'helpers/Formatters'

export const getView = ({
  // eslint-disable-next-line prettier/prettier
  key,
  store: selectedStore = {},
  stores = [],
  customerGroups = [],
  handleBulkUpdate,
  allowedToOrderRole,
}) => {
  switch (key) {
    case 'price':
      return {
        key,
        label: 'List Price',
        columns: stores.map(store => ({
          accessor: store.id,
          Header: (
            <div className="text-right" title={store.name}>
              <div>{store.name}</div>
              <div>({store.currency})</div>
            </div>
          ),
          headerClassName: 'justify-content-end',
          className: 'align-items-center justify-content-end',
          Cell: ({ original: { prices = [] } = {} }) => {
            const price = prices.find(item => item.storeId === store.id)
            return price && <div className="mb-1">{formatNumber(price.amount || 0)}</div>
          },
          label: `${store.name} (${store.currency})`,
          sortable: false,
          width: 120,
        })),
      }
    case 'store':
      return {
        key,
        label: selectedStore.name,
        columns: [
          {
            accessor: 'storePrice',
            Header: (
              <div className="text-right" title={selectedStore.name}>
                <div>{selectedStore.name}</div>
                <div>({selectedStore.currency})</div>
              </div>
            ),
            headerClassName: 'justify-content-end',
            className: 'align-items-center justify-content-end',
            Cell: ({ original }) => formatNumber(original.storePrice),
            label: `${selectedStore.name} (${selectedStore.currency})`,
          },
          ...customerGroups.map(group => ({
            accessor: group.id,
            Header: (
              <div className="text-right" title={group.name}>
                <div>{group.name}</div>
                <div>Discount</div>
              </div>
            ),
            headerClassName: 'justify-content-end',
            className: 'flex-column align-items-end',
            Cell: ({ original: { prices = [] } = {} }) => {
              const price = prices.find(item => item.groupId === group.id)
              return (
                price && (
                  <div className="text-right">
                    <div>{formatNumber(price.amount || 0)}</div>
                    <div>({price.discount ? `${formatNumber(price.discount.percentage || 0, 0)}` : '0'}%)</div>
                  </div>
                )
              )
            },
            label: group.name,
            sortable: false,
          })),
        ],
      }
    default:
      return {
        key,
        label: 'General View',
        columns: [
          {
            accessor: 'series',
            Header: 'Series',
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            width: 100,
          },
          {
            accessor: 'parentCategory',
            Header: 'Parent Category',
            width: 120,
            Cell: ({ original }) => (
              <span title={original.parentCategory} className="pr-2 ellipsis row-clickable">
                {original.parentCategory}
              </span>
            ),
          },
          {
            accessor: 'childCategory',
            Header: 'Child Category',
            width: 120,
            Cell: ({ original }) => (
              <span title={original.childCategory} className="pr-2 ellipsis row-clickable">
                {original.childCategory}
              </span>
            ),
          },
          {
            accessor: 'moq',
            Header: 'MOQ',
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            width: 80,
            Cell: ({ original }) => formatNumber(original.moq, 0),
          },
          {
            accessor: 'orderProcess',
            Header: 'Process Order',
            width: 120,
          },
          {
            accessor: 'storeDisplayInd ',
            Header: 'Store Display',
            width: 120,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.storeDisplayInd ? 'Yes' : 'No'}
                id={`switchStatus${original.id}`}
                checked={original.storeDisplayInd}
                onChange={event => handleBulkUpdate([original.id], 'storeDisplay', event.target.checked)}
                onClick={event => event.preventDefault()}
                disabled={!allowedToOrderRole()}
              />
            ),
          },
        ],
      }
  }
}
