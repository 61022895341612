import React, { Component } from 'react'

import { Button, Col, FormText, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { PRODUCT_NATURE_VIRTUAL } from 'definitions'
import Card from 'components/Common/Card'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ToasterNotify from 'helpers/ToasterNotify'

import DiscountContainer from 'components/Orders/OrderForm/DiscountContainer'
import OrderItemsModal from 'components/Orders/OrderForm/OrderItemsModal'
import ProductsTable from './ProductsTable'
import SerialNumbersModal from 'components/Orders/OrderForm/SerialNumbersModal'

class PricingRuleField extends Component {
  constructor(props) {
    super(props)
    this.state = { options: [] }
    this.getPricingRuleOptions(props.orderRepository.CRUD.data)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const orderDetails = nextProps.orderRepository.CRUD.data
    if (orderDetails.orderItemPricingRules) nextProps.form.$('pricingRules').set(orderDetails.orderItemPricingRules)
  }
  handlePricingRuleChange = vals => {
    const order = { ...this.props.orderRepository.CRUD.data }
    order.orderItemPricingRules = vals
    this.props.orderItemRepository.applyPricingRule(order.id, { order, pricingRules: vals }, res => {
      this.props.updateOrderData(res.order)
      this.getPricingRuleOptions(res.order)
    })
  }
  getPricingRuleOptions = order => {
    this.props.orderRepository.getPricingRulesOptions(order, options => {
      this.setState({
        options,
      })
    })
  }
  render() {
    const { options } = this.state
    const { form, readOnly } = this.props
    return (
      <ColPRStyled sm={12} className="price-rules">
        <ReactSelect
          disabled={readOnly}
          field={form.$('pricingRules')}
          customLabelKey={['label', 'id']}
          options={{
            isMulti: true,
            valueKey: 'id',
            labelKey: 'label',
            options: options,
          }}
          onChange={this.handlePricingRuleChange}
        />
      </ColPRStyled>
    )
  }
}

class ProductPanel extends Component {
  constructor(props) {
    super(props)
    const order = props.orderRepository.CRUD.data
    this.state = {
      isProductsModalOpen: false,
      isSerialNumbersModalOpen: false,
      discountInd: (order.discount && order.discount.discountInd) || 'NA',
      selected: {},
    }
  }
  handleDeleteItem = item => {
    ToasterNotify.confirm({
      message: (
        <span>
          Are you sure you want to remove order item? <br /> {item.product.product.name}
        </span>
      ),
      title: 'Warning',
      onConfirm: () => this.doDeleteItem(item),
    })
  }
  doDeleteItem = item => {
    const order = this.props.orderRepository.CRUD.data
    const orderId = this.props.form.$('id').value
    const payload = {
      order,
      item,
    }
    this.props.orderItemRepository.removeOrderItem(orderId, payload, res => {
      this.props.form.$('orderItems').set(res.orderItems)
      this.updateOrderData(res, true)
    })
  }
  handleDeleteDevice = (item, deviceIndex) => {
    const newItemObj = { ...item }
    if (newItemObj.devices) {
      const device = newItemObj.devices[deviceIndex]

      ToasterNotify.confirm({
        message: (
          <span>
            Are you sure you want to remove this device? <br /> Serial# {device.serialNumber}
            {newItemObj.devices.length === 1 && (
              <span>
                <br />
                <br /> This will also remove the order item.
              </span>
            )}
          </span>
        ),
        title: 'Warning',
        onConfirm: () => {
          newItemObj.devices.splice(deviceIndex, 1)

          if (newItemObj.devices.length > 0) {
            newItemObj.quantity = newItemObj.devices.length

            // remove serial number as well
            const serialNumberIndex = newItemObj.serialNumbers.findIndex(
              item => item.serialNumber === device.serialNumber
            )
            if (serialNumberIndex > -1) {
              newItemObj.serialNumbers.splice(serialNumberIndex, 1)
            }

            this.handleSubmit(newItemObj, true)
          } else {
            this.doDeleteItem(item)
          }
        },
      })
    }
  }
  handleQuantityChange = (value, item) => {
    item.quantity = parseInt(value, 10)
    this.handleSubmit(item, true)
  }
  handleOrderItemSerialNumbers = orderItem => {
    this.updateOrderItemsData([orderItem])
    this.toggleSerialNumbersModal()
  }
  handleSubmit = (item, updateShippingTotal) => {
    const { readOnly, orderRepository, orderItemRepository } = this.props
    const orderItems = orderRepository.CRUD.data.orderItems
    if (!readOnly) {
      orderItemRepository.patchOrderItem(
        { order: { ...orderRepository.CRUD.data, orderItems }, item },
        item.id,
        res => {
          this.updateOrderData(res.order, updateShippingTotal)
        }
      )
    }
  }
  handleOrderItemChangeCustomDiscountedPrice = (val, index) => {
    const orderId = this.props.form.$('id').value
    const order = { ...this.props.orderRepository.CRUD.data }
    let orderItems = [...order.orderItems]
    if (orderItems[index].id) {
      orderItems[index].customDiscountedPrice = val
      this.props.orderItemRepository.patchDiscount(
        {
          order,
          item: orderItems[index],
          discount: {
            discountInd: this.state.discountInd,
          },
        },
        orderId,
        res => {
          this.updateOrderData(res.order)
        }
      )
    }
  }
  submitOrderItem = (product, callback = null, isCustomOptions = false) => {
    const orderId = this.props.form.$('id').value
    const order = { ...this.props.orderRepository.CRUD.data }
    if (isCustomOptions) {
      this.props.orderItemRepository.patchOrderItem(
        {
          order,
          item: product,
        },
        orderId,
        result => {
          this.updateOrderData(result.order, true)
          if (callback) callback()
        }
      )
    } else {
      this.props.orderItemRepository.create(
        {
          order,
          items: [product],
        },
        orderId,
        result => {
          this.updateOrderData(result.order, true)
          this.toggleProductModal()
          if (callback) callback()
        }
      )
    }
  }
  toggleProductModal = () => {
    this.setState({
      isProductsModalOpen: !this.state.isProductsModalOpen,
    })
  }
  toggleSerialNumbersModal = selected => {
    this.setState({
      selected,
      isSerialNumbersModalOpen: !this.state.isSerialNumbersModalOpen,
    })
  }
  updateOrderData = (order, updateShippingTotal) => {
    this.props.orderRepository.CRUD.data = order
    this.props.orderRepository.Discounts.data = order.discountDetails
    // this.props.orderItemRepository.Table.data = order.orderItems
    this.props.orderItemRepository.Table.originalData = {
      orderItems: order.orderItems,
      totalAmount: 0,
    }
    this.props.computeOrderSummary(updateShippingTotal)
  }
  updateOrderItemsData = (orderItems, compute) => {
    const order = this.props.orderRepository.CRUD.data
    const itemIds = orderItems.map(item => item.id)
    order.orderItems = [...orderItems, ...(order.orderItems || []).filter(item => !itemIds.includes(item.id))]
    this.props.orderItemRepository.Table.data = order.orderItems
    this.props.orderItemRepository.Table.originalData = {
      orderItems: order.orderItems,
      totalAmount: 0,
    }
    compute && this.props.computeOrderSummary()
  }
  setDiscountInd = discountInd => {
    this.setState({
      discountInd: discountInd,
    })
  }
  renderStar = () => {
    return <span style={{ color: '#ffb81c' }}>★</span>
  }
  renderSerialNumbers = item => {
    if (item.product.product.productNature.value === PRODUCT_NATURE_VIRTUAL) {
      if (!item.serialNumbers || item.serialNumbers.length !== item.quantity) {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className="text-danger d-flex align-items-center"
              onClick={() => {
                this.toggleSerialNumbersModal(item)
              }}
            >
              <i className="icon ion-md-warning small" style={{ marginRight: '5px' }} />
              Please Configure
            </Button>
          </CellStyled>
        )
      } else {
        return (
          <CellStyled>
            <Button
              color="light"
              size="xs"
              className="d-flex align-items-center"
              onClick={() => {
                this.toggleSerialNumbersModal(item)
              }}
            >
              Configured
            </Button>
          </CellStyled>
        )
      }
    } else {
      return (
        <CellStyled>
          <div className="cell-sn">N/A</div>
        </CellStyled>
      )
    }
  }
  render() {
    const { discountInd } = this.state
    const { form, orderRepository, readOnly, readOnlyCoupon } = this.props
    const orderDetails = orderRepository.CRUD.data
    const orderItems = orderDetails.orderItems || []
    const store = form.$('store').value

    const field = form.$('orderItems')
    const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)

    return (
      <Card title="Products">
        <ProductsTable
          form={form}
          discountInd={discountInd}
          readOnly={readOnly}
          handleOrderItemChangeCustomDiscountedPrice={this.handleOrderItemChangeCustomDiscountedPrice}
          handleDeleteDevice={this.handleDeleteDevice}
          renderStar={this.renderStar}
        />
        {hasError && (
          <FormText color="danger" style={{ marginTop: '-15px' }}>
            {field.error}
          </FormText>
        )}
        <Row>
          <Col sm={10}>
            <p>Total {orderItems.length} product(s)</p>
          </Col>
          {orderDetails.hasOwnProperty('appliedCoupon') && (
            <Col sm={2} style={{ fontSize: 12 }}>
              {this.renderStar()} Coupon Code has been applied
            </Col>
          )}
        </Row>
        <PricingRuleField {...this.props} updateOrderData={this.updateOrderData} readOnly={readOnly} />
        <DiscountContainer
          form={form}
          updateOrderData={this.updateOrderData}
          store={store}
          orderItems={orderItems}
          order={orderDetails}
          readOnly={readOnly}
          readOnlyCoupon={readOnlyCoupon}
          setDiscountInd={this.setDiscountInd}
          computeOrderSummary={this.props.computeOrderSummary}
        />
        <OrderItemsModal
          open={this.state.isProductsModalOpen}
          onClose={this.toggleProductModal}
          form={form}
          order={orderDetails}
          store={store}
          handleSelected={this.submitOrderItem}
        />
        {this.state.isSerialNumbersModalOpen && (
          <SerialNumbersModal
            open={this.state.isSerialNumbersModalOpen}
            onClose={this.toggleSerialNumbersModal}
            handleSubmit={this.handleOrderItemSerialNumbers}
            orderDetails={orderDetails}
            selectedOrderItem={this.state.selected}
          />
        )}
      </Card>
    )
  }
}

const CellStyled = styled.div`
  position: relative;
  > div {
    padding: 0.4rem 5px;
  }
  &.device {
    padding-top: 22px;
  }
`
const ColPRStyled = styled(Col)`
  background: rgba(242, 242, 242, 0.8);
  margin-bottom: 15px;
  padding: 15px;
`

export default inject('orderRepository', 'orderItemRepository', 'shippingRateRepository')(observer(ProductPanel, {}))
