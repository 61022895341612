import { SUBSCRIPTIONS_PATH } from 'definitions'
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { decorate, observable, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import queryString from 'query-string'

import { formatOrderPayload, getFormattedDateTimeString } from 'helpers/Formatters'
import { Visible } from 'helpers/Auth'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'

import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import FormContainer from 'components/Common/Form/Container'
import ToasterNotify from 'helpers/ToasterNotify'

import { getFields } from 'components/Orders/OrderForm/fieldset'
import MobxForm from 'components/Common/Form/index'

import Actions from './Actions'
import ApprovalRequestModal from 'components/Orders/OrderForm/ApprovalRequestModal'
import BillingShippingPanel from 'components/Orders/OrderForm/BillingShippingPanel'
import CommentPanel from 'components/Orders/OrderForm/CommentPanel'
import CommentSidePanel from 'components/Orders/OrderForm/CommentSidePanel'
import ContextMenu from 'components/Common/ContextMenu'
import CustomerPanel from './CustomerPanel'
import MailingList from 'components/Orders/OrderForm/MailingList'
import OrderSummary from 'components/Orders/OrderForm/OrderSummary'
import PaymentPanel from 'components/Orders/OrderForm/PaymentPanel'
import ProductsPanel from './ProductsPanel'

import ShippingMethodPanel from 'components/Orders/OrderForm/ShippingMethodPanel'

import { OrderFormFunctions } from 'components/Orders/OrderForm/'
import activityLogIcon from 'images/activity log.svg'
import AssociatedOrders from './AssociatedOrders'
import commentsIcon from 'images/comment.svg'
import ContractPanel from './ContractPanel'
import SubscriptionDetails from './SubscriptionDetails'

class OrderForm extends Component {
  isToggleSaveQuotationModal = false
  payload = {}
  formAction = ''
  constructor(props) {
    super(props)
    const {
      location: { search },
    } = this.props

    const qs = queryString.parse(search)

    this.state = {
      formErrors: null,
      activeCommentPanelType: '',
      isSaveQuotationModalOpen: false,
      isApprovalRequestModalOpen: false,
      isContract: qs.contract === 'true',
      isLoan: qs.loan === 'true',
      isSub: qs.sub === 'true',
      readOnly: !(qs.edit && qs.edit === 'true'),
    }
    this.computeOrderSummary = _.debounce(this.computeOrderSummary, 350, { leading: false, trailing: true }).bind(this)
  }
  initForm = data => {
    const {
      location: { search },
    } = this.props

    const fields = getFields({
      data,
      requireQuotationDaysValidFor: fieldData => this.requireQuotationDaysValidFor(fieldData),
    })

    this.form = new MobxForm(
      { fields },
      {
        name: 'OrderForm',
        hooks: {
          onSuccess: form => {
            if (this.isToggleSaveQuotationModal) {
              this.toggleSaveQuotationModal()
            } else {
              this.handleSubmit(form)
            }
            this.isToggleSaveQuotationModal = false
          },
          onError: this.handleFormError,
        },
      }
    )
    const qs = queryString.parse(search)
    this.setState(
      {
        isContract: qs.contract === 'true',
        isLoan: qs.loan === 'true',
        isSub: qs.sub === 'true',
      },
      () => {
        if (this.state.isLoan) {
          this.updateOrderSummary({
            shippingTotal: 0,
            shippingInsurance: 0,
            grandTotal: 0,
            currency: '',
            paymentCharge: 0,
            subTotal: 0,
            subscriptionBalance: 0,
            subscriptionBalanceDue: 0,
          })
        }
      }
    )
    if (data.id && data.orderSummary) {
      this.updateOrderSummary(data.orderSummary)
    }
  }
  componentDidMount() {
    this.initForm(this.props.data)
  }
  componentWillReceiveProps(nextProps) {
    const prevData = Object.assign({}, this.props)
    const nextData = Object.assign({}, nextProps)
    const {
      location: { search },
    } = nextData

    const qs = queryString.parse(search)

    if (JSON.stringify(prevData.location) !== JSON.stringify(nextData.location)) {
      this.setState({
        isContract: qs.contract === 'true',
        isLoan: qs.loan === 'true',
        isSub: qs.sub === 'true',
      })
    }
  }

  componentDidUpdate(prevProps) {
    const prevData = Object.assign({}, prevProps)
    const nextData = Object.assign({}, this.props)
    const errors = toJS(this.props.orderRepository.CRUD.errors)
    const prevErrors = toJS(prevProps.orderRepository.CRUD.errors)
    const statusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const prevStatusErrors = toJS(this.props.orderRepository.OrderStatus.errors)
    const invalidateField = err =>
      err.fieldName && this.form.has(err.fieldName) && this.form.$(err.fieldName).invalidate(err.message)

    if (errors !== prevErrors) {
      errors.filter(invalidateField)
    }
    if (statusErrors !== prevStatusErrors) {
      statusErrors.filter(invalidateField)
    }

    if (JSON.stringify(prevData.data.orderSummary) !== JSON.stringify(nextData.data.orderSummary)) {
      this.updateOrderSummary(nextData.data.orderSummary)
    }
  }
  gotoOrders = () => {
    this.props.history.push(SUBSCRIPTIONS_PATH)
  }
  handleCancel = () => {
    this.props.history.push(SUBSCRIPTIONS_PATH)
  }
  handleCancelOrder = () => {
    const {
      orderRepository: {
        cancelOrder,
        CRUD: { data },
      },
    } = this.props
    ToasterNotify.confirm({
      message: 'Are you sure you want to cancel this order?',
      title: 'Warning',
      onConfirm: () => {
        cancelOrder(data.id).then(res => {
          this.handleCancel()
        })
      },
    })
  }
  handleFormError = form => {
    this.setState({
      formErrors: form.hasError
        ? [{ message: 'There are validation errors in this order. Please review your order' }]
        : null,
    })
  }
  handleSubmit = form => {
    const values = { ...toJS(form.values()) }
    const { data = {} } = this.props
    const {
      orderSubscriptionRepository: { saveAsDraft, patch, create, OrderRates },
    } = this.props

    const payload = {
      ...formatOrderPayload({
        ...data,
        ...values,
        payment: {
          ...values.payment,
          additionalCharge: OrderRates.data.additionalCharge || 0,
        },
        ...(values.groups && { groups: values.groups }),
      }),
      ...(data.id && {
        orderSubscription: {
          ...data.orderSubscription,
          upfrontPayment: parseFloat(values.orderSubscription.upfrontPayment),
        },
      }),
    }
    const type = this.formAction
    const doPatch = type === 'draft' ? saveAsDraft : patch
    const submit = () => {
      if (data.id) {
        doPatch(
          payload,
          () => {
            this.setState({ readOnly: true })
            this.props.history.push(SUBSCRIPTIONS_PATH)
          },
          data.id
        )
      } else
        create(
          {
            store: payload.store,
            customer: payload.customer,
            orderSubscription: values,
          },
          res => {
            this.props.history.push({
              pathname: `${SUBSCRIPTIONS_PATH}/${res.id}`,
              search: '?contract=true&edit=true',
            })
          }
        )
    }
    submit()
  }
  handleOpenDrawer = type => {
    this.setState({ activeCommentPanelType: type }, () => {
      this.drawer && this.drawer.openDrawer('comments')
    })
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  handleDiscardChanges = () => {
    const {
      match: { params },
      orderRepository: { getById },
    } = this.props

    getById(params.orderId, result => {})
  }
  computeOrderSummary = (updateShippingTotal, updatePayment, updateShippingInsurance) =>
    OrderFormFunctions.computeOrderSummary(updateShippingTotal, updatePayment, updateShippingInsurance, this)

  updateOrderSummary = orderSummaryData => OrderFormFunctions.updateOrderSummary(orderSummaryData, this)
  requireQuotationDaysValidFor = ({ field }) => {
    if (this.state && this.state.isSaveQuotationModalOpen) {
      return [field.value > 0, 'This field is required']
    }
    return [true]
  }
  toggleApprovalRequestModal = () => {
    this.setState({ isApprovalRequestModalOpen: !this.state.isApprovalRequestModalOpen })
  }
  setFieldValidations = (unset = false) => OrderFormFunctions.setFieldValidations(unset, null, this)
  updateBankAccount = country => OrderFormFunctions.updateBankAccount(country, this)
  validateForm = () => {
    const {
      orderRepository: {
        OrderRates,
        CRUD: { data },
        patch,
      },
    } = this.props
    const values = this.form.values()

    const payload = {
      ...formatOrderPayload({
        ...values,
        payment: {
          ...values.payment,
          additionalCharge: OrderRates.data.additionalCharge || 0,
        },
        ...(values.groups.length ? { groups: values.groups } : { groups: [] }),
      }),
      orderItems: data.orderItems,
      orderSubscription: {
        ...data.orderSubscription,
        upfrontPayment: parseInt(values.orderSubscription.upfrontPayment, 10),
      },
    }
    this.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        patch(
          payload,
          () => {
            this.setState({ readOnly: true })
            this.handleCancel()
          },
          data.id
        )
      }
    })
    this.handleFormError(this.form)
  }
  render() {
    const {
      breadcrumbs,
      title,
      commonRepository,
      customerRepository,
      customerAddressRepository,
      customerContactRepository,
      storeRepository,
      orderRepository: {
        CRUD: { errors, loading },
        CRUD,
        OrderStatus,
      },
      orderRepository,
      sanctionCustomerRepository,
      bankAccountRepository,
      quotation,
      data = {},
    } = this.props
    const { isApprovalRequestModalOpen, readOnly, isContract, isLoan, isSub } = this.state
    const isShippingApplicable = CRUD.data && CRUD.data.virtualProductOnlyInd
    let { formErrors } = this.state
    const repositoryErrors = errors.filter(err => !err.fieldName) || OrderStatus.errors.filter(err => !err.fieldName)
    const repositoryValidationError =
      errors.some(err => err.fieldName != null) || OrderStatus.errors.some(err => err.fieldName != null)
        ? errors.map(i => ({
            message: i.message,
          })) ||
          OrderStatus.errors.map(i => ({
            message: i.message,
          }))
        : null
    const isSubmitted = CRUD.data && CRUD.data.orderNumber
    if (!this.form) return false

    return (
      <React.Fragment>
        <div className="content">
          <ContentContainer
            promptOnExit={!readOnly}
            breadcrumbs={breadcrumbs}
            title={
              <div className="d-inline-flex align-items-start">
                <h4 className="m-0">{readOnly ? title : `Edit ${title}`}</h4>
                {data.orderSource && (
                  <span
                    className="btn btn-sm ml-4 px-4"
                    style={{ backgroundColor: 'rgba(0,0,0,0.1)', fontSize: '0.75rem' }}
                  >
                    {data.orderSource}
                  </span>
                )}
              </div>
            }
            actions={
              <Actions
                readOnly={readOnly}
                data={data}
                form={this.form}
                quotation={quotation}
                loading={loading}
                handleEditMode={() => this.setState({ readOnly: false })}
                handleSaveOrder={({ isToggleSaveQuotationModal }) => {
                  this.setFieldValidations()
                  if (isToggleSaveQuotationModal) {
                    this.formAction = 'quotation'
                    this.validateForm()
                  } else {
                    this.formAction = 'submit'
                    this.validateForm()
                  }
                }}
                handleCancel={this.handleCancelOrder}
                handleDiscardChanges={this.handleDiscardChanges}
                handleSaveDraft={() => {
                  this.formAction = 'draft'
                  this.form.submit()
                }}
                isSubmitted={isSubmitted}
              />
            }
          >
            <Row>
              <Col sm={12}>
                <FormContainer
                  title="Order Details"
                  onSubmit={() => this.handleSubmit(this.form)}
                  errors={formErrors || repositoryValidationError || repositoryErrors}
                  plain
                >
                  <Row>
                    <Col sm={12}>
                      <CustomerPanel
                        data={data}
                        form={this.form}
                        customerRepository={customerRepository}
                        commonRepository={commonRepository}
                        storeRepository={storeRepository}
                        sanctionCustomerRepository={sanctionCustomerRepository}
                        step={2}
                        computeOrderSummary={this.computeOrderSummary}
                        readOnly={readOnly}
                        isLoan={isLoan}
                      />
                    </Col>
                  </Row>
                  <>
                    <ProductsPanel
                      form={this.form}
                      quotation={quotation}
                      computeOrderSummary={this.computeOrderSummary}
                      readOnly={readOnly}
                      columnSet="subscriptions"
                      showDiscount={false}
                      orderRepository={orderRepository}
                      storeRepository={storeRepository}
                      orderItems={data.associatedProducts}
                      orderDetails={data}
                      isContract={isContract || isLoan}
                      isLoan={isLoan}
                    />

                    {!isContract && !isLoan && <AssociatedOrders data={data} />}
                    {((isContract && !isSub) || !isContract) && (
                      <SubscriptionDetails
                        isContract={isContract}
                        readOnly={readOnly}
                        data={data}
                        computeOrderSummary={this.computeOrderSummary}
                        form={this.form}
                      />
                    )}

                    {isContract || isLoan ? (
                      <React.Fragment>
                        <Row>
                          <Col sm="9">
                            <BillingShippingPanel
                              readOnly={readOnly}
                              form={this.form}
                              commonRepository={commonRepository}
                              customerContactRepository={customerContactRepository}
                              customerAddressRepository={customerAddressRepository}
                              copyBilling={this.copyBilling}
                              toggleBillingContact={this.toggleBillingContact}
                              toggleBillingAddress={this.toggleBillingAddress}
                              computeOrderSummary={this.computeOrderSummary}
                              isShippingApplicable={isShippingApplicable}
                              updateBankAccount={this.updateBankAccount}
                            />
                          </Col>
                          <Col sm="3">
                            <ShippingMethodPanel
                              readOnly={readOnly}
                              form={this.form}
                              commonRepository={commonRepository}
                              storeRepository={orderRepository}
                              computeOrderSummary={this.computeOrderSummary}
                              isShippingApplicable={isShippingApplicable}
                            />
                            <PaymentPanel
                              orderSource={data.orderSource}
                              orderType={data.orderType}
                              readOnly={readOnly}
                              form={this.form}
                              orderRepository={orderRepository}
                              commonRepository={commonRepository}
                              bankAccountRepository={bankAccountRepository}
                              computeOrderSummary={this.computeOrderSummary}
                              summary={orderRepository.OrderRates.data}
                              updateBankAccount={this.updateBankAccount}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col sm="7">
                            <CommentPanel
                              readOnly={readOnly}
                              field={this.form.$('customerComments')}
                              label="Customer Comments"
                              buttonColor="success"
                              className="mb-2"
                              handleOpenDrawer={() => this.handleOpenDrawer('CUSTOMER')}
                            />
                            <CommentPanel
                              readOnly={readOnly}
                              field={this.form.$('internalRemarks')}
                              label="Internal Remarks"
                              buttonColor="secondary"
                              className="mb-2"
                              handleOpenDrawer={() => this.handleOpenDrawer('INTERNAL')}
                            />
                            <MailingList readOnly={readOnly} form={this.form} />
                          </Col>
                          <Col sm="5" className="d-flex">
                            <OrderSummary form={this.form} storeRepository={orderRepository} subscription />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="d-flex align-items-end">
                            <small>{`Created by ${data.createdBy} ${getFormattedDateTimeString(
                              data.createdDate
                            )}`}</small>
                          </Col>
                          {data.status !== 'CANCELLED' && !isSubmitted && (
                            <Visible toOrderProcessRole>
                              <Col xs="6" className="d-flex justify-content-end">
                                <ContextMenu
                                  disabled={loading || readOnly}
                                  dropDownToggle="Actions"
                                  menus={[
                                    {
                                      text: 'Save Contract',
                                      onClick: () => {
                                        this.formAction = 'submit'
                                        this.setFieldValidations()
                                        this.validateForm()
                                      },
                                    },
                                    {
                                      text: 'Save as Draft',
                                      onClick: () => {
                                        this.formAction = 'draft'
                                        this.setFieldValidations(true)
                                        this.form.submit()
                                      },
                                      hidden: data.status === 'PENDING_PAYMENT',
                                    },
                                  ]}
                                />
                              </Col>
                            </Visible>
                          )}
                        </Row>
                      </React.Fragment>
                    ) : (
                      <ContractPanel data={data} />
                    )}
                  </>
                </FormContainer>
              </Col>
            </Row>
          </ContentContainer>
        </div>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="Order"
                  entityId={data.id}
                  entityLabel="Subscription"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  orderId={data.id}
                  activeType={this.state.activeCommentPanelType}
                  handleCloseDrawer={this.handleCloseDrawer}
                  form={this.form}
                />
              ),
            },
          ]}
        />
        {isApprovalRequestModalOpen && (
          <ApprovalRequestModal
            form={this.form}
            order={CRUD.data}
            open={isApprovalRequestModalOpen}
            onConfirm={this.gotoOrders}
            onClose={() => {
              this.setFieldValidations(true)
              this.toggleApprovalRequestModal()
            }}
            formAction={this.formAction}
            handleCancel={this.handleCancel}
            store={this.store}
          />
        )}
      </React.Fragment>
    )
  }
}

export default inject(
  'orderItemRepository',
  'commonRepository',
  'customerRepository',
  'customerAddressRepository',
  'customerContactRepository',
  'customerGroupRepository',
  'customerGroupDetailsRepository',
  'storeRepository',
  'shippingRateRepository',
  'sanctionCustomerRepository',
  'bankAccountRepository',
  // 'orderRepository',
  'storeRepository'
)(
  decorate(observer(OrderForm), {
    store: observable,
    form: observable,
  })
)
