/* @flow */
import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import SanctionServices, { SanctionCustomerServices } from 'services/sanction'

class SanctionRepository extends BaseRepository {
  service: SanctionServices
  constructor(service: SanctionServices) {
    super(service)
    this.service = service
  }
}

export class SanctionCustomerRepository extends SanctionRepository {
  service: SanctionCustomerServices
  constructor(service: SanctionCustomerServices) {
    super(service)
    this.service = service
  }
}

export default decorate(SanctionRepository, {})
