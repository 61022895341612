import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'

class OrderCommentRepository extends BaseRepository {
  constructor(service) {
    super(service)
    this.service = service
  }
}

export default decorate(OrderCommentRepository, {})
