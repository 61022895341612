import React, { Component } from 'react'

import { Button, Col, FormGroup, Label, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { sanitizeFilename } from 'helpers/Common'
import getIconClassNameFromFile from 'helpers/GetIconClassnameFromFile'
import ToasterNotify from 'helpers/ToasterNotify'

class CustomDiscountApproval extends Component {
  uploadFile = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errors: [],
    }
  }
  uploadFiles = files => {
    this.props.cdForm.$('files').set(files)
    this.props.cdForm.submit()
    this.uploadFile.current.value = ''
  }

  replaceAndUploadFiles = async (duplicateFiles, newFiles) => {
    await this.props.onDeleteFiles(duplicateFiles)
    this.uploadFiles(newFiles)
  }

  handleFileChange = () => {
    const files = [...this.uploadFile.current.files]
    if (files.length) {
      const existingFiles = this.props.files || []
      let duplicateFiles = []

      existingFiles.map(existingFile => {
        const duplicateFile = files.find(item => sanitizeFilename(item.name) === existingFile.fileName)
        duplicateFile && duplicateFiles.push(existingFile)
      })

      if (duplicateFiles.length) {
        const message =
          duplicateFiles.length > 1
            ? 'The following files already exist, do you want to replace them?'
            : 'The file already exists, do you want to replace it?'
        ToasterNotify.confirm({
          message: (
            <div>
              <div className="mb-2">{message}</div>
              <ul>
                {duplicateFiles.map(item => (
                  <li key={item.id}>{item.fileName}</li>
                ))}
              </ul>
            </div>
          ),
          title: `Replace existing file${duplicateFiles.length > 1 ? 's' : ''}`,
          onConfirm: () => {
            this.replaceAndUploadFiles(duplicateFiles, files)
          },
          onCancel: () => {
            this.uploadFile.current.value = ''
          },
        })
      } else {
        this.uploadFiles(files)
      }
    } else {
      this.props.cdForm.$('files').invalidate('This field is required')
    }
  }

  render() {
    const { files = [], onDeleteFiles } = this.props
    // const files = this.props.form.$('discountApproval.attachments').value
    return (
      <>
        <FormGroup style={{ borderBottom: '1px solid #CCC' }}>
          <Label>
            Documentation <small>(image file or PDF or DOC max 10MB)</small>
          </Label>
          <RowUploadContainerStyled className="form-control d-flex m-l-1">
            <input
              type="file"
              className="d-none"
              onChange={this.handleFileChange}
              ref={this.uploadFile}
              multiple="multiple"
            />
            <Col sm={8} className="file-text">
              {files.length ? `${files.length} File(s) selected` : 'No File(s) selected'}
              {/* ? values.map(v => v.name).J || value.fileName */}
            </Col>
            <Col sm={4} className="file-action text-right">
              <ButtonUploadStyled
                color="default"
                size="sm"
                type="button"
                onClick={() => {
                  this.uploadFile.current.click()
                }}
              >
                Select File
              </ButtonUploadStyled>
            </Col>
          </RowUploadContainerStyled>
          {/*
          {file.errors ? <FormText color="danger">{file.errors.map(e => e.message).join(', ')}</FormText> : null}
          */}
        </FormGroup>
        <FormGroup>
          <AttachmentColStyled lg={12} className="file-container">
            {!!files.length &&
              files.map(file => (
                <AttachmentItemColStyled key={file.id} className="file-item">
                  <div className="file-type d-flex align-items-center">
                    <i className={`icon ion-md-${getIconClassNameFromFile(file)} px-1`} />
                  </div>
                  <div className="file-details">
                    <div className="file-name">{file.fileName}</div> <div className="file-status">{file.fileSize}</div>
                  </div>
                  <button className="file-action p-0" onClick={() => onDeleteFiles([file])}>
                    <i className="icon ion-ios-trash" />
                  </button>
                </AttachmentItemColStyled>
              ))}
          </AttachmentColStyled>
        </FormGroup>
      </>
    )
  }
}

export const RowUploadContainerStyled = styled(Row)`
  border: none !important;
  // border-bottom: 1px solid #ccc !important;
  font-size: 11px !important;
  .file-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    padding-left: 10px;
  }
  .file-action {
    padding: 0 !important;
  }
`

export const ButtonUploadStyled = styled(Button)`
  margin-top: -10px;
  font-size: 11px !important;
  border-radius: 20px !important;
  background-color: rgba(230, 230, 230, 0.87) !important;
`

const AttachmentColStyled = styled.div`
  margin-top: 10px;
  min-height: 50px;
  max-height: 100px;
  border: 1px solid #ccc;
  padding: 2px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
`

const AttachmentItemColStyled = styled.div`
  &.file {
    &-item {
      background-color: #f5f5f5;
      max-width: 280px;
      border: 1px solid #e7e7e7;
      display: inline-flex;
      border-radius: 3px;
      margin: 4px 8px;
      position: relative;
      &:hover {
        .file-action {
          display: block;
        }
      }
      .file {
        &-type {
          border: 1px solid #e7e7e7;
          display: inline-block;
        }
        &-details {
          width: auto;
          display: inline-block;
          padding: 2px 5px;
          font-size: 9pt;
        }
        &-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-status {
          font-size: 8pt;
          color: #999;
        }
        &-action {
          display: none;
          width: 30px;
          position: absolute;
          right: 0;
          background-color: rgba(255, 184, 28, 1);
          height: 100%;
          cursor: pointer;
          margin: 0 !important;
          padding: 10px 4px;
        }
      }
    }
  }
`

export default inject(
  'fileUploadRepository',
  'orderRepository',
  'orderItemRepository'
)(observer(CustomDiscountApproval))
