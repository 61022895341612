import { CustomInput } from 'reactstrap'
import React from 'react'

export const getView = ({ key, handleBulkUpdate, allowedToAppAdminRole }) => {
  switch (key) {
    case 'customer':
      return {
        key,
        label: 'Customer',
        sort: { id: 'customerName', asc: true },
        columns: [
          {
            headerClassName: 'px-0',
            accessor: 'access',
            resizable: false,
            width: 20,
            fixed: 'leftmost',
            getProps: (state, { original = {} } = {}) => {
              return {
                style: {
                  padding: 0,
                  borderLeft: `5px solid ${original.access === 'blacklist' ? '#000' : '#FFF'}`,
                },
              }
            },
            Cell: () => '',
          },
          {
            accessor: 'customerName',
            Header: 'Customer',
          },
          {
            accessor: 'onlineOrder',
            Header: 'Online Order',
            width: 150,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            sortable: false,
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.onlineOrder ? 'Yes' : 'No'}
                id={`switchOnlineOrder${original.id}`}
                className="mr-2"
                checked={original.onlineOrder}
                onChange={event => handleBulkUpdate([original.id], 'onlineOrder', event.target.checked)}
                disabled={!allowedToAppAdminRole()}
              />
            ),
          },
          {
            accessor: 'storeDisplay',
            Header: 'Store Display',
            width: 150,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            sortable: false,
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.storeDisplay ? 'Yes' : 'No'}
                id={`switchStatus${original.id}`}
                className="mr-2"
                checked={original.storeDisplay}
                onChange={event => handleBulkUpdate([original.id], 'storeDisplay', event.target.checked)}
                disabled={!allowedToAppAdminRole()}
              />
            ),
          },
        ],
      }
    case 'user':
      return {
        key,
        label: 'User Login',
        sort: { id: 'customer', asc: true },
        columns: [
          {
            accessor: 'customer',
            Header: 'Customer',
          },
          {
            accessor: 'login',
            Header: 'Login',
          },
        ],
      }
    default:
      return {
        key: 'country',
        label: 'Country',
        sort: { id: 'countryCode', asc: true },
        columns: [
          {
            accessor: 'countryCode',
            Header: 'Code',
          },
          {
            accessor: 'countryLabel',
            Header: 'Country',
            headerClassName: 'flex-fill w-100',
            className: 'flex-fill w-100',
          },
          {
            accessor: 'onlineOrder',
            Header: 'Online Order',
            width: 150,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            sortable: false,
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.onlineOrder ? 'Yes' : 'No'}
                id={`switchOnlineOrder${original.id}`}
                className="mr-2"
                checked={original.onlineOrder}
                onChange={event => handleBulkUpdate([original.id], 'onlineOrder', event.target.checked)}
                disabled={!allowedToAppAdminRole()}
              />
            ),
          },
          {
            accessor: 'storeDisplay',
            Header: 'Store Display',
            width: 150,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            sortable: false,
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.storeDisplay ? 'Yes' : 'No'}
                id={`switchStatus${original.id}`}
                className="mr-2"
                checked={original.storeDisplay}
                onChange={event => handleBulkUpdate([original.id], 'storeDisplay', event.target.checked)}
                disabled={!allowedToAppAdminRole()}
              />
            ),
          },
          {
            accessor: 'directShipment',
            Header: 'Direct Shipment',
            width: 150,
            headerClassName: 'justify-content-center',
            className: 'justify-content-center',
            sortable: false,
            Cell: ({ original }) => (
              <CustomInput
                type="switch"
                label={original.directShipment ? 'Yes' : 'No'}
                id={`switchDirectShipment${original.id}`}
                className="mr-2"
                checked={original.directShipment}
                onChange={event => handleBulkUpdate([original.id], 'directShipment', event.target.checked)}
                disabled={!allowedToAppAdminRole()}
              />
            ),
          },
        ],
      }
  }
}
