import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import { formatOrganizationNumber } from 'helpers/Formatters'
import { FormGroup } from 'reactstrap'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'
import styled from 'styled-components'

class OrganizationNumbersModal extends Component {
  state = {
    submitting: false,
    errors: [],
  }

  constructor(props) {
    super(props)

    const {
      selectedOrderItem: { ic2Organizations },
      readOnly,
    } = props
    const fields = {
      organizationNumbers: {
        label: !readOnly ? 'Organization Code' : '',
        value: ic2Organizations ? ic2Organizations.map(item => item.org_code).join('\n') : null,
        rules: 'required',
        placeholder: 'Use ENTER key to add multiple organization code(s)',
      },
    }

    this.form = new Form({ fields }, { name: 'OrderItemOrganizationNumbers', handleSubmit: this.handleSubmit })
  }
  formatOrganizationNumbers = () => {
    const organizationNumbers = this.form
      .$('organizationNumbers')
      .values()
      .trim()
      .split('\n')
      .filter(item => !!item)
      .map(serialNumber => formatOrganizationNumber(serialNumber))
      .join('\n')

    this.form.$('organizationNumbers').set(organizationNumbers)
  }
  handleSubmit = async () => {
    const { orderItemRepository, orderDetails, selectedOrderItem, handleSubmit, onClose } = this.props
    const { organizationNumbers } = this.form.values()
    const org_codes = organizationNumbers.split('\n')
    const ic2Organizations = org_codes.map(item => ({ org_code: item }))

    const payload = {
      id: orderDetails.id,
      orderItems: [
        ...[{ ...selectedOrderItem, ic2Organizations }],
        ...(orderDetails.orderItems || []).filter(item => item.id != selectedOrderItem.id),
      ],
      org_codes,
    }

    this.setState({ errors: [], submitting: true })
    const { data, errors } = await orderItemRepository.validateOrganizationNumbers(payload)
    this.setState({ errors, submitting: false })

    if (!errors?.length && data?.orderItems?.length) {
      const [{ ic2Organizations: ic2OrganizationsData }] = data.orderItems

      selectedOrderItem.ic2Organizations = ic2OrganizationsData
      handleSubmit(selectedOrderItem.ic2Organizations)
      onClose()
    }
  }
  triggerSubmit = () => {
    this.buttonSubmit.click()
  }
  render() {
    const { open, onClose, readOnly } = this.props
    return (
      <Modal
        title={readOnly ? 'Organization Code(s)' : 'Enter Organization Code(s)'}
        loading={this.state.submitting}
        open={open}
        closeLabel={readOnly ? 'Close' : 'Cancel'}
        hideConfirm={!allowedToOrderRole() || readOnly}
        onClose={onClose}
        onConfirm={this.triggerSubmit}
      >
        <FormContainer
          onSubmit={e => {
            e.stopPropagation()
            if (!readOnly) {
              this.formatOrganizationNumbers()
              this.form.onSubmit(e, { onSuccess: this.handleSubmit })
            }
          }}
          errors={this.state.errors}
          plain
        >
          <FormGroupStyled>
            <Input
              field={this.form.$('organizationNumbers')}
              type="textarea"
              rows="10"
              onBlur={() => {
                !readOnly && this.formatOrganizationNumbers()
              }}
              readOnly={readOnly || this.state.submitting}
            />
          </FormGroupStyled>
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

export default decorate(inject('orderItemRepository')(observer(OrganizationNumbersModal)), {})

const FormGroupStyled = styled(FormGroup)`
  &.form-group {
    textarea.form-control {
      min-height: 150px;
      height: ${({ nolabel, haserror }) => (nolabel ? (haserror ? 'calc(100% - 15px)' : '100%') : 'auto')};
    }
  }
`
