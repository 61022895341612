import React from 'react'

import { Modal as BootstrapModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FormActions from 'components/Common/Buttons/FormActions'

const Modal = ({
  open,
  size = 'md',
  title = 'Dialog',
  backdrop = 'static',
  children,
  confirmLabel = 'OK',
  confirmIcon = '',
  closeLabel = 'Cancel',
  closeIcon = '',
  onConfirm,
  onClose,
  loading = false,
  loadingMessage = null,
  disabled = false,
  className,
  wrapClassName,
  hideConfirm,
  hideCancel,
  centered,
  onOpened,
}) => {
  return (
    <BootstrapModal
      className={className}
      wrapClassName={wrapClassName}
      backdrop={backdrop}
      isOpen={open}
      size={size}
      toggle={onClose}
      centered={centered}
      onOpened={onOpened}
    >
      <ModalHeader className="border-0" toggle={onClose}>
        {title}
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter className="border-0">
        <FormActions
          loading={loading}
          loadingMessage={loadingMessage}
          confirmLabel={confirmLabel}
          confirmIcon={confirmIcon}
          {...(onConfirm && { onConfirm: onConfirm })}
          cancelLabel={closeLabel}
          closeIcon={closeIcon}
          onCancel={onClose}
          disabled={disabled}
          hideConfirm={hideConfirm}
          hideCancel={hideCancel}
        />
      </ModalFooter>
    </BootstrapModal>
  )
}

export default Modal
