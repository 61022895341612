/* @flow */
import { observer } from 'mobx-react'
import React, { Component } from 'react'

import InputFile from 'components/Common/Form/InputFile'

class ImportFileItem extends Component {
  constructor(props) {
    super(props)

    const { fieldItem, fileType } = props

    //To store csv file object
    fieldItem.add({
      key: 'file',
      rules: fileType.required ? 'required' : '',
    })

    //To store filename
    fieldItem.add({
      key: 'filename',
      label: fileType.label,
      rules: fileType.required ? 'required' : '',
    })
  }
  handleFileChange = event => {
    const { files } = event.target
    const { fieldItem } = this.props
    if (files.length > 0) {
      const file = files[0]

      fieldItem.$('file').set('value', file)
    } else {
      this.removeFile()
    }
  }
  removeFile = () => {
    const { fieldItem } = this.props

    fieldItem.$('file').set('value', '')
    fieldItem.$('filename').set('value', '')
  }
  render() {
    const { fieldItem, fileType } = this.props

    return (
      <div key={fileType.key}>
        <div>
          <InputFile
            field={fieldItem.$('filename')}
            placeholder="Click here to select file..."
            onRemove={this.removeFile}
            onChange={this.handleFileChange}
            accept=".csv"
          />
        </div>
      </div>
    )
  }
}

export default observer(ImportFileItem)
