import cx from 'classnames'
import React from 'react'

import { observer } from 'mobx-react'
import styled from 'styled-components'

import CategoryListItem from './CategoryListItem'

const CategoryList = ({ list = [], className = '', primary = false, onSelectItem, selected }) => {
  return (
    <CategoryStyled className={cx('list-unstyled', className)}>
      {list.map((listItem, index) => (
        <CategoryListItem
          key={index}
          item={listItem}
          primary={primary}
          onSelectItem={onSelectItem}
          selected={selected}
        />
      ))}
    </CategoryStyled>
  )
}

export default observer(CategoryList)

const CategoryStyled = styled.ul`
  .icon {
    font-size: 16px;
    color: ${props => props.theme.primary};
  }
  .active {
    button {
      font-weight: bold;
    }
  }
  button {
    background: none;
    border: none;
    font-size: 12px;
    .icon {
      margin-right: 0.5rem;
    }
    :focus {
      outline: none;
    }
  }
`
