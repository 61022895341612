/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import Loader from 'components/Common/Loader'
import React, { Component } from 'react'
import ShippingDetail from './ShippingDetail'

class ShippingEdit extends Component {
  constructor(props) {
    super(props)

    this.loadShippingDetail()
  }

  loadShippingDetail() {
    const {
      match: {
        params: { id },
      },
      shippingRepository,
    } = this.props

    shippingRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.shippingRepository.clearCrudData()
    this.props.shippingRepository.clearErrorMessages()
  }

  render() {
    const {
      shippingRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <ShippingDetail
        {...this.props}
        title="Edit Shipping"
        breadcrumbLabel={data && data.shippingCarrier ? data.shippingCarrier.label : ''}
        data={data}
      />
    )
  }
}

export default decorate(inject('shippingRepository')(observer(ShippingEdit)), {})
