/* @flow */
import React, { Component } from 'react'

import { BANK_ACCOUNTS_PATH } from 'definitions'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Row } from 'reactstrap'
import BankAccountForm from './BankAccountForm'
import ContentContainer from 'components/Common/ContentContainer'

class BankAccountAdd extends Component {
  componentWillUnmount() {
    this.props.bankAccountRepository.clearCrudData()
    this.props.bankAccountRepository.clearErrorMessages()
  }

  render() {
    return (
      <ContentContainer
        title="Add New Bank Account"
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Bank Accounts',
            href: BANK_ACCOUNTS_PATH,
          },
          {
            label: 'New',
          },
        ]}
        promptOnExit={this.props.bankAccountRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <Row>
          <BankAccountForm {...this.props} isAdd={true} />
        </Row>
      </ContentContainer>
    )
  }
}

export default decorate(inject('bankAccountRepository')(observer(BankAccountAdd)), {})
