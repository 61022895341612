import { decorate } from 'mobx'
import { FormGroup, Label } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'

class ImagePanel extends React.Component {
  state = {
    loading: false,
    errors: [],
  }
  constructor(props) {
    super(props)

    this.fileInput = React.createRef()
  }
  handleFileChange = async files => {
    if (files.length) {
      this.setState({ loading: true })
      const { categoryRepository, categoryId, name } = this.props
      const formFiles = new FormData()
      const file = files[0]
      formFiles.append(name, file)
      const { data } = await categoryRepository.uploadImage(categoryId, formFiles)
      this.props.onChange(name, data)
      this.setState({ loading: false })
    }
  }
  handleImageError = event => {
    event.target.setAttribute('src', require('images/no-image.svg').default)
  }

  handleRemoveImage = imageObj => {
    const { fileUploadRepository, onChange, name, categoryId } = this.props
    const { fileName } = imageObj
    const categoryImageSection = name !== 'image' ? `${name}Image` : name

    if (confirm('Are you sure you want to delete this image?')) {
      this.setState({ loading: true }, async () => {
        const { errors } = await fileUploadRepository.removeModelFile(
          categoryId,
          fileName,
          'category',
          categoryImageSection
        )
        this.setState({ loading: false, errors: errors.length ? errors : [] }, () => {
          onChange(name, {
            resourcePath: null,
          })
        })
      })
    }
  }
  render() {
    const { errors } = this.state
    const { categoryRepository, image = {}, label, size, onToggleModal } = this.props
    const {
      CRUD: { loading },
    } = categoryRepository
    const hasImage = !!image.resourcePath

    return (
      <FormGroup>
        <Label>{label}</Label>
        {!!errors.length && <ErrorsList errors={errors} />}
        <ImageStyled className="d-flex-center">
          {hasImage && (
            <img
              src={`${process.env.REACT_APP_ATTACHMENT_SERVER}${image.resourcePath}`}
              alt={image.fileName || label}
              title={image.fileName}
              onError={this.handleImageError}
            />
          )}
          {this.state.loading && <Loader text="Please wait..." className="loader centered" />}
          <div className="actions centered">
            <IconButton
              title={`${image.resourcePath ? 'Replace image' : 'Upload image'} (auto-save)`}
              icon="md-cloud-upload"
              className={cx('large mr-3', !hasImage && 'no-image')}
              disabled={loading}
              onClick={() => {
                this.fileInput.current.click()
              }}
            />
            {hasImage && (
              <>
                <IconButton
                  title="View image"
                  icon="md-eye"
                  className="large mr-3"
                  disabled={loading}
                  onClick={() => onToggleModal({ ...image, size })}
                />
                <IconButton
                  title="Delete image"
                  icon="md-trash"
                  className="large mr-3"
                  disabled={loading}
                  onClick={() => this.handleRemoveImage({ ...image, size })}
                />
              </>
            )}
          </div>
          <input
            type="file"
            ref={this.fileInput}
            accept="image/*"
            onChange={event => this.handleFileChange(event.target.files)}
            className="collapse"
          />
        </ImageStyled>
      </FormGroup>
    )
  }
}

const ImageStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0;
  border: 1px solid ${props => props.theme.light};
  img {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
  }
  .centered {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    background-color: rgba(255, 255, 255, 0.8);
  }
  .actions {
    button {
      display: none;
      &.no-image {
        display: block;
      }
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
      button {
        display: block;
      }
    }
  }
`
export default decorate(inject('fileUploadRepository')(observer(ImagePanel)), {})
