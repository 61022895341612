import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { allowedTo } from 'helpers/Auth'
import Form from 'components/Common/Form/index'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'

import ImagePanel from 'components/Stores/StoreForm/StoreFrontSlideModal/ImagePanel'

class StoreFrontSlideModal extends Component {
  form = null
  state = {
    isModalOpen: false,
    selected: {},
  }

  constructor(props) {
    super(props)

    const data = props.data || {}
    const { id, name, description, learnMoreLink } = data

    const rules = 'required'
    const fields = {
      id: {
        value: id,
      },
      name: {
        value: name,
        label: 'Name',
        rules,
      },
      description: {
        value: description,
        label: 'Description',
        rules,
        type: 'textarea',
      },
      learnMoreLink: {
        value: learnMoreLink,
        label: 'Learn More Url',
        rules: `${rules}|url`,
      },
    }

    if (props.form) {
      this.form = props.form
    } else {
      this.form = new Form(
        { fields },
        {
          name: 'StorefrontSlideForm',
          hooks: {
            onSuccess: this.handleSubmit,
          },
        }
      )
    }
  }

  handleSubmit = () => {
    const { storefrontSlideRepository, storeId, toggleModal, onReloadTable, editMode } = this.props
    const { create, patch } = storefrontSlideRepository

    const action = editMode ? patch : create
    const payload = this.form.values()

    action(
      payload,
      () => {
        toggleModal()
        onReloadTable()
      },
      storeId
    )
  }

  handleImageOnChange = (name, category) => {
    switch (name) {
      case 'banner':
        this.props.data['bannerImage'] = category['bannerImage']
        break
      case 'mobileBanner':
        this.props.data['mobileBannerImage'] = category['mobileBannerImage']
        break
    }

    this.forceUpdate()
  }

  toggleModal = selected => this.setState({ isModalOpen: !this.state.isModalOpen, selected })

  render() {
    const { storefrontSlideRepository, data, isModalOpen, toggleModal, editMode, onReloadTable } = this.props
    const {
      CRUD: { errors, loading },
    } = storefrontSlideRepository

    return (
      <Modal
        title={`${editMode ? `Edit ` : `Add New `} Storefront Marketing Slide`}
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={() => this.form.submit()}
        hideConfirm={!allowedTo()}
        loading={loading}
        disabled={loading}
      >
        <FormContainer onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })} errors={errors} plain>
          <Input field={this.form.$('name')} />
          <Input field={this.form.$('description')} />
          <Input field={this.form.$('learnMoreLink')} />
          {editMode && (
            <React.Fragment>
              <ImagePanel
                storefrontSlideRepository={storefrontSlideRepository}
                id={data.id}
                image={data.bannerImage}
                name="banner"
                label="Banner Image"
                size="xl"
                onToggleModal={this.toggleModal}
                onReloadTable={onReloadTable}
                onChange={this.handleImageOnChange}
              />
              <ImagePanel
                storefrontSlideRepository={storefrontSlideRepository}
                id={data.id}
                image={data.mobileBannerImage}
                name="mobileBanner"
                label="Mobile Banner Image"
                size="xl"
                onToggleModal={this.toggleModal}
                onReloadTable={onReloadTable}
                onChange={this.handleImageOnChange}
              />
            </React.Fragment>
          )}
        </FormContainer>
        <Modal
          title=""
          size="xl"
          backdrop
          centered
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          hideConfirm
          hideCancel
        >
          <ModalImageStyled
            src={`${process.env.REACT_APP_ATTACHMENT_SERVER}${this.state.selected.resourcePath}`}
            alt={this.state.selected.fileName}
            title={this.state.selected.fileName}
          />
        </Modal>
      </Modal>
    )
  }
}

const ModalImageStyled = styled.img`
  max-width: 100%;
  height: auto;
`

export default inject('storefrontSlideRepository')(observer(StoreFrontSlideModal))
