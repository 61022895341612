/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Row } from 'reactstrap'

import { PAYMENT_ACCOUNTS_PATH } from 'definitions'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'
import PaymentAccountForm from './Form'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class PaymentAccountEdit extends Component {
  constructor(props) {
    super(props)

    this.loadAccount()
  }

  loadAccount = () => {
    const {
      match: {
        params: { id },
      },
      paymentAccountRepository,
    } = this.props

    paymentAccountRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.paymentAccountRepository.clearCrudData()
    this.props.paymentAccountRepository.clearErrorMessages()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      paymentAccountRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer
          title="Edit Payment Account"
          breadcrumbs={[
            {
              label: 'Home',
              href: '/',
            },
            {
              label: 'Payment Accounts',
              href: PAYMENT_ACCOUNTS_PATH,
            },
            {
              label: data ? data.name : '',
            },
          ]}
        >
          <Row>
            <PaymentAccountForm {...this.props} data={data} isAdd={false} />
          </Row>
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="PaymentAccount"
                  entityId={data.id}
                  entityLabel="Payment Account"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="paymentAccount"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('paymentAccountRepository')(observer(PaymentAccountEdit)), {})
