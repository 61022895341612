/* @flow */
import React, { Component } from 'react'

import { decorate, observable, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import PropTypes from 'prop-types'

import ContentContainer from 'components/Common/ContentContainer'

class GlobelErrorCatcher extends Component {
  hasError = false
  error = null
  info = null
  redirect = '/'
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true })
    this.error = error
    this.info = info
    this.redirect = this.updateHref(error, info)
  }

  updateHref = (error, info) => {
    const email = 'ticket-dev@peplink.com'
    const subject = 'Error Reporting'
    const divider = '------------------------------------------------'
    const br = '%0D%0A'
    const thisBody = `(Please don't change any text below this line)
        ${br}
        ${divider}
        ${br}
        User Data: ${toJS(this.props.userRepository.localUserDetails)}
        ${br}
        ${divider}
        ${br}
        URL: ${JSON.stringify(window.location.href)}
        ${br}
        ${divider}
        ${br}
        Error: ${error.message}
        ${br}
        ${divider}
        ${br}
        Stack Trace: ${JSON.stringify(info)}
        `
    return `mailto:${email}?subject=${subject}&body=${thisBody}`
  }

  goToDashboard = () => {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ContentContainer>
          <h1>Something went wrong</h1>
          <p>Errors were found, please refresh the page.</p>
          <p>If errors persist, kindly report using the link below:</p>
          <p>
            <a className="btn btn-sm btn-warning" href={this.redirect}>
              Report
            </a>
          </p>
        </ContentContainer>
      )
    }
    return this.props.children
  }
}

export default inject('userRepository')(
  decorate(observer(GlobelErrorCatcher), {
    hasError: observable,
    error: observable,
    info: observable,
  })
)

GlobelErrorCatcher.propTypes = {
  children: PropTypes.any,
}
