/* eslint-disable max-len */
import React from 'react'

export default () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33751 6.76914C5.58751 6.52539 4.78126 6.58164 4.06876 6.93789C3.35626 7.29414 2.83126 7.91289 2.56876 8.66289C2.04376 10.2191 2.90626 11.9066 4.46251 12.4316C4.76251 12.5254 5.08126 12.5816 5.40001 12.5816C5.86876 12.5816 6.31876 12.4691 6.73126 12.2629C7.44376 11.9066 7.96876 11.2879 8.23126 10.5379C8.73751 9.00039 7.89376 7.29414 6.33751 6.76914Z"
      fill="white"
    />
    <path
      d="M13.0875 4.12525C12.4313 3.919 11.7375 3.9565 11.1188 4.27525C10.5 4.594 10.0313 5.119 9.82501 5.77525C9.37501 7.144 10.1063 8.6065 11.475 9.0565C11.7375 9.15025 12.0188 9.18775 12.2813 9.18775C12.675 9.18775 13.0688 9.094 13.4438 8.9065C14.0625 8.58775 14.5313 8.06275 14.7375 7.4065C15.1875 6.03775 14.4563 4.57525 13.0875 4.12525Z"
      fill="white"
    />
    <path
      d="M19.5938 4.06899C19.05 3.88149 18.45 3.91899 17.9438 4.20024C17.4188 4.46274 17.0438 4.91274 16.8563 5.45649C16.6688 6.00024 16.725 6.60024 16.9875 7.10649C17.25 7.63149 17.7 8.00649 18.2438 8.19399C18.4688 8.26899 18.6938 8.30649 18.9188 8.30649C19.2563 8.30649 19.575 8.23149 19.875 8.08149C20.4 7.81899 20.775 7.36899 20.9625 6.82524C21.15 6.28149 21.0938 5.70024 20.8313 5.17524C20.5875 4.63149 20.1375 4.25649 19.5938 4.06899Z"
      fill="white"
    />
    <path
      d="M24.2625 7.96887C23.3625 7.66887 22.3875 8.15637 22.0875 9.05637C21.9375 9.48762 21.975 9.95637 22.1813 10.3689C22.3875 10.7814 22.7438 11.0814 23.175 11.2314C23.3625 11.2876 23.5313 11.3251 23.7188 11.3251C24.4313 11.3251 25.1063 10.8751 25.35 10.1439C25.65 9.24387 25.1625 8.26887 24.2625 7.96887Z"
      fill="white"
    />
    <path
      d="M26.8313 12.8252C26.5688 12.7314 26.2688 12.7502 26.0063 12.8814C25.7438 13.0127 25.5563 13.2377 25.4625 13.5189C25.275 14.0814 25.5938 14.7002 26.1563 14.8877C26.2688 14.9252 26.3813 14.9439 26.4938 14.9439C26.9438 14.9439 27.375 14.6627 27.525 14.1939C27.7125 13.6314 27.4125 13.0127 26.8313 12.8252Z"
      fill="white"
    />
    <path
      d="M10.5563 12.9751H19.0688V15.7126H13.5563V18.2251H17.9063V20.9251H13.5563V23.2876H19.4438V26.0251H10.5563V12.9751Z"
      fill="white"
    />
  </svg>
)
