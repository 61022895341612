import React from 'react'
import styled from 'styled-components'

const TextDecoration = props => {
  const { align = '' } = props
  return (
    <TextDecorationStyled className={`${align && `text-${align}`}`} {...props}>
      {props.children}
    </TextDecorationStyled>
  )
}

const TextDecorationStyled = styled.span`
  ${props => props.decoration && `text-decoration: ${props.decoration}`}
`

export default TextDecoration
