import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import RuleListItem from './RuleListItem'
import styled from 'styled-components'

class RuleList extends Component {
  componentWillMount() {
    const {
      commonRepository: { Common },
    } = this.props

    if (!Common.hasOwnProperty('pricingRuleConditions')) {
      this.props.commonRepository.getCommon('pricingRuleConditions', 'pricingRuleConditions')
    }
    if (!Common.hasOwnProperty('pricingRuleConditionValues')) {
      this.props.commonRepository.getCommon('pricingRuleConditionValues', 'pricingRuleConditionValues')
    }
    if (!Common.hasOwnProperty('pricingRuleOperations')) {
      this.props.commonRepository.getCommon('pricingRuleOperations', 'pricingRuleOperations')
    }
  }

  render() {
    const {
      commonRepository,
      AttributeCollection,
      criteria = [],
      className = '',
      classes = {},
      form,
      primaryIndex,
      onAttributeSearch,
      onDeleteParentCondition,
    } = this.props

    return (
      <RuleListStyled className={className ? className : classes.root}>
        {criteria.map((rule, index) => (
          <RuleListItem
            commonRepository={commonRepository}
            AttributeCollection={AttributeCollection}
            key={index}
            item={rule}
            form={form}
            parentCriteria={criteria}
            index={index}
            primaryIndex={primaryIndex}
            onAttributeSearch={onAttributeSearch}
            onDeleteParentCondition={onDeleteParentCondition}
          />
        ))}
      </RuleListStyled>
    )
  }
}

export default inject('commonRepository')(observer(RuleList))

const RuleListStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: 14px;
  button:focus {
    box-shadow: none;
  }
  .btn-add {
    color: ${props => props.theme.primary};
  }
  ul {
    border-left: 2px solid ${props => props.theme.inputBorder};
    padding-left: 1rem;
    &.bottom-controls {
      padding: 0;
    }
  }
  .row-item {
    display: flex;
    align-items: center;
    min-height: 36px;
  }
  .select-search {
    min-width: 300px;
    .react-select__control {
      min-height: auto;
    }
    .react-select__indicator {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`
