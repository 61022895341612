/* @flow */
import React, { Component } from 'react'

import { Col, Row } from 'reactstrap'
import { components } from 'react-select'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import cx from 'classnames'

import { formatCustomerCurrency, getFormattedDateTimeString, getFullName } from 'helpers/Formatters'
import { OptionStyled, RowColorCellStyled } from './OrderTable.styled'
import { ORDER_SOURCE, ORDERS_PATH, QUOTATIONS_PATH } from 'definitions'
import { sort } from 'helpers/Array'
import ContentContainer from 'components/Common/ContentContainer'
import Filters from './Filters'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import Status from 'components/Common/Status'

class OrderTable extends Component {
  filters = {}
  statusSelected = null
  orderSources = []

  constructor(props) {
    super(props)

    this.state = {
      orderSource: { value: 'ALL', label: 'All' },
    }

    this.fetchOrderSources()
    this.initializeDefaultFilters()
  }

  initializeDefaultFilters = () => {
    const { quotation, searchRepository, pageKey } = this.props

    const filters = searchRepository.Pages[pageKey]?.filters || {}
    this.filters = filters

    if (!quotation) {
      this.state = {
        ...this.state,
        orderSource: filters?.orderSource || this.state.orderSource,
      }
    }
  }

  fetchOrderSources = async () => {
    const { quotation, repository } = this.props
    if (!quotation) {
      await repository.getCommon('OrderSource', 'sources')
      if (repository.Common.OrderSource?.data?.length) {
        this.orderSources = sort(repository.Common.OrderSource.data, 'label')
      }
    }
  }

  fetchTable = async params => {
    const { repository } = this.props
    const { stores, status, orderSource, ...rest } = this.filters

    const filters = { ...rest }

    if (stores) {
      filters.stores = stores.map(item => item.value)?.join(',') || ''
    }
    if (status) {
      filters.status = status?.value
    }
    if (orderSource?.length) {
      const [item] = orderSource
      if (item.value !== 'ALL') {
        filters.orderSource = orderSource.map(item => item.value).join(',')
      }
    }

    return repository.fetchTable({ ...params, ...filters })
  }

  handleFilterOrderStatus = (selection, action) => {
    let newOrderSource

    if (selection?.length) {
      const hasAll = selection.some(item => item.value === 'ALL')
      if (action.action === 'select-option' && action.option.value !== 'ALL' && hasAll) {
        const newSelection = selection.filter(item => item.value !== 'ALL')
        newOrderSource = newSelection
      } else if (action.action === 'select-option' && action.option.value === 'ALL') {
        newOrderSource = [{ value: 'ALL', label: 'All' }]
      } else {
        newOrderSource = selection
      }
    } else {
      newOrderSource = [{ value: 'ALL', label: 'All' }]
    }

    delete this.filters.orderSource
    this.filters = { ...this.filters, orderSource: newOrderSource }
    this.setState({ orderSource: newOrderSource })

    // update memoized filters
    this.props.searchRepository.updateSearchPage(this.props.pageKey, {
      filters: { ...this.filters },
    })

    // reload table
    this.table && this.table.reload(false, true)
  }

  handleFilterChange = newFilters => {
    if (this.filters) {
      delete this.filters.stores
      delete this.filters.status
      delete this.filters.referralCode
    }

    this.filters = { ...this.filters, ...newFilters }

    // update memoized filters
    this.props.searchRepository.updateSearchPage(this.props.pageKey, { filters: { ...this.filters } })
    if (this.table) {
      this.table.reload(false, true)
    }
  }

  getColor = orderSource => {
    switch (orderSource) {
      case 'ALL':
      case 'All':
        return 'black'
      case ORDER_SOURCE.STORE:
      case ORDER_SOURCE.COTERM_V1:
        return 'primary'
      case ORDER_SOURCE.MANUAL:
      case ORDER_SOURCE.COTERM_V2:
        return 'success'
      case ORDER_SOURCE.APP_PROMO:
      case ORDER_SOURCE.APP_PROMO2:
        return 'turquoise'
      case ORDER_SOURCE.AUTO_RENEWAL:
      case ORDER_SOURCE.COTERM_PARTNER_PAVILION:
        return 'danger'
      case ORDER_SOURCE.MOBILE:
      case ORDER_SOURCE.MOBILE_V2:
        return 'cyan'
      case ORDER_SOURCE.PARTNER_QUOTATION:
      case ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION:
        return 'secondary'
      case ORDER_SOURCE.API:
        return 'info'
      default:
        return 'dark'
    }
  }

  getColorDash = orderSource => {
    switch (orderSource) {
      case ORDER_SOURCE.MOBILE:
      case ORDER_SOURCE.APP_PROMO2:
      case ORDER_SOURCE.COTERM_V1:
      case ORDER_SOURCE.COTERM_V2:
      case ORDER_SOURCE.COTERM_BACKEND:
      case ORDER_SOURCE.COTERM_PARTNER_PAVILION:
      case ORDER_SOURCE.COTERM_SIMULATOR_PARTNER_PAVILION:
        return 'dashed'
      default:
        return ''
    }
  }

  render() {
    const { quotation, repository, searchRepository, searchCriteria, pageKey } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData },
    } = repository

    return (
      <ContentContainer title={quotation ? 'Quotations' : 'Orders'}>
        <Row className="mb-3">
          <Col lg="6" md="8" sm="12">
            {!quotation && (
              <ReactSelect
                plain
                className="mr-2 select-series"
                options={{
                  placeholder: 'Filter By Series',
                  isMulti: true,
                  options: this.orderSources,
                  value: this.state.orderSource,
                  components: {
                    MultiValueLabel: innerProps => (
                      <components.MultiValueLabel {...innerProps}>
                        <OptionStyled color={this.getColor(innerProps.data.value)}>
                          <div className={cx('badge-icon', this.getColorDash(innerProps.data.value))} />
                          <span>{innerProps.data.label}</span>
                        </OptionStyled>
                      </components.MultiValueLabel>
                    ),
                    Option: innerProps => (
                      <components.Option {...innerProps}>
                        <OptionStyled color={this.getColor(innerProps.value)}>
                          <div className={cx('badge-icon', this.getColorDash(innerProps.value))} />
                          <span>{innerProps.label}</span>
                        </OptionStyled>
                      </components.Option>
                    ),
                  },
                }}
                onChange={this.handleFilterOrderStatus}
              />
            )}
          </Col>
        </Row>
        <ReactTable
          searchRepository={searchRepository}
          pageKey={pageKey}
          enableRowLink
          getRowUrl={({ original }) => `${quotation ? QUOTATIONS_PATH : ORDERS_PATH}/${original.id}`}
          columns={[
            {
              headerClassName: 'px-0',
              className: 'p-0',
              accessor: 'orderSource',
              resizable: false,
              width: 20,
              Cell: ({ value }) => (
                <RowColorCellStyled color={this.getColor(value)} className={cx(this.getColorDash(value))} />
              ),
            },
            {
              Header: quotation ? 'Quote No.' : 'Order No.',
              accessor: 'orderNumber',
              Cell: ({ original }) => original.orderNumber || original.tempOrderNumber || 'N/A',
            },
            {
              Header: 'Customer',
              accessor: 'customer.name',
              Cell: ({ original }) => (
                <span title={original.customer.name} className="pr-1 ellipsis row-clickable">
                  {original.customer.name}
                </span>
              ),
            },
            {
              Header: 'Store',
              accessor: 'store.name',
              className: 'd-flex align-items-center',
            },
            {
              Header: 'Purchased On',
              accessor: 'orderDate',
              Cell: ({ original }) => getFormattedDateTimeString(original.orderDate),
            },
            {
              Header: 'Bill To',
              accessor: 'billingAddress.firstName',
              Cell: ({ original }) => {
                const fullName = getFullName(original.billingAddress)
                return (
                  <span title={fullName} className="pr-1 ellipsis row-clickable">
                    {fullName}
                  </span>
                )
              },
            },
            {
              Header: 'Ship To',
              accessor: 'shippingAddress.firstName',
              Cell: ({ original }) => {
                const fullName = getFullName(original.shippingAddress)
                return (
                  <span title={fullName} className="pr-1 ellipsis row-clickable">
                    {fullName}
                  </span>
                )
              },
            },
            {
              Header: 'Currency',
              accessor: 'store.currency',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center',
              width: 80,
            },
            {
              Header: 'Amount',
              accessor: 'orderSummary.grandTotal',
              headerClassName: 'justify-content-end',
              className: 'justify-content-end',
              width: 120,
              Cell: ({ original }) => formatCustomerCurrency(original.orderSummary.grandTotal, original.customer),
            },
            {
              Header: 'Status',
              accessor: 'status',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 200,
              Cell: ({ original }) => <Status status={original.status} />,
            },
          ]}
          sort={{ id: 'createdDate', desc: true }}
          data={toJS(data)}
          loadData={this.fetchTable}
          // loadData={params => {
          //   return fetchTable({ ...params, ...this.filters })
          // }}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          searchCriteria={searchCriteria}
          className="striped cell-vertical-center horizontal-scroll"
          searchClassName="col-4"
          actionsClassName="col-8"
          ref={ref => (this.table = ref)}
          {...(!quotation
            ? {
                actions: (
                  <Filters pageKey={pageKey} searchRepository={searchRepository} onChange={this.handleFilterChange} />
                ),
              }
            : {})}
        />
      </ContentContainer>
    )
  }
}

export default observer(OrderTable)
