import React from 'react'

import { FormGroup, FormText, Input, Label } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

const InputField = ({
  field,
  disabled,
  className,
  inputClassName,
  readOnlyClassName,
  onChange = null,
  onKeyDown = null,
  onPaste,
  startAdornment,
  endAdornment,
  label: propsLabel,
  readOnly,
  showTooltip,
  warningText = null,
  allowNegative = true,
  allowExponent = true,
  ...rest
}) => {
  const { name, placeholder = `Enter ${field.label}` } = field
  const type = rest.type || field.type || 'text'
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  const Wrapper = type !== 'textarea' && (startAdornment || endAdornment) ? InputAdormentWrapper : React.Fragment
  const label = propsLabel || field.label

  return (
    <FormGroupStyled
      key={name}
      className={className}
      nolabel={label ? undefined : 'nolabel'}
      haserror={hasError ? 'hasError' : undefined}
    >
      {label && <Label>{label}</Label>}
      <Wrapper>
        {startAdornment}
        {readOnly ? (
          <ReadOnlyField
            plain
            {...rest}
            value={field.value}
            wrapped={type === 'textarea'}
            showTooltip={showTooltip}
            className={cx(readOnlyClassName)}
          />
        ) : (
          <Input
            {...field.bind({
              name,
              type,
              // id: name,
              disabled: disabled ? disabled : field.disabled,
              ...(onChange && {
                onChange: val => {
                  field.onChange(val)
                  field.validate()

                  if (onChange) onChange(val)
                },
              }),
            })}
            placeholder={placeholder}
            invalid={hasError}
            className={cx(inputClassName)}
            {...rest}
            onKeyDown={e => {
              if (!allowNegative) {
                // eslint-disable-next-line default-case
                switch (e.keyCode) {
                  case 189:
                    e.preventDefault()
                    break
                  case 40:
                    if (!e.target.value || parseFloat(e.target.value) <= 0) {
                      e.preventDefault()
                    }
                    break
                }
              }
              if (!allowExponent && e.keyCode === 69) {
                e.preventDefault()
              }
              onKeyDown && onKeyDown(e)
            }}
            onPaste={event => {
              !allowNegative && event.clipboardData.getData('Text').includes('-') && event.preventDefault()
              if (!allowExponent) {
                const pasteData = event.clipboardData.getData('text')
                if (pasteData?.toLowerCase()?.includes('e')) {
                  event.preventDefault()
                }
              }
              onPaste && onPaste(event)
            }}
          />
        )}
        {endAdornment}
      </Wrapper>
      {warningText ? <FormText color="warning">{warningText}</FormText> : null}
      {hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </FormGroupStyled>
  )
}

const FormGroupStyled = styled(FormGroup)`
  &.form-group {
    textarea.form-control {
      height: ${({ nolabel, haserror }) => (nolabel ? (haserror ? 'calc(100% - 15px)' : '100%') : 'auto')};
    }
    input[type='number'],
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: textfield;
    }
  }
`

const InputAdormentWrapper = styled.div`
  position: relative;
  display: flex;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid ${props => props.theme.inputBorder};
  }
  &&& input.form-control {
    border: 0;
  }
  .custom-control {
    margin-left: 5px;
  }
`

export default observer(InputField)
