import React from 'react'
import ReactDOM from 'react-dom'

import Alert from './Alert'

const PORTAL_ELEMENT = 'alert-manager'

class AlertManager {
  constructor() {
    let portalElement = document.getElementById(PORTAL_ELEMENT)

    if (!portalElement) {
      const el = document.createElement('div')
      el.id = PORTAL_ELEMENT
      el.className = 'collapse'
      if (document.body != null) {
        document.body.appendChild(el)
      }
      portalElement = el
    }

    ReactDOM.render(<Alert bindActions={this.bindActions} />, portalElement)
  }
  bindActions = (open, close) => {
    this.open = open
    this.close = close
  }
  open = options => {
    this.open && this.open(options)
  }
  close = () => {
    this.open && this.close()
  }
}

export default new AlertManager()
