import { observer } from 'mobx-react'
import React from 'react'

import { allowedTo, Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import ReactSelect from 'components/Common/Form/ReactSelect'
import Tags from 'components/Common/Tags'

class Zones extends React.Component {
  state = {
    selected: null,
  }
  handleCountryChange = selected => {
    const { onAdd, data } = this.props
    onAdd(data.id, selected, () => {
      this.setState({ selected: null })
    })
  }
  render() {
    const { title, availableCountries, data, onDelete } = this.props
    return (
      <Card title={`Zone ${title}`}>
        <Visible>
          {availableCountries && (
            <ReactSelect
              plain={true}
              options={{
                options: availableCountries,
                isClearable: false,
                value: this.state.selected,
                isLoading: !!this.state.selected,
                isDisabled: !!this.state.selected,
              }}
              onChange={value => {
                this.handleCountryChange(value)
                this.setState({ selected: value })
              }}
            />
          )}
        </Visible>
        <Tags
          data={data.countries}
          title={title}
          className="my-3"
          onDelete={country => onDelete(data.id, country.id)}
          hideDelete={!allowedTo()}
        />
      </Card>
    )
  }
}

export default observer(Zones)
