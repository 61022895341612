/* @flow */
import React, { Component } from 'react'

import { autorun, decorate, observable } from 'mobx'
import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'

/** Common Components */
import { allowedToOrderRole } from 'helpers/Auth'
import { Visible } from 'helpers/Auth'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import ContentContainer from 'components/Common/ContentContainer'
import Coupons from './Coupons'
import DatePicker from 'components/Common/Form/DatePicker'
import ErrorsList from 'components/Common/ErrorsList'
import Form from 'components/Common/Form'
import FormActions from 'components/Common/Buttons/FormActions'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

import Conditions from './Conditions'

const defaultCriteriaObj = {
  criteria: [
    {
      isCreate: false,
      isCriteriaOpen: false,
      isConditionOpen: false,
      condition: {
        condition: 'all',
        value: 'true',
      },
      type: 'combinations',
      criterias: [],
    },
  ],
}

class PricingRulesForm extends Component<Props> {
  conditions = null
  constructor(props) {
    super(props)
    const {
      match: {
        params: { type = '' },
      },
    } = props
    const {
      data: {
        name = '',
        label = '',
        description = '',
        status = false,
        stores,
        groups,
        endsGivenDateInd = false,
        startDate,
        endDate,
        priority = 0,
        overridePricingRuleInd = false,
        couponType = '',
        couponMaximumClaims = '',
        conditions = [],
      } = {},
    } = props
    const rule = 'required'
    const fields = [
      'name',
      'label',
      'description',
      'status',
      'stores',
      'groups',
      ...(type === 'rules' ? ['startDate', 'endsGivenDateInd', 'endDate'] : []),
      'priority',
      'conditions[]',
      'conditions[].action',
      'conditions[].action.applyBy',
      'conditions[].action.amount',
      'conditions[].criteria',
      'conditions[].criteria[]',
      'conditions[].criteria[].isCreate',
      'conditions[].criteria[].isCriteriaOpen',
      'conditions[].criteria[].isConditionOpen',
      'conditions[].criteria[].condition',
      'conditions[].criteria[].condition.condition',
      'conditions[].criteria[].condition.value',
      'conditions[].criteria[].type',
      'conditions[].criteria[].criterias',
      'conditions[].criteria[].criterias[]',
      ...(type === 'codes' ? ['couponRequired', 'overridePricingRuleInd', 'couponType', 'couponMaximumClaims'] : []),
    ]
    const labels = {
      name: 'Rule Name',
      label: 'Label',
      description: 'Description  (Limit to 100 words)',
      status: 'Status',
      stores: 'Store',
      groups: 'Customer Groups',
      ...(type === 'rules'
        ? { startDate: 'Effective Date', endsGivenDateInd: 'Ends at a given date', endDate: 'Expiry Date' }
        : {}),

      priority: 'Priority',
      'conditions[].action.applyBy': 'Apply By',
      'conditions[].action.amount': 'Discount Amount',
      ...(type === 'codes'
        ? {
            couponRequired: '',
            overridePricingRuleInd: 'Override Pricing Rule',
            couponType: 'Type',
            couponMaximumClaims: 'Maximum Claims',
          }
        : {}),
    }
    const types = {
      description: 'textarea',
      status: 'switch',
      startDate: 'datepicker',
      endDate: 'datepicker',
      endsGivenDateInd: 'checkbox',
      couponMaximumClaims: 'number',
      'conditions[].action.amount': 'number',
      ...(type === 'codes' ? { overridePricingRuleInd: 'checkbox' } : {}),
    }
    const values = {
      name,
      label,
      description,
      stores,
      groups,
      endsGivenDateInd,
      startDate,
      endDate,
      priority: { label: priority, value: priority },
      status: status === 'Active',
      conditions: conditions,
      ...(type === 'codes'
        ? { couponRequired: true, overridePricingRuleInd: overridePricingRuleInd, couponType, couponMaximumClaims }
        : {}),
    }
    const rules = {
      name: rule,
      label: rule,
      stores: rule,
      groups: rule,
      ...(type === 'rules' ? { startDate: rule } : {}),
      'conditions[]': rule,
      'conditions[].action': rule,
      'conditions[].action.applyBy': rule,
      'conditions[].action.amount': rule,
      ...(type === 'codes' ? { couponType: rule } : {}),
    }

    const hook = { onChange: () => this.setState({ promptOnExit: true }) }
    const hooks = {
      name: hook,
      label: hook,
      description: hook,
      status: hook,
      stores: hook,
      ...(type === 'rules' ? { startDate: hook, endsGivenDateInd: hook, endDate: hook } : {}),
      priority: hook,
      conditions: hook,
      'conditions[].action': hook,
      'conditions[].action.applyBy': hook,
      'conditions[].action.amount': hook,
    }

    this.form = new Form(
      { fields, labels, types, values, rules, hooks },
      {
        name: 'PriceRulesForm',
        hooks: {
          onInit: form => {
            !conditions.length &&
              form.$('conditions').add([
                {
                  ...defaultCriteriaObj,
                  action: {
                    applyBy: null,
                    amount: 0,
                  },
                },
              ])
          },
          onSuccess: this.handleSubmit,
        },
      }
    )

    this.conditions = conditions.length
      ? conditions.map(cond => ({ ...cond, criteria: [cond.criteria] }))
      : observable([defaultCriteriaObj])

    let checkConditionsChanged = false
    autorun(
      reaction => {
        JSON.stringify(this.conditions)
        if (checkConditionsChanged) {
          this.setState({ promptOnExit: true })

          //dispose autorun, only need to run 1 time, once a change is detected
          reaction.dispose()
        }
        checkConditionsChanged = true
      },
      { name: 'checkConditions', delay: 150 }
    )

    this.state = {
      currentTab: 0,
      promptOnExit: false,
    }
  }
  componentWillMount() {
    this.props.commonRepository.getCommon('pricingRuleStatus', 'pricingRuleStatus', {})
    this.props.commonRepository.getCommon('pricingRuleApply', 'pricingRuleApply', {})
    this.props.commonRepository.getCommon('couponType', 'couponType', {})
  }
  componentWillUnmount() {
    this.props.catalogPricingRuleRepository.clearCrudData()
    this.props.catalogPricingRuleRepository.clearErrorMessages()
    // this.props.catalogPricingRuleRepository.clearAttributes()
  }
  handleCancel = () => {
    this.props.history.push(`/pricing/${this.props.match.params.type}`)
  }
  handleSubmit = form => {
    const {
      catalogPricingRuleRepository: {
        CRUD: { data },
      },
      match: {
        params: { type = '' },
      },
    } = this.props
    const values = form.values()
    const payload = {
      ...values,
      status: values.status ? 'Active' : 'Inactive',
      priority: values.priority && values.priority.value ? values.priority.value : 0,

      conditions: this.conditions.map((cond, i) => ({
        ...cond,
        criteria: {
          ...cond.criteria[0],
        },
        action: values.conditions[i].action,
      })),
      ...(type === 'rules' ? { endDate: values.endsGivenDateInd ? values.endDate : null } : {}),
    }
    if (data.id) {
      this.props.catalogPricingRuleRepository.patch({ ...payload, id: data.id }, () => {
        this.setState({ promptOnExit: false })
        this.handleCancel()
      })
    } else {
      this.props.catalogPricingRuleRepository.create({ ...payload, suspendedInd: values.suspendedInd }, result => {
        this.setState({ promptOnExit: false })
        this.props.history.push(`/pricing/${type}/${result.id}`)
      })
    }
  }
  handleTabChange = (event, value) => {
    this.setState({ currentTab: value })
  }
  handleApplyByChange = (i, field) => {
    this.form
      .$(`conditions[${i}].action.amount`)
      .set(
        'label',
        field.value === 'percentage' ? 'Please state the amount in percentage' : 'Please state the discount amount'
      )
  }
  handleAddNewCondition = () => {
    const conditions = [
      ...this.conditions,
      observable({
        criteria: [
          {
            isCreate: false,
            isCriteriaOpen: false,
            isConditionOpen: false,
            condition: {
              condition: 'all',
              value: 'true',
            },
            type: 'combinations',
            criterias: [],
          },
        ],
      }),
    ]
    this.form.$('conditions').add([
      {
        ...defaultCriteriaObj,
        action: {
          applyBy: null,
          amount: 0,
        },
      },
    ])
    this.conditions = observable(conditions)
    this.setState({})
  }
  handleDeleteCondition = itemIndex => {
    if (this.conditions.length > itemIndex) {
      this.conditions.splice(itemIndex, 1)
      this.setState({})
    }
  }
  setFieldValidations = () => {
    const {
      match: {
        params: { type },
      },
    } = this.props

    if (type === 'codes') {
      const couponType = this.form.$('couponType').value

      this.form.$('couponMaximumClaims').set('rules', null)
      if (['USE', 'COMBO'].includes(couponType)) {
        this.form.$('couponMaximumClaims').set('rules', 'required')
      }
    }

    if (type === 'codruleses') {
      this.form.$('endDate').set('rules', null)
      if (this.form.$('endsGivenDateInd').value) {
        this.form.$('endDate').set('rules', 'required')
      }
    }
  }
  render() {
    const {
      commonRepository,
      customerGroupRepository,
      storeRepository,
      catalogPricingRuleRepository: { CRUD: { errors = [], loading, submitting } = {}, CRUD },
      couponRepository,
      match: {
        params: { type = '' },
      },
      view,
      title,
      breadcrumbs,
      actions,
    } = this.props
    const { promptOnExit } = this.state
    const { pricingRuleApply, couponType } = commonRepository.Common
    const conditions = this.form.$('conditions')
    const endsGivenDateInd = this.form.$('endsGivenDateInd').value

    const isSubmitting = submitting || couponRepository.CRUD.submitting

    return (
      <ContentContainer
        title={title}
        breadcrumbs={breadcrumbs}
        actions={actions}
        promptOnExit={isSubmitting || promptOnExit}
        promptOnExitIsConfirm={!isSubmitting}
      >
        <Row>
          <Col lg={12}>{!!errors.length && <ErrorsList errors={errors} />}</Col>
          <Col lg={4}>
            <Card title="Rule Information" className="mb-3">
              <Input
                field={this.form.$('name')}
                style={{ paddingRight: this.form.$('status').value ? '15px' : '85px' }}
                inputClassName="ellipsis"
                endAdornment={
                  <Checkbox
                    label={this.form.$('status').value ? 'Active' : 'Inactive'}
                    field={this.form.$('status')}
                    plain
                    className="ml-1 mt-4"
                  />
                }
              />
              <Input field={this.form.$('label')} inputClassName="ellipsis" />
              <Input field={this.form.$('description')} rows="10" />
              <ReactSelect
                field={this.form.$('stores')}
                customLabelKey={['name', 'id']}
                serverSide
                search={storeRepository.reactSelectSearch}
                options={{
                  isMulti: true,
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                }}
              />
              <ReactSelect
                field={this.form.$('groups')}
                customLabelKey={['name', 'id']}
                serverSide
                search={customerGroupRepository.reactSelectSearch}
                options={{
                  isMulti: true,
                  valueKey: 'id',
                  labelKey: 'name',
                  defaultOptions: true,
                }}
              />
              {type === 'codes' && (
                <>
                  <Checkbox field={this.form.$('overridePricingRuleInd')} />
                  <RadioGroup field={this.form.$('couponType')} radios={couponType.data} inline />
                  {!['TIME', 'UNLIMITED'].includes(this.form.$('couponType').value) && (
                    <Input field={this.form.$('couponMaximumClaims')} />
                  )}
                </>
              )}
              {type === 'rules' && (
                <>
                  <Checkbox field={this.form.$('endsGivenDateInd')} />
                  <Row>
                    <Col lg={endsGivenDateInd ? 6 : 12}>
                      <DatePicker field={this.form.$('startDate')} />
                    </Col>
                    {endsGivenDateInd && (
                      <Col lg={6}>
                        <DatePicker field={this.form.$('endDate')} />
                      </Col>
                    )}
                  </Row>
                </>
              )}

              {type === 'rules' && (
                <ReactSelect
                  field={this.form.$('priority')}
                  options={{
                    options: [...Array(10).keys()].map(v => ({ label: v, value: v })),
                    isClearable: false,
                  }}
                />
              )}
            </Card>
          </Col>
          <Col lg={8}>
            {view === 'conditions' && (
              <Card
                className="mb-3"
                title="Conditions"
                titleActions={
                  <Visible toOrderProcessRole>
                    <div className="text-right">
                      <IconButton onClick={this.handleAddNewCondition} color="dark" outline />
                    </div>
                  </Visible>
                }
              >
                {this.conditions.map((cond, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Conditions
                        primaryIndex={index}
                        form={conditions}
                        criteria={cond.criteria}
                        deleteCondition={() => this.handleDeleteCondition(index)}
                      />
                      <Row>
                        <Col sm="auto">
                          <RadioGroup
                            field={this.form.$(`conditions[${index}].action.applyBy`)}
                            radios={pricingRuleApply.data}
                            inline
                            id={`conditions[${index}].action.applyBy`}
                            onChange={() =>
                              this.handleApplyByChange(index, this.form.$(`conditions[${index}].action.applyBy`))
                            }
                          />
                        </Col>
                        {!!this.form.$(`conditions[${index}].action.applyBy`).value && (
                          <Col xl="4">
                            <Input field={this.form.$(`conditions[${index}].action.amount`)} />
                          </Col>
                        )}
                      </Row>
                    </React.Fragment>
                  )
                })}
              </Card>
            )}

            {view === 'coupons' && <Coupons data={CRUD.data} form={this.form} />}
          </Col>
          <Col lg={12} className="d-flex justify-content-end">
            <FormActions
              loading={loading}
              loadingMessage={`${CRUD.loading ? 'Saving' : 'Loading'} please wait...`}
              confirmLabel="Save"
              confirmIcon="save"
              onConfirm={() => {
                this.setFieldValidations()
                this.form.submit()
              }}
              onCancel={this.handleCancel}
              hideConfirm={!allowedToOrderRole()}
            />
          </Col>
        </Row>
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'catalogPricingRuleRepository',
    'commonRepository',
    'customerGroupRepository',
    'storeRepository',
    'couponRepository'
  )(observer(PricingRulesForm)),
  { condition: observable }
)
