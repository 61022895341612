import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { CHECK_JOB_STATUS } from 'definitions'
import ToasterNotify from 'helpers/ToasterNotify'

class JobStatusNotification extends Component {
  componentDidMount() {
    this.checkJobStatus()
  }
  checkJobStatus = async () => {
    if (sessionStorage.getItem(CHECK_JOB_STATUS)) {
      sessionStorage.removeItem(CHECK_JOB_STATUS)
      const { data } = await this.props.deviceManagementJobRepository.checkJobStatus()
      if (data) {
        ToasterNotify.alert({
          title: 'Auto Renewal Batch Job Notification',
          message: (
            <div>
              <div>Auto renewal batch job(s) has failure.</div>
              <div>Please check the page for details</div>
            </div>
          ),
          color: 'error',
          duration: 120000,
        })
      }
    }
  }
  render() {
    return null
  }
}

export default inject('deviceManagementJobRepository')(observer(JobStatusNotification))
