import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import { prettyPrintJson, titleize } from 'helpers/String'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable'

class LogBookModal extends Component {
  render() {
    const { logsRepository, reference, logId, open, onClose, onLogModalOpen } = this.props
    const {
      LogsTable: { data, loading, totalRecordCount, originalData },
      fetchLogsById,
    } = logsRepository
    return (
      <Modal
        open={open}
        size="xl"
        title={
          <span>
            Audit Logs <span className="text-primary text-transform-none ml-1">{reference}</span>
          </span>
        }
        onConfirm={onClose}
        hideCancel
        wrapClassName="log-book-modal-wrapper"
        onOpened={onLogModalOpen}
      >
        <ReactTable
          columns={[
            {
              Header: 'Type of Action',
              accessor: 'typeOfAction',
              Cell: props => <span>{titleize(props.value)}</span>,
              width: 150,
            },
            {
              Header: 'Field Name',
              accessor: 'fieldName',
              width: 150,
            },
            {
              Header: 'Changed From',
              accessor: 'changedFrom',
              Cell: props => (
                <CodeStyled className="py-1">
                  <code>{prettyPrintJson(props.value)}</code>
                </CodeStyled>
              ),
            },
            {
              Header: 'Changed To',
              accessor: 'changedTo',
              Cell: props => (
                <CodeStyled className="py-1">
                  <code>{prettyPrintJson(props.value)}</code>
                </CodeStyled>
              ),
            },
          ]}
          data={toJS(data)}
          loadData={params => fetchLogsById(logId, params)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
        />
      </Modal>
    )
  }
}

const CodeStyled = styled.div`
  white-space: pre;
  height: auto;
  min-height: 12px;
  max-height: 150px;
  overflow: auto;
  code {
    font-size: 100%;
    color: inherit;
  }
`

export default observer(LogBookModal)
