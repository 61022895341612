import styled from 'styled-components'

export const ReactTableContainerStyled = styled.div`
  position: relative;
  font-family: 'Arial';
  font-size: 11px;
  .table-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 10;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    .loader {
      padding: 0.5rem 0.75rem;
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .ReactTable {
    border: none !important;
    /* margin-bottom: 20px; */

    &.horizontal-scroll {
      /* Forces react-table to show horizontal scroll bar if columns doesn't fit screen */
      width: calc(100vw - 270px);
    }

    &.no-overflow {
      overflow: unset;
      .rt-table,
      .rt-tbody {
        overflow: unset;
      }
    }
    &.clickable {
      .rt-tr {
        cursor: pointer !important;
      }
    }
    .rt {
      &-resizer {
        width: 15px;
        margin: 0 5px;
      }
      &-table {
      }
      &-thead {
        &.-header {
          border-top: 1px solid #797979;
          box-shadow: none;
        }
      }
      &-th {
        outline: none !important;
        text-align: left;
        border-right: none !important;
        font-weight: 600;
        display: inline-flex;
        .rt-resizable-header-content {
          padding: 12px 0px;
          color: #7f7f7f;
        }
        &.-cursor-pointer {
          &:after {
            margin-left: 5px;
            margin-top: 10px;
            display: inline-block;
            font: normal normal normal 14px/1;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '⇅';
          }
        }

        &.-sort-asc {
          box-shadow: none !important;
          &:after {
            margin-left: 5px;
            margin-top: 10px;
            content: '↑';
          }
        }
        &.-sort-desc {
          box-shadow: none !important;
          &:after {
            margin-left: 5px;
            margin-top: 10px;
            content: '↓';
          }
        }
        &.no-sort {
          &:after {
            content: '';
          }
        }
        &.text-right {
          .rt-resizable-header-content {
            width: 100%;
            text-align: right;
          }
        }
      }
      &-tr-group {
        border-bottom: none !important;
        &:first-child {
          margin-top: 0px;
        }
      }
      &-tr {
        border-bottom: 1px solid rgb(239, 239, 239);
      }
      &-td {
        border-right: none !important;
        padding: 12px 6px;
        line-height: 1;
        min-height: 45px;
        &.no-overflow {
          overflow: unset;
        }
      }
      &-noData {
        z-index: auto;
      }
    }
    &.striped {
      .rt-tr-group {
        .-odd {
          background-color: rgba(242, 242, 242, 1);
        }
        .-even {
          background: rgba(255, 255, 255, 1);
        }
      }
    }
    .tr-active {
      background-color: rgba(255, 250, 238, 1) !important;
    }

    &:not(.no-hover),
    &:not(.no-data) {
      .rt {
        &-tr-group {
          .-odd {
            &:hover {
              background-color: rgba(255, 250, 238, 1) !important;
            }
          }
          .-even {
            &:hover {
              background-color: rgba(255, 250, 238, 1) !important;
            }
          }
          &:hover {
            background-color: rgba(255, 250, 238, 1) !important;
          }
        }
      }
    }
    &.cell-vertical-center {
      .rt-td {
        display: flex;
        align-items: center;
      }
    }
    &.multi-select {
      .rt-tbody .rt-tr .rt-td:first-child {
        display: flex;
        align-items: center;
      }
    }
    &.fixed-columns {
      .rt-tbody {
        overflow: visible;
      }
      .fixed-column {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: inherit;
        &.rt-th {
          background-color: #fafafa;
        }
        /* 9EC3E6 */
      }
    }
    .pagination-top {
      margin-bottom: 20px;
    }
    .pagination-bottom {
      margin-top: 20px;
    }
    .pagination-top,
    .pagination-bottom {
      .rt-pagination {
        font-size: 12px;
      }
    }
  }
  .rt-search {
    .search-box {
      display: flex;
      align-items: center;
    }
    input {
      font-size: 12px;
      &:focus {
        box-shadow: none;
      }
    }
    .form-control {
      border-bottom: 0px;
      color: #333333;
      background-color: transparent;
    }
  }
  .rt-info {
    select {
      display: inline-block;
      width: auto;
      border: 0px;
      font-size: 12px;
      margin: 0 10px 0 5px;
    }
  }
  a {
    color: inherit;
  }
`
