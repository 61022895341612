/* @flow */
import React, { Component } from 'react'

import { BANK_ACCOUNTS_PATH } from 'definitions'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import BankAccountForm from './BankAccountForm'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class BankAccountEdit extends Component {
  constructor(props) {
    super(props)

    this.loadBankAccount()
  }

  loadBankAccount() {
    const {
      match: {
        params: { id },
      },
      bankAccountRepository,
    } = this.props

    bankAccountRepository.getById(id)
  }

  componentWillUnmount() {
    this.props.bankAccountRepository.clearCrudData()
    this.props.bankAccountRepository.clearErrorMessages()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      bankAccountRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading && !data.id ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer
          title="Edit Bank Account"
          breadcrumbs={[
            {
              label: 'Home',
              href: '/',
            },
            {
              label: 'Bank Accounts',
              href: BANK_ACCOUNTS_PATH,
            },
            {
              label: data ? data.name : '',
            },
          ]}
        >
          <BankAccountForm {...this.props} data={data} isAdd={false} />
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="BankAccount"
                  entityId={data.id}
                  entityLabel="Bank Account"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="bankAccount"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('bankAccountRepository')(observer(BankAccountEdit)), {})
