import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { formatNumber, getFormattedDateTimeString } from 'helpers/Formatters'
import Card from 'components/Common/Card'
import CouponFormModal from './CouponFormModal'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class Coupons extends Component {
  state = {
    isCouponFormModalOpen: false,
    itemToDelete: null,
    selected: {},
  }
  componentWillMount() {}
  toggleCouponFormModal = () =>
    this.setState({
      isCouponFormModalOpen: !this.state.isCouponFormModalOpen,
      selected: !this.state.isCouponFormModalOpen && {},
    })
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this coupon?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }

  handleDelete = async () => {
    const { data, couponRepository } = this.props
    await couponRepository.delete({ pricingRuleId: data.id, id: this.state.itemToDelete.id }, () => {
      this.table.reload()
    })
  }
  handleEdit = selected => {
    this.setState({
      selected,
      isCouponFormModalOpen: !this.state.isCouponFormModalOpen,
    })
  }
  handleCouponFormSuccess = () => {
    this.table.reload()
    this.toggleCouponFormModal()
  }
  render() {
    const { selected, isCouponFormModalOpen } = this.state
    const {
      couponRepository: {
        Table: { loading, totalRecordCount, originalData },
        Table,
        fetchTable,
      },
      data,
      form,
    } = this.props
    const couponType = form.$('couponType').value?.toLowerCase()

    return (
      <Card
        title="Coupon Codes"
        titleActions={
          <Visible toOrderProcessRole>
            <div className="text-right">
              <IconButton onClick={this.toggleCouponFormModal} color="dark" outline />
            </div>
          </Visible>
        }
      >
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'code', Header: 'Code' },
            { accessor: 'dealId', Header: 'Deal ID' },
            {
              accessor: 'customer.name',
              Header: 'Customer',
            },
            {
              accessor: 'claimableFrom',
              Header: 'Date Starts',
              Cell: ({ original }) => getFormattedDateTimeString(original.claimableFrom),
            },
            {
              accessor: 'claimableTo',
              Header: 'Date Expire',
              Cell: ({ original }) => getFormattedDateTimeString(original.claimableTo),
            },
            {
              accessor: 'balanceOfUsage',
              Header: 'Balance',
              className: 'justify-content-end',
              headerClassName: 'justify-content-end',
              sortable: false,
              Cell: ({ original }) => formatNumber(original.balanceOfUsage, 0),
            },
            {
              Header: 'Action',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              show: allowedToOrderRole(),
              sortable: false,
              Cell: ({ original }) => (
                <>
                  <IconButton icon="md-create" onClick={() => this.handleEdit(original)} />
                  <IconButton icon="ios-trash" onClick={() => this.confirmDeletion(original)} />
                </>
              ),
            },
          ]}
          data={Table.data}
          loadData={params => fetchTable(data.id, params)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          ref={ref => (this.table = ref)}
          search
          sort={{ id: 'claimableFrom', desc: true }}
        />
        {isCouponFormModalOpen && (
          <CouponFormModal
            data={data}
            open={isCouponFormModalOpen}
            toggleModal={this.toggleCouponFormModal}
            selected={selected}
            onCreateSuccess={this.handleCouponFormSuccess}
            couponType={couponType}
          />
        )}
      </Card>
    )
  }
}

export default decorate(inject('catalogPricingRuleRepository', 'couponRepository')(observer(Coupons)), {})
