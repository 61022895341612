/* @flow */

import BaseRepository from './BaseRepository'
import DeviceManagementCommentServices from 'services/deviceManagementComment'

class DeviceManagementCommentRepository extends BaseRepository {
  service: DeviceManagementCommentServices
  constructor(service: DeviceManagementCommentServices) {
    super(service)
    this.service = service
  }
}

export default DeviceManagementCommentRepository
