import cx from 'classnames'
import React from 'react'

import { Button, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap'
import { observer } from 'mobx-react'

const InputGroupField = ({
  field,
  disabled,
  className,
  inputClassName,
  onChange = null,
  placeholder = `Enter ${field.label}`,
  buttonText = 'Action',
  color = 'primary',
  rounded = false,
  onBtnClick = null,
  onInputClear = null,
  ...rest
}) => {
  const { type = 'text', name, label } = field
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  return (
    <FormGroup key={name} className={cx(className)}>
      {label && <Label>{label}</Label>}
      <InputGroup>
        <Input
          {...field.bind({
            name,
            type,
            // id: name,
            disabled: disabled ? disabled : field.disabled,
            ...(onChange && {
              onChange: val => {
                field.onChange(val)
                field.validate()

                if (onChange) onChange(val)
              },
            }),
          })}
          placeholder={placeholder}
          invalid={hasError}
          className={cx(inputClassName)}
          {...rest}
        />
        <InputGroupAddon
          addonType="prepend"
          style={{
            position: 'absolute',
            right: 5,
            zIndex: 5,
          }}
        >
          {field.value && onInputClear ? (
            <Button
              className="clear"
              disabled={disabled}
              size="xs"
              onClick={() => {
                onInputClear()
              }}
              style={{
                position: 'absolute',
                right: 7,
                top: 5,
                borderRadius: '50%',
                background: 'transparent',
                color: '#000',
                border: 'none !important',
                height: 25,
                width: 25,
                padding: 0,
                zIndex: 5,
              }}
              type="button"
            >
              ✕
            </Button>
          ) : (
            <Button
              className={cx(rounded && 'btn-rounded')}
              color={color}
              onClick={onBtnClick}
              disabled={disabled ? disabled : field.disabled}
              type="button"
            >
              {buttonText}
            </Button>
          )}
        </InputGroupAddon>
      </InputGroup>
      {hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </FormGroup>
  )
}

export default observer(InputGroupField)
