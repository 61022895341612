import React, { Component } from 'react'

import { observer } from 'mobx-react'
import _ from 'lodash'

import { handleDeleteRule, handleProductAttribute, toggleOperator } from './itemFunctions'
import { Visible } from 'helpers/Auth'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import SelectField from './SelectField'

class NormalRule extends Component {
  render() {
    const { index, item, parentCriteria, commonRepository, onAttributeSearch, AttributeCollection } = this.props

    let allowedAttributeSupportedType
    let allowedOperators = []
    let attributeLabel
    let attributeType
    let multipleValuesInd = false

    if (!AttributeCollection.loading && AttributeCollection.data) {
      const flatAttributes = Object.keys(AttributeCollection.data).reduce(
        (list, key) => list.concat(AttributeCollection.data[key]),
        []
      )
      const attributeObj = _.find(flatAttributes, { attributeName: item.fieldName })

      if (attributeObj) {
        attributeLabel = attributeObj.attributeLabel
        attributeType = attributeObj.attributeType
        allowedAttributeSupportedType = attributeObj.attributeType
        multipleValuesInd = attributeObj.multipleValuesInd
      }

      allowedOperators = commonRepository.Common.pricingRuleOperations.data
        .filter(opt => opt.supportedTypes.indexOf(allowedAttributeSupportedType) !== -1)
        .map(opt => ({
          label: opt.label,
          value: opt.value,
        }))
    }

    return (
      <div className="row-item">
        <span>{attributeLabel}</span>
        <SelectField
          onBlur={() => toggleOperator(item)}
          onChange={selected => handleProductAttribute(selected.value, item, 'operator')}
          value={item.operator}
          options={allowedOperators}
        />
        {attributeType === 'number' && (
          <span>
            <input
              className="form-control"
              type="number"
              style={{ fontSize: 14, height: 30 }}
              onBlur={e => {
                handleProductAttribute(
                  {
                    label: e.target.value,
                    value: e.target.value,
                  },
                  item,
                  'attribute'
                )
              }}
              defaultValue={item.fieldValue ? item.fieldValue.value : 0}
            />
          </span>
        )}
        {attributeType !== 'number' && (
          <ReactSelect
            className="select-search"
            customLabelKey={['label', 'value']}
            serverSide
            search={payload =>
              onAttributeSearch({
                ...payload,
                attributeName: item.fieldName,
                searchType: 'unique',
                attributeType,
              })
            }
            onChange={vals => handleProductAttribute(vals, item, 'attribute')}
            options={{
              isMulti: multipleValuesInd,
              valueKey: 'value',
              labelKey: 'label',
              defaultOptions: true,
              value: item.fieldValue,
            }}
          />
        )}
        <Visible toOrderProcessRole>
          <IconButton icon="ios-trash" className="ml-1" onClick={() => handleDeleteRule(parentCriteria, index)} />
        </Visible>
      </div>
    )
  }
}

export default observer(NormalRule)
