/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CollectionServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/common'
    super(api, url)

    this.api = api
    this.url = url
  }

  async fetchTable(payload) {
    return await this.api.get(`${this.url}/collections`, payload)
  }

  async getDescription(collectionName) {
    return await this.api.get(`${this.url}/${collectionName}/collection/description`)
  }

  async patchDescription(collectionName, description) {
    return await this.api.patch(
      `${this.url}/${collectionName}/collection/description?description=${encodeURIComponent(description)}`
    )
  }
}

export class CollectionItemServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/common'
    super(api, url)

    this.api = api
    this.url = url
  }

  async fetchTable(collectionName, payload) {
    return this.api.get(`${this.url}/${collectionName}/items`, payload)
  }

  getById({ collectionName, id }) {
    return this.api.get(`${this.url}/${collectionName}/items/${id}`)
  }

  delete({ collectionName, id }) {
    return this.api.del(`${this.url}/${collectionName}/items/${id}`)
  }

  patch({ collectionName, id, ...payload }) {
    return this.api.patch(`${this.url}/${collectionName}/items/${id}`, payload)
  }

  create({ collectionName, ...payload }) {
    return this.api.post(`${this.url}/${collectionName}/items`, payload)
  }
}
