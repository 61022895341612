import React from 'react'

import { observer } from 'mobx-react'

import { ActionsStyled, CustomInputStyled, ReactSelectStyled } from './ProductTable.styled'
import CategoryFilter from 'components/Products/ProductTable/CategoryFilter'

class Actions extends React.Component {
  filters = {}

  constructor(props) {
    super(props)
    props.commonRepository.getSelectItemCollection('productSeries')
    this.initializeDefaultFilters()
  }

  initializeDefaultFilters = () => {
    const { searchRepository, pageKey, defaultFilters } = this.props
    this.filters = searchRepository.Pages[pageKey]?.filters || defaultFilters
  }

  render() {
    const { commonRepository, categoryRepository } = this.props
    const { SelectItems: { collections: { productSeries: { data: productSeriesData = [] } = {} } } = {} } =
      commonRepository

    return (
      <ActionsStyled className="d-flex justify-content-end align-items-start mb-1">
        <ReactSelectStyled
          plain
          className="mr-2 select-series"
          options={{
            placeholder: 'Filter By Series',
            options: productSeriesData,
            defaultValue: this.filters?.productSeries,
          }}
          showTooltip
          width="180px"
          onChange={selected => this.props.handleFilter('productSeries', selected)}
        />
        <CategoryFilter
          categoryRepository={categoryRepository}
          defaultProductCategories={this.filters?.productCategories}
          onChange={selection => this.props.handleFilter('productCategories', selection)}
        />
        <ReactSelectStyled
          plain
          className="mr-2"
          serverSide
          search={params => commonRepository.reactSelectSearch(params, 'productOrderProcess')}
          options={{
            placeholder: 'Filter By Process Order',
            valueKey: 'value',
            labelKey: 'label',
            defaultOptions: true,
            defaultValue: this.filters?.orderProcess,
          }}
          showTooltip
          width="210px"
          onChange={selected => this.props.handleFilter('orderProcess', selected)}
        />
        <CustomInputStyled
          type="switch"
          label="Show Disabled"
          id="showDisableProducts"
          className="mr-2"
          defaultChecked={this.filters?.showDisabled}
          onChange={event => this.props.handleFilter('showDisabled', event.target.checked)}
          style={{ width: '' }}
        />
      </ActionsStyled>
    )
  }
}

export default observer(Actions)
