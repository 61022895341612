import { Button, Progress } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ImportErrorDetailModal from './ImportErrorDetailModal'

class ImportProcess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      status: {
        progress: 0,
        total: 0,
        processed: 0,
        successCount: 0,
        failedCount: 0,
      },
      uploading: true,
      errors: [],
      errorDetailOptions: {},
    }

    this.setIntervalId = null
  }

  componentDidMount() {
    this.startImport()
  }

  componentWillUnmount() {
    this.stopTracking()
  }

  getImportFileData = () => {
    const {
      importRepository: { ImportList },
      importData: { key },
    } = this.props

    if (ImportList.hasOwnProperty(key)) {
      return ImportList[key]
    }
    return {
      data: {
        completed: false,
      },
    }
  }

  getTitle = (fileType, completed, hasErrors) => {
    const title = !(hasErrors || completed) ? '' : hasErrors ? 'FAILED' : 'SUCCESS!'
    return (
      <span>
        {`Import ${fileType.label} `}
        {title && <span className={hasErrors ? 'text-danger' : 'text-success'}>{title}</span>}
      </span>
    )
  }

  startImport = async () => {
    const attachments = await this.uploadFiles()
    this.setState({ uploading: false }, () => {
      if (attachments) {
        this.trackImportProgress()
        this.importFiles(attachments)
      }
    })
  }

  uploadFiles = async () => {
    const {
      importData: { key, files },
    } = this.props

    const payload = files.map(importFile => new File([importFile.file], importFile.name, { type: 'csv' }))
    const { data, errors } = await this.props.fileUploadRepository.uploadFile(payload, key)

    errors.length > 0 && this.reportError(errors)
    return errors.length === 0 && data
  }

  importFiles = async attachments => {
    const {
      importRepository,
      importData: { key, fileType, continueOnError, updateDuplicate },
    } = this.props

    const payload = {
      id: key,
      attachments,
      entity: fileType.value,
      continueOnError,
      updateDuplicate,
    }

    await importRepository.importFile(payload)
  }

  trackImportProgress = async () => {
    this.setIntervalId = await setInterval(async () => {
      const {
        importRepository,
        importData: { key },
      } = this.props

      await importRepository.getByKey(key, key)
      this.setState({}, () => {
        const { data, errors } = this.getImportFileData()
        const {
          importData: { continueOnError },
          reloadHistory,
        } = this.props

        if (errors && errors.length > 0) {
          this.reportError(errors)
        }

        if (!continueOnError || data.completed) {
          this.stopTracking()
          reloadHistory()
        }
      })
    }, 700)
  }

  stopTracking = () => {
    clearInterval(this.setIntervalId)
  }

  reportError = errors => {
    const errorList = errors.map(item => {
      if (item.message) {
        return item.message
      }
      return { message: item }
    })
    this.setState({ errors: errorList })
  }

  handleClose = () => {
    const { removeImportFile, importData } = this.props
    removeImportFile(importData.key)
  }

  showErrorDetailModal = (show, fileTypeLabel, errors) => {
    this.setState({ errorDetailOptions: { show, fileTypeLabel, errors } })
  }

  renderUploading() {
    return (
      <div className="mt-2 font-size-12">
        <div>Importing...</div>
        <Progress className="my-1" />
      </div>
    )
  }

  renderImporting() {
    const { data } = this.getImportFileData()

    const processed = (data.failedCount || 0) + (data.successCount || 0)
    let progress = 0

    if (data.completed) {
      progress = 100
    } else {
      if (data.total > 0) {
        progress = Math.floor(100 * (processed / data.total))
      }
    }

    return (
      <div className="mt-2 font-size-12">
        <div>{progress}% completed</div>
        <Progress variant="determinate" className="my-1" value={progress} />
        <div>
          Processed {processed} of {data.total || 0}
        </div>
        <div>Success: {data.successCount}</div>
        <div>Failed: {data.failedCount}</div>
      </div>
    )
  }

  render() {
    const { errors: stateErrors, errorDetailOptions, uploading } = this.state
    const {
      importData: { fileType },
    } = this.props

    const { data, errors: importErrors } = this.getImportFileData()
    const errors = stateErrors || importErrors
    const hasErrors = errors.length > 0

    return (
      <React.Fragment>
        <Card
          title={this.getTitle(fileType, data.completed, hasErrors)}
          onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleRetry })}
          errors={errors}
          titleActions={
            (hasErrors || data.completed) && (
              <IconButton title="Close this section" icon="close" onClick={this.handleClose} />
            )
          }
        >
          {hasErrors && (
            <div className="mt-2">
              <ErrorsList errors={errors} />
              {!!data?.importFailedDetails && (
                <Button
                  color="primary"
                  size="xs"
                  onClick={() => {
                    this.showErrorDetailModal(true, fileType.label, data.importFailedDetails)
                  }}
                >
                  Show Error Details
                </Button>
              )}
            </div>
          )}

          {uploading && this.renderUploading()}
          {!uploading && this.renderImporting()}
        </Card>
        {!!errorDetailOptions?.show && (
          <ImportErrorDetailModal {...errorDetailOptions} onSubmit={() => this.showErrorDetailModal(false)} />
        )}
      </React.Fragment>
    )
  }
}

export default decorate(inject('importRepository', 'fileUploadRepository')(observer(ImportProcess)), {})
