export default {
  BootstrapFieldReimplemented: ({ $try, field, props }) => ({
    type: $try(props.type, field.type),
    id: $try(props.id, field.id),
    name: $try(props.name, field.name),
    value: $try(props.value, field.value),
    checked: $try(props.checked, field.checked),
    label: $try(props.label, field.label),
    placeholder: $try(props.placeholder, field.placeholder),
    errorStyle: field.validating ? { background: 'yellow', color: 'black' } : {},
    disabled: $try(props.disabled, field.disabled),
    onChange: $try(props.onChange, field.onChange),
    onBlur: $try(props.onBlur, field.onBlur),
    onFocus: $try(props.onFocus, field.onFocus),
  }),

  BootstrapField: {
    id: 'id',
    name: 'name',
    type: 'type',
    value: 'value',
    checked: 'checked',
    label: 'label',
    placeholder: 'placeholder',
    disabled: 'disabled',
    error: 'error',
    onChange: 'onChange',
    onBlur: 'onBlur',
    onFocus: 'onFocus',
  },
}
