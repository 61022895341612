import React from 'react'

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

import { getFormattedDateString } from 'helpers/Formatters'
import { ORDER_STATUS_PENDING_SUBSCRIPTION } from 'definitions'
import { Visible } from 'helpers/Auth'

import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'

export default withRouter(
  ({
    data,
    readOnly,
    handleEditMode,
    handleCancel,
    handleSaveDraft,
    handleSaveOrder,
    location,
    isSubmitted,
    loading,
  }) => {
    const qs = queryString.parse(location.search)
    const { orderSubscription = {}, status } = data
    return (
      <ActionsStyled>
        {!qs.loan ? (
          <div className="subscription-period">
            Subscription Period
            <strong className="ml-3 px-2 py-1 rounded">
              {getFormattedDateString(orderSubscription.subscriptionStartDate)} -{' '}
              {getFormattedDateString(orderSubscription.subscriptionEndDate)}
            </strong>
          </div>
        ) : null}

        {loading && <Loader text="" className="ml-2 p-0" />}
        <Visible toOrderProcessRole>
          {((!qs.loan && !isSubmitted) || status === ORDER_STATUS_PENDING_SUBSCRIPTION) && !loading && (
            <UncontrolledDropdown>
              <DropdownToggle nav className="ml-2 p-0">
                <IconButton icon="md-more" className="icon-more" iconSize="large" />
              </DropdownToggle>
              <DropdownMenu right>
                {qs.contract && !isSubmitted && status !== ORDER_STATUS_PENDING_SUBSCRIPTION ? (
                  <React.Fragment>
                    {readOnly ? (
                      <DropdownItem onClick={handleEditMode}>Edit Contract</DropdownItem>
                    ) : (
                      <DropdownItem onClick={handleSaveDraft}>Save Draft</DropdownItem>
                    )}
                    <DropdownItem onClick={handleSaveOrder}>Save Contract</DropdownItem>
                    <DropdownItem onClick={handleCancel}>Cancel Contract</DropdownItem>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <DropdownItem>Renew Subscription</DropdownItem> */}
                    {qs.contract && (
                      <>
                        <DropdownItem onClick={handleEditMode}>Edit Contract</DropdownItem>
                        <DropdownItem onClick={handleSaveOrder}>Save Contract</DropdownItem>
                      </>
                    )}
                    <DropdownItem onClick={handleCancel}>Cancel Subscription</DropdownItem>
                  </React.Fragment>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </Visible>
      </ActionsStyled>
    )
  }
)

const ActionsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  label {
    cursor: pointer;
  }
  .btn-outline-primary {
    width: 150px;
    small {
      font-size: 90%;
      font-weight: 600;
    }
  }
  .icon-more {
    color: #bdbdbd;
  }
  div.subscription-period {
    font-size: 12px;
    strong {
      background-color: ${props => props.theme.lightDark};
    }
  }
`
