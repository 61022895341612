import { components } from 'react-select'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import { ReactSelectStyled } from './ProductTable.styled'

class CategoryFilter extends Component {
  state = {
    categories: [],
  }

  componentDidMount() {
    this.loadCategories()
  }

  loadCategories = async () => {
    const { categoryRepository } = this.props

    await categoryRepository.fetchTable()
    const {
      Table: { data = [] },
    } = categoryRepository
    this.setState({
      categories: this.flattenedCategoryTreeList(data),
    })
  }

  flattenedCategoryTreeList = (categories = [], flatCategories = [], level = 0) => {
    if (categories) {
      return categories.reduce((flatCategories, item) => {
        flatCategories.push({
          value: item.id,
          label: item.name,
          level,
          isParent: item.subCategories != null,
        })
        return this.flattenedCategoryTreeList(item.subCategories, flatCategories, level + 1)
      }, flatCategories)
    }
    return flatCategories
  }

  render() {
    return (
      <ReactSelectStyled
        plain
        className="mr-2 category-filter"
        options={{
          isMulti: true,
          placeholder: 'Filter By Category',
          defaultValue: this.props.defaultProductCategories,
          options: this.state.categories,
          closeMenuOnSelect: false,
          components: {
            Option: props => (
              <OptionStyled categoryLevel={props.data.level}>
                {props.data.isParent && <i className="icon ion-md-folder text-primary" />}
                {!props.data.isParent && <i className="icon ion-ios-list text-primary" />}
                <components.Option {...props} />
              </OptionStyled>
            ),
          },
        }}
        width="280px"
        onChange={this.props.onChange}
      />
    )
  }
}

const OptionStyled = styled.div`
  &&& {
    display: flex;
    padding-left: 12px;
    .icon {
      margin-left: ${props => props.categoryLevel * 20}px;
    }
    .react-select__option {
      cursor: pointer;
      padding-left: 8px;
      font-size: 14px;
    }
  }
`

export default observer(CategoryFilter)
