/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import OrderChargeServices from 'services/orderCharge'

class OrderChargeRepository extends BaseRepository {
  service: OrderChargeServices
  constructor(service: OrderChargeServices) {
    super(service)
    this.service = service
  }

  bulkCopy = async payload => {
    this.CRUD.submitting = true
    const result = await this.service.bulkCopy(payload)
    this.CRUD.submitting = false
    return result
  }
  bulkDelete = payload => {
    return this.service.bulkDelete(payload)
  }
}

export default decorate(OrderChargeRepository, {})
