import React, { Component } from 'react'

import queryString from 'query-string'

import ProductTable from './ProductTable'

class Products extends Component {
  componentDidMount() {
    const { history } = this.props
    const { search } = queryString.parse(this.props.location.search)
    search && history.replace('/products', {})
  }
  render() {
    const { search } = queryString.parse(this.props.location.search)
    return <ProductTable {...this.props} searchCriteria={search} />
  }
}

export default Products
