import React, { Component } from 'react'

import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

class ContextMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }
  toggle = () => this.setState({ isOpen: !this.state.isOpen })
  render() {
    const { disabled, dropDownToggle = null, menus = [], plain = false, className = '' } = this.props
    return (
      <ButtonDropdown isOpen={this.state.isOpen} toggle={this.toggle} className={className}>
        {dropDownToggle && !plain ? (
          <DropdownToggle caret color="primary" disabled={disabled}>
            {dropDownToggle}
          </DropdownToggle>
        ) : (
          <DropdownToggle tag="span" data-toggle="dropdown" className="cursor-pointer">
            {dropDownToggle}
          </DropdownToggle>
        )}
        <DropdownMenu>
          {menus
            .filter(item => !item.hidden)
            .map(({ text, onClick, disabled = false }, key) => (
              <DropdownItem key={key} disabled={disabled} onClick={onClick}>
                {text}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </ButtonDropdown>
    )
  }
}

export default ContextMenu
