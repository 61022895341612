import React, { Component } from 'react'

import { Card, CardBody, CardTitle, Container } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
// import cx from 'classnames'
import styled from 'styled-components'

import { SSO_CALLBACK, SSO_STATE } from 'definitions'
import ErrorsList from 'components/Common/ErrorsList'
import Loader from 'components/Common/Loader'
// import loginButtonImage from 'images/btn_google_signin_dark_pressed_web@2x.png'

import favicon from 'images/favicon.png'
import logo from 'images/logo.svg'

class Login extends Component {
  handlePeplinkIdLogin = () => {
    const { location } = this.props
    const REDIRECT_URL = localStorage.getItem('REDIRECT_URL')
    if (!REDIRECT_URL) {
      const tokens = queryString.parse(location.search)
      localStorage.setItem('REDIRECT_URL', `${location.pathname}${tokens.login ? '' : location.search}`)
    }
    // eslint-disable-next-line max-len
    window.location.href = `${process.env.REACT_APP_PEPLINK_SSO_SERVER_URL}${process.env.REACT_APP_PEPLINK_SSO_AUTH_PATH}?client_id=${process.env.REACT_APP_PEPLINK_SSO_CLIENT_ID}&response_type=token&redirect_uri=${process.env.REACT_APP_BASE_URL}${SSO_CALLBACK}&state=${SSO_STATE}`
  }
  render() {
    const { authRepository } = this.props
    return (
      <LoginStyled fluid>
        <div className="d-flex flex-column align-items-center w-100">
          <Card className="mb-5 shadow-sm border-0">
            <CardBody>
              <CardTitle className="mb-4">
                <img className="logo" src={logo} alt="Peplink" />
              </CardTitle>
              <CardTitle className="mb-3 login-title">Please sign in</CardTitle>
              <a
                href="#!"
                className="d-flex justify-content-center font-weight-bold mt-3 mb-3"
                onClick={this.handlePeplinkIdLogin}
              >
                <div className="btn-ic2">
                  <div className="login-icon-wrapper">
                    <img className="login-icon ic2" src={favicon} alt="SSO Login" />
                  </div>
                  <p className="btn-login">Sign in with Peplink ID</p>
                </div>
              </a>
              {authRepository.Auth.errors.length ? <ErrorsList errors={authRepository.Auth.errors} /> : null}
              {authRepository.Auth.loading && <Loader text="Please wait..." className="d-block p-0 pt-2" />}
            </CardBody>
          </Card>
          <small>© {new Date().getFullYear()} Peplink. All Rights Reserved.</small>
        </div>
      </LoginStyled>
    )
  }
}

const LoginStyled = styled(Container)`
  margin-top: 10rem;
  .card {
    width: 280px;
    .card-body {
      padding-top: 40px;
      padding-bottom: 40px;
      text-align: center;
      .logo {
        height: 50px;
      }
      .login-button {
        height: 40px;
        cursor: pointer;
        &.disabled {
          opacity: 0.5;
        }
      }
    }
  }

  .login-title {
    font-size: 13px;
  }
`

export default decorate(inject('authRepository')(observer(Login)), {})
