import cx from 'classnames'
import React from 'react'

import { FormGroup, Label, UncontrolledTooltip } from 'reactstrap'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const ReadOnlyValue = props => {
  const {
    plain,
    className = '',
    valueClassName,
    value,
    label,
    title,
    type = 'input',
    disabled,
    wrapped,
    showTooltip,
    ...rest
  } = props
  const readOnlyFieldId = showTooltip ? `readOnlyField${Math.random().toString().split('.')[1]}` : null
  const tooltipText = title || value

  return (
    <React.Fragment>
      {plain ? (
        <FormControlStyled
          id={readOnlyFieldId}
          {...rest}
          className={cx('form-control', wrapped && 'wrapped', className || valueClassName)}
          disabled={disabled}
        >
          {value}
        </FormControlStyled>
      ) : (
        <FormGroup className={className}>
          {label && <Label>{label}</Label>}
          {type === 'input' ? (
            <FormControlStyled
              id={readOnlyFieldId}
              className={cx('form-control', wrapped && 'wrapped', valueClassName)}
              disabled={disabled}
            >
              {value}
            </FormControlStyled>
          ) : (
            <textarea
              id={readOnlyFieldId}
              readOnly
              rows={3}
              className={cx('form-control', valueClassName)}
              value={value}
            />
          )}
        </FormGroup>
      )}
      {showTooltip && !!tooltipText && (
        <UncontrolledTooltip key={readOnlyFieldId} target={readOnlyFieldId} autohide={false} placement="bottom">
          {tooltipText}
        </UncontrolledTooltip>
      )}
    </React.Fragment>
  )
}

const FormControlStyled = styled.div`
  &&& {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.wrapped {
      white-space: pre-line;
      height: fit-content;
    }

    &[disabled] {
      background-color: #e6e6e6;
      opacity: 1;
    }
  }
`

export default observer(ReadOnlyValue)
