import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, FormText, Input, Label } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import styled from 'styled-components'

import InputForm from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

class TimePeriodPicker extends React.Component {
  state = {
    isOpen: false,
  }

  componentDidMount() {
    const { commonRepository } = this.props
    commonRepository.getSelectItemCollection('collections/timePeriodUnits')
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  updateTimePeriodProxy = () => {
    const { form } = this.props
    const timePeriod = parseInt(form.$('timePeriod').value * 100, 10) / 100
    form.$('timePeriod').set(timePeriod)
  }

  handleTimePeriodUnitChanged = selected => {
    const { form } = this.props
    if (selected?.value) {
      const timePeriodField = form.$('timePeriod')
      if (timePeriodField.value) {
        switch (selected.value) {
          case 'days':
            if (timePeriodField.value > 31) {
              timePeriodField.set(31)
            }
            break
          case 'weeks':
            if (timePeriodField.value > 4) {
              timePeriodField.set(4)
            }
            break
          case 'months':
            if (timePeriodField.value > 11) {
              timePeriodField.set(11)
            }
            break
          case 'years':
            if (timePeriodField.value > 5) {
              timePeriodField.set(5)
            }
            break
        }
      }
    }
    this.updateTimePeriodProxy()
  }

  render() {
    const { commonRepository, form, label, disabled, readOnly, className } = this.props
    const { isOpen } = this.state

    const {
      SelectItems: {
        collections: {
          // eslint-disable-next-line no-useless-computed-key
          ['collections/timePeriodUnits']: { data: timeUnitPeriods = [], loading: timeUnitPeriodsLoading = true } = {},
        },
      },
    } = commonRepository

    const dropdownDisabled = disabled || readOnly

    const timePeriod = form.$('timePeriod').value
    const timePeriodUnit = form.$('timePeriodUnit').value

    const timePeriodError = form.$('timePeriod').error
    const timePeriodUnitError = form.$('timePeriodUnit').error
    const fieldError = timePeriodError || timePeriodUnitError

    return (
      <TimePeriodPickerStyled className={cx(className)}>
        {label && <Label>{label}</Label>}
        <Dropdown isOpen={isOpen} toggle={this.toggle} disabled={dropdownDisabled}>
          <DropdownToggle tag="div" disabled={dropdownDisabled}>
            <div className="input-proxy">
              <Input
                readOnly
                value={`${timePeriod > 0 ? timePeriod : '0'} ${timePeriodUnit?.label || ''}`}
                className="bg-white"
              />
              {!!fieldError && (
                <FormText color="danger" className="text-wrap">
                  {fieldError}
                </FormText>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem text tag="div" toggle={false} disabled className="px-3 pt-3 pb-1 bg-white">
              <div>Set time period</div>
            </DropdownItem>
            <DropdownItem text tag="div" toggle={false} className="px-3 pt-0 pb-3 bg-white">
              <div className="d-flex">
                <InputForm
                  field={form.$('timePeriod')}
                  min={0}
                  className="col-4 p-0 pr-1 m-0 time-period"
                  inputClassName="px-1"
                  allowNegative={false}
                  allowExponent={false}
                  innerRef={ref => {
                    ref && ref.focus()
                  }}
                  disabled={!timePeriodUnit?.value}
                  onChange={this.updateTimePeriodProxy}
                />
                <ReactSelect
                  field={form.$('timePeriodUnit')}
                  options={{
                    options: timeUnitPeriods,
                    isLoading: timeUnitPeriodsLoading,
                    isClearable: false,
                    valueKey: 'value',
                    labelKey: 'label',
                    defaultOptions: true,
                  }}
                  className="col-8 p-0 m-0 time-period-unit"
                  onChange={this.handleTimePeriodUnitChanged}
                />
              </div>
              {!!fieldError && (
                <FormText color="danger" className="text-wrap">
                  {fieldError}
                </FormText>
              )}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </TimePeriodPickerStyled>
    )
  }
}

const TimePeriodPickerStyled = styled(FormGroup)`
  &.form-group {
    .input-proxy {
      .form-control {
        height: 33px;
      }
    }
    .form-control {
      height: 36px;
      &:invalid,
      &.is-invalid {
        background: none !important;
      }
    }
    .react-select__control {
      height: 36px;
    }
    .time-period,
    .time-period-unit {
      .form-text {
        display: none;
      }
    }
    .dropdown {
      .dropdown-menu {
        width: 250px;
      }
    }
  }
`

export default observer(TimePeriodPicker)
