import AttributeTable from './AttributeTable'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

class Attributes extends Component {
  render() {
    return (
      <ContentContainer title="Attributes">
        <AttributeTable {...this.props} />
      </ContentContainer>
    )
  }
}

export default Attributes
