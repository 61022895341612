/* @flow */
import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import StorefrontSlideServices from 'services/storefrontSlide'

class StorefrontSlideRepository extends BaseRepository {
  service: StorefrontSlideServices
  constructor(service: StorefrontSlideServices) {
    super(service)
    this.service = service
  }
  List = {
    loading: false,
    data: [],
    errors: [],
  }

  fetchList = async storeId => {
    this.List.loading = true
    const { data, errors } = await this.service.fetchList(storeId)
    if (data && !errors.length) {
      this.List = {
        data,
        loading: false,
        errors: [],
      }
    } else {
      this.List = {
        ...this.List,
        loading: false,
        errors,
      }
    }
  }

  uploadImage = async (categoryId, payload) => {
    const { data, errors } = await this.service.uploadImage(categoryId, payload)
    this.CRUD.loading = true
    this.CRUD.errors = []
    if (errors.length) {
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
    return { data, errors }
  }
}

export default decorate(StorefrontSlideRepository, {
  List: observable,
})
