import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import React, { Component } from 'react'
import styled from 'styled-components'

export const getColor = color => {
  let titleIcon
  let bgColor
  switch (color) {
    case 'error':
      titleIcon = 'md-information-circle-outline'
      bgColor = 'danger'
      break
    case 'warning':
      titleIcon = 'md-warning'
      bgColor = 'primary'
      break
    case 'info':
      titleIcon = 'md-information-circle-outline'
      bgColor = 'secondary'
      break
    default:
      titleIcon = 'ios-checkmark-circle-outline'
      bgColor = 'success'
      break
  }
  return { titleIcon, bgColor }
}

class Alert extends Component {
  state = {
    open: false,
    options: {},
  }
  constructor(props) {
    super(props)
    props.bindActions(this.handleOpen, this.handleClose)
  }
  handleOpen = options => {
    this.setState({ open: true, options })
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  render() {
    const { open, options } = this.state
    const { title, message, onConfirm, onCancel, color = 'error', backdrop = 'static', confirmText } = options
    const { bgColor } = getColor(color)

    return (
      <AlertStyled
        isOpen={open}
        onClose={this.handleClose}
        backdrop={backdrop}
        wrapClassName="modal-wrapper"
        modalClassName="modal-class"
        zIndex={1060}
        keyboard
      >
        <ModalHeader className={`border-0 bg-${bgColor}`}>
          <div className="d-flex align-items-center pb-1">
            <i className="icon ion-md-information-circle-outline" />
            <span className="ml-1">{title}</span>
          </div>
        </ModalHeader>
        <ModalBody className={`border-0 bg-${bgColor}`}>{message}</ModalBody>
        <ModalFooter className={`border-0 bg-${bgColor}`}>
          {!!onConfirm && (
            <>
              <Button
                size="sm"
                color="default"
                onClick={() => {
                  this.handleClose()
                  onCancel && onCancel()
                }}
                className="text-white"
              >
                Cancel
              </Button>{' '}
              <Button
                size="sm"
                color="default"
                onClick={() => {
                  onConfirm && onConfirm()
                  this.handleClose()
                }}
                className="text-white"
              >
                {confirmText || 'Confirm'}
              </Button>
            </>
          )}
        </ModalFooter>
      </AlertStyled>
    )
  }
}

const AlertStyled = styled(Modal)`
  .modal-class {
    top: 50px;
  }
  &.modal-dialog {
    margin: 8px;
    position: absolute;
    top: 0;
    right: 0;
    width: 450px;
    color: #fff;
    .modal-content {
      border-radius: 10px;
      border-color: transparent;
      .bg-danger {
        background-color: rgba(215, 31, 31, 1) !important;
        font-size: 13px;
      }
      .modal-header {
        padding: 0.75rem 1.25rem 0;
        .modal-title {
          text-transform: none;
        }
      }
      .modal-body {
        padding: 1rem 1.25rem 0 2.5rem;
        font-size: 14px;
      }
      .modal-footer {
        padding: 0.75rem 1.25rem;
      }
    }
  }
`

export default Alert
