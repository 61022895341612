export const stripEmptyValues = payload => {
  payload &&
    Object.keys(payload).forEach(
      key => (payload[key] === null || payload[key] === '' || payload[key] === 'value') && delete payload[key]
    )
  return payload
}

export const isNull = (value, defaultValue = null) => {
  return value && value !== '' && value !== 'value' ? value : defaultValue
}

export const countDecimals = value => {
  if (value) {
    if (Math.floor(value.valueOf()) === value.valueOf()) return 0
    const tokens = value.toString().split('.')
    if (tokens.length > 1) {
      return tokens[1].length || 0
    }
  }
  return 0
}

export const sanitizeFilename = filename => {
  if (filename) {
    let newFilename = filename.replaceAll(' ', '-')
    return newFilename.replaceAll(/[\\/:*?"<>|]/g, '')
  }
  return filename
}

export const isArrayOrObject = value => typeof value === 'object' || Array.isArray(value)

export const objectToParams = obj => {
  var p = []
  for (var key in obj) {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined) {
      p.push(key + '=' + encodeURIComponent(obj[key]))
    }
  }
  return p.join('&')
}

export const kbToSizes = (kb, defaultUnit = 'GB') => {
  const localDefaultUnit = defaultUnit || defaultUnit.toUpperCase()

  if (!kb) {
    return `0 ${localDefaultUnit}`
  }

  const units = ['KB', 'MB', 'GB', 'TB']
  let index = parseInt(Math.floor(Math.log(kb) / Math.log(1024)), 10)

  if (index == 0) {
    const defaultUnitIndex = units.findIndex(item => item === localDefaultUnit)
    if (defaultUnitIndex !== index) {
      index = defaultUnitIndex
    } else {
      return `${kb} ${units[index]}`
    }
  }
  const converted = kb / Math.pow(1024, index)
  let value

  if (converted > 99) {
    value = converted.toFixed()
  } else {
    value = converted.toFixed(1)
  }

  const decimals = value.toString().split('.')
  if (decimals.length > 1) {
    if (parseInt(decimals[1], 10) <= 0) {
      value = decimals[0]
    }
  }

  return `${value} ${units[index]}`
}
