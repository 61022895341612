import React from 'react'

import { formatOrderPayload } from 'helpers/Formatters'
import ToasterNotify from 'helpers/ToasterNotify'

export const ProductPanelFunctions = {
  handleDeleteItem: (item, self) => {
    ToasterNotify.confirm({
      message: (
        <span>
          Are you sure you want to remove product? <br /> {item.product.product.name}
        </span>
      ),
      title: 'Warning',
      onConfirm: () => {
        const order = self.props.orderRepository.CRUD.data
        const orderId = self.props.form.$('id').value
        const payload = {
          order,
          item,
        }
        self.props.orderItemRepository.removeOrderItem(orderId, payload, res => {
          self.updateOrderData(res, true)
        })
      },
    })
  },
  handleDeleteCustomOption: (item, customOptionIndex, self) => {
    const newItemObj = { ...item }
    if (newItemObj.customOptionValues) {
      const customOption = newItemObj.customOptionValues[customOptionIndex]

      ToasterNotify.confirm({
        message: (
          <span>
            Are you sure you want to remove custom option? <br /> {customOption.name}
          </span>
        ),
        title: 'Warning',
        onConfirm: () => {
          newItemObj.customOptionValues.splice(customOptionIndex, 1)
          self.handleSubmit(newItemObj, true)
        },
      })
    }
  },
  handleDeleteDevice: (item, handleDeleteDevice, self) => {
    const newItemObj = { ...item }
    if (newItemObj.devices) {
      const device = newItemObj.devices[handleDeleteDevice]

      ToasterNotify.confirm({
        message: (
          <span>
            Are you sure you want to remove this device? <br /> Serial# {device.serialNumber}
            {newItemObj.devices.length === 1 && (
              <span>
                <br />
                <br /> This will also remove the order item.
              </span>
            )}
          </span>
        ),
        title: 'Warning',
        onConfirm: () => {
          newItemObj.devices.splice(handleDeleteDevice, 1)
          if (newItemObj.devices.length > 0) {
            self.handleSubmit(newItemObj, true)
          } else {
            self.doDeleteItem(item)
          }
        },
      })
    }
  },
  doDeleteItem: (item, self) => {
    const order = self.props.orderRepository.CRUD.data
    const orderId = self.props.form.$('id').value
    const payload = {
      order,
      item,
    }
    self.props.orderItemRepository.removeOrderItem(orderId, payload, res => {
      self.updateOrderData(res, true)
    })
  },
  handleQuantityChange: (value, item, self) => {
    item.quantity = parseInt(value, 10)
    self.handleSubmit(item, true)
  },
  handleOrderItemSerialNumbers: (newSerialNumbers, self, isSubscription, proceed) => {
    const selectedOrderItem = self.state.selected
    const serialNumbers = newSerialNumbers
      ? newSerialNumbers.split('\n').map(i => ({
          serialNumber: i.trim(),
          status: 'ACTIVE',
        }))
      : []
    const quantity = serialNumbers.length + (selectedOrderItem.iccidNumbers?.length || 0)

    if (serialNumbers?.length) {
      const {
        orderItemSerialNumberRepository: { patch, put },
        orderRepository,
        form,
      } = self.props
      const orderDetails = orderRepository.CRUD.data

      const payload = {
        ...(isSubscription
          ? {
              id: orderDetails.id,
              orderId: orderDetails.id,
              orderItemId: selectedOrderItem.id,
            }
          : {
              order: formatOrderPayload({ ...orderDetails, ...form.values(), manualShippingFeeInd: false }),
              item: {
                ...selectedOrderItem,
                quantity,
                serialNumbers,
              },
            }),
        serialNumbers,
        proceed,
      }
      const action = isSubscription ? put : patch
      action(
        payload,
        res => {
          if (res.sfcPlanDetails?.length) {
            self.showSfcCloudUnlimitedMessage(res.sfcPlanDetails)
          } else if (res.esimDetails?.length) {
            self.showEsimPlanMessage(res.esimDetails)
          } else {
            let newSelectedOrderItem = { ...res }
            self.handleSubmit(newSelectedOrderItem, false)
            self.toggleSerialNumbersModal()
          }
        },
        orderDetails.id
      )
    } else {
      selectedOrderItem.serialNumbers = []
      selectedOrderItem.quantity = quantity
      self.handleSubmit(selectedOrderItem, false)
      self.toggleSerialNumbersModal()
    }
  },
  handleOrderItemIccidNumbers: (newIccidNumbers, self, isSubscription, proceed) => {
    const selectedOrderItem = self.state.selected
    const iccidNumbers = newIccidNumbers ? newIccidNumbers.split('\n').map(item => item.trim()) : []
    const quantity = iccidNumbers.length + (selectedOrderItem.serialNumbers?.length || 0)

    if (iccidNumbers?.length) {
      const {
        orderItemIccidNumberRepository: { patch, put },
        orderRepository,
        form,
      } = self.props
      const orderDetails = orderRepository.CRUD.data

      const payload = {
        ...(isSubscription
          ? {
              id: orderDetails.id,
              orderId: orderDetails.id,
              orderItemId: selectedOrderItem.id,
            }
          : {
              order: formatOrderPayload({ ...orderDetails, ...form.values(), manualShippingFeeInd: false }),
              item: {
                ...selectedOrderItem,
                quantity,
                iccidNumbers,
              },
            }),
        iccidNumbers,
        proceed,
      }
      const action = isSubscription ? put : patch
      action(
        payload,
        res => {
          if (res.esimDetails?.length) {
            self.showEsimPlanMessage(res.esimDetails)
          } else {
            let newSelectedOrderItem = { ...res }
            self.handleSubmit(newSelectedOrderItem, false)
            self.toggleIccidNumbersModal()
          }
        },
        orderDetails.id
      )
    } else {
      selectedOrderItem.iccidNumbers = []
      selectedOrderItem.quantity = quantity
      self.handleSubmit(selectedOrderItem, false)
      self.toggleIccidNumbersModal()
    }
  },
  handleOrderItemOrganizationNumbers: (organizationNumbers, self) => {
    const selectedOrderItem = self.state.selected
    selectedOrderItem.ic2Organizations = organizationNumbers
    selectedOrderItem.quantity = organizationNumbers.length
    self.updateOrderItemsData([selectedOrderItem])
  },
  handleOrderItemFscId: (fscId, self) => {
    const selectedOrderItem = self.state.selected
    selectedOrderItem.fscId = fscId
    self.updateOrderItemsData([selectedOrderItem])
  },
  handleSubmit: (item, updateShippingTotal, self) => {
    const { readOnly, orderRepository, orderItemRepository, form } = self.props
    const orderItems = orderRepository.CRUD.data.orderItems
    if (!readOnly) {
      orderItemRepository.patchOrderItem(
        {
          order: formatOrderPayload({
            ...orderRepository.CRUD.data,
            ...form.values(),
            manualShippingFeeInd: false,
            orderItems,
          }),
          item,
        },
        item.id,
        res => {
          self.updateOrderData(res.order, updateShippingTotal)
        }
      )
    }
  },
  handleOrderItemChangeCustomDiscountedPrice: (val, index, self) => {
    const orderId = self.props.form.$('id').value
    const order = formatOrderPayload({
      ...self.props.orderRepository.CRUD.data,
      ...self.props.form.values(),
      manualShippingFeeInd: false,
    })
    let orderItems = [...order.orderItems]
    if (orderItems[index].id) {
      orderItems[index].customDiscountedPrice = parseFloat(val)
      self.props.orderItemRepository.patchDiscount(
        {
          order,
          item: orderItems[index],
          discount: {
            discountInd: self.state.discountInd,
          },
        },
        orderId,
        res => {
          self.updateOrderData(res.order)
        }
      )
    }
  },
  handleCustomOptionChangeCustomDiscountedPrice: (val, orderItemIndex, customOptionIndex, self) => {
    const orderId = self.props.form.$('id').value
    const order = formatOrderPayload({
      ...self.props.orderRepository.CRUD.data,
      ...self.props.form.values(),
      manualShippingFeeInd: false,
    })
    let orderItems = [...order.orderItems]
    let customOptionValues = orderItems[orderItemIndex].customOptionValues
    if (orderItems[orderItemIndex].id && customOptionValues[customOptionIndex]) {
      orderItems[orderItemIndex].customOptionValues[customOptionIndex].customDiscountedPrice = parseFloat(val)
      self.props.orderItemRepository.patchDiscount(
        {
          order,
          item: orderItems[orderItemIndex],
          discount: {
            discountInd: self.state.discountInd,
          },
        },
        orderId,
        res => {
          self.updateOrderData(res.order)
        }
      )
    }
  },
  submitOrderItem: (product, callback = null, isCustomOptions = false, self) => {
    const orderId = self.props.form.$('id').value
    const order = formatOrderPayload({
      ...self.props.orderRepository.CRUD.data,
      ...self.props.form.values(),
      manualShippingFeeInd: false,
    })
    if (isCustomOptions) {
      self.props.orderItemRepository.patchOrderItem(
        {
          order,
          item: product,
        },
        orderId,
        result => {
          self.toggleCustomOptionsModal()
          self.updateOrderData(result.order, true)
          if (callback) callback()
        }
      )
    } else {
      self.props.orderItemRepository.create(
        {
          order,
          items: [product],
        },
        orderId,
        result => {
          self.updateOrderData({ ...result.order, customer: self.props.form.$('customer').value }, true)
          self.toggleProductModal()
          if (callback) callback()
        }
      )
    }
  },
  updateOrderData: (order, updateShippingTotal, self, callSummary = true) => {
    self.props.orderRepository.CRUD.data = { ...order }
    self.props.orderRepository.Discounts.data = order.discountDetails
    self.props.form.$('orderItems').set(order.orderItems)
    self.props.orderItemRepository.Table.originalData = {
      orderItems: order.orderItems,
      totalAmount: 0,
    }
    if (callSummary) self.props.computeOrderSummary(updateShippingTotal)
  },
  updateOrderItemsData: (orderItems, compute, self) => {
    const order = self.props.orderRepository.CRUD.data
    const itemIds = orderItems.map(item => item.id)
    self.props.orderRepository.CRUD.data = {
      ...self.props.orderRepository.CRUD.data,
      orderItems: [...orderItems, ...(order.orderItems || []).filter(item => !itemIds.includes(item.id))],
    }
    self.props.orderItemRepository.Table.data = order.orderItems
    self.props.orderItemRepository.Table.originalData = {
      orderItems: order.orderItems,
      totalAmount: 0,
    }
    compute && self.props.computeOrderSummary()
  },
}
