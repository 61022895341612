/* @flow */
import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import GlobalSearchServices from 'services/globalSearch'

const defaultData = {
  data: [],
  errors: [],
}

class GlobalSearchRepository extends BaseRepository {
  service: GlobalSearchServices
  Tables = {
    orders: defaultData,
  }
  constructor(service: GlobalSearchServices) {
    super(service)
    this.service = service
  }
  searchOrders = async (params, options) => {
    const { data, errors } = await this.service.searchOrders(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchQuotations = async (params, options) => {
    const { data, errors } = await this.service.searchQuotations(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchSubscriptions = async (params, options) => {
    const { data, errors } = await this.service.searchSubscriptions(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchCoTerm = async (params, options) => {
    const { data, errors } = await this.service.searchCoTerm(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchPayments = async (params, options) => {
    const { data, errors } = await this.service.searchPayments(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchCustomers = async (params, options) => {
    const { data, errors } = await this.service.searchCustomers(params, options)
    return { data: data ? data.content : [], errors }
  }
  searchProducts = async (params, options) => {
    const { data, errors } = await this.service.searchProducts(params, options)
    return { data: data ? data.content : [], errors }
  }
}

export default decorate(GlobalSearchRepository, {
  Tables: observable,
})
