import React from 'react'

import { BATCH_JOB_STATUS } from 'definitions'
import { Visible } from 'helpers/Auth'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import ToasterNotify from 'helpers/ToasterNotify'

class RetryButton extends React.PureComponent {
  state = {
    loading: false,
  }
  handleRetry = id => {
    ToasterNotify.confirm({
      message: `Are you sure you want to re-run this batch job?`,
      title: 'Warning',
      onConfirm: async () => {
        this.setState({ loading: true })
        this.props.setErrors([])
        const { errors = [] } = await this.props.deviceManagementJobRepository.retry(id)
        this.setState({ loading: false })
        this.props.setErrors(errors)
      },
    })
  }
  render() {
    const { id, status } = this.props
    const { loading } = this.state

    if (status !== BATCH_JOB_STATUS.SUCCESS) {
      return (
        <Visible toAppAdminRole>
          {loading && <Loader text=" " size="sm" />}
          {!loading && (
            <IconButton
              icon="md-repeat"
              title="Retry this batch job"
              onClick={event => {
                event.preventDefault()
                this.handleRetry(id)
              }}
            />
          )}
        </Visible>
      )
    }

    return false
  }
}

export default RetryButton
