import { Badge } from 'reactstrap'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import HtmlEditor from 'components/Common/Form/HtmlEditor'

class DescriptionPanel extends Component {
  render() {
    const { form, readOnly } = this.props

    return (
      <DescriptionPanelStyled title="Description">
        <Badge color="light" className="px-3 mb-2">
          Full Details
        </Badge>
        <HtmlEditor field={form.$('description')} readOnly={readOnly} />
        <Badge color="light" className="px-3 my-2">
          Excerpt
        </Badge>
        <HtmlEditor field={form.$('shortDescription')} readOnly={readOnly} maxLength={175} />
      </DescriptionPanelStyled>
    )
  }
}

const DescriptionPanelStyled = styled(Card)`
  .label-mail {
    width: 85px;
  }
`

export default observer(DescriptionPanel)
