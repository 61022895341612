/* @flow */
import APIRepository from 'stores/APIRepository'

export default class LoginServices {
  api: APIRepository
  constructor(api: APIRepository) {
    this.api = api
  }

  googleLogin(params: object = {}) {
    return this.api.post(`/authenticate-google`, params)
  }

  getGoogleDetailsByToken(token: string = '') {
    return fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`).then(response => response.json())
  }

  async logout() {
    const { errors } = await this.api.post('/logout')
    return { errors }
  }
}
