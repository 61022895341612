/* @flow */
import APIRepository, { jsonToUrlParams } from 'stores/APIRepository'

const fileHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export default class FileUploadServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/attachments'
    this.api = api
    this.url = url
  }

  uploadFile(attachments: *, attachmentUniqueId: *) {
    return this.api.post(
      `${this.url}/${attachmentUniqueId ? `?attachmentUniqueId=${attachmentUniqueId}` : ''}`,
      attachments,
      fileHeaders
    )
  }

  uploadModelFile(attachments: *, id: *, model = 'product', isHighResolution) {
    return this.api.post(
      `${this.url}/uploadModelFile?model=${model}&id=${id}${isHighResolution ? '&isHD=true' : ''}`,
      attachments,
      fileHeaders
    )
  }

  removeFile(attachmentUniqueId, filename, securedInd) {
    return this.api.del(
      `${this.url}/removeFile?attachmentUniqueId=${attachmentUniqueId}&filename=${filename}${
        securedInd ? `&securedInd=${securedInd}` : ''
      }`
    )
  }

  removeModelFile(id, filename, model = 'product', categoryImageSection) {
    return this.api.del(
      `${this.url}/removeModelFile?model=${model}&id=${id}&filename=${filename}${
        categoryImageSection ? `&categoryImageSection=${categoryImageSection}` : ''
      }`
    )
  }

  attachFiles(files, attachmentUniqueId, securedInd) {
    return this.api.post(
      `${this.url}?${jsonToUrlParams({
        ...(attachmentUniqueId && { attachmentUniqueId }),
        ...(securedInd && { securedInd }),
      })}`,
      files,
      fileHeaders
    )
  }

  getFile(fileName = null, id, attachmentId, model = 'order_payment') {
    return this.api.get(
      `${this.url}/download`,
      {
        model,
        id,
        attachmentId,
        fileName: fileName && fileName,
      },
      {
        responseType: 'blob',
      }
    )
  }
}
