import React from 'react'

import { formatDate, formatNumber } from 'helpers/Formatters'
import Checkbox from 'components/Common/Form/Checkbox'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

export default ({ readOnly, value: propsValue, label: propsLabel, className, children }) => {
  if (Array.isArray(children)) {
    throw new Error('FieldWrapper only accepts a single child element')
  }

  const { field } = children.props
  const label = propsLabel || field.label
  let value = propsValue || field.value

  if (readOnly) {
    if (value) {
      switch (field.type) {
        case 'number':
          value = propsValue || formatNumber(value)
          break
        case 'datetime':
          value = formatDate(value)
          break
        case 'array':
        case 'select':
          if (field.extra && field.extra.originalValue) {
            value = field.extra.originalValue
          }
          break
        default:
          break
      }
    }

    if (field.type === 'boolean') {
      return (
        <Checkbox
          field={field}
          id={`switchAttribute-${field.key}`}
          type="switch"
          className={className}
          plain
          disabled
          readOnly
        />
      )
    } else {
      return (
        <ReadOnlyField
          className={className}
          value={value && typeof value === 'object' ? value.label : value}
          label={label}
          showTooltip
          type={field.type === 'textarea' ? 'textarea' : 'input'}
        />
      )
    }
  }
  return children
}
