import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'

import { formatDate } from 'helpers/Formatters'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

import LogBookModal from './LogBookModal'

class LogBook extends Component {
  state = {
    reference: null,
    selectedLogId: null,
    isModalOpen: false,
  }
  toggleModal = selected => {
    this.setState({
      reference: selected.reference,
      selectedLogId: selected.id,
      isModalOpen: !this.state.isModalOpen,
    })
  }
  render() {
    const { logsRepository } = this.props
    const {
      Table: { data, loading, originalData, totalRecordCount },
      fetchTable,
    } = logsRepository

    return (
      <ContentContainer title="Log Book">
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'createdDate',
              Header: 'Date / Time',
              Cell: ({ original }) => formatDate(original.createdDate),
            },
            {
              accessor: 'entity',
              Header: 'Object Type',
            },
            {
              accessor: 'reference',
              Header: 'Reference',
            },
            {
              accessor: 'createdBy',
              Header: 'User',
            },
            {
              Header: 'Actions',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              width: 150,
              Cell: ({ original }) => <IconButton icon="md-search" onClick={() => this.toggleModal(original)} />,
              sortable: false,
            },
          ]}
          sort={{ id: 'createdDate', desc: true }}
          data={toJS(data)}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
        />
        <LogBookModal
          open={this.state.isModalOpen}
          onClose={this.toggleModal}
          logsRepository={logsRepository}
          reference={this.state.reference}
          logId={this.state.selectedLogId}
        />
      </ContentContainer>
    )
  }
}

export default inject('logsRepository')(observer(LogBook))
