/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class UserRoleAccessServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/userRoleAccess'
    super(api, url)
    this.api = api
    this.url = url
  }
}
