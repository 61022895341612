import React from 'react'
import styled from 'styled-components'

import accessDeniedPng from 'images/access_denied.png'

export default () => {
  return (
    <AccessDeniedStyled className="d-flex flex-column align-items-center h-100 w-100">
      <img className="access-denied" src={accessDeniedPng} alt="Peplink" />
      <h2 className="my-3">Access Denied !</h2>
      <div className="font-size-12 text-dark">You don't have access to this page</div>
    </AccessDeniedStyled>
  )
}

const AccessDeniedStyled = styled.div`
  .access-denied {
    margin-top: 120px;
    width: 94px;
    height: 76px;
  }
`
