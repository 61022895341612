import React, { Component } from 'react'

import { Alert, Button, Col, Collapse, Row } from 'reactstrap'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { getFormattedDateTimeString } from 'helpers/Formatters'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import Loader from 'components/Common/Loader'
import MobxForm from 'components/Common/Form'

class CommentSidePanel extends Component {
  constructor(props) {
    super(props)

    const { orderId, activeType } = this.props
    const fields = {
      orderId: {
        value: orderId,
      },
      type: {
        value: 'CUSTOMER',
      },
      comment: {
        value: null,
        rules: 'required',
      },
    }
    this.form = new MobxForm({ fields }, { name: 'OrderCommentsForm', handleSubmit: this.handleSubmit })
    this.state = {
      showAddComment: false,
    }

    this.handleSubmit = debounce(this.handleSubmit, 350)
    this.fetchComments(activeType)
  }
  showAddComment = showAddComment => {
    this.setState({ showAddComment })
  }
  fetchComments = async (type = '') => {
    const { orderCommentRepository, orderId } = this.props
    await orderCommentRepository.fetchTable({
      type,
      orderId,
      size: 30,
      sort: 'createdDate,desc',
    })
  }
  handleSubmit = event => {
    this.form.onSubmit(event, {
      onSuccess: form => {
        const { orderCommentRepository } = this.props
        const values = form.values()

        orderCommentRepository.create(values, async () => {
          this.form.$('comment').clear()
          await this.fetchComments()

          if (!orderCommentRepository.Table.errors.length) {
            const comments = orderCommentRepository.Table.data
            const { form: orderForm } = this.props

            if (comments && comments.length) {
              const customerComment = comments.find(item => item.type === 'CUSTOMER')
              customerComment &&
                !orderForm.$('customerComments').changed &&
                customerComment.comment &&
                orderForm.$('customerComments').set(customerComment.comment)
            }
          }
        })
      },
    })
  }
  handleKeyUp = event => {
    if (event.ctrlKey && event.keyCode === 13) {
      this.handleSubmit(event)
    }
  }
  render() {
    const { orderCommentRepository, handleCloseDrawer } = this.props
    const { showAddComment } = this.state
    const {
      CRUD: { submitting },
    } = orderCommentRepository
    const {
      Table: { data = [], loading },
    } = orderCommentRepository

    return (
      <CommentSidePanelStyled>
        <Row>
          <Col className="pr-0">
            <h5 className="title">Comments</h5>
          </Col>
          <Col className="ml-auto px-0">
            <Button color="success" size="xs" className="mr-1" onClick={() => this.showAddComment(!showAddComment)}>
              Add Comment
            </Button>
            <IconButton icon="md-close" onClick={handleCloseDrawer} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Collapse isOpen={showAddComment}>
              <div className="d-flex flex-column mt-3">
                <Input
                  field={this.form.$('comment')}
                  type="textarea"
                  inputClassName="border"
                  style={{ whiteSpace: 'pre-line' }}
                  disabled={submitting}
                  onKeyUp={this.handleKeyUp}
                />
                <Button
                  color="dark"
                  size="xs"
                  className="mt-2 ml-auto align-self-end"
                  disabled={submitting}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row className="mt-4 mb-3">
          <Col>
            <Button color="dark" size="xs" className="mr-2" onClick={() => this.fetchComments()}>
              All
            </Button>
            <Button color="secondary" size="xs" className="mr-2" onClick={() => this.fetchComments('INTERNAL')}>
              Internal
            </Button>
            <Button color="success" size="xs" className="mr-2" onClick={() => this.fetchComments('CUSTOMER')}>
              Customer
            </Button>
          </Col>
        </Row>
        <Row className="row-comments position-relative">
          {(loading || submitting) && <Loader className="loading p-3" text="Loading..." />}
          <Col>
            {data.map(row => {
              const bgColor = row.type === 'INTERNAL' ? 'bg-secondary' : 'bg-success'
              return (
                <div key={row.id} className="line">
                  <div className="d-inline-flex align-items-center">
                    <span className={`rounded-circle ${bgColor}`}></span>
                    <span className="ml-1 mr-2 font-weight-bold">{row.createdBy}</span>
                    <span>{getFormattedDateTimeString(row.createdDate)}</span>
                  </div>
                  <div>
                    <Alert color="dark" className="comment" style={{ whiteSpace: 'pre-line' }}>
                      {row.comment}
                    </Alert>
                  </div>
                </div>
              )
            })}
          </Col>
        </Row>
      </CommentSidePanelStyled>
    )
  }
}

const CommentSidePanelStyled = styled.div`
  .title {
    min-height: 26px;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5rem 0;
  }
  .line {
    padding: 0.5rem 0;
    font-size: 85%;
    .alert {
      padding: 0.5rem 1rem;
      margin: 0;
    }
    .rounded-circle {
      width: 16px;
      height: 16px;
      display: inline-block;
    }
  }
  .row-comments {
    min-height: 200px;
    .loading {
      display: flex;
      align-items: start;
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
    }
  }
`

export default inject('orderCommentRepository')(observer(CommentSidePanel))
