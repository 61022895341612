import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import ErrorsList from 'components/Common/ErrorsList'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable'

class CategoryImportProduct extends Component {
  table = null
  state = {
    selected: [],
  }
  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen) {
      this.setState({ selected: [] })
    }
  }
  handleSelected = ({ selection }) => {
    const selected = Object.keys(selection).map(i => i)
    this.setState({ selected })
  }
  handleSubmitImport = () => {
    this.props.handleSubmitImport(this.state.selected)
  }
  render() {
    const { selected } = this.state
    const {
      isOpen,
      onToggle,
      categoryRepository: { Products },
      productRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
    } = this.props
    return (
      <Modal
        open={isOpen}
        title={`Add Products to Category`}
        size="lg"
        confirmLabel={`Import ${selected ? selected.length : null} selected`}
        confirmIcon={`save`}
        loading={Products.loading || loading}
        loadingMessage={`Saving please wait...`}
        onConfirm={this.handleSubmitImport}
        onClose={onToggle}
      >
        {Products.errors.length ? <ErrorsList errors={Products.errors} /> : null}
        <ReactTable
          multiSelect
          selectElementId="categoryProductsModal"
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'productCode', Header: 'Product Code' },
            { accessor: 'name', Header: 'Name' },
            { accessor: 'description', Header: 'Description' },
          ]}
          sort={{ id: 'name' }}
          tableColumnExtensions={[]}
          data={data}
          loadData={params => fetchTable(params)}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          checkboxSelection={true}
          selectionChanged={this.handleSelected}
          ref={this.table}
          search
        />
      </Modal>
    )
  }
}

export default decorate(inject('categoryRepository', 'productRepository')(observer(CategoryImportProduct)), {})
