import React from 'react'

import Template from '../Template'

const Confirmation = props => {
  const {
    match: { params },
    history,
  } = props

  const invoiceNumber = params.invoiceNumber
  if (!invoiceNumber) {
    history.push('/')
  }
  return (
    <Template title="Thank you for Purchase">
      <p style={{ fontSize: 12 }}>
        Your payment is successful. The payment confirmation email has been sent to your email address. <br />
        Should you have any inquires concerning your order, please feel free to{' '}
        <a href="mailto:orders@peplink.com" className="font-weight-bold">
          contact us
        </a>
        .
      </p>

      <ul style={{ fontSize: 12 }}>
        <li>
          Got Questions? Check out our{' '}
          <a href="//www.peplink.com/knowledgebase/" className="font-weight-bold">
            Knowledge Base
          </a>{' '}
          for product information
        </li>
        <li>
          Join other Peplink customers and share your experience in our{' '}
          <a href="//forum.peplink.com/" className="font-weight-bold">
            Community Forum
          </a>
        </li>
        <li>
          The return policy of the products on this order are subject to{' '}
          <a href="//www.peplink.com/support/online-store-terms-conditions/" className="font-weight-bold">
            Terms & Conditions
          </a>
        </li>
      </ul>
    </Template>
  )
}

export default Confirmation
