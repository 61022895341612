import { Col /*CustomInput, Row*/ } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { formatDate, formatNumber } from 'helpers/Formatters'
import { VIRTUAL_PRODUCT_TYPE } from 'definitions'

const DATE_FORMAT = 'DD-MMM-YYYY'

const convertMbToGb = value => {
  if (value === undefined || value === null) return 'N/A'
  if (value) return formatNumber(value / 1024)
  return 0
}

const AutoRenewProduct = ({
  product,
  key,
  isSelected,
  onSelect,
  renewalDate,
  deviceManagementRepository,
  remainingData,
}) => {
  const { CRUD: { data: { subscriptionProducts: SPs } } = {} } = deviceManagementRepository
  const subscriptionProduct = SPs.filter(prod => prod.productCode === product.productCode)[0] || {}

  return (
    <StyledCol
      key={key}
      className={cx('mt-1 mb-1', product.overwrittenBySFCUnlimited && 'disabled')}
      isSelected={isSelected}
      {...(!product.overwrittenBySFCUnlimited
        ? {
            onClick: e => {
              if (e.target.tagName !== 'INPUT' || e.target.tagName !== 'LABEL')
                onSelect(e, {
                  ...product,
                  ...(subscriptionProduct &&
                    subscriptionProduct.referralCode && { referralCode: subscriptionProduct.referralCode }),
                })
            },
          }
        : {})}
    >
      <label>{product.productCode}</label>
      <p className="mb-2"> - {product.name}</p>
      {isSelected && <label className="d-block mb-0">Renewal Date: {formatDate(renewalDate, DATE_FORMAT)}</label>}
      {isSelected && subscriptionProduct.referralCode && (
        <label className="d-block mb-0">Referral Code: {subscriptionProduct.referralCode}</label>
      )}
      {[VIRTUAL_PRODUCT_TYPE.CLOUD, VIRTUAL_PRODUCT_TYPE.DATAPLANLICENSE].includes(product.actualProductType) && (
        <div>
          <label className="font-weight-normal mr-4">Quota: {product.quota || 0} GB</label>
          <label className="font-weight-normal">Remaining: {convertMbToGb(remainingData)} GB</label>
        </div>
      )}
    </StyledCol>
  )
}

const StyledCol = styled(Col)`
  position: relative;
  cursor: pointer;
  border: 1px solid #ccc;
  min-height: 110px;
  &:hover:not(.disabled) {
    background: rgba(0, 0, 0, 0.1);
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    border:1px solid #FFB820;
    &:after {
      content: "✓";
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 1em;
      color: #FFB820;
    }
  `}
  &.disabled {
    cursor: not-allowed;
  }
`

export default inject('deviceManagementRepository')(observer(AutoRenewProduct))
