/* @flow */
import { Collapse, Nav } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import styled from 'styled-components'

import { Visible } from 'helpers/Auth'

class MenuItem extends Component {
  constructor(props) {
    super(props)

    this.node = React.createRef()
    this.state = {
      isCollapsed: false,
    }
  }

  handleCollapse = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    })
  }
  setActiveMenu = () => {
    setTimeout(() => {
      if (this.node && this.node.querySelector('.active')) {
        this.node.querySelector('.sub-menu') && this.node.querySelector('.sub-menu').classList.add('show')
      }
    })
  }
  componentDidMount() {
    this.setActiveMenu()
    this.props.history.listen((location, action) => {
      this.setActiveMenu()
    })
  }

  render() {
    const { route = '', text, icon, routes = [] } = this.props
    const { isCollapsed } = this.state
    return (
      <MenuItemStyled ref={ref => (this.node = ref)}>
        {route ? (
          <NavLink className="nav-link" to={route} activeClassName="active">
            {icon != null && <img alt={text} src={require(`images/${icon}`).default} />}
            <div className="link">{text}</div>
          </NavLink>
        ) : (
          <div className="nav-link">
            {icon != null && <img alt={text} src={require(`images/${icon}`).default} />}
            <button className="link" onClick={this.handleCollapse}>
              {text}
            </button>
          </div>
        )}

        {!!routes.length && (
          <Collapse className="sub-menu" isOpen={isCollapsed}>
            <Nav vertical className="bg-light">
              {routes.map((item, index) => (
                <Visible to={item.roles} key={`${index}-${item.route}`}>
                  <li>
                    <NavLink className="nav-link link" to={item.route} activeClassName="active">
                      {item.text}
                    </NavLink>
                  </li>
                </Visible>
              ))}
            </Nav>
          </Collapse>
        )}
      </MenuItemStyled>
    )
  }
}

const MenuItemStyled = styled.li`
  .nav-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    padding: 0.4rem 0.8em 0.4rem 1rem;
    color: inherit;
    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
    &.active {
      background: rgba(0, 0, 0, 0.1);
    }
    .link {
      padding: 1px 6px;
      outline: none !important;
      border: none !important;
      background: none;
      width: 100%;
      text-align: left;
    }
  }
  .sub-menu {
    .nav-link {
      padding-left: 47px;
      color: inherit;
    }
  }
`

export default withRouter(MenuItem)
