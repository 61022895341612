import React, { Component } from 'react'

import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'

import { formatNumber } from 'helpers/Formatters'
import ReactTable from 'components/Common/ReactTable/index'

class OrderItemsTable extends Component {
  render() {
    let { columns, handleRowSelect, storeRepository, selected = null, store = {}, customer } = this.props
    const {
      StoreProducts: { data, loading, originalData, totalRecordCount },
      fetchProductsByStoreId,
    } = storeRepository
    columns = columns
      ? columns
      : [
          {
            Header: 'Name',
            accessor: 'product.name',
            Cell: ({ original }) => (
              <div className="ellipsis" title={original.product.name}>
                {original.product.name}
              </div>
            ),
          },
          {
            Header: 'Product Code',
            accessor: 'product.productCode',
            className: 'ellipsis',
            width: 150,
          },
          {
            Header: 'Price',
            accessor: 'price.amount',
            headerClassName: 'justify-content-end',
            className: 'justify-content-end',
            width: 100,
            Cell: ({ original }) =>
              original.price ? `${original.price.currency} ${formatNumber(original.price.amount)}` : '-',
          },
        ]
    return (
      <ReactTable
        className="striped cell-vertical-center"
        columns={columns}
        data={toJS(data)}
        handleRowClick={rowInfo => {
          rowInfo && handleRowSelect(rowInfo.original)
        }}
        getTrProps={(state, rowInfo, column) => {
          return {
            className:
              selected && rowInfo && rowInfo.original && selected.id === rowInfo.original.id
                ? 'tr-active'
                : 'cursor-pointer',
          }
        }}
        sort={{ id: 'product.name' }}
        loadData={params => fetchProductsByStoreId({ ...params, customerId: customer.id }, store && store.id)}
        loading={loading}
        search
        totalRecordCount={totalRecordCount}
        totalPages={originalData.totalPages}
      />
    )
  }
}

export default decorate(inject('storeRepository')(observer(OrderItemsTable)), {})
