import React, { Component } from 'react'

import { allowedToOrderRole, Visible } from 'helpers/Auth'
import { CustomInput } from 'reactstrap'
import { decorate, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { titleize } from 'helpers/String'
import Actions from './Actions'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class SerialModal extends Component {
  selection = []
  handleBulkToggleStatus = status => {
    const { orderItemSerialNumberRepository, orderDetails, updateOrderData } = this.props
    if (this.selection.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to change the status of ${this.selection.length} serial numbers?`,
        title: 'Warning',
        onConfirm: async () => {
          const payload = this.selection.map(({ serialNumber }) => ({
            serialNumber,
            status,
          }))
          orderItemSerialNumberRepository.bulkUpdateStatus(
            payload,
            res => {
              this.selection = []
              this.table.reload()
              updateOrderData(res)
            },
            orderDetails.id
          )
        },
      })
    } else {
      ToasterNotify.alert({ message: 'Please select shipping details to update', color: 'info' })
    }
  }
  handToggleStatus = item => {
    const { orderItemSerialNumberRepository, orderDetails, updateOrderData } = this.props

    if (item) {
      const payload = {
        ...item,
        status: item.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
      }

      orderItemSerialNumberRepository.updateStatus(
        payload,
        data => {
          this.table.reload()
          updateOrderData(data)
        },
        orderDetails.id
      )
    }
  }

  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => selection[key]) : []
  }

  render() {
    const {
      open,
      onClose,
      orderItemSerialNumberRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      orderDetails,
      selectedOrderItem,
    } = this.props
    const mappedSerials = data.map((i, key) => {
      return { ...i, id: key }
    })

    return (
      <Modal
        title={`${selectedOrderItem.product.product.productCode}`}
        loading={false}
        open={open}
        onClose={onClose}
        hideConfirm
        hideCancel
      >
        <FormContainer
          onSubmit={e => {
            e.stopPropagation()
            this.form.onSubmit(e, { onSuccess: this.handleSubmit })
          }}
          //   errors={errors}
          plain
        >
          <ReactTable
            multiSelect
            className="striped cell-vertical-center"
            actions={
              <Visible toOrderProcessRole>
                <Actions handToggleStatus={this.handleBulkToggleStatus} disabled={!this.selection.length} />
              </Visible>
            }
            columns={[
              {
                Header: 'Serial Number',
                accessor: 'serialNumber',
              },
              {
                Header: 'Balance of Instalment',
                accessor: 'balanceOfInstallment',
              },
              {
                accessor: 'status',
                Header: 'Status',
                width: 100,
                Cell: ({ original }) => (
                  <CustomInput
                    type="switch"
                    label={titleize(original.status)}
                    id={`switchStatus${original.id}`}
                    checked={original.status === 'ACTIVE'}
                    onChange={() => this.handToggleStatus(original)}
                    disabled={!allowedToOrderRole()}
                  />
                ),
              },
            ]}
            search
            data={mappedSerials}
            loadData={params => fetchTable({ ...params, ...this.filters }, orderDetails.id)}
            selectionChanged={this.handleSelectionChanged}
            loading={loading}
            totalRecordCount={totalRecordCount}
            totalPages={originalData.totalPages}
            ref={ref => (this.table = ref)}
          />
        </FormContainer>
      </Modal>
    )
  }
}

export default inject('orderItemSerialNumberRepository')(
  decorate(observer(SerialModal), {
    selection: observable,
  })
)
