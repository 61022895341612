import React from 'react'
import styled from 'styled-components'

import Modal from 'components/Common/Modal'

class ImportErrorDetailModal extends React.Component {
  render() {
    const { show, fileTypeLabel, errors = [], onSubmit } = this.props

    return (
      <Modal
        title={
          <span>
            <span className="text-primary">{fileTypeLabel}</span> Error Details
          </span>
        }
        open={show}
        size="xl"
        centered
        hideCancel
        confirmLabel="OK"
        onConfirm={onSubmit}
      >
        <TableStyled className="table table-striped table-sm">
          <thead>
            <tr>
              <th className="border-bottom border-primary" width="150px">
                Line Number
              </th>
              <th className="border-bottom border-primary">Reference</th>
              <th className="border-bottom border-primary">Reason</th>
            </tr>
          </thead>
          <tbody>
            {errors.map(item => (
              <tr key={item.lineNumber}>
                <td>{item.lineNumber}</td>
                <td>{item.reference}</td>
                <td>{item.reason}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Modal>
    )
  }
}

const TableStyled = styled.table`
  &.table {
    font-size: 0.875rem;
    line-height: 20px;
    th {
      font-weight: 600;
    }
  }
`

export default ImportErrorDetailModal
