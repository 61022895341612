import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import Input from 'components/Common/Form/Input'

class Zone extends Component {
  constructor(props) {
    super(props)

    const { zonesField, zonePriceAmount = {}, zone, promptOnExit } = props
    const hooks = { onChange: () => promptOnExit(true) }

    const itemArrayField = zonesField.add()
    itemArrayField.add({
      key: 'zoneId',
      value: zone.id,
    })
    this.field = itemArrayField.add({
      key: 'amount',
      type: 'number',
      value: zonePriceAmount.amount,
      hooks,
    })
  }
  render() {
    const { currencyIndex, zone } = this.props
    const label = currencyIndex === 0 ? zone.label : ''

    return <ZoneStyled field={this.field} label={label} className="text-right" />
  }
}

const ZoneStyled = styled(Input)`
  &.form-group label {
    text-align: right;
  }
`

export default observer(Zone)
