/* @flow */
import downloadjs from 'downloadjs'
import React, { Component } from 'react'

import { ACTIVE_STATUS, INACTIVE_STATUS, USERS_PATH } from 'definitions'
import { allowedTo, Visible } from 'helpers/Auth'
import { CustomInput } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { formatDate } from 'helpers/Formatters'
import { inject, observer } from 'mobx-react'
import { titleize } from 'helpers/String'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import ReactTable from 'components/Common/ReactTable'

class Users extends Component {
  filters = {}
  loadData = async params => {
    this.filters = { ...params }
    return await this.props.userRepository.fetchTable(params)
  }
  downloadUsers = async downloadAll => {
    const { search } = this.filters
    const payload = {}

    if (!downloadAll) {
      payload.search = search
    }

    await this.props.userRepository.export(payload, result => {
      const dateStamp = formatDate(new Date(), 'YYYYMMDDHHmm')
      downloadjs(
        result,
        `SA users_${dateStamp}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    })
  }
  handleCreate = () => {
    this.props.history.push('/users/new')
  }
  handToggleStatus = item => {
    const { userRepository } = this.props

    if (item) {
      const payload = {
        ...item,
        status: item.status === ACTIVE_STATUS ? INACTIVE_STATUS : ACTIVE_STATUS,
      }
      userRepository.patch(payload, data => {
        userRepository.Table.data.map(i => {
          if (i.id === data.id) {
            i.status = data.status
          }
          return i
        })
      })
    }
  }
  render() {
    const { searchRepository, userRepository } = this.props
    const {
      Table: { data, loading, totalRecordCount, originalData, errors = [] },
      Export: { loading: exportLoading, errors: exportErrors = [] },
    } = userRepository
    return (
      <ContentContainer
        title="Users"
        errors={[...errors, ...exportErrors]}
        actions={
          <ContextMenu
            className="mx-1"
            dropDownToggle={
              exportLoading ? (
                <Loader text="" title="Downloading users..." className="m-0 p-0" />
              ) : (
                <IconButton icon="md-download" title="Download users" />
              )
            }
            menus={[
              {
                text: 'Download all users',
                disabled: exportLoading,
                onClick: () => this.downloadUsers(true),
              },
              {
                text: 'Download filtered users',
                disabled: exportLoading,
                onClick: () => this.downloadUsers(false),
              },
            ]}
            plain
          />
        }
      >
        <ReactTable
          searchRepository={searchRepository}
          pageKey="users"
          enableRowLink
          getRowUrl={({ original }) => `${USERS_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          columns={[
            { accessor: 'fullName', Header: 'Full Name' },
            { accessor: 'username', Header: 'Email' },
            { accessor: 'roleDescription', Header: 'Role', allowSorting: false },
            {
              accessor: 'status',
              Header: 'Status',
              width: 150,
              Cell: ({ original }) => (
                <CustomInput
                  type="switch"
                  label={titleize(original.status)}
                  id={`switchStatus${original.id}`}
                  className="mr-2"
                  defaultChecked={original.status === 'ACTIVE'}
                  onClick={event => event.preventDefault()}
                  {...(allowedTo() ? { onChange: () => this.handToggleStatus(original) } : {})}
                  disabled={!allowedTo()}
                />
              ),
            },
          ]}
          actions={
            <Visible>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Create New User" />
              </div>
            </Visible>
          }
          tableColumnExtensions={[]}
          sort={{ id: 'fullName' }}
          data={toJS(data)}
          loadData={this.loadData}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('userRepository')(observer(Users)), {})
