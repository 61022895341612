import React from 'react'

import { observer } from 'mobx-react'

import { PRODUCT_NATURE_PHYSICAL, PRODUCT_TYPE_ACCESSORY } from 'definitions'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

class ProductInfo extends React.Component {
  handleProductCodeOnBlur = event => {
    const value = event.target.value
    if (value) {
      event.target.value = value.replace(/\s/g, '').toUpperCase()
      this.props.form.$('productCode').set(event.target.value)
    }
  }

  render() {
    const { form, commonRepository, onChangeProductType } = this.props

    const productNature = form.$('productNature').value ? form.$('productNature').value.value : PRODUCT_NATURE_PHYSICAL
    const productType = form.$('productType').value ? form.$('productType').value.value : null
    const isShowAddAsSingleProduct = productNature === PRODUCT_NATURE_PHYSICAL && productType !== PRODUCT_TYPE_ACCESSORY

    return (
      <Card title="Product Information" style={{ minHeight: '440px' }}>
        <Input field={form.$('name')} />
        <Input field={form.$('productCode')} onBlur={this.handleProductCodeOnBlur} />
        <div className="grouped">
          <ReactSelect
            field={form.$('productNature')}
            serverSide
            search={params => commonRepository.reactSelectSearch(params, 'productNature')}
            options={{
              valueKey: 'value',
              labelKey: 'label',
              defaultOptions: true,
            }}
          />
          <ReactSelect
            field={form.$('productType')}
            serverSide
            search={params => commonRepository.reactSelectSearch(params, 'productTypes')}
            options={{
              valueKey: 'value',
              labelKey: 'label',
              defaultOptions: true,
            }}
            onChange={onChangeProductType}
          />
        </div>
        <ReactSelect
          field={form.$('productSeries')}
          serverSide
          search={params => commonRepository.reactSelectSearch(params, 'productSeries')}
          options={{
            valueKey: 'value',
            labelKey: 'label',
            defaultOptions: true,
          }}
        />
        {isShowAddAsSingleProduct && <Checkbox field={form.$('addAsSingleProductInd')} className="mt-5" />}
      </Card>
    )
  }
}

export default observer(ProductInfo)
