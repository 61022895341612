export const getDuplicates = (items, key1, key2) => {
  if (items.length) {
    const duplicates = items.reduce((list, item) => {
      if (key1 && key2) {
        const indexOf = items.findIndex(current => current[key1] === item[key1] && current[key2] === item[key2])
        const lastIndexOf = items.findLastIndex(current => current[key1] === item[key1] && current[key2] === item[key2])
        if (indexOf !== lastIndexOf) {
          list.push(item)
        }
      } else if (key1) {
        const indexOf = items.findIndex(current => current[key1] === item[key1])
        const lastIndexOf = items.findLastIndex(current => current[key1] === item[key1])
        if (indexOf !== lastIndexOf) {
          list.push(item)
        }
      } else {
        if (items.indexOf(item) !== items.lastIndexOf(item)) {
          list.push(item)
        }
      }
      return list
    }, [])
    return unique(duplicates, key1, key2)
  }
  return []
}

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item].slice(0),
    }),
    {}
  )

export const removeEmptyItems = (items = []) => items.filter(item => !!item)

export const sort = (items = [], key) =>
  items.slice().sort((item1, item2) => (item1[key] ? item1[key].localeCompare(item2[key]) : 0))

export const sortByNumber = (items = [], key) =>
  items.slice().sort((item1, item2) => (item1.hasOwnProperty(key) ? item1[key] - item2[key] : 0))

export const selectMany = (items = [], key) =>
  items
    ? items
        .reduce((list, item) => {
          if (item[key]) {
            return list.concat(item[key])
          }
          return list
        }, [])
        .filter(item => !!item)
    : []

export const unique = (items, key1, key2) => {
  return items.filter((item, index, self) => {
    if (key1 && key2) {
      return self.findIndex(item2 => item2[key1] === item[key1] && item2[key2] === item[key2]) === index
    } else if (key1) {
      return self.findIndex(item2 => item2[key1] === item[key1]) === index
    } else {
      return self.indexOf(item) === index
    }
  })
}
