import { Badge } from 'reactstrap'
import { ORDER_STATUS } from 'definitions'
import classNames from 'classnames'
import React from 'react'

const defaultOrderStatus = label => ({ label, color: 'outline-primary' })

const Status = ({ status, isTable = true, className }) => {
  let orderStatus
  if (status) {
    orderStatus = ORDER_STATUS[status] || defaultOrderStatus(status)
  } else {
    orderStatus = defaultOrderStatus()
  }

  return isTable ? (
    <Badge
      color={orderStatus.color !== 'danger' ? orderStatus.color2 || orderStatus.color : ''}
      className={classNames(
        'font-size-11 w-100 d-inline-flex justify-content-center align-items-center',
        orderStatus.color === 'danger' && 'text-danger',
        className
      )}
    >
      {orderStatus.color === 'danger' && <i className="icon ion-md-alert mr-2" />}
      <span className={'text-uppercase'}>{orderStatus.label}</span>
    </Badge>
  ) : (
    <Badge color={ORDER_STATUS[status].color} className="py-2 text-uppercase" style={{ minWidth: '140px' }}>
      {ORDER_STATUS[status].label}
    </Badge>
  )
}

export default Status
