import { Badge } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'

import AutoRenewalTable from 'components/AutoRenewal/AutoRenewalTable'
import BatchJobs from 'components/AutoRenewal/BatchJob'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import ExportModal from './ExportModal'
import IconButton from 'components/Common/Buttons/IconButton'

class AutoRenewal extends React.PureComponent {
  state = {
    view: this.props.view || 'renewal',
    isExportModalOpen: false,
  }
  componentDidMount() {
    this.props.commonRepository.getVirtualProductTypes()
  }
  handleChangeView = view => {
    this.setState({ view })
  }
  render() {
    const {
      history,
      commonRepository: {
        VirtualProductTypes: { data },
      },
    } = this.props
    const { view } = this.state
    const isAutoRenewal = view === 'renewal'

    return (
      <ContentContainer
        title={isAutoRenewal ? 'Auto Renewal' : 'Batch Jobs'}
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: isAutoRenewal ? 'Auto Renewal' : 'Batch Jobs',
          },
        ]}
        actions={
          <React.Fragment>
            <Badge color="dark" className="text-uppercase p-2">
              {isAutoRenewal ? 'Auto Renewal' : 'Batch Jobs'}
            </Badge>
            <ContextMenu
              className="mx-1"
              dropDownToggle={<IconButton icon="md-apps" />}
              menus={[
                {
                  text: 'Auto Renewal',
                  onClick: () => {
                    this.handleChangeView('renewal')
                  },
                },
                {
                  text: 'Batch Jobs',
                  onClick: () => {
                    this.handleChangeView('job')
                  },
                },
              ]}
              plain
            />
            {this.state.view === 'renewal' && (
              <IconButton
                icon="md-download"
                title="Export products"
                onClick={() => this.setState({ isExportModalOpen: true })}
              />
            )}
          </React.Fragment>
        }
      >
        {isAutoRenewal && <AutoRenewalTable history={history} />}
        {!isAutoRenewal && <BatchJobs history={history} />}
        {this.state.view === 'renewal' && this.state.isExportModalOpen && (
          <ExportModal
            productTypes={data}
            open={this.state.isExportModalOpen}
            onClose={() => this.setState({ isExportModalOpen: false })}
          />
        )}
      </ContentContainer>
    )
  }
}

export default decorate(inject('deviceManagementRepository', 'commonRepository')(observer(AutoRenewal)), {})
