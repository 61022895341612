/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import CollectionItemForm from './CollectionItemForm'
import ContentContainer from 'components/Common/ContentContainer'
import Loader from 'components/Common/Loader'
import React, { Component } from 'react'

class CollectionItemEdit extends Component {
  componentDidMount() {
    const {
      match: {
        params: { collectionName, id },
      },
      collectionItemRepository: { getById },
    } = this.props

    getById({ collectionName, id })
  }

  componentWillUnmount() {
    this.props.collectionItemRepository.clearCrudData()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      match: {
        params: { collectionName },
      },
      collectionItemRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer title={`Edit ${collectionName}`} breadcrumbLabel={data ? data.label : ''}>
          <CollectionItemForm {...this.props} data={data} isEdit />
        </ContentContainer>
      </React.Fragment>
    )
  }
}

export default decorate(inject('collectionItemRepository')(observer(CollectionItemEdit)), {})
