/* @flow */
import React, { Component } from 'react'

import { allowedTo, allowedToAppAdminRole } from 'helpers/Auth'
import { Col, CustomInput, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { ACTIVE_STATUS, INACTIVE_STATUS, ROLE_ADMIN, ROLE_APP_ADMIN, ROLE_ORDER_PROCESS, ROLE_USER } from 'definitions'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import InputText from 'components/Common/Form/Input'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'
import ToasterNotify from 'helpers/ToasterNotify'

class UserForm extends Component {
  constructor(props) {
    super(props)
    const { data: { username = '', firstName = '', lastName = '', role = ROLE_ADMIN, status } = {} } = props
    const fields = {
      username: {
        type: 'email',
        label: 'Email',
        rules: 'required|email',
        value: username,
      },
      firstName: {
        type: 'text',
        label: 'First Name',
        rules: 'required',
        value: firstName,
      },
      lastName: {
        type: 'text',
        label: 'Last Name',
        rules: 'required',
        value: lastName,
      },
      role: {
        type: 'select',
        label: 'Role',
        rules: 'required',
        value: role,
      },
      status: {
        type: 'checkbox',
        label: 'Active',
        value: status === ACTIVE_STATUS,
      },
    }
    this.form = new Form({ fields }, { name: 'UserForm', handleSubmit: this.handleSubmit })
  }
  componentWillUnmount() {
    this.props.userRepository.clearErrorMessages()
    this.props.userRepository.clearCrudData()
  }
  handleCancel = () => {
    this.props.history.push('/users')
  }
  handleSubmit = form => {
    const values = form.values()
    const {
      userRepository: {
        CRUD: { data },
      },
      isMyProfile,
    } = this.props

    const isAllowAdminRole = allowedTo()
    const isAlloweAppAdminRole = allowedToAppAdminRole() && data.role !== ROLE_ADMIN

    const fullName = `${values.firstName} ${values.lastName}`
    if (data.id) {
      const payload = {
        ...values,
        status: values.status ? ACTIVE_STATUS : INACTIVE_STATUS,
        id: data.id,
        role: values.role,
        fullName,
      }
      if (!isAllowAdminRole) {
        delete payload.status
      }
      if (!isAllowAdminRole && !isAlloweAppAdminRole) {
        delete payload.role
      }

      this.props.userRepository.patch(payload, () => {
        isMyProfile && this.props.userRepository.getCommon(`Session`, data.id)

        ToasterNotify.alert({
          message: 'User changes have been saved successfully',
          color: 'success',
        })

        !isMyProfile && this.handleCancel()
      })
    } else {
      this.props.userRepository.create(
        {
          ...values,
          status: values.status ? ACTIVE_STATUS : INACTIVE_STATUS,
          role: values.role,
          fullName,
        },
        () => {
          ToasterNotify.alert({
            message: 'New user was created successfully',
            color: 'success',
          })

          !isMyProfile && this.handleCancel()
        }
      )
    }
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  getRoleLabel = role => {
    switch (role) {
      case ROLE_ADMIN:
        return 'System Admin'
      case ROLE_APP_ADMIN:
        return 'Admin Access'
      case ROLE_ORDER_PROCESS:
        return 'Order Process'
      default:
        return 'User - Read Only'
    }
  }
  render() {
    const {
      userRepository: { CRUD: { errors = [], loading, loadingFailed } = {} },
      data,
      isMyProfile,
    } = this.props
    const isAllowAdminRole = allowedTo()
    const isAlloweAppAdminRole = allowedToAppAdminRole() && data?.role !== ROLE_ADMIN

    return (
      <Row>
        <Col lg="6">
          <FormContainer
            title={isMyProfile ? 'My Profile' : 'User Details'}
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={loadingFailed && errors}
            actions={
              <FormActions
                variant="contained"
                loading={loading}
                loadingMessage={`Saving please wait...`}
                confirmLabel={`Save`}
                onCancel={this.handleCancel}
                hideConfirm={!isMyProfile && !isAllowAdminRole && !isAlloweAppAdminRole}
                hideCancel={isMyProfile}
              />
            }
          >
            <InputText
              field={this.form.$('username')}
              disabled={!isMyProfile && !isAllowAdminRole}
              endAdornment={
                isAllowAdminRole ? (
                  <CustomInput
                    type="switch"
                    label={this.form.$('status').value ? 'Active' : 'Suspended'}
                    id={`switchStatus${this.form.$('status').id}`}
                    className="mr-2 mt-1"
                    checked={this.form.$('status').value}
                    onChange={e => this.form.$('status').set(e.target.checked)}
                  />
                ) : null
              }
            />
            <InputText field={this.form.$('firstName')} disabled={!isMyProfile && !isAllowAdminRole} />
            <InputText field={this.form.$('lastName')} disabled={!isMyProfile && !isAllowAdminRole} />
            {isAllowAdminRole || isAlloweAppAdminRole ? (
              <RadioGroup
                field={this.form.$('role')}
                defaultValue={this.form.$('role').value}
                radios={[
                  ...(isAllowAdminRole
                    ? [
                        {
                          label: this.getRoleLabel(ROLE_ADMIN),
                          value: ROLE_ADMIN,
                        },
                      ]
                    : []),
                  {
                    label: this.getRoleLabel(ROLE_APP_ADMIN),
                    value: ROLE_APP_ADMIN,
                  },
                  {
                    label: this.getRoleLabel(ROLE_ORDER_PROCESS),
                    value: ROLE_ORDER_PROCESS,
                  },
                  {
                    label: this.getRoleLabel(ROLE_USER),
                    value: ROLE_USER,
                  },
                ]}
                inline
              />
            ) : (
              <ReadOnlyField
                disabled
                value={this.getRoleLabel(this.form.$('role').value)}
                label={this.form.$('role').label}
              />
            )}
          </FormContainer>
        </Col>
      </Row>
    )
  }
}

export default decorate(inject('userRepository')(observer(UserForm)), {})
