import { ReactTableDefaults } from 'react-table'
import React from 'react'

export default class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true })
    // api.sendReport(error, info)
  }

  render() {
    if (this.state.hasError) {
      // eslint-disable-next-line no-unused-vars
      const { children, ...rest } = this.props
      return <ReactTableDefaults.TdComponent {...rest} />
    }
    return <ReactTableDefaults.TdComponent {...this.props} />
  }
}
