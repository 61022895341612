import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Form from 'components/Common/Form'
import InputGroup from 'components/Common/Form/InputGroup'

class CouponContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      action: '',
    }

    const order = props.orderRepository.CRUD.data
    const fields = {
      coupon: {
        placeholder: 'Enter Coupon',
        value: order.hasOwnProperty('appliedCoupon') ? order.appliedCoupon.code : '',
      },
    }
    this.form = new Form(
      {
        fields,
      },
      { name: 'CouponContainerForm', handleSubmit: this.handleSubmit }
    )
  }
  componentDidUpdate() {
    const errors = this.props.orderRepository.Coupon.errors
    if (errors.length) {
      this.form.$('coupon').invalidate(errors[0].message)
    }
  }
  applyCoupon = () => {
    this.setState(
      {
        action: 'add',
      },
      () => {
        const order = this.props.orderRepository.CRUD.data
        const orderId = order.id
        const coupon = this.form.$('coupon')
        const payload = {
          codes: [coupon.value],
          order: {
            ...order,
            customer: {
              id: this.props.customer.id,
              name: this.props.customer.name,
            },
          },
        }
        this.props.orderRepository.applyCoupon(orderId, payload, res => {
          this.props.updateOrderData(res)
        })
      }
    )
  }
  removeCoupon = () => {
    this.setState(
      {
        action: 'remove',
      },
      () => {
        const order = this.props.orderRepository.CRUD.data
        const orderId = order.id
        const payload = {
          order,
        }
        this.props.orderRepository.removeCoupon(orderId, payload, res => {
          if (!res.error) {
            this.props.updateOrderData(res)
            this.form.$('coupon').set('')
          } else {
            this.form.$('coupon').invalidate(res.error[0].message)
          }
        })
      }
    )
  }

  render() {
    const { Coupon, CRUD } = this.props.orderRepository

    return (
      <InputGroupStyled
        field={this.form.$('coupon')}
        buttonText={'APPLY'}
        onBtnClick={this.applyCoupon}
        {...(CRUD.data.hasOwnProperty('appliedCoupon') && { onInputClear: this.removeCoupon })}
        placeholder="Enter Coupon"
        disabled={Coupon.loading || this.props.readOnly}
      />
    )
  }
}

const InputGroupStyled = styled(InputGroup)`
  .input-group {
    input.form-control {
      border: none;
      background: rgba(242, 242, 242, 1);
      border-radius: 25px !important;
      &:focus {
        outline: none !important;
      }
      &:disabled {
        color: rgba(242, 242, 242, 0.8);
        background: rgba(0, 0, 0, 0.5);
        &::placeholder {
          color: rgba(242, 242, 242, 0.8);
        }
      }
    }
    .input-group-prepend {
      right: 1px !important;
      button {
        &:not(.clear) {
          height: 35px !important;
          border-radius: 25px !important;
        }
        &:disabled {
          color: rgba(242, 242, 242, 0.8) !important;
        }
      }
    }
  }
`

export default inject('orderRepository', 'orderItemRepository')(observer(CouponContainer))
