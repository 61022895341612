import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import selectTableHOC from 'react-table/lib/hoc/selectTable'

export default TableComponent => {
  const ReactTable = selectTableHOC(TableComponent)
  const state = {
    selection: {},
    selectAll: false,
    selectedPages: {},
  }
  return class MultiSelect extends Component {
    state = state

    isSelected = key => {
      return !!this.state.selection[key]
    }

    getPages = () => {
      const { data, pageSize } = this.props
      return Math.ceil(data.length / pageSize)
    }

    getAllRows = page => {
      const { manual, pageSize } = this.props
      const wrappedInstance = this.table.getWrappedInstance()
      let rows
      if (manual) {
        rows = wrappedInstance.getResolvedState().sortedData
      } else {
        const start = page * pageSize
        const end = start + pageSize
        rows = wrappedInstance.getResolvedState().sortedData.slice(start, end)
      }
      return rows
    }

    selectionChanged = () => {
      const { selectionChanged } = this.props
      const { selection, selectAll } = this.state
      selectionChanged && selectionChanged({ selection, selectAll })
    }

    toggleAll = selectAllArg => {
      const { selectKeyField = 'id', selectDisabledKeyField, manual, page } = this.props
      let selection = {}
      let selectAll = selectAllArg
      const selectedPages = { ...this.state.selectedPages }

      if (selectAll) {
        const pages = this.getPages()

        if (!manual) {
          selection = { ...this.state.selection }
          selectedPages[page] = true
          selectAll = Object.values(selectedPages).filter(item => item).length === pages
        }

        const rows = this.getAllRows(page)
        rows.forEach(item => {
          if (selectDisabledKeyField && item._original[selectDisabledKeyField]) {
            return
          } else {
            selection[item._original[selectKeyField]] = item._original
          }
        })
      } else {
        if (!manual) {
          selection = { ...this.state.selection }
          selectedPages[page] = false

          const items = this.getAllRows(page)
          items.forEach(item => {
            delete selection[item._original[selectKeyField]]
          })
        }
      }
      this.setState(
        {
          selection,
          selectAll,
          selectedPages: { ...selectedPages },
        },
        this.selectionChanged
      )
    }

    toggleSelection = (key, row) => {
      const { selection } = this.state
      if (selection.hasOwnProperty(key)) {
        delete selection[key]
      } else {
        selection[key] = row
      }
      this.setState({ selection }, this.selectionChanged)
    }

    setSelection = selection => {
      const { selectKeyField, selectDisabledKeyField, manual } = this.props
      let selectAll
      const selectedPages = {}

      const getRows = page => {
        const allRows = this.getAllRows(page)
        if (selectDisabledKeyField) {
          const newRows = allRows.filter(item => !item._original[selectDisabledKeyField])
          return newRows
        }
        return allRows
      }

      if (manual) {
        const rows = getRows()
        selectAll = Object.keys(selection).length === rows.length
      } else {
        const pages = this.getPages()
        for (let page = 0; page < pages; page++) {
          const pageRows = getRows(page)
          selectedPages[page] = pageRows.every(item => {
            const id = item._original[selectKeyField]
            return !!selection[id]
          })
        }
        selectAll = Object.values(selectedPages).filter(item => item).length === pages
      }

      this.setState({ selection, selectAll, selectedPages })
    }

    render() {
      const {
        selectKeyField = 'id',
        selectElementId = '',
        data = [],
        selectDisabledKeyField = '',
        manual,
        page,
        ...rest
      } = this.props

      const selectAll = manual ? this.state.selectAll : !!this.state.selectedPages[page]

      return (
        <ReactTable
          {...rest}
          data={data}
          ref={ref => (this.table = ref)}
          manual={manual}
          page={page}
          keyField={selectKeyField}
          isSelected={this.isSelected}
          selectAll={selectAll}
          toggleAll={() => {}}
          toggleSelection={() => {}}
          selectType="checkbox"
          SelectAllInputComponent={({ checked }) =>
            data.length > 0 && (
              <div style={{ marginTop: '0.37rem' }}>
                <CustomInput
                  type="checkbox"
                  id={`tableCheckboxSelectAll${selectElementId}`}
                  className="no-label"
                  defaultChecked={checked}
                  onClick={event => this.toggleAll(event.target.checked)}
                />
              </div>
            )
          }
          SelectInputComponent={({ checked, row }) => {
            return (
              <CustomInput
                type="checkbox"
                id={`tableCheckboxSelectRow${selectElementId}${row[selectKeyField]}`}
                className="no-label"
                defaultChecked={row[selectDisabledKeyField] ? false : checked}
                onClick={event => {
                  event.preventDefault()
                  this.toggleSelection(row[selectKeyField], row)
                }}
                disabled={row[selectDisabledKeyField] || false}
              />
            )
          }}
        />
      )
    }
  }
}
