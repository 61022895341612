import React, { Component } from 'react'

/** Plugins */
import { FormGroup, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

/** Common Components */
import { ColStyled, handleCheckSelectedCustomer } from 'components/Orders/OrderForm/CustomerPanel'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { SANCTION_COUNTRY_TYPE, SANCTION_ENTITY_TYPE } from 'definitions'
import Card from 'components/Common/Card'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class CustomerPanel extends Component {
  state = {
    isSanctioned: false,
    isWhitelisted: false,
    showMatches: false,
    defaultStore: null,
  }

  componentWillMount() {}

  componentDidMount() {
    const { customerId } = queryString.parse(window.location.search)
    if (customerId) {
      this.props.customerRepository.getById(customerId, data => {
        this.props.form.$('customer').set(data)
      })
    }

    const { data } = this.props

    this.handleSelectedCustomer(data.customer, false)
  }

  handleNoOfInstallments = e => {
    const { form } = this.props
    form.$('orderSubscription.noOfInstallment').set(e.target.value)
  }

  handleSelectedCustomer = async (selected, loadDefaultStore = true) => {
    // const sanctioned = selected && selected.sanctioned
    // const whitelisted = selected && selected.whitelisted

    handleCheckSelectedCustomer(selected)

    if (selected && loadDefaultStore) {
      const { customerRepository, form } = this.props
      const { DefaultStore } = customerRepository
      await customerRepository.getDefaultStore(selected.id)
      const store = form.$('store').value
      if (DefaultStore.data && (!store || DefaultStore.data.id !== form.$('store').value.id)) {
        form.$('store').set(DefaultStore.data)
      } else {
        form.$('store').set(null)
      }
    }
  }

  toggleCustomerMatches = () => {
    this.setState({ showMatches: !this.state.showMatches })
  }

  getSanctionList = () => {
    const {
      sanctionCustomerRepository: {
        CRUD: { data: { content = [] } = {} },
      },
    } = this.props

    if (content.length) {
      return content.map(item => {
        switch (item.type) {
          case SANCTION_ENTITY_TYPE:
            return { message: item.companyName }
          case SANCTION_COUNTRY_TYPE:
            return { message: item.country }
          default:
            return { message: `${item.firstName} ${item.lastName}` }
        }
      })
    }

    return [{ message: 'No match found' }]
  }

  render() {
    const { form, step, data, isLoan } = this.props
    const customer = form.$('customer').value
    const { createdDate, createdBy } = data
    const groupName = step === 2 && form.$('groups').value ? form.$('groups').value.map(group => group.name)[0] : ''
    const store = form.$('store').value

    const blacklisted = customer && customer.blacklisted
    const sanctioned = customer && customer.sanctioned

    const customerNameAndPartnerId =
      customer && customer.partnerId && customer.partnerId !== '0'
        ? `${customer.name} (${customer.partnerId})`
        : customer.name

    return (
      <Card className="customer-panel">
        <Row>
          <ColStyled>
            {/* <ReactSelect
              field={form.$('customer')}
              customLabelKey={['name', 'id']}
              serverSide
              search={payload => reactSelectSearch({ ...payload, status: 'ACTIVE' })}
              options={{
                valueKey: 'id',
                labelKey: 'name',
                options: customer ? [customer] : [],
                defaultOptions: true,
                isClearable: false,
              }}
              onChange={this.handleSelectedCustomer}
              className={blacklisted || sanctioned ? 'has-error' : ''}
              readOnly={true}
              showTooltip
            /> */}
            <ReadOnlyField
              value={customerNameAndPartnerId}
              label="Customer"
              showTooltip
              className={blacklisted || sanctioned ? 'has-error' : ''}
            />
          </ColStyled>
          <ColStyled>
            <ReadOnlyField value={groupName} label="Customer Group" showTooltip />
          </ColStyled>
          <ColStyled>
            <ReadOnlyField value={store && store.name} label="Store" showTooltip />
          </ColStyled>
          <ColStyled width={5}>
            <ReadOnlyField value={store && store.currency} label="Currency" showTooltip />
          </ColStyled>
          <ColStyled width={8}>
            {isLoan ? (
              <ReadOnlyField value="Loan Out" label="Order Type" showTooltip />
            ) : (
              <ReadOnlyField
                value={`${form.$('orderSubscription.recurringPeriod').value} months`}
                label={form.$('orderSubscription.recurringPeriod').label}
                showTooltip
              />
            )}
          </ColStyled>
          {!isLoan && (
            <React.Fragment>
              <ColStyled width={8} className="ellipsis">
                <FormGroup>
                  <ReadOnlyField
                    value={`${form.$('orderSubscription.noOfInstallment').value}`}
                    label={form.$('orderSubscription.noOfInstallment').label}
                    showTooltip
                  />
                </FormGroup>
              </ColStyled>
              <ColStyled width={10}>
                <ReadOnlyField
                  value={`${form.$('orderSubscription.duration').value}`}
                  label={form.$('orderSubscription.duration').label}
                  showTooltip
                />
              </ColStyled>
            </React.Fragment>
          )}
          <ColStyled width={13}>
            <ReadOnlyField
              value={getFormattedDateTimeString(form.$('orderDate').value)}
              label={form.$('orderDate').label}
              showTooltip
            />
          </ColStyled>
          <ColStyled width={13}>
            <ReadOnlyField
              value={getFormattedDateTimeString(createdDate)}
              label={`Created By ${createdBy}`}
              showTooltip
            />
          </ColStyled>
        </Row>
      </Card>
    )
  }
}

export default inject('customerRepository')(observer(CustomerPanel))
