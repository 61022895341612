/* @flow */
import BaseServices from './BaseServices'

class OrderSubscriptionServices extends BaseServices {
  constructor(api) {
    const url = '/orderSubscriptions'
    super(api, url)
    this.api = api
    this.url = url
  }
  getNewContractById(payload) {
    return this.api.get(`${this.url}/contracts/${payload}`)
  }
  saveAsDraft(orderId, payload) {
    return this.api.update(`${this.url}/${orderId}`, payload)
  }
  orderSummary(payload) {
    return this.api.post(`/orders/summary`, payload)
  }
  cancelOrder(orderId) {
    return this.api.patch(`${this.url}/${orderId}/cancel`)
  }
}

export class OrderSubscriptionContractServices extends OrderSubscriptionServices {
  constructor(api) {
    const url = '/orderSubscriptions/contracts'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export class OrderSubscriptionProductsServices extends OrderSubscriptionServices {
  fetchTable(payload, orderId) {
    return this.api.get(`${this.url}/${orderId}/associatedDevices`, payload)
  }
}

export class OrderSubscriptionListServices extends OrderSubscriptionServices {
  fetchTable(payload, orderId) {
    return this.api.get(`${this.url}/${orderId}/list`, payload)
  }
}

export default OrderSubscriptionServices
