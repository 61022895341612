/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class UserServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/users'
    super(api, url)
    this.api = api
    this.url = url
  }

  export(payload, headers = {}) {
    const params = Object.keys(payload).length ? `?${Object.toParams(payload)}` : ''
    return this.api.post(`${this.url}/export${params}`, null, headers)
  }
}
