import styled from 'styled-components'

export const ListStyled = styled.div`
  font-size: 12px;
`

export const ListItemStyled = styled.div`
  display: flex;
  border: none;
  background-color: rgba(255, 255, 255, 1);
  padding: 0 2rem;
  > div {
    padding: 0.75rem 0.5rem;
    display: flex;
    align-items: center;
  }
`

export const ListHeaderStyled = styled.div`
  ${ListItemStyled} {
    font-weight: 600;
    color: #7f7f7f;
    padding-bottom: 0.25rem;
    &.sub-heading {
      color: ${props => props.theme.primary};
      background-color: rgba(255, 255, 255, 1);
      border-bottom: 1px solid rgb(239, 239, 239);
      padding-bottom: 0;
    }
  }
`

export const ListBodyStyled = styled.div`
  ${ListItemStyled} {
    border-bottom: 1px solid rgb(239, 239, 239);
    &:nth-child(odd) {
      background-color: rgba(242, 242, 242, 1);
    }
    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 1);
    }
    &:hover {
      background-color: rgba(255, 250, 238, 1);
    }
  }
`
