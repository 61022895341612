import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form'
import FormContainer from 'components/Common/Form/Container'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CountryModal extends Component {
  constructor(props) {
    super(props)
    const fields = {
      countries: {
        label: 'Countries',
        type: 'select',
        rules: 'required',
      },
      countryOnlineOrder: {
        type: 'switch',
        value: false,
      },
      countryStoreDisplay: {
        type: 'switch',
        value: false,
      },
      countryDirectShipment: {
        type: 'switch',
        value: false,
      },
    }
    this.form = new Form({ fields }, { name: 'CountryModalForm', handleSubmit: this.handleSubmit })
  }
  componentDidMount() {
    this.props.commonRepository.getCommon('countries', 'countries')
  }
  handleSubmit = () => {
    const { prohibitedItemRepository, onClose, reloadTable } = this.props
    const values = this.form.values()
    const payload = {
      ...values,
      access: 'blacklist',
      countries: values.countries.map(item => item),
    }
    prohibitedItemRepository.create(
      payload,
      () => {
        onClose()
        reloadTable()
      },
      'country'
    )
  }
  render() {
    const {
      prohibitedItemRepository: {
        CRUD: { loading, errors },
      },
      commonRepository: {
        Common: { countries: { data: countries = [] } = {} },
      },
      open,
      onClose,
    } = this.props
    return (
      <Modal
        open={open}
        title="Add Prohibited Countries"
        onConfirm={() => this.buttonSubmit.click()}
        onClose={onClose}
        loading={loading}
        confirmLabel="Add"
      >
        <FormContainer
          onSubmit={event => this.form.onSubmit(event, { onSuccess: this.handleSubmit })}
          plain
          errors={errors}
        >
          <ReactSelect
            field={this.form.$('countries')}
            options={{
              isMulti: true,
              options: countries,
            }}
          />
          <div className="d-flex justify-content-between align-items-center pr-2">
            <LabelStyled className="mt-2">Online Order</LabelStyled>
            <Checkbox
              field={this.form.$('countryOnlineOrder')}
              label={this.form.$('countryOnlineOrder').value ? 'Yes' : 'No'}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center pr-2">
            <LabelStyled>Store Display</LabelStyled>
            <Checkbox
              field={this.form.$('countryStoreDisplay')}
              label={this.form.$('countryStoreDisplay').value ? 'Yes' : 'No'}
            />
          </div>
          <div className="d-flex justify-content-between align-items-center pr-2 mb-4">
            <LabelStyled>Direct Shipment</LabelStyled>
            <Checkbox
              field={this.form.$('countryDirectShipment')}
              label={this.form.$('countryDirectShipment').value ? 'Yes' : 'No'}
            />
          </div>
          <button ref={ref => (this.buttonSubmit = ref)} type="submit" className="collapse" />
        </FormContainer>
      </Modal>
    )
  }
}

const LabelStyled = styled.label`
  color: #797979;
  font-size: 11px;
  font-weight: 700;
  margin: 0.25rem 0 0;
  & + .form-group {
    width: 60px;
  }
`

export default inject('commonRepository')(observer(CountryModal))
