/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import CustomerPanel from './CustomerPanel'
import DisabledProductPanel from './DisabledProductPanel'

class GroupDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenImportCustomerModal: false,
      selectedCustomers: [],
      groupId: props.match.params.groupId,
      bulkAction: null,
    }
  }
  componentDidMount() {
    const { setSelected, match } = this.props
    setSelected(match.params.groupId)
  }
  render() {
    const { cgView } = this.props
    return cgView === 'customer' ? <CustomerPanel {...this.props} /> : <DisabledProductPanel {...this.props} />
  }
}

export default decorate(inject('customerGroupRepository', 'customerGroupDetailsRepository')(observer(GroupDetails)), {})
