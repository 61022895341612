import React from 'react'

import { FormGroup, FormText, Input, Label } from 'reactstrap'
import { observer } from 'mobx-react'
import IconButton from 'components/Common/Buttons/IconButton'
import styled from 'styled-components'

const InputField = ({
  field,
  placeholder = `Select ${field.label}`,
  disabled,
  className,
  inputClassName,
  onRemove,
  onChange = null,
  ...rest
}) => {
  const { id, name, label } = field
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  return (
    <FormGroup key={name} className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <WrapperStyled>
        <label htmlFor={id}>
          <Input
            {...field.bind({
              name,
              id,
              disabled: disabled ? disabled : field.disabled,
              ...(onChange && {
                onChange: val => {
                  field.onChange(val)
                  field.validate()

                  if (onChange) onChange(val)
                },
              }),
            })}
            className={inputClassName}
            type="file"
            {...rest}
          />
          {field.value ? field.value.split('\\')[field.value.split('\\').length - 1] : <span>{placeholder}</span>}
        </label>
        {field.value && <IconButton title="Remove file" icon="close" onClick={onRemove}></IconButton>}
      </WrapperStyled>
      {hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </FormGroup>
  )
}

const WrapperStyled = styled.div`
  position: relative;
  input[type='file'] {
    visibility: hidden;
    position: absolute;
  }
  label[for] {
    display: block;
    height: 32px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
    font-weight: inherit;
    font-size: inherit;
    padding: 0.25rem;
    span {
      font-size: 0.8rem;
      opacity: 0.8;
    }
    &:hover,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.25);
    }
  }
  button {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export default observer(InputField)
