import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'

import { allowedToAppAdminRole } from 'helpers/Auth'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable'

class AddProductsModal extends Component {
  selection = []
  handleSubmit = () => {
    if (this.selection.length > 0) {
      const {
        productRepository: {
          Table: { data },
        },
      } = this.props

      const products = toJS(data)
      const selection = this.selection
        .map(productId => products.find(item => item.id === productId))
        .filter(item => item)
      this.props.handleSubmit(selection)
    }
  }
  handleSelectionChanged = ({ selection }) => {
    this.selection = selection ? Object.keys(selection).map(key => key) : []
  }
  render() {
    const {
      isModalOpen,
      toggleModal,
      productRepository: {
        fetchTable,
        loading,
        Table: { data, totalRecordCount, originalData },
      },
      isCustomOptions,
    } = this.props

    let tableData = toJS(data)

    return (
      <Modal
        title="Select Products"
        size="lg"
        open={isModalOpen}
        onClose={toggleModal}
        onConfirm={this.handleSubmit}
        hideConfirm={!allowedToAppAdminRole()}
      >
        <ReactTable
          search
          multiSelect
          selectDisabledKeyField="disabled"
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
            },
            {
              accessor: 'productCode',
              Header: 'Product Code',
            },
            {
              accessor: 'childCategory',
              Header: 'Category',
            },
            {
              accessor: 'parentCategory',
              Header: 'Parent Category',
            },
          ]}
          sort={{ id: 'name' }}
          data={isCustomOptions ? tableData.map(item => ({ ...item, disabled: !item.parentCategory })) : tableData}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          selectionChanged={this.handleSelectionChanged}
          ref={ref => (this.table = ref)}
        />
      </Modal>
    )
  }
}

export default inject('productRepository')(observer(AddProductsModal))
