// import { observer } from 'mobx-react'

import Loader from 'components/Common/Loader'
import React from 'react'

const SSOCallback = ({ history }) => {
  return <Loader text="Please wait..." />
}

export default SSOCallback
