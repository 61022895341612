/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import AttributeForm from './AttributeForm'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Loader from 'components/Common/Loader'
import React, { Component } from 'react'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class AttributeEdit extends Component {
  componentDidMount() {
    const {
      productAttributeRepository: { getById },
      match: {
        params: { id },
      },
    } = this.props

    getById(id)
  }

  componentWillUnmount() {
    this.props.productAttributeRepository.clearCrudData()
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      productAttributeRepository: {
        CRUD: { loading, data },
      },
    } = this.props

    return loading ? (
      <Loader />
    ) : (
      <React.Fragment>
        <ContentContainer title={`Edit Attribute`} breadcrumbLabel={data ? data.attributeName : ''}>
          <AttributeForm {...this.props} data={data} />
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="ProductAttributeOption"
                  entityId={data.id}
                  entityLabel="Attribute"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="attributes"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default decorate(inject('productAttributeRepository')(observer(AttributeEdit)), {})
