/* @flow */

import { decorate, observable } from 'mobx'
import _ from 'lodash'
import BaseRepository from './BaseRepository'
import FileUploadServices from 'services/fileUpload'

class FileUploadRepository extends BaseRepository {
  service: FileUploadServices
  // eslint-disable-next-line flowtype/no-primitive-constructor-types
  loading = false
  submitting = false
  errors = []
  constructor(service: FileUploadServices) {
    super(service)
    this.service = service
  }

  uploadFile = async (files, attachmentUniqueId = '') => {
    let formFiles = new FormData()
    _.each(files, file => {
      formFiles.append('files', file)
    })
    this.loading = true
    this.submitting = true
    const { data, errors } = await this.service.uploadFile(formFiles, attachmentUniqueId)
    this.submitting = false
    this.loading = false
    return { data, errors }
  }

  uploadModelFile = async (files, productId = '', model, isHighResolution) => {
    let formFiles = new FormData()
    _.each(files, file => {
      formFiles.append('files', file)
    })
    this.loading = true
    this.submitting = true
    this.errors = []
    const { data, errors } = await this.service.uploadModelFile(formFiles, productId, model, isHighResolution)
    this.errors = errors
    this.submitting = false
    this.loading = false
    return { data, errors }
  }

  removeFile = async (attachmentUniqueId, filename, securedInd = false) => {
    this.loading = true
    const { data, errors } = await this.service.removeFile(attachmentUniqueId, filename, securedInd)
    this.loading = false
    return { data, errors }
  }

  removeModelFile = async (id, filename, model = null, categoryImageSection = null) => {
    this.loading = true
    const { data, errors } = await this.service.removeModelFile(id, filename, model, categoryImageSection)
    this.loading = false
    return { data, errors }
  }

  attachFiles = async (files, attachmentUniqueId = null, securedInd = false) => {
    this.loading = true
    this.submitting = true
    const { data, errors } = await this.service.attachFiles(files, attachmentUniqueId, securedInd)
    this.submitting = false
    this.loading = false
    return { data, errors }
  }

  getFile = async (fileName = null, orderId, attachmentId, model) => {
    this.loading = true
    const { data, errors } = await this.service.getFile(fileName, orderId, attachmentId, model)
    this.loading = false
    return { data, errors }
  }
}

export default decorate(FileUploadRepository, {
  loading: observable,
  submitting: observable,
  errors: observable,
})
