/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository from './BaseRepository'
import PaymentServices from 'services/payment'

class PaymentRepository extends BaseRepository {
  service: PaymentServices
  constructor(service: PaymentServices) {
    super(service)
    this.service = service
  }
  Payment = {
    loading: false,
    data: {},
    errors: [],
  }
  Void = {
    loading: false,
    data: {},
    errors: [],
  }
  getPaymentDetails = async token => {
    this.CRUD.loading = true
    const { data, errors } = await this.service.getPaymentDetails(token)

    if (data && !errors.length) {
      this.CRUD.data = data
    } else {
      this.CRUD.loadingFailed = true
      this.CRUD.errors = errors
    }
    this.CRUD.loading = false
  }

  submitPayment = async (payload, token, callback = null) => {
    this.Payment.errors = []
    this.Payment.loading = true
    this.Payment.submitting = true
    const { data, errors } = await this.service.submitPayment(payload, token)
    this.Payment.submitting = false
    this.Payment.loading = false
    if (data && !errors.length) {
      this.Payment.data = data
      if (callback) callback(data)
    } else {
      this.Payment.errors = errors
    }
  }

  resendEmail = async (id, callback) => {
    this.Payment.loading = true
    const { data, errors } = await this.service.resendEmail(id)
    if (data && !errors.length) {
      this.Payment.data = data
    } else {
      this.Payment.errors = errors
    }
    if (callback) callback({ data, errors })
    this.Payment.loading = false
  }

  voidPayment = async (id, callback) => {
    this.Void.loading = true
    const { data, errors } = await this.service.voidPayment(id)
    if (data && !errors.length) {
      this.CRUD.data = data
    } else {
      this.Void.errors = errors
    }
    if (callback) callback({ data, errors })
    this.Void.loading = false
  }

  patchStripe = async (token, callback = null) => {
    this.Payment.errors = []
    this.Payment.loading = true
    this.Payment.submitting = true
    const { data, errors } = await this.service.patchStripe(token)
    this.Payment.submitting = false
    this.Payment.loading = false
    if (data && !errors.length) {
      this.Payment.data = data
      if (callback) callback(data)
    } else {
      this.Payment.errors = errors
    }
  }
}

export default decorate(PaymentRepository, {
  Payment: observable,
  Void: observable,
})
