import React, { useEffect, useState } from 'react'

import { Button } from 'reactstrap'
import styled from 'styled-components'

import { Visible } from 'helpers/Auth'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'

export default ({ handleCreate, handleDelete, handleMove, disabled, disabledAddProducts }) => {
  const [bulkAction, setBulkAction] = useState(null)
  useEffect(() => {
    if (disabled) {
      setBulkAction(null)
    }
  }, [disabled])
  return (
    <Visible toAppAdminRole>
      <div className="d-flex justify-content-end align-items-center">
        <ReactSelectStyled
          className="mr-2"
          options={{
            placeholder: 'Select Bulk Action',
            options: [
              { value: 0, label: 'Move' },
              { value: 1, label: 'Remove' },
            ],
            style: { width: '150px' },
            value: bulkAction,
          }}
          onChange={selected => {
            setBulkAction(selected)
          }}
          disabled={disabled}
        />
        <Button
          color="dark"
          size="sm"
          className="mr-2"
          onClick={() => {
            switch (bulkAction.value) {
              case 1:
                handleDelete()
                break
              default:
                handleMove()
                break
            }
          }}
          disabled={bulkAction === null || disabled}
        >
          Submit
        </Button>
        <IconButton onClick={handleCreate} color="light-dark" title="Add Products" disabled={disabledAddProducts} />
      </div>
    </Visible>
  )
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`
