import React, { Component } from 'react'

import ProhibitedItemsTable from './ProhibitedItemsTable'

class ProhibitedItems extends Component {
  render() {
    return <ProhibitedItemsTable {...this.props} />
  }
}

export default ProhibitedItems
