/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class OrderTypeServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/orderTypes'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export default OrderTypeServices
