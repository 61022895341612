import React from 'react'

import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'

const FormContainer = ({ children, onSubmit, errors, plain = false, noValidate, ...rest }) => {
  const Wrapper = plain ? React.Fragment : Card

  return (
    <form onSubmit={onSubmit} autoComplete="off" noValidate={noValidate}>
      <Wrapper {...(plain ? {} : rest)}>
        {errors && errors.length ? (
          <div className="my-3">
            <ErrorsList errors={errors} />{' '}
          </div>
        ) : null}
        {children}
      </Wrapper>
    </form>
  )
}

export default FormContainer
