/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react'

import { Col, FormGroup, FormText, Label } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import downloadjs from 'downloadjs'
import mime from 'mime'
import styled from 'styled-components'

import { ButtonUploadStyled, RowUploadContainerStyled } from './CustomDiscountApproval'
import {
  PAYMENT_METHOD_BANK_TRANSFER,
  PAYMENT_METHOD_CREDIT_PAYMENT,
  PAYMENT_METHOD_NOT_APPLICABLE,
  PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT,
} from 'definitions'
import BlockMessage from 'components/Common/BlockMessage'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import Loader from 'components/Common/Loader'
import ReactSelect from 'components/Common/Form/ReactSelect'

class PaymentPanel extends Component {
  uploadFile = React.createRef()
  constructor(props) {
    super(props)

    const { commonRepository, form, orderSource, orderType } = props

    commonRepository.getCommon(
      'paymentMethods',
      `paymentMethods${orderSource ? `?orderSource=${orderSource.toLowerCase()}&orderType=${orderType.name}` : ''}`,
      {}
    )
    this.surchargeAmount = form.$('payment.surcharge').value
    this.state = {
      loading: false,
    }
  }
  componentDidMount() {
    const {
      form,
      orderRepository: {
        CRUD: { data },
      },
    } = this.props
    const { value: paymentMethod } = form.$('payment.paymentMethod').value || {}
    if (paymentMethod === 'Peplink Credit Terms Payment') {
      if (data.payment.hasOwnProperty('paymentTerms') && data.payment.paymentTerms) {
        form.$('payment.paymentTerms').set(data.payment.paymentTerms)
      } else if (data.customer.hasOwnProperty('defaultPaymentTerms')) {
        form.$('payment.paymentTerms').set(data.customer.defaultPaymentTerms)
      }
    }
  }
  onPaymentMethodChanged = objectVal => {
    const {
      form,
      updateBankAccount,
      orderRepository: {
        CRUD: { data },
      },
    } = this.props
    const billingCountry = form.$('billingAddress.country').value
    // const bankAccount = form.$('payment.bankAccount').value

    // !bankAccount && store && form.$('payment.bankAccount').set(store.bankAccount)

    if (objectVal && objectVal.value === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT) {
      form.$('payment.paymentTerms').set('rules', 'required')
      if (data.payment.hasOwnProperty('paymentTerms') && data.payment.paymentTerms) {
        form.$('payment.paymentTerms').set(data.payment.paymentTerms)
      } else if (data.customer.hasOwnProperty('defaultPaymentTerms')) {
        form.$('payment.paymentTerms').set(data.customer.defaultPaymentTerms)
      }
    } else {
      form.$('payment.paymentTerms').set('rules', '')
    }

    if (
      objectVal &&
      (objectVal.value === PAYMENT_METHOD_BANK_TRANSFER ||
        objectVal.value === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT) &&
      billingCountry
    )
      updateBankAccount(billingCountry.value)

    this.props.computeOrderSummary()
  }
  handleSucharge = () => {
    this.props.computeOrderSummary(false, false, false)
  }
  handlePaymentAttachemntChange = e => {
    const {
      fileUploadRepository,
      form,
      orderRepository: { CRUD },
    } = this.props
    const attachmentUniqueId = CRUD.data.paymentAttachmentUniqueId || form.$('paymentAttachmentUniqueId').value
    const values = e.target
    let formAttachment = new FormData()
    Object.keys(values.files).forEach(key => {
      formAttachment.append('files', values.files[key])
    })
    this.setState(
      {
        loading: true,
      },
      async () => {
        const { data, errors } = await fileUploadRepository.attachFiles(formAttachment, attachmentUniqueId, true)
        if (data && !errors.length) {
          form.$('paymentAttachmentUniqueId').set(data.attachmentUniqueId)
          const newAttachments = [
            ...((CRUD.data.payment && CRUD.data.payment.attachments) || []),
            ...data.attachedFiles,
          ]

          CRUD.data = {
            ...CRUD.data,
            payment: {
              attachments: newAttachments,
            },
          }
          form.$('payment.attachments').set(newAttachments)
        }
        // this.form.invalidate(errors[0].message)
        else
          this.setState({
            errors: errors,
          })

        this.setState({
          loading: false,
        })
      }
    )
  }
  handleDetelePaymentAttachment = file => {
    const {
      fileUploadRepository,
      orderRepository: {
        CRUD: {
          data: { payment },
        },
        CRUD,
      },
      form,
    } = this.props
    const paymentAttachmentUniqueId = CRUD.data.paymentAttachmentUniqueId || form.$('paymentAttachmentUniqueId').value
    const newAttachments = payment.attachments.filter(f => f.id !== file.id)

    this.setState(
      {
        loading: true,
      },
      async () => {
        if (file.model === 'temp_attachment' && paymentAttachmentUniqueId) {
          const { data, errors } = await fileUploadRepository.removeFile(
            paymentAttachmentUniqueId,
            file.name || file.fileName,
            true
          )
          if (data && !errors.length) {
            CRUD.data.payment.attachments = newAttachments
            form.$('payment.attachments').set(newAttachments)
          } else {
            this.setState({
              errors: errors,
            })
          }
        } else {
          CRUD.data.payment.attachments = newAttachments
          form.$('payment.attachments').set(newAttachments)
          form.$('paymentDeleteAttachments').set([...form.$('paymentDeleteAttachments').value, file])
        }
        this.setState({
          loading: false,
        })
      }
    )
  }
  downloadFile = async (orderId, file) => {
    const { data, errors } = await this.props.fileUploadRepository.getFile(
      file.model && file.fileName,
      orderId,
      file.model ? this.props.form.$('paymentAttachmentUniqueId').value : file.id,
      file.model
    )

    if (data && !errors.length) {
      const extRegex = /(?:\.([^.]+))?$/
      downloadjs(data, file.fileName, mime.getType(extRegex.exec(file.fileName)[1]), file.model)
    }
  }

  render() {
    const {
      form,
      commonRepository,
      computeOrderSummary,
      bankAccountRepository,
      readOnly,
      orderRepository: { CRUD },
    } = this.props
    const { paymentMethods } = commonRepository.Common
    const { value: paymentMethod } = form.$('payment.paymentMethod').value || {}
    const files = (CRUD.data.payment && CRUD.data.payment.attachments) || []
    const paymentAttachment = form.$('payment.attachments')
    const attachmentError = paymentAttachment.errors() || (this.state.errors && this.state.errors[0].message)
    const { loading } = this.state
    const { paymentAccount = null } = CRUD.data.customer || {}
    // const { paymentCharge } = toJS(summary)
    return (
      <Card title="Payment" cardBodyClassName="pt-0 pb-2">
        <ReactSelect
          readOnly={readOnly}
          field={form.$('payment.paymentMethod')}
          options={{ options: paymentMethods.data }}
          onChange={this.onPaymentMethodChanged}
          showTooltip
        />
        {/* <PaymentField field={form.$('payment.amount')} defaultValue={paymentCharge || 0} /> */}

        {(paymentMethod === PAYMENT_METHOD_BANK_TRANSFER ||
          paymentMethod === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT) && (
          <ReactSelect
            readOnly={readOnly}
            field={form.$('payment.bankAccount')}
            customLabelKey={['name', 'id']}
            serverSide
            search={params =>
              bankAccountRepository.reactSelectSearch({ ...params, showDisabled: false, paymentAccount })
            }
            options={{
              valueKey: 'id',
              labelKey: 'name',
              defaultOptions: true,
            }}
            onChange={() => computeOrderSummary(false)}
          />
        )}
        {paymentMethod === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT && (
          <Input
            readOnly={readOnly}
            type="text"
            field={form.$('payment.paymentTerms')}
            onChange={computeOrderSummary}
          />
        )}
        {![
          PAYMENT_METHOD_NOT_APPLICABLE,
          PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT,
          PAYMENT_METHOD_CREDIT_PAYMENT,
        ].includes(paymentMethod) && (
          <>
            <Checkbox
              disabled={readOnly}
              field={form.$('payment.surchargeInd')}
              onChange={event => {
                form.$('payment.surcharge').set(event.target.checked ? this.surchargeAmount : '')
                computeOrderSummary(false, true, false)
              }}
            />
            <Input
              readOnly={readOnly}
              field={form.$('payment.surcharge')}
              disabled={!form.$('payment.surchargeInd').checked}
              onBlur={event => {
                this.surchargeAmount = event.target.value
                this.handleSucharge()
              }}
              allowNegative={false}
              min={0}
            />
          </>
        )}
        {(paymentMethod === PAYMENT_METHOD_PEPLINK_CREDIT_TERMS_PAYMENT ||
          paymentMethod === PAYMENT_METHOD_BANK_TRANSFER) && (
          <>
            <FormGroup style={{ borderBottom: '1px solid #CCC', position: 'relative' }}>
              {loading && (
                <BlockMessage
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  message={<Loader text="Please wait..." />}
                />
              )}
              <Label>
                Payment Attachments <small>(image file or PDF or DOC max 10MB)</small>
              </Label>
              {!readOnly && (
                <RowUploadContainerStyled className="form-control d-flex mx-0 px-0">
                  <input
                    type="file"
                    className="d-none"
                    onChange={this.handlePaymentAttachemntChange}
                    ref={this.uploadFile}
                    multiple="multiple"
                  />
                  <Col className="file-text">
                    {files.length ? `${files.length} File(s) selected` : 'No File(s) selected'}
                  </Col>
                  <Col xs="auto" className="file-action text-right">
                    <ButtonUploadStyled
                      color="default"
                      size="sm"
                      type="button"
                      onClick={() => {
                        this.uploadFile.current.click()
                      }}
                    >
                      Select File
                    </ButtonUploadStyled>
                  </Col>
                </RowUploadContainerStyled>
              )}
              <FormGroup>
                {files.length ? (
                  <AttachmentContainer>
                    <div className="attachments">
                      {!!files.length &&
                        files.map((file, fileIndex) => {
                          const key = file.id || fileIndex
                          const fileName = file.name || file.fileName

                          return (
                            <div key={key} className={cx('attachment', !readOnly && 'show-delete')}>
                              {!readOnly && (
                                <IconButton
                                  icon="ios-trash"
                                  className="btn-delete"
                                  title="Remove custom option"
                                  onClick={() => this.handleDetelePaymentAttachment(file)}
                                  iconSize="small"
                                />
                              )}
                              <button
                                className="text-primary btn btn-link p-0 btn-attachment"
                                onClick={() => this.downloadFile(CRUD.data.id, file)}
                                type="button"
                                title={fileName}
                              >
                                <i className="icon ion-md-attach" />
                                <span className="ml-1 ellipsis">{fileName}</span>
                              </button>
                            </div>
                          )
                        })}
                    </div>
                  </AttachmentContainer>
                ) : (
                  <small>No Attachment(s) Found.</small>
                )}
              </FormGroup>
            </FormGroup>
            {attachmentError ? <FormText color="danger">{attachmentError}</FormText> : null}
          </>
        )}
      </Card>
    )
  }
}
const AttachmentContainer = styled.div`
  .form-group {
    label {
      margin-bottom: 0;
    }
  }
  .attachments {
    max-height: 150px;
    overflow-y: auto;
    .attachment {
      display: block;
      align-items: center;
      margin-bottom: 6px;
      .btn-attachment {
        display: flex;
        font-size: 14px;
        max-width: 100%;
        i {
          font-size: 14px;
        }
      }
      &.show-delete {
        position: relative;
        padding-left: 28px;
        .btn-delete {
          position: absolute;
          top: -3px;
          left: 0;
        }
      }
    }
  }
`

export default inject('fileUploadRepository')(observer(PaymentPanel))
