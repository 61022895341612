import React from 'react'
import styled from 'styled-components'

const BlockMessage = ({ message = '', style }) => {
  return (
    <DivStyled className="d-flex justify-content-center align-items-center" style={style}>
      {message}
    </DivStyled>
  )
}

export default BlockMessage

const DivStyled = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  z-index: 10;
  font-weight: 600;
  color: #fff;
`
