import { Button } from 'reactstrap'
import React from 'react'
import styled from 'styled-components'

export default ({ data = [], title, hideDelete, onDelete, className = '', valueKey = 'value', labelKey = 'label' }) => {
  return (
    <TagContainerStyled title={title} className={className}>
      {data.map(item => (
        <TagStyled key={`tagItem${item[valueKey]}`}>
          {item[labelKey]}
          {!hideDelete && (
            <Button size="xs" color="inverse" type="button" title="Remove" onClick={() => onDelete(item)}>
              <i className="icon ion-md-close small" />
            </Button>
          )}
        </TagStyled>
      ))}
    </TagContainerStyled>
  )
}

const TagContainerStyled = styled.div`
  min-height: 150px;
  &:before {
    content: '${props => props.title}';
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    font-size: 8rem;
    font-weight: bold;
    opacity: 0.1;
    line-height: 1;
  }
`

const TagStyled = styled.span`
  padding: 0.25rem 0.75rem;
  border-radius: 100px;
  background-color: ${props => props.theme.gray200};
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  button.btn {
    height: 20px;
    width: 20px;
    border-radius: 100px;
    background-color: ${props => props.theme.gray500};
    color: #fff;
    padding: 0;
    line-height: 0;
    margin-left: 1rem;
    z-index: 1;
  }
`
