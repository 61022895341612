import React from 'react'

import { Button } from 'reactstrap'
import Loader from 'components/Common/Loader'

const Icon = ({ icon }) => <i className={`icon ion-${icon}`} style={{ marginRight: '5px' }} />

const FormActions = props => {
  const {
    loading,
    loadingMessage = 'Processing please wait...',
    confirmLabel = 'OK',
    cancelLabel = 'Cancel',
    confirmIcon = '',
    cancelIcon = '',
    onConfirm,
    onCancel,
    hideConfirm = false,
    hideCancel = false,
    disabled = false,
    submitButtonRef,
    outline = false,
    moreActions,
  } = props
  return (
    <React.Fragment>
      {loading && <Loader className="p-0 mr-auto" text={loadingMessage} />}
      {moreActions && moreActions.map(action => action({ ...props, moreActions: null, loading: false }))}
      {!hideCancel && (
        <Button
          color="dark"
          className="ml-2"
          {...(onCancel ? { onClick: onCancel } : { type: 'button' })}
          disabled={loading}
          outline={outline}
        >
          {cancelIcon && <Icon icon={cancelIcon} />} {cancelLabel}
        </Button>
      )}
      {!hideConfirm && (
        <Button
          color="primary"
          className="ml-2 d-flex"
          {...(onConfirm ? { onClick: onConfirm } : { type: 'submit' })}
          disabled={disabled || loading}
          innerRef={submitButtonRef}
          outline={outline}
        >
          {confirmIcon && <Icon icon={confirmIcon} />} {confirmLabel}
        </Button>
      )}
    </React.Fragment>
  )
}

export default FormActions
