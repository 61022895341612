import React from 'react'

import { observer } from 'mobx-react'
import cx from 'classnames'

import Card from 'components/Common/Card'
import Input from 'components/Common/Form/Input'

export default observer(({ field, readOnly }) => {
  return (
    <Card
      title={
        <span>
          Co-term Customer Remarks{' '}
          <span className="text-capitalize font-weight-normal">
            (Customer input ONLY from Partner Pavilion, Peplink should not edit)
          </span>
        </span>
      }
      className="mb-0"
    >
      <Input
        readOnly={readOnly}
        field={field}
        type="textarea"
        className={cx(readOnly && 'h-100')}
        inputClassName="border"
        rows="3"
        style={
          readOnly
            ? { minHeight: '70px', height: '100%', whiteSpace: 'pre-line' }
            : { minHeight: '70px', whiteSpace: 'pre-line' }
        }
      />
    </Card>
  )
})
