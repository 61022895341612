import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import CustomerTable from '../Customers/CustomerTable'
import ErrorsList from 'components/Common/ErrorsList'
import Modal from 'components/Common/Modal'

class ImportCustomerModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedCustomers: [],
    }
  }
  handleSelectCustomer = ({ selection = {} }) => {
    this.setState({
      selectedCustomers: Object.keys(selection),
    })
  }
  render() {
    const { selectedCustomers } = this.state
    const {
      customerGroupDetailsRepository,
      customerGroupRepository,
      onConfirm,
      onClose,
      open,
      selectedGroupData = {},
    } = this.props
    const { CRUD } = customerGroupDetailsRepository
    const { Customers, getAvailableCustomer } = customerGroupRepository
    return (
      <Modal
        open={open}
        title="Add Customers to group"
        confirmLabel={`Import ${selectedCustomers ? selectedCustomers.length : null} selected`}
        confirmIcon="save"
        loading={CRUD.loading}
        loadingMessage="Saving please wait..."
        disabled={!selectedCustomers.length}
        onConfirm={() => onConfirm(this.state.selectedCustomers)}
        onClose={onClose}
        hideConfirm={!allowedToOrderRole()}
        size="lg"
      >
        {CRUD.errors.length ? <ErrorsList errors={CRUD.errors} /> : null}
        {React.createElement(CustomerTable, {
          className: 'striped cell-vertical-center',
          multiSelect: true,
          columns: [{ accessor: 'name', Header: 'Name' }],
          selectionChanged: this.handleSelectCustomer,
          selectElementId: 'importCustomeranelTable',
          sort: { id: 'name' },
          isCG: true,
          CGData: {
            data: Customers.data,
            loadData: params => getAvailableCustomer({ ...params }, selectedGroupData.id),
            loading: Customers.loading,
            totalPages: Customers.originalData.totalRecordCount,
            totalRecordCount: Customers.totalRecordCount,
          },
        })}
      </Modal>
    )
  }
}

export default decorate(
  inject('customerGroupDetailsRepository', 'customerGroupRepository')(observer(ImportCustomerModal)),
  {}
)
