/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CategoryServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/category'
    super(api, url)
    this.api = api
    this.url = url
  }

  remove(payload) {
    return this.api.post(`${this.url}/remove`, payload)
  }

  move(payload) {
    return this.api.post(`${this.url}/move`, payload)
  }

  importProducts(payload, id) {
    return this.api.patch(`${this.url}/${id}/products`, payload)
  }

  deleteProduct(categoryId, productId) {
    return this.api.del(`${this.url}/${categoryId}/products/${productId}`)
  }

  uploadImage(categoryId, payload) {
    return this.api.post(`${this.url}/${categoryId}/upload`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}
