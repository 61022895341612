import { Badge, Button, Collapse } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import downloadjs from 'downloadjs'
import mime from 'mime'
import React, { Component } from 'react'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import ToasterNotify from 'helpers/ToasterNotify'

const maxImageCount = 6

class ImagesPanel extends Component {
  isHighResolution = false

  constructor(props) {
    super(props)

    this.fileInput = React.createRef()

    const { data: { attachments, defaultImageId = '' } = {} } = props
    this.state = {
      showAddImages: false,
      defaultImageId,
      productImages: attachments ? attachments.map(image => image) : [],
    }
  }
  handleUploadFile = isHighResolution => {
    this.isHighResolution = isHighResolution
    this.fileInput.current.click()
  }
  handleFileChange = async files => {
    if (this.validateFileUpload(files)) {
      if (files.length) {
        const productId = this.props.data.id
        const { data, errors } = await this.props.fileUploadRepository.uploadModelFile(
          files,
          productId,
          'product',
          this.isHighResolution
        )
        if (data && !errors.length) this.handleFileUploadSuccess(files, data)
      }
    }
  }
  handleFileUploadSuccess = (files, data) => {
    const { attachedFiles } = data

    this.setState(
      {
        productImages: [
          ...this.state.productImages,
          ...Array.from(files).map((file, index) => {
            const attachedFile = attachedFiles[index]
            return {
              id: attachedFile.id,
              fileObject: file,
              fileName: attachedFile.fileName,
              resourcePath: attachedFile.resourcePath,
              resourcePathThumbnail: attachedFile.resourcePathThumbnail,
              hd: attachedFile.hd,
            }
          }),
        ],
      },
      () => {
        const defaultImageId = this.state.productImages.length === 1 ? this.state.productImages[0].id : ''
        defaultImageId && this.handleMarkAsDefault(defaultImageId)
      }
    )
  }
  handleDelete = async fileName => {
    ToasterNotify.confirm({
      message: `Are you sure you want to delete '${fileName}'?`,
      title: 'Warning',
      onConfirm: async () => {
        const productId = this.props.data.id
        const { data, errors } = await this.props.fileUploadRepository.removeModelFile(productId, fileName, 'product')
        if (data && !errors.length) {
          this.setState({
            productImages: this.state.productImages.filter(item => item.fileName !== fileName),
          })
        }
      },
    })
  }
  handleMarkAsDefault = defaultImageId => {
    const { data, form, productImageRepository } = this.props
    this.setState({ defaultImageId })
    form.$('defaultImageId').set(defaultImageId)
    productImageRepository.setDefaultImage(data.id, defaultImageId)
  }
  handleDragAndDrop = event => {
    if (!this.state.showAddImages) return

    event.preventDefault()
    event.stopPropagation()

    if (['dragover', 'dragenter'].includes(event.type)) {
      this.dragAndDrop.classList.add('is-dragover')
    } else if (['dragleave', 'dragend', 'drop'].includes(event.type)) {
      this.dragAndDrop.classList.remove('is-dragover')
    }
    if (event.type === 'drop') {
      if (Array.from(event.dataTransfer.items).some(file => !file.type.match(/image.*/))) {
        ToasterNotify.alert({ message: 'You can only drop image files', color: 'info' })
      } else {
        this.handleFileChange(event.dataTransfer.files)
      }
    }
  }
  handleImageError = event => {
    event.target.setAttribute('src', require(`images/no-image.svg`).default)
  }
  handleDownloadImage = async image => {
    const { fileUploadRepository, data: productData } = this.props
    const { data, errors } = await fileUploadRepository.getFile(
      image.fileName,
      productData.id,
      image.id,
      'product_image'
    )

    if (data && !errors.length) {
      const extRegex = /(?:\.([^.]+))?$/
      const fileExtension = extRegex.exec(image.fileName)[1]
      const fileName = `${productData.productCode}-${image.id}.${fileExtension}`

      downloadjs(data, fileName, mime.getType(fileExtension), image.model)
    }
  }
  toggleShowAddImages = () => {
    this.setState({ showAddImages: !this.state.showAddImages })
  }
  validateFileUpload = files => {
    const { productImages } = this.state

    if (productImages.length + files.length > maxImageCount) {
      ToasterNotify.alert({ message: 'You can only upload up to 6 images', color: 'info' })
      return false
    }
    return true
  }
  render() {
    const { fileUploadRepository, productImageRepository, readOnly } = this.props
    const { productImages, defaultImageId, showAddImages } = this.state
    const loading = fileUploadRepository.loading || productImageRepository.CRUD.loading
    const hasHdImage = productImages ? productImages.some(item => item.hd) : false

    return (
      <ImagesPanelStyled
        title="Product Images"
        cardBodyClassName="p-0"
        titleActions={
          !readOnly && (
            <IconButton
              title={showAddImages ? 'Close' : 'Add new images'}
              icon={showAddImages ? 'md-close' : 'md-add'}
              color={showAddImages ? 'transparent' : 'primary'}
              size="sm"
              onClick={this.toggleShowAddImages}
            />
          )
        }
      >
        {loading && (
          <LoaderStyled className="d-flex-center">
            <Loader className="loader" text="Please wait..." />
          </LoaderStyled>
        )}
        <Collapse isOpen={!showAddImages && !loading && productImages.length === 0}>
          <div className="d-flex-center" style={{ height: '120px' }}>
            <p className="text-dark">{`To add images, click ${
              readOnly ? `'Edit Product' from menu` : 'Add new images button'
            }`}</p>
          </div>
        </Collapse>
        <div
          ref={ref => (this.dragAndDrop = ref)}
          className="drag-drop p-3"
          style={{ opacity: loading ? '0.6' : 1 }}
          draggable={showAddImages ? 'true' : undefined}
          onDrag={this.handleDragAndDrop}
          onDragStart={this.handleDragAndDrop}
          onDragEnd={this.handleDragAndDrop}
          onDragOver={this.handleDragAndDrop}
          onDragEnter={this.handleDragAndDrop}
          onDragLeave={this.handleDragAndDrop}
          onDrop={this.handleDragAndDrop}
        >
          <Collapse isOpen={showAddImages}>
            <div className="d-flex justify-content-center">
              <AddImagesWrapperStyled className="d-flex-center flex-column align-self-start mr-5">
                <p className="text-primary">Add normal image</p>
                <p className="text-dark">Drop files anywhere to upload</p>
                <Button color="primary" onClick={() => this.handleUploadFile(false)}>
                  Select Files
                </Button>
                <small>Maximum Size: 2 MB</small>
              </AddImagesWrapperStyled>
              <AddImagesWrapperStyled className="d-flex-center flex-column align-self-start ml-5">
                <p className="text-primary">Add high resolution image</p>
                <p className="text-dark">Drop files anywhere to upload</p>
                <Button
                  color="primary"
                  disabled={hasHdImage}
                  onClick={() => !hasHdImage && this.handleUploadFile(true)}
                >
                  Select Files
                </Button>
                <small>Maximum Size: 15 MB</small>
                <small className="text-danger m-0">Only 1 HD image per product</small>
              </AddImagesWrapperStyled>
            </div>
          </Collapse>
          <div className="d-flex flex-wrap p-1">
            {productImages.map(image => {
              const active = defaultImageId === image.id
              return (
                <ImageStyled key={`productImage${image.id}`} className="d-flex-center">
                  {(!readOnly || active) && (
                    <IconButton
                      icon={active ? 'ios-star' : 'ios-star-outline'}
                      color="primary"
                      className={`star ${active ? '' : 'inactive'}`}
                      iconSize="small"
                      outline
                      onClick={() => !readOnly && this.handleMarkAsDefault(image.id)}
                      title={active ? 'Default image' : 'Mark as default image'}
                    />
                  )}
                  <div className="image-actions">
                    <IconButton
                      icon="ios-download"
                      className="mr-1"
                      iconSize="medium"
                      onClick={() => this.handleDownloadImage(image)}
                      title="Download image"
                    />
                    {!readOnly && (
                      <IconButton
                        icon="ios-trash"
                        className="ml-1"
                        iconSize="medium"
                        onClick={() => this.handleDelete(image.fileName)}
                        title="Delete this image"
                      />
                    )}
                  </div>
                  {image.hd && (
                    <Badge color="dark" className="hd-tag text-white">
                      HD
                    </Badge>
                  )}
                  <img
                    src={`${process.env.REACT_APP_ATTACHMENT_SERVER}${image.resourcePathThumbnail}`}
                    alt={`product: ${image.fileName}`}
                    title={image.fileName}
                    onError={this.handleImageError}
                  />
                </ImageStyled>
              )
            })}
          </div>
        </div>
        <input
          type="file"
          ref={this.fileInput}
          multiple
          accept="image/*"
          onChange={event => this.handleFileChange(event.target.files)}
          className="collapse"
        />
      </ImagesPanelStyled>
    )
  }
}

const ImagesPanelStyled = styled(Card)`
  position: relative;
  .drag-drop {
    width: 100%;
    &.is-dragover {
      &::after {
        content: 'Drop images here';
        outline: 2px dashed ${props => props.theme.dark};
        outline-offset: -8px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => props.theme.dark};
      }
    }
  }
`

const ImageStyled = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  padding: 0.375rem;
  margin-right: 0.5rem;
  img {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
  }
  button {
    &.star {
      position: absolute;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;

      top: 2px;
      left: 2px;
      color: ${props => props.theme.primary} !important;
      &.inactive {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
      }
      &:hover {
        background-color: ${props => props.theme.white} !important;
      }
    }
  }
  .image-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      background-color: ${props => props.theme.white} !important;
    }
  }
  .hd-tag {
    position: absolute;
    top: 0;
    right: 0;
  }
  &:hover {
    .image-actions,
    button.star.inactive {
      opacity: 1;
    }
  }
`

const AddImagesWrapperStyled = styled.div`
  p {
    font-size: 12px;
  }
  small {
    display: block;
    margin-top: 0.5rem;
  }
`

const LoaderStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  .loader {
    margin-top: 0.25rem;
    padding: 30px 50px;
    background-color: ${props => props.theme.dark};
    border-radius: 5px;
  }
`

export default inject('fileUploadRepository', 'productImageRepository')(observer(ImagesPanel))
