/* @flow */
import { Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedTo } from 'helpers/Auth'
import { isNull } from 'helpers/Common'
import { ORDER_TYPES_PATH } from 'definitions'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import Checkbox from 'components/Common/Form/Checkbox'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import ReactSelect from 'components/Common/Form/ReactSelect'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

class OrderTypeForm extends Component {
  constructor(props) {
    super(props)

    const {
      data: {
        name,
        description,
        stores,
        orderNoPrefix,
        counterAdjustmentInd,
        counterPadding,
        startCounterFrom,
        priceAdjustmentInd,
        defaultPrice,
      } = {},
    } = props
    const rules = 'required'
    const fields = {
      name: {
        label: 'Name',
        value: name,
        rules,
      },
      description: {
        label: 'Description',
        type: 'textarea',
        value: description,
      },
      stores: {
        label: 'Available Stores',
        type: 'select',
        value: stores,
      },
      orderNoPrefix: {
        label: 'Order No. Prefix',
        value: orderNoPrefix,
      },
      counterAdjustmentInd: {
        label: 'Counter Adjustment',
        type: 'checkbox',
        value: counterAdjustmentInd,
      },
      counterPadding: {
        label: 'Counter Padding',
        type: 'number',
        value: counterPadding,
      },
      startCounterFrom: {
        label: 'Start Counter From',
        type: 'number',
        value: startCounterFrom,
      },
      priceAdjustmentInd: {
        label: 'Price Adjustment',
        type: 'checkbox',
        value: priceAdjustmentInd,
      },
      defaultPrice: {
        label: 'DefaultPrice',
        type: 'number',
        value: defaultPrice,
      },
    }

    this.form = new Form({ fields }, { name: 'OrderTypeForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }

  handleSubmit = () => {
    const actualValues = this.form.values()
    const {
      orderTypeRepository: {
        create,
        patch,
        CRUD: {
          data: { id = null },
        },
      },
      isAdd,
    } = this.props

    const requestAction = isAdd ? create : patch
    this.saving = true
    requestAction(
      {
        ...actualValues,
        id,
        stores: isNull(actualValues.stores),
      },
      result => result && this.props.history.push(ORDER_TYPES_PATH)
    )
  }

  backToIndex = () => {
    this.props.history.push(ORDER_TYPES_PATH)
  }

  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }

  render() {
    const {
      orderTypeRepository: { CRUD: { data, errors, loading } = {} },
      storeRepository,
    } = this.props

    return (
      <React.Fragment>
        <ContentContainer title="Edit Order Type" breadcrumbLabel={data.name}>
          <Row>
            <Col lg="5">
              <FormContainer
                title="Order Type Details"
                onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
                errors={errors}
                actions={
                  <FormActions
                    variant="contained"
                    loading={loading}
                    loadingMessage={`${this.saving ? 'Saving' : 'Loading'} please wait...`}
                    confirmLabel="Save"
                    cancelLabel={data.id ? 'Close' : 'Cancel'}
                    onCancel={this.backToIndex}
                    hideConfirm={!allowedTo()}
                  />
                }
              >
                <Input field={this.form.$('name')} />
                <Input field={this.form.$('description')} />
                <ReactSelect
                  field={this.form.$('stores')}
                  customLabelKey={['name', 'id']}
                  serverSide
                  search={storeRepository.reactSelectSearch}
                  options={{
                    isMulti: true,
                    valueKey: 'id',
                    labelKey: 'name',
                    defaultOptions: true,
                  }}
                />
                <Input field={this.form.$('orderNoPrefix')} />
                <Checkbox field={this.form.$('counterAdjustmentInd')} />
                <Row>
                  <Col lg="6">
                    <Input
                      field={this.form.$('counterPadding')}
                      disabled={!this.form.$('counterAdjustmentInd').value}
                    />
                  </Col>
                  <Col lg="6">
                    <Input
                      field={this.form.$('startCounterFrom')}
                      disabled={!this.form.$('counterAdjustmentInd').value}
                    />
                  </Col>
                </Row>
                <Checkbox field={this.form.$('priceAdjustmentInd')} />
                <Input field={this.form.$('defaultPrice')} disabled={!this.form.$('priceAdjustmentInd').value} />
              </FormContainer>
            </Col>
          </Row>
        </ContentContainer>
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel
                  entity="OrderType"
                  entityId={data.id}
                  entityLabel="Order Type"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <CommentSidePanel
                  referenceId={data.id}
                  commentSource="orderType"
                  closeDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </React.Fragment>
    )
  }
}

export default inject('orderTypeRepository', 'storeRepository')(observer(OrderTypeForm))
