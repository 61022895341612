import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import { allowedToOrderRole } from 'helpers/Auth'
import Modal from 'components/Common/Modal'
import ReactTable from 'components/Common/ReactTable/index'

class ProductsModal extends Component {
  form = null
  constructor(props) {
    super(props)
    this.state = {
      selected: [],
    }
  }

  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  handleSubmit = () => {
    const { customer, customerSpecialProductRepository, toggleModal, table } = this.props
    customerSpecialProductRepository.create(
      {
        products: this.state.selected.map(id => ({
          id: id,
        })),
      },
      res => {
        toggleModal()
        table.reload()
      },
      customer.id
    )
  }

  render() {
    const { customer, isModalOpen, toggleModal, editMode, customerSpecialProductRepository } = this.props
    const {
      ProductTable: { data, loading, originalData, totalRecordCount },
      fetchProductTable,
    } = customerSpecialProductRepository
    return (
      <Modal
        size="lg"
        open={isModalOpen}
        title={`${editMode ? `Edit ` : `Add New `} Special Products`}
        confirmLabel={`Save`}
        confirmIcon={`save`}
        loadingMessage={`Saving please wait...`}
        onConfirm={() => this.handleSubmit()}
        onClose={toggleModal}
        hideConfirm={!allowedToOrderRole()}
      >
        <ReactTable
          multiSelect
          className="striped cell-vertical-center"
          columns={[
            {
              Header: 'Product Code',
              accessor: 'productCode',
            },
            {
              Header: 'Name',
              accessor: 'name',
            },
          ]}
          sort={[{ columnName: 'name', direction: 'asc' }]}
          data={data}
          loadData={params => fetchProductTable(params, customer.id)}
          loading={loading}
          search
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          selectionChanged={this.handleSelected}
        />
      </Modal>
    )
  }
}

export default decorate(inject('customerSpecialProductRepository')(observer(ProductsModal)), {})
