/* eslint-disable max-len */
import { Helmet } from 'react-helmet'
import React from 'react'

export default () => {
  return (
    <Helmet>
      <script type="text/javascript">
        {`
          window.markerConfig = {
            destination: '6108fa84a347d63e87c49b3c',
            source: 'snippet'
            };
        `}
      </script>
      <script type="text/javascript">
        {`
          !function(e,r,a){if(!e.Marker){e.Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
        `}
      </script>
    </Helmet>
  )
}
