import { Col, Row } from 'reactstrap'
import { observer } from 'mobx-react'
import React from 'react'

import { groupBy } from 'helpers/Array'
import { VIRTUAL_PRODUCT_TYPE } from 'definitions'
import AutoRenewProduct from './AutoRenewProduct'
import Loader from 'components/Common/Loader'

const AutoRenewalProduct = ({
  field = null,
  options,
  subscriptionProducts: subscriptionProductsProp = [],
  loading,
  validateAutoRenewal,
}) => {
  const groupSubscriptionProducts = React.useMemo(() => {
    const isOverwrittenBySFCUnlimited = subscriptionProductsProp.some(
      item => item.productType == VIRTUAL_PRODUCT_TYPE.CLOUD && item.overwrittenBySFCUnlimited
    )
    const selectedProductTypes = subscriptionProductsProp.map(product => product.productType)
    const validOptions = options
      .filter(opt => selectedProductTypes.includes(opt.productType))
      .map(item => {
        // match the options' overwrittenBySFCUnlimited if any cloud subscription is overwrittenBySFCUnlimited
        if (item.productType == VIRTUAL_PRODUCT_TYPE.CLOUD && isOverwrittenBySFCUnlimited) {
          item.overwrittenBySFCUnlimited = isOverwrittenBySFCUnlimited
        }

        return item
      })

    return groupBy(validOptions, 'productType')
  }, [subscriptionProductsProp, options])

  const handleSelectSubscriptionProduct = (_, product) => {
    const subscriptionProducts = field.value
    const subscriptionProduct = subscriptionProducts.filter(prod => prod.productCode === product.productCode)[0]
    if (subscriptionProduct) {
      field.set(subscriptionProducts.filter(prod => prod.productCode !== product.productCode))
    } else {
      field.set([
        ...subscriptionProducts.filter(prod => prod.productType !== product.productType),
        {
          id: product.productId,
          ...product,
        },
      ])
    }
    validateAutoRenewal()
  }

  return (
    <div className="mt-2">
      <Row className="form-group">
        <Col lg={12} className="p-0">
          <label>Subscription Products</label>
        </Col>
      </Row>
      <Row className="form-group">
        <Col lg={12}>
          {loading ? (
            <Loader />
          ) : (
            Object.keys(groupSubscriptionProducts).map((productType, key) => {
              return (
                <>
                  <label className="text-uppercase text-primary">{productType}</label>
                  <Row key={key}>
                    {groupSubscriptionProducts[productType].map((opt, index) => {
                      const subscriptionProduct =
                        field.value.filter(
                          prod => prod.productCode === opt.productCode && !opt.overwrittenBySFCUnlimited
                        )[0] || {}
                      return (
                        <Col xs={12} sm={12} md={6} lg={6} key={index}>
                          <AutoRenewProduct
                            product={opt}
                            key={index}
                            onSelect={handleSelectSubscriptionProduct}
                            checked={subscriptionProduct?.autoRenewInd}
                            isLast={groupSubscriptionProducts[productType].length - 1 === index}
                            isSelected={!!subscriptionProduct?.id}
                            renewalDate={subscriptionProduct?.renewalDate}
                            remainingData={subscriptionProduct?.remainingData}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </>
              )
            })
          )}
        </Col>
      </Row>
    </div>
  )
}

export default observer(AutoRenewalProduct)
