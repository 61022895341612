/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class BankAccountServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/bankAccounts'
    super(api, url)
    this.api = api
    this.url = url
  }
  getBankInfoBySelectedBank(country, payload) {
    return this.api.get(`${this.url}/countries/${country}`, payload)
  }

  getBankAccountsByOrder(payload) {
    return this.api.post(`${this.url}/orders/${payload.id}`, payload)
  }
}

export default BankAccountServices
