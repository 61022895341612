import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import CombinationRule from './CombinationRule'
import NormalRule from './NormalRule'

class RuleListItem extends Component {
  render() {
    const {
      item,
      parentCriteria,
      form,
      index,
      primaryIndex,
      commonRepository,
      AttributeCollection,
      onAttributeSearch,
      onDeleteParentCondition,
    } = this.props
    return (
      <li>
        {item.type === 'combinations' ? (
          <CombinationRule
            {...{
              commonRepository,
              item,
              form,
              parentCriteria,
              index,
              primaryIndex,
              AttributeCollection,
              onAttributeSearch,
              onDeleteParentCondition,
            }}
          />
        ) : (
          <NormalRule
            {...{
              index,
              commonRepository,
              item,
              AttributeCollection,
              onAttributeSearch,
              parentCriteria,
            }}
          />
        )}
      </li>
    )
  }
}

export default inject('orderAttributeRepository')(observer(RuleListItem))
