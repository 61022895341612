import React, { Component } from 'react'

import ShippingTable from './ShippingTable'

class Shipping extends Component {
  render() {
    return <ShippingTable {...this.props} />
  }
}

export default Shipping
