import React, { Component } from 'react'

import { Button, Col } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { allowedTo, Visible } from 'helpers/Auth'
import { formatNumber } from 'helpers/Formatters'
import { PRODUCTS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import ErrorsList from 'components/Common/ErrorsList'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'
import ReactTable from 'components/Common/ReactTable'
import SetStoreProductPriceModal from './SetStoreProductPriceModal'
import StoreProductModal from './StoreProductModal'
import ToasterNotify from 'helpers/ToasterNotify'

class StoreProductPanel extends Component {
  table = null

  state = {
    isProductModalOpen: false,
    isPriceModalOpen: false,
    selectedProducts: [],
    itemToDelete: {},
    selected: [],
    bulkAction: null,
  }

  toggleAddProductModal = () => this.setState({ isProductModalOpen: !this.state.isProductModalOpen })
  togglePriceModal = () => this.setState({ isPriceModalOpen: !this.state.isPriceModalOpen })

  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }

  handleProductSelect = (selection = []) => {
    selection.length &&
      this.setState({
        isPriceModalOpen: true,
        selectedProducts: selection,
      })
  }

  confirmDeletion = item => {
    ToasterNotify.confirm({
      message: 'Are you sure you want to delete this product?',
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
    this.setState({
      itemToDelete: item,
    })
  }

  handleDelete = async () => {
    const { storeId, storeProductRepository } = this.props
    await storeProductRepository.delete({ storeId, products: [{ id: this.state.itemToDelete.id }] }, () => {
      this.table.reload()
    })
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    if (bulkAction === 'Remove') {
      const { storeId, storeProductRepository } = this.props
      ToasterNotify.confirm({
        message: 'Are you sure you want to delete the selected product(s)?',
        title: 'Warning',
        onConfirm: async () => {
          await storeProductRepository.delete({ storeId, products: selected.map(id => ({ id })) }, () => {
            this.table.reload()
          })
        },
      })
    }
  }
  render() {
    const { bulkAction, selected } = this.state
    const {
      storeRepository: {
        CRUD: { data: store },
      },
      storeProductRepository: {
        CRUD: { errors = [] } = {},
        Table: { data = [], loading, totalRecordCount, originalData },
        fetchTable,
      },
      storeProductRepository,
      storeId,
    } = this.props
    return (
      <Col lg={7}>
        <Card
          title="Store Products"
          titleActions={
            <Visible>
              <IconButton color="dark" className="mb-1" outline onClick={this.toggleAddProductModal} />
            </Visible>
          }
        >
          {errors.length > 0 && (
            <div style={{ margin: '20px' }}>
              <ErrorsList errors={errors} />{' '}
            </div>
          )}
          <ReactTable
            multiSelect={allowedTo()}
            selectElementId="storeProductPanel"
            className="striped cell-vertical-center"
            columns={[
              {
                accessor: 'product.name',
                Header: 'Name',
                Cell: ({ original }) => (
                  <Link to={`${PRODUCTS_PATH}/${original.product.id}`}>{original.product.name}</Link>
                ),
              },
              {
                accessor: 'productCode',
                Header: 'Product Code',
                Cell: ({ original }) => (
                  <Link to={`${PRODUCTS_PATH}/${original.product.id}`}>{original.product.productCode}</Link>
                ),
              },
              {
                accessor: 'price',
                Header: 'Price',
                Cell: ({ original }) =>
                  `${(original.price && formatNumber(original.price.amount)) || ''} ${
                    (original.price && original.price.currency) || ''
                  }`,
              },
              {
                Header: 'Actions',
                headerClassName: 'justify-content-center',
                className: 'p-1 justify-content-center',
                show: allowedTo(),
                width: 100,
                Cell: ({ original }) => <IconButton icon="ios-trash" onClick={() => this.confirmDeletion(original)} />,
              },
            ]}
            actions={
              <Visible>
                <div className="d-flex justify-content-end align-items-center mb-1">
                  <ReactSelectStyled
                    plain={true}
                    className="mr-2"
                    options={{
                      placeholder: 'Bulk Action',
                      options: [{ value: 'Remove', label: 'Remove' }],
                    }}
                    disabled={!selected.length}
                    onChange={vals => {
                      this.setState({ bulkAction: vals.value })
                    }}
                  />
                  <Button
                    size="sm"
                    color="dark"
                    type="button"
                    disabled={!bulkAction}
                    onClick={this.handleSubmitBulkAction}
                  >
                    Submit
                  </Button>
                </div>
              </Visible>
            }
            sort={{ id: 'product.name' }}
            data={data}
            loadData={params => fetchTable(params, storeId)}
            loading={loading}
            totalRecordCount={totalRecordCount}
            ref={ref => (this.table = ref)}
            search
            totalPages={originalData.totalPages}
            selectionChanged={this.handleSelected}
          />
        </Card>

        <StoreProductModal
          storeId={storeId}
          storeProductRepository={storeProductRepository}
          isModalOpen={this.state.isProductModalOpen}
          toggleModal={this.toggleAddProductModal}
          handleSelected={this.handleProductSelect}
        />

        {/* Contrary to my PR comments, this condition is important because
        I need the modal to unmount to set the array fields ¯\_(ツ)_/¯ */}
        {this.state.isPriceModalOpen && (
          <SetStoreProductPriceModal
            store={store}
            selectedProducts={this.state.selectedProducts}
            isModalOpen={this.state.isPriceModalOpen}
            toggleModal={this.togglePriceModal}
            reloadTable={() => this.table.reload()}
            {...this.props}
          />
        )}
      </Col>
    )
  }
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`

export default decorate(inject('storeRepository', 'storeProductRepository')(observer(StoreProductPanel)), {})
