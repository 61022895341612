import { decorate, observable } from 'mobx'
import jwt_decode from 'jwt-decode'

import BaseRepository from './BaseRepository'

class authStore extends BaseRepository {
  Auth = {
    loading: false,
    data: null,
    errors: [],
  }

  constructor(api) {
    super(api)
    this.api = api
    const user = this.getUser()
    if (user) {
      const { fullName = '' } = user
      this.Auth.data = { fullName }
    }
  }

  isAuthenticated = () => {
    const authenticatedToken = this.getUser()
    if (authenticatedToken) {
      const { token = null, id = null, role = null } = authenticatedToken
      return !!id && !!token && !!role
    }
    return false
  }

  getToken() {
    return localStorage.getItem('STORE_SESSION') && JSON.parse(localStorage.getItem('STORE_SESSION'))
  }

  getUser = () => {
    return this.getToken() || {}
  }

  getUserId = () => {
    const user = this.getUser()
    return user.id
  }

  authenticatePeplinkId = async ({ history, data, state, isLoginPage, isCallback }) => {
    try {
      this.submitting = true
      const expiration = data.tokenParsed.exp + data.timeSkew - new Date().getTime() / 1000
      const tokenInfo = jwt_decode(data.token)

      const success = await this.ssoLogin({
        access_token: data.token,
        refresh_token: data.refreshToken,
        expires_in: expiration,
        session_state: tokenInfo.session_state,
        token_type: tokenInfo.typ,
        state,
      })

      if (success) {
        const REDIRECT_URL = localStorage.getItem('REDIRECT_URL')
        if (REDIRECT_URL) {
          localStorage.removeItem('REDIRECT_URL')
          history.push(REDIRECT_URL)
        } else {
          if (isLoginPage || isCallback) {
            history.push('/')
          }
        }
      } else {
        history.push('/')
      }
    } finally {
      this.submitting = false
    }
  }

  ssoLogin = async payload => {
    this.Auth.loading = true
    this.Auth.errors = []

    const { data, errors } = await this.api.ssoLogin(payload)
    this.Auth.loading = false
    if (!errors.length) {
      this.setLogin(data)
      return true
    } else {
      this.Auth.errors = errors
      return false
    }
  }

  setLogin(value) {
    this.setToken(value)
    this.Auth.data = this.getUser()
  }

  setToken = data => {
    if (!this.isCustom) {
      const loginInfo = jwt_decode(data.id_token)
      localStorage.setItem(
        'STORE_SESSION',
        JSON.stringify({
          token: data.id_token,
          ...(loginInfo.sub && JSON.parse(loginInfo.sub)),
          role: loginInfo.auth || {},
        })
      )
    } else {
      localStorage.setItem('STORE_SESSION', JSON.stringify(data))
    }
  }

  ssoLogout = async ({ onAuthLogout = null } = {}) => {
    const success = await this.logout()
    if (success) {
      if (onAuthLogout) {
        window.location.reload()
      } else {
        // eslint-disable-next-line max-len
        window.location.href = `${process.env.REACT_APP_PEPLINK_SSO_SERVER_URL}${process.env.REACT_APP_PEPLINK_SSO_LOGOUT_PATH}${process.env.REACT_APP_BASE_URL}`
      }
    }
  }

  logout = async () => {
    this.Auth.loading = true
    const { errors } = await this.service.logout()
    this.Auth.loading = false
    if (!errors.length) {
      this.Auth.loading = false
      localStorage.removeItem('STORE_SESSION')
      return true
    } else {
      this.Auth = {
        loading: false,
        errors: errors,
      }
    }
    return false
  }
}

export default decorate(authStore, {
  Auth: observable,
  isAuthenticated: observable,
  submitting: observable,
})
