import React, { Component } from 'react'

import { CustomInput } from 'reactstrap'
import { observer } from 'mobx-react'

import { groupBy } from 'helpers/Array'
import CustomOptionsGroup from './CustomOptions'
import ErrorsList from 'components/Common/ErrorsList'
import Form from 'components/Common/Form'
import Input from 'components/Common/Form/Input'
import Modal from 'components/Common/Modal'

class CustomOptionsModal extends Component {
  state = {
    customOptions: [],
  }

  constructor(props) {
    super(props)
    const { productForm } = props
    const productCode = productForm.$('productCode').value

    const fields = {
      productCode: {
        type: 'text',
        label: 'Series Product Code',
        value: productCode,
        rules: 'required',
        placeholder: 'Edit series product code',
      },
      customOptions: {
        value: [],
      },
    }
    this.form = new Form({ fields }, { name: 'ProductSmarAddForm', handleSubmit: this.handleSubmit })
  }

  handleSubmit = () => {
    const { onConfirm } = this.props
    const { customOptions } = this.form.values()

    const selection = customOptions.filter(item => item.selected).map(item => item)
    const grouped = groupBy(selection, 'groupId')
    const payload = Object.keys(grouped).map(key => {
      const values = grouped[key]
      const group = grouped[key][0].group

      return {
        category: group.title,
        disabled: false,
        id: group.id,
        products: values.map(item => ({
          disabled: false,
          name: item.name,
          productCode: item.productCode,
          timePeriod: item.timePeriod,
        })),
      }
    })

    this.props.productForm.$('customOptions').set(payload)
    onConfirm()
  }

  handleSeriesProductCodeChanged = event => {
    this.form.$('customOptions').map(field => {
      const tokens = field.$('productCode').value.split('-')
      const begin = tokens[0]
      const end = tokens[tokens.length - 1]

      field.$('productCode').set(`${begin}-${event.target.value}-${end}`)
      return field
    })
  }

  toggleAllCustomOptions = selected => {
    this.form.$('customOptions').map(item => {
      item.$('selected').set(selected)
      return item
    })

    document.querySelectorAll('.checkbox-custom-option-select-all').forEach(item => {
      item.checked = selected
    })
  }

  handleSelectionChanged = () => {
    const allSelected = [...document.querySelectorAll('.checkbox-custom-option-select-all')].every(item => item.checked)
    this.checkboxSelectAllRef.checked = allSelected
  }

  render() {
    const { productRepository, onClose } = this.props
    const {
      CRUD: { errors: productErrors, loading: productLoading },
      CustomOptions: { data = [], loading, errors },
    } = productRepository

    return (
      <Modal
        title="Smart Add on Custom Options"
        size="lg"
        open
        loading={loading || productLoading}
        loadingMessage="Please wait..."
        onConfirm={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
        onClose={onClose}
        className="bg-light"
      >
        <div style={{ minHeight: '200px' }}>
          <p style={{ fontSize: '80%' }}>
            The system will create the relevant virtual products automatically. Please be minded to edit the details.
          </p>
          {errors && errors.length > 0 && <ErrorsList errors={errors} />}
          {productErrors && productErrors.length > 0 && <ErrorsList errors={productErrors} />}
          <Input
            field={this.form.$('productCode')}
            className="w-50 mb-2 px-2"
            onChange={this.handleSeriesProductCodeChanged}
          />
          <CustomInput
            innerRef={ref => (this.checkboxSelectAllRef = ref)}
            type="checkbox"
            id={`checkboxCustomOptionSelectAll`}
            className="ml-2 mb-3"
            title="Toggle All Options"
            label="Select All Custom Options"
            defaultChecked
            onChange={event => this.toggleAllCustomOptions(event.target.checked)}
          />
          {data.map((item, index) => (
            <CustomOptionsGroup
              key={index}
              form={this.form}
              customOption={item}
              isOpen={index === 0}
              onSelectionChanged={this.handleSelectionChanged}
            />
          ))}
        </div>
      </Modal>
    )
  }
}

export default observer(CustomOptionsModal)
