/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Row } from 'reactstrap'

import { PAYMENT_ACCOUNTS_PATH } from 'definitions'
import ContentContainer from 'components/Common/ContentContainer'
import PaymentAccountForm from './Form'

class PaymentAccountAdd extends Component {
  componentWillUnmount() {
    this.props.paymentAccountRepository.clearCrudData()
    this.props.paymentAccountRepository.clearErrorMessages()
  }

  render() {
    return (
      <ContentContainer
        title={`Add Payment Account`}
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: `Payment Accounts`,
            href: PAYMENT_ACCOUNTS_PATH,
          },
          {
            label: 'New',
          },
        ]}
        promptOnExit={this.props.paymentAccountRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <Row>
          <PaymentAccountForm {...this.props} isAdd={true} />
        </Row>
      </ContentContainer>
    )
  }
}

export default decorate(inject('paymentAccountRepository')(observer(PaymentAccountAdd)), {})
