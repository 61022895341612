import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import classnames from 'classnames'
import React, { Component } from 'react'
import styled from 'styled-components'

class Tab extends Component {
  renderedTabs = this.props.activeTab ? [this.props.activeTab] : []
  state = {
    activeTab: this.props.activeTab,
  }
  toggleTab = activeTab => {
    if (this.state.activeTab !== activeTab) {
      !this.renderedTabs.includes(activeTab) && this.renderedTabs.push(activeTab)
      this.setState({ activeTab }, this.props.onChangeActiveTab)
    }
  }
  render() {
    const { tabs = [], className, tabContentClassName = '' } = this.props
    const visibleTabs = tabs.filter(item => !item.hidden)

    return (
      <>
        <TabHeaderStyled tabs className={classnames(className)}>
          {visibleTabs.map(tab => (
            <NavItem key={`tabHeader${tab.key}`}>
              <NavLink
                className={classnames({ active: this.state.activeTab === tab.key })}
                onClick={() => this.toggleTab(tab.key)}
              >
                {tab.label}
              </NavLink>
            </NavItem>
          ))}
        </TabHeaderStyled>
        <TabContentStyled activeTab={this.state.activeTab} className={`d-flex ${tabContentClassName}`}>
          {visibleTabs
            .filter(tab => this.renderedTabs.includes(tab.key))
            .map(tab => (
              <TabPane key={`tabContent${tab.key}`} tabId={tab.key} className="flex-fill w-100">
                {tab.content}
              </TabPane>
            ))}
        </TabContentStyled>
      </>
    )
  }
}

const TabHeaderStyled = styled(Nav)`
  &.nav {
    display: flex;
    border: 0;
    .nav-item {
      display: flex;
      flex-grow: 1;
      flex-basis: 0;
      padding: 0 0.5rem;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      .nav-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        text-transform: uppercase;
        font-family: Helvetica, Arial, sans-serif;
        min-height: 25px;
        font-size: 0.75rem;
        font-weight: bold;
        text-align: center;
        border: 0px;
        box-shadow: 0 -0.125rem 0.25rem rgba(0, 0, 0, 0.075);
        background-color: #f3f3f3;
        color: #333333;
        &.active {
          background-color: ${props => props.theme.white};
        }
      }
    }
  }
`

const TabContentStyled = styled(TabContent)`
  &.tab-content {
    min-height: 120px;
    background: ${props => props.theme.white};
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    margin-bottom: 30px;
    border-radius: 0 0 10px 10px;
    .tab-pane {
      padding: 1.25rem;
    }
  }
`

export default Tab
