import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import { decorate, observable } from 'mobx'
import { Editor } from 'react-draft-wysiwyg'
import { FormGroup, FormText, Label } from 'reactstrap'
import { observer } from 'mobx-react'
import draftToHtml from 'draftjs-to-html'
import React, { Component } from 'react'
import styled from 'styled-components'

class HtmlEditor extends Component {
  editorState = EditorState.createEmpty()
  characterCount = 0
  constructor(props) {
    super(props)
    this.props.field.value && this.setEditorState(this.props.field.value)
  }
  clear() {
    this.editorState = EditorState.createEmpty()
  }
  handleEditorStateChange = editorState => {
    const { onChange, field } = this.props

    this.editorState = editorState
    const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    field.onChange(value)
    field.validate()
    onChange && onChange(value)

    this.setCharacterCount()
  }
  handleBeforeInput = value => {
    const { maxLength = 0 } = this.props
    if (maxLength > 0) {
      const textLength = this.editorState.getCurrentContent().getPlainText().length
      if (value && textLength >= maxLength) {
        return 'handled'
      }
    }
    return 'not-handled'
  }
  handlePastedText = value => {
    const { maxLength = 0 } = this.props
    if (maxLength > 0) {
      const textLength = this.editorState.getCurrentContent().getPlainText().length
      return value.length + textLength > maxLength
    }
    return false
  }
  setEditorState = value => {
    const contentBlock = convertFromHTML(value)
    if (contentBlock.contentBlocks) {
      const contentState = ContentState.createFromBlockArray(contentBlock)
      const editorState = EditorState.createWithContent(contentState)
      this.editorState = editorState
    }
    this.setCharacterCount()
  }
  setCharacterCount = () => {
    const { maxLength = 0 } = this.props
    if (maxLength > 0) {
      const characterCount = this.editorState.getCurrentContent().getPlainText().length
      this.characterCount = characterCount
    }
  }
  renderRemainingCharacters = () => {
    const { maxLength = 0 } = this.props

    if (maxLength > 0) {
      const remaining = maxLength - (this.characterCount || 0)
      return <small>{remaining} character(s) remaining</small>
    }

    return false
  }

  render() {
    const { field, readOnly } = this.props
    const { label, placeholder = `Enter ${field.label}` } = field
    const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)

    return (
      <HtmlEditorStyled>
        {label && <Label>{label}</Label>}
        <Editor
          toolbar={{
            options: [
              'inline',
              'textAlign',
              'fontFamily',
              'fontSize',
              'list',
              'blockType',
              'colorPicker',
              'link',
              'embedded',
              'image',
              'history',
            ],
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            list: { inDropdown: true },
            history: { inDropdown: false },
          }}
          editorState={this.editorState}
          onEditorStateChange={this.handleEditorStateChange}
          wrapperClassName="wrapper border"
          toolbarClassName="toolbar border-0 border-bottom"
          editorClassName="editor"
          spellCheck
          placeholder={placeholder}
          readOnly={readOnly}
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
        />
        {this.renderRemainingCharacters()}
        {hasError ? <FormText color="danger">{field.error}</FormText> : null}
      </HtmlEditorStyled>
    )
  }
}

const HtmlEditorStyled = styled(FormGroup)`
  .wrapper {
    .toolbar {
      background-color: #f3f3f3;
      padding: 0.25rem;
      margin: 0;
      .rdw-option-wrapper {
        border: none;
        border-radius: 0;
        padding: 0 0.5rem;
        min-width: 0;
        height: 32px;
        margin: 0;
        background: inherit;
        &:hover {
          box-shadow: none;
          background-color: #e7e7e7;
        }
        &.rdw-option-active {
          box-shadow: none;
          background-color: ${props => props.theme.primary};
        }
      }
      .rdw-list-dropdown {
        z-index: 5;
      }
    }
    .editor {
      height: 180px;
      padding: 0.25rem;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      &:focus-within {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(255, 184, 28, 0.25);
      }
      .public-DraftStyleDefault-block {
        margin: 0;
      }
    }
  }
`

export default decorate(observer(HtmlEditor), {
  editorState: observable,
})
