import React, { Component } from 'react'

import { Alert, Col, Row } from 'reactstrap'
import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import { getFormattedDateTimeString } from 'helpers/Formatters'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import LogBookModal from 'components/LogBook/LogBookModal'

class ActivityLogSidePanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      selectedLog: {},
      isActivityLogModalOpen: false,
    }
    this.refresh = debounce(this.refresh, 250)
  }
  componentDidMount() {
    this.fetchLogs()
  }
  fetchLogs = async () => {
    const { logsRepository, entity, entityId } = this.props
    await logsRepository.fetchLogsByEntity({
      entity,
      entityId,
      size: 30,
      sort: 'createdDate,desc',
    })
    this.setState({
      data: [...logsRepository.EntityLogsTable.data],
    })
  }
  getLogMessage = row => {
    if (row) {
      const { entity, entityLabel, referenceLabel } = this.props

      if (row.type === 'CREATE') {
        return (
          <span>
            Created the {entityLabel || entity}{' '}
            <span className="text-primary word-break-break-all">{referenceLabel || row.reference}</span>
          </span>
        )
      } else if (row.type === 'UPDATE') {
        return (
          <span>
            Revised as <span className="text-primary">{referenceLabel || row.reference}</span>
          </span>
        )
      }
    }
    return null
  }
  toggleActivityLogModal = selectedLog => {
    this.setState({
      selectedLog,
      isActivityLogModalOpen: !this.state.isActivityLogModalOpen,
    })
  }
  refresh = () => {
    this.fetchLogs()
  }
  render() {
    const { selectedLog, isActivityLogModalOpen } = this.state
    const { logsRepository, entityName, closeDrawer, hideClose, onLogModalOpen } = this.props
    const {
      EntityLogsTable: { loading },
    } = logsRepository
    const { data } = this.state

    return (
      <ActivityLogSidePanelStyled className="activity-log-side-panel">
        <Row>
          <Col>
            <h5 className="title">Activity Log</h5>
          </Col>
          <Col xs="auto" className="ml-auto">
            <IconButton icon="md-refresh" className="mr-1" onClick={this.refresh} />
            {!hideClose && <IconButton icon="md-close" onClick={closeDrawer} />}
          </Col>
        </Row>
        {entityName && (
          <Row>
            <Col>
              <h5 className="sub-title text-primary">{entityName}</h5>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            {loading && <Loader className="py-4 text-center" text="Loading..." />}
            {!loading &&
              data.map(row => {
                return (
                  <div key={row.id} className="line">
                    <div className="d-flex">
                      <span className="mx-1 font-weight-bold ellipsis col p-0" title={row.createdBy}>
                        {row.createdBy}
                      </span>
                      <span className="text-right col-auto ml-auto p-0">
                        {getFormattedDateTimeString(row.createdDate)}
                      </span>
                    </div>
                    <div>
                      <Alert color="activity" className="d-flex justify-content-between align-items-center py-1">
                        {this.getLogMessage(row)}
                        <IconButton icon="md-search" className="p-1" onClick={() => this.toggleActivityLogModal(row)} />
                      </Alert>
                    </div>
                  </div>
                )
              })}
          </Col>
        </Row>
        <LogBookModal
          open={isActivityLogModalOpen}
          onClose={this.toggleActivityLogModal}
          logsRepository={logsRepository}
          reference={selectedLog.reference}
          logId={selectedLog.id}
          onLogModalOpen={onLogModalOpen}
        />
      </ActivityLogSidePanelStyled>
    )
  }
}

const ActivityLogSidePanelStyled = styled.div`
  .title {
    min-height: 26px;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0.5rem 0;
  }
  .sub-title {
    min-height: 26px;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
    padding: 0.5rem 0;
  }
  .line {
    padding: 0.5rem 0;
    font-size: 85%;
    .alert {
      padding: 0.5rem 1rem;
      margin: 0;
    }
  }
`

export default inject('logsRepository')(observer(ActivityLogSidePanel))
