/* @flow */
import React, { Component } from 'react'

import { Badge, Col, Row } from 'reactstrap'
import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Route } from 'react-router-dom'
import _ from 'lodash'

import { formatNumber } from 'helpers/Formatters'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import CustomerGroupFormModal from './CustomerGroupFormModal'
import FormContainer from 'components/Common/Form/Container'
import GroupDetails from './GroupDetails'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

const pageKey = 'customer-groups'

const STR_DEF = {
  customer: 'Customers',
  product: 'Disabled Products',
  customerActions: {
    new: 'New Customer Group',
    edit: 'Edit Customer Group',
  },
}

class CustomerGroups extends Component {
  table = null
  state = {
    isModalOpen: false,
    selectedGroup: null,
    selectedGroupData: null,
    editMode: false,
    cgView: 'customer',
  }
  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const pageSettings = searchRepository.Pages[pageKey]
    if (pageSettings?.cgView) {
      this.state.cgView = pageSettings.cgView
    }
    if (pageSettings?.selectedGroup) {
      this.state.selectedGroup = pageSettings.selectedGroup
    }
  }

  loadData = params => {
    const { customerGroupRepository } = this.props

    customerGroupRepository.fetchTable(params, null, () => {
      const { selectedGroup } = this.state
      const newData = customerGroupRepository.Table.data
      if (selectedGroup) {
        const found = newData.find(item => item.id === selectedGroup)
        if (found) {
          this.setState({ selectedGroupData: found })
          this.props.history.push(`/customer-groups/${selectedGroup}`)
        } else {
          this.setSelected(undefined)
          this.props.history.push('/customer-groups')
        }
      }
    })
  }

  toggleModal = editMode => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      editMode: editMode,
    })
  }

  handleChangeView = cgView => {
    this.setState({
      cgView,
    })
    this.props.searchRepository.updateSearchPage(pageKey, { cgView })
  }
  handleRowSelect = selectedRowId => {
    if (typeof selectedRowId !== 'undefined') {
      this.setSelected(selectedRowId)
      this.props.history.push(`/customer-groups/${selectedRowId}`)
    } else if (this.state.selectedGroup === selectedRowId) {
      return
    } else {
      this.setSelected(selectedRowId)
      this.table.reload()
      this.props.history.push(`/customer-groups`)
    }
  }
  setSelected = selected => {
    const {
      customerGroupRepository: {
        Table: { data },
      },
    } = this.props
    const selectedGroupData = data.find(item => item.id === selected)
    this.setState({
      selectedGroup: selected,
      selectedGroupData,
    })
    this.props.searchRepository.updateSearchPage(pageKey, { selectedGroup: selected })
  }

  render() {
    const { cgView, selectedGroupData } = this.state
    const {
      customerGroupRepository: {
        CRUD: { submitting },
        Table: { data, loading, totalRecordCount, originalData },
      },
      customerGroupDetailsRepository,
      customerGroupDisabledProductRepository,
      searchRepository,
    } = this.props
    const { isModalOpen, selectedGroup, editMode } = this.state
    const isSubmitting =
      submitting ||
      customerGroupDetailsRepository.CRUD.submitting ||
      customerGroupDisabledProductRepository.CRUD.submitting

    return (
      <ContentContainer
        promptOnExit={isSubmitting}
        promptOnExitIsConfirm={false}
        title="Customer Groups"
        actions={
          <>
            {selectedGroupData && !!selectedGroupData.id && (
              <>
                <Badge color="dark" className="text-uppercase p-2">
                  {STR_DEF[cgView]}
                </Badge>
                <ContextMenu
                  className="ml-1"
                  disabled={!cgView}
                  dropDownToggle={<IconButton icon="md-apps" />}
                  menus={[
                    {
                      text: STR_DEF.customer,
                      onClick: () => {
                        this.handleChangeView('customer')
                      },
                    },
                    {
                      text: STR_DEF.product,
                      onClick: () => {
                        this.handleChangeView('product')
                      },
                    },
                  ]}
                  plain
                />
              </>
            )}
            <Visible toAppAdminRole>
              <ContextMenu
                disabled={!cgView}
                dropDownToggle={<IconButton icon="md-more" />}
                menus={[
                  {
                    text: STR_DEF.customerActions.new,
                    onClick: () => {
                      this.toggleModal(false)
                    },
                  },
                  {
                    text: STR_DEF.customerActions.edit,
                    onClick: () => {
                      this.toggleModal(true)
                    },
                    hidden: !(selectedGroupData && selectedGroupData.id),
                  },
                ]}
                plain
              />
            </Visible>
          </>
        }
      >
        <Row>
          <Col lg={4}>
            <FormContainer>
              <ReactTable
                searchRepository={searchRepository}
                pageKey={pageKey}
                className="striped cell-vertical-center"
                columns={[
                  { accessor: 'name', Header: 'Groups' },
                  {
                    accessor: 'totalCustomers',
                    Header: 'Total Customers',
                    headerClassName: 'justify-content-end',
                    className: 'justify-content-end',
                    Cell: d => (d.original.totalCustomers ? formatNumber(d.original.totalCustomers, 0) : 0),
                  },
                ]}
                data={toJS(data)}
                loadData={params => this.loadData(params)}
                loading={loading}
                totalRecordCount={totalRecordCount}
                ref={ref => (this.table = ref)}
                totalPages={originalData.totalPages}
                handleRowClick={({ original }) => this.handleRowSelect(original.id)}
                getTrProps={(state, rowInfo, column) => {
                  return {
                    className:
                      selectedGroup && rowInfo && rowInfo.original && selectedGroup === rowInfo.original.id
                        ? 'tr-active'
                        : 'cursor-pointer',
                  }
                }}
                search
                sort={{ id: 'name' }}
              />
            </FormContainer>
          </Col>
          <Col lg={8}>
            {!!selectedGroupData && (
              <Route
                path={`${this.props.match.path}/:groupId`}
                render={props => (
                  <GroupDetails
                    setSelected={this.setSelected}
                    selectedGroupData={selectedGroupData}
                    groupId={selectedGroupData ? selectedGroupData.id : null}
                    toggleModal={this.toggleModal}
                    handleSelect={this.handleRowSelect}
                    cgView={cgView}
                    STR_DEF={STR_DEF}
                    {...props}
                  />
                )}
              />
            )}
          </Col>
        </Row>
        {isModalOpen ? (
          <CustomerGroupFormModal
            isModalOpen={isModalOpen}
            toggleModal={() => this.toggleModal(true)}
            redirect={this.handleRowSelect}
            table={this.table}
            editMode={editMode}
            selectedGroupData={selectedGroupData}
          />
        ) : null}
      </ContentContainer>
    )
  }
}

export default decorate(
  inject(
    'customerGroupRepository',
    'customerGroupDetailsRepository',
    'customerGroupDisabledProductRepository',
    'searchRepository'
  )(observer(CustomerGroups)),
  {}
)
