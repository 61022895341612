import React, { Component } from 'react'

import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

import { SHIPPING_METHOD_CUSTOMER_PROVIDED, SHIPPING_METHOD_PRIORITY_SHIPPING } from 'definitions'
import BlockMessage from 'components/Common/BlockMessage'
import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import Input from 'components/Common/Form/Input'
import RadioGroup from 'components/Common/Form/RadioGroup'
import ReactSelect from 'components/Common/Form/ReactSelect'

class ShippingCostField extends Component {
  render() {
    const { form, readOnly, onChange } = this.props
    return (
      <Input
        readOnly={readOnly}
        field={form.$('shippingMethod.totalShippingFee')}
        onChange={onChange}
        allowNegative={false}
        min={0}
      />
    )
  }
}

class ShippingMethodPanel extends Component {
  constructor(props) {
    super(props)
    this.fetchOrderShippingMethods()
    this.shippingInsuranceAmount = this.props.form.$('shippingMethod.shippingInsurance').value
  }
  componentWillMount() {
    const {
      form,
      storeRepository: {
        CRUD: { data },
      },
    } = this.props
    const selectedCustomer = form.$('customer').value
    if (data.shippingMethod && data.shippingMethod.hasOwnProperty('method')) {
      form.$('shippingMethod.method').set(data.shippingMethod.method)
    } else {
      if (
        selectedCustomer &&
        selectedCustomer.shippingMethod &&
        selectedCustomer.shippingMethod.hasOwnProperty('method')
      ) {
        form.$('shippingMethod.method').set(selectedCustomer.shippingMethod.method)
        if (selectedCustomer.shippingMethod.method === SHIPPING_METHOD_PRIORITY_SHIPPING) {
          form.$('shippingMethod.shippingCarrier').set(selectedCustomer.shippingMethod.shippingCarrier)
          form.$('shippingMethod.shippingService').set(selectedCustomer.shippingMethod.shippingService)
        }
      }
    }
  }
  fetchOrderShippingMethods = () => {
    this.props.commonRepository.getCommon('orderShippingMethods', 'orderShippingMethods', {})
  }
  handleRadioChange = () => {
    this.props.computeOrderSummary(true)
  }
  render() {
    const {
      form,
      commonRepository,
      storeRepository,
      computeOrderSummary,
      shippingRepository: { reactSelectSearchShippingServices, reactSelectSearchShippingCarriers },
      readOnly,
      isShippingApplicable,
    } = this.props
    const OrderRates = toJS(storeRepository.OrderRates).data
    const {
      orderShippingMethods: { data: orderShippingMethodsData = [] },
    } = toJS(commonRepository.Common)
    const shippingMethod = form.$('shippingMethod').value
    const selectedOrderShippingMethod = orderShippingMethodsData.find(item => item.value === shippingMethod.method)

    return (
      <Card title="Shipping Preference" className="mb-3" cardBodyClassName="pt-0 pb-2">
        {!!isShippingApplicable && (
          <BlockMessage
            message="Shipping method selection is not applicable"
            className
            style={{ top: '0px', left: '0px', width: '100%', height: '100%', textAlign: 'center' }}
          />
        )}
        <RadioGroup
          field={form.$('shippingMethod.method')}
          radios={orderShippingMethodsData}
          onChange={selected => {
            this.handleRadioChange()
            if (shippingMethod.value !== selected) {
              form.$('shippingMethod.shippingCarrier').set(null)
              form.$('shippingMethod.shippingService').set(null)
            }
          }}
          radioProps={{
            disabled: readOnly,
          }}
        />
        {shippingMethod.method ? (
          <>
            <ReactSelect
              readOnly={readOnly}
              field={form.$('shippingMethod.shippingCarrier')}
              serverSide
              innerKey={`shippingCarrier${(
                (selectedOrderShippingMethod && selectedOrderShippingMethod.id) ||
                ''
              ).trim()}`}
              search={params =>
                reactSelectSearchShippingCarriers(selectedOrderShippingMethod && selectedOrderShippingMethod.id, params)
              }
              options={{
                valueKey: 'value',
                labelKey: 'label',
                defaultOptions: true,
              }}
              onChange={selected => {
                if (shippingMethod.shippingCarrier && selected) {
                  if (shippingMethod.shippingCarrier.value !== selected.value) {
                    form.$('shippingMethod.shippingService').set(null)
                  }
                }
                computeOrderSummary(true)
              }}
            />
            <ReactSelect
              readOnly={readOnly}
              field={form.$('shippingMethod.shippingService')}
              serverSide
              innerKey={`shippingService${shippingMethod.shippingCarrier && shippingMethod.shippingCarrier.value}`}
              search={params =>
                reactSelectSearchShippingServices(
                  selectedOrderShippingMethod && selectedOrderShippingMethod.id,
                  shippingMethod.shippingCarrier && shippingMethod.shippingCarrier.id,
                  params
                )
              }
              options={{
                valueKey: 'value',
                labelKey: 'label',
                defaultOptions: true,
              }}
              onChange={() => computeOrderSummary(true)}
              showTooltip
            />
            {shippingMethod.method === SHIPPING_METHOD_CUSTOMER_PROVIDED ? (
              <>
                <Input field={form.$('shippingMethod.accountNo')} readOnly={readOnly} showTooltip />
                <Input field={form.$('shippingMethod.contactInfo')} readOnly={readOnly} showTooltip />
              </>
            ) : (
              <>
                <ShippingCostField
                  readOnly={readOnly}
                  form={form}
                  shippingTotal={OrderRates.shippingTotal}
                  onChange={() => {
                    form.$('manualShippingFeeInd').set(true)
                    computeOrderSummary(false)
                  }}
                />
                <Checkbox
                  disabled={readOnly}
                  field={form.$('shippingMethod.shippingInsuranceInd')}
                  onChange={event => {
                    form
                      .$('shippingMethod.shippingInsurance')
                      .set(event.target.checked ? this.shippingInsuranceAmount : '')
                    if (event.target.checked) {
                      computeOrderSummary(false, true, true)
                    } else {
                      computeOrderSummary()
                    }
                  }}
                />
                <Input
                  field={form.$('shippingMethod.shippingInsurance')}
                  disabled={!form.$('shippingMethod.shippingInsuranceInd').checked}
                  readOnly={readOnly}
                  onBlur={event => {
                    this.shippingInsuranceAmount = event.target.value ? event.target.value : ''
                    computeOrderSummary(false, true, false)
                  }}
                  allowNegative={false}
                  min={0}
                />
              </>
            )}
          </>
        ) : null}
      </Card>
    )
  }
}

export default inject('shippingRepository')(observer(ShippingMethodPanel))
