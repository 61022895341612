/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import UserServices from 'services/user'

class UserRepository extends BaseRepository {
  service: UserServices

  Export = defaults.CRUD

  constructor(service: UserServices) {
    super(service)
    this.service = service
  }

  reset = () => {
    // this.User.data = initialValues
  }

  export = async (payload, callback) => {
    this.Export.errors = []
    this.Export.loading = true
    const { data, errors } = await this.service.export(payload, { responseType: 'blob' })
    if (data && !errors.length) {
      this.Export.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.Export.loadingFailed = true
      this.Export.errors = errors
    }
    this.Export.loading = false
  }
}

export default decorate(UserRepository, {
  Export: observable,
})
