/* @flow */
import APIRepository, { jsonToUrlParams } from 'stores/APIRepository'
import BaseServices from './BaseServices'

class PaymentAccountServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/paymentAccounts'
    super(api, url)
    this.api = api
    this.url = url
  }
  getBankInfoBySelectedBank(country, payload) {
    return this.api.get(`${this.url}/countries/${country}`, payload)
  }

  getByCountryAndVat(country, vatInd, paymentAccount) {
    return this.api.get(
      `${this.url}/country/${country}/vatInd/${vatInd}?${jsonToUrlParams({
        ...(paymentAccount && { paymentAccount }),
      })}`
    )
  }
  getByCustomer(customerId, paymentAccount) {
    return this.api.get(
      `${this.url}/customer/${customerId}?${jsonToUrlParams({
        ...(paymentAccount && { paymentAccount }),
      })}`
    )
  }
}

export default PaymentAccountServices
