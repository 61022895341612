/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import Loader from 'components/Common/Loader'
import OrderForm from './OrderForm'
import queryString from 'query-string'
class SubscriptionEdit extends Component {
  constructor(props) {
    super(props)
    this.loadOrder()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadOrder()
    }

    if (this.props.match.params.orderId !== prevProps.match.params.orderId) {
      this.loadOrder()
    }
  }
  componentWillUnmount() {
    this.props.orderSubscriptionRepository.reset()
  }
  loadOrder = () => {
    const {
      match: { params },
      location,
      orderSubscriptionRepository: { getNewContractById, getById },
    } = this.props
    const qs = queryString.parse(location.search)
    const get = qs.contract || qs.loan ? getNewContractById : getById
    get(params.orderId)
  }

  render() {
    const {
      orderSubscriptionRepository: {
        CRUD: { data, loading },
      },
      orderSubscriptionRepository,
    } = this.props
    const orderNumber = data.orderNumber || data.tempOrderNumber || data.id
    return (
      <React.Fragment>
        {loading && !data.id ? (
          <Loader />
        ) : (
          <OrderForm
            {...this.props}
            breadcrumbs={[
              {
                label: 'Home',
                href: '/',
              },
              {
                label: 'Subscriptions',
                href: SUBSCRIPTIONS_PATH,
              },
              {
                label: orderNumber,
              },
            ]}
            data={data}
            title={orderNumber ? `# ${orderNumber}` : 'N/A'}
            step={2}
            orderRepository={orderSubscriptionRepository}
          />
        )}
      </React.Fragment>
    )
  }
}

export default inject('orderSubscriptionRepository')(decorate(observer(SubscriptionEdit), {}))
