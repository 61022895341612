import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'

import Form from 'components/Common/Form'
import Modal from 'components/Common/Modal'
import ReactSelect from 'components/Common/Form/ReactSelect'

class CCFormModal extends Component {
  form = null
  constructor(props) {
    super(props)
    const { data: { ccs = [], status } = {} } = props
    this.state = {}
    const fields = {
      ccs: {
        type: 'select',
        label: '',
        value: ccs.map(email => ({ label: email, value: email })),
        disabled: status === 'VOID',
      },
    }
    this.form = new Form(
      { fields },
      {
        name: 'CCMailingForm',
        hooks: {
          onSuccess: this.handleSubmit,
        },
      }
    )
  }
  handleSubmit = form => {
    const values = form.values()
    this.props.paymentRepository.patch(
      {
        id: this.props.data.id,
        ccs: values.ccs.map(val => val.value),
      },
      () => {
        this.props.onClose()
      }
    )
  }
  render() {
    const {
      open,
      onClose,
      paymentRepository: {
        CRUD: { loading },
      },
      data: { status } = {},
    } = this.props
    const isVoid = status === 'VOID'
    return (
      <Modal
        className={isVoid && 'void-payment-modal'}
        open={open}
        size="md"
        title="CC Mailing List"
        confirmLabel={'Update'}
        confirmIcon=""
        onConfirm={() => this.form.submit()}
        onClose={onClose}
        loading={loading}
        disabled={loading || isVoid}
      >
        <ReactSelect
          field={this.form.$('ccs')}
          creatable
          options={{ options: [], isMulti: true, isDisabled: isVoid }}
        />
      </Modal>
    )
  }
}

export default decorate(inject('paymentRepository')(observer(CCFormModal)), {})
