/* @flow */
import { inject, observer } from 'mobx-react'

import CustomerForm from './CustomerForm'
import queryString from 'query-string'
import React, { Component } from 'react'

class CustomerAdd extends Component {
  render() {
    const { redirectTo } = queryString.parse(this.props.location.search)

    return <CustomerForm {...this.props} title="Add New Customer" redirectAfterCreateTo={redirectTo} />
  }
}

export default inject('customerRepository')(observer(CustomerAdd))
