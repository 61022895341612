export default {
  onInit() {
    // eslint-disable-next-line
    this.each(field => field.set('bindings', 'BootstrapField'))
  },

  onSuccess(form) {},

  onError(form) {},

  onSubmit(instance) {},

  onClear(instance) {},

  onReset(instance) {},

  onChange(field) {},

  onFocus: field => {},

  onBlur: field => {},
}
