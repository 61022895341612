import React from 'react'

import { CUSTOMERS_PATH } from 'definitions'

export const getCustomerOptions = () => {
  const sort = { id: 'createdDate', desc: true }
  const querySort = `${sort.id},${sort.desc ? 'desc' : 'asc'}`
  const getRowUrl = ({ original }) => `${CUSTOMERS_PATH}/${original.id}`

  return {
    title: 'Customers',
    moreUrl: CUSTOMERS_PATH,
    sort: querySort,
    tableOptions: {
      enableRowLink: true,
      getRowUrl,
      columns: [
        {
          accessor: 'group',
          Header: '',
          width: 20,
          getProps: (state, { original = {} } = {}) => {
            const group = original.groups && original.groups.length > 0 ? original.groups[0] : {}
            return {
              style: {
                padding: 0,
                borderLeft: `5px solid ${group.name === 'General' ? '#70D776' : '#AB6694'}`,
              },
            }
          },
          fixed: 'leftmost',
          Cell: () => '',
          sortable: false,
        },
        { accessor: 'bpCode', Header: 'BP No.', width: 120 },
        {
          accessor: 'name',
          Header: 'Customers',
          width: '25%',
          Cell: ({ original }) => (
            <span title={original.name} className="pr-2 ellipsis row-clickable">
              {original.name}
            </span>
          ),
        },
        // { accessor: 'userLogin', Header: 'User Login', width: '10%' },
        {
          accessor: 'defaultStore',
          Header: 'Default Store',
          Cell: ({ original }) => (original.defaultStore ? original.defaultStore.name : 'N/A'),
          width: '10%',
        },
        {
          accessor: 'groups',
          Header: 'Group',
          Cell: ({ original }) =>
            original.groups
              ? original.groups.slice(0, 1).map((item, index) => (
                  <div key={index} style={{ marginBottom: '2px' }}>
                    {item.name}
                  </div>
                ))
              : 'N/A',
          width: '10%',
        },
      ],
      sort,
    },
  }
}
