import React, { Component } from 'react'

import { Button, Col, Row } from 'reactstrap'
import { CustomInput } from 'reactstrap'
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import CustomerTable from './CustomerTable'
import ReactSelect from 'components/Common/Form/ReactSelect'
import TableFilterButtons from 'components/Common/TableFilterButtons'
import ToasterNotify from 'helpers/ToasterNotify'

const pageKey = 'customers'

class Customers extends Component {
  table = React.createRef()
  state = {
    itemToDelete: {},
    selected: [],
    bulkAction: null,
    filters: {
      status: 'ALL',
      type: 'ALL',
    },
  }
  constructor(props) {
    super(props)
    this.initializeDefaultFilters()
  }
  initializeDefaultFilters = () => {
    const { searchRepository } = this.props
    const defaultFilters = searchRepository.Pages[pageKey]?.filters
    if (defaultFilters) {
      this.state.filters = defaultFilters
    }
  }
  componentDidMount() {
    const { history } = this.props
    const { search } = queryString.parse(this.props.location.search)
    search && history.replace('/customers', {})
  }
  handleSelected = ({ selection = {} }) => {
    this.setState({
      selected: Object.keys(selection),
    })
  }
  handleFilterChange = filters => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filters,
        },
      },
      () => {
        this.props.searchRepository.updateSearchPage(pageKey, { filters: this.state.filters })
        this.table.current.reload()
      }
    )
  }
  handleSubmitBulkAction = () => {
    const { bulkAction, selected } = this.state
    const { customerRepository } = this.props
    ToasterNotify.confirm({
      message: `Are you sure you want to update the selected record(s) to ${bulkAction}?`,
      title: 'Warning',
      onConfirm: async () => {
        await customerRepository.bulkUpdateStatus(
          { status: bulkAction.toUpperCase(), customers: selected.map(id => ({ id })) },
          () => {
            this.table.current.reload()
          }
        )
      },
    })
  }
  handlePatchStatus = (e, id) => {
    this.props.customerRepository.patch(
      {
        status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
        id,
      },
      () => this.table.current.reload()
    )
  }
  render() {
    const { selected, filters, bulkAction } = this.state
    const { search } = queryString.parse(this.props.location.search)

    return (
      <ContentContainer title="Customers">
        <Row className="mb-3">
          <Col lg={6}>
            <TableFilterButtons
              filterKey="type"
              filters={[
                {
                  label: 'All',
                  value: 'ALL',
                  color: '#828282',
                  selected: this.state.filters?.type == 'ALL',
                },
                {
                  label: 'Partner',
                  value: 'PARTNER',
                  color: '#AB6694',
                  selected: this.state.filters?.type == 'PARTNER',
                },
                {
                  label: 'Individual',
                  value: 'INDIVIDUAL',
                  color: '#70D776',
                  selected: this.state.filters?.type == 'INDIVIDUAL',
                },
              ]}
              onChange={this.handleFilterChange}
            />
          </Col>
          <Col lg={6} className="d-flex justify-content-end">
            <CustomInput
              type="switch"
              id={`status-customer`}
              label="Show Inactive"
              checked={filters.status === 'ALL'}
              onChange={e => {
                this.handleFilterChange({ status: e.target.checked ? 'ALL' : 'ACTIVE' })
              }}
            />
          </Col>
        </Row>
        <CustomerTable
          innerRef={this.table}
          pageKey={pageKey}
          multiSelect={allowedToAppAdminRole()}
          selectionChanged={this.handleSelected}
          filters={filters}
          fixedColumns
          handlePatchStatus={this.handlePatchStatus}
          searchCriteria={search}
          actions={
            <Visible toAppAdminRole>
              <div className="d-flex justify-content-end align-items-center mb-1">
                <ReactSelect
                  plain={true}
                  className="mr-2 table-select"
                  options={{
                    placeholder: 'Bulk Action',
                    options: ['Active', 'Inactive'].map(status => ({
                      value: status,
                      label: status,
                    })),
                  }}
                  disabled={!selected.length}
                  onChange={vals => {
                    this.setState({ bulkAction: vals ? vals.value : '' })
                  }}
                />
                <Button
                  size="sm"
                  color="dark"
                  type="button"
                  disabled={!bulkAction}
                  onClick={this.handleSubmitBulkAction}
                >
                  Submit
                </Button>
              </div>
            </Visible>
          }
        />
      </ContentContainer>
    )
  }
}

export default decorate(inject('customerRepository', 'searchRepository')(observer(Customers)), {})
