/* @flow */
import React, { Component } from 'react'

import { Badge, Button, Col, Input, InputGroup, InputGroupAddon, Row, Tooltip } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import CCFormModal from './CCFormModal'
import ContentContainer from 'components/Common/ContentContainer'
import ContextMenu from 'components/Common/ContextMenu'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import ReactTable from 'components/Common/ReactTable'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

import { formatNumber, getDays, getFormattedDateString, getFormattedDateTimeString } from 'helpers/Formatters'
import { titleize } from 'helpers/String'
import { Visible } from 'helpers/Auth'
import ToasterNotify from 'helpers/ToasterNotify'

import amex from 'images/icon_amex.svg'
import ErrorsList from 'components/Common/ErrorsList'
import mastercard from 'images/icon_mastercard.svg'
import visa from 'images/icon_visa.svg'

class PaymentEdit extends Component {
  ccIcons = {
    visa,
    amex,
    mastercard,
  }
  constructor(props) {
    super(props)
    this.state = {
      isCCFormModalOpen: false,
      isTooltipOpen: false,
    }
    this.getPaymentDetails()
  }
  componentWillUnmount() {
    this.props.paymentRepository.reset()
  }
  getPaymentDetails = () => {
    const {
      match: { params },
      paymentRepository,
    } = this.props
    paymentRepository.getById(params.id)
  }
  copyLink = () => {
    const input = document.getElementById('htmlLink')
    input.select()
    document.execCommand('copy')
    this.setState(
      {
        isTooltipOpen: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            isTooltipOpen: false,
          })
        }, 3000)
      }
    )
  }
  handleResendEmail = () => {
    const {
      paymentRepository: {
        CRUD: { data },
      },
    } = this.props
    ToasterNotify.confirm({
      message: 'Do you confirm to resend the payment notification to the relevant email addresses?',
      title: 'Warning',
      onConfirm: async () => {
        this.props.paymentRepository.resendEmail(data.id, ({ data, errors }) => {
          if (data && !errors.length) {
            ToasterNotify.alert({ message: 'Email was successfully sent.' })
          } else {
            ToasterNotify.alert({ message: `Failed to Resend Email: ${errors[0].message}`, color: 'error' })
          }
        })
      },
    })
  }
  handleVoidPayment = () => {
    const {
      paymentRepository: {
        CRUD: { data },
      },
    } = this.props
    ToasterNotify.confirm({
      message: 'Do you wish to VOID the payment? This action cannot be undone.',
      title: 'Warning',
      onConfirm: async () => {
        this.props.paymentRepository.voidPayment(data.id, ({ data, errors }) => {
          if (data && !errors.length) {
            ToasterNotify.alert({ message: 'Payment was successfully voided.' })
          } else {
            ToasterNotify.alert({ message: `Failed to Void Payment: ${errors[0].message}`, color: 'error' })
          }
        })
      },
    })
  }
  handleToggleModal = () => {
    this.setState({
      isCCFormModalOpen: !this.state.isCCFormModalOpen,
    })
  }
  render() {
    const {
      paymentRepository: {
        CRUD: { loading, data, errors },
        Payment,
        Void,
      },
    } = this.props
    const isPendingPayment = data.status === 'PENDING_PAYMENT'
    const isSA_ORDER = data.source === 'SA_ORDER'
    const isPaid = data.status === 'PAID'
    const isVoid = data.status === 'VOID'
    const paymentMethod = (data.cardBrand || data.cardLast4) && (
      <>
        {data.cardBrand ? <img src={this.ccIcons[data.cardBrand]} alt="" /> : null} **** **** **** {data.cardLast4}
      </>
    )
    const resetButtonText = !isPaid ? 'Resend Payment Request' : 'Resend Receipt'
    return loading ? (
      <Loader />
    ) : errors.length ? (
      <ContentContainer>
        <ErrorsList errors={errors} />
      </ContentContainer>
    ) : (
      <ContentContainer
        title={
          <>
            {data.invoiceNumber}{' '}
            {!(isPaid || isVoid) && (
              <small className="duein" style={{ marginLeft: 10, fontSize: 12, color: '#666' }}>
                <i> Due in {getDays(data.dueDate)} days</i>
              </small>
            )}
          </>
        }
        actions={
          <>
            <Visible toOrderProcessRole>
              <Badge
                className={`text-uppercase d-flex justify-content-center font-size-18 font-weight-bold ${
                  isPaid || isVoid ? '' : 'text-danger'
                }`}
                color={isPaid || isVoid ? 'light' : ''}
              >
                {!(isPaid || isVoid) && (
                  <i
                    className="icon ion-md-warning"
                    style={{
                      width: '19px',
                      height: '19px',
                      fontSize: '14pt',
                      marginTop: '-5px',
                    }}
                  />
                )}
                <span className="ml-2 mr-2">{titleize(data.status, '_')}</span>
              </Badge>
              {!isVoid && (
                <ContextMenu
                  disabled={loading}
                  dropDownToggle={<IconButton icon="md-more" />}
                  menus={[
                    {
                      text: resetButtonText,
                      onClick: () => this.handleResendEmail(),
                    },
                    {
                      text: 'Void',
                      onClick: () => this.handleVoidPayment(),
                      hidden: isPaid || isVoid,
                    },
                  ]}
                  plain
                />
              )}
            </Visible>
          </>
        }
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: 'Online Payments',
            href: '/payments',
          },
          {
            label: data.invoiceNumber,
          },
        ]}
      >
        <CardStyled>
          <Row>
            <Col lg={8} className="d-flex no-padding">
              <Col lg={2}>
                <ReadOnlyField value={data.orderId} label="Order ID" showTooltip />
              </Col>
              <Col lg={3}>
                <ReadOnlyField value={data.paymentTo} label="Payment To" showTooltip />
              </Col>
              <Col lg={2}>
                <label>&nbsp;</label>
                <div className="viewcc">
                  {/*eslint-disable-next-line */}
                  <a href="javascript:void(0);" className="text-primary" onClick={this.handleToggleModal}>
                    [ View CC List ]
                  </a>
                </div>
              </Col>
              <Col lg={1}>
                <ReadOnlyField value={data.amountCurrency} label="Currency" showTooltip />
              </Col>
              <Col lg={2}>
                <ReadOnlyField value={formatNumber(data.amount)} label="Amount Due" showTooltip />
              </Col>
              <Col lg={2}>
                <ReadOnlyField value={getFormattedDateString(data.dueDate)} label="Due Date" showTooltip />
              </Col>
            </Col>
            <Col lg={4} className="d-flex no-padding">
              <Col lg={6}>
                <ReadOnlyField value={paymentMethod} label="Payment Method" showTooltip />
              </Col>
              <Col lg={6}>
                <ReadOnlyField value={getFormattedDateTimeString(data.paymentDate)} label="Paid On" showTooltip />
              </Col>
            </Col>
            <Col lg={12} className="d-flex no-padding">
              <Col lg={3}>
                <ReadOnlyField value={(data.customer && data.customer.name) || ''} label="Customer" showTooltip />
              </Col>
              <Col lg={3}>
                <ReadOnlyField
                  value={(data.paymentAccount && data.paymentAccount.name) || ''}
                  label="Stripe Public Business Name"
                  showTooltip
                />
              </Col>
            </Col>
          </Row>
        </CardStyled>
        <Card className="invoice-panel" style={{ margin: '20px 0' }} title="Payment Details">
          <ReactTable
            className="striped cell-vertical-center"
            columns={[
              { accessor: 'orderNumber', Header: 'Order Id', sortable: false, width: 150 },
              { accessor: 'description', Header: 'Description', sortable: false, width: '50%', className: 'text-wrap' },
              {
                accessor: 'quantity',
                Header: 'QTY',
                sortable: false,
                width: 150,
                className: 'justify-content-center',
                headerClassName: 'justify-content-center',
                Cell: ({ original }) => {
                  return original.quantity
                },
              },
              {
                accessor: 'amount',
                Header: 'Unit Price',
                sortable: false,
                width: 150,
                className: 'justify-content-end',
                headerClassName: 'text-right',
                Cell: ({ original }) => {
                  return formatNumber(original.amount)
                },
              },
              {
                accessor: 'amount',
                Header: 'Amount',
                sortable: false,
                width: 150,
                className: 'justify-content-end',
                headerClassName: 'text-right',
                Cell: ({ original }) => {
                  return formatNumber(original.amount)
                },
              },
            ]}
            data={[
              {
                orderNumber: data.orderId,
                description: data.description,
                quantity: data.quantity,
                amount: data.amount,
              },
            ]}
            loading={loading}
            totalRecordCount={0}
            showRowPerPage={false}
          />
          <Row style={{ fontSize: 11 }}>
            <Col lg={10}>&nbsp;</Col>
            <Col lg={2}>
              <Row style={{ marginBottom: 10 }}>
                <Col lg={6} className="text-right">
                  Subtotal
                </Col>
                <Col
                  lg={6}
                  className="text-right"
                  style={{
                    padding: '0 21px',
                  }}
                >
                  {formatNumber(data.amount)}
                </Col>
              </Row>
              <Row style={{ borderBottom: '1px solid #ccc', marginBottom: 10, paddingBottom: 10 }}>
                <Col lg={6} className="text-right font-weight-bold">
                  Amount due
                </Col>
                <Col
                  lg={6}
                  className="text-right font-weight-bold"
                  style={{
                    padding: '0 21px',
                  }}
                >
                  {data.amountCurrency} {formatNumber(data.amount)}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <Row className="mt-5">
          <Col lg={6}>
            {!(isPendingPayment && isSA_ORDER) && !isVoid && (
              <InputGroupStyled>
                <InputGroupAddon addonType="prepend">
                  <Button color="white" disabled>
                    <i className="icon ion-md-link" />
                  </Button>
                </InputGroupAddon>
                <Input
                  id="htmlLink"
                  value={`${process.env.REACT_APP_BASE_URL}/email/html/payment/${
                    data.status === 'PAID' ? 'pd' : 'pndng'
                  }/${data.tokenId}.html`}
                  readOnly
                />
                <InputGroupAddon addonType="append">
                  <Button color="primary" id="tooltipCopy" onClick={this.copyLink}>
                    <i className="icon ion-md-copy" />
                  </Button>
                  <Tooltip
                    trigger="manual"
                    placement="right"
                    isOpen={this.state.isTooltipOpen}
                    target="tooltipCopy"
                    toggle={() => {
                      this.setState({ isTooltipOpen: !this.state.isTooltipOpen })
                    }}
                    autohide
                  >
                    Copied!
                  </Tooltip>
                </InputGroupAddon>
              </InputGroupStyled>
            )}
          </Col>
          <Visible toOrderProcessRole>
            <Col lg={6} className="d-flex justify-content-end">
              {!isVoid && !isPaid && (
                <Button
                  color="primary"
                  className="mr-3 pl-5 pr-5"
                  disabled={Void.loading}
                  onClick={this.handleVoidPayment}
                >
                  {Void.loading ? 'Please wait...' : 'Void'}
                </Button>
              )}{' '}
              {!isVoid && (
                <Button
                  color="primary"
                  className="pl-3 pr-3"
                  disabled={Payment.loading}
                  onClick={this.handleResendEmail}
                >
                  {Payment.loading ? 'Sending...' : resetButtonText}
                </Button>
              )}
            </Col>
          </Visible>
          <Col lg={12} className="d-flex mt-5">
            <small>{`Created by ${data.createdBy} ${getFormattedDateTimeString(data.createdDate)}`}</small>
          </Col>
        </Row>
        <CCFormModal open={this.state.isCCFormModalOpen} onClose={this.handleToggleModal} data={data} />
      </ContentContainer>
    )
  }
}

const CardStyled = styled(Card)`
  .form-group {
    label {
      color: rgb(255, 184, 28);
      margin-bottom: 0px;
      font-size: 12px;
    }
    .form-control {
      font-size: 20px;
      font-weight: bold;
      height: calc((1.2em + 0.75rem) + 3px);
      padding: 0.3rem 0rem;
    }
  }

  label,
  .form-control,
  .viewcc {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .viewcc {
    width: 100%;
  }
  .no-padding {
    padding: 0;
  }
`

const InputGroupStyled = styled(InputGroup)`
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  height: 40px !important;
  .form-control {
    font-size: 14px;
    height: 40px;
    background: #fff !important;
    border-color: #fff !important;
  }
  .input-group-prepend {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    button {
      transform: rotate(-45deg);
      background: none !important;
      border-color: transparent !important;
      padding: 0.3rem 0.75rem;
    }
  }
  .input-group-append {
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    height: 40px;
    button {
      padding: 0.3rem 0.75rem;
    }
  }
  i {
    font-size: 20px;
  }
`

export default inject('paymentRepository')(observer(PaymentEdit))
