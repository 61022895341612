export const capitalize = str => [str[0].toUpperCase(), str.slice(1).toLowerCase()].join('')
export const titleize = (str, splitBy = '-') => str && str.split(splitBy).map(capitalize).join(' ')

export const prettyPrintJson = (value, space = 2) => {
  return value && JSON.stringify(JSON.parse(JSON.stringify(value)), null, space)
}

export const toInt = value => (value && typeof value === 'string' ? parseInt(value.replace(/,/g, ''), 10) : value)

export const toDecimal = value => (value && typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : value)
