/* @flow */

import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import CustomerGroupServices from 'services/customerGroup'

class CustomerGroupRepository extends BaseRepository {
  service: CustomerGroupServices
  constructor(service: CustomerGroupServices) {
    super(service)
    this.service = service
  }
  Customers = {
    ...defaults.Table,
  }
  getAvailableCustomer = async (payload, id) => {
    this.Customers.loading = true
    const { data, errors } = await this.service.getAvailableCustomer(payload, id)
    if (data && !errors.length) {
      this.Customers = {
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
      }
    }
    this.Customers.loading = false
  }
}

export class CustomerGroupDetails extends BaseRepository {
  constructor(service) {
    super(service)
    this.service = service
  }
}

class CustomerGroupDisabledProduct extends BaseRepository {
  constructor(service: CustomerGroupDisabledProductServices) {
    super(service)
    this.service = service
  }
  ProductTable = {
    ...defaults.Table,
  }
  fetchProductTable = async (params, id = null, callback) => {
    this.ProductTable.loading = true
    const { data, errors } = await this.service.fetchProductTable(params, id)
    if (data && !errors.length) {
      this.ProductTable = {
        ...this.ProductTable,
        originalData: data,
        data: data.content,
        totalRecordCount: data.totalElements,
        loading: false,
      }
      this.ProductTable.loading = false
      callback && callback(data)
    } else {
      this.ProductTable.loading = false
    }
  }
}

export const CustomerGroupDisabledProductRepository = decorate(CustomerGroupDisabledProduct, {
  ProductTable: observable,
})

export default decorate(CustomerGroupRepository, {
  Customers: observable,
  // CustomerGroups: observable,
})
