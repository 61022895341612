import React from 'react'

import { CustomInput, FormGroup, FormText } from 'reactstrap'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const Checkbox = ({
  field,
  subLabel = '',
  onChange = null,
  className = '',
  inputClassName = '',
  plain,
  label: propsLabel,
  ...rest
}) => {
  const { name = '', type, value } = field
  const hasError = !!((field.touched || field.submitting || !field.isValid) && field.error)
  const Wrapper = plain ? React.Fragment : FormGroup
  const label = propsLabel || field.label

  return (
    <Wrapper {...(!plain ? { className } : null)}>
      <CustomInputStyled
        {...field.bind({
          name,
          type,
        })}
        {...(onChange && {
          onChange: val => {
            field.onChange(val)
            if (onChange) onChange(val)
          },
        })}
        invalid={hasError}
        label={label}
        checked={value}
        className={inputClassName}
        {...rest}
      />
      {!plain && subLabel ? <small>{subLabel}</small> : null}
      {!plain && hasError ? <FormText color="danger">{field.error}</FormText> : null}
    </Wrapper>
  )
}

const CustomInputStyled = styled(CustomInput)`
  &.custom-control {
    margin-top: 0.375rem;

    input:disabled[readonly] {
      & + .custom-control-label {
        &::before {
          color: inherit;
          background-color: #fff;
        }
        &::after {
          color: inherit;
        }
      }
      &:checked + .custom-control-label {
        &::before {
          background-color: ${props => props.theme.primary};
        }
      }
    }
  }
`

export default observer(Checkbox)
