import { CardHeader, CardTitle, Col, Collapse, CustomInput, Row } from 'reactstrap'
import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import Card from 'components/Common/Card'
import Checkbox from 'components/Common/Form/Checkbox'
import IconButton from 'components/Common/Buttons/IconButton'
import Input from 'components/Common/Form/Input'
import ReadOnlyField from 'components/Common/Form/ReadOnlyField'

class CustomOptionGroup extends Component {
  state = {
    isOpen: this.props.isOpen,
  }
  fields = []

  constructor(props) {
    super(props)

    const { form, customOption } = props

    // Generate custom option value fields
    if (customOption.values) {
      customOption.values.forEach((customOptionValue, index) => {
        const field = form.$('customOptions').add()
        this.fields.push(field)

        const fieldId = `${customOptionValue.id}${index}`
        field.add({
          key: 'selected',
          type: 'checkbox',
          value: true,
        })
        field.add({
          key: 'id',
          value: fieldId,
        })
        field.add({
          key: 'productCode',
          type: 'text',
          value: customOptionValue.productCode,
          validators: [
            fieldData => this.validateUniqueness(fieldData, fieldId, field.$('selected').value, 'Product Code'),
          ],
        })
        field.add({
          key: 'name',
          type: 'text',
          value: customOptionValue.name,
          rules: 'required',
        })
        field.add({
          key: 'timePeriod',
          value: customOptionValue.timePeriod,
        })
        field.add({
          key: 'groupId',
          value: customOption.id,
        })
        field.add({
          key: 'group',
          value: customOption,
        })
      })
    }
  }

  validateUniqueness = (fieldData, id, selected, label) => {
    const { field, form } = fieldData
    if (!field.value) {
      return [false, `${label} is required.`]
    } else {
      const values = form.values()
      const valid = !values.customOptions.some(
        item => selected && item.selected && item[field.key] === field.value && item.id !== id
      )
      return [valid, `${field.value} already exists.`]
    }
  }

  toggleCollapse = () => this.setState({ isOpen: !this.state.isOpen })

  toggleSelection = (customOptionId, selected) => {
    this.fields.forEach(field => {
      if (field.$('groupId').value === customOptionId) {
        field.$('selected').set(selected)
      }
    })
    this.props.onSelectionChanged()
  }

  handleCustomOptionSelectionChanged = () => {
    const allSelected = this.fields.every(field => !!field.$('selected').value)
    this.checkboxSelectAllRef.checked = allSelected
    this.props.onSelectionChanged()
  }

  render() {
    const { customOption } = this.props

    return (
      <CardStyled className="mb-2">
        <CardHeader onClick={this.toggleCollapse}>
          <CardTitle>{customOption.title}</CardTitle>
          <IconButton icon={this.state.isOpen ? 'ios-arrow-down' : 'ios-arrow-forward'} color="light" />
        </CardHeader>
        <Collapse isOpen={this.state.isOpen} className="pt-1">
          <Row className="pt-2 pb-1">
            <Col xs="auto" className="pr-0">
              <CustomInput
                innerRef={ref => (this.checkboxSelectAllRef = ref)}
                type="checkbox"
                id={`checkboxCustomOptionSelectAll${customOption.id}`}
                className="mr-2"
                cssModule={{ 'custom-control-input': 'custom-control-input checkbox-custom-option-select-all' }}
                title="Toggle Selection"
                defaultChecked
                onChange={event => this.toggleSelection(customOption.id, event.target.checked)}
              />
            </Col>
            <Col className="column-title col font-weight-bold">Product Code</Col>
            <Col className="column-title col font-weight-bold">Product Name</Col>
          </Row>
          {this.fields.map((field, index) => (
            <Row key={index}>
              <Col xs="auto" className="pr-0">
                <Checkbox
                  field={field.$('selected')}
                  onChange={event => this.handleCustomOptionSelectionChanged(event.target.value)}
                />
              </Col>
              <Col>
                <ReadOnlyField value={field.$('productCode').value} readOnlyValueClassName="border-0" />
              </Col>
              <Col>
                <Input field={field.$('name')} />
              </Col>
            </Row>
          ))}
        </Collapse>
      </CardStyled>
    )
  }
}

const CardStyled = styled(Card)`
  &&& {
    .card-header {
      align-items: center;
      padding: 0;
      cursor: pointer;

      .card-title {
        text-transform: unset;
      }
    }
    .column-title {
      font-size: 80%;
    }
  }
`

export default observer(CustomOptionGroup)
