import React, { Component } from 'react'

import { observer } from 'mobx-react'
import { STRING_MAP } from './defaults'
import RuleList from './index'
import SelectField from './SelectField'

import {
  handleConditionChange,
  handleCriteriaChange,
  handleDeleteCondition,
  handleNewRule,
  toggleCondition,
  toggleCreate,
  toggleCriteria,
} from './itemFunctions'
import { Visible } from 'helpers/Auth'
import IconButton from 'components/Common/Buttons/IconButton'
import ToasterNotify from 'helpers/ToasterNotify'

const ListItem = ({ children }) => <div className="combi-list-item">{children}</div>

class CombinationRule extends Component {
  getDefaultConditions = () => {
    const { AttributeCollection } = this.props
    return [
      {
        label: STRING_MAP.condition_combination,
        value: 'condition_combination',
      },
      {
        label: AttributeCollection.loading ? `Loading Product Attributes` : `Product Attributes`,
        values: Object.keys(AttributeCollection.data).reduce((list, key) => {
          return list.concat([
            {
              label: key,
              disabled: true,
              className: 'group',
            },
            ...AttributeCollection.data[key].map(attrib => ({
              label: attrib.attributeLabel,
              value: attrib.attributeName,
              attributeType: attrib.attributeType,
              className: 'attribute',
            })),
          ])
        }, []),
      },
    ]
  }
  handleDeleteCondition = (parentCriteria, item) => {
    const { primaryIndex, index, onDeleteParentCondition } = this.props
    const doDelete = primaryIndex ? onDeleteParentCondition : () => handleDeleteCondition(parentCriteria, index)

    if (item.criterias.length) {
      ToasterNotify.confirm({
        message: `Are you sure you want to remove this condition?`,
        title: 'Warning',
        onConfirm: doDelete,
      })
    } else {
      doDelete()
    }
  }
  render() {
    const {
      item,
      form,
      parentCriteria,
      primaryIndex = null,
      commonRepository: {
        Common: { pricingRuleConditions, pricingRuleConditionValues },
      },
      AttributeCollection,
      onAttributeSearch,
    } = this.props

    return (
      <ListItem>
        <div className="row-item">
          <span>If</span>
          <SelectField
            onBlur={() => toggleCriteria(item)}
            onChange={selected => handleCriteriaChange(selected.value, item)}
            value={item.condition.condition}
            options={pricingRuleConditions.data}
          />
          <span>of these conditions are</span>
          <SelectField
            onBlur={() => toggleCondition(item)}
            onChange={selected => handleConditionChange(selected.value, item)}
            value={item.condition.value}
            options={pricingRuleConditionValues.data}
          />
          <Visible toOrderProcessRole>
            {primaryIndex === null || primaryIndex > 0 ? (
              <IconButton icon="ios-trash" onClick={() => this.handleDeleteCondition(parentCriteria, item)} />
            ) : null}
          </Visible>
        </div>
        {!!item.criterias.length && (
          <RuleList
            criteria={item.criterias}
            parentCriteria={item}
            form={form}
            AttributeCollection={AttributeCollection}
            onAttributeSearch={onAttributeSearch}
          />
        )}
        {item.isCreate ? (
          <ul component="ul" className="combi-create">
            <ListItem>
              <SelectField
                onChange={selected => {
                  handleNewRule(selected, item)
                }}
                options={this.getDefaultConditions()}
                placeholder={`Please choose a condition to add`}
                value={''}
              />
            </ListItem>
          </ul>
        ) : (
          <ul className="bottom-controls">
            <ListItem>
              <IconButton className="btn-add ml-1" onClick={() => toggleCreate(item)}></IconButton>
            </ListItem>
          </ul>
        )}
      </ListItem>
    )
  }
}

export default observer(CombinationRule)
