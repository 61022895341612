/* @flow */
import React, { Component } from 'react'

import ContentContainer from 'components/Common/ContentContainer'
import ErrorsList from 'components/Common/ErrorsList'

class InvalidForm extends Component {
  render() {
    const { id, type, title, breadcrumbs } = this.props

    return (
      <ContentContainer title={title} breadcrumbs={breadcrumbs}>
        <ErrorsList
          errors={[{ message: `'${id}' is not a valid ${type === 'codes' ? 'coupon code' : 'price rule'} record.` }]}
        />
      </ContentContainer>
    )
  }
}

export default InvalidForm
