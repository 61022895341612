/* @flow */
import React, { Component } from 'react'

import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap'
import { decorate } from 'mobx'
import { head } from 'lodash'
import { inject, observer } from 'mobx-react'
import { Visible } from 'helpers/Auth'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import CategoryDetails from './CategoryDetails'
import CategoryFormModal from './CategoryFormModal'
import CategoryList from './CategoryList/'
import CommentSidePanel from 'components/Settings/CommentSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import FormContainer from 'components/Common/Form/Container'
import IconButton from 'components/Common/Buttons/IconButton'
import Loader from 'components/Common/Loader'
import ToasterNotify from 'helpers/ToasterNotify'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'

const pageKey = 'categories'

class Category extends Component {
  state = {
    selected: {},
    selectedParent: {},
    selectedSiblings: [],
    isModalOpen: false,
    type: 'root',
    dropdownOpen: false,
    isOpenDeleteAlert: false,
  }

  constructor(props) {
    super(props)
    this.initializeDefaults()
  }
  initializeDefaults = () => {
    const { searchRepository } = this.props
    const category = searchRepository.Pages[pageKey]?.category
    if (category) {
      this.state.selected = category
    }
  }

  componentDidMount() {
    const {
      categoryRepository: { Table },
    } = this.props
    this.fetchCategories().then(() => {
      if (!this.state.selected?.id) {
        this.setSelected(head(Table.data), {}, Table.data)
      }
    })
  }

  fetchCategories = () => {
    return new Promise((resolve, reject) => {
      this.props.categoryRepository.fetchTable().then(resolve)
    })
  }

  setSelected = (item = {}) => {
    const { selected = {} } = this.state
    let newSelected
    if (item.id !== selected.id) {
      newSelected = item
    } else if (item.id === selected.id) {
      newSelected = { selected: item }
    } else {
      newSelected = {}
    }

    this.props.searchRepository.updateSearchPage(pageKey, { category: newSelected })
    this.setState({ selected: newSelected })
  }
  handleDelete = () => {
    const {
      categoryRepository,
      categoryRepository: {
        Table: { data },
      },
    } = this.props
    let { selected } = this.state

    categoryRepository.delete(selected.id).then(() => {
      const find = (id, list) => {
        for (let ctr = 0; ctr <= list.length - 1; ctr++) {
          const item = list[ctr]

          if (item.id === id) {
            this.setSelected(item)
            item.subCategories.splice(
              item.subCategories.findIndex(i => i.id === selected.id),
              1
            )
            if (item.subCategories.length === 0) {
              delete item.subCategories
            }
          }
          if (item.subCategories) {
            find(id, item.subCategories)
          }
        }
      }
      if (!selected.parent) {
        data.splice(
          data.findIndex(i => i.id === selected.id),
          1
        )
        this.setSelected(head(data))
      } else {
        find(selected.parent, data)
      }
    })
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  toggleDeleteAlert = () => {
    const { selected } = this.state
    ToasterNotify.confirm({
      message: `Are you sure you want to delete ${selected.name} category?`,
      title: 'Warning',
      onConfirm: this.handleDelete,
    })
  }

  toggleModal = (type = 'root') => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      type: type,
    })
  }
  render() {
    const { selected, isModalOpen, type } = this.state
    const {
      categoryRepository: {
        CRUD: { submitting },
        Table: { loading, data },
        Products,
      },
      classes = {},
    } = this.props
    const dropdownOptions = [
      {
        title: 'New Root Category',
        onClick: () => this.toggleModal('root_category'),
      },
      {
        title: 'New Subcategory',
        onClick: () => this.toggleModal('sub_category'),
        disabled: !selected.id,
      },
      {
        title: 'Edit Category',
        onClick: () => this.toggleModal('edit_category'),
        disabled: !selected.id,
      },
      {
        title: 'Delete Category',
        onClick: this.toggleDeleteAlert,
        disabled: !selected.id,
      },
    ]
    return (
      <React.Fragment>
        <ContentContainer
          promptOnExit={submitting || Products.submitting}
          promptOnExitIsConfirm={false}
          title="Categories"
          actions={
            <Visible toAppAdminRole>
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
              >
                <DropdownToggle
                  tag="span"
                  onClick={this.toggle}
                  data-toggle="dropdown"
                  aria-expanded={this.state.dropdownOpen}
                >
                  <IconButton icon="md-more" color="dark" outline className="icon-more" />
                </DropdownToggle>
                <DropdownMenu>
                  {dropdownOptions.map((i, key) => (
                    <DropdownItem key={key} onClick={i.onClick} disabled={i.disabled}>
                      {i.title}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Visible>
          }
        >
          <Row>
            <Col md={4}>
              <FormContainer className={classes.categoryPanel}>
                {loading ? (
                  <Loader loading={loading} />
                ) : (
                  <CategoryList list={data} primary={true} onSelectItem={this.setSelected} selected={selected} />
                )}
              </FormContainer>
            </Col>
            <Col md={8}>
              {selected.id && (
                <CategoryDetails
                  {...this.props}
                  list={data}
                  selected={selected}
                  pageKey={pageKey}
                  toggleModal={this.toggleModal}
                  setSelected={this.setSelected}
                  onDelete={this.handleDelete}
                />
              )}
            </Col>
          </Row>
          {isModalOpen && (
            <CategoryFormModal
              isModalOpen={isModalOpen}
              selected={selected}
              type={type}
              setSelected={this.setSelected}
              redirect={this.toggleModal}
              toggleModal={() => this.toggleModal(null, true)}
            />
          )}
        </ContentContainer>
        {!!selected?.id && (
          <Drawer
            ref={ref => (this.drawer = ref)}
            tabs={[
              {
                key: 'logs',
                icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
                content: (
                  <ActivityLogSidePanel
                    entity="Category"
                    entityId={selected.id}
                    entityName={selected.name}
                    closeDrawer={this.handleCloseDrawer}
                  />
                ),
              },
              {
                key: 'comments',
                icon: <img src={commentsIcon} alt="Comments" />,
                content: (
                  <CommentSidePanel
                    referenceId={selected.id}
                    commentSource="categories"
                    entityName={selected.name}
                    closeDrawer={this.handleCloseDrawer}
                  />
                ),
              },
            ]}
          />
        )}
      </React.Fragment>
    )
  }
}

export default decorate(inject('categoryRepository', 'productRepository', 'searchRepository')(observer(Category)), {})
