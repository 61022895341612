/* @flow */
import React from 'react'

import ImportProcess from './ImportProcess'

export default props => {
  const { importList, removeImportFile, reloadHistory } = props

  return (
    <>
      {importList.length > 0 &&
        importList.map(importData => (
          <div key={importData.key}>
            <ImportProcess importData={importData} removeImportFile={removeImportFile} reloadHistory={reloadHistory} />
          </div>
        ))}
    </>
  )
}
