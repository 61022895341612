import React, { Component } from 'react'

import { debounce } from 'lodash'
import { formatCustomerCurrency, getFormattedDateString, getFullName } from 'helpers/Formatters'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { SUBSCRIPTIONS_PATH } from 'definitions'
import Card from 'components/Common/Card'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'

class AssociatedOrders extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = debounce(this.handleInputChange, 500)
  }
  handleInputChange = e => {
    const { data } = this.props
    data.orderSubscription[e.name] = e.value
  }
  render() {
    const {
      data: { associatedOrders, customer },
    } = this.props
    return (
      <Card title="Associated Orders">
        <ReactTable
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'orderNumber',
              Header: 'Order No.',
              sortable: false,

              Cell: ({ original }) => (
                <Link
                  to={{
                    pathname: `${SUBSCRIPTIONS_PATH}/${original.id}`,
                    search: `?contract=true&sub=true`,
                  }}
                >
                  {original.orderNumber}
                </Link>
              ),
            },
            {
              accessor: 'customer.name',
              Header: 'Customer',
              sortable: false,
            },
            {
              accessor: 'createdDate',
              Header: 'Created On',
              sortable: false,
              Cell: ({ original }) => <span>{getFormattedDateString(original.createdDate)}</span>,
            },
            {
              accessor: 'billingAddress.firstName',
              Header: 'Bill To',
              sortable: false,
              Cell: ({ original }) => getFullName(original.billingAddress),
            },
            {
              accessor: 'store.currency',
              Header: 'Currency',
              sortable: false,
              width: 75,
            },
            {
              accessor: 'orderSummaryDto.grandTotal',
              Header: 'Amount',
              headerClassName: 'd-flex justify-content-end',
              className: 'd-flex justify-content-end',
              sortable: false,
              Cell: ({ original }) => formatCustomerCurrency(original.orderSummaryDto.grandTotal, customer),
            },
            {
              Header: 'Action',
              headerClassName: 'justify-content-center',
              className: 'justify-content-center p-1',
              sortable: false,
              width: 75,
              Cell: ({ original }) => (
                <Link
                  to={{
                    pathname: `${SUBSCRIPTIONS_PATH}/${original.id}`,
                    search: `?contract=true&sub=true`,
                  }}
                >
                  <IconButton icon="md-search" />
                </Link>
              ),
            },
          ]}
          dateColumns={['createdDate']}
          data={associatedOrders}
          showRowPerPage={false}
          defaultSorted={[]}
          // loadData={params => fetchTable({ ...params })}
          // loading={loading}
          // totalRecordCount={totalRecordCount}
          // totalPages={originalData.totalPages}
        />
      </Card>
    )
  }
}

export default observer(AssociatedOrders)
