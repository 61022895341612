import React from 'react'

import { Alert, Col } from 'reactstrap'
import styled from 'styled-components'

export default ({ data = {} }) => {
  return (
    <Col xl={6}>
      <AlertStyled color="danger" className="px-3 pt-2 pb-1">
        <div className="mb-1">
          <b>Batch job error detail:</b>
        </div>
        <ol className="pl-3 mb-0">
          {data.errorDetails.map((err, errIndex) => {
            return err.deviceManagementJobErrorItems.map((product, productIndex) => (
              <li key={`${errIndex}-${productIndex}`} className="mb-1">
                <div className="d-flex">
                  <span className="col-3 pl-0">Serial Number:</span>
                  <span className="col-9 pr-0">{err.serialNumber}</span>
                </div>
                <div className="d-flex">
                  <span className="col-3 pl-0">Product Code:</span>
                  <span className="col-9 pr-0">{product.productCode}</span>
                </div>
                <div className="d-flex">
                  <span className="col-3 pl-0">Order Number:</span>
                  <span className="col-9 pr-0">{product.orderNumber || 'N/A'}</span>
                </div>
                <div className="d-flex">
                  <span className="col-3 pl-0">Reason:</span>
                  <span className="col-9 pr-0">{product.errorReason}</span>
                </div>
              </li>
            ))
          })}
        </ol>
      </AlertStyled>
    </Col>
  )
}

const AlertStyled = styled(Alert)`
  &&& {
    font-size: 14px;
    .alert {
      margin-bottom: 0 !important;
    }
    ul {
      max-height: 380px;
      overflow-y: auto;
    }
  }
`
