/* @flow */
import BaseServices from './BaseServices'

class LogsServices extends BaseServices {
  constructor(api) {
    const url = '/logs'
    super(api, url)
    this.api = api
    this.url = url
  }
  fetchLogsById = (id, payload) => {
    return this.api.get(`${this.url}/${id}`, payload)
  }
  fetchLogsByEntity = ({ entityId, entity, ...rest }) => {
    return this.api.get(`${this.url}/${entityId}/entity/${entity}`, rest)
  }
}

export default LogsServices
