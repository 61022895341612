/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import cx from 'classnames'
import React, { Component } from 'react'

import { formatDate } from 'helpers/Formatters'
import { titleize } from 'helpers/String'
import Card from 'components/Common/Card'
import ReactTable from 'components/Common/ReactTable'

class ImportHistory extends Component {
  reloadTable = () => {
    this.table.reload()
  }
  render() {
    const {
      importRepository: {
        Table: { data, loading, totalRecordCount, originalData },
        fetchTable,
      },
    } = this.props

    return (
      <Card title="Import History" unpadded>
        <ReactTable
          columns={[
            {
              accessor: 'createdDate',
              Header: 'Import Date',
              Cell: ({ original }) => formatDate(original.createdDate),
            },
            {
              accessor: 'entity',
              Header: 'Entity',
              Cell: ({ original }) => titleize(original.entity),
            },
            {
              accessor: 'completed',
              Header: 'Completed',
              Cell: ({ original }) =>
                original.completed ? 'Yes' : <span className="text-danger font-weight-bold">No</span>,
            },
            {
              accessor: 'stats',
              Header: 'Stats',
              Cell: ({ original }) => (
                <div>
                  <div>Total: {original.total}</div>
                  <div>Success: {original.successCount}</div>
                  <div className={cx(original.failedCount && 'text-danger')}>Failed: {original.failedCount}</div>
                </div>
              ),
            },
            {
              accessor: 'files',
              Header: 'Files',
              Cell: ({ original }) => {
                if (original.attachments && original.attachments.attachedFiles) {
                  return (
                    <div>
                      {original.attachments.attachedFiles.map(item => (
                        <div key={item.id}>
                          <a href={`${process.env.REACT_APP_ATTACHMENT_SERVER}${item.resourcePath}`} download>
                            {item.fileName}
                          </a>
                        </div>
                      ))}
                    </div>
                  )
                }
                return ''
              },
            },
            {
              accessor: 'lastModifiedBy',
              Header: 'Imported By',
            },
          ]}
          sort={{ id: 'createdDate', desc: true }}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          search
          ref={ref => (this.table = ref)}
        />
      </Card>
    )
  }
}

export default decorate(inject('importRepository')(observer(ImportHistory)), {})
