/* @flow */
import React, { Component } from 'react'

import ImportFileItem from './ImportFileItem'

class ImportFiles extends Component {
  constructor(props) {
    super(props)

    const { form, fileTypes } = props
    form.update({ files: fileTypes })
  }
  render() {
    const { form } = this.props
    return (
      <>
        <h6 key="title" variant="h6" className="my-4">
          Select files for {form.$('fileType').value.label}
        </h6>

        {form.$('files').map(item => (
          <ImportFileItem key={item.value.key} fieldItem={item} form={form} fileType={item.value} />
        ))}
      </>
    )
  }
}

export default ImportFiles
