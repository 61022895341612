/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class ZoneServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/zone'
    super(api, url)
    this.api = api
    this.url = url
  }
  deleteCountry(zoneId, countryId) {
    return this.api.del(`${this.url}/${zoneId}/countries/${countryId}`)
  }

  addCountry(zoneId, payload) {
    return this.api.post(`${this.url}/${zoneId}/countries`, payload)
  }
}

export default ZoneServices
