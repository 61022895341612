import { observer } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import ReactSelect from 'components/Common/Form/ReactSelect'
import Tags from 'components/Common/Tags'

class PermissionsPanel extends Component {
  sortItems = (items = []) => {
    return items.sort((item1, item2) => {
      if (item1.label < item2.label) {
        return -1
      }
      if (item1.label > item2.label) {
        return 1
      }
      return 0
    })
  }
  renderSelect = () => {
    const { elementKey, field, search, valueKey = 'value', labelKey = 'label', closeMenuOnSelect = false } = this.props
    return (
      <ReactSelect
        innerKey={elementKey}
        field={field}
        serverSide
        search={search}
        options={{
          isMulti: true,
          valueKey,
          labelKey,
          defaultOptions: true,
          closeMenuOnSelect,
        }}
      />
    )
  }
  renderReadOnly = () => {
    const { field, readOnly, valueKey, labelKey } = this.props
    const items = field.value ? field.value : []

    return (
      <>
        <label>{field.label}</label>
        {!items.length && <p className="text-dark">To add entries, click Edit Product</p>}
        {!!items.length && (
          <Tags
            data={this.sortItems(items)}
            onDelete={this.handleDelete}
            hideDelete={readOnly}
            className="tags border-bottom px-2 py-0"
            valueKey={valueKey}
            labelKey={labelKey}
          />
        )}
      </>
    )
  }
  render() {
    const { readOnly } = this.props
    return (
      <CardStyled>
        {readOnly && this.renderReadOnly()}
        {!readOnly && this.renderSelect()}
      </CardStyled>
    )
  }
}

const CardStyled = styled.div`
  padding: 0 1.25rem 1rem;
  label {
    color: #797979;
    font-size: 11px;
    font-weight: 700;
  }
  .tags {
    min-height: 0;
    background-color: #fff;
    padding: 0.75rem 0.75rem 0.25rem;
    > div {
      padding: 0.25rem 0.75rem;
    }
  }
`

export default observer(PermissionsPanel)
