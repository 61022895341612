/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

export default class CustomerGroupServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/customerGroups'
    super(api, url)
    this.api = api
    this.url = url
  }
  getAvailableCustomer(payload, id) {
    return this.api.get(`${this.url}/${id}/customers/available`, payload)
  }
}

export class CustomerGroupDetailsServices extends CustomerGroupServices {
  fetchTable(payload, id) {
    return this.api.get(`${this.url}/${id}/customers`, payload)
  }
  create(payload) {
    return this.api.post(this.url, payload)
  }
  put(payload) {
    return this.api.update(`${this.url}/${payload.id}/customers`, payload)
  }
  delete({ id, ...payload }) {
    return this.api.post(`${this.url}/${id}/customers/remove`, payload)
  }
}

export class CustomerGroupDisabledProductServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/customerGroups'
    super(api, url)
    this.api = api
    this.url = url
  }
  fetchTable(payload, id) {
    return this.api.get(`${this.url}/${id}/disabledProducts`, payload)
  }

  fetchProductTable(payload, id) {
    return this.api.get(`${this.url}/${id}/disabledProducts/available`, payload)
  }

  create(payload, id) {
    return this.api.post(`${this.url}/${id}/disabledProducts`, payload)
  }

  delete({ groupId, ...payload }) {
    return this.api.post(`${this.url}/${groupId}/disabledProducts/remove`, payload)
  }
}
