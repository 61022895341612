/* @flow */
import React, { Component } from 'react'

import Form from './Form'

class CatalogPriceRulesAdd extends Component {
  render() {
    const {
      match: {
        params: { type },
      },
    } = this.props
    return (
      <Form
        {...this.props}
        action="new"
        view="conditions"
        title={type === 'rules' ? 'New Price Rules' : 'New Coupon Code Rules'}
        breadcrumbs={[
          {
            label: 'Home',
            href: '/',
          },
          {
            label: type === 'rules' ? 'Price Rules' : 'Coupon Codes',
            href: type === 'rules' ? '/pricing/rules' : '/pricing/codes',
          },
          {
            label: 'New',
          },
        ]}
      />
    )
  }
}

export default CatalogPriceRulesAdd
