/* @flow */
import APIRepository from 'stores/APIRepository'
import BaseServices from './BaseServices'

class QuotationServices extends BaseServices {
  api: APIRepository
  constructor(api: APIRepository) {
    const url = '/orders/quotation'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export default QuotationServices
