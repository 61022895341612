import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import styled from 'styled-components'

class SelectField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }
  render() {
    const { options = [], onChange = null, value = '', placeholder = '' } = this.props
    const { isOpen } = this.state
    return (
      <span>
        <DropdownStyled isOpen={isOpen} toggle={() => this.setState({ isOpen: !isOpen })}>
          <DropdownToggle color="default" caret>
            {value || placeholder}
          </DropdownToggle>
          <DropdownMenu>
            {options.map((option, key) =>
              option.hasOwnProperty('values') ? (
                option.values.map((opt, index) => (
                  <DropdownItem
                    key={index}
                    className={opt.className}
                    active={value === option.value}
                    disabled={opt.disabled}
                    onClick={() => onChange(opt)}
                  >
                    {opt.label}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem key={key} active={value === option.value} onClick={() => onChange(option)}>
                  {option.label}
                </DropdownItem>
              )
            )}
          </DropdownMenu>
        </DropdownStyled>
      </span>
    )
  }
}

export default SelectField

const DropdownStyled = styled(Dropdown)`
  display: inline-block;
  margin: 0 0.5rem;
  .dropdown-menu {
    border-radius: 5px;
  }
  button.dropdown-toggle {
    background-color: inherit;
    color: inherit;
    padding: 0.25rem;
    margin: 0 0.25rem;
    border: none;
    font-weight: normal;
    text-transform: uppercase;
    border-bottom: 1px solid ${props => props.theme.inputBorder};
    border-radius: 0;
    &.dropdown-toggle::after {
      margin-left: 1rem;
    }
    &:focus {
      box-shadow: none;
    }
  }
  button.dropdown-item {
    &.group {
      color: inherit;
      font-weight: 600;
    }
    &.attribute {
      padding-left: 2rem;
    }
  }
`
