import { Collapse, CustomInput, ListGroupItem } from 'reactstrap'
import { observer } from 'mobx-react'
import cx from 'classnames'
import React, { Component } from 'react'

import CategoryList from './index'

class CategoryListItem extends Component {
  constructor(props) {
    super(props)

    const { form, item } = this.props
    const categories = form.$('categories').value

    //Set checked
    item.isChecked = categories.some(cat => cat.id === item.id)

    //Check if sub categories are selected
    //If yes, this parent category needs to be collapsed/open
    item.isOpen = categories.some(cat => {
      const tokens = cat.path.split(/\/+/)
      return tokens.length > 2 && tokens.slice(1, -1).includes(item.id)
    })
  }
  toggleChild = item => {
    item.isOpen = !item.isOpen
  }
  selectedChanged = (event, item) => {
    //Uncontrolled updating of selected categories
    const { form } = this.props
    const categories = form.$('categories').value
    const { checked } = event.target

    const indexMatch = categories.findIndex(cat => cat.id === item.id)
    if (checked) {
      indexMatch < 0 && categories.push(item)
    } else {
      indexMatch >= 0 && categories.splice(indexMatch, 1)
    }
    form.$('categories').set(categories)
  }
  render() {
    const { form, item, siblings, readOnly } = this.props
    const hasSubCategories = !!item['subCategories']

    return (
      <>
        <ListGroupItem tag="a" action onClick={() => this.toggleChild(item)}>
          <div className={cx('d-inline-flex', !hasSubCategories && 'indent-left')}>
            {hasSubCategories && (
              <i className={`icon ion-${item.isOpen ? 'ios-arrow-down' : 'ios-arrow-forward'} text-primary mr-2`} />
            )}{' '}
            {hasSubCategories ? (
              <i className={`icon ion-${item.isOpen ? 'md-folder-open' : 'md-folder'} text-primary`} />
            ) : (
              <i className="icon ion-ios-list text-dark" />
            )}
          </div>
          <div className="list-item-text ml-2">
            <CustomInput
              type="checkbox"
              label={item.name}
              id={`checkboxCategory${item.id}`}
              defaultChecked={item.isChecked}
              onChange={event => this.selectedChanged(event, item)}
              disabled={readOnly || item.rootInd}
            />
          </div>
        </ListGroupItem>
        {hasSubCategories && (
          <Collapse isOpen={item.isOpen} className="indent-left">
            <CategoryList form={form} list={item.subCategories} siblings={siblings} readOnly={readOnly} />
          </Collapse>
        )}
      </>
    )
  }
}

export default observer(CategoryListItem)
