/* @flow */
import APIRepository from 'stores/APIRepository'

export default class BaseServices {
  api: APIRepository
  constructor(api: APIRepository, url) {
    this.api = api
    this.url = url
  }

  fetchTable(payload) {
    return this.api.get(this.url, payload)
  }

  getById(payload) {
    return this.api.get(`${this.url}/${payload}`)
  }

  create(payload) {
    return this.api.post(this.url, payload)
  }

  patch(payload) {
    return this.api.patch(`${this.url}/${payload.id}`, payload)
  }

  put(payload) {
    return this.api.update(`${this.url}/${payload.id}`, payload)
  }

  delete(id) {
    return this.api.del(`${this.url}/${id}`)
  }
}
