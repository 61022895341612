import React, { useState } from 'react'

import { Button } from 'reactstrap'
import styled from 'styled-components'

import IconButton from '../Common/Buttons/IconButton'
import ReactSelect from 'components/Common/Form/ReactSelect'

export default ({ onCreate, onBulkAction }) => {
  const [bulkAction, setBulkAction] = useState(null)

  return (
    <div className="d-flex justify-content-end align-items-center">
      <ReactSelectStyled
        className="mr-2"
        plain={true}
        options={{
          placeholder: 'Select Bulk Action',
          options: [
            { value: 0, label: 'Delete' },
            { value: 1, label: 'Copy' },
          ],
        }}
        onChange={selected => {
          setBulkAction(selected && selected.value)
        }}
      />
      <Button
        color="dark"
        size="sm"
        className="mr-2"
        onClick={() => {
          switch (bulkAction) {
            case 1:
              onBulkAction('copy')
              break
            default:
              onBulkAction('delete')
              break
          }
        }}
        disabled={bulkAction === null}
      >
        Submit
      </Button>
      <IconButton color="dark" outline onClick={onCreate} title="Add New Additional Charge" />
    </div>
  )
}

const ReactSelectStyled = styled(ReactSelect)`
  .react-select {
    &__control {
      width: ${props => props.width || '180px'};
      border: none;
    }
  }
`
