/* @flow */
import React, { Component } from 'react'

import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'

import OrderTable from 'components/Orders/OrderTable'
import ToasterNotify from 'helpers/ToasterNotify'

class Quotations extends Component {
  componentDidMount() {
    const { history } = this.props
    const { status, search } = queryString.parse(this.props.location.search)

    switch (status) {
      case 'NEW':
        ToasterNotify.alert({ message: 'Quotation created successfully!' })
        history.replace('/quotations', {})
        break
      default:
        search && history.replace('/quotations', {})
        break
    }
  }
  render() {
    const { quotationRepository, searchRepository } = this.props
    const { search } = queryString.parse(this.props.location.search)

    return (
      <OrderTable
        quotation
        repository={quotationRepository}
        searchRepository={searchRepository}
        pageKey="quotations"
        searchCriteria={search}
      />
    )
  }
}

export default decorate(inject('quotationRepository', 'searchRepository')(observer(Quotations)), {})
