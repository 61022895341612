/* @flow */
import { decorate } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { allowedTo } from 'helpers/Auth'
import Checkbox from 'components/Common/Form/Checkbox'
import Form from 'components/Common/Form/index'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import ReactSelect from 'components/Common/Form/ReactSelect'

import Files from './ImportFiles'

class ImportForm extends Component {
  constructor(props) {
    super(props)

    const fieldSet = {
      fields: [
        'fileType',
        'continueOnError',
        'updateDuplicate',
        'files',
        'files[].key',
        'files[].label',
        'files[].name',
        'files[].required',
      ],
      values: { continueOnError: true, updateDuplicate: true, files: [] },
      labels: {
        fileType: 'File Type',
        continueOnError: 'Continue On Error',
        updateDuplicate: 'Update Duplicate',
      },
      rules: {
        fileType: 'required',
      },
      types: {
        continueOnError: 'checkbox',
        updateDuplicate: 'checkbox',
      },
    }

    this.form = new Form(fieldSet, { name: 'ImportForm', handleSubmit: this.handleSubmit })
    this.saving = false
  }
  componentDidMount() {
    this.props.importTypeRepository.get()
  }
  handleImportType = fileType => {
    this.form.update({ fileType })
    fileType && this.props.importTypeRepository.getById(fileType.value)
    !fileType && this.props.importTypeRepository.clearCrudData()
  }
  handleSubmit = () => {
    const { addImportFile } = this.props
    const { fileType, continueOnError, updateDuplicate, files } = this.form.values()
    const importData = {
      key: new Date().getTime().toString(16),
      fileType,
      continueOnError,
      updateDuplicate,
      files: files.filter(importFile => !!importFile.file),
    }
    addImportFile(importData)

    this.form.$('fileType').set(null)
    this.props.importTypeRepository.clearCrudData()
  }
  render() {
    const {
      importTypeRepository: {
        CRUD: importType = {},
        Common: { importType: importTypeList = {} },
      },
    } = this.props
    return (
      <FormContainer
        title="Import Files"
        onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
        errors={importType.errors || importTypeList.errors}
        actions={
          <FormActions
            variant="contained"
            loading={importType.loading}
            loadingMessage={`${this.saving ? 'Importing' : 'Loading'} please wait...`}
            confirmLabel="Import"
            hideCancel
            disabled={!importType.data || !importType.data.files || importType.loadingFailed}
            hideConfirm={!allowedTo()}
          />
        }
      >
        <ReactSelect
          field={this.form.$('fileType')}
          options={{ options: importTypeList.data }}
          onChange={this.handleImportType}
        />
        <Checkbox field={this.form.$('continueOnError')} />
        <Checkbox field={this.form.$('updateDuplicate')} />
        {!importType.loading && importType.data && importType.data.files && !importType.loadingFailed && (
          <Files fileTypes={importType.data.files} form={this.form} />
        )}
      </FormContainer>
    )
  }
}

export default decorate(inject('importRepository', 'importTypeRepository')(observer(ImportForm)), {})
