import { inject, observer } from 'mobx-react'
import CollectionTable from './CollectionTable'
import ContentContainer from 'components/Common/ContentContainer'
import React, { Component } from 'react'

class Collections extends Component {
  render() {
    return (
      <ContentContainer
        title="Collections"
        promptOnExit={this.props.collectionItemRepository.CRUD.submitting}
        promptOnExitIsConfirm={false}
      >
        <CollectionTable history={this.props.history} />
      </ContentContainer>
    )
  }
}

export default inject('collectionItemRepository')(observer(Collections))
