import BaseServices from './BaseServices'

class ProductCommentServices extends BaseServices {
  constructor(api) {
    const url = '/productComments'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export default ProductCommentServices
