// eslint-disable-next-line no-unused-vars
import React from 'react'

import { inject } from 'mobx-react'
import { ROLE_ADMIN, ROLE_APP_ADMIN, ROLE_ORDER_PROCESS } from 'definitions'

const adminRole = [ROLE_ADMIN]
const appAdminRoles = [...adminRole, ROLE_APP_ADMIN]
const orderProcessRoles = [...appAdminRoles, ROLE_ORDER_PROCESS]

const getRole = () => {
  if (localStorage.hasOwnProperty('STORE_SESSION')) {
    const { role = null } = JSON.parse(localStorage.getItem('STORE_SESSION'))
    return role
  } else return null
}

export const Visible = inject('loginRepository')(props => {
  const {
    loginRepository: { getCurrentRole },
    to = [],
    toAppAdminRole,
    toOrderProcessRole,
  } = props

  let roles = adminRole
  if (to) roles = [...to, ...roles]
  if (toAppAdminRole) roles = [...to, ...appAdminRoles]
  if (toOrderProcessRole) roles = [...to, ...orderProcessRoles]

  if (roles.includes(getCurrentRole())) {
    return <React.Fragment>{props.children}</React.Fragment>
  }

  return false
})

export const allowedToOrderRole = () => {
  return allowedToAppAdminRole([ROLE_ORDER_PROCESS])
}

export const allowedToAppAdminRole = (roles = []) => {
  return allowedTo([ROLE_APP_ADMIN, ...roles])
}

export const allowedTo = (roles = []) => {
  const role = getRole()
  return [ROLE_ADMIN, ...roles].includes(role)
}

export const isAuthenticated = () => {
  if (localStorage.hasOwnProperty('STORE_SESSION')) {
    const { token = null, id = null, role = null } = JSON.parse(localStorage.getItem('STORE_SESSION'))
    return !!id && !!token && !!role
  } else return false
}
