import React, { Component } from 'react'

import { debounce } from 'lodash'
import { inject, observer } from 'mobx-react'
import Card from 'components/Common/Card'
import styled from 'styled-components'

import { formatCustomerCurrency } from 'helpers/Formatters'

class SubscriptionDetails extends Component {
  constructor(props) {
    super(props)
    this.handleInputChange = debounce(this.handleInputChange, 500)
  }

  handleInputChange = e => {
    this.props.form.$(`orderSubscription.${e.name}`).set(e.value)
    this.props.computeOrderSummary()
  }

  render() {
    const {
      readOnly,
      isContract,
      data: { orderSubscription = { upfrontPayment: 0 }, customer },

      orderSubscriptionRepository: {
        OrderRates: {
          data: { subscriptionBalance = 0, subTotal = 0, subscriptionBalanceDue = 0, currency },
        },
      },
    } = this.props
    return (
      <Card title={`Subscription ${isContract ? 'Details' : 'Balance'}`}>
        <TableStyled>
          <thead>
            <tr>
              <th>Description</th>
              <th className="text-right" style={{ width: '300px' }}>
                Subtotal
              </th>
            </tr>
          </thead>
          <tbody>
            {isContract ? (
              <React.Fragment>
                <tr>
                  <td>Subscription Fee</td>
                  <td className="text-right">{formatCustomerCurrency(subTotal, customer)}</td>
                </tr>
                <tr>
                  <td>Upfront Payment (Deposit)</td>
                  <td className="text-right">
                    {readOnly ? (
                      formatCustomerCurrency(orderSubscription.upfrontPayment || 0, customer)
                    ) : (
                      <InputStyled
                        type="number"
                        name="upfrontPayment"
                        defaultValue={orderSubscription.upfrontPayment}
                        onBlur={e => this.handleInputChange(e.target)}
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <tr>
                  <td>Balance Due</td>
                  <td className="text-right">{formatCustomerCurrency(subscriptionBalanceDue, customer)}</td>
                </tr>
                <tr>
                  <td>Upfront Payment (Deposit)</td>
                  <td className="text-right">
                    {readOnly ? (
                      formatCustomerCurrency(orderSubscription.upfrontPayment || 0, customer)
                    ) : (
                      <InputStyled
                        type="number"
                        name="upfrontPayment"
                        defaultValue={orderSubscription.upfrontPayment}
                        onBlur={e => this.handleInputChange(e.target)}
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <td></td>
              <td className="text-right text-balance">
                <label htmlFor="">Balance</label>{' '}
                <span className="ml-5">
                  {currency || ''} {formatCustomerCurrency(subscriptionBalance, customer)}
                </span>
              </td>
            </tr>
          </tbody>
        </TableStyled>
      </Card>
    )
  }
}

export default inject('orderSubscriptionRepository')(observer(SubscriptionDetails))

const InputStyled = styled.input`
  background-color: #eee;
  border: none;
  padding: 0.35rem 0.5rem;
  max-width: 75px;
  text-align: right;
`

const TableStyled = styled.table`
  width: 100%;
  font-size: 11px;
  thead {
    border-top: 1px solid #797979;
    border-bottom: 1px solid #efefef;
  }
  th {
    padding: 12px 0px;
    color: #7f7f7f;
    &:last-child {
      padding-right: 0.5rem;
    }
  }

  td {
    border-right: none !important;
    padding: 12px 6px;
    line-height: 1;
    min-height: 45px;
  }
  .text-balance {
    font-size: 14px;
    span {
      font-size: 18px;
    }
  }
`
