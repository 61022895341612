/* @flow */
import { decorate, observable } from 'mobx'
import BaseRepository, { defaults } from './BaseRepository'
import ProhibitedItemServices from 'services/prohibitedItem'

class ProhibitedItemRepository extends BaseRepository {
  service: ProhibitedItemServices
  constructor(service: ProhibitedItemServices) {
    super(service)
    this.service = service
  }
  BulkAction = defaults.CRUD
  clearBulkErrorMessages = () => {
    this.BulkAction.errors = []
  }
  bulkPost = async (key, payload, callback) => {
    this.clearBulkErrorMessages()
    this.BulkAction.loading = true
    const { data, errors } = await this.service.bulkPost(key, payload)
    if (data && !errors.length) {
      this.BulkAction.loadingFailed = false
      if (callback) callback(data)
    } else {
      this.BulkAction.loadingFailed = true
      this.BulkAction.errors = errors
    }
    this.BulkAction.loading = false
  }
}

export default decorate(ProhibitedItemRepository, {
  BulkAction: observable,
})
