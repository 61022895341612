import BaseServices from './BaseServices'

class OrderCommentServices extends BaseServices {
  constructor(api) {
    const url = '/orderComments'
    super(api, url)
    this.api = api
    this.url = url
  }
}

export default OrderCommentServices
