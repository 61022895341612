import { Badge, Col, Row } from 'reactstrap'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'
import React, { Component } from 'react'
import styled from 'styled-components'

import { allowedToAppAdminRole, Visible } from 'helpers/Auth'
import { ATTRIBUTE_NAMES, PRODUCT_NATURE_PHYSICAL, PRODUCT_NATURE_VIRTUAL, PRODUCTS_PATH } from 'definitions'
import { generateFields } from './fieldset'
import { getFormattedDateTimeString } from 'helpers/Formatters'
import { isNull } from 'helpers/Common'
import ActivityLogSidePanel from 'components/LogBook/ActivityLogSidePanel'
import ContentContainer from 'components/Common/ContentContainer'
import Drawer from 'components/Common/Drawer'
import FormActions from 'components/Common/Buttons/FormActions'
import FormContainer from 'components/Common/Form/Container'
import Input from 'components/Common/Form/Input'
import MobxForm from 'components/Common/Form/index'
import Tab from 'components/Common/Tab'
import ToasterNotify from 'helpers/ToasterNotify'

import Actions from './Actions'
import AttributesPanel from './AttributesPanel'
import CategoriesPanel from './CategoriesPanel'
import CloneProduct from './CloneProduct'
import CustomOptionsPanel from './CustomOptionsPanel'
import DescriptionPanel from './DescriptionPanel'
import FlexibleCustomOptionPanel from './FlexibleCustomOptionPanel'
import ImagesPanel from './ImagesPanel'
import MiniStoreSettingsPanel from './MiniStoreSettingsPanel'
import PermissionsPanel from './PermissionsPanel'
import ProductInfoPanel from './ProductInfoPanel'
import RelatedProductsPanel from './RelatedProductsPanel'
import RemarkSidePanel from './RemarkSidePanel'
import StorePricePanel from './StorePricePanel'
import TagsPanel from './TagsPanel'

import activityLogIcon from 'images/activity log.svg'
import commentsIcon from 'images/comment.svg'
import Modal from 'components/Common/Modal'

class ProductEditForm extends Component {
  promptOnExit = false
  state = {
    readOnly: !allowedToAppAdminRole() || this.props.readOnly,
    skipPromptToExit: false,
    formErrors: [],
    categoryError: false,
    attributesError: false,
    isModalOpen: false,
    isCloneProduct: false,
  }
  constructor(props) {
    super(props)
    this.form = new MobxForm(
      {
        fields: generateFields(props),
      },
      {
        name: 'ProductForm',
        hooks: {
          onSuccess: form => {
            this.setState(
              {
                categoryError: false,
                attributesError: false,
                formErrors: [],
              },
              () => this.handleSubmit(form)
            )
          },
          onError: this.handleFormError,
        },
      }
    )
  }
  componentDidMount() {
    this.props.commonRepository.getCommon('productPermissionLabels', 'collections/productPermissionLabels')
  }
  componentWillUnmount() {
    this.props.fileUploadRepository.errors = []
  }
  gotoProducts = () => {
    this.props.history.push(PRODUCTS_PATH)
  }
  handleFormError = form => {
    const { categories, customFields, customFieldsValidator } = form.errors()
    const formErrors = []
    let attributesHasError = false

    if (categories) {
      formErrors.push({ message: 'Please link this product to at least one category.' })
    }

    if (customFieldsValidator) {
      attributesHasError = true
      formErrors.push({ message: customFieldsValidator })
    }

    if (!attributesHasError && customFields && typeof customFields === 'object') {
      attributesHasError = Object.keys(customFields).some(key => !!customFields[key])
    }

    this.setState({
      categoryError: !!categories,
      attributesError: attributesHasError,
      formErrors: formErrors.length ? formErrors : [],
    })
  }
  handleSubmit = async () => {
    const { data } = this.props
    const {
      additionalOptions,
      categories,
      customOptions,
      customFields,
      timePeriod,
      timePeriodUnit,
      shippingMethod,
      shippingType,
      permission,
      ministorePermission,
      countryOfManufacture,
      prices,
      flexibleCustomOptionCategory,
      newCustomOptionCategories,
      ...actualValues
    } = this.form.values()

    const attributes = Object.keys(customFields).length ? this.transformAttributes(customFields) : data.attributes

    const payload = {
      ...actualValues,
      id: data.id,
      additionalOptions: isNull(additionalOptions, []).map(item => ({ id: item.id })),
      attributes: attributes || [],
      categories: isNull(categories, []).map(item => toJS(item)),
      customOptions: isNull(customOptions, []).map(item => toJS(item)),
      shippingMethod: isNull(shippingMethod),
      shippingType: shippingType ? shippingType.value : null,
      permission: {
        availableCustomers: permission.availableCustomers.map(item => ({ id: item.id })),
        availableStores: permission.availableStores.map(item => ({ id: item.id })),
        disableCustomerGroups: permission.disableCustomerGroups.map(item => ({ id: item.id })),
        hidePriceFromCustomerGroups: permission.hidePriceFromCustomerGroups.map(item => ({ id: item.id })),
        excludedCountries: permission.excludedCountries.map(item => ({ id: item.id })),
        visibleOnlyAsCustomOptionInd: permission.visibleOnlyAsCustomOptionInd,
        autoRenewInd: permission.autoRenewInd,
        paymentAccount: permission.paymentAccount ? 'SG' : null,
        referralProductInd: permission.referralProductInd,
        iccidBasedInd: permission.iccidBasedInd,
        iccidOptionESIMInd: permission.iccidOptionESIMInd,
        iccidOptionPhySIMInd: permission.iccidOptionPhySIMInd,
      },
      ...(permission.referralProductInd
        ? {
            ministorePermission: {
              availableCustomers: ministorePermission.availableCustomers.map(item => ({ id: item.id })),
              availableStores: ministorePermission.availableStores.map(item => ({ id: item.id })),
              disableCustomerGroups: ministorePermission.disableCustomerGroups.map(item => ({ id: item.id })),
              hidePriceFromCustomerGroups: ministorePermission.hidePriceFromCustomerGroups.map(item => ({
                id: item.id,
              })),
              excludedCountries: ministorePermission.excludedCountries.map(item => ({ id: item.id })),
            },
          }
        : {}),
      timePeriod: timePeriodUnit?.value ? timePeriod : null,
      timePeriodUnit: timePeriodUnit?.value ? timePeriodUnit : null,
      countryOfManufacture: isNull(countryOfManufacture),
      prices: prices
        ? prices.map(item => ({
            ...item,
            stockStatus: item.stockStatus
              ? {
                  id: item.stockStatus.id,
                  value: item.stockStatus.value,
                  label: item.stockStatus.label,
                }
              : null,
          }))
        : [],
      flexibleCustomOptionCategory: flexibleCustomOptionCategory
        ? flexibleCustomOptionCategory.map(item => ({ id: item.id, name: item.name }))
        : [],
      newCustomOptionCategories: newCustomOptionCategories,
    }

    delete payload.timePeriodAndUnitProxy

    await this.props.productRepository.patch(payload, ({ showModal }) => {
      this.setState({ skipPromptToExit: true, isModalOpen: showModal })
      !showModal && this.gotoProducts()
    })

    const patchErrors = this.props.productRepository.CRUD.errors
    if (patchErrors && patchErrors.length) {
      patchErrors.forEach(error => {
        if (error.fieldName && this.form.has(error.fieldName)) {
          this.form.$(error.fieldName).invalidate(error.message)
        }
      })
    }
  }
  handleCloseDrawer = () => {
    this.drawer && this.drawer.closeDrawer()
  }
  handleConvertToVirtualNoSN = productNature => {
    ToasterNotify.confirm({
      message: `Are you sure you want to convert this product to ${
        productNature === PRODUCT_NATURE_VIRTUAL ? 'Virtual' : '"Virtual No Serial Number"'
      } ?`,
      title: 'Warning',
      onConfirm: async () => {
        const { data, errors = [] } = await this.props.productRepository.convertToVirtualNoSN({
          id: this.props.data.id,
          productNature,
        })
        if (!errors.length) {
          this.props.productRepository.CRUD.data.productNature = data.productNature
          ToasterNotify.alert({ message: 'Conversion success!' })
        }
      },
    })
  }
  toggleCloneProduct = () => {
    this.setState({ isCloneProduct: !this.state.isCloneProduct })
  }
  toggleReadOnly = () => {
    if (!this.state.readOnly) {
      this.form.reset()

      //Reset store prices
      this.storePricePanel.initializeFields()
    }
    this.form.showErrors(false)
    this.setState({
      readOnly: !this.state.readOnly,
      formErrors: [],
      categoryError: false,
      attributesError: false,
    })
  }
  transformAttributes = attributes =>
    Object.keys(attributes).map(key => ({
      attributeName: key,
      attributeValue: this.transformAttributeValue(key, attributes[key]),
    }))
  transformAttributeValue = (name, value) => {
    if (value instanceof Date) {
      return (value = value.toISOString())
    } else if (value instanceof Array) {
      return value.map(item => item.value).join(',')
    } else if (value instanceof Object && value.hasOwnProperty('value')) {
      return value.value
    } else if (typeof value === 'boolean') {
      return (value = value.toString())
    } else if (typeof value === 'number') {
      return (value = parseInt(value, 10))
    } else if ([ATTRIBUTE_NAMES.SFCVOLUME, ATTRIBUTE_NAMES.SFCRENEWALQUOTA].includes(name)) {
      return (value = parseInt(value, 10))
    }
    return value
  }
  newCustomOptionCategoriesHandler = action => {
    let customOptions = this.form.$('newCustomOptionCategories').value || []
    const title = action?.option?.name
    switch (action?.action) {
      case 'select-option':
        customOptions.push(title)
        break
      case 'remove-value':
        customOptions.splice(customOptions.indexOf(title), 1)
        break
      default:
        break
    }
    this.form.$('newCustomOptionCategories').set(customOptions)
  }

  handleClose = () => {
    this.setState({ isModalOpen: false })
    this.gotoProducts()
  }

  render() {
    const {
      productRepository: { CRUD: { errors = [], loading } = {} },
      commonRepository,
      shippingRateRepository,
      fileUploadRepository,
      data,
    } = this.props
    const { readOnly, formErrors, categoryError, attributesError, isCloneProduct } = this.state
    const { productNature = {} } = data

    return (
      <ProductEditFormStyled>
        <ContentContainer
          promptOnExit={
            (!readOnly && this.form.isDirty && !this.state.skipPromptToExit) || fileUploadRepository.submitting
          }
          promptOnExitIsConfirm={!fileUploadRepository.submitting}
          breadcrumbLabel={data.name}
          title={
            <div className="d-inline-flex align-items-start">
              {readOnly && <h4 className="m-0">{data.name}</h4>}
              {!readOnly && (
                <Input
                  field={this.form.$('name')}
                  inputClassName="input-product-name"
                  style={{
                    width: `${(data.name.length > 50 ? 50 : data.name.length) + 1}ch`,
                    minWidth: '250px',
                    maxWidth: '100%',
                  }}
                />
              )}
              <h6 className="mb-0 ml-3 align-self-center">
                <Badge color="title" className="text-uppercase">
                  {data.productNature && data.productNature.label}
                </Badge>
              </h6>
            </div>
          }
          actions={
            <Visible toAppAdminRole>
              <Actions
                data={data}
                form={this.form}
                readOnly={readOnly}
                toggleReadOnly={this.toggleReadOnly}
                handleSubmit={() => this.buttonSubmit.click()}
                handleConvertToVirtualNoSN={this.handleConvertToVirtualNoSN}
                handleCloneProduct={this.toggleCloneProduct}
              />
            </Visible>
          }
        >
          <FormContainer
            onSubmit={e => this.form.onSubmit(e, { onSuccess: this.handleSubmit })}
            errors={[...formErrors, ...toJS(errors), ...fileUploadRepository.errors]}
            plain
            noValidate
          >
            <Row>
              <Col>
                <ProductInfoPanel
                  commonRepository={commonRepository}
                  shippingRateRepository={shippingRateRepository}
                  data={data}
                  form={this.form}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ImagesPanel data={data} form={this.form} readOnly={readOnly} />
              </Col>
            </Row>
            <Row>
              <Col xl="4">
                <DescriptionPanel data={data} form={this.form} readOnly={readOnly} />
                <TagsPanel commonRepository={commonRepository} data={data} form={this.form} readOnly={readOnly} />
              </Col>
              <Col xl="8" className="d-flex flex-column">
                <Tab
                  className="product-tab"
                  tabContentClassName="flex-fill"
                  activeTab="price"
                  tabs={[
                    {
                      key: 'price',
                      label: 'Prices / Stocks',
                      content: (
                        <StorePricePanel
                          data={data}
                          form={this.form}
                          readOnly={readOnly}
                          ref={ref => (this.storePricePanel = ref)}
                        />
                      ),
                    },
                    {
                      key: 'attributes',
                      label: (
                        <span className="d-inline-flex align-items-center">
                          {attributesError && <i className="icon ion-md-alert text-danger mr-1"></i>}
                          Attributes
                        </span>
                      ),
                      content: data && <AttributesPanel data={data} form={this.form} readOnly={readOnly} />,
                    },
                    {
                      key: 'customOptions',
                      label: 'Custom Options',
                      content: <CustomOptionsPanel data={data} form={this.form} readOnly={readOnly} />,
                    },
                    {
                      key: 'flexibleCustomOptions',
                      label: 'Flexible Custom Options',
                      content: (
                        <FlexibleCustomOptionPanel
                          form={this.form}
                          readOnly={readOnly}
                          setNewCustomOptionCategory={this.newCustomOptionCategoriesHandler}
                        />
                      ),
                    },
                    {
                      key: 'categories',
                      label: (
                        <span className="d-inline-flex align-items-center">
                          {categoryError && <i className="icon ion-md-alert text-danger mr-1"></i>}
                          Categories
                        </span>
                      ),
                      content: <CategoriesPanel form={this.form} readOnly={readOnly} />,
                    },
                    {
                      key: 'relatedProducts',
                      label: 'Related Products',
                      content: <RelatedProductsPanel data={data} form={this.form} readOnly={readOnly} />,
                    },
                    {
                      key: 'permission',
                      label: 'Permission',
                      content: <PermissionsPanel data={data} form={this.form} readOnly={readOnly} />,
                    },
                    {
                      key: 'ministorePermission',
                      label: 'Ministore Permission',
                      content: <MiniStoreSettingsPanel form={this.form} readOnly={readOnly} />,
                      hidden: (productNature.value || PRODUCT_NATURE_PHYSICAL) !== PRODUCT_NATURE_PHYSICAL,
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="6" className="d-flex align-items-end">
                <small>
                  <span>Last Modified on {getFormattedDateTimeString(data.lastModifiedDate)}</span>
                  {!data.storeDisplayInd && (
                    <span>
                      <span className="mx-3">|</span>
                      <span>
                        Store Display Disabled on{' '}
                        {getFormattedDateTimeString(data.storeDisplayDisabledDate || data.createdDate)}
                      </span>
                    </span>
                  )}
                </small>
              </Col>
              <Col xs="6" className="d-flex justify-content-end">
                <FormActions
                  loading={loading}
                  loadingMessage="Saving please wait..."
                  confirmLabel="Save Product"
                  hideConfirm={readOnly}
                  hideCancel
                  submitButtonRef={ref => (this.buttonSubmit = ref)}
                />
              </Col>
            </Row>
          </FormContainer>
        </ContentContainer>
        <Modal
          open={this.state.isModalOpen}
          title="Note"
          onClose={this.handleClose}
          closeLabel="Close"
          centered
          hideConfirm
        >
          Your updated details has been submitted to the system. Before your inputs are displayed, it will take
          approximately 5 minutes for the system background processes
        </Modal>
        {isCloneProduct && <CloneProduct data={data} open onClose={this.toggleCloneProduct} />}
        <Drawer
          ref={ref => (this.drawer = ref)}
          tabs={[
            {
              key: 'logs',
              icon: <img src={activityLogIcon} alt="Acitivity Logs" />,
              content: (
                <ActivityLogSidePanel entity="Product" entityId={data.id} closeDrawer={this.handleCloseDrawer} />
              ),
            },
            {
              key: 'comments',
              icon: <img src={commentsIcon} alt="Comments" />,
              content: (
                <RemarkSidePanel
                  productId={data.id}
                  activeType={this.state.activeCommentPanelType}
                  handleCloseDrawer={this.handleCloseDrawer}
                />
              ),
            },
          ]}
        />
      </ProductEditFormStyled>
    )
  }
}

const ProductEditFormStyled = styled.div`
  &&& .input-product-name {
    font-size: 1.5rem;
    font-weight: bold;
    background-color: transparent;
    padding: 0;
  }

  .product-tab {
    .nav-item {
      @media (max-width: 1912px) {
        flex: 1 1 auto;
        width: auto;
        max-width: 100%;
        &:nth-child(-n + 4) {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
    }
  }
`

export default inject(
  'productRepository',
  'commonRepository',
  'shippingRateRepository',
  'fileUploadRepository'
)(observer(ProductEditForm))
