/* @flow */
import { inject, observer } from 'mobx-react'
import React, { Component } from 'react'

import { ORDER_TYPES_PATH } from 'definitions'
import { Visible } from 'helpers/Auth'
import ContentContainer from 'components/Common/ContentContainer'
import IconButton from 'components/Common/Buttons/IconButton'
import ReactTable from 'components/Common/ReactTable'
import ToasterNotify from 'helpers/ToasterNotify'

class OrderTypes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      itemToDelete: null,
    }
  }
  handleCreate = () => {
    this.props.history.push(`${ORDER_TYPES_PATH}/new`)
  }
  handleDelete = item => {
    ToasterNotify.confirm({
      message: `Are you sure you want to delete this order type?`,
      title: 'Warning',
      onConfirm: () =>
        this.props.orderTypeRepository.delete(item.id, () => {
          this.table && this.table.reload()
        }),
    })
  }
  handToggleStatus = item => {
    // if (item) {
    //   const payload = {
    //     ...item,
    //     status: item.status && item.status === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE',
    //   }
    //   this.props.orderTypeRepository.patch(payload, () => {
    //     this.table && this.table.reload()
    //   })
    // }
  }
  render() {
    const {
      orderTypeRepository: {
        Table: { data, loading, originalData, totalRecordCount },
        fetchTable,
      },
      searchRepository,
    } = this.props

    return (
      <ContentContainer title="Order Types">
        <ReactTable
          searchRepository={searchRepository}
          pageKey="order-types"
          enableRowLink
          getRowUrl={({ original }) => `${ORDER_TYPES_PATH}/${original.id}`}
          className="striped cell-vertical-center"
          columns={[
            {
              accessor: 'name',
              Header: 'Name',
            },
            {
              accessor: 'description',
              Header: 'Description',
            },
            {
              accessor: 'orderNoPrefix',
              Header: 'Order No. Prefix',
            },
          ]}
          actions={
            <Visible>
              <div className="text-right">
                <IconButton onClick={this.handleCreate} color="dark" outline title="Add New Order Type" />
              </div>
            </Visible>
          }
          sort={{ id: 'name' }}
          data={data}
          loadData={fetchTable}
          loading={loading}
          totalRecordCount={totalRecordCount}
          totalPages={originalData.totalPages}
          innerRef={ref => (this.table = ref)}
          search
          ref={ref => (this.table = ref)}
        />
      </ContentContainer>
    )
  }
}

export default inject('orderTypeRepository', 'searchRepository')(observer(OrderTypes))
