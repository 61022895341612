/* @flow */

import { decorate } from 'mobx'
import BaseRepository from './BaseRepository'
import ShippingServices from 'services/shipping'

class ShippingRepository extends BaseRepository {
  service: ShippingServices
  constructor(service: ShippingServices) {
    super(service)
    this.service = service
  }
  bulkDelete = async payload => {
    return await this.service.bulkDelete(payload)
  }
  bulkUpdateStatus = async payload => {
    return await this.service.bulkUpdateStatus(payload)
  }
  reactSelectSearchShippingCarriers = async (shippingMethodId, params) => {
    if (shippingMethodId) {
      const { data, errors } = await this.service.reactSelectSearchShippingCarriers(shippingMethodId, params)
      return { data, errors }
    }
    return { errors: [{ message: 'shippingCarrier parameter is required' }] }
  }
  reactSelectSearchShippingServices = async (shippingMethodId, shippingCarrierId, params) => {
    if (shippingMethodId && shippingCarrierId) {
      const { data, errors } = await this.service.reactSelectSearchShippingServices(
        shippingMethodId,
        shippingCarrierId,
        params
      )
      return { data, errors }
    }
    return { errors: [{ message: 'shippingCarrier parameter is required' }] }
  }
}

export default decorate(ShippingRepository, {})
