import { observer } from 'mobx-react'
import React, { Component } from 'react'

import Card from 'components/Common/Card'
import ReactSelect from 'components/Common/Form/ReactSelect'
import Tags from 'components/Common/Tags'

class TagsPanel extends Component {
  render() {
    const { commonRepository, form, readOnly } = this.props

    return (
      <Card title="Tags">
        {!readOnly && (
          <ReactSelect
            field={form.$('searchTag')}
            search={params =>
              commonRepository.reactSelectSearch({ ...params, sort: 'label,asc', size: 50 }, 'productSearchTag')
            }
            serverSide
            options={{
              isMulti: true,
              valueKey: 'value',
              labelKey: 'label',
              defaultOptions: true,
            }}
          />
        )}
        {readOnly && <Tags data={form.$('searchTag').value} title="" hideDelete />}
      </Card>
    )
  }
}

export default observer(TagsPanel)
