import React, { Component } from 'react'

import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import RuleList from 'components/RuleList'

class Conditions extends Component {
  constructor(props) {
    super(props)
    const { catalogPricingRuleRepository } = props
    const { Attributes = {} } = catalogPricingRuleRepository

    if (!Attributes.data) {
      catalogPricingRuleRepository.getAttributesPricingRules()
    }
  }
  onAttributeSearch = payload => {
    if (payload.attributeType === 'collection') {
      if (payload.attributeName === 'country') {
        return this.props.commonRepository.reactSelectSearch(
          { ...payload, size: '99999', sort: 'label,asc' },
          payload.attributeName
        )
      }
      return this.props.commonRepository.reactSelectSearch(payload, payload.attributeName)
    } else if (payload.attributeType === 'customer') {
      return this.props.customerRepository.genericReactSelectSearch({
        search: payload.search,
        status: 'ACTIVE',
      })
    } else {
      return this.props.catalogPricingRuleRepository.getAttributeValues(payload)
    }
  }
  render() {
    const { catalogPricingRuleRepository, criteria, primaryIndex, deleteCondition } = this.props
    const { Attributes } = catalogPricingRuleRepository

    return (
      <div>
        <RuleList
          criteria={criteria}
          form={this.props.form}
          primaryIndex={primaryIndex}
          AttributeCollection={toJS(Attributes)}
          onAttributeSearch={this.onAttributeSearch}
          onDeleteParentCondition={deleteCondition}
        />
      </div>
    )
  }
}

export default decorate(
  inject('catalogPricingRuleRepository', 'commonRepository', 'customerRepository')(observer(Conditions)),
  {}
)
