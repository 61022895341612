import { decorate, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import React, { Component } from 'react'

import Loader from 'components/Common/Loader'
import ProductEditForm from 'components/Products/ProductEditForm'

class ProductEdit extends Component {
  readOnly = true
  componentDidMount() {
    this.loadProduct(this.props.match.params.productId)
  }
  componentDidUpdate(prevProps) {
    if (this.props.match.params.productId !== prevProps.match.params.productId) {
      this.loadProduct(this.props.match.params.productId)
    }
  }
  componentWillUnmount() {
    this.props.productRepository.clearCrudData()
  }
  loadProduct = productId => {
    const { productRepository, location, history } = this.props
    const { status } = queryString.parse(location.search)

    this.readOnly = status !== 'new'

    productRepository.clearCrudData()
    productRepository.getById(productId)
    status === 'new' && history.replace(location.pathName, {})
  }
  render() {
    const {
      productRepository: {
        CRUD: { loading = true, data },
      },
    } = this.props

    if (loading && !data.id) {
      return <Loader />
    }
    if (data.id) {
      return <ProductEditForm data={toJS(data)} readOnly={this.readOnly} {...this.props} />
    }
    return false
  }
}

export default decorate(inject('productRepository')(observer(ProductEdit)), {})
